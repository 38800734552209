import { useEffect } from 'react'
import { useFormikContext } from 'formik'

export const GetDateOfBirthValues = () => {
  const CalculateAge = (dateString) => {
    if (dateString === '') {
      return ''
    } else {
      let today = new Date()
      let birthDate = new Date(dateString)
      let age = today.getFullYear() - birthDate.getFullYear()
      let m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    }
  }
  const { values, setFieldValue } = useFormikContext()
  useEffect(() => {
    setFieldValue('age', CalculateAge(values.dateOfBirth))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])
  return null
}
