import { useState } from 'react'

const useInitialState = () => {
  const [isInitiallyLoad, setIsInitiallyLoad] = useState(true)

  const changeIntialStateHandler = (value = false) => setIsInitiallyLoad(value)

  return { isInitiallyLoad, changeIntialStateHandler }
}

export default useInitialState
