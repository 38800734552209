import React from 'react'
import { Form } from 'react-bootstrap'
import { useField } from 'formik'
import classnames from 'classnames'

const Checkbox = ({
  formGroupProps,
  labelProps: { className: labelClassName, ...other } = {},
  label,
  custom,
  leftSideLabel = false,
  ...rest
}) => {
  const [field] = useField(rest)

  const renderCheckboxLabel = () => {
    return (
      <Form.Check.Label
        className={classnames(
          'font-14',
          {
            'leftside-checkbox-label': leftSideLabel,
          },
          labelClassName,
        )}
        style={{ paddingTop: '1px' }}
        {...other}>
        {label}
      </Form.Check.Label>
    )
  }

  return (
    <Form.Group {...formGroupProps}>
      <Form.Check
        id={`custom-${rest?.name}`}
        custom={custom}
        type={'checkbox'}
        className={classnames('align-baseline', rest?.className, {
          'cursor-pointer': !rest.disabled,
        })}
        {...field}
        {...rest}>
        <Form.Check.Input
          type={'checkbox'}
          className={classnames('z3', {
            'cursor-pointer': !rest.disabled,
            'cursor-disabled': rest.disabled,
          })}
          {...field}
          {...rest}
        />
        {renderCheckboxLabel()}
      </Form.Check>
    </Form.Group>
  )
}

Checkbox.defaultProps = {
  custom: true,
}

export default Checkbox
