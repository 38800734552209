import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import SwiperCore, { Controller, Lazy } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { Helmet } from 'react-helmet'
import { useParams, useLocation } from 'react-router-dom'
import { ABSpinner, useErrorService, ModalProvider } from '@abroad/components'
import { ABSwiperSlider } from '@abroad/components/dist/slider'
import {
  InsightModel,
  Intention,
  useIntentionReducer,
  ArchivedIntentions,
  useUserFirstName,
} from '../components'
import API from '../utils/API'

SwiperCore.use([Controller, Lazy])
const swiperRef = React.createRef()

const Intentions = () => {
  const { state, dispatch } = useIntentionReducer()
  const [arrowHeight, setArrowHeight] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()
  const { clientId } = useParams()
  const userFirstName = useUserFirstName(clientId)
  const location = useLocation()
  const isMatch = location?.pathname.includes('coach')
  const url = isMatch ? '/coach/clients' : '/admin/users'

  useEffect(() => {
    const getintentions = async () => {
      setIsLoading(true)
      try {
        let { data } = await API.CoachAPI.coachClient.getIntentions(clientId, {
          archived: false,
        })
        if (data.length < 2) {
          dispatch({
            type: 'INTENTION_SUCCESS',
            payload: [
              ...data,
              ...Array.from({ length: 2 - data.length }, (x, i) => ({
                insights: [],
                createdAt: new Date(),
              })),
            ],
          })
          setIsLoading(false)
        } else {
          dispatch({ type: 'INTENTION_SUCCESS', payload: data })
          setIsLoading(false)
        }
      } catch (e) {
        dispatch({ type: 'INTENTION_FAIL', payload: null })
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getintentions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inputKeyUp = (e) => {
    setArrowHeight(e?.target?.scrollHeight - 72)
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${userFirstName} Intentions & Insights | Abroad`}</title>
        <meta
          name='title'
          content={`${userFirstName} Intentions & Insights | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${userFirstName} Intentions & Insights | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${userFirstName} Intentions & Insights | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}${url}/${clientId}/intentions`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}/${clientId}/intentions`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}/${clientId}/intentions`}></meta>
      </Helmet>
      <ModalProvider>
        <div className='d-block intentions d-md-none h-auto w-100'>
          <ABSwiperSlider
            lazy={true}
            onSwiper={(swiper) => {
              swiperRef.current = swiper
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
            autoHeight={true}
            arrowProps={{
              bgColor: '#646464',
              bgOpacity: 1,
              className: 'position-absolute mx-2',
              style: {
                top: `calc(11.5rem + ${arrowHeight}px)`,
              },
            }}>
            {state.intentions.map((intention, index) => (
              <SwiperSlide key={index}>
                {({ isActive }) => (
                  <Intention
                    inputKeyUp={inputKeyUp}
                    index={index + 1}
                    intention={intention}
                    isActive={isActive}
                  />
                )}
              </SwiperSlide>
            ))}
          </ABSwiperSlider>
        </div>
        <Container
          className='d-none d-md-block intention-card custom-offset-lg-1 custom-col-lg-12'
          fluid>
          {state.intentions.map((intention, index) => (
            <Intention
              key={intention?._id ? intention?._id : index}
              index={index + 1}
              intention={intention}
              inputKeyUp={inputKeyUp}
            />
          ))}
        </Container>
        <Container fluid>
          <ArchivedIntentions />
        </Container>
        <InsightModel />
      </ModalProvider>
    </>
  )
}

export default Intentions
