import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import makeAnimated from 'react-select/animated'
import { isLGScreen } from '../../utils/utility'
import { returnSelectionFieldStyle } from '../../constants/common'

const animatedComponents = makeAnimated()

const SelectionField = ({
  label,
  name,
  options,
  placeholder,
  t,
  classNamePrefix = '',
  ...rest
}) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext()
  const styles = returnSelectionFieldStyle(true, options?.length) //disabled props as true

  const handleChange = (selectedOption) => {
    setFieldValue(name, selectedOption)
  }

  return (
    <Row noGutters xs={1} sm={1} md={1} lg={2}>
      {label && (
        <Col className='col-lg-2 d-flex align-items-center'>
          <Form.Group className={isLGScreen ? '' : 'mb-0'}>
            <Form.Label column={isLGScreen ? true : false}>{label}</Form.Label>
          </Form.Group>
        </Col>
      )}
      <Col className={classnames({ 'col-lg-10 px-lg-0': label })}>
        <Form.Group>
          <Select
            value={values[name]}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            isValid={
              touched.hasOwnProperty(name) && !errors.hasOwnProperty(name)
            }
            isInvalid={
              touched.hasOwnProperty(name) && errors.hasOwnProperty(name)
            }
            placeholder={t(placeholder)}
            styles={styles}
            onFocus={() => {
              setFieldTouched(name, true, false)
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            options={options}
            isDisabled={rest?.disabled ? rest?.disabled : false}
            isSearchable={rest?.disabled ? !rest?.disabled : true}
            classNamePrefix={classNamePrefix}
          />
        </Form.Group>
      </Col>
    </Row>
  )
}

SelectionField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

SelectionField.defaultProps = {
  options: [],
  name: '',
  label: '',
}

export default withTranslation('translation')(SelectionField)
