import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { FieldArray, useFormikContext } from 'formik'
import { Col, Form, Row } from 'react-bootstrap'
import { Checkbox } from '../form'
import AttendanceCheckbox from './AttendanceCheckbox'

const MemberAttendance = ({ groupMembers }) => {
  const checkboxName = 'memberAttendance'
  const maxMembersAfterSeparateInTwoSection = 17
  const sortedGroupMembers = groupMembers?.sort((a, b) =>
    a.label.localeCompare(b.label),
  )
  const clonedGroupMembers = [...sortedGroupMembers]
  const middleIndex = Math.ceil(clonedGroupMembers?.length / 2)
  const firstSectionMembersArray = clonedGroupMembers?.splice(0, middleIndex)
  const secondSectionMembersArray = clonedGroupMembers?.splice(-middleIndex)
  const [selectAllMembers, setSelectAllMembers] = useState(false)
  const { setFieldValue, values } = useFormikContext()

  useEffect(() => {
    if (values?.[checkboxName]?.length === sortedGroupMembers?.length) {
      setSelectAllMembers(true)
    } else {
      setSelectAllMembers(false)
    }
  }, [values, sortedGroupMembers])

  const renderSelectAllCheckbox = () => {
    return (
      <Checkbox
        name='selectAllMembers'
        type='checkbox'
        label='Select All Members'
        labelProps={{
          className: 'cursor-pointer w-15 text-left word-breakall',
        }}
        value={selectAllMembers}
        checked={selectAllMembers}
        onChange={(e) => {
          setSelectAllMembers(!selectAllMembers)
          if (e.target.checked) {
            setFieldValue(
              checkboxName,
              sortedGroupMembers.map((member) => member?.value),
            )
          } else {
            setFieldValue(checkboxName, [])
          }
        }}
        formGroupProps={{
          className: 'd-flex',
        }}
        leftSideLabel={true}
        className='pl-0'
      />
    )
  }

  return (
    <Row className='mb-3 attendance-container'>
      <Col
        lg={2}
        md={12}
        sm={12}
        className='d-lg-flex justify-content-lg-end pr-lg-1'>
        <Form.Label>Member Attendance</Form.Label>
      </Col>
      <Col
        lg={10}
        md={12}
        sm={12}
        className={classNames('pl-lg-2', {
          'attendance-checkboxes': groupMembers?.length > 29,
        })}>
        <FieldArray
          name={checkboxName}
          render={(arrayHelpers) => (
            <div className='d-flex flex-column'>
              {groupMembers?.length >= maxMembersAfterSeparateInTwoSection ? (
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    {renderSelectAllCheckbox()}
                    {firstSectionMembersArray?.map((member, index) => (
                      <AttendanceCheckbox
                        key={index}
                        index={index}
                        checkboxName={checkboxName}
                        member={member}
                        arrayHelpers={arrayHelpers}
                      />
                    ))}
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className='second-attendance-section d-lg-flex flex-lg-column'>
                    {secondSectionMembersArray?.map((member, index) => (
                      <AttendanceCheckbox
                        key={index + firstSectionMembersArray?.length}
                        index={index + firstSectionMembersArray?.length}
                        checkboxName={checkboxName}
                        member={member}
                        arrayHelpers={arrayHelpers}
                      />
                    ))}
                  </Col>
                </Row>
              ) : (
                <React.Fragment>
                  {renderSelectAllCheckbox()}
                  {sortedGroupMembers?.map((member, index) => (
                    <React.Fragment key={index}>
                      <AttendanceCheckbox
                        index={index}
                        checkboxName={checkboxName}
                        member={member}
                        arrayHelpers={arrayHelpers}
                      />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </div>
          )}
        />
      </Col>
      <Col className='col-lg-2 col-12' />
      <Col className='pr-lg-2'>
        <span className='font-14 leading-3 forn-semibold'>
          * If you don't select anyone, then attendance wont count.
        </span>
      </Col>
    </Row>
  )
}

export default MemberAttendance
