import React from 'react'
import { FieldArray } from 'formik'
import CurrencyInput from './CurrencyInput'
import MinuteInput from './MinuteInput'
import { InputField } from '../form'

const column = ['ic', 'manager', 'leader', 'executive', 'ceo']

export const currencyUnmask = (e) => {
  if (typeof e === 'number') {
    return e
  } else {
    const value = e?.replace(/,/g, '')?.replace(/\$/g, '')
    return value !== '' ? parseInt(value) : 0
  }
}

export const minuteUnmask = (e) => {
  if (typeof e === 'number') {
    return e
  } else {
    const value = e?.replace(/,/g, '').replace(' min', '')
    return value !== '' ? parseInt(value) : 0
  }
}

const MonthlyPaymentTable = ({ disabled }) => {
  return (
    <>
      <thead>
        <tr>
          <th
            className='text-uppercase header-data monthly-payments'
            width='25%'>
            Monthly Payments
          </th>
          {['IC', 'Manager', 'Leader', 'Executive', 'Board & CEO'].map(
            (e, index) => (
              <th className='header-data text-uppercase' key={index}>
                {e}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Monthly Fee</td>
          <FieldArray
            name='monthlyFee'
            render={() => (
              <>
                {column.map((field) => (
                  <td key={field}>
                    <CurrencyInput
                      name={`monthlyFee.${field}`}
                      type='text'
                      unmask={currencyUnmask}
                      disabled={disabled}
                    />
                  </td>
                ))}
              </>
            )}
          />
        </tr>
        <tr>
          <td>Monthly Individual Sessions</td>
          <FieldArray
            name='monthlyIndividualSessions'
            render={() => (
              <>
                {column.map((field) => (
                  <td key={field}>
                    <InputField
                      type='number'
                      name={`monthlyIndividualSessions.${field}`}
                      disabled={disabled}
                      formGroupProps={{ className: 'mb-0' }}
                      className='border-radius-3'
                    />
                  </td>
                ))}
              </>
            )}
          />
        </tr>
        <tr>
          <td>Individual Session Duration (Minutes)</td>
          <FieldArray
            name='individualSessionDuration'
            render={() => (
              <>
                {column.map((field) => (
                  <td key={field}>
                    <MinuteInput
                      type='text'
                      name={`individualSessionDuration.${field}`}
                      unmask={minuteUnmask}
                      disabled={disabled}
                    />
                  </td>
                ))}
              </>
            )}
          />
        </tr>
        <tr>
          <td>Monthly Group Sessions</td>
          <FieldArray
            name='monthlyGroupSessions'
            render={() => (
              <>
                {column.map((field) => (
                  <td key={field}>
                    <InputField
                      name={`monthlyGroupSessions.${field}`}
                      type='number'
                      disabled={disabled}
                      formGroupProps={{ className: 'mb-0' }}
                      className='border-radius-3'
                    />
                  </td>
                ))}
              </>
            )}
          />
        </tr>
        <tr>
          <td>Group Session Duration (Minutes)</td>
          <FieldArray
            name='groupSessionDuration'
            render={() => (
              <>
                {column.map((field) => (
                  <td key={field}>
                    <MinuteInput
                      type='text'
                      name={`groupSessionDuration.${field}`}
                      unmask={minuteUnmask}
                      disabled={disabled}
                    />
                  </td>
                ))}
              </>
            )}
          />
        </tr>
      </tbody>
    </>
  )
}

export default MonthlyPaymentTable
