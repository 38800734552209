import { useContext, useEffect } from 'react'
import { OrgEcoIdsContext } from '../../utils/contexts'

const ReInitializedFilters = ({ globalFilter, setGlobalFilter }) => {
  const { isLoadNewData } = useContext(OrgEcoIdsContext)
  useEffect(() => {
    if (isLoadNewData) {
      setGlobalFilter({
        ...globalFilter,
        isToggleReInitialized: !globalFilter.isToggleReInitialized,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadNewData])
  return null
}

export default ReInitializedFilters
