import React, { useEffect, useState } from 'react'
import {
  useParams,
  useLocation,
  useHistory,
  Link,
  NavLink,
} from 'react-router-dom'
import { InfoIcon, useErrorService } from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import API from '../utils/API'
import i18n from '../utils/i18n'
import { withTranslation, Trans } from 'react-i18next'
import { Helmet } from 'react-helmet'

const EcosystemDateSurveyResult = () => {
  let { search } = useLocation()
  const { ecoSystemName } = useParams()
  let history = useHistory()
  const Error = useErrorService()
  const [survey, setSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const query = new URLSearchParams(search)
  const year = query.get('year')
  const quarter = query.get('quarter')
  const startDate = query.get('startDate')
  const endDate = query.get('endDate')

  useEffect(() => {
    const getComparion = async () => {
      try {
        const { data } =
          await API.AdminAPI.companyList.getSurveyDateByEcosystem(
            ecoSystemName,
            search,
          )
        setSurvey(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (((year && quarter) || (startDate && endDate)) && ecoSystemName) {
      getComparion()
    } else {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${ecoSystemName}'s Survey Result | Abroad`}</title>
        <meta
          name='title'
          content={`${ecoSystemName}'s Survey Result | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${ecoSystemName}'s Survey Result | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${ecoSystemName}'s Survey Result | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/networks`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/networks`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/networks`}></meta>
      </Helmet>
      <main>
        <Result
          survey={survey}
          isLoading={isLoading}
          i18n={i18n}
          navigateToSurveyCategory={(subcategory) => {
            return (
              <Link to={`/survey/category/${subcategory}`}>
                <InfoIcon />
              </Link>
            )
          }}
          headerText={<Trans>Network Awareness Assessment</Trans>}
          subHeaderText={
            <Trans>{`${decodeURIComponent(
              ecoSystemName,
            ).toUpperCase()}'S COMBINED SUMMARY RESULTS`}</Trans>
          }
          subHeaderDescription={
            <Trans>
              Below are the aggregate results for how all participants within
              Network rated their organizations and themselves during the
              specified time period
            </Trans>
          }
          showBreakthroughProgramInfo={false}
          onGoBack={() => history.goBack()}
          showGoBackButton={false}
          API={API}
          NavLink={NavLink}
        />
      </main>
    </>
  )
}

export default withTranslation('translation')(EcosystemDateSurveyResult)
