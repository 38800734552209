import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { Link, useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  Button,
  useErrorService,
  useUserService,
  useNotificationService,
} from '@abroad/components'
import { Table, TableInstance } from '../table'
import { ClearFilters } from '../common'
import {
  getGroupLabel,
  getManageGroupURL,
  isSOWGroups,
} from '../../utils/utility'
import API from '../../utils/API'
import UserSearch from '../company/UserSearch'

const intialGlobalFilter = {
  search: '',
}

const TeamListingTable = ({ isArchivedTable = false, tableId }) => {
  const hasMore = useRef(false)
  const [isLoading, setIsLoading] = useState(true)
  const [teamsData, setTeamsData] = useState([])
  const [buttonLoading, setButtonLoading] = useState(false)
  const [reqData, setReqData] = useState(null)
  const { user } = useUserService()
  const Notification = useNotificationService()
  const Error = useErrorService()
  const { pathname } = useLocation()
  const history = useHistory()

  const getAPIFunction = () => {
    const searchQuery = `?page=${reqData.page}&limit=${reqData.limit}&sort=${
      reqData.filter.sort
    }${reqData.filter?.search ? `&name=${reqData.filter?.search}` : ''}${
      isArchivedTable ? '&isArchived=true' : ''
    }`

    if (isArchivedTable) {
      return API.AdminAPI.teams.getArchivedTeams(searchQuery)
    }
    return API.AdminAPI.teams.getTeams(searchQuery)
  }

  const getTeamsData = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await getAPIFunction()
      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setTeamsData((prev) => [...prev, ...data])
      } else {
        setTeamsData(data)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getTeamsData(reqData)
    }
  }, [getTeamsData, reqData])

  const fetchData = useCallback(({ search, sortBy, page, limit }) => {
    if (page === 1) {
      setTeamsData([])
    }
    const req = {
      limit,
      page: page,
      filter: {
        ...(search && {
          search: search,
        }),
        sort: !sortBy[0].desc ? 'updatedAt' : '-updatedAt',
      },
    }
    setReqData(req)
  }, [])

  useEffect(() => {
    setTeamsData([])
    setReqData({
      limit: 25,
      page: 1,
      filter: {
        sort: '-updatedAt',
      },
    })
  }, [pathname])

  const getRestoreArchivedAPIFunction = (teamId) => {
    if (isArchivedTable) {
      return API.AdminAPI.teams.restoreTeam(teamId)
    }
    return API.AdminAPI.teams.archivedTeam(teamId, {
      isArchived: true,
    })
  }

  const handleRestoreArchivedTeam = async (teamId) => {
    try {
      const { data } = await getRestoreArchivedAPIFunction(teamId?._id)
      if (data) {
        setTeamsData((prev) => prev.filter((team) => team?._id !== teamId?._id))
        setButtonLoading(false)
        Notification.showNotification(
          `Group has been ${isArchivedTable ? 'restored' : 'archived'}!`,
          'success',
        )
      }
    } catch (e) {
      setButtonLoading(false)
      Error.showError(e)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'number',
        Header: '#',
        width: '3%',
        accessor: (properties) => properties,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
        disableSortBy: true,
      },
      {
        id: 'group-name',
        Header: 'Group Name',
        width: '25%',
        accessor: (properties) => properties.name,
        Cell: ({ cell }) => <span className='word-break'>{cell?.value}</span>,
        disableSortBy: true,
      },
      {
        id: 'group',
        Header: 'Group',
        width: '22%',
        accessor: (properties) => ({
          groupName: properties?.groupName,
          groupId: properties?.groupId,
          groupType: properties?.groupType,
        }),
        Cell: ({ cell }) => (
          <div>
            {cell?.value?.groupName || cell?.value?.groupId?.name ? (
              <span
                className={classnames({
                  'text-uppercase': cell?.value?.groupType === 3,
                })}>
                {cell?.value.groupName || cell?.value?.groupId?.name}
                <br />
              </span>
            ) : null}
            <small className='text-saffron-700'>
              ({getGroupLabel(cell?.value?.groupType)})
            </small>
          </div>
        ),
        disableSortBy: true,
      },
      {
        id: 'creation-date',
        Header: 'Creation Date',
        width: '10%',
        accessor: (properties) => properties?.createdAt,
        Cell: ({ cell }) => (
          <span>{moment(cell?.value).format('MM-DD-YYYY')}</span>
        ),
        disableSortBy: true,
      },
      {
        id: 'updated-date',
        Header: 'Updated Date',
        width: '15%',
        accessor: (properties) => properties?.updatedAt,
        Cell: ({ cell }) => (
          <span>{moment(cell?.value).format('MM-DD-YYYY')}</span>
        ),
      },
      {
        id: 'agreement-signed-date',
        Header: 'Agreement Signed Date',
        width: '10%',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <span>
            {cell?.value &&
            'agreementSignedAt' in cell?.value &&
            cell?.value?.agreementSignedAt !== null
              ? moment(cell?.value?.agreementSignedAt).format('MM-DD-YYYY')
              : 'Not Specified'}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'actions',
        Header: 'Action',
        width: '15%',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <span>
            {/* eslint-disable */}
            <Link
              className={classnames('text-saffron-700 cursor-pointer', {
                'pr-2 archive-border-right': !isSOWGroups(
                  cell?.value?.groupType,
                ),
                'text-center': isSOWGroups(cell?.value?.groupType),
              })}
              target={
                isSOWGroups(cell?.value?.groupType) && user?.isAdmin
                  ? '_blank'
                  : '_self'
              }
              to={getManageGroupURL(cell?.value, user?.isAdmin)}>
              {isArchivedTable ? 'View' : 'Manage'}
            </Link>
            {!isSOWGroups(cell?.value?.groupType) && (
              <a
                href='javascript:void(0)'
                className={classnames('text-red-500 cursor-pointer pl-2', {
                  'cursor-disabled': buttonLoading,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  if (!buttonLoading) {
                    setButtonLoading(true)
                    handleRestoreArchivedTeam(cell?.value)
                  }
                }}>
                {isArchivedTable ? 'Restore' : 'Archive'}
              </a>
            )}
            {/* eslint-enable */}
          </span>
        ),
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isArchivedTable, buttonLoading, user.isAdmin],
  )

  return (
    <TableInstance
      columns={columns}
      data={teamsData}
      initialState={{
        globalFilter: {
          search: '',
        },
        sortBy: [{ id: 'updated-date', desc: true }],
      }}
      key={tableId}>
      {({
        preGlobalFilteredRows,
        state: { globalFilter, ...other },
        setGlobalFilter,
        ...rest
      }) => (
        <>
          <div className='d-flex justify-content-between mb-3'>
            <div className='d-flex'>
              <UserSearch
                {...{
                  globalFilter,
                  setGlobalFilter,
                }}
                //placeholder='Name Or Group name'
              />
              {globalFilter?.search && (
                <ClearFilters
                  wrapperClasses='mx-4 group-clear-filter'
                  onClick={() => setGlobalFilter(intialGlobalFilter)}
                  disabled={isLoading}
                />
              )}
            </div>
            {!isArchivedTable && (
              <Button
                variant='gray'
                size='md'
                className='line-height-normal border-radius-3 text-uppercase'
                onClick={() => history.push('/admin/group')}>
                create group
              </Button>
            )}
          </div>
          <Table
            ref={hasMore}
            emptyTableMessage='No Groups found.'
            tableInstance={{
              preGlobalFilteredRows,
              state: { globalFilter, ...other },
              setGlobalFilter,
              ...rest,
            }}
            isLoading={isLoading}
            fetchData={fetchData}
          />
        </>
      )}
    </TableInstance>
  )
}

export default TeamListingTable
