import React, { useEffect, useState } from 'react'
import { Form as RBFrom, Row, Col, Container } from 'react-bootstrap'
import {
  Button,
  useNotificationService,
  useErrorService,
} from '@abroad/components'
import { Formik, Form } from 'formik'
import { string, object, number, date } from 'yup'
import { InputField, SingleDatePicker } from '../components/form'
import API from '../utils/API'
import { PromoCodeListing } from '../components/promoCode'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../components/common'
import moment from 'moment'

export const PromoCode = () => {
  const Notification = useNotificationService()
  const Error = useErrorService()
  const [isPromoCreate, setIsPromoCreate] = useState(false)
  const [promoCodeData, setPromoCodeData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  let promoCodeInitialValues = {
    code: '',
    noOfUse: '',
    expiryDate: null,
    discount: '',
    maxAmount: '',
  }

  const validationSchema = object().shape({
    code: string().required().matches('^[a-zA-Z0-9]+$'),
    noOfUse: number().required().positive().integer().max(100000),
    maxAmount: number().positive().integer().max(100000),
    discount: number().required().positive().integer().max(100),
    expiryDate: date()
      .required()
      .test('valid-date', 'Please enter valid date!', function (value) {
        const checkDate = moment(value, 'MM/DD/YYYY')
        return checkDate.isValid()
      }),
  })

  useEffect(() => {
    const getPromoCodeList = async () => {
      try {
        const { data } = await API.AdminAPI.promo.getPromoCodeList()
        if (data) {
          setPromoCodeData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getPromoCodeList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values, { resetForm }) => {
    setIsPromoCreate(true)
    let params = {
      code: values.code.toUpperCase(),
      noOfUse: values.noOfUse,
      expTime: moment(values.expiryDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      discount: values.discount,
      maxAmount: values.maxAmount,
    }

    Object.keys(params).forEach(
      (key) => params[key] === '' && delete params[key],
    )

    try {
      const { data } = await API.AdminAPI.promo.createPromoCode(params)
      if (data) {
        Notification.showNotification('Promo Code Created!', 'success')
        resetForm()
        promoCodeInitialValues = {
          ...promoCodeInitialValues,
          expiryDate: null,
        }
        setIsPromoCreate(false)
        setPromoCodeData((prev) => [...prev, data])
      }
    } catch (e) {
      if (e.code === 'promo_code_exists') {
        Notification.showNotification(e.message, 'danger')
      } else {
        Error.showError(e)
      }
      setIsPromoCreate(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>{'Promo Code | Abroad'}</title>
        <meta name='title' content={'Promo Code | Abroad'}></meta>
        <meta property='og:title' content={'Promo Code | Abroad'}></meta>
        <meta property='twitter:title' content={'Promo Code | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/promo}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/promo}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/promo}`}></meta>
      </Helmet>
      <main>
        <section>
          <PageHeader
            titleText='GENERATE NEW PROMO CODE'
            colClasses='px-lg-0'
            titleClasses='sentence-case-text'
          />
          <Container
            fluid
            className='custom-offset-lg-1 custom-col-lg-12 px-lg-0'>
            <Formik
              validationSchema={validationSchema}
              initialValues={promoCodeInitialValues}
              onSubmit={onSubmit}>
              {({ handleSubmit, errors }) => {
                return (
                  <RBFrom as={Form} className='profile mt-4'>
                    <Row className='d-flex'>
                      <Col
                        className='d-flex flex-column'
                        xs={4}
                        sm={4}
                        md={4}
                        lg={2}>
                        <RBFrom.Label className='text-left'>
                          CODE NAME *
                        </RBFrom.Label>
                        <InputField
                          name='code'
                          type='text'
                          size='lg'
                          className='text-uppercase'
                        />
                      </Col>
                      <Col
                        className='d-flex flex-column'
                        xs={4}
                        sm={4}
                        md={4}
                        lg={2}>
                        <RBFrom.Label className='text-left'>
                          % DISCOUNT
                        </RBFrom.Label>
                        <InputField name='discount' type='number' size='lg' />
                      </Col>
                      <Col
                        className='d-flex flex-column'
                        xs={4}
                        sm={4}
                        md={4}
                        lg={2}>
                        <RBFrom.Label className='text-left'>
                          MAX OFF ($)
                        </RBFrom.Label>
                        <InputField name='maxAmount' type='number' size='lg' />
                      </Col>
                      <Col
                        className='d-flex flex-column'
                        xs={4}
                        sm={4}
                        md={4}
                        lg={2}>
                        <RBFrom.Label className='text-left'>
                          THE NO OF USE
                        </RBFrom.Label>
                        <InputField name='noOfUse' type='number' size='lg' />
                      </Col>
                      <Col
                        className='d-flex flex-column'
                        xs={4}
                        sm={4}
                        md={4}
                        lg={2}>
                        <RBFrom.Label className='text-left'>
                          EXPIRY DATE
                        </RBFrom.Label>
                        <SingleDatePicker
                          name='expiryDate'
                          size='lg'
                          minimumDate={moment()}
                          maximumDate={moment().add(10, 'years')}
                          className='user-input form-control-lg bg-white'
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          onClick={handleSubmit}
                          isLoading={isPromoCreate}
                          disabled={isPromoCreate}
                          type='submit'
                          size='md'
                          className='line-height-normal text-uppercase border-radius-3'
                          variant='gray'>
                          GENERATE CODE
                        </Button>
                      </Col>
                    </Row>
                  </RBFrom>
                )
              }}
            </Formik>
          </Container>
        </section>
        <PromoCodeListing
          removePromoHandler={(promoId) => {
            setPromoCodeData((prev) =>
              prev.filter((promo) => promo?._id !== promoId),
            )
          }}
          promoCodeData={promoCodeData}
          isLoading={isLoading}
        />
      </main>
    </>
  )
}
