import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { AsyncPaginate } from 'react-select-async-paginate'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { useErrorService } from '@abroad/components'
import API from '../../utils/API'
import { returnSelectionFieldStyle } from '../../constants/common'

const InsightsTeamClientFilter = ({
  name,
  placeholder,
  disabled,
  filterByTeam = false,
}) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext()
  const Error = useErrorService()
  const dataLimit = 10
  const [isLoadOptionAgain, setIsLoadOptionAgain] = useState(false)
  const extraStyle = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  }
  const styles = returnSelectionFieldStyle(disabled, extraStyle)

  useEffect(() => {
    if (values.filterByCoach !== '') {
      setFieldValue(name, '')
      setIsLoadOptionAgain(!isLoadOptionAgain)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.filterByCoach])

  const getOptionsList = async (search = '', page = 1) => {
    try {
      const { data } = await API.AdminAPI.teams.getClientOrTeamOption(
        filterByTeam,
        {
          ...(values?.filterByCoach &&
            Array.isArray(values?.filterByCoach) &&
            values?.filterByCoach?.length > 0 && {
              coachIds: values?.filterByCoach.map(({ value }) => value),
            }),
          page,
          limit: dataLimit,
          ...(search !== '' && { search }),
        },
      )
      let hasLoadMore = data.length === 10

      const options = data.map((data) => ({
        value: data._id,
        label: filterByTeam ? data?.name : data?.fullName,
      }))
      return {
        options,
        hasMore: hasLoadMore,
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const handleChange = (selectedOption) => {
    setFieldValue(
      name,
      selectedOption === null
        ? []
        : [selectedOption[selectedOption.length - 1]],
    )
    setIsLoadOptionAgain(!isLoadOptionAgain)
  }

  const loadPageOptions = async (inputValue, prevOptions, { page }) => {
    const { options, hasMore } = await getOptionsList(
      inputValue,
      page,
      prevOptions,
    )

    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  return (
    <Row>
      <Col className='custom-selection'>
        <Form.Group>
          <AsyncPaginate
            key={isLoadOptionAgain}
            value={values[name]}
            closeMenuOnSelect={false}
            additional={{ page: 1 }}
            isMulti
            loadOptions={loadPageOptions}
            isValid={
              touched.hasOwnProperty(name) && !errors.hasOwnProperty(name)
            }
            isInvalid={
              touched.hasOwnProperty(name) && errors.hasOwnProperty(name)
            }
            placeholder={placeholder}
            styles={styles}
            onFocus={() => {
              setFieldTouched(name, true, false)
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            isClearable={values[name]}
            isDisabled={disabled}
            debounceTimeout={1000}
          />
        </Form.Group>
      </Col>
    </Row>
  )
}

InsightsTeamClientFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

InsightsTeamClientFilter.defaultProps = {
  options: [],
  name: '',
  label: '',
}

export default InsightsTeamClientFilter
