import React from 'react'
import classNames from 'classnames'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'

const SubHeader = ({
  title = '',
  contentText = '',
  placement = 'bottom',
  wrapperClasses = '',
  titleClasses = '',
}) => {
  return (
    <Row>
      <Col className={classNames('d-flex', wrapperClasses)}>
        <h2
          className={classNames(
            'font-open-sans font-bold font-lg-24 font-20 text-dark-900 leading-9 letter-spacing-minus-04 text-capitalize mb-0',
            titleClasses,
          )}>
          {title}
        </h2>
        {contentText && (
          <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
              <Tooltip
                id={`tooltip-${placement}-${title}`}
                className='font-open-sans font-16 font-semibold leading-7 text-center header-tooltip'>
                {contentText}
              </Tooltip>
            }>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              className='pointer-cursor ml-3 align-self-center'>
              <circle cx='12' cy='12' r='12' fill='black' />
              <circle cx='12' cy='6.50391' r='1.5' fill='white' />
              <rect
                x='10.5'
                y='10.0039'
                width='3'
                height='9'
                rx='1.5'
                fill='white'
              />
            </svg>
          </OverlayTrigger>
        )}
      </Col>
    </Row>
  )
}

export default SubHeader
