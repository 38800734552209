import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import { Switch, NavLink, useLocation, useHistory } from 'react-router-dom'
import SwiperCore, { Controller, History } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { SaffronNav } from '@abroad/components'
import { ABSwiperSlider } from '@abroad/components/dist/slider'
import { ClientsOfCoach, TeamsOfCoach } from '../components'
import PrivateRoute from '../routes/PrivateRoute'
import { PageHeader } from '../components/common'
import { isLGScreen } from '../utils/utility'

SwiperCore.use([Controller, History])
const swiperRef = React.createRef()

const Clients = () => {
  let location = useLocation()
  let history = useHistory()

  const pages = [
    {
      path: `/coach/clients`,
      name: 'Clients',
    },
    {
      path: `/coach/groups`,
      name: 'Groups',
    },
  ]

  useEffect(() => {
    if (location.pathname === `/coach/clients`) {
      swiperRef.current.slideTo(0)
    } else {
      swiperRef.current.slideTo(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <Helmet>
        <title>{'Coach Dashboard | Abroad'}</title>
        <meta name='title' content={'Coach Dashboard | Abroad'}></meta>
        <meta property='og:title' content={'Coach Dashboard | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Coach Dashboard | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/coach/clients`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients`}></meta>
      </Helmet>
      <main className='coach-dashboard'>
        <PageHeader
          sectionClassName='page-heading'
          titleText='Coach Dashboard'
          hasBackArrow={false}
          titleClasses='sentence-case-text'
          colClasses='px-0'
        />
        <section className='custom-offset-lg-1 custom-col-lg-12 px-lg-0'>
          <Container fluid className='px-lg-0'>
            <SaffronNav
              variant='tabs'
              fill
              defaultActiveKey='/admin/home'
              className='d-none d-md-flex header-tabs border-0'>
              {pages.map((page) => (
                <Row className='d-flex' key={page?.name}>
                  <Col>
                    <SaffronNav.Item
                      key={page.path}
                      className='position-relative'>
                      <SaffronNav.Link
                        as={NavLink}
                        to={page.path}
                        isActive={(match) => {
                          if (!match) {
                            return false
                          }
                          return true
                        }}>
                        {page.name}
                      </SaffronNav.Link>
                    </SaffronNav.Item>
                  </Col>
                </Row>
              ))}
            </SaffronNav>
          </Container>
        </section>
        <section className='d-none d-md-block custom-offset-lg-1 custom-col-lg-12 px-lg-0'>
          <Switch location={location}>
            <PrivateRoute path={`/coach/clients`}>
              <ClientsOfCoach />
            </PrivateRoute>
            <PrivateRoute path={`/coach/groups`}>
              <TeamsOfCoach />
            </PrivateRoute>
          </Switch>
        </section>
        <section className='d-block position-relative d-md-none custom-offset-lg-1 custom-col-lg-12'>
          <ABSwiperSlider
            onSlideChange={(e) => {
              if (e.activeIndex === 0) {
                history.push(`/coach/clients`)
              } else {
                history.push(`/coach/groups`)
              }
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper
            }}
            arrowProps={{
              bgColor: '#646464',
              bgOpacity: 1,
              className: 'position-absolute',
              style: {
                top: '2rem',
              },
            }}>
            <SwiperSlide url={`/coach/clients`} key={`/coach/clients`}>
              <div>
                <div className='px-5 pt-1 font-medium text-saffron-700 h-auto gp-label text-center'>
                  Clients
                  <hr className='w-25' />
                </div>
                {!isLGScreen && (
                  <PrivateRoute exact strict path={`/coach/clients`}>
                    <ClientsOfCoach />
                  </PrivateRoute>
                )}
              </div>
            </SwiperSlide>
            <SwiperSlide url={`/coach/groups`} key={`/coach/groups`}>
              <div>
                <div className='px-5 pt-1 font-medium text-saffron-700 h-auto gp-label text-center'>
                  Groups
                  <hr className='w-25' />
                </div>
                {!isLGScreen && (
                  <PrivateRoute exact strict path={`/coach/groups`}>
                    <TeamsOfCoach />
                  </PrivateRoute>
                )}
              </div>
            </SwiperSlide>
          </ABSwiperSlider>
        </section>
      </main>
    </>
  )
}

export default withTranslation('translation')(Clients)
