import React, { useContext, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Button } from '@abroad/components'
import PageHeader from './PageHeader'
import {
  CompanyEcoSearch,
  OrganizationFilterForm,
  UpsertCompanyEcoModal,
} from '../company'
import { Table, TableInstance } from '../table'
import labels from '../../constants/labels'
import { ListingFiltersContext } from '../../utils/contexts'

const OrgEcoListingTable = ({
  columns,
  data,
  label,
  sortById,
  search,
  fetchData,
  isLoading,
  hasMore,
  type,
}) => {
  const title = `${label} | Abroad`
  const [showAddCompanyEcoModal, setShowAddCompanyEcoModal] = useState(false)
  const { filters } = useContext(ListingFiltersContext)

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='title' content={title}></meta>
        <meta property='og:title' content={title}></meta>
        <meta property='twitter:title' content={title}></meta>
        <link
          rel='canonical'
          href={`${
            process.env.REACT_APP_DOMAIN
          }/admin/${label?.toLowerCase()}`}></link>
        <meta
          property='og:url'
          content={`${
            process.env.REACT_APP_DOMAIN
          }/admin/${label?.toLowerCase()}`}></meta>
        <meta
          property='twitter:url'
          content={`${
            process.env.REACT_APP_DOMAIN
          }/admin/${label?.toLowerCase()}`}></meta>
      </Helmet>
      <TableInstance
        columns={columns}
        data={data}
        initialState={{
          globalFilter: {
            search: search || filters?.search,
            ...(type === 'organization' && { type: filters?.type }),
            ...(type === 'network' && {
              isItProgram: filters?.isItProgram,
            }),
            ...(type === 'network' && {
              isEmailSet: filters?.isEmailSet,
            }),
            status: filters?.status,
          },
          sortBy: [{ id: sortById, desc: filters?.sort === 'DESC' }],
        }}>
        {({
          preGlobalFilteredRows,
          state: { globalFilter, ...other },
          setGlobalFilter,
          ...rest
        }) => (
          <>
            <PageHeader
              titleText={label}
              titleClasses='s2 custom-mb-0-625 sentence-case-text'
              wrapperClassName='d-flex justify-content-between mb-0'
              descriptionFn={() => {
                return (
                  <div className='d-flex'>
                    <div className='profile d-flex flex-grow-1'>
                      <CompanyEcoSearch
                        {...{
                          globalFilter,
                          setGlobalFilter,
                        }}
                        placeholder={`Search ${label}`}
                      />
                      {/* Hide merge organization for now as it will require additional changes due to SOWs now. */}
                      {/* <MergeOrganization
                  updateOrganizationList={updateOrganizationList}
                /> */}
                      <Button
                        className='text-uppercase ml-4 s8 line-height-normal'
                        variant='black'
                        onClick={() => setShowAddCompanyEcoModal(true)}>
                        Add
                      </Button>
                    </div>
                  </div>
                )
              }}
              colClasses='px-0'
            />
            <Container
              className='custom-offset-lg-1 custom-col-lg-12 px-0 mb-2-5 mt-2-5 profile'
              fluid>
              <Row>
                <Col className='d-flex space-x-6'>
                  {type === 'organization' && (
                    <div className='d-flex flex-column'>
                      <OrganizationFilterForm
                        {...{
                          globalFilter,
                          setGlobalFilter,
                        }}
                        name='type'
                        options={labels.orgFilterType}
                        optionplaceholder={`Filter ${
                          type === 'organization' ? 'Org' : 'Network'
                        } Type`}
                      />
                      <Form.Text className='text-muted font-10'>
                        Select Organization Type
                      </Form.Text>
                    </div>
                  )}
                  <div className='d-flex flex-column'>
                    <OrganizationFilterForm
                      {...{
                        globalFilter,
                        setGlobalFilter,
                      }}
                      name='status'
                      options={labels.orgFilterstatus}
                      optionplaceholder='Filter Status'
                    />
                    <Form.Text className='text-muted font-10'>
                      Select Status
                    </Form.Text>
                  </div>
                  {type === 'network' && (
                    <>
                      <div className='d-flex flex-column'>
                        <OrganizationFilterForm
                          {...{
                            globalFilter,
                            setGlobalFilter,
                          }}
                          name='isItProgram'
                          options={labels.statusFilterOptions}
                          optionplaceholder='Filter Is It Program'
                        />
                        <Form.Text className='text-muted font-10'>
                          Is It Program?
                        </Form.Text>
                      </div>
                      <div>
                        <OrganizationFilterForm
                          {...{
                            globalFilter,
                            setGlobalFilter,
                          }}
                          name='isEmailSet'
                          options={labels.statusFilterOptions}
                          optionplaceholder='Filter Is Email Set'
                        />
                        <Form.Text className='text-muted font-10'>
                          Is Email Set?
                        </Form.Text>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
            <UpsertCompanyEcoModal
              show={showAddCompanyEcoModal}
              onHide={() => {
                setShowAddCompanyEcoModal(false)
              }}
              type={type}
            />
            <Container
              className='mt-3 custom-offset-lg-1 custom-col-lg-12 px-0'
              fluid>
              <Table
                ref={hasMore}
                emptyTableMessage={`No ${sortById?.toLowerCase()} found.`}
                tableInstance={{
                  preGlobalFilteredRows,
                  state: { globalFilter, ...other },
                  setGlobalFilter,
                  ...rest,
                }}
                isLoading={isLoading}
                fetchData={fetchData}
              />
              {/* {isLoadMore && <tr ref={loader}></tr>} */}
            </Container>
          </>
        )}
      </TableInstance>
    </>
  )
}

export default OrgEcoListingTable
