import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import { Col, Form as RBForm, Row } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { string, object } from 'yup'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
} from '@abroad/components'
import API from '../utils/API'
import { InputField, Checkbox } from '../components/form'
import { isLGScreen } from '../utils/utility'

const EmailFormInitialValues = {
  email: '',
  emailVerified: false,
}

export const UpdateEmail = () => {
  const { clientId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [initialValues, setInitialValues] = useState(EmailFormInitialValues)
  const [user, setUser] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const history = useHistory()

  /*eslint-disable */
  const validationSchema = object().shape({
    email: string()
      .email('Please enter a valid Email address')
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      .max(255)
      .required('Required'),
  })
  /*eslint-enable */

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['email', 'fullName', 'emailVerified', 'fname'],
          },
        )
        if (data) {
          setUser(data)
          setIsLoading(false)
          setInitialValues((prev) => ({
            ...prev,
            email: data.email,
            emailVerified: data.emailVerified,
          }))
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values) => {
    setIsSubmitting(true)
    try {
      const { data } = await API.AdminAPI.coachClient.updateEmail(clientId, {
        email: values.email,
        emailVerified: values?.emailVerified,
      })
      if (data) {
        setIsSubmitting(false)
        Notification.showNotification('User Email has been updated!', 'success')
        history.push(`/admin/users/${clientId}`)
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${user?.fname}'s Update Email | Abroad`}</title>
        <meta
          name='title'
          content={`${user?.fname}'s Update Email | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${user?.fname}'s Update Email | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${user?.fname}'s Update Email | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/users/${clientId}/update-email`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/users/${clientId}/update-email`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/users/${clientId}/update-email`}></meta>
      </Helmet>
      <main>
        <section className='update-email d-flex justify-content-center align-item-center'>
          <Formik
            displayName='profile-form'
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnMount
            onSubmit={onSubmit}>
            {({ handleSubmit }) => {
              return (
                <RBForm
                  onSubmit={handleSubmit}
                  as={Form}
                  className='profile px-4 px-lg-0'>
                  <Row noGutters xs={1} sm={1} md={1} lg={2}>
                    <Col className='col-lg-2 d-flex justify-content-start justify-content-lg-end align-items-center'>
                      <RBForm.Group className={isLGScreen ? '' : 'mb-0'}>
                        <RBForm.Label column={isLGScreen ? true : false}>
                          Email
                        </RBForm.Label>
                      </RBForm.Group>
                    </Col>
                    <Col className='col-lg-10'>
                      <InputField
                        name='email'
                        type='email'
                        size='lg'
                        placeholder='Enter Email.'
                        className='user-input'
                        sm='10'
                        disabled={isSubmitting}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2} md={0} sm={0}></Col>
                    <Col lg={10} md={12} sm={12}>
                      <Checkbox
                        name='emailVerified'
                        type='checkbox'
                        label='Email Verified'
                        disabled={user.emailVerified || isSubmitting}
                      />
                    </Col>
                  </Row>
                  <div className='text-right'>
                    <Button
                      variant='gray'
                      disabled={isLoading || isSubmitting}
                      isLoading={isLoading || isSubmitting}
                      size='md'
                      className='text-uppercase line-height-normal border-radius-3'
                      type='submit'>
                      Save
                    </Button>
                  </div>
                </RBForm>
              )
            }}
          </Formik>
        </section>
      </main>
    </>
  )
}
