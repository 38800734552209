import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Button, useUserService, CloseIcon } from '@abroad/components'
import ClientPlans from './ClientPlans'
import { AdditionalSettings } from '../common/right-sidebar'

const ProfileSidebarSection = ({
  closeClickedHandler,
  isAdmin,
  isCoach,
  email,
  clientProfile,
  rightSidebarRef,
  isShowActiveSessions,
  setIsShowActiveSessions,
  planChangeHandler,
}) => {
  const [isShowCalendar, setIsShowCalendar] = useState(false)
  const { user } = useUserService()
  const { clientId } = useParams()
  const { pathname } = useLocation()
  const isSessionsTab = pathname.split('/').includes('sessions')

  const linksForAdmin = [
    {
      name: 'Update Email',
      to: `/admin/users/${clientId}/update-email`,
    },
  ]

  const linksForCoach = [
    {
      name: 'View Profile',
      to: `/admin/client/${clientId}/view/client-profile`,
    },
  ]

  useEffect(() => {
    //  fire event if clicked on outside of element
    const handleClickOutside = (event) => {
      if (
        rightSidebarRef.current &&
        !rightSidebarRef.current.contains(event.target) &&
        !isShowCalendar
      ) {
        closeClickedHandler()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightSidebarRef, isShowCalendar])

  return (
    <div className='m-3'>
      <div className='text-right'>
        <CloseIcon color='#000' onClick={closeClickedHandler} />
      </div>
      <section className='d-flex flex-column mt-3'>
        {user?.isAdmin && (
          <ClientPlans
            clientData={clientProfile}
            setIsShowCalendar={setIsShowCalendar}
            planChangeHandler={planChangeHandler}
          />
        )}
        <AdditionalSettings
          links={
            user?.isAdmin ? [...linksForCoach, ...linksForAdmin] : linksForCoach
          }
          closeClickedHandler={closeClickedHandler}
        />
      </section>
      {isSessionsTab && user?.isAdmin && (
        <Button
          variant='black'
          className='w-100 line-height-normal'
          size='md'
          onClick={() => {
            closeClickedHandler()
            setIsShowActiveSessions(!isShowActiveSessions)
          }}>
          {isShowActiveSessions
            ? 'View Archived sessions'
            : 'View Active sessions'}
        </Button>
      )}
    </div>
  )
}

export default ProfileSidebarSection
