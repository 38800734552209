import React from 'react'
import { CloseIcon } from '@abroad/components'

const SideBarCloseIcon = ({ closeClickedHandler }) => {
  return (
    <div className='text-right mb-3'>
      <CloseIcon color='#000' onClick={closeClickedHandler} />
    </div>
  )
}

export default SideBarCloseIcon
