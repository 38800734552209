import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { useUserService } from '@abroad/components'
import { ActionButton } from '../common'
import { ReturnPageIcon } from '../assetsComponents'

const ProfileHeader = ({ name, email, hasBackArrow, buttonClickedHandler }) => {
  const history = useHistory()
  const { user } = useUserService()
  const { pathname } = useLocation()
  const isViewClientProfilePage = pathname.split('/').includes('client-profile')

  return (
    <div className='py-5 bg-gray-100 header-shadow platform-header d-flex align-items-center'>
      <Container fluid className='custom-offset-lg-1 custom-col-lg-12'>
        <Row>
          <Col>
            <div className='d-flex'>
              {hasBackArrow && (
                <ReturnPageIcon
                  onClick={() => {
                    if (history.action === 'POP') {
                      history.push(
                        user?.isAdmin ? '/admin/users' : '/coach/clients',
                      )
                      return
                    }
                    history.goBack()
                  }}
                />
              )}
              <div>
                <h3 className='text-dark-600 text-uppercase page-label'>
                  {name}
                </h3>
                <span className='font-montserrat font-medium'>{email}</span>
              </div>
            </div>
          </Col>
          {isViewClientProfilePage && !user?.isAdmin ? null : (
            <Col className='d-flex align-self-center justify-content-end'>
              <ActionButton buttonClickedHandler={buttonClickedHandler} />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default ProfileHeader
