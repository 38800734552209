import React from 'react'
import classNames from 'classnames'
import { useField } from 'formik'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { getCurrencyMaskOption } from '../../utils/maskOptionHelper'

const CurrencyInput = ({ ...rest }) => {
  const [field, meta] = useField({ name: rest.name, type: rest.type })

  return (
    <MaskedInput
      {...field}
      mask={createNumberMask(getCurrencyMaskOption())}
      maskOptions={getCurrencyMaskOption()}
      type='text'
      className={classNames(
        'form-control',
        {
          'is-valid': meta.touched && !meta.error,
          'is-invalid': meta.touched && meta.error,
        },
        rest.inputClasses,
      )}
      {...rest}
    />
  )
}

export default CurrencyInput
