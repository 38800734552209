import React, { useEffect } from 'react'
import sysend from 'sysend'

const ClientRedirect = () => {
  useEffect(() => {
    sysend.broadcast('admin_logout', { message: 'admin_logout' })
  }, [])
  return (
    <>
      <iframe
        src={`${process.env.REACT_APP_CLIENT_DOMAIN}/force-logout`}
        title='Abroad'
        style={{ display: 'none' }}
      />
      <div>Redirect to {process.env.REACT_APP_CLIENT_DOMAIN}</div>
    </>
  )
}

export default ClientRedirect
