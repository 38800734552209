import React from 'react'
import { convertSessionDate } from '../../utils/date'

const EnterpriseOneTimePayments = ({ oneTimePayments }) => {
  if (oneTimePayments?.length === 0) {
    return null
  }
  return (
    <>
      {oneTimePayments?.length && (
        <tr>
          <td className='font-weight-bold text-center' colSpan='13'>
            One Time Payments
          </td>
        </tr>
      )}
      {oneTimePayments?.map((otp, index) => (
        <tr key={otp.id}>
          <td>{index + 1}</td>
          <td width='20%'>{otp?.title}</td>
          <td colSpan={5} />
          <td>{convertSessionDate(otp?.date)}</td>
          <td />
          <td width='15%'>
            <div
              style={{
                color: otp?.paid ? 'green' : 'orangered',
              }}
              className='text-nowrap'>
              $ {otp?.amount?.toFixed(2)}
            </div>
          </td>
          <td colSpan={3} />
          <td className='text-nowrap'>$ {otp?.amount?.toFixed(2)}</td>
        </tr>
      ))}
    </>
  )
}

export default EnterpriseOneTimePayments
