import React, { useEffect, useState } from 'react'
import { useErrorService } from '@abroad/components'
import CONSTANTS from '../../utils/constants'
import UpsertAttachKeyResources from '../common/UpsertAttachKeyResources'
import API from '../../utils/API'

const AttachKeyResources = ({ keySkills, setKeySkills, disabled }) => {
  const [searchKeySkill, setSearchKeySkill] = useState('')
  const [inputFocusOfKeySkill, setInputFocusOfKeySkill] = useState(false)
  const [isSearchKeySkill, setIsSearchKeySkill] = useState(false)
  const [searchKeySkillData, setSearchKeySkillData] = useState([])
  const [keySkillSearchData, setKeySkillSearchData] = useState([])
  const Error = useErrorService()
  useEffect(() => {
    const getSearchData = () => {
      setIsSearchKeySkill(true)
      const data = keySkillSearchData.filter((data) =>
        data?.title?.toLowerCase()?.includes(searchKeySkill?.toLowerCase()),
      )

      setSearchKeySkillData(data)
      setIsSearchKeySkill(false)
    }
    getSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeySkill, keySkillSearchData])

  useEffect(() => {
    const getKeySkillData = async () => {
      try {
        const { data } = await API.AdminAPI.common.getKeySkillSearchPdf()
        if (data) {
          setKeySkillSearchData(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (!disabled) getKeySkillData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UpsertAttachKeyResources
      searchKeySkill={searchKeySkill}
      setSearchKeySkill={setSearchKeySkill}
      searchKeySkillData={searchKeySkillData}
      isSearchKeySkill={isSearchKeySkill}
      inputFocusOfKeySkill={inputFocusOfKeySkill}
      setInputFocusOfKeySkill={setInputFocusOfKeySkill}
      keySkills={keySkills}
      setKeySkills={setKeySkills}
      maxLimit={CONSTANTS.maxLimitForKeySkill}
      disabled={disabled}
    />
  )
}

export default AttachKeyResources
