import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { SelectionField as RBFormSelectionField } from '../common'
import { ListingFiltersContext } from '../../utils/contexts'

const OrganizationFilterForm = React.memo(
  ({
    size,
    globalFilter,
    setGlobalFilter,
    options,
    name,
    optionplaceholder,
    className = '',
  }) => {
    const [value, setValue] = useState(globalFilter[name])
    const { setFilters } = useContext(ListingFiltersContext)
    return (
      <>
        <RBFormSelectionField
          name='filterByOrganizationsType'
          options={options}
          className={classNames(
            'pointer-cursor user-input org-filters',
            className,
          )}
          optionplaceholder={optionplaceholder}
          size={size ? size : 'md'}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            setGlobalFilter({
              ...globalFilter,
              [name]: parseInt(e.target.value),
            })
            setFilters((prev) => ({
              ...prev,
              [name]: parseInt(e.target.value),
            }))
          }}
          isUseFormikSelectionField={false}
          getKeyAsOption={true}
        />
      </>
    )
  },
)

OrganizationFilterForm.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default OrganizationFilterForm
