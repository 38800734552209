import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '@abroad/components'

const TargetInfoModal = ({ showModal, onHide }) => {
  return (
    <Modal
      show={showModal}
      className='border-modal custom__modal'
      size='lg'
      onHide={onHide}
      scrollable={true}
      centered>
      <Modal.Body>{/* Image guide */}</Modal.Body>
      <Modal.Footer>
        <div className='text-right'>
          <Button
            variant='none'
            size='md'
            className='border-radius-3 line-height-normal mr-3 secondary-popup-button'
            onClick={onHide}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default TargetInfoModal
