import React, { useState } from 'react'
import classNames from 'classnames'
import { Form, Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Button, useErrorService } from '@abroad/components'
import { SelectionField, useInput } from '../common'
import API from '../../utils/API'

const isValidCoachRate = (value) =>
  value !== '' && value <= 1000 && value >= 0 && Number.isInteger(Number(value))
const defaultValue = '0'

const AddGrowthPlanModal = ({
  coaches,
  showModal,
  setShowModal,
  setGrowthPlanDetails,
}) => {
  const {
    value: coachRate,
    hasError: coachRateHasError,
    valueChangeHandler: coachRateChangeHandler,
    inputBlurHandler: coachRateBlurHandler,
    reset: resetCoachRate,
  } = useInput(isValidCoachRate, defaultValue)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [coachId, setCoachId] = useState('')
  const [coachHasError, setCoachHasError] = useState(false)
  const Error = useErrorService()
  const { clientId } = useParams()

  const onHide = () => {
    setShowModal(false)
    resetCoachRate(defaultValue)
    setCoachId('')
  }

  const addGrowthPlanHandler = async () => {
    if (!coachId) setCoachHasError(true)
    if (coachRateHasError || !coachId) return
    setIsSubmitting(true)
    try {
      const { data } = await API.AdminAPI.coachPlan.createGrowthPlan(
        { coachId, hourlyRate: parseInt(coachRate) },
        clientId,
      )
      if (data) {
        setGrowthPlanDetails((prev) => ({
          isGrowthPlanExists: true,
        }))
        setIsSubmitting(false)
        setShowModal(false)
        resetCoachRate(defaultValue)
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  if (!showModal) return null

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      className='custom-modal normal-padding-modal'
      centered>
      <Modal.Header>
        <h3 className='s2 text-center mb-0'>Add Growth Plan</h3>
      </Modal.Header>
      <Modal.Body className='profile'>
        <Form.Group>
          <Form.Label>COACH</Form.Label>
          <SelectionField
            name='coachId'
            size='lg'
            options={coaches?.map((coach) => ({
              label: `${coach?.fullName} ${
                coach?.isStaffCoach ? '(Staff Coach)' : ''
              }`,
              value: coach?._id,
            }))}
            getValueAsOption={true}
            optionplaceholder='Select Coach'
            sm='10'
            className={classNames('mb-3', {
              'bg-white cursor-pointer': !isSubmitting,
              'disabled-input': isSubmitting,
              'is-invalid': coachHasError,
            })}
            isUseFormikSelectionField={false}
            value={coachId}
            onChange={(e) => {
              setCoachHasError(false)
              setCoachId(e.target.value)
            }}
            disabled={isSubmitting}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>HOURLY RATE PAID TO COACH (USD)</Form.Label>
          <Form.Control
            name='coachRate'
            type='number'
            size='lg'
            placeholder='Hourly Rate'
            value={coachRate}
            onChange={coachRateChangeHandler}
            onBlur={coachRateBlurHandler}
            className='s6 border-radius-3'
            isInvalid={coachRateHasError}
            disabled={isSubmitting}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <div className='text-right'>
          <Button
            variant='saffron'
            className='line-height-normal'
            disabled={isSubmitting}
            isLoading={isSubmitting}
            size='md'
            onClick={addGrowthPlanHandler}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AddGrowthPlanModal
