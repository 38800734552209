import React from 'react'
import { useFormikContext } from 'formik'
import { Checkbox } from '../form'

const AttendanceCheckbox = ({ index, checkboxName, member, arrayHelpers }) => {
  const { values } = useFormikContext()
  return (
    <Checkbox
      name={`${checkboxName}.${index}`}
      type='checkbox'
      label={member?.label}
      labelProps={{
        className: 'cursor-pointer w-15 text-left word-breakall',
      }}
      value={member}
      checked={values?.[checkboxName]?.includes(member?.value)}
      key={member?.value}
      onChange={(e) => {
        if (e.target.checked) {
          arrayHelpers.push(member?.value)
        } else {
          const idx = values?.[checkboxName]?.indexOf(member?.value)
          arrayHelpers.remove(idx)
        }
      }}
      formGroupProps={{
        className: 'd-flex',
      }}
      leftSideLabel={true}
      className='pl-0'
    />
  )
}

export default AttendanceCheckbox
