import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CKEditor from '../editor'

const CkForCategoriesLogs = ({
  answers,
  question,
  qkey,
  handleSaveLog,
  disabled,
}) => {
  const { clientId, growthSessionId } = useParams()
  const logs = localStorage.getItem(`logs-${clientId}`)
  const parseValue = JSON.parse(logs)
  const prevValue =
    parseValue?.clientId === clientId &&
    parseValue?.growthSessionId === growthSessionId
      ? parseValue?.answers?.[qkey]
      : ''
  const [saveLog, setSaveLog] = useState('')
  const [isInitiallyLoad, setIsInitiallyLoad] = useState(true)
  const [isEditorReady, setIsEditorReady] = useState(false)
  let delayAutoSave

  useEffect(() => {
    if (isEditorReady) {
      if (prevValue) {
        setSaveLog(prevValue || answers || '')
      } else {
        setSaveLog(answers || '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditorReady])

  useEffect(() => {
    if (!isEditorReady || isInitiallyLoad) return
    // eslint-disable-next-line react-hooks/exhaustive-deps
    delayAutoSave = setTimeout(() => {
      handleSaveLog({ [qkey]: saveLog })
    }, 5000)

    return () => clearTimeout(delayAutoSave)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveLog])

  return (
    <>
      {question && <p className='py-3 s6 mb-0'>{question}</p>}
      <CKEditor
        data={saveLog}
        onReady={(editor) => {
          setIsEditorReady(true)
        }}
        onFocus={() => {
          setIsInitiallyLoad(false)
        }}
        onChange={(event, editor) => {
          setSaveLog(editor.getData())
        }}
        onBlur={(event, editor) => {
          clearTimeout(delayAutoSave)
          handleSaveLog({ [qkey]: editor.getData() })
        }}
        disabled={disabled}
      />
    </>
  )
}

export default CkForCategoriesLogs
