import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ModelContext } from '@abroad/components'
import CKEditor from '../editor'

const InsightModel = () => {
  const { show, onHide, data } = useContext(ModelContext)
  const [isEditorReady, setIsEditorReady] = useState(false)
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (isEditorReady) {
      setDescription(data?.description ? data.description : '')
    }
  }, [data, isEditorReady])

  return (
    <Modal
      show={show}
      size='lg'
      onHide={onHide}
      aria-labelledby='Insight'
      centered>
      <Modal.Body className='p-4'>
        <div className='pb-3'>
          <span className='text-uppercase font-montserrat font-16 font-bold leading-5 letter-spacing-02 text-saffron-700'>
            Insight
          </span>
        </div>
        <CKEditor
          data={description}
          disabled='true'
          onReady={(editor) => {
            setIsEditorReady(true)
          }}
        />
      </Modal.Body>
    </Modal>
  )
}

export default InsightModel
