import React, { useEffect, useState } from 'react'
import {
  useParams,
  useLocation,
  useHistory,
  Link,
  NavLink,
} from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { InfoIcon, useErrorService } from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import { useGroupName } from '../components'
import API from '../utils/API'
import i18n from '../utils/i18n'

const TeamDateRangeAssessmentResult = () => {
  let { search } = useLocation()
  const { teamId } = useParams()
  let history = useHistory()
  const Error = useErrorService()
  const [survey, setSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const query = new URLSearchParams(search)
  const year = query.get('year')
  const quarter = query.get('quarter')
  const startDate = query.get('startDate')
  const endDate = query.get('endDate')
  const teamName = useGroupName(teamId)

  useEffect(() => {
    const getTeamSurveyByTeam = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getSurveyDateByTeam(
          teamId,
          search,
        )
        setSurvey(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (((year && quarter) || (startDate && endDate)) && teamId) {
      getTeamSurveyByTeam()
    } else {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${
          teamName ? teamName : 'Group'
        } Assessment Result | Abroad`}</title>
        <meta
          name='title'
          content={`${
            teamName ? teamName : 'Group'
          } Assessment Result | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${
            teamName ? teamName : 'Group'
          } Assessment Result | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${
            teamName ? teamName : 'Group'
          } Assessment Result | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
      </Helmet>
      <main>
        <Result
          survey={survey}
          isLoading={isLoading}
          i18n={i18n}
          navigateToSurveyCategory={(subcategory) => {
            return (
              <Link to={`/survey/category/${subcategory}`}>
                <InfoIcon />
              </Link>
            )
          }}
          headerText={<Trans>Group Awareness Assessment</Trans>}
          subHeaderText={<Trans>GROUPS COMBINED RESULTS</Trans>}
          subHeaderDescription={
            <Trans>
              Below are the aggregate results for how your Group rated your
              organization and themselves during the specified time period.
            </Trans>
          }
          showBreakthroughProgramInfo={false}
          onGoBack={() => history.goBack()}
          showGoBackButton={false}
          API={API}
          NavLink={NavLink}
        />
      </main>
    </>
  )
}

export default withTranslation('translation')(TeamDateRangeAssessmentResult)
