import { useState } from 'react'

const useToSetCharsLimit = () => {
  const [totalChars, setTotalChars] = useState(0)
  const updateCharsLength = (newChars) => {
    setTotalChars(newChars)
  }

  return { totalChars, updateCharsLength }
}

export default useToSetCharsLimit
