import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Container } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import SOWGroupList from './SOWGroupList'
import { IndeterminateCheckbox, Table, TableInstance } from '../table'
import { StaffCoachIcon } from '../assetsComponents'
import { ClearFilters, FilterSelection } from '../common'
import UserSearch from '../company/UserSearch'
import CONSTANTS from '../../utils/constants'
import API from '../../utils/API'
import { OrgEcoIdsContext } from '../../utils/contexts'

const intialGlobalFilter = {
  search: '',
  filterByPL: null,
  filterByCoach: null,
}

const SOWUsersTable = ({ setSOWDetails }) => {
  const hasMore = useRef(false)
  const { setIds, isLoadNewData, setSelectedSOWUsers, setIsLoadNewData } =
    useContext(OrgEcoIdsContext)
  const [usersList, setUsersList] = useState([])
  const [coaches, setCoaches] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [reqData, setReqData] = useState(null)
  const Error = useErrorService()
  const { sowId, ecoSystemName } = useParams()

  const getUsersData = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.common.getUsersInSOW(sowId, reqData)
      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setUsersList((prev) => [...prev, ...data])
      } else {
        setUsersList(data)
      }
      setSOWDetails((prev) => ({
        ...prev,
        isMembersEmpty: data?.length === 0 && usersList?.length === 0,
      }))
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (isLoadNewData) {
      setTimeout(() => {
        setIsLoadNewData(false)
      }, 500)
    }
  }, [isLoadNewData, setIsLoadNewData])

  useEffect(() => {
    const getCoachData = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getSOWCoaches(sowId)
        if (data) {
          setCoaches(
            data?.map((coach) => ({
              key: coach?._id,
              value: coach?.fullName,
            })),
          )
        }
        setIsFetching(false)
      } catch (e) {
        Error.showError(e)
        setIsFetching(false)
      }
    }
    if (sowId) getCoachData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowId])

  useEffect(() => {
    if (reqData) {
      getUsersData(reqData)
    }
  }, [getUsersData, reqData, isLoadNewData])

  const fetchData = useCallback(
    ({ search, filterByPL, filterByCoach, sortBy, page, limit }) => {
      if (page === 1) {
        setUsersList([])
      }
      const req = {
        limit,
        page: page,
        ...(search && {
          search: search,
        }),
        ...(filterByPL && {
          participantLevel: filterByPL,
        }),
        ...(filterByCoach && {
          coachId: filterByCoach,
        }),
        sort: !sortBy[0].desc ? 1 : -1,
      }
      setReqData(req)
    },
    [],
  )

  const columns = React.useMemo(
    () => [
      {
        id: 'username',
        Header: 'Name',
        width: '16%',
        accessor: (properties) => properties.fullName,
        Cell: ({ cell }) => (
          <span className='word-breakall'>
            <Link
              to={`/coach/clients/${cell?.row?.original?._id}`}
              className='text-dark-900'>
              {cell.value}
            </Link>
          </span>
        ),
        //disableSortBy: true,
      },
      {
        id: 'DEPARTMENT',
        Header: 'DEPARTMENT',
        width: '16%',
        accessor: (properties) => properties.companyTeamName,
        Cell: ({ cell }) => <span>{cell?.value}</span>,
        dataProps: {
          className: ecoSystemName ? 'd-none p-0' : '',
        },
        headerProps: {
          className: ecoSystemName ? 'd-none p-0' : '',
        },
        disableSortBy: true,
      },
      {
        id: 'PARTICIPANT LEVEL',
        Header: 'PARTICIPANT LEVEL',
        width: '16%',
        accessor: (properties) => properties?.profile?.roleInCompany,
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value ? (
              <span>
                {
                  CONSTANTS.PARTICIPANT_LEVELS.find(
                    (e) => e.value === cell?.value,
                  )?.label
                }
              </span>
            ) : (
              ''
            )}
          </React.Fragment>
        ),
        disableSortBy: true,
      },
      {
        id: 'COACH',
        Header: 'COACH',
        width: '16%',
        accessor: (properties) => properties?.sowCoaches,
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.map((coach, index) => (
              <React.Fragment key={index}>
                <Link to={`/admin/coaches/${coach?._id}`} target='_blank'>
                  {coach?.fullName}
                  {coach?.isStaffCoach ? (
                    <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                  ) : null}
                </Link>
                <br />
              </React.Fragment>
            ))}
          </React.Fragment>
        ),
        disableSortBy: true,
      },
      {
        id: 'GROUP',
        Header: 'GROUP',
        width: '16%',
        accessor: (properties) => properties?.sowTeams,
        Cell: ({ cell }) => <SOWGroupList sowTeams={cell?.value} />,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const selectionHook = useCallback((hooks) => {
    hooks.visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: 'selection',
        width: '4%',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({
          getToggleAllRowsSelectedProps,
          toggleRowSelected,
          ...rest
        }) => {
          const rowProps = getToggleAllRowsSelectedProps()
          rowProps.onChange = (e) => {
            rest.rows.forEach((row) => {
              if (!rest.isAllPageRowsSelected) {
                toggleRowSelected(row.id, true)
              } else {
                toggleRowSelected(row.id, false)
              }
            })
          }
          return (
            <div>
              <IndeterminateCheckbox
                _id={'header'}
                {...rowProps}
                classes='header-checkbox'
              />
            </div>
          )
        },
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => {
          return (
            <div>
              <IndeterminateCheckbox
                disabled={false}
                _id={row.id}
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          )
        },
      },
      ...columns,
    ])
  }, [])

  const selectedRow = (selectedRow) => {
    const selectedIds = selectedRow.map((row) => row?._id)
    setIds(selectedIds)
    if (selectedIds?.length > 0) {
      const users = selectedRow?.map((user) => ({
        _id: user?._id,
        fullName: user?.fullName,
        sowCoaches: user?.sowCoaches,
      }))
      setSelectedSOWUsers(users)
    } else {
      setSelectedSOWUsers([])
    }
  }

  return (
    <TableInstance
      columns={columns}
      data={usersList}
      initialState={{
        globalFilter: intialGlobalFilter,
        sortBy: [{ id: 'username', desc: false }],
      }}
      autoResetSelectedRows={false}
      tableHooks={[selectionHook]}>
      {({
        preGlobalFilteredRows,
        state: { globalFilter, ...other },
        setGlobalFilter,
        ...rest
      }) => (
        <Container className='px-0' fluid>
          <div className='d-flex mb-2-5 sow-user-filters'>
            <UserSearch
              {...{
                globalFilter,
                setGlobalFilter,
              }}
            />
            <FilterSelection
              {...{
                globalFilter,
                setGlobalFilter,
              }}
              name='filterByPL'
              inputClasses='org-users-filter mx-4 border-radius-5'
              placeholder='Level Filter'
              options={CONSTANTS.PARTICIPANT_LEVELS.map((pl) => ({
                key: pl.value,
                value: pl.label,
              }))}
              size='sm'
            />
            <FilterSelection
              {...{
                globalFilter,
                setGlobalFilter,
              }}
              name='filterByCoach'
              inputClasses='org-users-filter mr-4 border-radius-5'
              placeholder='Coach Filter'
              options={coaches}
              size='sm'
              disabled={isFetching}
            />
            {((globalFilter?.search !== '' &&
              globalFilter?.search !== undefined) ||
              globalFilter?.filterByPL !== null ||
              globalFilter?.filterByCoach !== null) && (
              <ClearFilters
                wrapperClasses='d-flex align-items-center'
                onClick={() => setGlobalFilter(intialGlobalFilter)}
                disabled={isLoading}
              />
            )}
          </div>
          <Table
            ref={hasMore}
            emptyTableMessage='No user found.'
            tableInstance={{
              preGlobalFilteredRows,
              state: { globalFilter, ...other },
              setGlobalFilter,
              ...rest,
            }}
            isLoading={isLoading}
            fetchData={fetchData}
            selectedRow={selectedRow}
            totalNumberOfColumns='6'
          />
        </Container>
      )}
    </TableInstance>
  )
}

export default SOWUsersTable
