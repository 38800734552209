import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'
import {
  Button,
  useErrorService,
  useNotificationService,
  FeatherLoader,
} from '@abroad/components'
import API from '../../utils/API'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { formatDate } from '../../utils/date'
import { DeleteConfirmationPrompt } from '../common'

export const TeamReports = ({
  user,
  isTeamCoach,
  isArchived = false,
  isSOWGroup = false,
}) => {
  const [isUploadImpactReportLoading, setIsUploadImpactReportLoading] =
    useState(false)
  const [isUploadCoachReportLoading, setIsUploadCoachReportLoading] =
    useState(false)
  const [fetchDocumentList, setFetchDocumentList] = useState(true)
  const [documentList, setDocumentList] = useState([])
  const [isReportDelete, setIsReportDelete] = useState(false)
  const [reportId, setReportId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { teamId } = useParams()
  const impactInputRef = useRef(null)
  const coachReportInputRef = useRef(null)

  useEffect(() => {
    const getDocList = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getDocumentList(teamId)
        if (data) {
          setDocumentList(data)
          setFetchDocumentList(false)
        }
      } catch (e) {
        Error.showError(e)
        setFetchDocumentList(false)
      }
    }
    if (fetchDocumentList) getDocList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDocumentList])

  const getImpactReport = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const impactReportFile = files[0]
      addReport(impactReportFile)
    }
  }

  const getCoachReport = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const impactReportFile = files[0]
      addCoachReport(impactReportFile)
    }
  }

  const addReport = async (imapactReportFileName) => {
    if (isSOWGroup) return
    const contentType = imapactReportFileName.name
      .substr(imapactReportFileName.name.lastIndexOf('.') + 1)
      .toUpperCase()

    setIsUploadImpactReportLoading(true)
    try {
      const { data } = await API.AdminAPI.teams.getSignUrlForUploadImpactReport(
        contentType,
        teamId,
      )
      if (data) {
        let uploadFile = await axios.put(data.url.url, imapactReportFileName)

        if (uploadFile) {
          const { data: reportData } = await API.AdminAPI.teams.addImpactReport(
            teamId,
            {
              fileName: data.fileName,
            },
          )
          if (reportData) {
            setIsUploadImpactReportLoading(false)
            Notification.showNotification(
              'Impact Report has been uploaded.',
              'success',
            )
            impactInputRef.current.value = ''
            setFetchDocumentList(true)
          }
        }
      }
    } catch (e) {
      setIsUploadImpactReportLoading(false)
      impactInputRef.current.value = ''
      Error.showError(e)
    }
  }

  const addCoachReport = async (coachReportFileName) => {
    if (isSOWGroup) return
    const contentType = coachReportFileName.name
      .substr(coachReportFileName.name.lastIndexOf('.') + 1)
      .toUpperCase()

    setIsUploadCoachReportLoading(true)
    try {
      const { data } = await API.AdminAPI.teams.getSignUrlForUploadCoachReport(
        contentType,
        teamId,
      )
      if (data) {
        let uploadFile = await axios.put(data.url.url, coachReportFileName)

        if (uploadFile) {
          const { data: reportData } = await API.AdminAPI.teams.addcoachReport(
            teamId,
            {
              fileName: data.fileName,
            },
          )
          if (reportData) {
            setIsUploadCoachReportLoading(false)
            Notification.showNotification(
              'Coaching Agreement has been uploaded.',
              'success',
            )
            coachReportInputRef.current.value = ''
            setFetchDocumentList(true)
          }
        }
      }
    } catch (e) {
      setIsUploadCoachReportLoading(false)
      coachReportInputRef.current.value = ''
      Error.showError(e)
    }
  }

  const handleDocDownload = async (docId, fileName, docType) => {
    const contentType = fileName.substr(fileName.indexOf('.') + 1).toUpperCase()

    try {
      const { data } = await API.AdminAPI.teams.downloadReport(
        docId,
        docType,
        fileName,
        contentType,
        teamId,
      )
      if (data) {
        window.open(data.url)
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const handleDocDelete = async () => {
    setIsReportDelete(true)
    try {
      const { data } = await API.AdminAPI.teams.deleteReport(reportId, teamId)
      if (data) {
        setShowModal(false)
        setIsReportDelete(false)
        Notification.showNotification('Document has been deleted.', 'success')
        setDocumentList((prev) =>
          prev.filter((report) => report?._id !== reportId),
        )
      }
    } catch (e) {
      setShowModal(false)
      setIsReportDelete(false)
      Error.showError(e)
    }
  }

  return (
    <section>
      <DeleteConfirmationPrompt
        showModal={showModal}
        setShowModal={setShowModal}
        isDeleteLoading={isReportDelete}
        handleDelete={handleDocDelete}
        message='This can not be reverted. Are you sure you want to delete this
    Report?'
      />
      <Row className='d-flex justify-content-between my-3'>
        <Col className='pl-lg-0'>
          <h3 className='font-montserrat font-semibold text-saffron-700 font-18'>
            REPORTS
          </h3>
        </Col>
        <Col className='d-flex justify-content-end pr-lg-1'>
          {user.isCoach && isTeamCoach && !isArchived && (
            <Button
              variant='dark'
              size='sm'
              onClick={() => impactInputRef.current.click()}
              disabled={isUploadImpactReportLoading || isSOWGroup}
              className={`btn-report mr-2 ${
                isSOWGroup ? 'cursor-disabled' : ''
              }`}>
              {isUploadImpactReportLoading
                ? 'Uploading...'
                : 'Upload Impact Report'}
            </Button>
          )}
          <Form.Control
            type='file'
            className='d-none'
            accept='.pdf,.docx'
            ref={impactInputRef}
            onChange={getImpactReport}
          />
          {user.isAdmin && !isArchived && (
            <Button
              variant='dark'
              size='sm'
              onClick={() => coachReportInputRef.current.click()}
              disabled={isUploadCoachReportLoading || isSOWGroup}
              className={`btn-report ${isSOWGroup ? 'cursor-disabled' : ''}`}>
              {isUploadCoachReportLoading
                ? 'Uploading...'
                : 'Upload Coaching Agreement'}
            </Button>
          )}
          <Form.Control
            type='file'
            className='d-none'
            accept='.pdf,.docx'
            ref={coachReportInputRef}
            onChange={getCoachReport}
          />
        </Col>
      </Row>
      <Row>
        <Col className='px-0'>
          {/* eslint-disable */}
          <Table hover responsive className='custom-table'>
            <thead>
              <tr>
                <th className={isArchived ? 'w-6/12' : ''}>Type</th>
                <th className={isArchived ? 'w-6/12' : ''}>Date</th>
                {!isArchived && <th className='text-center'>Action</th>}
              </tr>
            </thead>
            <tbody>
              {fetchDocumentList && (
                <tr>
                  <td colSpan='4' className='text-center border-0'>
                    <FeatherLoader />
                  </td>
                </tr>
              )}
              {/* eslint-disable */}
              {documentList.length > 0 &&
                !fetchDocumentList &&
                documentList.map((doc) => (
                  <tr key={doc._id}>
                    {doc.type === 3 ? (
                      <>
                        <td>
                          <a
                            onClick={() =>
                              handleDocDownload(doc._id, doc.fileName, doc.type)
                            }
                            className='text-dark-600 under-line my-2 cursor-pointer'>
                            Impact Report
                          </a>
                        </td>
                        <td>{formatDate(doc.uploadedAt)}</td>
                        {!isArchived && (
                          <td className='text-center'>
                            <a
                              onClick={() => {
                                setReportId(doc._id)
                                setShowModal(true)
                              }}
                              className='text-red-600 my-2 ml-2 cursor-pointer'>
                              Delete
                            </a>{' '}
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        <td>
                          <a
                            onClick={() =>
                              handleDocDownload(doc._id, doc.fileName, doc.type)
                            }
                            className='text-dark-600 under-line my-2 cursor-pointer'>
                            Coaching Agreement
                          </a>
                        </td>
                        <td>{formatDate(doc.uploadedAt)}</td>
                        {!isArchived && (
                          <td className='text-center'>
                            {user.isAdmin ||
                            (user._id === doc?.uploadedBy && doc.type !== 1) ? (
                              <a
                                onClick={() => {
                                  setReportId(doc._id)
                                  setShowModal(true)
                                }}
                                className='text-red-600 under-line my-2 ml-2 cursor-pointer'>
                                Delete
                              </a>
                            ) : (
                              <p className='mb-0'>-</p>
                            )}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
              {documentList.length === 0 && !fetchDocumentList && (
                <tr>
                  <td colSpan='5' className='text-center'>
                    No Report.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* eslint-enable */}
        </Col>
      </Row>
    </section>
  )
}
