import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ABSpinner, useErrorService } from '@abroad/components'
import {
  EditableUsersList,
  SOWUsersTable,
  NoUsersOrSOWsText,
  SOWRosterAndAddUserButtons,
} from '../components'
import API from '../utils/API'
import { OrgEcoIdsContext } from '../utils/contexts'

const SOWUsers = ({ type, orgEcoId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showUploadRoster, setShowUploadRoster] = useState(false)
  const [sowDetails, setSOWDetails] = useState([])
  const {
    ids,
    isLoadNewData,
    setIsLoadNewData,
    isShowAddBtn,
    setIsShowAddBtn,
    setSelectedSOWUsers,
  } = useContext(OrgEcoIdsContext)
  const { sowId, ecoSystemName, companyName } = useParams()
  const url = `${process.env.REACT_APP_DOMAIN}/admin/${type}/${
    companyName ? companyName : ecoSystemName
  }/sow/${sowId}/users`
  const Error = useErrorService()

  useEffect(() => {
    const getSOWDetails = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.companyList.getSpecificDetailsOfSOW(
          {
            fields: ['planName'],
          },
          sowId,
        )
        if (data) {
          setIsShowAddBtn({
            hideBtn: data?.isMembersEmpty,
            showTable: data?.isMembersEmpty,
          })
          setShowUploadRoster(data?.isMembersEmpty)
          if (data?.isMembersEmpty) {
            setSelectedSOWUsers([])
          }
          setIsLoading(false)
          setIsLoadNewData(false)
          setSOWDetails(data)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (sowId && isLoading) getSOWDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowId, isLoading])

  useEffect(() => {
    if (isLoadNewData) {
      setIsLoading(true)
    }
  }, [isLoadNewData])

  const addUsersInSOW = async () => {
    if (ids?.length === 0) return
    setIsSubmitting(true)
    try {
      const { status } = await API.AdminAPI.companyList.addUsersInSOW(sowId, {
        ids: ids,
      })
      if (status) {
        setIsShowAddBtn({
          hideBtn: false,
          showTable: false,
        })
        setIsSubmitting(false)
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  const renderPageHelmet = () => {
    return (
      <Helmet>
        <title>{`${sowDetails?.planName}'s Users | Abroad`}</title>
        <meta
          name='title'
          content={`${sowDetails?.planName}'s Users | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${sowDetails?.planName}'s Users | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${sowDetails?.planName}'s Users | Abroad`}></meta>
        <link rel='canonical' href={url}></link>
        <meta property='og:url' content={url}></meta>
        <meta property='twitter:url' content={url}></meta>
      </Helmet>
    )
  }

  if (isLoading) {
    return <ABSpinner />
  }

  if (showUploadRoster && !ecoSystemName) {
    return (
      <React.Fragment>
        {renderPageHelmet()}
        <NoUsersOrSOWsText renderOnSOWPage={true} />
        <SOWRosterAndAddUserButtons
          orgEcoId={orgEcoId}
          hideUploadRoster={() => setShowUploadRoster(false)}
        />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderPageHelmet()}
      {!isShowAddBtn?.showTable && (
        <SOWUsersTable setSOWDetails={setSOWDetails} />
      )}
      {isShowAddBtn?.showTable && (
        <React.Fragment>
          {sowDetails?.isMembersEmpty && !ecoSystemName && (
            <p className='s3 font-semibold no-users'>
              There are currently no users in this SOW. Please select the users
              you would like <br /> to add from the roster, then move them into
              this SOW using the actions panel.
            </p>
          )}
          <EditableUsersList
            showAddUserToBtn={true}
            orgEcoId={orgEcoId}
            type={companyName ? 'company' : 'affiliate'}
            buttonText='ADD USERS TO SOW'
            isSubmitting={isSubmitting}
            onBtnClickHandler={addUsersInSOW}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default SOWUsers
