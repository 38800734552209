import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Form, Row } from 'react-bootstrap'
import sanitizeHtml from 'sanitize-html'
import { useErrorService } from '@abroad/components'
import ReturnLink from './ReturnLink'
import GoToNextStepBtn from './GoToNextStepBtn'
import AttachAudioResource from './AttachAudioResource'
import CKEditor from '../editor'
import ShowCharsLimt from '../common/ShowCharsLimt'
import useToSetCharsLimit from '../common/hooks/useCountCharactersLimit'
import CONSTANTS from '../../utils/constants'
import API from '../../utils/API'

const GrowthSessionPractice = ({
  growthSessionData,
  nextStepHandler,
  prevStepHandler,
}) => {
  const { clientId } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [psp, setPSP] = useState('')
  const [isEditorReady, setIsEditorReady] = useState(false)
  const [saveResourcePdf, setSaveResourcePdf] = useState({
    mediaModules: [],
    medias: [],
  })
  const {
    totalChars: totalCharsInPSP,
    updateCharsLength: updateCharsLengthForPSP,
  } = useToSetCharsLimit()
  const [audioResource, setAudioResource] = useState({})
  const Error = useErrorService()

  useEffect(() => {
    if (isEditorReady) {
      setPSP(growthSessionData?.postSession || '')
    }
  }, [growthSessionData, isEditorReady])

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const params = {
      postSession: psp,
      mediaIds: audioResource?.mediaIds,
      moduleIds: audioResource?.moduleIds,
      keySkillIds: audioResource?.keySkillsIds,
    }
    try {
      const { data } = await API.CoachAPI.coachingSession.updateGrowthSession(
        clientId,
        params,
      )
      if (data) {
        setIsSubmitting(false)
        nextStepHandler({ ...saveResourcePdf, postSession: psp ? psp : '' })
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  return (
    <section className='explore-offerings d-flex flex-column session-practice-wrapper pb-3'>
      <Row className='profile'>
        <Col
          lg={2}
          md={12}
          sm={12}
          className='d-lg-flex align-items-lg-center justify-content-lg-end pr-lg-1'>
          <Form.Label>Post Session Practice (optional)</Form.Label>
        </Col>
        <Col lg={10} md={12} sm={12}>
          <CKEditor
            data={psp}
            placeholder='Post Session practices are recommended, though not required for a
          growth plan. Feel free to leave notes here for your client to see, as
          well as recommend audio and written content.'
            onReady={(editor) => {
              setIsEditorReady(true)
            }}
            onChange={(event, editor) => {
              updateCharsLengthForPSP(
                sanitizeHtml(editor.getData(), {
                  allowedTags: [],
                  allowedAttributes: {},
                })?.length,
              )
              setPSP(editor.getData())
            }}
            disabled={isSubmitting}
          />
        </Col>
      </Row>
      <ShowCharsLimt
        limit={CONSTANTS.charsLimitInPSP}
        enteredChars={totalCharsInPSP}
      />
      <AttachAudioResource
        growthPlanData={growthSessionData}
        setAudioResource={setAudioResource}
        setSaveResourcePdf={setSaveResourcePdf}
        disabled={isSubmitting}
      />
      <div className='d-flex justify-content-between mt-3 mb-5'>
        <ReturnLink
          prevStepHandler={prevStepHandler}
          isDisabled={isSubmitting}
        />
        <GoToNextStepBtn
          nextStepHandler={handleSubmit}
          isDisabled={
            isSubmitting || totalCharsInPSP > CONSTANTS.charsLimitInPSP
          }
          isLoading={isSubmitting}
        />
      </div>
    </section>
  )
}

export default GrowthSessionPractice
