import React from 'react'
import CategoriesNotes from './CategoriesNotes'

const ReviewAssessmentResults = ({
  logs,
  sessionLogs,
  saveLogHandler = () => {},
  disabled = false,
}) => {
  return (
    <div>
      <h3 className='mb-point-75 font-open-sans font-22 font-bold review-title'>
        Review Assessment Results (30 minutes)
      </h3>
      <p className='s6 review-description'>
        Starting with the lowest scores, we encourage you to ask the prompt
        questions for each category to uncover the mindsets and behaviors that
        are driving these results and to input key notes. In a typical growth
        plan session, coaches normally get through 3 to 7 categories.
      </p>
      <CategoriesNotes
        logs={logs}
        sessionLogs={sessionLogs}
        saveLogHandler={saveLogHandler}
        disabled={disabled}
      />
    </div>
  )
}

export default ReviewAssessmentResults
