import React from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AdditionalSettings = ({
  closeClickedHandler,
  links,
  otherLinks = () => {},
}) => {
  if (links.length === 0) return null

  return (
    <div className='additional-settings'>
      <Form.Label className='custom-mb-0-625 text-uppercase sidebar-label'>
        ADDITIONAL SETTINGS
      </Form.Label>
      <ul>
        {links.map((link) => (
          <li key={link.name}>
            <Link
              to={link.to}
              onClick={closeClickedHandler}
              className='under-line text-dark-900 font-12 font-normal leading-4 tracking-normal font-open-sans'>
              {link?.name}
            </Link>
          </li>
        ))}
        {otherLinks()}
      </ul>
    </div>
  )
}

export default AdditionalSettings
