import React, { useState } from 'react'
import { plans } from '@abroad/components'
import { Link } from 'react-router-dom'
import UserCompany from './UserCompany'
import ToggleAdminPrivileges from './ToggleAdminPrivileges'
import ToggleTeamPrivileges from './ToggleTeamPrivileges'
import ToggleEcosystemPrivileges from './ToggleEcosystemPrivileges'
import ToggleCoachPrivileges from './ToggleCoachPrivileges'
import UserPlanUpdate from './UserPlanUpdate'
import UserAffiliation from './UserAffiliation'
import { StaffCoachIcon } from '../assetsComponents'
import { formatDate } from '../../utils/date'

const UserRow = React.memo(({ user, index }) => {
  const [rowUserIsCoach, setRowUserIsCoach] = useState(user?.isCoach)
  const [rowUserIsAdmin, setRowUserIsAdmin] = useState(user?.isAdmin)
  const [plan, setPlan] = useState(user?.planCode)

  return (
    <tr>
      <td width='1%'>{index + 1}</td>
      <td width='22%' className='userinfo-fullname'>
        <div className='d-flex flex-column'>
          <Link
            to={`/admin/users/${user?._id}`}
            className='text-dark-600 under-line word-breakall'
            rel='noopener noreferrer'
            target='_blank'>
            {user.fname + ' ' + user.lname}
          </Link>
          <Link
            to={`/admin/users/${user?._id}`}
            className='text-dark-600 under-line word-breakall'
            rel='noopener noreferrer'
            target='_blank'>
            {user.email}
          </Link>
          <span>Status : In {user.workStatus}</span>
          <span className='d-flex align-items-center user-plan-section mb-1'>
            Plan:&nbsp;
            <UserPlanUpdate
              userId={user?._id}
              planCode={plan}
              onPlanChangeHandler={(newPlan, expiresOn) => {
                setPlan(newPlan)
              }}
              userEmail={user.email}
              isAdminOrCoach={rowUserIsAdmin || rowUserIsCoach}
              selectionClasses='black-input-border'
            />
          </span>
          <span>
            {rowUserIsCoach ? (
              <span className='font-normal'>Coach:&nbsp;User is a coach</span>
            ) : user?.coachIds && user?.coachIds?.length > 0 ? (
              <>
                <span className='font-normal'>Coach:</span>
                <br />
                <React.Fragment>
                  {user?.coachIds?.map((coach, index) => (
                    <React.Fragment key={index}>
                      <Link
                        to={`/admin/coaches/${coach?._id}`}
                        className='text-dark-600 under-line word-breakall mb-1'
                        rel='noopener noreferrer'
                        target='_blank'>
                        {coach?.fullName}
                        {coach?.isStaffCoach ? (
                          <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                        ) : null}
                      </Link>
                      <br />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              </>
            ) : (
              <span className='font-normal'>Coach:&nbsp;To be assigned</span>
            )}
          </span>
        </div>
      </td>
      <td width='17%' className='user-plan'>
        <div className='d-flex flex-column'>
          <span>User:</span>
          <span>{user?.staticCompany ? user?.staticCompany : '-'}</span>
          <span className='mt-2'>Abroad:</span>
          <UserCompany
            userId={user._id}
            email={user.email}
            workStatus={user?.workStatus}
            userComapany={user?.company}
            name='userCompany'
            className='pointer-cursor'
          />
        </div>
      </td>
      <td width='25%'>
        <UserAffiliation
          userId={user?._id}
          affiliation={user?.affiliation}
          email={user.email}
        />
      </td>
      <td width='10%'>{formatDate(user.createdAt)}</td>
      <td width='10%'>
        {user?.promoCode === null ? (
          <span className='word-breakall'>No Code Applied</span>
        ) : (
          <span className='word-breakall text-uppercase'>
            {user?.promoCode}
          </span>
        )}
      </td>
      <td width='5%'>
        {user?.surveys && user?.surveys?.length > 0 ? (
          user?.surveys?.map((surveyData, index) => (
            <span key={index + user?._id} className='my-3 word-breakall'>
              <Link
                to={`/survey/${surveyData?._id}/clients/${surveyData?.userId}`}
                className='text-dark-600 under-line'
                rel='noopener noreferrer'
                target='_blank'>
                {formatDate(surveyData?.createdAt)}
              </Link>
              <br />
            </span>
          ))
        ) : (
          <span className='word-breakall'>No Survey Taken</span>
        )}
      </td>
      <td width='10%'>
        <div className='d-flex form-group form-check pl-0'>
          <ToggleAdminPrivileges
            userId={user._id}
            isAdmin={user.isAdmin}
            index={index}
            changePrivileges={(isChecked) => {
              if (isChecked) {
                setPlan(plans.coaching.code)
              } else {
                setPlan(plans.free.code)
              }
              setRowUserIsAdmin(isChecked)
            }}
          />
        </div>
        <div className='d-flex form-group form-check pl-0'>
          <ToggleTeamPrivileges
            userId={user._id}
            workStatus={user?.workStatus}
            isCompanyAdmin={user.isCompanyAdmin}
            index={index}
          />
        </div>
        <div className='d-flex form-group form-check pl-0'>
          <ToggleEcosystemPrivileges
            userId={user._id}
            index={index}
            isEcosystemAdmin={user.isEcosystemAdmin}
          />
        </div>
        <div className='d-flex form-group form-check pl-0'>
          <ToggleCoachPrivileges
            userId={user._id}
            index={index}
            isCoach={user.isCoach}
            coachId={user.coachId}
            render={(isChecked) => {
              if (isChecked) {
                setPlan(plans.coaching.code)
              } else {
                setPlan(plans.free.code)
              }
              setRowUserIsCoach(isChecked)
            }}
          />
        </div>
      </td>
    </tr>
  )
})

export default UserRow
