import React, { useRef } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import RBDateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'

const DateRangePicker = React.forwardRef(
  (
    {
      onApply,
      parentEl,
      className,
      initialSettings = {},
      setOnHide = false,
      isUseKeyProp = false,
      key,
      disabled = false,
    },
    ref,
  ) => {
    const picker = useRef()
    const handleApply = (event, picker) => {
      picker.element.val(
        picker.startDate.format('MM/DD/YYYY') +
          ' - ' +
          picker.endDate.format('MM/DD/YYYY'),
      )
      onApply(picker.startDate, picker.endDate)
    }

    return (
      <RBDateRangePicker
        ref={(component) => {
          picker.current = component
          ref.current = component
        }}
        onApply={handleApply}
        onHide={setOnHide ? handleApply : null}
        initialSettings={{
          autoUpdateInput: false,
          startDate: moment(),
          endDate: moment(),
          maxDate: moment(),
          locale: {
            format: 'MM/DD/YYYY',
          },
          parentEl: parentEl ? `#${parentEl}` : '#p1',
          applyButtonClasses: 'btn-saffron font-montserrat',
          cancelButtonClasses: 'btn-black font-montserrat',
          autoApply: true,
          ...initialSettings,
        }}
        {...(isUseKeyProp && { key })}>
        <input
          autoComplete={'off'}
          placeholder='MM/DD/YYYY - MM/DD/YYYY'
          type='text'
          className={classnames(
            'form-control w-9/12 user-input',
            {
              'cursor-disabled': disabled,
            },
            className,
          )}
          {...(isUseKeyProp && { key })}
          disabled={disabled}
        />
      </RBDateRangePicker>
    )
  },
)

export default DateRangePicker
