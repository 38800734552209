import React from 'react'
import classNames from 'classnames'
import { Button as CommonButton } from '@abroad/components'

const Button = ({ text, url, buttonClasses, btnClickHandler, ...rest }) => {
  return (
    <CommonButton
      variant='gray'
      className={classNames(
        's8 line-height-normal border-radius-3',
        buttonClasses,
      )}
      size='md'
      onClick={btnClickHandler}
      {...rest}>
      {text}
    </CommonButton>
  )
}

export default Button
