import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useUserFirstName } from '../components'
import { ClientList } from '../components/coachClientList'

const CoachClientList = () => {
  const { coachId } = useParams()
  const coachFirstName = useUserFirstName(coachId)
  return (
    <>
      <Helmet>
        <title>{`${coachFirstName} Clients | Abroad`}</title>
        <meta
          name='title'
          content={`${coachFirstName} Clients | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${coachFirstName} Clients | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${coachFirstName} Clients | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/coaches/${coachId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/coaches/${coachId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/coaches/${coachId}`}></meta>
      </Helmet>
      <main className='pb-5'>
        <section className='pt-5'>
          <ClientList />
        </section>
      </main>
    </>
  )
}

export default CoachClientList
