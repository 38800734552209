import React, { useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Row, Tab, Nav } from 'react-bootstrap'
import classNames from 'classnames'
import { ABSpinner, ArrowWrapper } from '@abroad/components'
import CONSTANTS from '../../utils/constants'
import { ValuesAndLeadershipForm } from '../companyMetadata'

export default function ValuesAndPrinciples({
  companyName,
  tabsLength,
  id,
  isLoading,
}) {
  const tabItems = useRef(null)

  const headerInfo = {
    title: 'What is Values & Principles?',
    description: `Your company culture is your organizational operating system. It’s the set of shared assumptions, values, and beliefs that govern how people behave in organizations. These shared values and the leadership principles that support them have a strong influence on both your people and customers. According to PWC research, 67% of executives feel culture is more important than strategy or operations. For these reasons, your people leaders have taken the time to articulate your company's core values and the leadership principles required to strengthen and reinforce these values.`,
  }

  const dataArr = [
    {
      title: 'Abroad’s Cultural Value',
      description:
        CONSTANTS.COMPANY_METADATA.valuesAndPrinciples.culturalValueDescription,
      keyProp: 'value',
      inputLabel: 'Cultural Value',
      placeholder: 'Enter Cultural Value Here',
      maxCharactersAllow: '100',
      inputAsTextArea: false,
      rows: 1,
    },
    {
      title: `Abroad’s Leadership Principles`,
      description:
        CONSTANTS.COMPANY_METADATA.valuesAndPrinciples
          .leadershipPrincplesDescription,
      keyProp: 'principles',
      inputLabel: 'Leadership Principles',
      placeholder: 'Enter Leadership Principle Here',
      maxCharactersAllow: '100',
      inputAsTextArea: false,
      rows: 1,
      hasMultipleInput: true,
    },
    {
      title: `Abroad’s Description`,
      description:
        CONSTANTS.COMPANY_METADATA.valuesAndPrinciples.abroadDescription,
      keyProp: 'description',
      inputLabel: 'Description',
      placeholder: 'Enter description here',
      maxCharactersAllow: '1000',
      inputAsTextArea: true,
      rows: 10,
    },
    {
      title: `Abroad’s Example Behaviors`,
      description:
        CONSTANTS.COMPANY_METADATA.valuesAndPrinciples.behaviorsDescription,
      keyProp: 'behaviors',
      inputLabel: 'Example Behaviors',
      placeholder: 'Enter Example Behavior Here',
      maxCharactersAllow: '100',
      inputAsTextArea: false,
      rows: 1,
      hasMultipleInput: true,
    },
  ]
  if (isLoading) {
    return <ABSpinner />
  }
  return (
    <>
      <Helmet>
        <title>{`${companyName}'s Values & Principles | Abroad`}</title>
        <meta
          name='title'
          content={`${companyName}'s Values & Principles | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${companyName}'s Values & Principles | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${companyName}'s Values & Principles | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/values-and-principles`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/values-and-principles`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/values-and-principles`}></meta>
      </Helmet>
      <section>
        <Container className='mt-40 px-0' fluid>
          <Row>
            <Col>
              <h3 className='mb-point-75 font-open-sans font-bold font-24 leading-9 letter-spacing-minus-04'>
                {headerInfo.title}
              </h3>
              <p className='font-open-sans font-semibold font-14 leading-6 letter-spacing-minus-02 opacity-point-5 mb-0'>
                {headerInfo.description}
              </p>
            </Col>
          </Row>
          <Row>
            <Col id='numbers-tabs'>
              <Tab.Container defaultActiveKey='#1'>
                <div className='numbers-tabs border-bottom-none values-principles'>
                  <ArrowWrapper
                    parentRef={tabItems}
                    parentId='numbers-tabs'
                    childId='numbers-tab-items'>
                    <Nav
                      className='d-flex'
                      id='numbers-tab-items'
                      ref={tabItems}>
                      {[...Array(tabsLength)].map((values, index) => (
                        <Nav.Item key={index} className='d-flex'>
                          <Nav.Link
                            eventKey={`#${index + 1}`}
                            className={classNames('mb-0', {
                              'mr-0': tabsLength === index + 1,
                            })}>
                            {index + 1}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </ArrowWrapper>
                </div>
                <Tab.Content className='mt-4'>
                  {[...Array(tabsLength)].map((values, index) => (
                    <Tab.Pane eventKey={`#${index + 1}`} key={index}>
                      <ValuesAndLeadershipForm
                        valuesAndPrinciples={dataArr}
                        id={id}
                        tabIndex={index}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
