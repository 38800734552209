import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import { ABSpinner, Button, useErrorService } from '@abroad/components'
import LoadableCategories from './ViewCategories'
import AttachAudioResource from './AttachAudioResource'
import API from '../../utils/API'
import CONSTANTS from '../../utils/constants'

const ViewGrowthPlan = ({ growthSessionId, isEditable = false }) => {
  const [growthSessionData, setGrowthSessionData] = useState(null)
  const [reviewCall, setReviewCall] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [logs, setLogs] = useState({ loading: true, data: null })
  const { clientId } = useParams()
  const history = useHistory()
  const Error = useErrorService()

  useEffect(() => {
    setIsLoading(true)
    const getGrowthPlan = async () => {
      try {
        const { data } = await API.CoachAPI.coachingSession.getClientSession(
          growthSessionId,
          clientId,
        )
        if (data) {
          setGrowthSessionData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (growthSessionId) getGrowthPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [growthSessionId])

  useEffect(() => {
    const getSurveyLogs = async () => {
      setLogs({ loading: true, data: null })
      try {
        const { data } = await API.AdminAPI.common.getClientSurveyLogs(clientId)
        if (data) {
          setLogs({ loading: false, data: data?.logs })
          setReviewCall(data?.surveyLogs)
        }
      } catch (e) {
        setLogs({ loading: false, data: null })
        Error.showError(e)
      }
    }
    if (growthSessionId) getSurveyLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [growthSessionId])

  if (isLoading || logs?.loading) {
    return <ABSpinner />
  }

  return (
    <section className='mt-3 view-growth-plan'>
      <div className='d-flex'>
        <span className='s8 mr-3 growth-label align-self-center text-right'>
          LOGGED BY:{' '}
        </span>
        <span className='s6'>{growthSessionData?.coach?.fullName}</span>
      </div>
      <div className='d-flex'>
        <span className='s8 mr-3 growth-label align-self-center'>
          CALL OUTCOME:{' '}
        </span>
        <span className='s6'>
          {growthSessionData?.callOutcome
            ? CONSTANTS.callOutcomeObj[growthSessionData?.callOutcome]
            : '-'}
        </span>
      </div>
      {isEditable && (
        <Button
          variant='outline-dark'
          className='btn s8 text-uppercase border-radius-3 line-height-normal mt-3 outline-dark-btn'
          onClick={() => {
            history.push(
              `/coach/clients/${clientId}/growth-plan/${growthSessionId}`,
            )
          }}>
          Edit Growth Plan
        </Button>
      )}
      <div>
        <h3 className='font-open-sans font-22 font-bold review-title view-gp-subtitle'>
          Key Takeaways
        </h3>
        <div
          className='s5'
          dangerouslySetInnerHTML={{
            __html: growthSessionData?.coachNotes,
          }}
        />
        <h3 className='font-open-sans font-22 font-bold review-title view-gp-subtitle mb-3'>
          Category Notes
        </h3>
        <LoadableCategories
          logs={{ logs: logs?.data }}
          sessionLogs={{ answers: reviewCall || {} }}
          saveLogHandler={() => {}}
          disabled={true}
          isViewGPSection={true}
        />
        {growthSessionData?.postSession && (
          <>
            <h3 className='font-open-sans font-22 font-bold review-title view-gp-subtitle mb-3'>
              Post Session Practice
            </h3>
            <div
              className='s5'
              dangerouslySetInnerHTML={{
                __html: growthSessionData?.postSession,
              }}
            />
          </>
        )}
        <div
          className={classNames({
            'view-gp-subtitle':
              !growthSessionData?.postSession ||
              growthSessionData?.postSession === '',
            'my-3': growthSessionData?.postSession,
          })}>
          <AttachAudioResource
            growthPlanData={growthSessionData}
            setAudioResource={() => {}}
            setSaveResourcePdf={() => {}}
            disabled={true}
          />
        </div>
      </div>
    </section>
  )
}

export default ViewGrowthPlan
