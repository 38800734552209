import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { InputField } from '../form'
import { ShowCharsLimt } from '../common'
import { useToSetCharsLimit } from '../common/hooks'

const labelProps = { column: false, sm: '2' }

const CustomBody = ({
  maxCharsInBody,
  minCharsInBody,
  disabled,
  placeholder,
}) => {
  const [error, setError] = useState(null)
  const {
    totalChars: totalCharsInBodyText,
    updateCharsLength: updateCharsInBodyText,
  } = useToSetCharsLimit()
  const {
    values: { body },
    handleBlur,
    handleChange,
  } = useFormikContext()

  useEffect(() => {
    updateCharsInBodyText(body)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body])
  return (
    <>
      <InputField
        name='body'
        type='text'
        as='textarea'
        rows={2}
        labelProps={labelProps}
        placeholder={placeholder}
        size='lg'
        lg='12'
        label='Custom Body'
        displayFlex={false}
        formGroupProps={{ className: 'mb-0' }}
        onChange={(e) => {
          handleChange(e)
          setError(null)
        }}
        onBlur={(e) => {
          handleBlur(e)
          const headerError =
            body?.length > maxCharsInBody
              ? `Body must be less than ${maxCharsInBody} characters.`
              : body?.length < minCharsInBody && body?.length !== 0
              ? `Body must be at least ${minCharsInBody} characters long.`
              : null
          setError(headerError)
        }}
        className={classNames('resize-none border-radius-5', {
          'disabled-input': disabled,
        })}
        disabled={disabled}
      />
      <div
        className={classNames({
          'd-flex justify-content-between': error,
          'd-flex justify-content-end': !error,
        })}>
        {error && <span className='font-open-sans text-error'>{error}</span>}
        <ShowCharsLimt
          limit={maxCharsInBody}
          enteredChars={totalCharsInBodyText ? totalCharsInBodyText?.length : 0}
          wrapperClasses='mb-4'
        />
      </div>
    </>
  )
}

export default CustomBody
