import React from 'react'
import { useHistory } from 'react-router-dom'
import { FeatherLoader, useUserService } from '@abroad/components'
import TableBodyCell from './TableBodyCell'
import { getManageGroupURL, isSOWGroups } from '../../utils/utility'

/**
 * Table body component
 *
 * @param {*} getTableBodyProps               // react table body props
 * @param {*} rows                            // react table body props
 * @param {*} prepareRow                      // react table body props
 * @param {Function} renderRow                // React renderer prop to show row data
 */
const TableBody = React.memo(
  ({
    getTableBodyProps,
    columns,
    isLoading,
    rows,
    emptyTableMessage,
    prepareRow,
    renderRow,
    totalNumberOfColumns,
    isRenderOnCoachGroupTable,
    trClasses,
  }) => {
    const history = useHistory()
    const { user } = useUserService()
    return (
      <tbody {...getTableBodyProps()}>
        {rows.length === 0 && !isLoading && (
          <tr key={-2}>
            <td
              colSpan={
                totalNumberOfColumns ? totalNumberOfColumns : columns.length
              }
              className='text-center'>
              {emptyTableMessage}
            </td>
          </tr>
        )}
        {
          // Loop over the table rows
          rows.map((row, index) => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr
                key={index}
                {...row.getRowProps()}
                {...(isRenderOnCoachGroupTable && {
                  onClick: () => {
                    if (!isRenderOnCoachGroupTable) return
                    isSOWGroups(row.values.number?.groupType) && user?.isAdmin
                      ? window.open(
                          getManageGroupURL(row.values.number, user?.isAdmin),
                        )
                      : history.push(
                          getManageGroupURL(row.values.number, user?.isAdmin),
                        )
                  },
                })}
                className={trClasses}>
                {
                  // Loop over the rows cells
                  renderRow ? (
                    renderRow(row.cells)
                  ) : (
                    <>
                      {row.cells.map((cell, i) => (
                        <TableBodyCell key={i} cell={cell} index={i} />
                      ))}
                    </>
                  )
                }
              </tr>
            )
          })
        }
        {isLoading && (
          <tr key={-1}>
            <td
              colSpan={
                totalNumberOfColumns ? totalNumberOfColumns : columns.length
              }
              className='text-center'>
              <FeatherLoader />
            </td>
          </tr>
        )}
      </tbody>
    )
  },
)

export default TableBody
