import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const CheckSelectedCoachIsStaffCoach = ({
  coaches,
  setSelectedCoachIsStaffCoach,
}) => {
  const {
    values: { coachId },
    setFieldValue,
  } = useFormikContext()

  useEffect(() => {
    if (coachId) {
      const filteredCoach = coaches.find((coach) => coach?.value === coachId)

      if (filteredCoach?.isStaffCoach) {
        setSelectedCoachIsStaffCoach(true)

        setFieldValue('individualPricing.ic', '')
        setFieldValue('individualPricing.manager', '')
        setFieldValue('individualPricing.leader', '')
        setFieldValue('individualPricing.executive', '')
        setFieldValue('individualPricing.ceo', '')

        setFieldValue('groupPricing.ic', '')
        setFieldValue('groupPricing.manager', '')
        setFieldValue('groupPricing.leader', '')
        setFieldValue('groupPricing.executive', '')
        setFieldValue('groupPricing.ceo', '')
      } else {
        setSelectedCoachIsStaffCoach(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachId, coaches])

  return null
}

export default CheckSelectedCoachIsStaffCoach
