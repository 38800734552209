import React, { useEffect, useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import { Form as RBForm, Row, Col } from 'react-bootstrap'
import { string, object, array } from 'yup'
import classNames from 'classnames'
import {
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'

import { InputField } from '../form'
import CompanyMetadataOverlay from './CompanyMetadataOverlay'
import { CompanyMetadataContext } from '../../utils/contexts'
import API from '../../utils/API'
import BehaviorsPrinciplesField from './BehaviorsPrinciplesField'

const ValuesAndLeadershipForm = ({ valuesAndPrinciples, id, tabIndex }) => {
  const { data, setData } = useContext(CompanyMetadataContext)
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()

  const behaviorsArraySize = 5
  const principlesArraySize = 3

  const formInitialValues = {
    value: '',
    description: '',
    behaviors: [...Array(behaviorsArraySize)],
    principles: [...Array(principlesArraySize)],
  }
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const keyWrapper = 'valuesAndPrinciples'

  const validationSchema = object().shape({
    value: string()
      .required('This is required field, please add value.')
      .min(2, 'Must be at least 2 characters')
      .max(100, 'Must be at most 100 characters'),
    description: string()
      .required('This is required field, please add description.')
      .min(2, 'Must be at least 2 characters')
      .max(500, 'Must be at most 500 characters'),
    behaviors: array()
      .of(
        string()
          .required('This is required field, please add behaviors.')
          .min(2, 'Must be at least 2 characters')
          .max(100, 'Must be at most 100 characters'),
      )
      .test(
        'unique',
        (ctx) => {
          let arr = ctx.value
          let length = arr.filter((a) => a !== undefined).length
          let obj = {}
          if (length > 1)
            obj[0] =
              'This behaviors has already been added. Please add another behaviors.'
          if (Object.keys(obj).length === 0) return null
          else return obj
        },
        (values) => {
          return new Set(values).size === values.length
        },
      ),
    principles: array()
      .of(
        string()
          .required('This is required field, please add principles.')
          .min(2, 'Must be at least 2 characters')
          .max(100, 'Must be at most 100 characters'),
      )
      .test(
        'unique',
        (ctx) => {
          let arr = ctx.value
          let length = arr.filter((a) => a !== undefined).length
          let obj = {}
          if (length > 1)
            obj[0] =
              'This principles has already been added. Please add another principles.'
          if (Object.keys(obj).length === 0) return null
          else return obj
        },
        (values) => {
          return new Set(values).size === values.length
        },
      ),
  })

  useEffect(() => {
    if (data) {
      setInitialValues({
        value: data[tabIndex]?.value || '',
        description: data[tabIndex]?.description || '',
        behaviors: data[tabIndex]?.behaviors || [''],
        principles: data[tabIndex]?.principles || [''],
      })
    }
  }, [data, tabIndex])

  const updateMetadata = async (obj) => {
    try {
      setIsLoading(true)
      const { data } = await API.AdminAPI.companyList.updateCompanyMetdata(
        id,
        keyWrapper,
        obj,
      )
      if (data) {
        Notification.showNotification(data?.message, 'success')
      }
    } catch (e) {
      Error.showError(e)
    }
    setIsLoading(false)
  }

  const onSubmit = async (values) => {
    const newArr = data.map((object, index) => {
      if (index === tabIndex) {
        return values
      }
      return object
    })
    setData(newArr)

    const apiArray = newArr.reduce((prevValu, currentValue) => {
      if (currentValue.value !== '') {
        return [...prevValu, currentValue]
      } else return [...prevValu]
    }, [])

    try {
      await updateMetadata({
        [keyWrapper]: apiArray,
      })
    } catch (e) {
      Error.showError(e)
    }
  }

  return (
    <Formik
      displayName={`cultural-values`}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={onSubmit}>
      {({ values, handleSubmit, isValid, dirty }) => {
        return (
          <RBForm
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(e)
            }}
            as={Form}
            className='purpose-vision-form'>
            {valuesAndPrinciples.map((e, index) => (
              <Row className='custom-mt-2-6' key={index}>
                <Col className='col-12 col-lg-6'>
                  <Row>
                    <Col className='d-flex align-items-center mb-3'>
                      <h3 className='mb-0 mr-3 font-open-sans font-semibold font-20 leading-7-half letter-spacing-minus-02'>
                        {e.inputLabel}
                      </h3>
                      <CompanyMetadataOverlay toolTipText={e.tooltipText} />
                    </Col>
                  </Row>
                  {e.keyProp === 'behaviors' || e.keyProp === 'principles' ? (
                    <BehaviorsPrinciplesField
                      keyProp={e.keyProp}
                      maxInputAllow={
                        e.keyProp === 'behaviors'
                          ? behaviorsArraySize
                          : principlesArraySize
                      }
                      nameOfInput={e.keyProp}
                      placeholder={e.placeholder}
                      values={
                        e.keyProp === 'behaviors'
                          ? values.behaviors
                          : values.principles
                      }
                    />
                  ) : (
                    <InputField
                      className={classNames('mb-pv-form', {
                        'hide-scrollbar resize-none': e.inputAsTextArea,
                      })}
                      name={e.keyProp}
                      {...(e.inputAsTextArea
                        ? { as: 'textarea' }
                        : { type: 'text' })}
                      {...(e.inputAsTextArea && { rows: e.rows })}
                      size='lg'
                      placeholder={e.placeholder}
                      sm='12'
                      showErrorMessage={true}
                      hasErrorMessagePadding={false}
                    />
                  )}
                </Col>
                <Col className='col-12 col-lg-6'>
                  <div className='font-open-sans font-semibold font-20 leading-7-half letter-spacing-minus-02 mb-2'>
                    {e.title}
                  </div>
                  <div
                    className='font-open-sans font-semibold font-14 leading-6 letter-spacing-minus-02 opacity-point-5'
                    dangerouslySetInnerHTML={{ __html: e.description }}
                  />
                </Col>
              </Row>
            ))}
            <Button
              variant='gray'
              isLoading={isLoading}
              disabled={isLoading || !(isValid && dirty)}
              size='md'
              className='line-height-normal text-uppercase mt-4 border-radius-3'
              type='submit'>
              Submit
            </Button>
          </RBForm>
        )
      }}
    </Formik>
  )
}

export default ValuesAndLeadershipForm
