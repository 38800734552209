import React, { useEffect, useState, useRef, useContext } from 'react'
import { useAsyncDebounce } from 'react-table'
import Form from 'react-bootstrap/Form'
import { useQuery } from '../common'
import { ListingFiltersContext } from '../../utils/contexts'

const CompanyEcoSearch = React.memo(
  ({
    globalFilter: { search, ...rest },
    setGlobalFilter,
    placeholder = 'Search',
  }) => {
    const [value, setValue] = useState(search)
    const query = useQuery()
    const searchQuery = query.get('search')
    const { setFilters } = useContext(ListingFiltersContext)
    const ifFirsRender = useRef(true)
    useEffect(() => {
      if (ifFirsRender.current) {
        ifFirsRender.current = false
      } else {
        setGlobalFilter({ ...rest, search: searchQuery || undefined })
        setValue(searchQuery || undefined)
        setFilters((prev) => ({ ...prev, search: searchQuery || undefined }))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ifFirsRender, searchQuery, setGlobalFilter])

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter({ ...rest, search: value || undefined })
      setFilters((prev) => ({ ...prev, search: value || undefined }))
    }, 1000)

    return (
      <Form.Control
        className='form-control w-40 s7'
        size='md'
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    )
  },
)

export default CompanyEcoSearch
