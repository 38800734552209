import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, useErrorService } from '@abroad/components'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const RemoveUsersFromSOW = ({ closeClickedHandler }) => {
  const { ids, setIds, setIsLoadNewData } = useContext(OrgEcoIdsContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const Error = useErrorService()
  const { sowId } = useParams()

  const removeUsersFromSOW = async () => {
    if (ids?.length === 0) return
    setIsSubmitting(true)
    try {
      const { status } = await API.AdminAPI.companyList.removeUsersFromSOW(
        sowId,
        {
          ids: ids,
        },
      )
      if (status) {
        setIsSubmitting(false)
        setIds([])
        setIsLoadNewData(true)
        closeClickedHandler()
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  return (
    <Button
      variant='danger'
      size='md'
      className='w-100 mb-point-75 border-radius-3 line-height-normal'
      onClick={removeUsersFromSOW}
      disabled={isSubmitting}
      isLoading={isSubmitting}>
      REMOVE USERS FROM SOW
    </Button>
  )
}

export default RemoveUsersFromSOW
