import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import { isLGScreen } from '../../utils/utility'

const MediaPageTitle = ({ title, description, icon, tooltip }) => {
  return (
    <>
      <div className='d-flex py-3 space-x-2 align-items-center position-relative d-lg-none bg-white justify-content-center'>
        {icon}
        <h3
          className={classnames(
            'font-montserrat align-items-center d-flex font-semibold text-dark-600',
            {
              'mb-0': !isLGScreen,
            },
          )}>
          <Trans>{title}</Trans>
          {tooltip}
        </h3>
      </div>
      {description && (
        <div className='d-flex d-lg-none justify-content-center bg-gray-100 py-3'>
          <Container className='text-center'>
            <Trans>{description}</Trans>
          </Container>
        </div>
      )}
      <Container className='px-lg-5 pt-4 d-none d-lg-block'>
        <Row>
          <Col className='text-center d-flex flex-row flex-lg-column justify-content-center align-items-center space-x-3 space-lg-x-0'>
            {icon}
            <h2 className='font-montserrat mt-1 w-100 d-flex align-items-center justify-content-center font-semibold text-dark-600 position-relative'>
              {tooltip}
              <Trans>{title}</Trans>
            </h2>
            {description && (
              <span className='text-gray-800 font-open-sans w-11/12 w-lg-8/12'>
                <Trans>{description}</Trans>
              </span>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

MediaPageTitle.defaultProps = {
  tooltip: null,
}

export default withTranslation('translation')(MediaPageTitle)
