import React from 'react'
import classNames from 'classnames'

const StaffCoachIcon = ({ classes = '' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={classNames('ml-1', classes)}
      viewBox='0 0 186 186'>
      <g id='Group_584' data-name='Group 584' transform='translate(-297 -293)'>
        <g
          id='Ellipse_2'
          data-name='Ellipse 2'
          transform='translate(297 293)'
          stroke='#000'
          strokeWidth='3'>
          <circle cx='93' cy='93' r='93' stroke='none' fill='#bf9000' />
          <circle cx='93' cy='93' r='91.5' fill='none' stroke='#bf9000' />
        </g>
        <g
          id='Group_2'
          data-name='Group 2'
          transform='translate(236.773 92.928)'>
          <path
            id='Path_7'
            data-name='Path 7'
            d='M66.73,64.27v-.79c-.53-.11-1-.21-1.56-.36-4.05-1.23-8.24-2.14-12.12-3.76C42.78,55.13,34.92,47.83,28.46,39a123.7,123.7,0,0,1-16-31,7.57,7.57,0,0,0-.86-1.36l-.6.12a11.83,11.83,0,0,0,.24,2.47c1.58,4.77,3.25,9.51,4.84,14.28a3.33,3.33,0,0,1,.13,1.91c-2.76,6.59-2,12.9,1.36,19,.46.83,1.07,1.57,1.61,2.36.54-.76,1.1-1.49,1.56-2.27.59-1,1.12-2,1.67-3l.56.19c.1,2.88.27,5.76.27,8.64a3.56,3.56,0,0,0,1.48,3.18c3,2.06,5.82,4.39,9,6a49.52,49.52,0,0,0,25.52,5.86,68.27,68.27,0,0,0,7.49-1.11ZM80,56.94a6.869,6.869,0,0,0-.82-.41c-4.94-1.38-10-2.44-14.8-4.21C58,50,51.77,47,46.56,42.28a3.39,3.39,0,0,0-1.3-.75c-2.82-.86-5.68-1.67-8.51-2.52a5,5,0,0,1-1-.56,1.076,1.076,0,0,1,.16-.39l4.58-.3c-6.76-5.46-13.22-10.65-19.87-16a7.43,7.43,0,0,0,.38,1.5c1.86,3.37,3.58,6.84,5.63,10.08,8.84,13.85,20.68,23.72,36.86,28,6,1.58,11.12-.09,15.91-3.54a4.579,4.579,0,0,0,.6-.86Z'
            transform='translate(80 258.729)'
            fill='#fff'
          />
          <path
            id='Path_8'
            data-name='Path 8'
            d='M66.73,64.27a68.268,68.268,0,0,1-7.5,1.15,49.52,49.52,0,0,1-25.52-5.86c-3.19-1.65-6-4-9-6a3.56,3.56,0,0,1-1.48-3.18c0-2.88-.17-5.76-.27-8.64l-.56-.19c-.55,1-1.08,2-1.67,3a26.991,26.991,0,0,1-1.56,2.27c-.54-.79-1.15-1.53-1.61-2.36-3.38-6.12-4.12-12.43-1.36-19a3.33,3.33,0,0,0-.13-1.91C14.47,18.74,12.8,14,11.22,9.23A11.83,11.83,0,0,1,11,6.76l.63-.19a7.57,7.57,0,0,1,.86,1.36c4,11,9,21.53,16,31,6.46,8.86,14.32,16.16,24.59,20.39,3.88,1.62,8.07,2.53,12.12,3.76.51.15,1,.25,1.56.36Z'
            transform='translate(80 258.729)'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            id='Path_9'
            data-name='Path 9'
            d='M80,56.94a4.58,4.58,0,0,1-.61.84c-4.79,3.45-9.87,5.12-15.91,3.54-16.18-4.26-28-14.13-36.86-28C24.61,30.1,22.89,26.63,21,23.26a7.43,7.43,0,0,1-.45-1.51c6.65,5.36,13.11,10.55,19.87,16l-4.58.3c-.06.13-.11.26-.16.39a5,5,0,0,0,1,.56c2.83.85,5.69,1.66,8.51,2.52a3.39,3.39,0,0,1,1.3.75C51.77,47,58,50,64.42,52.32c4.8,1.77,9.86,2.83,14.8,4.21A6.87,6.87,0,0,1,80,56.94Z'
            transform='translate(80 258.729)'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            id='Path_10'
            data-name='Path 10'
            d='M59,68.12a34.05,34.05,0,0,0,12.37,0c8.17-1.36,15.45-4.88,22.22-9.58a90.42,90.42,0,0,0,15-13.09c2.77-3,5.45-6.15,7.94-9.45,2.36-3.11,4.46-6.38,6.51-9.67.24-.39.49-.88,1-.6s0,.72-.09,1a73.47,73.47,0,0,1-8.68,13.75,87.81,87.81,0,0,1-24.4,21.67c-.4.24-1.16.37-.92,1s1,.36,1.47.19c1.52-.52,3-1.16,4.53-1.69A55.82,55.82,0,0,0,116.32,49.3,72.522,72.522,0,0,0,124,40.59a58.87,58.87,0,0,0,7.72-13.71A52.83,52.83,0,0,0,135.3,11c.24-3.23,0-6.49,0-9.73,0-.48,0-1.13-.61-1.24s-.56.55-.75.91a42.19,42.19,0,0,1-8.11,10.54c-4.22,3.83-9,6.9-13.64,10.15-6.43,4.51-10.42,10.23-10.84,18.25-.08,1.58.09,3.3-1,5a13.88,13.88,0,0,1-.62-4.43c0-.47.18-1.11-.47-1.28s-.9.46-1.2.94C92.28,49.64,85,57.67,74.84,62.84a44.3,44.3,0,0,1-13.63,4.39,3,3,0,0,0-2.21.89Z'
            transform='translate(80 258.729)'
            fill='#fff'
          />
          <path
            id='Path_11'
            data-name='Path 11'
            d='M59,68.12a3,3,0,0,1,2.2-.89,44.3,44.3,0,0,0,13.63-4.39C85,57.67,92.28,49.64,98.13,40.08c.3-.48.48-1.13,1.2-.94s.45.81.47,1.28a13.88,13.88,0,0,0,.62,4.43c1-1.69.88-3.41,1-5,.42-8,4.41-13.74,10.84-18.25,4.64-3.25,9.42-6.32,13.64-10.15A42.189,42.189,0,0,0,134,.92c.19-.36.16-1,.75-.91s.61.76.61,1.24c0,3.24.21,6.5,0,9.73a52.83,52.83,0,0,1-3.59,15.9A58.87,58.87,0,0,1,124,40.59a72.52,72.52,0,0,1-7.67,8.71A55.82,55.82,0,0,1,96,61.63c-1.52.53-3,1.17-4.53,1.69-.49.17-1.21.5-1.47-.19s.52-.74.92-1a87.81,87.81,0,0,0,24.37-21.66,73.469,73.469,0,0,0,8.68-13.75c.12-.28.62-.72.09-1s-.77.21-1,.6a113.554,113.554,0,0,1-6.51,9.67c-2.49,3.3-5.17,6.42-7.94,9.45a90.42,90.42,0,0,1-15,13.09c-6.77,4.7-14,8.22-22.22,9.58A34.05,34.05,0,0,1,59,68.12Z'
            transform='translate(80 258.729)'
            fill='#fff'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
}

export default StaffCoachIcon
