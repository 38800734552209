import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import {
  useErrorService,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { PageHeader } from '../components'
import { DeleteConfirmationPrompt, SearchInput } from '../components/common'
import API from '../utils/API'

const DeleteUser = () => {
  const [search, setSearch] = useState('')
  const [clientInfo, setClientInfo] = useState([])
  const [isUserDelete, setIsUserUserDelete] = useState(false)
  const Notification = useNotificationService()
  const Error = useErrorService()
  const initialValues = {
    show: false,
    conformation: false,
  }
  const [showModal, setShowModal] = useState(initialValues)
  const { user } = useUserService()

  const handleDeleteUser = async () => {
    try {
      const { status } = await API.AdminAPI.userList.deleteUserAccount(
        clientInfo[0]?._id,
      )
      if (status) {
        setSearch('')
        setClientInfo([])
        setShowModal(initialValues)
        Notification.showNotification(
          'User account has been deleted!',
          'success',
        )
      }
    } catch (e) {
      setShowModal(initialValues)
      Error.showError(e)
    }
    setIsUserUserDelete(false)
  }

  const handleComfirmDelete = () => {
    setIsUserUserDelete(true)
    if (showModal.conformation && clientInfo[0]?.email === search) {
      handleDeleteUser()
    } else {
      setShowModal({ ...showModal, conformation: true })
      setTimeout(() => setIsUserUserDelete(false), 300)
    }
  }

  return (
    <>
      <Helmet>
        <title>{'Delete User Account | Abroad'}</title>
        <meta name='title' content={'Delete User Account | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Delete User Account | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Delete User Account | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/delete-user`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/delete-user`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/delete-user`}></meta>
      </Helmet>
      <PageHeader
        titleText='Delete User Account'
        descriptionFn={() => {
          return (
            <p className='font-montserrat font-medium'>{`${user?.fullName}(${user?.email})`}</p>
          )
        }}
        colClasses='px-0'
      />
      <DeleteConfirmationPrompt
        showModal={showModal.show}
        setShowModal={setShowModal}
        isDeleteLoading={isUserDelete}
        handleDelete={handleComfirmDelete}
        message={
          !showModal.conformation
            ? `Are you sure you want to delete ${clientInfo[0]?.fullName}'s account from abroad platform?`
            : 'Please reconfirm the choice.'
        }
      />
      <section className='simple-360 profile custom-offset-lg-1 custom-col-lg-12 px-0'>
        <Container fluid className='px-0'>
          <Row>
            <Col>
              <h4 className='mt-38 mb-2 font-open-sans font-16 font-semibold leading-6 letter-spacing-02'>
                Search User
              </h4>
              <SearchInput
                search={search}
                render={(data) => setSearch(data)}
                setClientInfo={setClientInfo}
                handleSubmit={() => setShowModal({ ...showModal, show: true })}
                buttonText='DELETE'
                placeholder='search user'
                inputColumnClasses='col-12'
                wrapperClasses='d-flex flex-column'
                btnColumnClasses='mb-5'
                listClasses='delete-user-list'
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default DeleteUser
