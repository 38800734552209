import React, { useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import classnames from 'classnames'
import { isLGScreen } from '../../utils/utility'

const ordinal_suffix_of = (i) => {
  let j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

const AddIntentionField = ({ title, index, hasIntention, isActive }) => {
  const [intentionTitle, setIntentionTitle] = useState('')
  const textAreaRef = useRef(null)

  useEffect(() => {
    setIntentionTitle(title || '')
  }, [title])

  useEffect(() => {
    const ref = textAreaRef.current
    if (!isLGScreen && isActive) {
      const event = new Event('focus')
      ref.dispatchEvent(event)
    } else if (isLGScreen) {
      const event = new Event('focus')
      ref.dispatchEvent(event)
    }
  }, [intentionTitle, isActive, textAreaRef])

  return (
    <Form.Group
      controlId={`add-intention-${index}`}
      className={classnames({
        'mb-0': isLGScreen,
      })}>
      <Form.Control
        ref={textAreaRef}
        as='textarea'
        value={intentionTitle}
        placeholder={`${'Insert your'} ${ordinal_suffix_of(
          index,
        )} ${'intention title here'}`}
        disabled
        className={classnames(
          'intention-textarea font-montserrat overflow-auto text-dark-900 bg-primary bg-transparent px-0 shadow-none pointer-event-none',
          {
            'font-24 font-semibold': isLGScreen,
            'font-20 font-medium': !isLGScreen,
            hasIntention: !hasIntention,
          },
        )}
        rows={isLGScreen ? 1 : 2}
        style={{ resize: 'none' }}
      />
      <Form.Text
        className='characters-limit text-right'
        id='passwordHelpBlock'
        muted>
        {intentionTitle ? intentionTitle?.length : 0} / 60
      </Form.Text>
    </Form.Group>
  )
}

AddIntentionField.defaultProps = {
  inputKeyUp: () => {},
}

export default AddIntentionField
