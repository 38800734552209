import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Row, Table, Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import {
  useErrorService,
  useNotificationService,
  Button,
  ABSpinner,
  useUserService,
  FeatherLoader,
} from '@abroad/components'
import { DeleteConfirmationPrompt, PageHeader } from '../components/common'
import { formatDate } from '../utils/date'
import API from '../utils/API'

export const UploadCustomReport = () => {
  const [hasPlan, setHasplan] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [reportData, setReportData] = useState([])
  const [isUploadReportLoading, setIsUploadReportLoading] = useState(false)
  const [fetchDocumentList, setFetchDocumentList] = useState(true)
  const [clientProfile, setClientProfile] = useState(null)
  const [isReportDelete, setIsReportDelete] = useState(false)
  const [reportId, setReportId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [show, setShow] = useState(false)
  const [typeInput, setTypeInput] = useState('')
  const [allCoachIds, setAllCoachIds] = useState([])
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { clientId } = useParams()
  const { user } = useUserService()
  const inputRef = useRef(null)

  useEffect(() => {
    const fetchClientProfile = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['email', 'coachIds', 'fname'],
          },
        )
        setClientProfile(data)
        setHasplan(data?.coachingPlanExist)
        setIsLoading(false)
        if (data?.coachIds)
          setAllCoachIds(data?.coachIds?.map((coach) => coach?._id))
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    fetchClientProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const { data } =
          await API.CoachAPI.document.getClientDocumentListByPlanId(
            clientId,
            null,
            4,
          )
        if (data) {
          setReportData(data)
          setFetchDocumentList(false)
        }
      } catch (e) {
        setFetchDocumentList(false)
        Error.showError(e)
      }
    }
    if (fetchDocumentList) {
      fetchReports()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDocumentList])

  const handleDocDelete = async () => {
    setIsReportDelete(true)
    try {
      const { data } = await API.CoachAPI.document.deleteClientDocument(
        clientId,
        reportId,
      )
      if (data?.status) {
        setIsReportDelete(false)
        setShowModal(false)
        setReportData((prev) =>
          prev.filter((report) => report?._id !== reportId),
        )
        Notification.showNotification('Document has been deleted.', 'success')
      }
    } catch (e) {
      setIsReportDelete(false)
      setShowModal(false)
      setFetchDocumentList(false)
      Error.showError(e)
    }
  }

  const handleDocDownload = async (docId, fileName, docType) => {
    let contentType = fileName.substr(fileName.length - 4).toUpperCase()
    if (contentType === '.PDF') {
      contentType = 'PDF'
    } else if (contentType === 'DOCX') {
      contentType = 'DOCX'
    } else {
      Notification.showNotification(
        'Please upload pdf or doc format files only.',
        'danger',
      )
    }
    try {
      const { data } = await API.CoachAPI.document.getDownloadDocumentUrl(
        clientId,
        docId,
        docType,
        fileName,
        contentType,
      )
      if (data) {
        window.open(data.url)
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const getCustomReport = (e) => {
    const files = e.target.files
    if (files && files.length > 0 && files[0].size / 1024 / 1024 <= 100) {
      addCustomReport(files[0])
    } else {
      Notification.showNotification('File is too big!', 'danger')
    }
  }

  const addCustomReport = async (reportFileName) => {
    let contentType = null

    if (
      reportFileName.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      contentType = 'DOCX'
    } else if (reportFileName.type === 'application/pdf') {
      contentType = 'PDF'
    } else {
      Notification.showNotification(
        'Only pdf or docx file format is allowed',
        'danger',
      )
      return
    }

    setIsUploadReportLoading(true)
    try {
      const { data } =
        await API.CoachAPI.document.getSignUrlForUploadReportOfUser(
          contentType,
          clientId,
          4,
        )
      if (data) {
        let uploadFile = await axios.put(data.url.url, reportFileName)

        if (uploadFile) {
          const { data: reportData } =
            await API.CoachAPI.document.addReportForUser(clientId, 4, {
              fileName: data.fileName,
              reportType: typeInput,
            })
          if (reportData) {
            setTypeInput('')
            setIsUploadReportLoading(false)
            Notification.showNotification(
              'Custom Report has been uploaded.',
              'success',
            )
            inputRef.current.value = ''
            setShow(false)
            setFetchDocumentList(true)
          }
        }
      }
    } catch (e) {
      setIsUploadReportLoading(false)
      inputRef.current.value = ''
      Error.showError(e)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${clientProfile?.fname}'s Custom Report | Abroad`}</title>
        <meta
          name='title'
          content={`${clientProfile?.fname}'s Custom Report | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${clientProfile?.fname}'s Custom Report | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${clientProfile?.fname}'s Custom Report | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/reports/custom`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/reports/custom`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/reports/custom`}></meta>
      </Helmet>
      <DeleteConfirmationPrompt
        showModal={showModal}
        setShowModal={setShowModal}
        isDeleteLoading={isReportDelete}
        handleDelete={handleDocDelete}
        message='This can not be reverted. Are you sure you want to delete this
    Report?'
      />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        keyboard={false}
        centered
        className='profile'>
        <Modal.Header closeButton>
          <Modal.Title className='text-uppercase font-montserrat text-saffron-700 font-14'>
            Upload Custom Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Form.Label>Report Type</Form.Label>
            </Col>
            <Col lg={12} md={12} sm={12} className='mb-2'>
              <Form.Control
                type='text'
                placeholder='Eg. Annual Performance Report'
                value={typeInput}
                isInvalid={typeInput.length > 50}
                onChange={(e) => setTypeInput(e.target.value)}
                disabled={isUploadReportLoading}
                className='user-input'
              />
              <Form.Text className='text-muted font-open-sans'>
                maximum 50 characters allowed.
              </Form.Text>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Button
                variant='gray'
                size='sm'
                onClick={() =>
                  allCoachIds.includes(user?._id) || user.isAdmin
                    ? inputRef.current.click()
                    : Notification.showNotification(
                        'You are not coach for this user',
                        'danger',
                      )
                }
                className='font-10 text-uppercase'
                isLoading={isUploadReportLoading}
                disabled={
                  isUploadReportLoading ||
                  !hasPlan ||
                  typeInput === '' ||
                  typeInput.length > 50
                }>
                Upload
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <main className='py-3'>
        <PageHeader
          titleText='Custom Reports'
          wrapperClassName='d-flex justify-content-between'
          titleClasses='mb-0'
          descriptionFn={() => {
            return (
              <>
                <p className='font-montserrat font-medium mb-0'>
                  {clientProfile?.email}
                </p>
              </>
            )
          }}
          extraFn={() => {
            return (
              <div className='d-flex justify-content-end'>
                <Button
                  variant='gray'
                  onClick={() =>
                    user.isAdmin ||
                    (user?.isCoach && allCoachIds.includes(user?._id))
                      ? setShow(true)
                      : Notification.showNotification(
                          'You are not coach for this user',
                          'danger',
                        )
                  }
                  className='font-12 text-uppercase'
                  disabled={!hasPlan || isUploadReportLoading}>
                  Add
                </Button>
              </div>
            )
          }}
          colClasses='px-lg-0'
        />
        <Form.Control
          type='file'
          className='d-none'
          accept='.pdf,.docx'
          ref={inputRef}
          onChange={getCustomReport}
        />
        <section className='custom-offset-lg-1 custom-col-lg-12 px-0'>
          <Container fluid>
            <h3 className='profile-subheader font-montserrat font-18 font-bold leading-relaxed my-4'>
              Reports
            </h3>
            <Row>
              <Col className='d-flex'>
                <Table responsive hover className='custom-table user-table'>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchDocumentList && (
                      <tr>
                        <td className='text-center border-0' colSpan='3'>
                          <FeatherLoader />
                        </td>
                      </tr>
                    )}
                    {/* eslint-disable */}
                    {reportData?.length > 0 &&
                      !fetchDocumentList &&
                      reportData.map((doc) => (
                        <tr key={doc._id}>
                          <td>
                            <a
                              onClick={() =>
                                handleDocDownload(
                                  doc._id,
                                  doc.fileName,
                                  doc.type,
                                )
                              }
                              className='text-dark-600 under-line my-2 cursor-pointer'>
                              {doc?.reportType}
                            </a>
                          </td>
                          <td>{formatDate(doc.uploadedAt)}</td>
                          <td>
                            {user?.isAdmin || user?._id === doc?.uploadedBy ? (
                              <a
                                onClick={() => {
                                  setReportId(doc._id)
                                  setShowModal(true)
                                }}
                                className='text-red-600 under-line my-2 ml-2 cursor-pointer'>
                                Delete
                              </a>
                            ) : (
                              <div className='my-2 ml-2 pl-4'>-</div>
                            )}
                          </td>
                        </tr>
                      ))}
                    {reportData?.length === 0 && !fetchDocumentList && (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No Reports.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}
