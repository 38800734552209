import React, { useCallback, useContext, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IndeterminateCheckbox, Table, TableInstance } from '../table'
import { StaffCoachIcon } from '../assetsComponents'
import { OrgEcoIdsContext } from '../../utils/contexts'

const SOWGroupTable = ({ data }) => {
  const { companyName, ecoSystemName, sowId } = useParams()
  const { setIds } = useContext(OrgEcoIdsContext)
  const hasMore = useRef()

  const columns = React.useMemo(
    () => [
      {
        id: 'groupname',
        Header: 'Group Name',
        width: '22%',
        accessor: (properties) => properties?.name,
        Cell: ({ cell }) => (
          <React.Fragment>
            <Link
              className='text-dark-900'
              to={`/admin/${
                companyName
                  ? `organization/${companyName}`
                  : `network/${ecoSystemName}`
              }/sow/${sowId}/sow-group/${cell?.row?.original?._id}`}>
              {cell?.value}
            </Link>
          </React.Fragment>
        ),
        sortType: (rowA, rowB) => {
          return 0
        },
      },
      {
        id: 'Users',
        Header: 'Users',
        width: '25%',
        accessor: (properties) => properties?.usersCount,
        Cell: ({ cell }) => <span>{cell?.value}</span>,
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        disableSortBy: true,
      },
      {
        id: 'Coach',
        Header: 'Coach',
        width: '25%',
        accessor: (properties) => properties?.coaches,
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.map((coach) => (
              <React.Fragment key={coach?._id}>
                <Link
                  className='text-dark-900'
                  to={`/admin/coaches/${coach?._id}`}>
                  {coach?.fullName}
                  {coach?.isStaffCoach ? (
                    <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                  ) : null}
                </Link>
                <br />
              </React.Fragment>
            ))}
          </React.Fragment>
        ),
        disableSortBy: true,
      },
      {
        id: 'Status',
        Header: 'Status',
        width: '20%',
        accessor: (properties) => properties?.isArchived,
        Cell: ({ cell }) => <span>{cell?.value ? 'Archived' : 'Active'}</span>,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyName, ecoSystemName, sowId],
  )

  const selectionHook = useCallback((hooks) => {
    hooks.visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: 'selection',
        width: '4%',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({
          getToggleAllRowsSelectedProps,
          toggleRowSelected,
          ...rest
        }) => {
          const rowProps = getToggleAllRowsSelectedProps()
          rowProps.onChange = (e) => {
            rest.rows.forEach((row) => {
              if (!row.original.isArchived) {
                if (!rest.isAllPageRowsSelected) {
                  toggleRowSelected(row.id, true)
                } else {
                  toggleRowSelected(row.id, false)
                }
              }
            })
          }
          return (
            <div>
              <IndeterminateCheckbox
                _id={'header'}
                {...rowProps}
                classes='header-checkbox'
              />
            </div>
          )
        },
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => {
          return (
            <div>
              <IndeterminateCheckbox
                disabled={row.original.isArchived}
                _id={row.id}
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          )
        },
      },
      ...columns,
    ])
  }, [])

  const selectedRow = (selectedRow) => {
    const selectedIds = selectedRow.map((row) => row?._id)
    setIds(selectedIds)
  }

  return (
    <TableInstance
      columns={columns}
      data={data}
      initialState={{
        sortBy: [{ id: 'groupname', desc: false }],
      }}
      manualSortBy={false}
      // disableMultiRemove={false}
      tableHooks={[selectionHook]}>
      {({ ...rest }) => (
        <Table
          ref={hasMore}
          emptyTableMessage='No Groups.'
          tableInstance={{
            ...rest,
          }}
          isLoading={false}
          fetchData={() => {}}
          selectedRow={selectedRow}
          totalNumberOfColumns='5'
        />
      )}
    </TableInstance>
  )
}

export default SOWGroupTable
