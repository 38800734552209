import React, { useContext, useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Form as RBForm, Row, Col, Container } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import ClearFilter from './ClearFilter'
import { UserListDataContext } from './Users'
import PromoCodeFilter from './PromoCodeFilter'
import { InputField } from '../form'
import { useQuery, SelectionField as FormikSelectionField } from '../common'
import useDebounce from '../common/useDebounce'
import useInitialState from '../common/hooks/useInitialState'
import usePreviousState from '../common/hooks/usePreviousState'
import labels from '../../constants/labels'
import API from '../../utils/API'

const FilterForm = React.memo(
  ({ render, renderHeder, needUserFilter = false }) => {
    // get coaches from context
    const { companies, ecoSystems } = useContext(UserListDataContext)
    const firstUpdate = useRef(true)
    const observer = useRef(null)
    const loader = useRef(null)
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [users, setUsers] = useState({
      isLoadMore: true,
      users: [],
    })
    const query = useQuery()
    const search = query.get('search')
    const {
      values: {
        filterBySearch,
        filterByAffiliation,
        filterByCompany,
        filterByPromoCode,
        filterByPlan,
        //filterByCoachId,
      },
      setFieldValue,
    } = useFormikContext()

    const debouncedSearchTerm = useDebounce(filterBySearch, 500)
    const Error = useErrorService()
    const { isInitiallyLoad, changeIntialStateHandler } = useInitialState()
    const { ecoSystemName } = useParams()
    const prevEcoSystemName = usePreviousState(ecoSystemName)

    /* eslint-disable */
    const getUserList = async (emptyFirst = false) => {
      let params = {
        limit: 25,
        page,
        search: filterBySearch,
        filter: {
          company: filterByCompany,
          affiliation: filterByAffiliation,
          promoCode: filterByPromoCode,
          planCode: filterByPlan && JSON.parse(filterByPlan).value,
          //coachId: filterByCoachId,
        },
      }

      Object.keys(params).forEach(
        (key) => params[key] === '' && delete params[key],
      )

      Object.keys(params.filter).forEach(
        (key) => params.filter[key] === '' && delete params.filter[key],
      )

      try {
        setIsLoading(true)
        const { data } = await API.AdminAPI.userList.getUserList(params)
        setIsLoading(false)
        setUsers((prev) => ({
          isLoadMore:
            data && (data.length === 0 || data.length < 25) ? false : true,
          users: emptyFirst ? [...data] : [...prev.users, ...data],
        }))
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
      changeIntialStateHandler()
    }

    useEffect(() => {
      if (search) {
        setFieldValue('filterBySearch', search)
      }
    }, [search])

    useEffect(() => {
      if (ecoSystemName !== prevEcoSystemName) {
        changeIntialStateHandler(true)
        setIsLoading(true)
        setPage(1)
        setUsers({
          isLoadMore: true,
          users: [],
        })
      }
    }, [ecoSystemName])

    useEffect(() => {
      getUserList()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false
        return
      }
      setUsers((prev) => {
        observer.current?.disconnect()
        return {
          isLoading: true,
          isLoadMore: true,
          users: [],
        }
      })
      setPage((prev) => {
        if (prev === 1) {
          getUserList(true)
        }
        return 1
      })
      changeIntialStateHandler(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      filterByAffiliation,
      filterByCompany,
      filterByPromoCode,
      filterByPlan,
      //filterByCoachId,
      debouncedSearchTerm,
    ])

    const handleObserver = (entities) => {
      const target = entities[0]
      if (target.isIntersecting) {
        setPage((page) => page + 1)
      }
    }

    useEffect(() => {
      let options = {
        root: null,
        rootMargin: '20px',
        threshold: 1.0,
      }
      // initialize IntersectionObserver
      // and attaching to Load More div
      if (users.users?.length > 0) {
        observer.current = new IntersectionObserver(handleObserver, options)
        if (loader.current && observer.current) {
          observer.current?.observe(loader.current)
        }
      } else {
        observer.current?.disconnect()
      }
      return () => {
        observer.current?.disconnect()
      }
    }, [users])

    return (
      <>
        {renderHeder(users.users)}
        {needUserFilter && (
          <RBForm
            onSubmit={(e) => {
              e.preventDefault()
            }}
            className='profile h-100 d-flex flex-column justify-content-between space-y-6 space-lg-y-0 user-list mt-5 custom-offset-lg-1 custom-col-lg-12 px-lg-0'>
            <Container fluid className='px-lg-0'>
              <Row>
                <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                  <InputField
                    placeholder='First Name,Last Name Or Email'
                    name='filterBySearch'
                    type='text'
                    className='user-input black-color-placeholder text-dark-900'
                  />
                </Col>
                {/* <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                  <FormikSelectionField
                    name='filterByCoachId'
                    className={classNames('user-input', {
                      'pointer-cursor': !coaches?.isLoading,
                      'cursor-disabled': coaches?.isLoading,
                    })}
                    options={coaches.coaches?.map((coach) => ({
                      label: coach?.fullName,
                      value: coach?._id,
                    }))}
                    optionplaceholder={
                      coaches.isLoading ? 'Fetching Coaches...' : 'Select Coach'
                    }
                    size='md'
                    onBlur={(e) => {
                      // Overrider formik blur handler
                    }}
                    getValueAsOption={true}
                    disabled={coaches?.isLoading}
                    key={filterByCoachId}
                  />
                </Col> */}
                <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                  <FormikSelectionField
                    name='filterByCompany'
                    className={classNames('user-input', {
                      'pointer-cursor': !companies?.isLoading,
                      'cursor-disabled': companies?.isLoading,
                    })}
                    options={companies.companies?.filter(
                      (company) => company !== null,
                    )}
                    optionplaceholder={
                      companies.isLoading
                        ? 'Fetching Organization...'
                        : 'Select Organization'
                    }
                    size='md'
                    onBlur={(e) => {
                      // Overrider formik blur handler
                    }}
                    disabled={companies.isLoading}
                  />
                </Col>
                <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                  <PromoCodeFilter />
                </Col>

                <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                  <FormikSelectionField
                    name='filterByAffiliation'
                    options={ecoSystems.ecoSystems?.filter(
                      (ecoSystem) => ecoSystem !== null,
                    )}
                    className={classNames('user-input', {
                      'pointer-cursor': !ecoSystems?.isLoading,
                      'cursor-disabled': ecoSystems?.isLoading,
                    })}
                    optionplaceholder='Select Network'
                    size='md'
                    disabled={ecoSystems?.isLoading}
                  />
                </Col>
                <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                  <FormikSelectionField
                    name='filterByPlan'
                    options={labels.plans}
                    className='pointer-cursor user-input'
                    optionplaceholder='Select Plan'
                    size='md'
                    isKeyValuePair={true}
                    keyprop='label'
                    valueprop='value'
                    labelProp='label'
                  />
                </Col>
              </Row>
              <ClearFilter isLoading={isLoading} />
            </Container>
          </RBForm>
        )}
        {render(users, isLoading, loader, isInitiallyLoad)}
      </>
    )
  },
)

export default FilterForm
