import { useEffect, useState } from 'react'
import { useErrorService } from '@abroad/components'
import API from '../../../utils/API'

const useGroupName = (id) => {
  const [groupName, setGroupName] = useState('')
  const Error = useErrorService()

  useEffect(() => {
    const getGroupName = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamName(id)
        if (data) {
          setGroupName(data?.name)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (id) getGroupName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!id) return ''

  return groupName ? `${groupName}'s` : ''
}

export default useGroupName
