import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

const PromoCodeTable = ({ promoCodeData, isLoading, getPromoIdHandler }) => {
  let { search } = useLocation()
  const query = new URLSearchParams(search)
  const code = query.get('code')

  return (
    <Table hover responsive className='custom-table'>
      <thead>
        <tr className='font-montserrat'>
          <th width='3%'>#</th>
          <th width='27%'>Code</th>
          <th width='10%'>Status</th>
          <th width='10%'>% Discount</th>
          <th width='10%'>Max Off </th>
          <th width='10%'>Remaining</th>
          <th width='15%'>Expiry Date</th>
          <th width='15%'>Action</th>
        </tr>
      </thead>
      <tbody>
        <React.Fragment>
          {isLoading && (
            <tr>
              <td colSpan={8} className='font-montserrat text-center'>
                Loading...
              </td>
            </tr>
          )}
          {promoCodeData?.length === 0 && !isLoading && (
            <tr>
              <td colSpan={8} className='font-montserrat text-center'>
                No Promo code Found.
              </td>
            </tr>
          )}
          {!isLoading &&
            promoCodeData?.map((promoData, index) => (
              <tr
                key={index}
                className={classNames({
                  'match-promo': code === promoData.code,
                })}>
                <td width='3%'>{index + 1}</td>
                <td width='27%'>{promoData?.code?.toUpperCase()}</td>
                <td width='10%'>
                  {promoData.status ? (
                    <p className='promo-active mb-0'> Active</p>
                  ) : (
                    <p className='promo-inactive mb-0'>Inactive</p>
                  )}
                </td>
                <td width='10%'>{promoData.discount}%</td>
                <td width='10%'>
                  {promoData.maxAmount ? (
                    `$ ${promoData.maxAmount}`
                  ) : (
                    <p className='text-center'>-</p>
                  )}
                </td>
                <td width='10%'>{promoData.noOfUse}</td>
                <td width='15%' className='text-nowrap'>
                  {moment(promoData?.expTime).format('MM-DD-YYYY')}
                </td>
                <td width='15%'>
                  {/* eslint-disable */}
                  <Link
                    className='text-saffron-700 cursor-pointer pr-2 archive-border-right'
                    to={`/admin/promo/${promoData?._id}`}>
                    Update
                  </Link>

                  <a
                    href='javascript:void(0)'
                    className='text-red-500 cursor-pointer pl-2'
                    onClick={(e) => {
                      e.preventDefault()
                      getPromoIdHandler(promoData._id)
                    }}>
                    Remove
                  </a>
                  {/* eslint-enable */}
                </td>
              </tr>
            ))}
        </React.Fragment>
      </tbody>
    </Table>
  )
}

export default PromoCodeTable
