import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { showInUrls } from '@abroad/components'
import { MediaLibrarySubCategory, ProgramSubCategory } from '../media'

import categories from '../../constants/mediaLibrary'
import { PageHeader } from '../common'

const CategoryList = () => {
  return (
    <main>
      <PageHeader
        titleText='MEDIAS'
        colClasses='px-0'
        titleClasses='sentence-case-text'
      />
      <Container
        className='pt-5 custom-offset-lg-1 custom-col-lg-12 px-0'
        fluid>
        <Row>
          <Col className='d-flex flex-row flex-lg-column justify-content-center'>
            <h4 className='font-montserrat w-100 d-flex position-relative'>
              PROGRAMS
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {categories.programs.map((program) => {
              if (
                program?.onlyRC &&
                !showInUrls.includes(window.location.origin)
              ) {
                return null
              }
              return (
                <div key={program.id} className='mt-3'>
                  <h5 className='font-montserrat font-semibold text-saffron-700'>
                    {program.name}
                  </h5>
                  <ProgramSubCategory id={program.id} />
                </div>
              )
            })}
          </Col>
        </Row>
        <Row>
          <Col className='d-flex flex-row flex-lg-column justify-content-center'>
            <h4 className='font-montserrat mt-5 w-100 d-flex position-relative'>
              MEDIA LIBRARY
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {categories.mediaCategory.map((category) => {
              return (
                <div key={category.id} className='mt-3'>
                  <h5 className='font-montserrat font-semibold text-saffron-700'>
                    {category.name}
                  </h5>
                  <MediaLibrarySubCategory parentId={category.id} />
                </div>
              )
            })}
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default withTranslation('translation')(CategoryList)
