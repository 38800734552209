import React, { useRef, useCallback, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { TableInstance, Table, IndeterminateCheckbox } from '../table'
import { getMomentConvertedDateForSOW } from '../../utils/utility'
import { OrgEcoIdsContext } from '../../utils/contexts'

const SOWDetailsTable = ({ data, type }) => {
  const hasMore = useRef(null)
  const { companyName, ecoSystemName } = useParams()
  const { setIds } = useContext(OrgEcoIdsContext)

  const columns = React.useMemo(
    () => [
      {
        id: 'sowname',
        Header: 'SOW Name',
        width: '27%',
        accessor: (properties) => properties?.planName,

        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.row?.original?.isDeleted ? (
              <span>{cell?.value}</span>
            ) : (
              <Link
                className='text-dark-900'
                to={`/admin/${
                  type === 'Organization'
                    ? `organization/${companyName}`
                    : `network/${ecoSystemName}`
                }/sow/${cell?.row?.original?._id}/details`}>
                {cell?.value}
              </Link>
            )}
          </React.Fragment>
        ),
        sortType: (rowA, rowB) => {
          return 0
        },
      },
      {
        id: 'Users',
        Header: 'Users',
        width: '15%',
        accessor: (properties) => properties?.usersCount,
        Cell: ({ cell }) => <span>{cell?.value}</span>,
        disableSortBy: true,
      },
      {
        id: 'Start Date',
        Header: 'Start Date',
        width: '15%',
        accessor: (properties) => properties?.sowStartDate,
        Cell: ({ cell }) => (
          <span>{getMomentConvertedDateForSOW(cell?.value)}</span>
        ),
        disableSortBy: true,
      },
      {
        id: 'status',
        Header: 'Status',
        width: '40%',
        accessor: (properties) => ({
          isDeleted: properties?.isDeleted,
          deletionDate: properties?.deletionDate,
        }),
        Cell: ({ cell }) => (
          <span>
            {cell?.value?.isDeleted
              ? `Inactive (${getMomentConvertedDateForSOW(
                  cell?.value?.deletionDate,
                )})`
              : 'Active'}
          </span>
        ),
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const selectionHook = useCallback((hooks) => {
    hooks.visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: 'selection',
        width: '4%',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({
          getToggleAllRowsSelectedProps,
          toggleRowSelected,
          ...rest
        }) => {
          const rowProps = getToggleAllRowsSelectedProps()
          rowProps.onChange = (e) => {
            rest.rows.forEach((row) => {
              if (!row.original.isDeleted) {
                if (!rest.isAllPageRowsSelected) {
                  toggleRowSelected(row.id, true)
                } else {
                  toggleRowSelected(row.id, false)
                }
              }
            })
          }
          return (
            <div>
              <IndeterminateCheckbox
                _id={'header'}
                {...rowProps}
                classes='header-checkbox'
              />
            </div>
          )
        },
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => {
          return (
            <div>
              <IndeterminateCheckbox
                disabled={row.original.isDeleted}
                _id={row.id}
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          )
        },
      },
      ...columns,
    ])
  }, [])

  const selectedRow = (selectedRow) => {
    const selectedIds = selectedRow.map((row) => row?._id)
    setIds(selectedIds)
  }

  return (
    <TableInstance
      columns={columns}
      data={data}
      initialState={{
        sortBy: [{ id: 'sowname', desc: false }], // for manual short pass intial sort data
      }}
      manualSortBy={false}
      //disableMultiRemove={false}
      tableHooks={[selectionHook]}>
      {({ ...rest }) => (
        <Table
          ref={hasMore}
          emptyTableMessage='No SOWs.'
          tableInstance={{
            ...rest,
          }}
          isLoading={false}
          fetchData={() => {}}
          selectedRow={selectedRow}
          totalNumberOfColumns='5'
        />
      )}
    </TableInstance>
  )
}

export default SOWDetailsTable
