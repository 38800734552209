import React, { useState } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import {
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import { CoachFilter } from '../components/coachList'
import { PageHeader } from '../components/common'

const Coaches = () => {
  const [isCoachesExportLoading, setIsCoachesExportLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()

  const exportCoachesHandler = async () => {
    let xlsxFile, downloadLink
    const filename = 'abroad_coaches.xlsx'
    setIsCoachesExportLoading(true)
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/export-coaches`,
        { responseType: 'blob', withCredentials: true },
      )
      if (data) {
        // XLSX file
        xlsxFile = new Blob([data])

        // Download link
        downloadLink = document.createElement('a')

        // File name
        downloadLink.download = filename

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(xlsxFile)

        // Hide download link
        downloadLink.style.display = 'none'

        // Add the link to DOM
        document.body.appendChild(downloadLink)

        // Click download link
        downloadLink.click()

        Notification.showNotification('Export Successful.', 'success')
        setIsCoachesExportLoading(false)
      }
    } catch (e) {
      setIsCoachesExportLoading(false)
      Error.showError(e)
    }
  }
  return (
    <>
      <Helmet>
        <title>{'Coaches | Abroad'}</title>
        <meta name='title' content={'Coaches | Abroad'}></meta>
        <meta property='og:title' content={'Coaches | Abroad'}></meta>
        <meta property='twitter:title' content={'Coaches | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/coaches`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/coaches`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/coaches`}></meta>
      </Helmet>
      <PageHeader
        titleText='COACH ACCOUNTS'
        wrapperClassName='d-flex justify-content-between'
        extraFn={() => {
          return (
            <>
              <Button
                variant='gray'
                className='line-height-normal border-radius-3'
                isLoading={isCoachesExportLoading}
                disabled={isCoachesExportLoading}
                onClick={exportCoachesHandler}>
                Export All Coaches
              </Button>
            </>
          )
        }}
        titleClasses='mb-0 sentence-case-text'
        colClasses='px-0'
      />
      <CoachFilter />
    </>
  )
}

export default Coaches
