import React, { useContext } from 'react'
import SelectionFieldForAffiliation from './SelectionFieldForAffiliation'
import { UserListDataContext } from './Users'

const UserAffiliation = ({ userId, affiliation, email, isNetworkDisabled }) => {
  const { ecoSystems } = useContext(UserListDataContext)
  return (
    <SelectionFieldForAffiliation
      id={`select-network-${userId}`}
      name='network'
      placeholder='Select Network'
      className='user-input'
      values={
        affiliation?.length > 0
          ? affiliation?.map((ecoSystem) => ({
              value: ecoSystem,
              label: ecoSystem,
            }))
          : []
      }
      options={ecoSystems.ecoSystems?.map((ecoSystem) => ({
        value: ecoSystem,
        label: ecoSystem,
      }))}
      userEmail={email}
      userId={userId}
      disabled={isNetworkDisabled}
    />
  )
}

export default UserAffiliation
