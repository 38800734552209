import React, { useContext, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Button, useErrorService } from '@abroad/components'
import { useInput } from './hooks'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const DeactiveateSOWGroup = ({
  setIsAnyModalEventOccurs,
  closeClickedHandler,
  conditionFordisabledBtn,
  isRenderAsSOWModal = true,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const { companyName, ecoSystemName, sowId, sowGroupId } = useParams()
  const history = useHistory()
  const location = useLocation()
  const isGroupsListPage = location?.pathname.split('/').includes('sow-groups')

  const onHide = () => {
    setShowModal(false)
    setIsAnyModalEventOccurs(false)
    resetConfirmText()
  }

  const isValidconfirmText = (value) => value.toLowerCase() === 'deactivate'

  const {
    value: confirmText,
    isValid: confirmTextIsValid,
    hasError: confirmTextHasError,
    valueChangeHandler: confirmTextChangeHandler,
    inputBlurHandler: confirmTextBlurHandler,
    reset: resetConfirmText,
  } = useInput(isValidconfirmText)
  const { ids, setIds, setIsLoadNewData } = useContext(OrgEcoIdsContext)

  const submitHandler = async (event) => {
    event.preventDefault()
    if (!confirmTextIsValid) return

    const getAPIFunction = () => {
      if (isRenderAsSOWModal) {
        return API.AdminAPI.companyList.deactiveateSOW(
          sowId ? { ids: [sowId] } : { ids },
        )
      } else {
        return API.AdminAPI.companyList.deactiveateSOWGroups(
          sowGroupId ? { ids: [sowGroupId] } : { ids },
        )
      }
    }

    //API call
    setIsLoading(true)
    try {
      const { status } = await getAPIFunction()

      if (status) {
        setIsLoading(false)
        onHide()
        if (!sowId || isGroupsListPage) {
          setIsLoadNewData(true)
        }
        if (sowId && isRenderAsSOWModal) {
          history.push(
            `/admin/${
              companyName
                ? `organization/${companyName}`
                : `network/${ecoSystemName}`
            }/sows`,
          )
        }
        if (sowGroupId) {
          history.push(
            `/admin/${
              companyName
                ? `organization/${companyName}`
                : `network/${ecoSystemName}`
            }/sow/${sowId}/sow-groups`,
          )
        }
        closeClickedHandler()
        setIds([])
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <section>
      <Modal
        show={showModal}
        size='lg'
        onHide={onHide}
        className='custom-modal sow-deactivation custom__modal'
        centered>
        <Modal.Body>
          <h3 className='s2 title'>
            {isRenderAsSOWModal ? 'SOW' : 'Group'} Deactivation
          </h3>
          <p className='description s5'>
            <b>Warning:</b>{' '}
            {isRenderAsSOWModal
              ? `Users not part of other SOWs will be moved to a free
            plan.To deactive this SOW you must type ‘deactivate’ below`
              : `To deactive this Group you must type ‘deactivate’ below`}
          </p>
          <Form onSubmit={submitHandler}>
            <Form.Control
              type='text'
              value={confirmText}
              onChange={confirmTextChangeHandler}
              onBlur={confirmTextBlurHandler}
              className='s6 border-radius-3 sow-deactivation-input'
              isInvalid={confirmTextHasError}
              size='lg'
              sm='10'
            />
            <div className='d-flex justify-content-end'>
              <Button
                variant='none'
                size='md'
                className='border-radius-3 line-height-normal secondary-popup-button'
                onClick={onHide}>
                Cancel
              </Button>
              <Button
                variant='danger'
                size='md'
                className='border-radius-3 confirm-btn line-height-normal'
                isLoading={isLoading}
                disabled={isLoading || !confirmTextIsValid}
                onClick={() => setIsAnyModalEventOccurs(true)}
                type='submit'>
                Enter
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* conditionally show DeActiveate SOW */}
      {/* style issue of padding */}
      <Button
        variant='danger'
        size='md'
        className='deactiveate-sow border-radius-3 line-height-normal'
        disabled={isLoading || conditionFordisabledBtn}
        onClick={() => {
          setShowModal(true)
          setIsAnyModalEventOccurs(true)
        }}>
        DEACTIVEATE {isRenderAsSOWModal ? 'SOW' : 'GROUP'}
      </Button>
    </section>
  )
}

export default DeactiveateSOWGroup
