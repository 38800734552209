import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import { EditTeams } from '../../editTeams'
import { initialTeamState, useTeamReducer } from '../../editTeams/Reducer'
import API from '../../../utils/API'

const OrgEcoTeams = ({ setIsAnyModalEventOccurs, orgEcoId }) => {
  const { state, dispatch } = useTeamReducer()
  const [totalTeams, setTotalTeams] = useState(0)
  const { companyName } = useParams()
  const Error = useErrorService()

  useEffect(() => {
    const getTeams = async () => {
      try {
        dispatch({ type: 'SET_ORG_ECO_ID', payload: orgEcoId })
        dispatch({ type: 'TEAM_FETCH' })

        const { data } = await API.AdminAPI.teams.getOrganizationTeams(
          companyName ? 'company' : 'affiliate',
          orgEcoId,
        )
        if (data?.teams?.length === 0) {
          dispatch({
            type: 'TEAM_FETCH_SUCCESS',
            payload: [
              ...data.teams,
              ...Array.from(
                { length: 1 - data.teams.length },
                (x, i) => initialTeamState,
              ),
            ],
          })
        } else {
          const teamsData = data?.teams?.map((team) => ({
            name: team,
            isEditable: false,
          }))
          dispatch({
            type: 'TEAM_FETCH_SUCCESS',
            payload: teamsData,
          })
        }
      } catch (e) {
        Error.showError(e)
        dispatch({ type: 'TEAM_FETCH_FAIL', payload: null })
      }
    }
    if (orgEcoId) getTeams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgEcoId])

  useEffect(() => {
    if (state?.teams?.length > 0) {
      setTotalTeams(state?.teams.filter((team) => team?.name !== '').length)
    }
  }, [state.teams])

  return (
    <React.Fragment>
      <Form.Group
        className={classnames('align-self-start team-fields', {
          'custom-mb-0-625': totalTeams > 0,
          'mb-0': totalTeams === 0,
        })}>
        <Form.Label className='custom-mb-0-625 text-uppercase sidebar-label'>
          Departments
        </Form.Label>
        {state?.teams
          .filter((team, idx) => team?.name !== '')
          .filter((team, idx) => idx <= 2)
          .map((team, index) => (
            <React.Fragment key={index}>
              {team?.name && (
                <Form.Control
                  type='text'
                  key={index}
                  value={team?.name}
                  className={classnames(
                    'viewonly user-input border-radius-3 form-control-lg',
                    {
                      'custom-mb-0-625': index !== 2 || totalTeams === 3,
                      'mb-2': totalTeams > 3 && index === 2,
                    },
                  )}
                  disabled
                />
              )}
            </React.Fragment>
          ))}
        {totalTeams > 3 && (
          <span className='custom-mb-0-625 font-12 font-normal leading-4 tracking-normal font-open-sans'>{`+${
            totalTeams - 3
          } departments...`}</span>
        )}
      </Form.Group>
      <EditTeams setIsAnyModalEventOccurs={setIsAnyModalEventOccurs} />
    </React.Fragment>
  )
}

export default OrgEcoTeams
