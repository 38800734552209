import React from 'react'
import classNames from 'classnames'

const ClearFilters = ({
  wrapperClasses = '',
  onClick = () => {},
  disabled = false,
}) => {
  return (
    <div className={wrapperClasses}>
      <span
        onClick={onClick}
        className={classNames('text-dark-900 underline', {
          'pointer-cursor': !disabled,
          'cursor-disabled': disabled,
        })}>
        Clear Filters
      </span>
    </div>
  )
}

export default ClearFilters
