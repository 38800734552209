import React from 'react'
import CKEditor from '../editor'

export const CKSessionInsight = ({ data }) => {
  return (
    <CKEditor
      data={data}
      placeholder='Share Session Insights with the Abroad Coaching Team. Non-Confidential Insights may be specific to this session or the overall engagement.'
      disabled={true}
    />
  )
}
