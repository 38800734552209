import React, { Suspense } from 'react'
import { Container } from 'react-bootstrap'
import { ABSpinner } from '@abroad/components'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
const MediaForm = React.lazy(() =>
  import('../components/upsertMedia/MediaForm'),
)
const HeaderCategory = React.lazy(() =>
  import('../components/layout/HeaderCategory'),
)

const Profile = ({ t }) => {
  return (
    <>
      <Helmet>
        <title>{'Upsert Media | Abroad'}</title>
        <meta name='title' content={'Upsert Media | Abroad'}></meta>
        <meta property='og:title' content={'Upsert Media | Abroad'}></meta>
        <meta property='twitter:title' content={'Upsert Media | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/media`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/media`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/media`}></meta>
      </Helmet>
      <main>
        <section>
          <Container
            className='pt-5 custom-offset-lg-1 custom-col-lg-12 px-0'
            fluid>
            <Suspense fallback={<ABSpinner />}>
              <HeaderCategory />
              <MediaForm />
            </Suspense>
          </Container>
        </section>
      </main>
    </>
  )
}

export default withTranslation('translation')(Profile)
