import React, { useState } from 'react'
import { ListingFiltersContext } from '../../../utils/contexts'
import labels from '../../../constants/labels'
import { getStatusDefaultFilterValue } from '../../../utils/utility'

const anyFilterValue = getStatusDefaultFilterValue()

const initialStateOfFilter = {
  search: '',
  type: labels.orgFilterDefaultType.SOW_OR_INVITE_ONLY,
  status: labels.orgFilterStatusDefaultType.ORG_ACTIVE,
  sort: 'ASC',
}
const initialStateOfFilterForNetwork = {
  search: '',
  isItProgram: anyFilterValue,
  isEmailSet: anyFilterValue,
  status: labels.orgFilterStatusDefaultType.ORG_ACTIVE,
  sort: 'ASC',
}

const ListingFiltersProvider = ({ children, type = null }) => {
  const [filters, setFilters] = useState(
    type === 'network' ? initialStateOfFilterForNetwork : initialStateOfFilter,
  )
  return (
    <ListingFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </ListingFiltersContext.Provider>
  )
}

export default ListingFiltersProvider
