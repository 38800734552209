import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useUserService } from '@abroad/components'

const PrivateRoute = ({ children, component: Component, render, ...rest }) => {
  const { user } = useUserService()
  return (
    <Route
      {...rest}
      render={({ location, ...renderProps }) => {
        const query = new URLSearchParams(location?.search)
        const nextUrl = query.get('next')
        if (render && user) {
          render({ ...location, ...renderProps })
          return
        }
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: `${location.pathname}${location.search}` },
              }}
            />
          )
        } else if (user?.isAdmin || user?.isCoach) {
          const isTwoFactorVerified = user?.isTwoFactorVerified
          if (!isTwoFactorVerified) {
            window.location.replace(
              `${process.env.REACT_APP_CLIENT_DOMAIN}/2fa-setup${
                nextUrl ? `?next=${nextUrl}` : ''
              }`,
            )
          } else {
            return children || <Component />
          }
        } else {
          return <Redirect to='/404' />
        }
      }}
    />
  )
}

export default PrivateRoute
