import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  useErrorService,
  ABSpinner,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { UpdateClientTeamSession } from '../components'
import API from '../utils/API'

export const UpdateSessionOfUser = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFetchSessionData, setIsFetchSessionData] = useState(true)
  const [sessionData, setSessionData] = useState([])
  const [isSessionUpdateLoading, setIsSessionUpdateLoading] = useState(false)
  const [sessionInsights, setSessionInsights] = useState([])
  const [nonConfidentialsessionInsights, setNonConfidentialSessionInsights] =
    useState([])
  const [postSession, setPostSession] = useState([])
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [mediaInfo, setMediaInfo] = useState([])
  const [clientInfo, setClientInfo] = useState([])
  const [keySkillSearchData, setKeySkillSearchData] = useState([])
  const [searchKeySkillData, setSearchKeySkillData] = useState([])
  const [searchKeySkill, setSearchKeySkill] = useState('')
  const [inputFocusOfKeySkill, setInputFocusOfKeySkill] = useState(false)
  const [isSearchKeySkill, setIsSearchKeySkill] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { clientId, sessionId } = useParams()
  const { user } = useUserService()
  const history = useHistory()

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getUserInfo(clientId)
        if (data) {
          setClientInfo(data)
          setIsLoading(false)
          const coachIds = data?.coachIds?.map((coach) => coach?._id)
          if (!user?.isAdmin && !coachIds.includes(user?._id)) {
            history.push('/')
          }
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getClientSessionData = async () => {
      try {
        const { data } = await API.CoachAPI.coachingSession.getClientSession(
          sessionId,
          clientId,
        )
        if (data) {
          setSessionData(data)
          setIsFetchSessionData(false)
        }
      } catch (e) {
        setIsFetchSessionData(false)
        Error.showError(e)
      }
    }
    getClientSessionData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sessionData) {
      setSessionInsights(sessionData?.coachNotes)
      setPostSession(
        sessionData?.postSession !== undefined ? sessionData?.postSession : '',
      )
      setNonConfidentialSessionInsights(
        sessionData?.coachNotesNonConfidential !== undefined
          ? sessionData?.coachNotesNonConfidential
          : '',
      )
      setSearch(sessionData?.media?.title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData])

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        const { data } = await API.AdminAPI.common.getSearchMedia(
          search,
          'user',
          clientId,
        )
        if (data) {
          setSearchData(data)
          setIsSearch(false)
          data.length > 0 && setMediaInfo(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (search === '' || search === undefined) {
      return
    } else {
      getSearchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const getSearchData = () => {
      setIsSearchKeySkill(true)
      const data = keySkillSearchData.filter((data) =>
        data?.title?.toLowerCase()?.includes(searchKeySkill?.toLowerCase()),
      )

      setSearchKeySkillData(data)
      setIsSearchKeySkill(false)
    }
    getSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeySkill, keySkillSearchData])

  useEffect(() => {
    const getKeySkillData = async () => {
      try {
        const { data } = await API.AdminAPI.common.getKeySkillSearchPdf()
        if (data) {
          setKeySkillSearchData(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getKeySkillData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || isFetchSessionData) {
    return <ABSpinner />
  }

  const onSubmit = async ({ mediaIds, moduleIds, keySkillsIds }) => {
    if (sessionInsights.length === 0) {
      Notification.showNotification('Please Enter Session Insights!', 'danger')
    } else {
      let params = {
        coachNotes: sessionInsights,
        coachNotesNonConfidential:
          nonConfidentialsessionInsights.length === 0
            ? ''
            : nonConfidentialsessionInsights,
        postSession: postSession.length === 0 ? '' : postSession,
        mediaIds: mediaIds,
        moduleIds: moduleIds,
        keySkillIds: keySkillsIds,
      }
      search === mediaInfo[0]?.title
        ? setSearch(mediaInfo[0]?.title)
        : setSearch('')
      try {
        setIsSessionUpdateLoading(true)
        const { data } = await API.CoachAPI.coachClient.sessionUpdate(
          clientId,
          sessionId,
          params,
        )
        if (data) {
          setIsSessionUpdateLoading(false)
          Notification.showNotification('session has been updated!', 'success')
        }
      } catch (e) {
        setIsSessionUpdateLoading(false)
        Error.showError(e)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${clientInfo?.fname}'s Session | Abroad`}</title>
        <meta
          name='title'
          content={`${clientInfo?.fname}'s Session | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${clientInfo?.fname}'s Session | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${clientInfo?.fname}'s Session | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/session/${sessionId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/session/${sessionId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/session/${sessionId}`}></meta>
      </Helmet>
      <main className='py-3'>
        <UpdateClientTeamSession
          sessionData={sessionData}
          sessionInsights={sessionInsights}
          setSessionInsights={setSessionInsights}
          showCKCondition={sessionData?.coach?._id}
          isTeamSession={false}
          clientFullName={clientInfo?.fullName}
          nonConfidentialsessionInsights={nonConfidentialsessionInsights}
          setNonConfidentialSessionInsights={setNonConfidentialSessionInsights}
          postSession={postSession}
          setPostSession={setPostSession}
          search={search}
          setSearch={setSearch}
          inputFocus={inputFocus}
          setInputFocus={setInputFocus}
          searchData={searchData}
          isSearch={isSearch}
          isSessionUpdateLoading={isSessionUpdateLoading}
          onSubmit={onSubmit}
          medias={[...sessionData?.medias, ...sessionData?.mediaModules]}
          searchKeySkill={searchKeySkill}
          setSearchKeySkill={setSearchKeySkill}
          searchKeySkillData={searchKeySkillData}
          isSearchKeySkill={isSearchKeySkill}
          inputFocusOfKeySkill={inputFocusOfKeySkill}
          setInputFocusOfKeySkill={setInputFocusOfKeySkill}
          keySkillPdfsData={sessionData?.keySkills}
        />
      </main>
    </>
  )
}
