import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form as RBForm, Container } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { string, object } from 'yup'
import { useParams } from 'react-router-dom'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import GetTeamMemberData from './GetTeamMemberData'
import FreeSelectTeamMembers from './FreeSelectTeamMembers'
import { CustomSelectionField, InputField } from '../form'
import { SelectionField } from '../common'
import { getGroupLabel, isLGScreen, isSOWGroups } from '../../utils/utility'
import Labels from '../../constants/labels'
import API from '../../utils/API'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const formInitialValues = {
  teamName: '',
  groupType: '',
  group: '',
  freeSelectteamMembers: [],
  teamMembers: [],
  email: '',
  coaches: [],
}

const validationSchema = object().shape({
  teamName: string().required().min(2).max(100),
  groupType: string().required(),
  group: string().when('groupType', {
    is: 'Free Select',
    then: string().notRequired(),
    otherwise: () => string().required(),
  }),
  teamMembers: string().when('groupType', {
    is: 'Free Select',
    then: string().notRequired(),
    otherwise: () => string().required(),
  }),
  freeSelectteamMembers: string().when('groupType', {
    is: 'Free Select',
    then: string().required(),
    otherwise: () => string().notRequired(),
  }),
  email: string().required(),
  coaches: string().required(),
})

export const TeamInformation = ({ teamMetadata, setTeamName }) => {
  const { user } = useUserService()
  const [isLoading, setIsLoading] = useState(true)
  const [isCoachFetchLoading, setIsCoachFetchLoading] = useState(true)
  const [isTeamCreateLoading, setIsTeamCreateLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [coachesData, setCoachesData] = useState([])
  const [membersData, setMembersData] = useState([])
  const [fieldDisabled, setFieldDisabled] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { teamId } = useParams()

  useEffect(() => {
    if (user && teamMetadata)
      setFieldDisabled(!user?.isAdmin || teamMetadata?.isArchived)
  }, [user, teamMetadata])

  useEffect(() => {
    const getTeamData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamData(teamId)
        if (data) {
          setInitialValues((prev) => ({
            ...prev,
            teamName: data?.name,
            groupType: getGroupLabel(data?.groupType),
            isSOWGroup: isSOWGroups(data?.groupType),
            b2bSOWId: data?.b2bSOWId ? data?.b2bSOWId : null,
            type:
              data?.groupType === 5
                ? 'organization'
                : data?.groupType === 6
                ? 'network'
                : null,
            group:
              data?.groupType === 4
                ? ''
                : data?.groupType === 5 || data?.groupType === 6
                ? data?.groupId?.name
                : data?.groupName,
            freeSelectteamMembers:
              data?.groupType === 4
                ? data?.members?.map((member) => ({
                    value: member?._id,
                    label: `${member.fullName}(${member.email})`,
                  }))
                : [],
            teamMembers:
              data?.groupType !== 4
                ? data?.members?.map((member) => ({
                    value: member?._id,
                    label: `${member.fullName}(${member.email})`,
                  }))
                : [],
            email: data?.emailName,
            coaches:
              data?.coaches?.length > 0
                ? data?.coaches?.map((coach) => ({
                    value: coach?._id,
                    label: coach.fullName,
                  }))
                : [],
          }))
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (teamId) getTeamData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  useEffect(() => {
    const getCoachData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getCoachData()
        if (data) {
          setCoachesData(data)
          setIsCoachFetchLoading(false)
        }
      } catch (e) {
        setIsCoachFetchLoading(false)
        Error.showError(e)
      }
    }
    if (user.isAdmin) {
      getCoachData()
    } else {
      setIsCoachFetchLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values) => {
    let params = {
      name: values.teamName,
      members:
        values.groupType === 'Free Select'
          ? values.freeSelectteamMembers.map((member) => member?.value)
          : values.teamMembers.map((member) => member?.value),
      emailName: values.email,
      coaches: values.coaches.map((coach) => coach.value),
    }

    Object.keys(params).forEach(
      (key) => params[key] === '' && delete params[key],
    )

    try {
      setIsTeamCreateLoading(true)
      const { data } = await API.AdminAPI.teams.updateTeam(teamId, params)
      if (data) {
        setIsTeamCreateLoading(false)
        setTeamName(values?.teamName)
        Notification.showNotification('Group has been updated!', 'success')
      }
    } catch (e) {
      setIsTeamCreateLoading(false)
      Error.showError(e)
    }
  }

  if (isLoading || isCoachFetchLoading) {
    return <ABSpinner />
  }

  return (
    <Container className='mt-5 px-lg-0' fluid>
      <Formik
        displayName='profile-form'
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnMount
        onSubmit={onSubmit}>
        {({ values, handleSubmit }) => {
          return (
            <RBForm
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit(e)
              }}
              as={Form}
              className='profile px-4 px-lg-0'>
              <InputField
                label='Name'
                labelProps={labelProps}
                name='teamName'
                type='string'
                size='md'
                placeholder='Group Name'
                sm='10'
                disabled={fieldDisabled}
              />
              <SelectionField
                label='Group Type'
                labelProps={labelProps}
                name='groupType'
                id='groupType'
                optionplaceholder='Select one'
                options={Labels?.groupType}
                disabled
                className='cursor-disabled'
                size='md'
              />
              <GetTeamMemberData
                render={(teamMemberData) => {
                  setMembersData(teamMemberData)
                }}
              />
              <SelectionField
                label='Group'
                labelProps={labelProps}
                name='group'
                optionplaceholder='Select one'
                options={
                  values.groupType !== 'Free Select' ? [values?.group] : []
                }
                className={classNames('cursor-disabled', {
                  'text-uppercase': values.groupType === 'Promo Code',
                })}
                disabled
                size='md'
              />

              <RBForm.Group>
                {values.groupType !== 'Free Select' && (
                  <CustomSelectionField
                    name={'teamMembers'}
                    placeholder='Select'
                    label='THE GROUP MEMBERS'
                    className='mb-0'
                    options={membersData?.map((data) => ({
                      value: data?._id,
                      label: `${data.fullName}(${data.email})`,
                    }))}
                    disabled={fieldDisabled}
                    maxMenuHeight={140}
                  />
                )}
                {values.groupType === 'Free Select' && (
                  <FreeSelectTeamMembers
                    name='freeSelectteamMembers'
                    placeholder='Search Team Member.'
                    className='mb-0'
                    label='THE TEAM MEMBERS'
                    options={[]}
                    disabled={
                      (user?.isCoach && !user?.isAdmin) ||
                      teamMetadata?.isArchived
                    }
                  />
                )}
              </RBForm.Group>
              <CustomSelectionField
                label='THE GROUP COACHES'
                name='coaches'
                placeholder='Select'
                options={
                  fieldDisabled
                    ? []
                    : coachesData?.map((coach) => ({
                        value: coach?._id,
                        label: coach.fullName,
                      }))
                }
                disabled={fieldDisabled}
                maxMenuHeight={140}
              />
              {!values?.isSOWGroup && (
                <InputField
                  label='Email Salutation'
                  labelProps={labelProps}
                  name='email'
                  type='text'
                  size='md'
                  placeholder='Email here.'
                  showTooltipMessage={true}
                  dataTooltip='This is the text that &#013;
                gets inserted into the &#013;
                email body "Dear ____", &#013;
                this is an automatic email that &#013;
                notifies this group &#013;
                when you log Post-Session &#013;
                Practices. &#013;
                '
                  extraIcon={
                    <svg
                      aria-hidden='true'
                      focusable='false'
                      data-prefix='fas'
                      data-icon='question-circle'
                      className='svg-inline--fa fa-question-circle fa-w-16'
                      role='img'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 512 512'
                      width='20'
                      height='20'>
                      <path
                        fill='currentColor'
                        d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z'></path>
                    </svg>
                  }
                  sm='10'
                  disabled={fieldDisabled}
                />
              )}
              {user?.isAdmin &&
                !teamMetadata?.isArchived &&
                !values?.isSOWGroup && (
                  <>
                    {!fieldDisabled && (
                      <div className='text-right'>
                        <Button
                          isLoading={isTeamCreateLoading}
                          disabled={isTeamCreateLoading}
                          type='submit'
                          variant='gray'
                          size='md'
                          className='line-height-normal border-radius-3 text-uppercase'>
                          UPDATE
                        </Button>
                      </div>
                    )}
                  </>
                )}
            </RBForm>
          )
        }}
      </Formik>
    </Container>
  )
}
