import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { useErrorService } from '@abroad/components'
import RemoveCoachesModal from './RemoveCoachesModal'
import API from '../../utils/API'
import CoachPaymentRatesModal from '../common/CoachPaymentRatesModal'

const initialStateOfModal = {
  show: false,
  id: null,
}

const SOWCoachesActions = ({
  coachInfo,
  removeCoachFromSOW,
  updateCoachRate,
}) => {
  const [showModal, setShowModal] = useState(initialStateOfModal)
  const [showCoachPaymentModal, setShowCoachPaymentModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { sowId } = useParams()
  const Error = useErrorService()
  const onHide = () => setShowModal(initialStateOfModal)

  const removeCoachesFromSOW = async () => {
    setIsSubmitting(true)
    try {
      const { status } = await API.AdminAPI.companyList.removeCoachesFromSOW(
        sowId,
        { coachIds: showModal.id },
      )
      if (status) {
        removeCoachFromSOW(showModal?.id[0])
        setIsSubmitting(false)
        setShowModal(initialStateOfModal)
        onHide()
      }
    } catch (e) {
      Error.showError(e)
      setIsSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <CoachPaymentRatesModal
        showModal={showCoachPaymentModal}
        onHide={() => setShowCoachPaymentModal(false)}
        data={coachInfo}
        addNewCoachPaymentRate={(newValue) => updateCoachRate(newValue)}
      />
      <RemoveCoachesModal
        showModal={showModal?.show}
        ids={showModal.id}
        removeCoachesFromSOW={removeCoachesFromSOW}
        onHide={onHide}
        isSubmitting={isSubmitting}
      />
      <span
        className={classNames('s6 text-saffron-700 edit-coach font-bold', {
          'cursor-disabled opacity-point-5': coachInfo?.coachId?.isStaffCoach,
          'cursor-pointer': !coachInfo?.coachId?.isStaffCoach,
        })}
        onClick={() => {
          if (coachInfo?.coachId?.isStaffCoach) return
          setShowCoachPaymentModal(true)
        }}>
        Edit
      </span>
      <span
        className='s6 text-red-800 font-bold cursor-pointer'
        onClick={() =>
          setShowModal({
            show: true,
            id: [coachInfo?.coachId?._id],
          })
        }>
        Delete
      </span>
    </React.Fragment>
  )
}

export default SOWCoachesActions
