import React from 'react'
import { Helmet } from 'react-helmet'
import { Users } from '../components'

export const UserList = () => {
  return (
    <>
      <Helmet>
        <title>{'Users | Abroad'}</title>
        <meta name='title' content={'Users | Abroad'}></meta>
        <meta property='og:title' content={'Users | Abroad'}></meta>
        <meta property='twitter:title' content={'Users | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/users`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/users`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/users`}></meta>
      </Helmet>
      <main className='h-100'>
        <section className='filter-area'>
          <Users />
        </section>
      </main>
    </>
  )
}
