import {
  ABSpinner,
  useErrorService,
  useNotificationService,
  CloseIcon,
} from '@abroad/components'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import API from '../../utils/API'

const HeaderCategory = () => {
  const Error = useErrorService()
  const Notification = useNotificationService()
  let history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [categoryName, setCategoryName] = useState(null)
  const [subCategoryName, setSubCategoryName] = useState(null)
  const [moduleImageFileName, setModuleImageFileName] = useState(null)
  const [uploadImageMessage, setUploadImageMessage] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [signedUrlObject, setSignedUrlObject] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  let { mediaCategory, catId, subCatId } = useParams()

  useEffect(() => {
    const getProfile = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.AdminAPI.mediaLibrary.getCategoryName(
          catId,
          subCatId,
        )
        const { category, subCategory } = data
        setCategoryName(category.title)
        setSubCategoryName(subCategory.title)
        setModuleImageFileName(subCategory.imageFilename)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteImage = async () => {
    try {
      setIsLoading(true)
      await API.AdminAPI.mediaLibrary.updateModuleImageBySubCatId(subCatId, {
        imageFilename: '',
      })
      setIsLoading(false)
      window.location.reload()
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const getSignedUrl = async () => {
    try {
      setIsLoading(true)
      const { data } =
        await API.AdminAPI.mediaLibrary.getSignedUrlForModuleImageUpload(
          subCatId,
        )
      setSignedUrlObject(data)
      setIsLoading(false)
      return data
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const getImage = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const imageFile = files[0]
      setImageFile(imageFile)
    }
  }

  const uploadImageFile = async (e) => {
    e.preventDefault()
    if (!imageFile) {
      Notification.showNotification('Please upload image!', 'danger')
      return
    }
    const mSignedUrlObject = await getSignedUrl()
    setUploadImageMessage('Uploading...')
    const contentType = imageFile.type // eg. image/jpeg or image/svg+xml

    const putUrl = mSignedUrlObject.url
    const options = {
      headers: {
        'Content-Type': contentType,
      },
    }

    axios
      .put(putUrl, imageFile, options)
      .then((res) => {
        Notification.showNotification('Upload Successful', 'success')
        setImageUploaded(true)
      })
      .catch((err) => {
        setUploadImageMessage('Sorry, something went wrong')
      })
  }

  useEffect(() => {
    if (!signedUrlObject) {
      return
    }
    const updateMedia = async () => {
      try {
        let imageFilename = ''
        if (moduleImageFileName) {
          imageFilename = moduleImageFileName
        }
        if (imageUploaded) {
          imageFilename = signedUrlObject.filename
        }
        setIsUpdating(true)
        await API.AdminAPI.mediaLibrary.updateModuleImageBySubCatId(subCatId, {
          imageFilename: imageFilename,
        })
        setIsUpdating(false)
        window.location.reload()
      } catch (e) {
        setIsUpdating(false)
        Error.showError(e)
      }
    }
    updateMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUploaded])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <Row className='mt-4'>
      <Col className='col-6'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <div
                className='cursor-pointer'
                onClick={(e) => {
                  history.push(`/admin/media`)
                }}>
                {categoryName}
              </div>
            </li>
            <li className='breadcrumb-item'>
              <div
                className='cursor-pointer'
                onClick={(e) => {
                  history.push(
                    `/admin/media/${mediaCategory}/${catId}/${subCatId}`,
                  )
                }}>
                {subCategoryName}
              </div>
            </li>
          </ol>
        </nav>
      </Col>
      {!moduleImageFileName && mediaCategory === 'program' && (
        <Col className='col-6'>
          <input
            id='upload-image'
            type='file'
            accept={
              signedUrlObject ? signedUrlObject.contentType : 'image/jpeg'
            }
            onChange={getImage}
          />
          <p>{uploadImageMessage}</p>
          <form onSubmit={(e) => uploadImageFile(e)}>
            <button id='file-upload-button'>Upload Image</button>
          </form>
        </Col>
      )}
      {moduleImageFileName && mediaCategory === 'program' && (
        <Col className='col-6'>
          <Imgix
            className='border-radius-10'
            imgixParams={{
              fit: 'crop',
              crop: 'edges',
              w: 400,
              h: 400,
            }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/${moduleImageFileName}`}
            height={160}
            width={320}
            alt='image'
          />
          <span className='image-delete' onClick={() => deleteImage()}>
            <CloseIcon color='#000' discardMargin={true} />
          </span>
        </Col>
      )}
      {isUpdating && <ABSpinner />}
    </Row>
  )
}

export default HeaderCategory
