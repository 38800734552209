import React, { useState, useContext } from 'react'
import { useErrorService, NotificationContext } from '@abroad/components'
import API from '../../utils/API'
import CheckBox from './CheckBox'

const ToggleEcosystemPrivileges = ({ userId, isEcosystemAdmin, index }) => {
  const [checkboxStatus, setCheckboxStatus] = useState({
    disabled: false,
    checked: isEcosystemAdmin,
  })
  const Error = useErrorService()
  const { showNotification } = useContext(NotificationContext)

  const toggleEcosystemPrivileges = async (e) => {
    setCheckboxStatus({
      disabled: true,
      checked: e.target.checked,
    })
    try {
      const { data } = await API.AdminAPI.userList.toggleEcosystemPrivileges(
        {
          isEcosystemAdmin: e.target.checked,
        },
        userId,
      )
      if (data) {
        setCheckboxStatus((prev) => ({
          ...prev,
          disabled: false,
        }))
        showNotification('User Changes Saved!', 'success')
      }
    } catch (e) {
      setCheckboxStatus({
        disabled: false,
        checked: isEcosystemAdmin,
      })
      Error.showError(e)
    }
  }

  return (
    <>
      <CheckBox
        onChange={toggleEcosystemPrivileges}
        checked={checkboxStatus.checked}
        disabled={checkboxStatus.disabled}
        id={`Network-Assessment-${index}`}
        className='privileges-checkbox ml-0'
      />
      <label
        className='form-check-label ml-1'
        htmlFor={`Network-Assessment-${index}`}>
        Network
        <br />
        Assessment
      </label>
    </>
  )
}

export default ToggleEcosystemPrivileges
