import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'
import sanitizeHtml from 'sanitize-html'
import { useNotificationService } from '@abroad/components'
import { SubHeader } from '../common'
import CONSTANTS from '../../utils/constants'
import ShowCharsLimt from '../common/ShowCharsLimt'
import useToSetCharsLimit from '../common/hooks/useCountCharactersLimit'
import { JourneyMediasContext } from '../../utils/contexts'

const IntroductoryNotes = ({
  notes = '',
  wrapperClasses = '',
  setIsMaxCharLimitExceeded,
}) => {
  const [enteredNotes, setEnteredNotes] = useState(notes)
  const charsLimitInNotes = 1500
  const { showNotification } = useNotificationService()
  const {
    totalChars: totalEnteredCharsInNotes,
    updateCharsLength: updateCharsLengthForNotes,
  } = useToSetCharsLimit()
  const { setNotes } = useContext(JourneyMediasContext)

  const calculateNotesLength = (value) => {
    const valueLength = sanitizeHtml(value, {
      allowedTags: [],
      allowedAttributes: {},
    })?.length
    return valueLength
  }

  const saveNotesHandler = () => {
    const totalChars = calculateNotesLength(enteredNotes)
    if (totalChars > charsLimitInNotes) {
      showNotification(
        'Character limit exceeded. Max allowed characters for Introductory Notes is 1500.',
        'danger',
      )
      setIsMaxCharLimitExceeded(true)
    } else {
      setIsMaxCharLimitExceeded(false)
      setNotes(enteredNotes)
    }
  }

  useEffect(() => {
    updateCharsLengthForNotes(calculateNotesLength(enteredNotes))
  }, [enteredNotes, updateCharsLengthForNotes])

  return (
    <section className={classNames('notes col-10 px-0', wrapperClasses)}>
      <SubHeader
        title={CONSTANTS.JOURNEYS_METADATA.notes.title}
        contentText={CONSTANTS.JOURNEYS_METADATA.notes.contentText}
      />
      <div className='mt-3 note-input-section'>
        <Form.Control
          as='textarea'
          placeholder='Enter the notes you would like your client to see here'
          className='font-open-sans font-14 font-semibold leading-6 letter-spacing-minus-02 text-dark-900-5 notes-input'
          rows={8}
          value={enteredNotes}
          onChange={(e) => setEnteredNotes(e.target.value)}
          onBlur={saveNotesHandler}
        />
        <ShowCharsLimt
          limit={charsLimitInNotes}
          enteredChars={totalEnteredCharsInNotes}
          wrapperClasses='mb-0'
        />
      </div>
    </section>
  )
}

export default IntroductoryNotes
