import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const CompanyMetadataOverlay = ({ toolTipText = '' }) => {
  const placement = 'bottom'
  return (
    <>
      {toolTipText ? (
        <OverlayTrigger
          key={placement}
          placement={placement}
          overlay={
            <Tooltip
              id={`tooltip-${placement}-1`}
              className='font-open-sans font-16 font-semibold leading-7 text-center header-tooltip'>
              {toolTipText}
            </Tooltip>
          }>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            className='cursor-pointer'>
            <circle cx='12' cy='12' r='12' fill='black' />
            <circle cx='12' cy='6.50391' r='1.5' fill='white' />
            <rect
              x='10.5'
              y='10.0039'
              width='3'
              height='9'
              rx='1.5'
              fill='white'
            />
          </svg>
        </OverlayTrigger>
      ) : null}
    </>
  )
}

export default CompanyMetadataOverlay
