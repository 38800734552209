import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import CkForCategoriesLogs from './CkForCategoriesLogs'
import ContextAwareToggle from './ContextAwareToggle'

const CategoriesNotes = ({
  logs,
  sessionLogs,
  saveLogHandler = () => {},
  disabled,
  isViewGPSection = false,
}) => {
  return (
    <>
      {logs?.logs?.map((data, index) => (
        <Accordion defaultActiveKey='0' key={index}>
          {data?.keyQuestions[0]?.stages ? (
            <div className='pb-3' key={data?.keyQuestions[0]?.name}>
              <ContextAwareToggle
                eventKey={
                  index === 0 && !isViewGPSection
                    ? '0'
                    : data?.keyQuestions[0]?.name
                }
                title={data?.results?.title}
                percent={`${data?.results?.percent}%`}
                isEmpty={
                  sessionLogs?.answers?.[data?.keyQuestions[0]?.name]
                    ? false
                    : true
                }
                qkey={data?.keyQuestions[0]?.name}
              />
              <Accordion.Collapse
                eventKey={
                  index === 0 && !isViewGPSection
                    ? '0'
                    : data?.keyQuestions[0]?.name
                }>
                <>
                  <CkForCategoriesLogs
                    disabled={disabled}
                    answers={
                      sessionLogs?.answers?.[data?.keyQuestions[0]?.name]
                    }
                    question={data?.keyQuestions[0]?.q}
                    qkey={data?.keyQuestions[0]?.name}
                    handleSaveLog={saveLogHandler}
                    key={data?.keyQuestions[0]?.name}
                  />
                  {data?.keyQuestions[0]?.stages.map((data, index) => (
                    <section className='pb-3' key={index}>
                      <Row className='d-flex'>
                        <Col lg={10} md={10} sm={12}>
                          <CkForCategoriesLogs
                            disabled={disabled}
                            question={`Stage ${index + 1}: ${data?.q}`}
                            answers={sessionLogs?.answers?.[data?.name]}
                            qkey={data?.name}
                            handleSaveLog={saveLogHandler}
                            key={index}
                          />
                        </Col>
                        <Col lg={2} md={2} sm={12}></Col>
                      </Row>
                    </section>
                  ))}
                </>
              </Accordion.Collapse>
            </div>
          ) : (
            <div className='pb-3' key={index}>
              <ContextAwareToggle
                eventKey={
                  index === 0 && !isViewGPSection
                    ? '0'
                    : data?.keyQuestions[0]?.name
                }
                title={data?.results?.title}
                percent={`${data?.results?.percent}%`}
                isEmpty={
                  sessionLogs?.answers?.[data?.keyQuestions[0]?.name]
                    ? false
                    : true
                }
                qkey={data?.keyQuestions[0]?.name}
              />
              <Accordion.Collapse
                eventKey={
                  index === 0 && !isViewGPSection
                    ? '0'
                    : data?.keyQuestions[0]?.name
                }>
                <CkForCategoriesLogs
                  disabled={disabled}
                  answers={sessionLogs?.answers?.[data?.keyQuestions[0]?.name]}
                  question={data?.keyQuestions[0]?.q}
                  qkey={data?.keyQuestions[0]?.name}
                  handleSaveLog={saveLogHandler}
                  key={data?.keyQuestions[0]?.name}
                />
              </Accordion.Collapse>
            </div>
          )}
        </Accordion>
      ))}
    </>
  )
}

export default CategoriesNotes
