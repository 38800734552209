import React, { useContext } from 'react'
import { AccordionContext, useAccordionToggle } from 'react-bootstrap'
import classnames from 'classnames'

const IntentionAccordianContext = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className='cursor-pointer accordion-icon' onClick={decoratedOnClick}>
      <svg
        className={classnames({
          'rotate-180': isCurrentEventKey,
        })}
        xmlns='http://www.w3.org/2000/svg'
        width='40'
        height='40'
        viewBox='0 0 70 70'>
        <g transform='translate(-1075 -874)'>
          <circle
            // fill='#646464'
            cx='35'
            cy='35'
            r='35'
            transform='translate(1145 874) rotate(90)'
          />
          <g transform='translate(18.676 -33.748)'>
            <line
              fill='none'
              stroke='#fff'
              strokeLinecap='round'
              strokeWidth='3'
              x2='16.288'
              y2='13.176'
              transform='translate(1104.676 934.748) rotate(90)'
            />
            <line
              fill='none'
              stroke='#fff'
              strokeLinecap='round'
              strokeWidth='3'
              y1='13.176'
              x2='16.288'
              transform='translate(1091.5 934.748) rotate(90)'
            />
          </g>
        </g>
      </svg>
    </a>
  )
}

export default IntentionAccordianContext
