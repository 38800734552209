import React from 'react'
import { Form, Table } from 'react-bootstrap'
import classNames from 'classnames'
import { FeatherLoader } from '@abroad/components'
import UserRow from './UserRow'

export const UserInfoInTable = React.memo(
  React.forwardRef(
    (
      {
        users,
        isLoading,
        TextWhenNoUser,
        wrapperClasses = '',
        isInitiallyLoad,
      },
      ref,
    ) => {
      return (
        <Form
          className={classNames(
            'custom-offset-lg-1 custom-col-lg-12',
            wrapperClasses,
          )}
          id='users-list'>
          <div className='mt-3'>
            <Table responsive hover className='h-100 custom-table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th className='userinfo-fullname'>Full Name</th>
                  <th>Organization</th>
                  <th>Network</th>
                  <th>Sign Up Date</th>
                  <th>Promo</th>
                  <th>Assessments</th>
                  <th>Privileges</th>
                </tr>
              </thead>
              <tbody>
                {isInitiallyLoad && isLoading && (
                  <tr>
                    <td className='text-center' colSpan='8'>
                      <FeatherLoader />
                    </td>
                  </tr>
                )}
                {!isLoading && users.users?.length === 0 ? (
                  <tr>
                    <td className='text-center' colSpan='8'>
                      {TextWhenNoUser}
                    </td>
                  </tr>
                ) : (
                  users.users?.map((user, index) => (
                    <UserRow key={index} index={index} user={user} />
                  ))
                )}
                {users.isLoadMore && <tr ref={ref}></tr>}
              </tbody>
            </Table>
            <Table borderless>
              <tbody>
                {!isInitiallyLoad && users.isLoadMore && (
                  <tr>
                    {isLoading && (
                      <td className='text-center' colSpan='8'>
                        <FeatherLoader />
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Form>
      )
    },
  ),
)
