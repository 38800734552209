import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ABSpinner, useErrorService } from '@abroad/components'
import { ObjectivesForm } from '../components/objectives'
import CONSTANTS from '../utils/constants'
import { ObjectivesContext } from '../utils/contexts'
import API from '../utils/API'

const ObjectivesWrapper = (props) => {
  const orgId = props?.companyAndEcoInfo?.id
  const [isLoading, setIsLoading] = useState(true)
  const [objectivesData, setObjectivesData] = useState(null)
  const Error = useErrorService()
  const objectivesArraySize = 5

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const { data: metaData } =
          await API.AdminAPI.companyList.getCompanyMetadata(orgId)
        if (metaData) {
          const data = metaData?.objectives
          setObjectivesData(
            Object.keys(metaData?.objectives)?.length !== 0
              ? {
                  description: data?.description || '',
                  objectivesList: [
                    ...(data && data?.objectivesList
                      ? [
                          ...data?.objectivesList,
                          ...Array.from(
                            {
                              length:
                                objectivesArraySize -
                                data?.objectivesList.length,
                            },
                            () => '',
                          ),
                        ]
                      : null),
                  ],
                }
              : {
                  description: '',
                  objectivesList: [
                    ...Array.from(
                      {
                        length: 5,
                      },
                      () => '',
                    ),
                  ],
                },
          )
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getMetadata()
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <ObjectivesContext.Provider
      value={{
        setData: (obj) => setObjectivesData(obj),
        data: objectivesData,
      }}>
      <Objectives {...props} />
    </ObjectivesContext.Provider>
  )
}

const Objectives = ({ companyAndEcoInfo }) => {
  const { companyName } = useParams()
  const companyId = companyAndEcoInfo?.id

  return (
    <>
      <Helmet>
        <title>{`${companyName}'s Objectives | Abroad`}</title>
        <meta
          name='title'
          content={`${companyName}'s Objectives | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${companyName}'s Objectives | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${companyName}'s Objectives | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/objectives`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/objectives`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/objectives`}></meta>
      </Helmet>
      <Container fluid className='px-0'>
        <Row>
          <Col className='col-12 col-lg-9 mt-40'>
            <div className='font-open-sans font-bold font-24 leading-9 letter-spacing-minus-04 mb-point-75'>
              {`What are Abroad’s Objectives for ${companyName}`}
            </div>
            <div className='font-open-sans font-normal font-semibold font-14 leading-6 letter-spacing-minus-02 opacity-point-5'>
              {CONSTANTS.COMPANY_METADATA.objective.description}
            </div>
          </Col>
          <Col className='col-12 col-lg-3' />
          <Col className='mt-40'>
            <ObjectivesForm id={companyId} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ObjectivesWrapper
