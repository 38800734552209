import React, { useEffect } from 'react'
import { useErrorService, useUserService } from '@abroad/components'
import API from '../utils/API'
import AuthService from '../utils/AuthService'
import sysend from 'sysend'

const AdminSignOut = () => {
  const Error = useErrorService()
  const { setUser } = useUserService()

  useEffect(() => {
    const inIframe = () => {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    }

    const signOut = async () => {
      try {
        await API.ClientAPI.onboarding.logout()
        setUser(null)
        AuthService.removeData()
        setTimeout(() => {
          sysend.broadcast('admin_logout', { message: 'admin_logout' })
          window.parent.postMessage(
            'remove_iframe',
            process.env.REACT_APP_CLIENT_DOMAIN,
          )
        }, 300)
      } catch (e) {
        Error.showError(e)
      }
    }
    if (inIframe()) {
      signOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <div>signing out</div>
}

export default AdminSignOut
