import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Modal } from 'react-bootstrap'
import {
  Button,
  useErrorService,
  paymentType as paymentTypeConst,
  paymentStatus as paymentStatusConst,
} from '@abroad/components'
import {
  CoachingPlanDetailsModal,
  IndividualSOWForm,
  formInitialValues as initialValues,
} from '../coachingPlan'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const AssignSOWSection = ({
  showModal,
  setShowModal,
  usersHasOneCoach,
  userCoachMapping,
  usersHasNoCoach,
  coachId,
}) => {
  const { ids, setIsLoadNewData } = useContext(OrgEcoIdsContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [formInitialValues, setInitialValues] = useState(initialValues)
  const Error = useErrorService()

  const handleBulkAssignSOW = async (formValues) => {
    setIsSubmitting(true)

    let params = {
      paymentStatus: formValues.paymentStatus,
      // SOW Details
      planName: formValues.planName,
      // Billing
      paymentCycle: formValues?.paymentCycle,
      initialFee: formValues?.initialFee,
      ...(formValues?.paymentCycle === paymentTypeConst.RECURRING && {
        invoiceEvery: Number(formValues?.invoiceEvery),
        numberOfCoachingCycle: formValues?.numberOfCoachingCycle,
        amount: formValues?.amount,
      }),
      // Coaching
      sessionPerCoachingCycle: formValues?.sessionPerCoachingCycle,
      numberOfMonths: Number(formValues?.numberOfMonths),
      sessionDuration: formValues?.sessionDuration,
      report360: formValues?.report360 ? formValues?.reportPlan360 : null,
      impactReport: formValues?.impactReport
        ? formValues?.impactReportPlan
        : null,
      report360hourlyRate: formValues?.report360hourlyRate,
      impactReporthourlyRate: formValues?.impactReporthourlyRate,
      hourlyRate: formValues?.hourlyRate,
      timeZone: formValues?.clientTimeZone[0].value,
      firstSessionDate: formValues?.firstSessionDate,
    }

    Object.keys(params).forEach(
      (key) =>
        (params[key] === '' || params[key] === undefined) && delete params[key],
    )

    const newUserCoachMapping = userCoachMapping.map((user) => ({
      clientId: user?.clientId,
      coachId: coachId,
    }))

    const userCoachMappingArray = usersHasNoCoach
      ? newUserCoachMapping
      : userCoachMapping

    if (params.paymentStatus === paymentStatusConst.NO_BILLING_REQUIRED) {
      const noBillingObj = {
        paymentCycle: paymentTypeConst.RECURRING,
        initialFee: 0,
        invoiceEvery: 1,
        numberOfCoachingCycle: null,
        amount: 1,
      }
      params = {
        ...params,
        ...noBillingObj,
      }
    }

    try {
      const { status } = await API.AdminAPI.common.bulkAssignSOWToUser({
        coachingPlan: params,
        isReadyForPayment: !!formValues.isReadyForPayment,
        userCoachMapping: userCoachMappingArray,
      })
      if (status) {
        setShowModal(false)
        setTimeout(() => {
          setIsSubmitting(false)
          setShowConfirmationModal(false)
          setIsLoadNewData(true)
        }, 500)
      }
    } catch (e) {
      Error.showError(e)
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (showModal) {
      setInitialValues(initialValues)
    }
  }, [showModal])

  return (
    <>
      <Button
        variant='saffron'
        size='md'
        className='line-height-normal assign-btn'
        style={{ height: '2rem' }}
        onClick={() => {
          if (!usersHasOneCoach) return
          setShowModal(true)
        }}
        isLoading={isSubmitting}
        disabled={ids?.length === 0 || !usersHasOneCoach}>
        Assign SOW
      </Button>
      {/* confirm coaching plan details */}
      <CoachingPlanDetailsModal
        showModal={showConfirmationModal}
        setShowModal={(e) => {
          setShowConfirmationModal(e)
          setShowModal(true)
        }}
        isLoading={isSubmitting}
        handleSubmit={handleBulkAssignSOW}
        values={formValues}
        isBulkAssignSOWSection={true}
      />
      <Modal
        show={showModal}
        className={classNames(
          'border-modal custom__modal assign-sow sow-modal',
          {
            invisible: showConfirmationModal,
          },
        )}
        enforceFocus={false}
        size='lg'
        onHide={() => {
          if (usersHasNoCoach) {
            setIsLoadNewData(true)
          }
          setShowModal(false)
        }}
        scrollable={true}
        centered>
        <Modal.Header className='s3 text-center'>Assign SOW</Modal.Header>
        <Modal.Body>
          {/* Individual SOW Form */}
          <IndividualSOWForm
            initialValues={formInitialValues}
            onSubmit={(e) => {
              setFormValues(e)
              if (e.paymentStatus !== paymentStatusConst.NO_BILLING_REQUIRED) {
                setShowConfirmationModal(true)
              } else {
                handleBulkAssignSOW(e)
              }
            }}
            viewOnlyField={false}
            isPlanTerminated={false}
            isUpdatePlanLoading={isSubmitting}
            isBulkAssignSOWSection={true}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AssignSOWSection
