import React from 'react'
import { ParentCategoryOfSiteCopy } from '../common'

export const SelfAwareness = () => {
  return (
    <ParentCategoryOfSiteCopy
      parentKey='5b46b176ce416b175ff98e17'
      activeLink='sa'
    />
  )
}
