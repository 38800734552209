import React from 'react'
import { Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const InviteLogo = ({ orgEcoId, logo }) => {
  const { companyName } = useParams()
  const isNotProduction = process.env.REACT_APP_ENV !== 'prod'

  return (
    <Image
      src={`https://${
        isNotProduction ? 'abroad-public-dev' : 'abroad-public'
      }.s3.us-east-2.amazonaws.com/${
        companyName ? 'organizations' : 'networks'
      }/${orgEcoId}/${logo?.fileName}`}
      alt='invite logo'
      style={{
        height: 87 / window.devicePixelRatio,
        width: 'auto',
      }}
      className='cursor-pointer signup-logo'
    />
  )
}

export default InviteLogo
