import React, { useCallback, useEffect, useState } from 'react'
import { Form as RBForm, Row, Col } from 'react-bootstrap'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
  CloseIcon,
} from '@abroad/components'
import { Formik, Form } from 'formik'
import { Trans, withTranslation } from 'react-i18next'
import { string, object } from 'yup'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Imgix from 'react-imgix'
import classnames from 'classnames'

import { InputField } from '../form'
import { isLGScreen } from '../../utils/utility'
import API from '../../utils/API'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const formInitialValues = {
  title: '',
  description: '',
  expert: '',
  closingQuestion: '',
}

const validationSchema = object().shape({
  title: string().required(),
  description: string(),
  expert: string().required(),
  closingQuestion: string(),
})

const ProfileForm = () => {
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateProfileLoading, setIsUpdateProfileLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  let { mediaCategory, catId, subCatId, mediaId } = useParams()

  const [signedUrlObject, setSignedUrlObject] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [audioFile, setAudioFile] = useState(null)
  const [transcriptFile, setTranscriptFile] = useState(null)
  const [uploadImageMessage, setUploadImageMessage] = useState('')
  const [uploadAudioMessage, setUploadAudioMessage] = useState('')
  const [uploadTranscriptMessage, setUploadTranscriptMessage] = useState('')
  const [imageUploaded, setImageUploaded] = useState(false)
  const [audioUploaded, setAudioUploaded] = useState(false)
  const [transcriptUploaded, setTranscriptUploaded] = useState(false)
  const [createdMediaId, setCreatedMediaId] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [mediaData, setMediaData] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)

  const [audio, setAudio] = useState(null)
  const [curTime, setCurTime] = useState()
  const [tempTime, setTempTime] = useState(null)
  const [clickedTime, setClickedTime] = useState()
  const [audioLoading, setAudioLoading] = useState(false)

  const getMediasById = () => {
    setIsLoading(true)
    if (mediaId !== 'create') {
      return API.ClientAPI.mediaLibrary.getMediaById(subCatId, mediaId)
    } else {
      return {}
    }
  }

  const getSignedUrls = async (mediaId) => {
    if (!mediaId) {
      return
    }
    const { data } = await API.AdminAPI.mediaLibrary.getSignedUrlForUploadMedia(
      mediaId,
    )
    setSignedUrlObject(data)
  }

  useEffect(() => {
    if (!signedUrlObject) {
      return
    }
    const updateMedia = async () => {
      try {
        let updateObj = {}
        if (mediaData?.imageFilename) {
          updateObj.imageFilename = mediaData?.imageFilename
        }
        if (mediaData?.mediaFilename) {
          updateObj.mediaFilename = mediaData?.mediaFilename
        }
        if (mediaData?.transcriptFileName) {
          updateObj.transcriptFileName = mediaData?.transcriptFileName
        }
        if (imageUploaded) {
          updateObj.imageFilename = signedUrlObject.image.filename
        }
        if (audioUploaded) {
          updateObj.mediaFilename = signedUrlObject.media.filename
        }
        if (transcriptUploaded) {
          updateObj.transcriptFileName = signedUrlObject.transcription.filename
        }
        setIsUpdating(true)
        await API.AdminAPI.mediaLibrary.uploadMediaStatus(
          createdMediaId || mediaData?._id,
          updateObj,
        )
        setIsUpdating(false)
        // window.location.reload()
      } catch (e) {
        setIsUpdating(false)
        Error.showError(e)
      }
    }
    updateMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUploaded, audioUploaded, transcriptUploaded])

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await getMediasById()
        setInitialValues((prev) => ({
          ...prev,
          title: data?.title ? data?.title : '',
          description: data?.description ? data?.description : '',
          expert: data?.expert ? data?.expert : '',
          closingQuestion: data?.closingQuestion ? data?.closingQuestion : '',
        }))
        setIsUpdate(true)
        await getSignedUrls(data?._id)
        setMediaData(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line
  useEffect(() => {
    if (mediaData !== null) {
      const audioEle = document.getElementById('admin-audio')
      if (
        audio &&
        audio.currentSrc !== mediaData?.mediaUrl &&
        audio.dataset.id === mediaData.id
      ) {
        audio.src = mediaData.mediaUrl
      } else {
        setAudio(audioEle)
      }
    }
  })

  const getMedia = async () => {
    try {
      setAudioLoading(true)
      const { data } = await API.ClientAPI.mediaLibrary.getSignedUrlByMediaId(
        mediaData?.id,
      )
      if (data) {
        setMediaData({ ...mediaData, mediaUrl: data.mediaUrl })
      }
      setAudioLoading(false)
    } catch (e) {
      Error.showError(e)
      setAudioLoading(false)
    }
  }

  const setAudioTime = useCallback(
    (e) => {
      if (tempTime) {
        setCurTime(tempTime)
      } else if (e.target.currentTime !== 0) {
        setCurTime(e.target.currentTime)
      }
      setTempTime(null)
    },
    [tempTime],
  )

  useEffect(() => {
    if (audio && curTime && audio.currentTime !== curTime) {
      audio.currentTime = curTime
    }
  }, [audio, curTime])

  useEffect(() => {
    if (audio) {
      const setAudioData = (e) => {
        if (tempTime && mediaData.id === e.target.dataset.id) {
          audio.currentTime = tempTime
        }
        setTempTime(null)
      }

      audio.addEventListener('timeupdate', setAudioTime)
      audio.addEventListener('loadeddata', setAudioData)
    }
    return () => {
      if (audio) {
        audio.removeEventListener('loadeddata', () => {})
        audio.removeEventListener('timeupdate', setAudioTime)
      }
    }
  }, [audio, tempTime, setAudioTime, mediaData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const errorHandler = (e) => {
    if (!audioLoading) {
      setTempTime(audio.currentTime)
      getMedia()
    }
  }

  useEffect(() => {
    if (audio) {
      audio.addEventListener('error', errorHandler)
    }
    return () => {
      if (audio) {
        audio.removeEventListener('error', errorHandler)
      }
    }
  }, [audio, errorHandler, mediaData])

  useEffect(() => {
    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime
      setClickedTime(null)
    }
  }, [audio, clickedTime, curTime])

  if (isLoading) {
    return <ABSpinner />
  }

  const onSubmit = async (values) => {
    try {
      if (mediaId !== 'create') {
        await API.AdminAPI.mediaLibrary.updateMedia(mediaId, values)
        Notification.showNotification('Media has been updated!', 'success')
      } else {
        values.type = 'Audio'
        values.categoryId = catId
        values.subCategoryId = subCatId
        const { data } = await API.AdminAPI.mediaLibrary.createMedia(values)
        setCreatedMediaId(data?._id)
        await getSignedUrls(data?._id)
        Notification.showNotification('Media has been created!', 'success')
      }
    } catch (e) {
      setIsUpdateProfileLoading(false)
      Error.showError(e)
    }
  }

  const getImage = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const imageFile = files[0]
      setImageFile(imageFile)
    }
  }

  const getAudio = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const audioFile = files[0]
      setAudioFile(audioFile)
    }
  }

  const getTranscription = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const transcriptFile = files[0]
      setTranscriptFile(transcriptFile)
    }
  }

  const uploadImageFile = (e) => {
    e.preventDefault()
    if (!imageFile) {
      Notification.showNotification('Please upload image!', 'danger')
      return
    }
    setUploadImageMessage('Uploading...')
    const contentType = imageFile.type // eg. image/jpeg or image/svg+xml

    const putUrl = signedUrlObject.image.url
    const options = {
      headers: {
        'Content-Type': contentType,
      },
    }

    axios
      .put(putUrl, imageFile, options)
      .then((res) => {
        Notification.showNotification('Upload Successful', 'success')
        setImageUploaded(true)
      })
      .catch((err) => {
        setUploadImageMessage('Sorry, something went wrong')
      })
  }

  const uploadAudioFile = (e) => {
    e.preventDefault()
    if (!audioFile) {
      Notification.showNotification('Please upload audio!', 'danger')
      return
    }
    setUploadAudioMessage('Uploading...')
    const contentType = audioFile.type

    const putUrl = signedUrlObject.media.url
    const options = {
      headers: {
        'Content-Type': contentType,
      },
    }

    axios
      .put(putUrl, audioFile, options)
      .then((res) => {
        setUploadAudioMessage('Upload Successful')
        setTimeout(() => {
          setUploadAudioMessage('')
          // document.querySelector('#upload-audio').value = ''
          setAudioUploaded(true)
        }, 100)
      })
      .catch((err) => {
        setUploadAudioMessage('Sorry, something went wrong')
      })
  }

  const uploadTranscriptFile = (e) => {
    e.preventDefault()
    if (!transcriptFile) {
      Notification.showNotification('Please upload transcript!', 'danger')
      return
    }
    setUploadTranscriptMessage('Uploading...')
    const contentType = transcriptFile.type

    const putUrl = signedUrlObject.transcription.url
    const options = {
      headers: {
        'Content-Type': contentType,
      },
    }

    axios
      .put(putUrl, transcriptFile, options)
      .then((res) => {
        setUploadTranscriptMessage('Upload Successful')
        setTimeout(() => {
          setUploadTranscriptMessage('')
          // document.querySelector('#upload-audio').value = ''
          setTranscriptUploaded(true)
        }, 100)
      })
      .catch((err) => {
        setUploadTranscriptMessage('Sorry, something went wrong')
      })
  }

  const deleteMedia = async (mediaId, intent) => {
    try {
      setIsLoading(true)
      await API.AdminAPI.mediaLibrary.deleteMediaById(mediaId, intent)
      setIsLoading(false)
      window.location.reload()
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const downloadTranscript = async (mediaId) => {
    try {
      const { data } = await API.ClientAPI.mediaLibrary.downloadTranscript(
        mediaId,
      )
      if (data) {
        window.open(data.url)
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  return (
    <>
      <Formik
        displayName='media-upsert-form'
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnMount
        onSubmit={onSubmit}>
        {({ values, handleSubmit }) => {
          return (
            <RBForm
              onSubmit={handleSubmit}
              as={Form}
              className='profile px-4 px-lg-0'>
              <Row noGutters className='mb-3'>
                <Col className='col-sm-2 d-none d-lg-block' />
                <Col className='col-lg-offset-2'>
                  <h3>
                    <Trans>Media</Trans>
                  </h3>
                </Col>
              </Row>
              <InputField
                label='TITLE'
                labelProps={labelProps}
                name='title'
                type='text'
                placeholder='Title'
                size='lg'
                sm='10'
              />
              <InputField
                label='DESCRIPTION'
                labelProps={labelProps}
                name='description'
                type='text'
                placeholder='Description'
                size='lg'
                sm='10'
              />
              <InputField
                label='EXPERT'
                labelProps={labelProps}
                name='expert'
                type='text'
                placeholder='Expert'
                size='lg'
                sm='10'
              />
              <InputField
                label='CLOSING QUE'
                labelProps={labelProps}
                name='closingQuestion'
                type='text'
                placeholder='Closing Question'
                size='lg'
                sm='10'
              />
              <div className='text-right'>
                <Button
                  isLoading={isUpdateProfileLoading}
                  disabled={isUpdateProfileLoading}
                  type='submit'
                  variant='saffron'>
                  <Trans>{mediaId !== 'create' ? 'Update' : 'Create'}</Trans>
                </Button>
              </div>
            </RBForm>
          )
        }}
      </Formik>
      {((!mediaData?.imageFilename && signedUrlObject?.image) ||
        (isUpdate && mediaData?.imageFilename === '')) && (
        <>
          <input
            id='upload-image'
            type='file'
            accept={
              signedUrlObject ? signedUrlObject.image.contentType : 'image/jpeg'
            }
            onChange={getImage}
          />
          <p>{uploadImageMessage}</p>
          <form onSubmit={uploadImageFile}>
            <button id='file-upload-button'>Upload Image</button>
          </form>
        </>
      )}
      <br />
      {((!mediaData?.mediaFilename && signedUrlObject?.media) ||
        (isUpdate && mediaData?.mediaFilename === '')) && (
        <>
          <input
            id='upload-audio'
            type='file'
            accept={
              signedUrlObject ? signedUrlObject.media.contentType : 'audio/mpeg'
            }
            onChange={getAudio}
          />
          <p>{uploadAudioMessage}</p>
          <form onSubmit={uploadAudioFile}>
            <button id='file-upload-button'>Upload Audio</button>
          </form>
        </>
      )}
      <br />
      {((!mediaData?.transcriptFileName && signedUrlObject?.transcription) ||
        (isUpdate && mediaData?.transcriptFileName === '')) && (
        <>
          <input
            id='upload-transcript'
            type='file'
            accept={
              signedUrlObject
                ? signedUrlObject.transcription.contentType
                : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            onChange={getTranscription}
          />
          <p>{uploadTranscriptMessage}</p>
          <form onSubmit={uploadTranscriptFile}>
            <button id='file-upload-button'>Upload Transcript</button>
          </form>
        </>
      )}
      {isUpdate && mediaData?.imageFilename && mediaCategory === 'category' && (
        <Row className='mt-4'>
          <Col className='col'>
            <Imgix
              className='border-radius-10'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                w: 400,
                h: 400,
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/${mediaData?.imageFilename}`}
              height={320}
              width={320}
              alt='image'
            />
            <span
              className='image-delete'
              onClick={() => deleteMedia(mediaData?._id, 'image')}>
              <CloseIcon color='#000' discardMargin={true} />
            </span>
          </Col>
        </Row>
      )}
      <br />
      {isUpdate && mediaData?.mediaFilename && (
        <Row>
          <Col className='col'>
            <audio controls id='admin-audio'>
              <source src={mediaData?.mediaUrl} type='audio/mpeg' />
              Your browser does not support the audio element.
            </audio>
            <span
              className='audio-delete'
              onClick={() => deleteMedia(mediaData?._id, 'audio')}>
              <CloseIcon color='#000' />
            </span>
            {audioLoading && <span className='audio-delete'>Loading</span>}
          </Col>
        </Row>
      )}
      {isUpdating && <ABSpinner />}
      <br />
      {isUpdate && mediaData?.transcriptFileName && (
        <div
          className={classnames(
            'align-items-center cursor-pointer download-icon space-y-1',
            {
              'cursor-disabled': mediaData?.transcriptFileName === '',
            },
          )}>
          <div
            onClick={
              mediaData?.transcriptFileName !== ''
                ? () => downloadTranscript(mediaData?.id)
                : null
            }
            style={{ display: 'inline-block' }}>
            Download Transcript
          </div>
          {mediaData.transcriptFileName && (
            <span
              className='transcript-delete'
              onClick={() => deleteMedia(mediaData?._id, 'transcript')}>
              <CloseIcon color='#000' />
            </span>
          )}
        </div>
      )}
    </>
  )
}

export default withTranslation('translation')(ProfileForm)
