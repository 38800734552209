import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ABSpinner, useErrorService } from '@abroad/components'
import SOWGroupCoachesTable from './SOWGroupCoachesTable'
import CustomCoachingRateModal from './CustomCoachingRateModal'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const SOWGroupCoaches = ({ groupName }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isCoachesEmpty, setIsCoachesEmpty] = useState(true)
  const [coachRateData, setCoachRateData] = useState(null)
  const [groupisArchived, setGroupisArchived] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedCoaches, setSelectedCoaches] = useState([])
  const [isSamePL, setIsSamePL] = useState(false)
  const { companyName, ecoSystemName, sowId, sowGroupId } = useParams()
  const {
    ids,
    isLoadNewData,
    setIsLoadNewData,
    selectedGroupisArchived,
    isShowAddBtn,
    setIsShowAddBtn,
  } = useContext(OrgEcoIdsContext)
  const Error = useErrorService()
  const url = `${process.env.REACT_APP_DOMAIN}/admin/${
    companyName ? 'organization' : 'network'
  }/${
    companyName ? companyName : ecoSystemName
  }/sow/${sowId}/sow-group/${sowGroupId}/coaches`

  useEffect(() => {
    const getGroupCoachInfo = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(
          sowGroupId,
          {
            fields: ['name', 'isArchived'],
          },
        )
        if (data) {
          setIsShowAddBtn({
            hideBtn: data?.isCoachesEmpty,
            showTable: data?.isCoachesEmpty,
          })
          setIsCoachesEmpty(data?.isCoachesEmpty)
          setIsSamePL(data?.isSamePL)
          setGroupisArchived(data?.isArchived)
          setIsLoading(false)
          setIsLoadNewData(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (isLoading) getGroupCoachInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const addSOWGroupCoaches = async () => {
    setIsSubmitting(true)
    try {
      const { status } = await API.AdminAPI.companyList.addSOWGroupCoaches(
        sowGroupId,
        {
          coachIds: ids,
          ...(coachRateData && { coachesPricing: coachRateData }),
        },
      )
      if (status) {
        setIsSubmitting(false)
        setIsShowAddBtn({
          hideBtn: false,
          showTable: false,
        })
        setIsLoading(true)
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
      if (e.code === 'sow_team_custom_rate_needed') {
        setShowModal(true)
      }
    }
  }

  useEffect(() => {
    if (coachRateData) {
      addSOWGroupCoaches()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachRateData])

  useEffect(() => {
    if (isLoadNewData) {
      setIsLoading(true)
    }
  }, [isLoadNewData])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${groupName}'s Coaches | Abroad`}</title>
        <meta name='title' content={`${groupName}'s Coaches | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${groupName}'s Coaches | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${groupName}'s Coaches | Abroad`}></meta>
        <link rel='canonical' href={url}></link>
        <meta property='og:url' content={url}></meta>
        <meta property='twitter:url' content={url}></meta>
      </Helmet>
      <main>
        <CustomCoachingRateModal
          showModal={showModal}
          setShowModal={setShowModal}
          setCoachRateData={setCoachRateData}
          newSelectedCoaches={selectedCoaches}
        />
        {!isShowAddBtn?.showTable && (
          <SOWGroupCoachesTable
            setSelectedCoaches={setSelectedCoaches}
            isRenderSOWGroupCoaches={true}
            disabled={selectedGroupisArchived}
            isSamePL={isSamePL}
          />
        )}
        {isShowAddBtn?.showTable && (
          <React.Fragment>
            {isCoachesEmpty && !groupisArchived && (
              <p className='s3 font-semibold no-users'>
                There are currently no coaches in this Group. Please select the
                coaches you would like
                <br />
                to add from the list below.
              </p>
            )}
            {!groupisArchived ? (
              <SOWGroupCoachesTable
                showAddCoachesBtn={true}
                onBtnClickHandler={addSOWGroupCoaches}
                isSubmitting={isSubmitting}
                setSelectedCoaches={setSelectedCoaches}
                disabled={selectedGroupisArchived}
                isSamePL={isSamePL}
                renderAsAddCoachTable={true}
              />
            ) : (
              <div className='text-center'>
                <span className='s5'>No coaches.</span>
              </div>
            )}
          </React.Fragment>
        )}
      </main>
    </React.Fragment>
  )
}

export default SOWGroupCoaches
