import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import { Container, Table } from 'react-bootstrap'

import { useErrorService, Button, FeatherLoader } from '@abroad/components'
import { ClearFilters, CoachingPlansList, useQuery } from '../common'
import { formatDate } from '../../utils/date'
import labels from '../../constants/labels'
import API from '../../utils/API'

export const ClientsOfCoach = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [clients, setClients] = useState([])
  const [clientsList, setClientsList] = useState([])
  const Error = useErrorService()
  const [surveyList, setSurveyList] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [initialData, setInitialData] = useState([])
  const query = useQuery()
  const search = query.get('search')

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    if (search && !isLoading) {
      setSearchTerm(search)
    } else {
      setSearchTerm('')
    }
  }, [search, isLoading])

  useEffect(() => {
    const temp = [...clients]

    const results = temp.filter(
      (client) =>
        client?.fullName
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase()?.trim()) ||
        client?.email
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase()?.trim()) ||
        client?.company
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase()?.trim()),
    )
    setClientsList(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getClientList()
        setClients(data)
        setClientsList(data)
        setInitialData(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    fetchClients()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {},
    )

  const surveyListGrouped = groupBy(surveyList, 'clientId')

  const compare = (id) => {
    window.open(
      `/user/${id}/comparison_report?survey=${surveyListGrouped[id][0].surveyId}&survey=${surveyListGrouped[id][1].surveyId}`,
    )
  }

  return (
    <Container fluid className='px-lg-0'>
      {initialData.length > 0 && !isLoading && (
        <section className='my-3'>
          <div className='d-flex justify-content-end'>
            <input
              className='coach-user-search'
              type='text'
              placeholder='Search client...'
              value={searchTerm}
              onChange={handleChange}
            />
            {searchTerm?.toLowerCase()?.trim() && (
              <ClearFilters
                wrapperClasses='ml-4 align-self-center'
                onClick={() => {
                  setSearchTerm('')
                }}
              />
            )}
          </div>
        </section>
      )}
      <Table responsive className='custom-table mt-3'>
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>Organization</th>
            <th>Network</th>
            <th>Coaching Plan</th>
            <th>Assessments Results</th>
            <th>Compare</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan='7' className='text-center'>
                <FeatherLoader />
              </td>
            </tr>
          )}
          {clientsList.length > 0 &&
            !isLoading &&
            clientsList.map((client, index) => (
              <tr key={client._id}>
                <td>{index + 1}</td>
                <td>
                  <NavLink
                    to={`/coach/clients/${client._id}`}
                    className='text-dark-600 underline-link'>
                    {client.fullName}
                    <br />
                    {client.email}
                  </NavLink>
                  <br /> Plan:{' '}
                  {labels.plans.find((e) => e.value === client.planCode)
                    ?.label || ''}
                </td>
                <td>
                  {client.workStatus === labels.workStatus.IN_OPERATING
                    ? client.company
                    : 'User is in career transition'}
                </td>
                <td>
                  {client.affiliation.length > 0 ? (
                    client.affiliation.join(', ')
                  ) : (
                    <p className='text-center'>-</p>
                  )}
                </td>
                <td width='20%'>
                  <CoachingPlansList
                    coachingPlans={client?.coachingPlans}
                    b2bPlans={client?.b2bPlans}
                  />
                </td>
                <td width='20%'>
                  {client.surveys.length > 0
                    ? client.surveys.map((s, index) => (
                        <div
                          key={index}
                          className='d-flex justify-content-between'>
                          <a
                            className='text-dark-600 underline-link'
                            href={`/survey/${s._id}/clients/${client._id}`}
                            target='_blank'
                            rel='noopener noreferrer'>
                            {' '}
                            Assessments Results - {formatDate(s.createdAt)}
                          </a>
                          <input
                            type='checkbox'
                            onClick={() => {
                              let arr = [...surveyList]
                              const index = arr.findIndex(
                                (e) =>
                                  e.clientId === client._id &&
                                  e.surveyId === s._id,
                              )
                              if (index > -1) {
                                arr.splice(index, 1)
                              } else {
                                arr.push({
                                  clientId: client._id,
                                  surveyId: s._id,
                                })
                              }
                              setSurveyList(arr)
                            }}
                            className='survey-link'
                            id={s._id}
                          />
                        </div>
                      ))
                    : 'No Survey Taken'}
                </td>
                <td>
                  <Button
                    variant='dark'
                    size='sm'
                    onClick={() => compare(client._id)}
                    disabled={
                      !(
                        surveyListGrouped[client._id] &&
                        surveyListGrouped[client._id].length === 2
                      )
                    }
                    className={classnames('sm-btn text-uppercase', {
                      'compare-btn-disabled': !(
                        surveyListGrouped[client._id] &&
                        surveyListGrouped[client._id].length === 2
                      ),
                    })}>
                    Compare
                  </Button>
                </td>
              </tr>
            ))}
          {clientsList.length === 0 && !isLoading && (
            <tr>
              <td colSpan='7' className='text-center'>
                No Clients.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
}
