import { useFormikContext } from 'formik'
import { useEffect } from 'react'

export const SetTotalSessionDuration = () => {
  const { values, setFieldValue } = useFormikContext()
  useEffect(() => {
    const { invoiceEvery, numberOfCoachingCycle } = values
    if (invoiceEvery && numberOfCoachingCycle) {
      setFieldValue(
        'totalDurationOfContract',
        `${invoiceEvery * numberOfCoachingCycle} Month(s)`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])
  return null
}
