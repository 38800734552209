import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink, Switch, useLocation } from 'react-router-dom'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { Helmet } from 'react-helmet'
import { NavTabs } from '@abroad/components'
import { TeamListingTable } from '../components'
import { PageHeader } from '../components/common'
import PrivateRoute from '../routes/PrivateRoute'

export const TeamListing = () => {
  const location = useLocation()

  const groupPages = [
    {
      path: '/admin/groups/active',
      name: 'Active',
      defaultUrl: '/admin/groups',
    },
    {
      path: '/admin/groups/archive',
      name: 'Archive',
    },
  ]

  return (
    <>
      <Helmet>
        <title>{'Groups | Abroad'}</title>
        <meta name='title' content={'Groups | Abroad'}></meta>
        <meta property='og:title' content={'Groups | Abroad'}></meta>
        <meta property='twitter:title' content={'Groups | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/groups`}></meta>
      </Helmet>
      <main>
        <PageHeader
          titleText='GROUPS'
          colClasses='px-lg-0'
          titleClasses='sentence-case-text'
        />
        <Container fluid className='px-lg-0'>
          <section className='my-4 custom-offset-lg-1 custom-col-lg-12 px-lg-0'>
            <NavTabs
              pages={groupPages}
              pathname={location?.pathname}
              hash={location?.hash}
              NavLink={NavLink}
            />
            <Switch location={location}>
              <PrivateRoute
                exact
                path={['/admin/groups/active', '/admin/groups']}>
                <TeamListingTable
                  isArchivedTable={false}
                  tableId={'active-group'}
                />
              </PrivateRoute>
              <PrivateRoute exact path='/admin/groups/archive'>
                <TeamListingTable
                  isArchivedTable={true}
                  tableId={'archive-group'}
                />
              </PrivateRoute>
            </Switch>
          </section>
        </Container>
      </main>
    </>
  )
}
