import React from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js'

const styleObj = {
  // hidePostalCode: true, // only use in 'card' element
  style: {
    base: {
      iconColor: '#666EE8',
      color: '#000',
      lineHeight: '20px',
      fontWeight: 500,
      fontFamily: 'Open Sans',
      fontSize: '0.75rem',
      '::placeholder': {
        fontSize: '0.75rem',
        color: '#00000040'
      }
    },
    invalid: {
      borderColor: '#bc4c6a',
      color: '#000'
    }
    // complete:{}, // if field is valid, this style will be applied, for now no usage
  }
}

const AddCreditCard = () => {
  return (
    <React.Fragment>
      <div className='d-flex w-100'>
        <label className='mb-2-rem w-100'>
          <p className='card-labels'>Card Number</p>
          <CardNumberElement
            className='field form-control-md'
            options={{ ...styleObj, placeholder: '0000 0000 0000 0000' }}
          />
          {/* other way pass style={{}} as props */}
          {/* other way pass class='form-control' */}
        </label>
      </div>
      <Row className='d-flex justify-content-between'>
        <Col className='col-6'>
          <div className='d-flex w-100'>
            <label className='mb-2-rem w-100'>
              <p className='card-labels'>EXPIRATION DATE</p>
              <CardExpiryElement
                className='field form-control-md'
                options={styleObj}
              />
            </label>
          </div>
        </Col>
        <Col className='col-5'>
          <div className='d-flex w-100'>
            <label className='mb-2-rem w-100'>
              <p className='card-labels'>CVV</p>
              <CardCvcElement
                className='field form-control-md'
                options={{ ...styleObj, placeholder: '000' }}
              />
            </label>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AddCreditCard
