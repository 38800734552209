import React from 'react'
import classNames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ReturnPageIcon } from '../assetsComponents'

const PageHeader = ({
  titleText = '',
  titleFn = () => {},
  extraFn = () => {},
  sectionClassName = '',
  wrapperClassName = '',
  colClasses = '',
  descriptionFn = () => {},
  hasBackArrow,
  titleClasses,
}) => {
  const history = useHistory()
  return (
    <section
      className={`py-5 bg-gray-100 header-shadow platform-header d-flex align-items-center ${sectionClassName}`}>
      <Container fluid>
        <Row>
          <Col
            className={classNames(
              'custom-offset-lg-1 custom-col-lg-12',
              colClasses,
            )}>
            <div
              className={`${wrapperClassName}${hasBackArrow ? ' d-flex' : ''}`}>
              {hasBackArrow && (
                <ReturnPageIcon onClick={() => history.goBack()} />
              )}
              {titleText ? (
                <h3
                  className={classNames(
                    'text-dark-600 page-label',
                    titleClasses,
                  )}>
                  {titleText}
                </h3>
              ) : (
                titleFn()
              )}
              {extraFn()}
            </div>
            {descriptionFn()}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PageHeader
