import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import makeAnimated from 'react-select/animated'
import { isLGScreen } from '../../utils/utility'
import { returnLGSelectionFieldStyle } from '../../constants/common'

const animatedComponents = makeAnimated()

const SelectionField = ({
  label,
  name,
  options,
  placeholder,
  fullInputWidth,
  disabled,
  removeLabelPadding,
  menuPortalTarget = null,
  isInsidePopup = false,
  popupClassName = 'sow-modal',
}) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext()

  const styles = returnLGSelectionFieldStyle(disabled) //noNeedPladding=true

  const handleChange = (selectedOption) => {
    setFieldValue(
      name,
      selectedOption === null
        ? []
        : [selectedOption[selectedOption.length - 1]],
    )
  }

  const filterData = (inputValue) => {
    return options?.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    )
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterData(inputValue))
    })

  let portalProps = {
    menuPortalTarget: menuPortalTarget,
  }

  if (isInsidePopup) {
    portalProps = {
      menuPortalTarget: document.getElementsByClassName(popupClassName)[0],
      menuPosition: 'fixed',
    }
  }

  return (
    <Row noGutters xs={1} sm={1} md={1} lg={2}>
      {label && (
        <Col
          className={classnames(
            'd-flex justify-content-lg-end align-self-center',
            {
              'col-lg-2': label && fullInputWidth === undefined,
              'col-lg-12': fullInputWidth === 'true',
            },
            {
              'px-0': !isLGScreen,
            },
          )}>
          <Form.Group className={isLGScreen ? '' : 'mb-0'}>
            <Form.Label
              column={isLGScreen ? true : false}
              className={removeLabelPadding ? '' : 'pl-lg-5 pr-lg-0'}>
              {label}
            </Form.Label>
          </Form.Group>
        </Col>
      )}
      <Col
        className={classnames('custom-selection', {
          'col-lg-10 px-0': label && fullInputWidth === undefined,
          'col-lg-12': fullInputWidth === 'true',
        })}>
        <Form.Group className='user-input'>
          <AsyncSelect
            value={values[name]}
            {...portalProps}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            isValid={
              touched.hasOwnProperty(name) && !errors.hasOwnProperty(name)
            }
            isInvalid={
              touched.hasOwnProperty(name) && errors.hasOwnProperty(name)
            }
            placeholder={placeholder}
            styles={styles}
            onFocus={() => {
              setFieldTouched(name, true, false)
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultOptions={options}
            loadOptions={promiseOptions}
            isClearable={values[name]}
            isDisabled={disabled}
            className='user-input'
            classNamePrefix={
              disabled ? 'timezone-field-disabled' : 'timezone-field'
            }
            maxMenuHeight={140}
          />
        </Form.Group>
      </Col>
    </Row>
  )
}

SelectionField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

SelectionField.defaultProps = {
  options: [],
  name: '',
  label: '',
}

export default SelectionField
