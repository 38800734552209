import React from 'react'
import { Container } from 'react-bootstrap'
import { CreateTeamForm } from '../components/team'
import { Helmet } from 'react-helmet'
import PageHeader from '../components/common/PageHeader'

export const CreateTeam = () => {
  return (
    <>
      <Helmet>
        <title>{'Create Group | Abroad'}</title>
        <meta name='title' content={'Create Group | Abroad'}></meta>
        <meta property='og:title' content={'Create Group | Abroad'}></meta>
        <meta property='twitter:title' content={'Create Group | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/group`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/group`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/group`}></meta>
      </Helmet>
      <main>
        <PageHeader titleText='CREATE GROUP' colClasses='px-lg-0' />
        <section className='custom-offset-lg-1 custom-col-lg-12 px-lg-0'>
          <Container className='pt-5 px-lg-0' fluid>
            <CreateTeamForm />
          </Container>
        </section>
      </main>
    </>
  )
}
