import React from 'react'
import classNames from 'classnames'

const InformationIcon = ({ iconClasses = '', ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 24 24'
      fill='none'
      className={classNames('cursor-pointer', iconClasses)}
      {...rest}>
      <circle cx='12' cy='12' r='12' fill='black' />
      <circle cx='12' cy='6.50391' r='1.5' fill='white' />
      <rect x='10.5' y='10.0039' width='3' height='9' rx='1.5' fill='white' />
    </svg>
  )
}

export default InformationIcon
