import React, { useState, useEffect } from 'react'
import { useErrorService } from '@abroad/components'

import { SelectionField } from '../common'
import API from '../../utils/API'

const PromoCodeFilter = () => {
  const Error = useErrorService()
  const [promoCodes, setPromoCodes] = useState({
    isLoading: true,
    promoCodes: [],
  })

  useEffect(() => {
    const getPromocodeData = async () => {
      try {
        const { data } = await API.AdminAPI.userList.getPromocodeData()
        if (data) {
          const sortedPromo = data?.sort((a, b) =>
            a?.code?.localeCompare(b?.code),
          )
          setPromoCodes({
            isLoading: false,
            promoCodes: sortedPromo?.map((promoCode) => ({
              key: promoCode?.code,
              value: promoCode.code?.toUpperCase(),
            })),
          })
        }
      } catch (e) {
        setPromoCodes({
          isLoading: false,
          promoCodes: [],
        })
        Error.showError(e)
      }
    }
    getPromocodeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <SelectionField
      name='filterByPromoCode'
      options={promoCodes.promoCodes?.filter((promoCode) => promoCode !== null)}
      className='pointer-cursor user-input'
      optionplaceholder='Select Promo'
      size='md'
      getKeyAsOption={true}
    />
  )
}

export default PromoCodeFilter
