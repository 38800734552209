import React from 'react'
import classNames from 'classnames'

const AngleUpArrow = ({ className = '', ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='7'
      viewBox='0 0 10 7'
      fill='none'
      className={classNames('cursor-pointer', className)}
      {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.53033 0.874499C9.82322 1.16037 9.82322 1.62386 9.53033 1.90973L5.53033 5.81384C5.23744 6.09971 4.76256 6.09971 4.46967 5.81384L0.46967 1.90973C0.176777 1.62386 0.176777 1.16037 0.46967 0.874499C0.762563 0.588628 1.23744 0.588628 1.53033 0.874499L5 4.26099L8.46967 0.874499C8.76256 0.588627 9.23744 0.588627 9.53033 0.874499Z'
        fill='black'
      />
    </svg>
  )
}

export default AngleUpArrow
