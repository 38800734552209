export const initialState = {
  teams: [],
  isLoading: true,
  error: null,
  orgEcoId: null,
}

const returnLatestState = (state, action) => {
  return {
    ...state,
    teams: action.payload,
    isLoading: false,
    error: null,
  }
}

const teamReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ORG_ECO_ID':
      return {
        ...state,
        teams: [],
        isLoading: true,
        error: null,
        orgEcoId: action.payload,
      }
    case 'TEAM_FETCH':
      return { ...state, teams: [], isLoading: true, error: null }
    case 'TEAM_FETCH_SUCCESS':
      return returnLatestState(state, action)
    case 'TEAM_FETCH_FAIL':
      return {
        ...state,
        teams: [],
        isLoading: false,
        error: action.payload,
      }
    case 'UPDATE_TEAMS':
      return {
        ...state,
        teams:
          state?.teams?.length === 1 && state?.teams[0]?.name === ''
            ? [action.payload]
            : [...state.teams, action.payload],
        isLoading: false,
        error: null,
      }
    case 'UPDATE_TEAM':
      return {
        ...state,
        teams: state.teams.map((team, i) => {
          if (action.i === i) {
            return action.payload
          }
          return team
        }),
        isLoading: false,
        error: null,
      }
    case 'DELETE_TEAM':
      return {
        ...state,
        teams: state.teams.filter((team) => {
          return team.name !== action.payload
        }),
        isLoading: false,
        error: null,
      }
    case 'REMOVE_EMPTY_TEAMS':
      if (state.teams.length > 1) {
        return {
          ...state,
          teams: state.teams.filter((team) => {
            return team.name !== ''
          }),
          isLoading: false,
          error: null,
        }
      } else {
        return state
      }
    default:
      return initialState
  }
}

export default teamReducer
