import React, { useContext } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Button } from '@abroad/components'
import { OrgEcoIdsContext } from '../../utils/contexts'

const UserWithCoachesModal = ({
  showModal,
  onHide,
  coachName,
  isSubmitting,
  assignCoachToUsersHandler,
}) => {
  const { selectedSOWUsers } = useContext(OrgEcoIdsContext)

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      className='custom-modal coach-payment-rates'
      centered>
      <Modal.Body>
        <h3 className='s5 mb-4'>{`Users is already assigned to old coaches, Do you want to assign users to ${coachName}'s too?`}</h3>
        <Table hover responsive className='custom-table'>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Coaches</th>
            </tr>
          </thead>
          <tbody>
            {selectedSOWUsers?.map((user, index) => (
              <React.Fragment key={index}>
                {user?.sowCoaches?.length > 0 && (
                  <tr>
                    <td className='d-flex'>
                      <Link
                        to={`/admin/users/${user?._id}`}
                        target='_blank'
                        className='text-dark-900'>
                        {user?.fullName}
                      </Link>
                    </td>
                    <td>
                      {user?.sowCoaches?.map((coach, index) => (
                        <React.Fragment key={index}>
                          <Link
                            to={`/admin/coaches/${coach?._id}`}
                            target='_blank'
                            className='text-dark-900'>
                            {coach?.fullName}
                          </Link>
                          <br />
                        </React.Fragment>
                      ))}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='none'
          className='confirmation-btn text-uppercase line-height-normal secondary-popup-button'
          size='sm'
          onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant='saffron'
          className='confirmation-btn text-uppercase line-height-normal'
          size='sm'
          onClick={assignCoachToUsersHandler}
          isLoading={isSubmitting}
          disabled={isSubmitting}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserWithCoachesModal
