import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { FeatherLoader } from '@abroad/components'
import AddNewTeamIcon from './AddNewTeamIcon'
import TeamInput from './TeamInput'
import { useTeamReducer } from './Reducer'

const EditTeams = ({ setIsAnyModalEventOccurs }) => {
  const [showEditTeamsModal, setShowEditTeamsModal] = useState(false)
  const { state, dispatch } = useTeamReducer()
  const onHide = () => setShowEditTeamsModal(false)
  const { companyName } = useParams()

  useEffect(() => {
    if (!showEditTeamsModal) {
      setTimeout(() => {
        setIsAnyModalEventOccurs(false)
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditTeamsModal])

  return (
    <>
      <Modal
        show={showEditTeamsModal}
        onHide={onHide}
        centered
        lg='true'
        className='edit-teams'>
        <Modal.Body className='edit-team-modal'>
          <h3 className='font-16 font-montserrat font-semibold leading-5 modal-header-text'>
            {companyName ? 'Organizations' : 'Networks'} Departments
          </h3>
          <p className='modal-subheader font-open-sans font-12 font-normal mb-0 pb-4'>
            Use this page to set the departments of this
            {companyName ? ' organization' : ' network'}. The departments listed
            here will be reflected on this
            {companyName ? ' organizations ' : ' networks '}
            specific sign-up link.
          </p>
          <span className='team-name-text font-montserrat font-weight-medium mt-3'>
            DEPARTMENT NAME
          </span>
          {state?.isLoading ? (
            <div className='d-flex m-5 align-items-center justify-content-center'>
              <FeatherLoader />
            </div>
          ) : (
            <div
              className={classNames({
                'inputs-overflow': state?.teams?.length > 5,
              })}
              id='edit-team-inputs'>
              {state?.teams?.map((team, index) => (
                <TeamInput
                  index={index}
                  key={team?.name ? `${team.name}-${index}` : index}
                />
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className='mt-3'>
          <AddNewTeamIcon disabled={state?.isLoading} />
          <Button
            className='confirmation-btn text-uppercase m-0 w-25 secondary-popup-button'
            variant='none'
            size='sm'
            onClick={() => {
              setIsAnyModalEventOccurs(true)
              setShowEditTeamsModal(false)
            }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        variant='outline-dark'
        className='btn s8 text-uppercase custom-mb-1-25 w-100 team-btn border-radius-3 line-height-normal'
        onClick={() => {
          setIsAnyModalEventOccurs(true)
          dispatch({
            type: 'REMOVE_EMPTY_TEAMS',
          })
          setShowEditTeamsModal(true)
        }}>
        Edit Departments
      </Button>
    </>
  )
}

export default EditTeams
