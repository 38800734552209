import React, { useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import { InputField } from '../form'
import { useDrag, useDrop } from 'react-dnd'

const ObjectivesWithDnd = ({
  id,
  index,
  objectives,
  inputsMandatory,
  inputDeletehandler,
  moveInput,
}) => {
  const ref = useRef(null)
  const dndType = 'objectives-inputs'

  const [{ handlerId }, drop] = useDrop({
    accept: dndType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveInput(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: dndType,
    item: () => {
      return { index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.5 : 1
  drag(drop(ref))

  return (
    <div style={{ opacity }} ref={ref}>
      <Row className='objectives-input'>
        <Col className='pr-0'>
          <div className='d-flex'>
            <div className='d-flex align-self-center mr-10-px'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='14'
                viewBox='0 0 16 14'
                fill='none'
                className='cursor-move'
                data-handler-id={handlerId}>
                <line
                  x1='1'
                  y1='1'
                  x2='15'
                  y2='1'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <line
                  x1='1'
                  y1='7'
                  x2='15'
                  y2='7'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <line
                  x1='1'
                  y1='13'
                  x2='15'
                  y2='13'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
              </svg>
            </div>
            <div
              key={id}
              className='w-100'
              draggable={true}
              onDragStart={(event) => event.preventDefault()}>
              <InputField
                className={classNames(
                  'font-open-sans font-14 font-semibold leading-6 letter-spacing-minus-02',
                  {
                    'mb-0': id === objectives.length,
                  },
                )}
                name={`objectivesList.${id}`}
                type='string'
                size='lg'
                placeholder='e.g, 5% increase in team member self awareness'
                sm='12'
                showErrorMessage={true}
                hasErrorMessagePadding={false}
              />
            </div>
          </div>
        </Col>
        <Col
          className='col-2 d-flex align-self-center justify-content-center'
          draggable={true}
          onDragStart={(event) => event.preventDefault()}>
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={classNames(
                'delete-link font-open-sans font-14 font-bold leading-6 letter-spacing-minus-02',
                {
                  'pointer-events-none': objectives.length === inputsMandatory,
                },
              )}
              // eslint-disable-next-line no-script-url
              href='javascript:void(0)'
              onClick={() => inputDeletehandler(id)}>
              Delete
            </a>
          </>
        </Col>
      </Row>
    </div>
  )
}

export default ObjectivesWithDnd
