import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  ABSpinner,
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import { CreateSOWGroupModal, SOWGroupTable } from '../components'
import API from '../utils/API'
import { OrgEcoIdsContext } from '../utils/contexts'
import { sortByGivenKey } from '../utils/utility'

const SOWGroups = ({
  orgEcoId,
  orgEcoType,
  sowName,
  setIsShowAddSOWGroup,
  setIsCoachesEmpty,
  isCoachesEmpty,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [groups, setGroups] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showCreateGroupSOWModal, setShowCreateGroupSOWModal] = useState(false)
  const { sowId, companyName, ecoSystemName } = useParams()
  const { isLoadNewData, setIsLoadNewData } = useContext(OrgEcoIdsContext)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const url = `/admin/${
    companyName ? `organization/${companyName}` : `network/${ecoSystemName}`
  }/sow/${sowId}/sow-groups`

  useEffect(() => {
    const getSOWGroups = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getSOWGroups(sowId)
        if (data) {
          const activeGroups = data.filter((group) => !group?.isArchived)
          const sortActiveGroup = sortByGivenKey(activeGroups, 'name')
          const archivedGroups = data.filter((group) => group?.isArchived)
          const sortArchivedGroup = sortByGivenKey(archivedGroups, 'name')
          setIsShowAddSOWGroup(data?.length > 0)
          if (data?.length > 0) {
            setIsCoachesEmpty(false)
          }
          setGroups([...sortActiveGroup, ...sortArchivedGroup])
          setIsLoading(false)
          setIsLoadNewData(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (sowId && isLoading) getSOWGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowId, isLoading])

  useEffect(() => {
    if (isLoadNewData) {
      setIsLoading(true)
    }
  }, [isLoadNewData])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${sowName}'s Groups | Abroad`}</title>
        <meta name='title' content={`${sowName}'s Groups | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${sowName}'s Groups | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${sowName}'s Groups | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}${url}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
      </Helmet>
      <CreateSOWGroupModal
        showModal={showModal}
        setShowModal={setShowModal}
        orgEcoId={orgEcoId}
        orgEcoType={orgEcoType}
        showCreateGroupSOWModal={showCreateGroupSOWModal}
        setShowCreateGroupSOWModal={setShowCreateGroupSOWModal}
      />
      {groups?.length > 0 ? (
        <SOWGroupTable data={groups} />
      ) : (
        <div>
          <p className='s5 font-semibold mb-0'>
            This SOW currently has no groups. You may create one pressing the
            <br />
            button below, or in the actions panel.
          </p>
          <Button
            variant='black'
            className='create-new-group line-height-normal'
            disabled={isLoading}
            onClick={() => {
              if (isCoachesEmpty) {
                Notification.showNotification(
                  'First add coaches to SOW.',
                  'danger',
                )
                return
              }
              setShowModal(true)
              setShowCreateGroupSOWModal(true)
            }}>
            Create New Group
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}

export default SOWGroups
