import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Form, InputGroup } from 'react-bootstrap'

const MultiRangeSlider = ({
  min,
  max,
  onChange,
  timeInterval = 1000,
  ...rest
}) => {
  const [minVal, setMinVal] = useState(rest?.defaultValue || min)
  const [maxVal, setMaxVal] = useState(max)
  const [isInitiallyLoad, setIsInitiallyLoad] = useState(true)
  const minValRef = useRef(min)
  const maxValRef = useRef(max)
  const range = useRef(null)

  useEffect(() => setIsInitiallyLoad(false), [])

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  // Get min and max values when their state changes
  useEffect(() => {
    const handler = setTimeout(() => {
      if (!isInitiallyLoad) {
        onChange({ min: minVal, max: maxVal })
      }
    }, timeInterval)
    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxVal, minVal])

  const sliderRangeChangeHandler = (event, type = 'max') => {
    if (type === 'min') {
      const value = Math.min(Number(event.target.value), maxVal - 1)
      if (event.target.value <= min) {
        setMinVal(min)
        minValRef.current = min
      } else {
        setMinVal(value)
        minValRef.current = value
      }
      return
    }
    const value = Math.max(Number(event.target.value), minVal + 1)
    if (event.target.value > max) {
      setMaxVal(max)
      maxValRef.current = max
    } else {
      setMaxVal(value)
      maxValRef.current = value
    }
  }

  return (
    <div className='slider-wrapper'>
      <input
        type='range'
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => sliderRangeChangeHandler(event, 'min')}
        className={classNames('thumb z3', {
          'cursor-pointer': !rest.isLoading,
        })}
        style={{ zIndex: minVal > max - 100 && '5' }}
        {...rest}
      />
      <input
        type='range'
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => sliderRangeChangeHandler(event)}
        className={classNames('thumb z4', {
          'cursor-pointer': !rest.isLoading,
        })}
        {...rest}
      />

      <div className='position-relative w-100'>
        <div className='slider__track position-absolute border-radius-3 w-100' />
        <div
          ref={range}
          className='slider__range position-absolute border-radius-3 z2 bg-saffron-700'
        />
        <div className='slider__left-value position-absolute'>
          <InputGroup className='mb-0'>
            <InputGroup.Text className='s5 py-0 remove-right-border' id='range'>
              $
            </InputGroup.Text>
            <Form.Control
              type='number'
              min={min}
              max={max}
              maxLength={4}
              value={minVal}
              onChange={(event) => sliderRangeChangeHandler(event, 'min')}
              className={classNames('slider-range-input', {
                'cursor-disabled': rest?.disabled,
              })}
              aria-describedby='range'
              {...rest}
            />
          </InputGroup>
          <Form.Text className='text-muted'>Min</Form.Text>
        </div>
        <div className='slider__right-value position-absolute'>
          <InputGroup className='mb-0'>
            <InputGroup.Text className='s5 py-0 remove-right-border' id='range'>
              $
            </InputGroup.Text>
            <Form.Control
              type='number'
              min={min}
              max={max}
              maxLength={4}
              value={maxVal}
              onChange={(event) => sliderRangeChangeHandler(event)}
              className={classNames('slider-range-input', {
                'cursor-disabled': rest?.disabled,
              })}
              aria-describedby='range'
              {...rest}
            />
          </InputGroup>
          <Form.Text className='text-muted'>Max</Form.Text>
        </div>
      </div>
    </div>
  )
}

export default MultiRangeSlider
