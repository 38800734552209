import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { UserInfoInTable } from './UserInfoInTable'
import API from '../../utils/API'
import { useErrorService } from '@abroad/components'
import { PageHeader } from '../common'
import ExportUser from './ExportUser'
import FilterForm from './FilterForm'
import { getNameArrayFromCompanies } from '../../utils/utility'

const userFormInitialValues = {
  filterBySearch: '',
  filterByCompany: '',
  filterByPromoCode: '',
  filterByAffiliation: '',
  filterByPlan: '',
  //filterByCoachId: '',
}

export const UserListDataContext = React.createContext({
  companies: {
    isLoading: true,
    companies: [],
  },
  ecoSystems: {
    isLoading: true,
    ecoSystems: [],
  },
  user: {
    isLoading: true,
    users: [],
  },
  // coaches: {
  //   isLoading: true,
  //   coaches: [],
  // },
})

export const Users = React.memo(() => {
  const Error = useErrorService()
  const formRef = useRef()

  const [companies, setCompanies] = useState({
    isLoading: true,
    companies: [],
  })
  const [ecoSystems, setEcoSystems] = useState({
    isLoading: true,
    ecoSystems: [],
  })

  // const [coaches, setCoaches] = useState({
  //   isLoading: true,
  //   coaches: [],
  // })

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        let { data } = await API.AdminAPI.companyList.getCompanyData()
        data = getNameArrayFromCompanies(data)
        if (data) {
          setCompanies({
            isLoading: false,
            companies: data,
          })
        }
      } catch (e) {
        setCompanies({
          isLoading: false,
          companies: [],
        })
        Error.showError(e)
      }
    }
    getCompanyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getEcoSystemsData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getEcoSystemsData()
        if (data) {
          setEcoSystems({
            isLoading: false,
            ecoSystems: data,
          })
        }
      } catch (e) {
        setEcoSystems({
          isLoading: false,
          ecoSystems: [],
        })
        Error.showError(e)
      }
    }
    getEcoSystemsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   const getCoachList = async () => {
  //     try {
  //       const { data } = await API.AdminAPI.userList.getCoaches()
  //       if (data) {
  //         setCoaches({
  //           isLoading: false,
  //           coaches: data,
  //         })
  //       }
  //     } catch (e) {
  //       setCoaches({
  //         isLoading: false,
  //         coaches: [],
  //       })
  //       Error.showError(e)
  //     }
  //   }
  //   getCoachList()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const onSubmit = async (values, { resetForm }) => {
    resetForm()
  }

  return (
    // add coaches on value
    <UserListDataContext.Provider value={{ companies, ecoSystems }}>
      <Formik
        innerRef={formRef}
        displayName='profile-form'
        validateOnBlur={false}
        initialValues={userFormInitialValues}
        onSubmit={onSubmit}
        enableReinitialize={false}>
        <>
          <FilterForm
            needUserFilter={true}
            renderHeder={(users) => (
              <PageHeader
                titleText='User Accounts'
                wrapperClassName='d-flex justify-content-between'
                extraFn={() => {
                  return (
                    <>
                      <ExportUser
                        hasUsersData={users.length > 0 ? true : false}
                        showAddUserBtn={true}
                      />
                    </>
                  )
                }}
                titleClasses='mb-0 sentence-case-text'
                colClasses='px-0'
              />
            )}
            render={(users, isLoading, loader, isInitiallyLoad) => (
              <UserInfoInTable
                ref={loader}
                users={users}
                TextWhenNoUser='No users found for given criteria.'
                isLoading={isLoading}
                setHasUsersData={(setHasUsersData) => {}}
                wrapperClasses='px-0'
                isInitiallyLoad={isInitiallyLoad}
              />
            )}
          />
        </>
      </Formik>
    </UserListDataContext.Provider>
  )
})

export default Users
