import React from 'react'
import { useFormikContext } from 'formik'
import { ClearFilters } from '../common'

const ClearFilter = ({ isLoading = false }) => {
  const { values, resetForm } = useFormikContext()
  if (
    values.filterByAffiliation !== '' ||
    values.filterByCompany !== '' ||
    values.filterByPlan !== '' ||
    values.filterBySearch !== '' ||
    values.filterByPromoCode !== ''
    //|| values.filterByCoachId !== ''
  ) {
    return (
      <ClearFilters
        wrapperClasses='d-flex justify-content-end'
        onClick={() => {
          resetForm()
        }}
        disabled={isLoading}
      />
    )
  }
  return null
}

export default ClearFilter
