import { useFormikContext } from 'formik'
import { useEffect } from 'react'

export const GetCurrentClientsCapacity = () => {
  const { values, setFieldValue } = useFormikContext()
  useEffect(() => {
    setFieldValue(
      'currentCapacity',
      values.maximumClients - values.currentClients,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])
  return null
}
