import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Table } from 'react-bootstrap'
import { ShimmerTable } from 'react-shimmer-effects'
import { useLocation } from 'react-router-dom'
import { useErrorService } from '../../services'
import { paymentType } from '../../helper'
import { getNextPaymentDate } from '../../utils'

const defaultAddress = {
  line1: '',
  line2: '',
  state: '',
  country: '',
  postal_code: ''
}

const PendingPaymentTable = ({
  isHidePayButton = false,
  setIsTogglePayment,
  isTogglePayment,
  showHighlightedPart,
  PayButton = <></>,
  API,
  userId,
  setIsToggleCardListing = () => {},
  isToggleCardListing = false
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pendingPayments, setPendingPayments] = useState([])
  const [addressObject, setAddressObject] = useState(defaultAddress)
  const Error = useErrorService()
  const location = useLocation()

  useEffect(() => {
    const getUserPendingPayment = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.user.getUserPendingPayment(userId)
        if (data) {
          setPendingPayments(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserPendingPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getUserAddress = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.user.getUserAddress(userId)
        if (data?.address) {
          // remove "city" key from response
          Object.keys(data?.address).forEach(
            (key) => data?.address[key] === null && delete data?.address[key]
          )
          setAddressObject((prev) => ({
            ...prev,
            ...data?.address
          }))
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (!isHidePayButton) {
      getUserAddress()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPageTitle = () => {
    return <h3 className='s3 payment-title'>Pending Payment</h3>
  }

  if (isLoading) {
    return (
      <>
        {renderPageTitle()}
        <ShimmerTable row={2} col={4} />
      </>
    )
  }

  return (
    <section>
      {pendingPayments?.length > 0 && (
        <>
          {renderPageTitle()}
          <Table hover responsive className='custom-table'>
            <thead>
              <tr>
                <th width='25%'>SOW name</th>
                <th width='25%'>Type of payment</th>
                <th width='25%'>Amount</th>
                <th width='25%'>Action</th>
              </tr>
            </thead>
            <tbody>
              {pendingPayments?.map((payment, index) => (
                <tr
                  key={index}
                  id={payment?._id}
                  className={classNames({
                    'highlight-row':
                      `#${payment?._id}` === location?.hash &&
                      showHighlightedPart
                  })}
                >
                  <td width='25%'>
                    {payment?.planName} - {payment?.coachId?.fullName}
                  </td>
                  <td width='25%'>{!payment?.payments ? 'New' : 'Failed'}</td>
                  <td width='25%'>
                    {/* added initialFee && amount */}
                    {payment?.initialFee &&
                    payment?.amount &&
                    payment?.paymentCycle === Object.values(paymentType)[0]
                      ? `$${payment?.initialFee + payment?.amount} Today + $${
                          payment?.amount
                        } from ${getNextPaymentDate(
                          payment?.invoiceEvery
                        )}, every ${payment?.invoiceEvery} month(s).`
                      : null}
                    {/* added amount */}
                    {payment?.initialFee === 0 &&
                    payment?.amount &&
                    payment?.paymentCycle === Object.values(paymentType)[0]
                      ? `$${payment?.amount} from Today, every ${payment?.invoiceEvery} month(s).`
                      : null}
                    {/* added initialFee & payment type is onetime */}
                    {payment?.paymentCycle === Object.values(paymentType)[1] &&
                    (payment?.initialFee || payment?.initialFee === 0)
                      ? `$${payment?.initialFee}`
                      : null}
                  </td>
                  <td width='25%'>
                    {isHidePayButton ? (
                      '-'
                    ) : (
                      <PayButton
                        key={index}
                        payment={payment}
                        addressObject={addressObject}
                        setAddressObject={setAddressObject}
                        onSuccess={(sowId) => {
                          setPendingPayments((prev) =>
                            prev.filter(
                              (pendingPayment) => pendingPayment?._id !== sowId
                            )
                          )
                          setIsTogglePayment(!isTogglePayment)
                        }}
                        setIsToggleCardListing={setIsToggleCardListing}
                        isToggleCardListing={isToggleCardListing}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </section>
  )
}

export default PendingPaymentTable
