import React, { useState } from 'react'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import SignupPreviewModal from './SignupPreviewModal'

const PreviewInviteLink = ({
  disabled,
  orgEcoId,
  logo,
  signupTitle,
  signupSubTitle,
}) => {
  const [show, setShow] = useState(false)
  return (
    <span className='d-flex preview-modal-wrapper'>
      <SignupPreviewModal
        show={show}
        onHide={() => setShow(false)}
        orgEcoId={orgEcoId}
        logo={logo}
        signupTitle={signupTitle}
        signupSubTitle={signupSubTitle}
      />
      <Button
        variant='outline-dark'
        size='md'
        onClick={() => {
          if (disabled) return
          setShow(true)
        }}
        disabled={disabled}
        className={classNames('mb-4 border-radius-3 w-25 preview-btn', {
          'pointer-events-none': disabled,
        })}>
        PREVIEW
      </Button>
    </span>
  )
}

export default PreviewInviteLink
