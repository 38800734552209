import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { ABCard, ModelContext } from '@abroad/components'
import { getIntentionDate } from '../../utils/date'

const SanitizeHtml = loadable.lib(() =>
  import(/* webpackChunkName: "sanitizeHtml" */ 'sanitize-html'),
)

const InsightCard = ({ insight, intentionId }) => {
  const { onShow, setModalData } = useContext(ModelContext)

  const getInsightDescription = (data = '') => {
    return (
      <SanitizeHtml fallback={'Loading...'}>
        {({ default: sanitizeHtml }) =>
          sanitizeHtml(data, {
            allowedTags: [],
            allowedAttributes: {},
          })?.slice(0, 130)
        }
      </SanitizeHtml>
    )
  }
  return (
    <ABCard
      onClick={() => {
        onShow()
        setModalData({ ...insight, ...{ intentionId: intentionId } })
      }}
      shadow={false}
      onHoverShadow={true}
      className='overflow-hidden cursor-pointer'>
      <ABCard.Body className='p-0'>
        <div className='text-center py-1 font-montserrat text-uppercase tracking-lg-wide font-light text-white bg-dark-800'>
          {getIntentionDate(insight.createdAt)}
        </div>
        <div className='h-35 px-3 py-2 font-open-sans font-semibold font-14 leading-6 letter-spacing-minus-02'>
          {getInsightDescription(insight?.description)}
        </div>
      </ABCard.Body>
    </ABCard>
  )
}

export default InsightCard
