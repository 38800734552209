import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ABSpinner, useErrorService } from '@abroad/components'
import { useParams, useHistory } from 'react-router-dom'
import API from '../utils/API'
import { Helmet } from 'react-helmet'

const SurveyCategory = () => {
  const { category } = useParams()
  let history = useHistory()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [surveyCategory, setSurveyCategory] = useState(null)

  useEffect(() => {
    const getCategory = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getCategory(category)
        setSurveyCategory(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
        setTimeout(() => {
          history.replace('/admin/home')
        }, 3000)
      }
    }
    if (category) {
      getCategory()
    } else {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSpinner = () => {
    if (isLoading) {
      return (
        <section>
          <ABSpinner />
        </section>
      )
    }
    return null
  }

  const renderCategory = () => {
    if (surveyCategory) {
      return (
        <>
          <Helmet>
            <title>{`${surveyCategory?.title} | Abroad`}</title>
            <meta
              name='title'
              content={`${surveyCategory?.title} | Abroad`}></meta>
            <meta
              property='og:title'
              content={`${surveyCategory?.title} | Abroad`}></meta>
            <meta
              property='twitter:title'
              content={`${surveyCategory?.title} | Abroad`}></meta>
            <link
              rel='canonical'
              href={`${process.env.REACT_APP_DOMAIN}/survey/category/${category}`}></link>
            <meta
              property='og:url'
              content={`${process.env.REACT_APP_DOMAIN}/survey/category/${category}`}></meta>
            <meta
              property='twitter:url'
              content={`${process.env.REACT_APP_DOMAIN}/survey/category/${category}`}></meta>
          </Helmet>
          <section>
            <Container
              className='space-y-5 py-4 px-lg-0 custom-offset-lg-1 custom-col-lg-12'
              fluid>
              <Row>
                <Col className='text-center'>
                  <h2 className='font-montserrat font-semibold text-dark-600'>
                    {surveyCategory?.title}
                  </h2>
                </Col>
              </Row>
            </Container>
          </section>
          <section className='bg-gray-100'>
            <Container
              className='space-y-5 py-4 px-lg-0 custom-offset-lg-1 custom-col-lg-12'
              fluid>
              <h3 className='text-gray-201 font-montserrat font-semibold'>
                Overview
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: surveyCategory?.description,
                }}
              />
            </Container>
          </section>
          {surveyCategory?.actionItems.length > 0 && (
            <>
              {surveyCategory?.actionItems.map((item, index) => {
                if (index % 2 === 0) {
                  return (
                    <section key={index}>
                      <Container
                        className='space-y-5 pb-4 pt-5 px-lg-0 custom-offset-lg-1 custom-col-lg-12'
                        fluid>
                        {index === 0 && (
                          <h3 className='text-gray-201 font-montserrat font-semibold'>
                            Suggested Practices
                          </h3>
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item,
                          }}
                        />
                      </Container>
                    </section>
                  )
                }
                return (
                  <section key={index} className='bg-gray-100'>
                    <Container
                      className='space-y-5 pb-4 pt-5 px-lg-0 custom-offset-lg-1 custom-col-lg-12'
                      fluid>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item,
                        }}
                      />
                    </Container>
                  </section>
                )
              })}
            </>
          )}
        </>
      )
    }
    return null
  }

  const renderEmptyContainer = () => {
    if (!surveyCategory && !isLoading) {
      return (
        <section className='h-60 d-flex justify-content-center align-items-center'>
          <h4 className='font-montserrat font-semibold text-dark-600'>
            Please Enter a valid Category
          </h4>
        </section>
      )
    }
    return null
  }

  return (
    <main>
      {renderSpinner()}
      {renderCategory()}
      {renderEmptyContainer()}
    </main>
  )
}

export default SurveyCategory
