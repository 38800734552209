import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { PageHeader } from '../components/common'
import { UpdatePromoForm } from '../components/promoCode'

export const UpdatePromoCode = () => {
  const { promoId } = useParams()

  return (
    <>
      <Helmet>
        <title>{'Update Promo Code | Abroad'}</title>
        <meta name='title' content={'Update Promo Code | Abroad'}></meta>
        <meta property='og:title' content={'Update Promo Code | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Update Promo Code | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/promo/${promoId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/promo/${promoId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/promo/${promoId}`}></meta>
      </Helmet>
      <main>
        <PageHeader titleText='UPDATE PROMO CODE' colClasses='px-lg-0' />
        <section>
          <Container
            className='pt-5 custom-offset-lg-1 custom-col-lg-12 px-lg-0'
            fluid>
            <UpdatePromoForm />
          </Container>
        </section>
      </main>
    </>
  )
}
