import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Modal, Form as RBForm, Row, Col } from 'react-bootstrap'
import { addMethod, object, string } from 'yup'
import { useParams } from 'react-router-dom'
import { Button, useErrorService } from '@abroad/components'
import CheckSelectedCoachIsStaffCoach from './CheckSelectedCoachIsStaffCoach'
import { SelectionField } from '../common'
import API from '../../utils/API'
import { removeEmpty, getUnmaskedObject } from '../../utils/utility'
import { currencyUnmask } from '../enterpriseSOW/MonthlyPaymentTable'
import CurrencyInput from '../enterpriseSOW/CurrencyInput'

const columns = [
  { name: 'IC', key: 'ic' },
  { name: 'Manager', key: 'manager' },
  { name: 'Leader', key: 'leader' },
  { name: 'Executive', key: 'executive' },
  { name: 'Board/CEO', key: 'ceo' },
]

const initialValue = {
  ic: null,
  manager: null,
  leader: null,
  executive: null,
  ceo: null,
}

const initialValues = {
  coachId: null,
  individualPricing: initialValue,
  groupPricing: initialValue,
}

const CoachPaymentRatesModal = ({
  showModal,
  onHide,
  addNewCoachPaymentRate = () => {},
  closeClickedHandler = () => {},
  data = null,
  existingCoachIds = [],
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCoachIsStaffCoach, setSelectedCoachIsStaffCoach] =
    useState(false)
  const [coaches, setCoaches] = useState([])
  const [isLoadingCoaches, setIsLoadingCoaches] = useState(true)
  const Error = useErrorService()
  const { sowId } = useParams()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  useEffect(() => {
    if (data) {
      setInitialFormikValues({
        coachId: data?.coachId?._id,
        individualPricing: data?.individualPricing,
        groupPricing: data?.groupPricing,
      })
    }
  }, [data])

  const isValidCoachPayment = () => {
    return string().test(
      'isValidCoachPayment',
      'Please enter valid coach payment rate!',
      function (value) {
        if (value === null || value === '' || value === undefined) return true
        const unmaskMonthlyFee = currencyUnmask(value)
        return unmaskMonthlyFee >= 0 && unmaskMonthlyFee <= 1000
      },
    )
  }
  addMethod(string, 'isValidCoachPayment', isValidCoachPayment)

  const validationSchema = object().shape({
    coachId: string().required(),
    individualPricing: object().shape({
      ic: string().isValidCoachPayment().nullable(),
      manager: string().isValidCoachPayment().nullable(),
      leader: string().isValidCoachPayment().nullable(),
      executive: string().isValidCoachPayment().nullable(),
      ceo: string().isValidCoachPayment().nullable(),
    }),
    groupPricing: object().shape({
      ic: string().isValidCoachPayment().nullable(),
      manager: string().isValidCoachPayment().nullable(),
      leader: string().isValidCoachPayment().nullable(),
      executive: string().isValidCoachPayment().nullable(),
      ceo: string().isValidCoachPayment().nullable(),
    }),
  })

  useEffect(() => {
    const getCoachData = async () => {
      setIsLoadingCoaches(true)
      try {
        const { data } = await API.AdminAPI.teams.getCoachData()
        if (data) {
          const filteredCoaches = data?.filter(
            (coach) => !existingCoachIds.includes(coach?._id),
          )
          setCoaches(
            filteredCoaches?.map((coach) => ({
              label: `${coach?.fullName} ${
                coach?.isStaffCoach ? '(Staff Coach)' : ''
              }`,
              value: coach?._id,
              isStaffCoach: coach?.isStaffCoach || false,
            })),
          )
        }
        setIsLoadingCoaches(false)
      } catch (e) {
        Error.showError(e)
        setIsLoadingCoaches(false)
      }
    }
    if (showModal && data === null) {
      getCoachData()
    } else {
      setCoaches([
        {
          label: data?.coachId?.fullName,
          value: data?.coachId?._id,
          isStaffCoach: data?.coachId?.isStaffCoach || false,
        },
      ])
      setIsLoadingCoaches(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const onSubmit = async (values) => {
    const findSelectedCoach = coaches?.find(
      (coach) => coach?.value === values?.coachId,
    )
    const newCoachValue = {
      _id: findSelectedCoach?.value,
      fullName: findSelectedCoach?.label,
      isStaffCoach: findSelectedCoach?.isStaffCoach,
    }
    setIsLoading(true)

    const newIndividualPricingObject = getUnmaskedObject(
      values.individualPricing,
      (value) => currencyUnmask(value),
    )

    const newGroupPricingObject = getUnmaskedObject(
      values.groupPricing,
      (value) => currencyUnmask(value),
    )

    const newObject = {
      coachId: newCoachValue,
      groupPricing: newGroupPricingObject,
      individualPricing: newIndividualPricingObject,
    }

    const individualPricing = removeEmpty(newIndividualPricingObject)
    const groupPricing = removeEmpty(newGroupPricingObject)

    const getAPIFunction = (paymentData, coachId) => {
      if (data) {
        return API.AdminAPI.companyList.updateCoachPaymentRates(
          paymentData,
          coachId,
          sowId,
        )
      } else {
        return API.AdminAPI.companyList.addCoachPaymentRates(
          paymentData,
          coachId,
          sowId,
        )
      }
    }

    try {
      const { status } = await getAPIFunction(
        {
          ...(individualPricing && {
            individualPricing: individualPricing,
          }),
          ...(groupPricing && {
            groupPricing: groupPricing,
          }),
        },
        values?.coachId,
      )
      if (status) {
        setIsLoading(false)
        onHide()
        addNewCoachPaymentRate(newObject)
        closeClickedHandler()
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      className='custom-modal coach-payment-rates'
      centered>
      <Modal.Body>
        <h3 className='s2 mb-4 text-center'>
          {data ? 'Edit SOW Coach' : 'Add Coaches to the SOW'}
        </h3>
        <Formik
          displayName='coach-payment-rates-form'
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialFormikValues}
          onSubmit={onSubmit}>
          {({ handleSubmit }) => {
            return (
              <RBForm
                onSubmit={handleSubmit}
                as={Form}
                className='profile px-0'>
                <SelectionField
                  name='coachId'
                  size='lg'
                  options={coaches}
                  getValueAsOption={true}
                  optionplaceholder={
                    isLoadingCoaches
                      ? 'Fetching Coaches...'
                      : 'Select Coaches to the SOW'
                  }
                  sm='10'
                  className='cursor-pointer mb-3 bg-white'
                  disabled={isLoadingCoaches || isLoading || data !== null}
                />
                <Row>
                  <Col className='col-4'></Col>
                  <Col className='col-4 mb-3'>
                    <span className='s8'>Individual Rate</span>
                  </Col>
                  <Col className='col-4 mb-3'>
                    <span className='s8'>Group Rate</span>
                  </Col>
                </Row>
                {columns.map((col) => (
                  <Row className='mb-4 coach-rates'>
                    <Col className='col-4 s6 d-flex align-items-center'>
                      {col.name}
                    </Col>
                    <Col className='col-4'>
                      <CurrencyInput
                        unmask={currencyUnmask}
                        name={`individualPricing.${col.key}`}
                        type='text'
                        size='md'
                        disabled={isLoading || selectedCoachIsStaffCoach}
                        formGroupProps={{ className: 'mb-0' }}
                      />
                    </Col>
                    <Col className='col-4'>
                      {/* TODO: is-valid class not apply on group inputs */}
                      <CurrencyInput
                        unmask={currencyUnmask}
                        name={`groupPricing.${col.key}`}
                        type='text'
                        size='md'
                        disabled={isLoading || selectedCoachIsStaffCoach}
                        formGroupProps={{ className: 'mb-0' }}
                      />
                    </Col>
                  </Row>
                ))}
                <CheckSelectedCoachIsStaffCoach
                  coaches={coaches}
                  setSelectedCoachIsStaffCoach={setSelectedCoachIsStaffCoach}
                />
                <div className='text-right'>
                  <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    type='submit'
                    className='w-5/12 line-height-normal'
                    variant='saffron'>
                    Finish
                  </Button>
                </div>
              </RBForm>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CoachPaymentRatesModal
