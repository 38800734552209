import React, { useState, useContext, useEffect } from 'react'
import {
  useErrorService,
  NotificationContext,
  useUserService,
  FeatherLoader,
  Button,
} from '@abroad/components'
import API from '../../utils/API'
import CheckBox from './CheckBox'
import { Modal, Table } from 'react-bootstrap'
import { getGroupLabel, plans } from '../../utils/utility'

const ToggleCoachPrivileges = ({ userId, isCoach, coachId, index, render }) => {
  const { user, setUser } = useUserService()
  const [checkboxStatus, setCheckboxStatus] = useState({
    disabled: !!coachId,
    checked: isCoach,
  })
  const Error = useErrorService()
  const { showNotification } = useContext(NotificationContext)
  const [showModal, setShowModal] = useState({ show: false, value: null })
  const [isLoadingRemoveCoach, setIsLoadingRemoveCoach] = useState(false)
  const [coachDetails, setCoachDetails] = useState({
    details: null,
    isLoading: true,
  })

  useEffect(() => {
    const getCoachDetails = async () => {
      try {
        const { data } = await API.AdminAPI.userList.getCoachClientsAndGroups(
          userId,
        )
        if (data) {
          setCoachDetails({ isLoading: false, details: data })
        }
      } catch (e) {
        Error.showError(e)
        setCoachDetails({ isLoading: false, details: null })
      }
    }

    if (showModal.show) getCoachDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal.show])

  const getUserPriviliges = async () => {
    try {
      const { data } = await API.ClientAPI.user.getUserPriviliges()
      if (data) {
        const profile = data.profile || {}
        delete data.profile
        setUser({
          ...user,
          ...data,
          profile: {
            ...user.profile,
            ...profile,
          },
        })
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const toggleCoachPrivileges = async (isChecked) => {
    setIsLoadingRemoveCoach(true)
    setCheckboxStatus({
      disabled: true,
      checked: isChecked,
    })
    try {
      const { data } = await API.AdminAPI.userList.toggleCoachPrivileges(
        {
          isCoach: isChecked,
        },
        userId,
      )
      if (data) {
        if (userId === user?.id) {
          getUserPriviliges()
        }
        setCheckboxStatus((prev) => ({
          ...prev,
          disabled: !!coachId,
        }))
        setIsLoadingRemoveCoach(false)
        setShowModal({ show: false, value: null })
        render(isChecked)
        showNotification('User Changes Saved!', 'success')
      }
    } catch (e) {
      setCheckboxStatus({
        disabled: !!coachId,
        checked: isCoach,
      })
      setIsLoadingRemoveCoach(false)
      setShowModal({ show: false, value: null })
      Error.showError(e)
    }
  }
  const handleClose = () => setShowModal({ ...showModal, show: false })

  const renderClientsTable = (key, title, emptyClientsMessage) => {
    return (
      <React.Fragment>
        <span className='mb-1 mt-4 font-open-sans font-semibold font-14 leading-6 letter-spacing-02'>
          <b>{title}</b>
        </span>
        <div className='remove-coach-popup'>
          <Table hover responsive className='custom-table'>
            <thead>
              <tr className='text-uppercase'>
                <th>Full Name</th>
                <th>Plan</th>
              </tr>
            </thead>
            <tbody>
              {coachDetails.isLoading && (
                <tr>
                  <td colSpan='2' className='text-center border-0'>
                    <FeatherLoader />
                  </td>
                </tr>
              )}
              {!coachDetails.isLoading &&
                coachDetails?.details[key]?.length === 0 && (
                  <tr>
                    <td colSpan='2' className='text-center'>
                      {emptyClientsMessage}
                    </td>
                  </tr>
                )}

              {!coachDetails.isLoading &&
                coachDetails?.details[key]?.length > 0 && (
                  <>
                    {coachDetails?.details[key]?.map((client, index) => (
                      <tr key={index}>
                        <td>{client?.fullName}</td>
                        <td>{plans[client?.planCode]}</td>
                      </tr>
                    ))}
                  </>
                )}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    )
  }

  const renderGroupsTable = (key, title, emptyGroupsMessage) => {
    return (
      <React.Fragment>
        <span className='mb-1 mt-4 font-open-sans font-semibold font-14 leading-6 letter-spacing-02'>
          <b>{title}</b>
        </span>
        <div className='remove-coach-popup'>
          <Table hover responsive className='custom-table'>
            <thead>
              <tr className='text-uppercase'>
                <th>GROUP NAME</th>
                <th>GROUP</th>
                <th>Other Coaches</th>
              </tr>
            </thead>
            <tbody>
              {coachDetails.isLoading && (
                <tr>
                  <td colSpan='3' className='text-center border-0'>
                    <FeatherLoader />
                  </td>
                </tr>
              )}
              {!coachDetails.isLoading &&
                coachDetails?.details[key]?.length === 0 && (
                  <tr>
                    <td colSpan='3' className='text-center'>
                      {emptyGroupsMessage}
                    </td>
                  </tr>
                )}

              {!coachDetails.isLoading &&
                coachDetails?.details[key]?.length > 0 && (
                  <>
                    {coachDetails?.details[key]?.map((group, index) => (
                      <tr key={index}>
                        <td>{group?.name}</td>
                        <td>{getGroupLabel(group?.groupType)}</td>
                        <td className='word-break'>
                          {group?.otherCoaches?.length > 0
                            ? group?.otherCoaches
                                .map((coach) => coach.fullName)
                                .join(', ')
                            : '-'}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    )
  }

  return (
    <>
      <Modal
        show={showModal.show}
        onHide={handleClose}
        centered
        className='remove-coach-privileges custom__modal'
        scrollable={true}>
        <Modal.Header className='border-0 pb-0 px-4'>
          <Modal.Title className='text-uppercase font-montserrat font-16 font-bold leading-5 letter-spacing-02'>
            You are about to revoke this coach's coaching access. This coach is
            assigned to the following groups and clients. Please review these
            details carefully before revoking the access:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-4 py-0 border-0'>
          <div className='d-flex flex-column'>
            {renderClientsTable(
              'indiClients',
              'Individual Clients:',
              'No Individual Clients',
            )}
            {renderGroupsTable(
              'indiGroups',
              'Individual Groups:',
              'No Individual Groups',
            )}
            {renderClientsTable('b2bClients', 'B2B Clients:', 'No B2B Clients')}
            {renderGroupsTable('b2bGroups', 'B2B Groups:', 'No B2B Groups')}
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end px-4 space-x-3'>
          <>
            <Button
              variant='none'
              onClick={handleClose}
              className='px-3 secondary-popup-button'>
              Cancel
            </Button>
            <Button
              variant='saffron'
              disabled={isLoadingRemoveCoach || coachDetails.isLoading}
              isLoading={isLoadingRemoveCoach}
              onClick={() => toggleCoachPrivileges(showModal.value)}
              className='px-3'>
              Revoke Access
            </Button>
          </>
        </Modal.Footer>
      </Modal>
      <CheckBox
        onChange={(e) =>
          e.target.checked
            ? toggleCoachPrivileges(e.target.checked)
            : setShowModal({ show: true, value: e.target.checked })
        }
        checked={checkboxStatus.checked}
        disabled={checkboxStatus.disabled}
        id={`Coach-${index}`}
        className='privileges-checkbox ml-0'
      />
      <label className='form-check-label mt-1 ml-1' htmlFor={`Coach-${index}`}>
        Coach
      </label>
    </>
  )
}

export default ToggleCoachPrivileges
