import React from 'react'

const ReturnPageIcon = ({ onClick, ...rest }) => {
  return (
    <div
      className='d-flex flex-column px-0 justify-content-center align-items-center pointer-cursor back-btn mr-3'
      onClick={onClick}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='43'
        viewBox='0 0 24 43'
        fill='none'
        {...rest}>
        <path
          d='M18.5748 1.35352L1.4248 17.9997'
          stroke='black'
          strokeWidth='2.5'
          strokeLinecap='round'
        />
        <path
          d='M18.5748 34.6462L1.4248 18'
          stroke='black'
          strokeWidth='2.5'
          strokeLinecap='round'
        />
      </svg>

      <svg
        width='24'
        height='43'
        viewBox='0 0 24 43'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...rest}>
        <path
          d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
          fill='black'
        />
      </svg>
    </div>
  )
}

export default ReturnPageIcon
