import React from 'react'

const ShowTakenByCoachName = ({ coach, userId, isAdmin }) => {
  if (!coach) return null
  return (
    <React.Fragment>
      {coach?._id === userId ? (
        <span>Taken by you</span>
      ) : (
        <span>Taken by {coach?.fullName}</span>
      )}
    </React.Fragment>
  )
}

export default ShowTakenByCoachName
