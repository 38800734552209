import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import {
  useErrorService,
  ABSpinner,
  useNotificationService,
} from '@abroad/components'
import CKEditor from '../editor'
import API from '../../utils/API'
import { SubCategoryOfSiteCopy } from './SubCategoryOfSiteCopy'

export const ParentCategoryOfSiteCopy = ({ parentKey, activeLink }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [description, setDescription] = useState('')
  const [categoryId, setCategoryId] = useState(null)
  const [subcategoriesData, setSubcategoriesData] = useState(null)
  const Error = useErrorService()
  const Notification = useNotificationService()

  useEffect(() => {
    const getSiteCopyParentData = async () => {
      try {
        const { data } =
          await API.AdminAPI.siteCopy.getSiteCopyParentCategoryData(parentKey)
        if (data) {
          setDescription(data?.description)
          setCategoryId(data?._id)
          setSubcategoriesData(data?.subcategories)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getSiteCopyParentData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  const handleSaveDescription = async () => {
    try {
      const { data } =
        await API.AdminAPI.siteCopy.updateSiteCopyParentCategoryData(
          { description },
          categoryId,
        )
      if (data) {
        Notification.showNotification(
          'Description has been updated!',
          'success',
        )
      }
    } catch (e) {
      Error.showError(e)
    }
  }
  return (
    <main className='custom-offset-lg-1 custom-col-lg-12 py-3 px-lg-0'>
      <section className='sitecopy h-100 w-100 d-flex p-3 bg-gray-100 d-flex flex-column'>
        <h4 className='font-20 font-montserrat font-medium font-lg-24 text-dark-600 mb-4 sentence-case-text'>
          USER ASSESSMENT COPY
        </h4>
        <Row className='mb-3 d-flex'>
          <Col lg={4} md={4} sm={12} className='my-4'>
            <Link
              className={classnames('text-dark-600 px-3 py-2', {
                'border-radius-10 site-copy-btn-active': activeLink === 'sa',
                'site-copy-btn': activeLink !== 'sa',
              })}
              to='/admin/sitecopy/user/sa'>
              Self Awareness
            </Link>
          </Col>
          <Col lg={4} md={4} sm={12} className='my-4'>
            <Link
              className={classnames('text-dark-600 px-3 py-2', {
                'border-radius-10 site-copy-btn-active': activeLink === 'oe',
                'site-copy-btn': activeLink !== 'oe',
              })}
              to='/admin/sitecopy/user/oe'>
              Organizational Efficacy
            </Link>
          </Col>
          <Col lg={4} md={4} sm={12} className='my-4'>
            <Link
              className={classnames('text-dark-600 px-3 py-2', {
                'border-radius-10 site-copy-btn-active': activeLink === 'vi',
                'site-copy-btn': activeLink !== 'vi',
              })}
              to='/admin/sitecopy/user/vi'>
              Vision & Impact
            </Link>
          </Col>
        </Row>
        <div className='parent-discription d-flex flex-column'>
          <h5 className='text-dark-600 font-16 font-montserrat font-medium font-lg-20'>
            DESCRIPTION
          </h5>
          <br />
          <CKEditor
            data={description}
            onChange={(event, editor) => {
              setDescription(editor.getData())
            }}
            onBlur={handleSaveDescription}
          />
        </div>
        <SubCategoryOfSiteCopy data={subcategoriesData} />
      </section>
    </main>
  )
}
