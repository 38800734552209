import React, { useContext } from 'react'
import { ModelContext } from '../../contexts'

const LearnMore = ({ category, i18n, onLearnMoreClick }) => {
  const { onShow, setModalData } = useContext(ModelContext)
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={() => {
        onShow()
        setModalData(category)
        onLearnMoreClick(category._id)
      }}
      data-toggle='modal'
      className='btn-overall-score cursor-pointer'
    >
      LEARN MORE
    </a>
  )
}

export default LearnMore
