import React, { useEffect, useState } from 'react'
import { FieldArray } from 'formik'
import classNames from 'classnames'
import { InputField } from '../form'

const BehaviorsPrinciplesField = ({
  keyProp,
  maxInputAllow,
  nameOfInput,
  placeholder,
  values,
}) => {
  let boundArrayHelpers

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers
  }

  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    setIsDisabled(values.length === maxInputAllow)
  }, [values, maxInputAllow])

  const addNewInputs = () => {
    if (isDisabled) return
    boundArrayHelpers.push('')
  }

  const inputDeletehandler = (index) => {
    if (values.length === 1) return
    boundArrayHelpers.remove(index)
  }

  return (
    <React.Fragment>
      <FieldArray
        name={nameOfInput}
        render={(arrayHelpers) => {
          bindArrayHelpers(arrayHelpers)
          return (
            <>
              {values.map((_, index) => (
                <React.Fragment key={index}>
                  <InputField
                    className='custom-mb-0-625'
                    name={`${nameOfInput}.${index}`}
                    type='text'
                    size='lg'
                    placeholder={placeholder}
                    sm='12'
                    showErrorMessage={true}
                    hasErrorMessagePadding={false}
                    renderLink={values.length === 1 ? false : true}
                    linkWrapperClasses='delete-link font-open-sans font-14 font-bold leading-6 letter-spacing-minus-02'
                    linkLabel='Delete'
                    linkClickHandler={(e) => {
                      e.preventDefault()
                      inputDeletehandler(index)
                    }}
                    inputClasses={classNames({
                      'pr-10-percent': values.length !== 1,
                    })}
                  />
                </React.Fragment>
              ))}
            </>
          )
        }}
      />
      <div
        className={classNames('custom-mb-0-625', {
          'add-input': !isDisabled,
        })}>
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={classNames(
            'm-0',
            {
              'cursor-disabled disable': isDisabled,
            },
            {
              'pointer-cursor': !isDisabled,
            },
          )}
          onClick={addNewInputs}>
          <rect width='32' height='32' rx='3' fill='black' fillOpacity='0.7' />
          <line
            x1='16'
            y1='9'
            x2='16'
            y2='23'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <line
            x1='9'
            y1='16'
            x2='23'
            y2='16'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
          />
        </svg>
      </div>
    </React.Fragment>
  )
}

export default BehaviorsPrinciplesField
