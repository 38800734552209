import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { LeftAngleIcon } from '@abroad/components'

const ReturnLink = ({ prevStepHandler, isDisabled = false }) => {
  return (
    <Link
      onClick={(e) => {
        e.preventDefault()
        prevStepHandler()
      }}
      className={classNames(
        's5 d-flex align-items-center space-x-3 text-dark-900',
        {
          'pointer-event-none': isDisabled,
        },
      )}>
      <LeftAngleIcon />
      <span className='text-dark-900'>Return</span>
    </Link>
  )
}

export default ReturnLink
