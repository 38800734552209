import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { ABSpinner } from '@abroad/components'

const VerifyingTwoFactAuth = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.location.replace(`${process.env.REACT_APP_CLIENT_DOMAIN}${pathname}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ABSpinner />
}

export default VerifyingTwoFactAuth
