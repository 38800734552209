import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Accordion, Form as RBForm, Button } from 'react-bootstrap'
import { useErrorService, useNotificationService } from '@abroad/components'
import { InputField } from '../form'
import CKEditor from '../editor'
import API from '../../utils/API'
import { isLGScreen } from '../../utils/utility'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const formInitialValues = {
  questionId0: '',
  questionId1: '',
  questionId2: '',
  questionId3: '',
  questionId4: '',
  questionId5: '',
  question0: '',
  question1: '',
  question2: '',
  question3: '',
  question4: '',
  question5: '',
  action0: '',
  action1: '',
  action2: '',
  action3: '',
  action4: '',
  action5: '',
  resource0: '',
  resource1: '',
  resource2: '',
  resource3: '',
  resource4: '',
  resource5: '',
  content0: '',
  content1: '',
  content2: '',
  content3: '',
  content4: '',
  content5: '',
  mediaId0: '',
  mediaId1: '',
  mediaId2: '',
  mediaId3: '',
  mediaId4: '',
  mediaId5: '',
  youtubeId: '',
}

export const SubCategory = ({ id, title, isUpdateLoading }) => {
  const [description, setDescription] = useState('')
  const [questionsData, setQuestionsData] = useState(null)
  const [actionItems, setActionItems] = useState([])
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const Notification = useNotificationService()
  const Error = useErrorService()

  useEffect(() => {
    const getSiteCopySubCategoryData = async () => {
      try {
        const { data } = await API.AdminAPI.siteCopy.getSiteCopySubCategoryData(
          id,
        )
        if (data) {
          setDescription(data?.description)
          setQuestionsData(data?.questions)
          setInitialValues({
            questionId0: data?.questions[0]?._id,
            questionId1: data?.questions[1]?._id,
            questionId2: data?.questions[2]?._id,
            questionId3: data?.questions[3]?._id,
            questionId4: data?.questions[4]?._id,
            questionId5: data?.questions[5]?._id,
            question0: data?.questions[0]?.question,
            question1: data?.questions[1]?.question,
            question2: data?.questions[2]?.question,
            question3: data?.questions[3]?.question,
            question4: data?.questions[4]?.question,
            question5: data?.questions[5]?.question,
            action0: data?.questions[0]?.action,
            action1: data?.questions[1]?.action,
            action2: data?.questions[2]?.action,
            action3: data?.questions[3]?.action,
            action4: data?.questions[4]?.action,
            action5: data?.questions[5]?.action,
            resource0: data?.questions[0]?.resource,
            resource1: data?.questions[1]?.resource,
            resource2: data?.questions[2]?.resource,
            resource3: data?.questions[3]?.resource,
            resource4: data?.questions[4]?.resource,
            resource5: data?.questions[5]?.resource,
            content0: data?.questions[0]?.cta,
            content1: data?.questions[1]?.cta,
            content2: data?.questions[2]?.cta,
            content3: data?.questions[3]?.cta,
            content4: data?.questions[4]?.cta,
            content5: data?.questions[5]?.cta,
            mediaId0: data?.questions[0]?.mediaId,
            mediaId1: data?.questions[1]?.mediaId,
            mediaId2: data?.questions[2]?.mediaId,
            mediaId3: data?.questions[3]?.mediaId,
            mediaId4: data?.questions[4]?.mediaId,
            mediaId5: data?.questions[5]?.mediaId,
            youtubeId: data?.videoBasic,
          })
          setActionItems(data?.actionItems)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getSiteCopySubCategoryData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const HandleUpdateActionItems = (data, index) => {
    let newItemsArray = [...actionItems]

    newItemsArray[index] = data

    setActionItems(newItemsArray)
  }

  useEffect(() => {
    if (isUpdateLoading) {
      document.getElementById(`subcategory-btn${id}`).click()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [isUpdateLoading]) // eslint-disable-line

  const onSubmit = async (values) => {
    const params = {
      actionItems,
      description,
      questions: [
        {
          id: values.questionId0,
          question: values.question0,
          cta: values.content0,
          resource: values.resource0,
          action: values.action0,
          mediaId: values.mediaId0,
        },
        {
          id: values.questionId1,
          question: values.question1,
          cta: values.content1,
          resource: values.resource1,
          action: values.action1,
          mediaId: values.mediaId1,
        },
        {
          id: values.questionId2,
          question: values.question2,
          cta: values.content2,
          resource: values.resource2,
          action: values.action2,
          mediaId: values.mediaId2,
        },
        {
          id: values.questionId3,
          question: values.question3,
          cta: values.content3,
          resource: values.resource3,
          action: values.action3,
          mediaId: values.mediaId3,
        },
        {
          id: values.questionId4,
          question: values.question4,
          cta: values.content4,
          resource: values.resource4,
          action: values.action4,
          mediaId: values.mediaId4,
        },
        {
          id: values.questionId5,
          question: values.question5,
          cta: values.content5,
          resource: values.resource5,
          action: values.action5,
          mediaId: values.mediaId5,
        },
      ],
      videoBasic: values.youtubeId,
    }
    try {
      const { data } =
        await API.AdminAPI.siteCopy.updateSiteCopySubCategoryData(id, params)
      if (data) {
        Notification.showNotification('SiteCopy has been updated!', 'success')
      }
    } catch (e) {
      Error.showError(e)
    }
  }
  return (
    <section className='p-lg-3 p-2'>
      <Accordion className='sitecopy'>
        <Accordion.Toggle
          eventKey='subCategory'
          className='subcategory-questions w-100 text-left p-2'>
          {title} (Click to contract or expand.)
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='subCategory'>
          <div className='sub-discription d-flex flex-column px-2 py-2'>
            <h5 className='text-dark-600 font-16 font-montserrat font-medium font-lg-20 mt-2'>
              DESCRIPTION
            </h5>
            <br />
            <CKEditor
              data={description}
              onChange={(event, editor) => {
                setDescription(editor.getData())
              }}
            />
            <h5 className='text-dark-600 font-16 font-montserrat font-medium font-lg-20 mt-2'>
              QUESTIONS
            </h5>
            <Formik
              displayName='profile-form'
              initialValues={initialValues}
              enableReinitialize={true}
              validateOnMount
              onSubmit={onSubmit}>
              {({ values, handleSubmit }) => {
                return (
                  <RBForm
                    onSubmit={handleSubmit}
                    as={Form}
                    className='site-copy-sub-category px-4 px-lg-0'>
                    {questionsData?.map((questionData, index) => (
                      <React.Fragment key={questionData._id}>
                        <InputField
                          name={`questionId${index}`}
                          as='textarea'
                          rows={3}
                          size='lg'
                          placeholder='QUESTION ID'
                          className='d-none'
                          disabled
                          sm='10'
                          required
                        />
                        <InputField
                          labelProps={labelProps}
                          label='QUESTION:'
                          name={`question${index}`}
                          as='textarea'
                          rows={3}
                          size='lg'
                          placeholder='QUESTION'
                          sm='10'
                          required
                        />
                        <InputField
                          labelProps={labelProps}
                          label='CALL TO ACTION:'
                          name={`action${index}`}
                          as='textarea'
                          rows={3}
                          size='lg'
                          placeholder='CALL TO ACTION'
                          sm='10'
                          required
                        />
                        <InputField
                          labelProps={labelProps}
                          label='RESOURCE:'
                          name={`resource${index}`}
                          as='textarea'
                          rows={3}
                          size='lg'
                          placeholder='RESOURCE'
                          sm='10'
                          required
                        />
                        <InputField
                          labelProps={labelProps}
                          label='EXPERT CONTENT:'
                          name={`content${index}`}
                          as='textarea'
                          rows={3}
                          size='lg'
                          placeholder='EXPERT CONTENT'
                          sm='10'
                          required
                        />
                        <InputField
                          name={`mediaId${index}`}
                          as='textarea'
                          rows={3}
                          size='lg'
                          placeholder='MEDIA ID'
                          className='d-none'
                          disabled
                          sm='10'
                          required
                        />
                      </React.Fragment>
                    ))}
                    <InputField
                      labelProps={labelProps}
                      label='VIDEO (ONLY VIDEO ID OF YOUTUBE VIDEO)'
                      name='youtubeId'
                      as='textarea'
                      rows={3}
                      size='lg'
                      placeholder='VIDEO ID'
                      sm='10'
                      required
                    />
                    {actionItems?.map((actionItem, index) => (
                      <div className='my-3'>
                        <CKEditor
                          key={index}
                          data={actionItem}
                          onBlur={(event, editor) => {
                            HandleUpdateActionItems(editor.getData(), index)
                          }}
                        />
                      </div>
                    ))}
                    <Button
                      type='submit'
                      id={`subcategory-btn${id}`}
                      className='d-none mt-3'
                      variant='dark'>
                      Submit
                    </Button>
                  </RBForm>
                )
              }}
            </Formik>
          </div>
        </Accordion.Collapse>
      </Accordion>
    </section>
  )
}
