import React from 'react'
import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { GoBackIcon } from '@abroad/components'

const JourneyAudioInformation = ({
  title,
  imageURL,
  moduleDetails = '',
  source,
  removeMediaHandler = () => {},
  wrapperClasses = '',
  isLoading = false,
}) => {
  return (
    <div className={classNames('media-list pointer-cursor', wrapperClasses)}>
      <Row className='custom-mb-0-625'>
        <Col className='col-11 d-flex align-items-center'>
          <div className='d-flex align-items-center'>
            <div className='media-image'>
              <div className='main-image'>
                <Imgix
                  className='border-radius-5 border-black'
                  imgixParams={{ fit: 'scale', w: 48, h: 48 }}
                  src={`${source}/${imageURL}`}
                  height={48}
                  width={48}
                  alt='image'
                />
              </div>
              {getPlayIconSvg()}
            </div>
            <div className='d-flex flex-column justify-content-center ml-3'>
              <span className='font-open-sans font-13 font-semibold leading-4-5 letter-spacing-minus-04'>
                {title}
              </span>
              <span className='font-open-sans font-10 font-semibold leading-3-5 tracking-normal'>
                {moduleDetails}
              </span>
            </div>
          </div>
        </Col>
        <Col className='col-1 align-self-center'>
          <GoBackIcon
            className='media-remove-icon'
            onClick={removeMediaHandler}
          />
        </Col>
      </Row>
      <hr className='m-0 p-0 media-list-divider d-lg-block d-none' />
    </div>
  )
}

const getPlayIconSvg = () => {
  return (
    <div className='play-icon border-radius-5 bg-dark-600'>
      <span className='icon icon-play' style={{ fontSize: '48px' }} />
    </div>
  )
}

export default JourneyAudioInformation
