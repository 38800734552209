import React, { useState, useRef, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import {
  ABSpinner,
  useErrorService,
  useResolutionService,
} from '@abroad/components'
import { ABSwiperSlider } from '../slider'
import API from '../../utils/API'
import CONSTANTS from '../../utils/constants'

const MediaListSubCategory = ({ parentId }) => {
  const Error = useErrorService()
  const recommendedSwiperRef = useRef(null)
  const [recommendedData, setRecommendedData] = useState([])
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const { isMDScreen, isLGScreen } = useResolutionService()

  useEffect(() => {
    const getRecommendedData = async () => {
      try {
        const { data } = await API.AdminAPI.mediaLibrary.getSubCategories(
          parentId,
        )
        if (data) {
          setRecommendedData([...recommendedData, ...data])
        }
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    getRecommendedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <Row className='mt-4'>
      <Col>
        {!isLoading && (
          <ABSwiperSlider
            arrowProps={{
              hide:
                (isLGScreen && recommendedData.length < 3) ||
                (isMDScreen && recommendedData.length < 2),
              bgColor: '#646464',
              bgOpacity: 1,
            }}
            breakpoints={CONSTANTS.swiperSliderBreakpoints}
            onSwiper={(swiper) => (recommendedSwiperRef.current = swiper)}>
            {recommendedData.map((media) => (
              <div key={media._id}>
                <div className='position-relative h-16 border-radius-10 overflow-hidden'>
                  <div className='position-absolute inset-0 d-flex justify-content-center align-items-center border-radius-10'>
                    <NavLink
                      to={`/admin/media/category/${parentId}/${media._id}`}
                      className='text-dark-600'>
                      <Card className='border-0 border-radius-10'>
                        <Card.Body className='font-montserrat font-medium text-center px-3 py-3'>
                          {media.title}
                        </Card.Body>
                      </Card>
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
            {recommendedData.length === 0 && (
              <div key='no-data-found'>
                <div className='position-relative h-16 border-radius-10 overflow-hidden'>
                  <div className='position-absolute inset-0 d-flex justify-content-center align-items-center border-radius-10'>
                    <Card className='border-0 border-radius-10'>
                      <Card.Body className='font-montserrat font-medium text-center px-3 py-3'>
                        No record found.
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </ABSwiperSlider>
        )}
      </Col>
    </Row>
  )
}

export default withTranslation('translation')(MediaListSubCategory)
