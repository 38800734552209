import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Button, plans } from '@abroad/components'
import AddIndividualUserModal from './AddIndividualUserModal'
import UploadCSVModal from './UploadCSVModal'
import { OrgEcoIdsContext } from '../../utils/contexts'

const SOWRosterAndAddUserButtons = ({ orgEcoId, hideUploadRoster }) => {
  const {
    setIsShowAddBtn,
    companyAndEcoInfo,
    setCompanyAndEcoInfo,
    setIsCsvUploaded,
  } = useContext(OrgEcoIdsContext)
  const { companyName } = useParams()

  return (
    <div className='d-flex'>
      <UploadCSVModal
        orgEcoId={orgEcoId}
        buttonClasses='mr-4'
        uploadCSVHandler={() => {
          hideUploadRoster()
          setIsCsvUploaded({ showRosterUI: false, showBtn: true })
          setIsShowAddBtn({ hideBtn: false, showTable: false })
          if (!companyAndEcoInfo?.planCode) {
            setCompanyAndEcoInfo((prev) => ({
              ...prev,
              planCode: plans.coaching.code,
              isCsvUploaded: true,
              usersCount: 1, // add static user count for UI
            }))
          } else {
            setCompanyAndEcoInfo((prev) => ({
              ...prev,
              isCsvUploaded: true,
              usersCount: 1, // add static user count for UI
            }))
          }
        }}
      />
      <Button
        variant='gray'
        size='lg'
        className='line-height-normal mr-4'
        onClick={hideUploadRoster}>
        ADD USER FROM {companyName ? 'ORG' : 'NETWORK'}
      </Button>
      <AddIndividualUserModal
        orgEcoId={orgEcoId}
        uploadCSVHandler={() => {
          setIsCsvUploaded({ showRosterUI: false, showBtn: true })
          if (!companyAndEcoInfo?.usersCount) {
            setCompanyAndEcoInfo((prev) => ({
              ...prev,
              usersCount: 1, // add static user count for UI
            }))
          }
          hideUploadRoster()
          setIsShowAddBtn({ hideBtn: false, showTable: false })
        }}
        isRenderOnUsersTab={false}
      />
    </div>
  )
}

export default SOWRosterAndAddUserButtons
