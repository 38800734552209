import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Table } from 'react-bootstrap'
import { ShimmerTable } from 'react-shimmer-effects'
import { useLocation, Link } from 'react-router-dom'
import { useErrorService } from '../../services'
import { paymentType, coachingPlanStatus, paymentStatus } from '../../helper'
import CardChangeOption from './CardChangeOption'
import PauseOrResumeSubscription from './PauseOrResumeSubscription'
import CancelSubscription from './CancelSubscription'
import { formatDate } from '../../utils'

const SubscriptionTable = ({
  isRenderOnAdminSide = false,
  isTogglePayment,
  showHighlightedPart,
  API,
  userId,
  setIsToggleCardListing = () => {},
  isToggleCardListing = false
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [subscriptionPayments, setSubscriptionPayments] = useState([])
  const location = useLocation()
  const Error = useErrorService()

  useEffect(() => {
    const getUserSubscriptionPayment = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.payment.getUserSubscriptionPayment(userId)
        if (data) {
          setSubscriptionPayments(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserSubscriptionPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTogglePayment])

  const renderPageTitle = () => {
    return (
      <h3 className='s3 payment-title'>
        {isRenderOnAdminSide ? 'Subscription' : 'My Subscription'}
      </h3>
    )
  }

  if (isLoading) {
    return (
      <>
        {renderPageTitle()}
        <ShimmerTable row={2} col={6} />
      </>
    )
  }

  return (
    <section>
      <>
        {renderPageTitle()}
        <Table hover responsive className='custom-table'>
          <thead>
            <tr>
              <th width='20%'>SOW name</th>
              <th width='16%'>Type of payment</th>
              <th width='16%'>Next Charge Amount</th>
              <th width='16%'>Next Charge Date</th>
              <th width='16%'>Status</th>
              <th width='16%'>Action</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionPayments?.length > 0 ? (
              <>
                {subscriptionPayments?.map((subscription, index) => {
                  const noBillingReq =
                    subscription?.paymentStatus ===
                    paymentStatus.NO_BILLING_REQUIRED
                  const showActionBtns =
                    subscription?.paymentCycle ===
                      Object.values(paymentType)[0] &&
                    !noBillingReq &&
                    ([
                      coachingPlanStatus[1].value,
                      coachingPlanStatus[4].value
                    ].includes(subscription?.status) ||
                      (subscription?.status === coachingPlanStatus[3].value &&
                        subscription?.payments?.paid === false))

                  return (
                    <tr
                      key={index}
                      id={subscription?._id}
                      className={classNames({
                        'highlight-row':
                          `#${subscription?._id}` === location?.hash &&
                          showHighlightedPart
                      })}
                    >
                      <td width='14%'>
                        {isRenderOnAdminSide ? (
                          <Link
                            to={`/coach/clients/${userId}/plans/${subscription?._id}`}
                          >
                            {subscription?.planName} with{' '}
                            {subscription?.coachId?.fullName}
                          </Link>
                        ) : (
                          <>
                            {subscription?.planName} with{' '}
                            {subscription?.coachId?.fullName}
                          </>
                        )}
                      </td>
                      <td width='14%' className='text-capitalize'>
                        {subscription?.paymentCycle}
                      </td>
                      <td width='14%'>
                        {noBillingReq
                          ? 'N/A'
                          : subscription?.nextChargeAmount
                          ? `$${subscription?.nextChargeAmount}`
                          : '-'}
                      </td>
                      <td width='14%'>
                        {subscription?.nextChargeDate
                          ? formatDate(subscription?.nextChargeDate)
                          : '-'}
                      </td>
                      <td width='14%'>
                        {
                          coachingPlanStatus.find(
                            (e) => e.value === subscription?.status
                          ).label
                        }
                      </td>
                      <td width='30%'>
                        {showActionBtns ? (
                          <div className='d-flex flex-column'>
                            {!isRenderOnAdminSide && (
                              <span>
                                {/* Option to change card - only if active (client only) */}
                                <CardChangeOption
                                  subscriptionId={
                                    subscription?.stripeSubscriptionId
                                  }
                                  API={API}
                                  setIsToggleCardListing={
                                    setIsToggleCardListing
                                  }
                                  isToggleCardListing={isToggleCardListing}
                                  defaultCardId={subscription?.paymentMethodId}
                                  onSuccess={(newPaymentMethodId) => {
                                    const newPaymentObj =
                                      subscriptionPayments.map((subPayment) =>
                                        subPayment?._id === subscription?._id
                                          ? {
                                              ...subPayment,
                                              paymentMethodId:
                                                newPaymentMethodId
                                            }
                                          : subPayment
                                      )
                                    setSubscriptionPayments(newPaymentObj)
                                  }}
                                  key={subscription?.paymentMethodId}
                                />
                              </span>
                            )}
                            {isRenderOnAdminSide && (
                              <span>
                                {/* Option to pause/resume subscription - only if active
                            (admin only) */}
                                <PauseOrResumeSubscription
                                  sowId={subscription?._id}
                                  newValue={
                                    subscription?.status ===
                                    coachingPlanStatus[4].value
                                      ? coachingPlanStatus[1].value
                                      : coachingPlanStatus[4].value
                                  }
                                  modalText={`Are you sure you want to ${
                                    subscription?.status ===
                                    coachingPlanStatus[4].value
                                      ? 'resume'
                                      : 'pause'
                                  } this subscription?`}
                                  linkText={`${
                                    subscription?.status ===
                                    coachingPlanStatus[4].value
                                      ? 'Resume'
                                      : 'Pause'
                                  } Subscription`}
                                  onSuccess={() => {
                                    const newPaymentObj =
                                      subscriptionPayments.map((subPayment) =>
                                        subPayment?._id === subscription?._id
                                          ? {
                                              ...subPayment,
                                              status:
                                                subscription?.status ===
                                                coachingPlanStatus[4].value
                                                  ? coachingPlanStatus[1].value
                                                  : coachingPlanStatus[4].value
                                            }
                                          : subPayment
                                      )
                                    setSubscriptionPayments(newPaymentObj)
                                  }}
                                  API={API}
                                  userId={userId}
                                />
                              </span>
                            )}
                            <span>
                              {/* Option to cancel a subscription - only if active */}
                              <CancelSubscription
                                sowId={subscription?._id}
                                onSuccess={() => {
                                  const newPaymentObj =
                                    subscriptionPayments.map((subPayment) =>
                                      subPayment?._id === subscription?._id
                                        ? {
                                            ...subPayment,
                                            status: coachingPlanStatus[2].value,
                                            nextChargeDate: null,
                                            nextChargeAmount: null
                                          }
                                        : subPayment
                                    )
                                  setSubscriptionPayments(newPaymentObj)
                                }}
                                API={API}
                              />
                            </span>
                          </div>
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  You don’t have any active subscriptions
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    </section>
  )
}

export default SubscriptionTable
