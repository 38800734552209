import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { Helmet } from 'react-helmet'
import { Trans, withTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useUserService, ABSpinner } from '@abroad/components'
import { isLGScreen } from '../utils/utility'

const NotFound = ({ t }) => {
  const { user } = useUserService()
  const location = useLocation()

  useEffect(() => {
    const from = location?.state?.from
    if (!user) {
      window.location.replace(
        `${process.env.REACT_APP_ALTERNATE_DOMAIN}?next=${
          process.env.REACT_APP_DOMAIN
        }${from ? from : ''}`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if (!user) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{t('Not Found | Abroad')}</title>
        <meta name='title' content={t('Not Found | Abroad')}></meta>
        <meta property='og:title' content={t('Not Found | Abroad')}></meta>
        <meta property='twitter:title' content={t('Not Found | Abroad')}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/404`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/404`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/404`}></meta>
      </Helmet>
      <div className='position-relative h-100 w-100 vh-100 vw-100 not-found'>
        {isLGScreen ? (
          <Imgix
            className='lazyload w-100 h-lg-100'
            imgixParams={{ ar: '16:9', fil: 'crop', auto: 'format' }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/not-found.png`}
          />
        ) : (
          <Imgix
            className='lazyload vh-100 h-md-100 vw-100'
            imgixParams={{ ar: '16:9', fil: 'crop', auto: 'format' }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/not-found-sm.png`}
          />
        )}

        <Container
          fluid
          className='position-absolute inset-0 h-100 p-lg-5 p-md-5 p-2 text-lg-left text-md-left text-center'>
          <Row className='w-100 h-100 m-0'>
            <Col>
              <p className='pb-lg-5 tracking-md-wide'>
                <Trans>404 - PAGE NOT FOUND</Trans>
              </p>
              <h1 className='pb-3 font-weight-bolder'>
                <Trans>Oh No.</Trans>
              </h1>
              <div className='w-lg-35 w-sm-100'>
                <p className='font-lg-24 font-md-24 font-sm-14 font-weight-medium text-break'>
                  <Trans>
                    The page you requested could not be found. Please check your
                    link and you will be soaring again soon.
                  </Trans>
                </p>
              </div>
              <span className='font-lg-24 font-md-24 tracking-lg-wide home-link'>
                <Link
                  to='/admin/media'
                  className='text-saffron-600 hover:text-saffron-700'>
                  <Trans>CLICK HERE TO RETURN TO HOME.</Trans>
                </Link>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withTranslation('translation')(NotFound)
