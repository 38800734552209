import React from 'react'
import { ParentCategoryOfSiteCopy } from '../common'

export const TeamCohesion = () => {
  return (
    <ParentCategoryOfSiteCopy
      parentKey='5b46b176ce416b175ff98e2f'
      activeLink='oe'
    />
  )
}
