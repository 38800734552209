import React, { useRef, useState } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import { Col, Form, Row } from 'react-bootstrap'
import {
  Button as CommonButton,
  useErrorService,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { Button } from '../common'
import API from '../../utils/API'

const SurveyReportButtons = ({
  surveyId,
  setIsLoading,
  clientCoachIds,
  setClientSurveyReport,
  clientSurveyReport,
  coachingPlanExist,
  impactReportIncluded,
  report360Included,
  isCoach,
  isAdmin,
}) => {
  const [isUploadCoachReportLoading, setIsUploadCoachReportLoading] =
    useState(false)
  const history = useHistory()
  const Notification = useNotificationService()
  const { clientId } = useParams()
  const { user } = useUserService()
  const Error = useErrorService()
  const inputRef = useRef(null)

  const getCoachReport = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const impactReportFile = files[0]
      addCoachReport(impactReportFile)
    }
  }

  const addCoachReport = async (coachReportFileName) => {
    let contentType = null

    if (
      coachReportFileName.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      contentType = 'DOCX'
    } else if (coachReportFileName.type === 'application/pdf') {
      contentType = 'PDF'
    } else {
      Notification.showNotification(
        'Only pdf or docx file format is allowed',
        'danger',
      )
      return
    }

    setIsUploadCoachReportLoading(true)
    try {
      const { data } =
        await API.CoachAPI.document.getSignUrlForUploadReportOfUser(
          contentType,
          clientId,
          1,
        )
      if (data) {
        let uploadFile = await axios.put(data.url.url, coachReportFileName)

        if (uploadFile) {
          const { data: reportData } =
            await API.CoachAPI.document.addReportForUser(clientId, 1, {
              fileName: data.fileName,
            })
          if (reportData && 'clientId' in reportData) {
            setIsLoading(true)
            setClientSurveyReport([
              {
                ...reportData,
                submittedAt: reportData.uploadedAt,
                coach: {
                  _id: user?._id,
                  fullName: user?.fullName,
                },
              },
              ...clientSurveyReport,
            ])
            setIsUploadCoachReportLoading(false)
            Notification.showNotification(
              'Coaching Agreement has been uploaded.',
              'success',
            )
            inputRef.current.value = ''
            setIsLoading(false)
          }
        }
      }
    } catch (e) {
      setIsUploadCoachReportLoading(false)
      inputRef.current.value = ''
      Error.showError(e)
    }
  }

  return (
    <Row className='my-4 report-buttons'>
      <Col className='d-flex justify-content-between'>
        <div className='w-75'>
          {clientCoachIds &&
            user?.isCoach &&
            clientCoachIds?.includes(user?._id) && (
              <Button
                text='Log Impact report'
                buttonClasses='mr-2 mb-2'
                disabled={!impactReportIncluded}
                title={
                  !impactReportIncluded
                    ? 'Impact Report is not included in plan. Please contact administrator for further assistance.'
                    : null
                }
                onClick={() =>
                  impactReportIncluded
                    ? history.push(`/coach/clients/${clientId}/reports/Impact`)
                    : null
                }
              />
            )}
          {clientCoachIds &&
            user?.isCoach &&
            clientCoachIds.includes(user?._id) && (
              <Button
                text='Log 360 report'
                buttonClasses='mr-2 mb-2'
                disabled={!report360Included}
                title={
                  !report360Included
                    ? '360 Report is not included in plan. Please contact administrator for further assistance.'
                    : null
                }
                onClick={() =>
                  report360Included
                    ? history.push(`/coach/clients/${clientId}/reports/360`)
                    : null
                }
              />
            )}
          {clientCoachIds?.length > 0 && (
            <Button
              text='Log Custom report'
              buttonClasses='mr-2 mb-2'
              disabled={!coachingPlanExist}
              title={
                !coachingPlanExist
                  ? 'Custom Report is not included in plan. Please contact administrator for further assistance.'
                  : null
              }
              onClick={() =>
                coachingPlanExist
                  ? history.push(`/coach/clients/${clientId}/reports/custom`)
                  : null
              }
            />
          )}
          {user?.isAdmin && !isCoach && !isAdmin && (
            <>
              <CommonButton
                variant='gray'
                className='text-uppercase mr-2 mb-2 line-height-normal border-radius-3'
                disabled={isUploadCoachReportLoading}
                onClick={() =>
                  clientCoachIds?.length > 0
                    ? inputRef.current.click()
                    : Notification.showNotification(
                        'Please assign a coach to add Upload Coaching Agreement.',
                        'danger',
                      )
                }>
                {isUploadCoachReportLoading
                  ? 'Uploading...'
                  : 'Upload Coaching Agreement'}
              </CommonButton>
              <Form.Control
                ref={inputRef}
                type='file'
                className='d-none'
                accept='.pdf,.docx'
                onChange={getCoachReport}
              />
            </>
          )}
        </div>
        <div className='w-25 text-right'>
          <CommonButton
            variant='gray'
            className='text-uppercase ml-2 line-height-normal border-radius-3'
            onClick={() => {
              if (surveyId.length === 2) {
                window.open(
                  `/user/${clientId}/comparison_report?survey=${surveyId[0]}&survey=${surveyId[1]}`,
                )
              }
            }}
            disabled={surveyId.length !== 2}>
            Compare Assessments
          </CommonButton>
        </div>
      </Col>
    </Row>
  )
}

export default SurveyReportButtons
