import React, { useState } from 'react'
import { Form as RBForm, Row, Col, Container } from 'react-bootstrap'
import sanitizeHtml from 'sanitize-html'
import { Formik, Form } from 'formik'
import { Button, useUserService } from '@abroad/components'
import PageHeader from './PageHeader'
import ShowCharsLimt from './ShowCharsLimt'
import UpsertAttachAudio from './UpsertAttachAudio'
import UpsertAttachKeyResources from './UpsertAttachKeyResources'
import useToSetCharsLimit from './hooks/useCountCharactersLimit'
import CKEditor from '../editor'
import { SingleDatePicker } from '../form'
import { MemberAttendance } from '../team'
import { SelectionField as CommonSelectionField } from '../common'
import Labels from '../../constants/labels'
import { isLGScreen } from '../../utils/utility'
import CONSTANTS from '../../utils/constants'
import { getUniqueAudioAndSkillIds } from '../../utils/utility'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

export const AddClientTeamSession = ({
  validationSchema,
  formInitialValues,
  initialValues,
  onSubmit,
  titleText,
  sessionInsights,
  setSessionInsights,
  nonConfidentialsessionInsights,
  setNonConfidentialSessionInsights,
  postSession,
  setPostSession,
  search,
  setSearch,
  inputFocus,
  setInputFocus,
  searchData,
  isSearch,
  isSessionCreateLoading,
  teamSessionData,
  searchKeySkill,
  setSearchKeySkill,
  searchKeySkillData,
  isSearchKeySkill,
  inputFocusOfKeySkill,
  setInputFocusOfKeySkill,
  coachingPlans = [],
  isRenderOnGroupSession = false,
  groupMembers = [],
}) => {
  const { user } = useUserService()
  let values = [{}]
  const [audios, setAudios] = useState({ values })
  const [keySkills, setKeySkills] = useState({ values })
  const {
    totalChars: totalCharsInSessionNonConf,
    updateCharsLength: updateCharsLengthForSessionNonConf,
  } = useToSetCharsLimit()

  const {
    totalChars: totalCharsInSessionConf,
    updateCharsLength: updateCharsLengthForSessionConf,
  } = useToSetCharsLimit()

  const {
    totalChars: totalCharsInPSP,
    updateCharsLength: updateCharsLengthForPSP,
  } = useToSetCharsLimit()

  const onFormSubmit = (formElement) => {
    const wrapper = getUniqueAudioAndSkillIds(audios, keySkills)
    onSubmit({ ...formElement, ...wrapper })
  }

  const getSessionDurationOptions = (planId) => {
    if (!coachingPlans || !coachingPlans.length || !planId) {
      return Labels?.sessionDuration
    }
    const fixedSessionDuration = coachingPlans.find(
      (e) => e.value === planId,
    )?.sessionDuration
    if (fixedSessionDuration) {
      return [
        {
          key: fixedSessionDuration,
          value: `${fixedSessionDuration} Minutes`,
        },
      ]
    } else {
      return Labels?.sessionDuration
    }
  }

  return (
    <Formik
      displayName='session-form'
      validationSchema={validationSchema}
      initialValues={teamSessionData ? initialValues : formInitialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={(e) => onFormSubmit(e)}>
      {({ values, handleSubmit }) => {
        return (
          <>
            <main className='py-3'>
              <PageHeader titleText={titleText} colClasses='px-lg-0' />
              <Container
                className='pt-5 custom-offset-lg-1 custom-col-lg-12 px-lg-0'
                fluid>
                <RBForm onSubmit={handleSubmit} as={Form} className='profile'>
                  {/* TODO-MULTIPLE-PLANS-DONE */}
                  {!teamSessionData && (
                    <CommonSelectionField
                      label='Plan'
                      labelProps={labelProps}
                      name='planId'
                      size='md'
                      options={coachingPlans}
                      getValueAsOption={true}
                      disabled={coachingPlans.length === 1}
                    />
                  )}
                  <SingleDatePicker
                    label='Session Date'
                    labelProps={labelProps}
                    name='sessionDate'
                    className='pointer-cursor user-input form-control-md bg-white'
                    sm='10'
                  />
                  <div className='mt-3'></div>
                  <CommonSelectionField
                    label='SESSION DURATION'
                    labelProps={labelProps}
                    name='sessionDuration'
                    optionplaceholder='Select one'
                    size='md'
                    options={getSessionDurationOptions(values?.planId)}
                    getKeyAsOption={true}
                  />
                  {teamSessionData && (
                    <CommonSelectionField
                      label='COACH'
                      labelProps={labelProps}
                      name='coach'
                      optionplaceholder='Select one'
                      size='md'
                      options={
                        teamSessionData.length !== 0 && user.isAdmin
                          ? teamSessionData?.coaches?.map(
                              (data) => data.fullName,
                            )
                          : [user?.fullName] || []
                      }
                      disabled={user.isCoach && !user.isAdmin}
                    />
                  )}
                  {isRenderOnGroupSession && (
                    <MemberAttendance groupMembers={groupMembers} />
                  )}
                  <Row>
                    <Col
                      lg={2}
                      md={12}
                      sm={12}
                      className='d-lg-flex align-items-lg-center justify-content-lg-end pr-lg-1'>
                      <RBForm.Label>
                        SESSION INSIGHTS (Confidential)
                      </RBForm.Label>
                    </Col>
                    <Col lg={10} md={12} sm={12}>
                      <CKEditor
                        data={sessionInsights}
                        onChange={(event, editor) => {
                          updateCharsLengthForSessionConf(
                            sanitizeHtml(editor.getData(), {
                              allowedTags: [],
                              allowedAttributes: {},
                            })?.length,
                          )
                          setSessionInsights(editor.getData())
                        }}
                        placeholder='Session Insights are required. They are confidential to you as the coach and will not be seen by Abroad or the client.'
                      />
                    </Col>
                  </Row>
                  <ShowCharsLimt
                    limit={CONSTANTS.charsLimitInSessionConf}
                    enteredChars={totalCharsInSessionConf}
                  />

                  <Row>
                    <Col
                      lg={2}
                      md={12}
                      sm={12}
                      className='d-lg-flex align-items-lg-center justify-content-lg-end pr-lg-1'>
                      <RBForm.Label>
                        SESSION INSIGHTS (Non-Confidential)
                      </RBForm.Label>
                    </Col>
                    <Col lg={10} md={12} sm={12}>
                      <CKEditor
                        data={nonConfidentialsessionInsights}
                        onChange={(event, editor) => {
                          updateCharsLengthForSessionNonConf(
                            sanitizeHtml(editor.getData(), {
                              allowedTags: [],
                              allowedAttributes: {},
                            })?.length,
                          )
                          setNonConfidentialSessionInsights(editor.getData())
                        }}
                        placeholder='Share Session Insights with the Abroad Coaching Group. Non-Confidential Insights may be specific to this session or the overall engagement.'
                      />
                    </Col>
                  </Row>
                  <ShowCharsLimt
                    limit={CONSTANTS.charsLimitInSessionNonConf}
                    enteredChars={totalCharsInSessionNonConf}
                  />
                  <Row>
                    <Col
                      lg={2}
                      md={12}
                      sm={12}
                      className='d-lg-flex align-items-lg-center justify-content-lg-end pr-lg-1'>
                      <RBForm.Label>POST-SESSION PRACTICES</RBForm.Label>
                    </Col>
                    <Col lg={10} md={12} sm={12}>
                      <CKEditor
                        data={postSession}
                        onChange={(event, editor) => {
                          updateCharsLengthForPSP(
                            sanitizeHtml(editor.getData(), {
                              allowedTags: [],
                              allowedAttributes: {},
                            })?.length,
                          )
                          setPostSession(editor.getData())
                        }}
                        placeholder='Post-Session Practices are recommended. Once you save the session, the client(s) will be notified via email to log into the Abroad Platform to view Post-Session Practices.'
                      />
                    </Col>
                  </Row>
                  <ShowCharsLimt
                    limit={CONSTANTS.charsLimitInPSP}
                    enteredChars={totalCharsInPSP}
                  />
                  <Row>
                    <Col
                      lg={2}
                      md={12}
                      sm={12}
                      className='d-lg-flex justify-content-lg-end align-items-lg-center p-lg-0'>
                      <RBForm.Label className='form-label'>
                        ATTACH AUDIO
                      </RBForm.Label>
                    </Col>
                    <Col>
                      <UpsertAttachAudio
                        search={search}
                        setSearch={setSearch}
                        searchData={searchData}
                        isSearch={isSearch}
                        inputFocus={inputFocus}
                        setInputFocus={setInputFocus}
                        audios={audios}
                        setAudios={setAudios}
                        maxLimit={CONSTANTS.maxSessionAudioLimit}
                      />
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col
                      lg={2}
                      md={12}
                      sm={12}
                      className='d-lg-flex justify-content-lg-end align-items-lg-center p-lg-0'>
                      <RBForm.Label className='form-label text-uppercase'>
                        Attach Key Resources
                      </RBForm.Label>
                    </Col>
                    <Col>
                      <UpsertAttachKeyResources
                        searchKeySkill={searchKeySkill}
                        setSearchKeySkill={setSearchKeySkill}
                        searchKeySkillData={searchKeySkillData}
                        isSearchKeySkill={isSearchKeySkill}
                        inputFocusOfKeySkill={inputFocusOfKeySkill}
                        setInputFocusOfKeySkill={setInputFocusOfKeySkill}
                        keySkills={keySkills}
                        setKeySkills={setKeySkills}
                        maxLimit={CONSTANTS.maxLimitForKeySkill}
                      />
                    </Col>
                  </Row>
                  <div className='text-right mt-3'>
                    <Button
                      isLoading={isSessionCreateLoading}
                      disabled={
                        isSessionCreateLoading ||
                        totalCharsInSessionNonConf >
                          CONSTANTS.charsLimitInSessionNonConf ||
                        totalCharsInSessionConf >
                          CONSTANTS.charsLimitInSessionConf ||
                        totalCharsInPSP > CONSTANTS.charsLimitInPSP
                      }
                      type='submit'
                      size='md'
                      className='line-height-normal border-radius-3 text-uppercase'
                      variant='gray'>
                      SAVE
                    </Button>
                  </div>
                </RBForm>
              </Container>
            </main>
          </>
        )
      }}
    </Formik>
  )
}
