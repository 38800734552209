import React from 'react'
import { Form } from 'react-bootstrap'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, _id, classes, disabled, title, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Form.Check
          ref={resolvedRef}
          custom={true}
          id={`custom-${_id}`}
          className={classes}
          type='checkbox'
          size='sm'
          title=''
          disabled={disabled}
          {...rest}
        />
      </>
    )
  },
)

export default IndeterminateCheckbox
