import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useRouteMatch, useParams } from 'react-router-dom'
import {
  PendingPaymentTable,
  SubscriptionTable,
  PaymentHistoryTable,
} from '@abroad/components'
import API from '../utils/API'

const UserPlansAndPayments = () => {
  const [isTogglePayment, setIsTogglePayment] = useState(true)
  const [showHighlightedPart, setShowHighlightedPart] = useState(false)
  const { url } = useRouteMatch()
  const { clientId } = useParams()
  const renderPageHelmet = () => {
    return (
      <Helmet>
        <title>{'Plans And Payment | Abroad'}</title>
        <meta name='title' content={'Plans And Payment | Abroad'}></meta>
        <meta property='og:title' content={'Plans And Payment | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Plans And Payment | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/${url}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/${url}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/${url}`}></meta>
      </Helmet>
    )
  }

  return (
    <>
      {renderPageHelmet()}
      <main>
        <PendingPaymentTable
          isTogglePayment={isTogglePayment}
          setIsTogglePayment={setIsTogglePayment}
          showHighlightedPart={showHighlightedPart}
          isHidePayButton={true}
          API={API.ClientAPI}
          userId={clientId}
        />
        <SubscriptionTable
          isTogglePayment={isTogglePayment}
          showHighlightedPart={showHighlightedPart}
          API={API.ClientAPI}
          userId={clientId}
          isRenderOnAdminSide={true}
        />
        <PaymentHistoryTable
          setShowHighlightedPart={setShowHighlightedPart}
          API={API.ClientAPI}
          userId={clientId}
        />
      </main>
    </>
  )
}

export default UserPlansAndPayments
