import React, { useState, useRef, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { withTranslation } from 'react-i18next'
import {
  ABSpinner,
  useErrorService,
  useResolutionService,
} from '@abroad/components'
import { ABSwiperSlider } from '../slider'
import API from '../../utils/API'
import CONSTANTS from '../../utils/constants'

const ProgramSubCategory = ({ id }) => {
  const Error = useErrorService()
  const recommendedSwiperRef = useRef(null)
  const [recommendedData, setRecommendedData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const { isMDScreen, isLGScreen } = useResolutionService()

  useEffect(() => {
    const getRecommendedData = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.ClientAPI.programs.getPrograms(id)
        if (data) {
          setRecommendedData([...recommendedData, ...data])
        }
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    getRecommendedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <Row className='mt-4'>
      <Col>
        {!isLoading && (
          <ABSwiperSlider
            arrowProps={{
              hide:
                (isLGScreen && recommendedData.length < 3) ||
                (isMDScreen && recommendedData.length < 2),
              bgColor: '#646464',
              bgOpacity: 1,
            }}
            breakpoints={CONSTANTS.swiperSliderBreakpoints}
            onSwiper={(swiper) => (recommendedSwiperRef.current = swiper)}>
            {recommendedData.map((module) => (
              <div key={module._id}>
                <NavLink
                  to={{
                    pathname: `/admin/media/program/${id}/${module._id}`,
                    state: { parentId: 'Program' },
                  }}>
                  <div className='position-relative h-80 border-radius-10 overflow-hidden'>
                    <Imgix
                      className='border-radius-10 img-cover'
                      imgixParams={{
                        fit: 'crop',
                        crop: 'edges',
                        w: 400,
                        h: 400,
                      }}
                      src={`${process.env.REACT_APP_IMG_SOURCE}/${module.imageFilename}`}
                      height={320}
                      width='100%'
                      alt='image'
                    />
                    <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10'>
                      <Card className='border-0 bg-black-100 bg-opacity-75 border-radius-10'>
                        <Card.Body className='text-white font-montserrat font-medium text-center px-3 py-3'>
                          {module.title}
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </NavLink>
              </div>
            ))}
          </ABSwiperSlider>
        )}
      </Col>
    </Row>
  )
}

export default withTranslation('translation')(ProgramSubCategory)
