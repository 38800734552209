import React from 'react'
import classNames from 'classnames'
import { AngleUpArrow } from '../assetsComponents'

/**
 *  Table header cell
 *
 * @param {*} column                // react-table column props
 */
const TableHeaderCell = ({ column }) => {
  return (
    <th
      {...(column?.width && { width: column?.width })}
      {...column.getHeaderProps(column.getSortByToggleProps())}
      // onClick={() => {
      //   // if (column.isSortColumn) {
      //   //   column.toggleSortBy(!column.isSortedDesc)
      //   // }
      // }}
      className={`${column?.headerProps?.className}`}
      title=''>
      <div className={`d-flex ${column?.headerProps?.wrapperClassName}`}>
        <span>{column.render('Header')}</span>
        <span className='ml-2 inline-flex'>
          {column.canSort ? (
            <AngleUpArrow
              className={classNames(
                'vertical-align-initial',
                { 'rotate-original-position': column.isSortedDesc },
                { 'rotate-180': !column.isSortedDesc },
              )}
            />
          ) : null}
        </span>
      </div>
    </th>
  )
}

export default TableHeaderCell
