import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FieldArray, Formik } from 'formik'
import { addMethod, array, object, string } from 'yup'
import { Button, FeatherLoader, useErrorService } from '@abroad/components'
import { StaffCoachIcon } from '../assetsComponents'
import CurrencyInput from '../enterpriseSOW/CurrencyInput'
import { currencyUnmask } from '../enterpriseSOW/MonthlyPaymentTable'
import API from '../../utils/API'

const isValidCoachPayment = () => {
  return string().test(
    'isValidCoachPayment',
    'Please enter valid coach payment rate!',
    function (value) {
      if (value === null || value === '' || value === undefined) return false
      const unmaskMonthlyFee = currencyUnmask(value)
      return unmaskMonthlyFee >= 0 && unmaskMonthlyFee <= 1000
    },
  )
}
addMethod(string, 'isValidCoachPayment', isValidCoachPayment)

const validationSchema = object().shape({
  coachPricing: array().of(
    object().shape({
      coachId: string().required(),
      coachName: string().required(),
      pricing: string().when('isStaffCoach', {
        is: true,
        then: string().notRequired(),
        otherwise: string().isValidCoachPayment(),
      }),
    }),
  ),
})

const CustomCoachingRateModal = ({
  showModal,
  setShowModal,
  setCoachRateData = () => {},
  newSelectedCoaches = [],
  coachId = null,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [initialValues, setInitialValues] = useState({})
  const { sowGroupId } = useParams()
  const Error = useErrorService()

  useEffect(() => {
    const getGroupCoachInfo = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.companyList.getGroupCoachInfo(
          sowGroupId,
          coachId && { coachId },
        )
        if (data) {
          const getPricing = (_id) => {
            const coachPricing = data?.coachesPricing?.find(
              (coach) => coach?.coachId?._id === _id,
            )
            return Number.isInteger(coachPricing?.pricing)
              ? coachPricing?.pricing
              : ''
          }
          const newData = data?.coaches?.map((coach) => ({
            coachId: coach?._id,
            coachName: coach?.fullName,
            pricing: getPricing(coach?._id),
            isStaffCoach: coach?.isStaffCoach || false,
          }))
          setInitialValues({
            coachPricing: newSelectedCoaches
              ? [...newData, ...newSelectedCoaches]
              : newData,
          })
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (showModal) getGroupCoachInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const onHide = () => {
    setShowModal(false)
  }

  const getPopupSubTitle = () => {
    if (coachId) {
      return null
    }
    return 'This group contains users from multiple participant levels. coach current group rate is no longer valid. Please set a new rate.'
  }

  const onSubmit = async (values) => {
    const paymentData = values?.coachPricing?.map((pricing) => ({
      coachId: pricing.coachId,
      pricing: pricing?.isStaffCoach ? null : currencyUnmask(pricing.pricing),
    }))
    setCoachRateData(paymentData)
    onHide()
  }

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      backdrop='static'
      keyboard={false}
      scrollable={true}
      className='custom-modal custom-coaching-rate custom__modal'
      centered>
      <Modal.Body>
        <section>
          <h3 className='s2 mb-point-75'>
            {coachId ? 'Edit Custom Coaching Rate' : 'Custom Coaching Rate'}
          </h3>
          {isLoading ? (
            <div className='text-center my-4'>
              <FeatherLoader />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}>
              {({ handleSubmit, values }) => (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(e)
                  }}>
                  <div>
                    <p className='s6'>{getPopupSubTitle()}</p>
                    <FieldArray
                      name='coachPricing'
                      render={(arrayHelpers) => (
                        <React.Fragment>
                          {values?.coachPricing?.map((pricing, index) => (
                            <Row className='mb-3 coach-rates'>
                              <Col className='col-6 s6 d-flex align-items-center word-break'>
                                {pricing?.coachName}
                                {pricing?.isStaffCoach ? (
                                  <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                                ) : null}
                              </Col>
                              <Col className='col-6 s6 d-flex align-items-center'>
                                <CurrencyInput
                                  name={`coachPricing.${index}.pricing`}
                                  inputClasses='form-control-md'
                                  disabled={pricing.isStaffCoach}
                                />
                              </Col>
                            </Row>
                          ))}
                        </React.Fragment>
                      )}
                    />
                    <div className='text-right'>
                      <Row className='mb-4 coach-rates'>
                        <Col className='col-6' />
                        <Col className='col-6'>
                          <Button
                            variant='saffron'
                            size='md'
                            className='border-radius-3 line-height-normal'
                            disabled={isLoading}
                            type='submit'>
                            Finish
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default CustomCoachingRateModal
