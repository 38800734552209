import React, { useRef } from 'react'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { Form } from 'react-bootstrap'
import { useNotificationService, CrossIcon } from '@abroad/components'
import InviteLogo from './InviteLogo'

const UploadLogo = ({ orgEcoId, logo, setLogo, disabled }) => {
  const inputRef = useRef(null)
  const { setFieldValue } = useFormikContext()
  const Notification = useNotificationService()

  const renderUploadIcon = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='40'
        height='40'
        viewBox='0 0 40 40'
        className={classNames('upload-logo align-self-center', {
          'cursor-pointer': !disabled,
        })}
        onClick={() => {
          if (disabled) return
          inputRef.current.click()
        }}
        fill='none'>
        <rect width='40' height='40' rx='5' fill='#D9D9D9' />
        <g clipPath='url(#clip0_0_55)'>
          <path
            d='M21.0265 26.4765V16.3347L22.8265 18.1347C22.9275 18.2357 23.0622 18.2878 23.1969 18.2878C23.3316 18.2878 23.4663 18.2357 23.5673 18.1347C23.7724 17.9296 23.7724 17.5959 23.5673 17.3908L20.8704 14.6939C20.6745 14.498 20.3255 14.498 20.1265 14.6939L17.4296 17.3908C17.2245 17.5959 17.2245 17.9296 17.4296 18.1347C17.6347 18.3398 17.9684 18.3398 18.1735 18.1347L19.9735 16.3347V26.4735C19.9735 26.7643 20.2092 27 20.4969 27C20.7939 27 21.0265 26.7673 21.0265 26.4765Z'
            fill='black'
          />
          <path
            d='M13.8572 12.5235V15.5755C13.8572 15.8663 14.0929 16.102 14.3806 16.102C14.6715 16.102 14.9072 15.8663 14.9072 15.5755V13.05H26.096V15.5786C26.096 15.8694 26.3317 16.1051 26.6194 16.1051C26.9072 16.1051 27.1429 15.8694 27.1429 15.5786V12.5235C27.1429 12.2327 26.9072 12 26.6194 12H14.3837C14.0929 12 13.8572 12.2327 13.8572 12.5235Z'
            fill='black'
          />
        </g>
        <defs>
          <clipPath id='clip0_0_55'>
            <rect
              width='15'
              height='15'
              fill='white'
              transform='translate(13 12)'
            />
          </clipPath>
        </defs>
      </svg>
    )
  }

  const getLogoFileName = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const imageFile = files[0]
      let img = new Image()
      img.src = window.URL.createObjectURL(imageFile)
      img.onload = () => {
        // Min height & width - 256
        // Max height & width - 1024
        if (
          img.width >= 256 &&
          img.width <= 1024 &&
          img.height >= 256 &&
          img.height <= 1024
        ) {
          setLogo((prev) => ({
            ...prev,
            isLogoUploaded: false,
            logoDetails: imageFile,
            showLogo: false,
            newFileName: imageFile.name,
          }))
          setFieldValue('logoFileName', imageFile.name)
        } else {
          Notification.showNotification(
            'Height and Width should be between 256px to 1024px.',
            'danger',
          )
          inputRef.current.value = ''
        }
      }
    }
  }

  return (
    <div className='mt-4 d-flex'>
      {renderUploadIcon()}
      <p className='mb-0 s6 align-self-center mx-3'>Upload Logo</p>
      <Form.Control
        ref={inputRef}
        name='logoFileName'
        type='file'
        className='d-none'
        accept='.jpg, .jpeg, .png, .svg'
        onChange={getLogoFileName}
      />
      {logo.isLogoUploaded && <InviteLogo orgEcoId={orgEcoId} logo={logo} />}
      {logo.logoDetails && (
        <React.Fragment>
          <p className='mb-0 s6 align-self-center ml-3 text-saffron-700'>
            ({logo?.newFileName}) &nbsp;
            <CrossIcon
              onClick={() => {
                setLogo((prev) => ({
                  ...prev,
                  isLogoUploaded: prev.fileName ? true : false,
                  logoDetails: null,
                  showLogo: prev.fileName ? true : false,
                  newFileName: null,
                }))
                setFieldValue('logoFileName', null)
                inputRef.current.value = ''
              }}
            />
          </p>
        </React.Fragment>
      )}
    </div>
  )
}

export default UploadLogo
