import React from 'react'
import classnames from 'classnames'
import { Button } from '@abroad/components'

const TeamAssessmentActionButton = ({
  compareBtnProps,
  className = '',
  text = 'Compare Assessments',
}) => {
  return (
    <div className={classnames('mt-3 float-left', className)}>
      <div>
        <Button
          onClick={compareBtnProps?.onClick}
          variant='gray'
          size='md'
          className='line-height-normal border-radius-3 text-uppercase'
          {...compareBtnProps}>
          {text}
        </Button>
      </div>
    </div>
  )
}

export default TeamAssessmentActionButton
