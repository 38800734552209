import React from 'react'
import { useFormikContext } from 'formik'
import InviteLinkColorPicker from './InviteLinkColorPicker'
import { Checkbox } from '../form'

const SelectButtonFontColor = ({ disabled }) => {
  const { values, setFieldValue, handleChange } = useFormikContext()

  return (
    <section className='font-color-section'>
      <h3 className='s5'>Select Button & Font Color</h3>
      <div className='d-flex'>
        <InviteLinkColorPicker disabled={disabled} />
        <div className='font-color d-flex font-color-checkboxes'>
          <Checkbox
            name='fontBlackColorHex'
            className='ml-12px'
            type='checkbox'
            label='black'
            checked={values?.fontBlackColorHex}
            onChange={(e) => {
              setFieldValue(
                'fontWhiteColorHex',
                e.target.checked ? false : true,
              )
              handleChange(e)
            }}
            labelProps={{
              className: `${
                disabled ? '' : 'cursor-pointer'
              } font-open-sans text-gray-501`,
            }}
            formGroupProps={{
              className: 'mb-0',
            }}
            disabled={disabled}
          />
          <Checkbox
            name='fontWhiteColorHex'
            className='ml-2'
            type='checkbox'
            label='white'
            checked={values?.fontWhiteColorHex}
            onChange={(e) => {
              setFieldValue(
                'fontBlackColorHex',
                e.target.checked ? false : true,
              )
              handleChange(e)
            }}
            labelProps={{
              className: `${
                disabled ? '' : 'cursor-pointer'
              } font-open-sans text-gray-501`,
            }}
            formGroupProps={{
              className: 'mb-0',
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </section>
  )
}

export default SelectButtonFontColor
