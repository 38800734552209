import React, { useEffect, useRef, useState } from 'react'
import ColorPicker from 'react-best-gradient-color-picker'
import { useFormikContext } from 'formik'

const InviteLinkColorPicker = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext()
  const [color, setColor] = useState(values?.buttonColorHex)
  const [show, setShow] = useState(false)
  const pickerRef = useRef(null)

  useEffect(() => {
    //  fire event if clicked on outside of element
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShow(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, pickerRef])

  const renderColorIcon = (color) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={disabled ? '' : 'cursor-pointer'}
        onClick={() => {
          if (disabled) return
          setShow(true)
        }}>
        <rect width='24' height='24' rx='5' fill={color} />
      </svg>
    )
  }

  return (
    <div className='color-picker d-flex'>
      {renderColorIcon(color)}
      {show && (
        <div ref={pickerRef}>
          <ColorPicker
            value={color}
            onChange={(e) => {
              setColor(e)
              setFieldValue('buttonColorHex', e)
            }}
            className='position-absolute z20 bg-white top-25-pr color-palette-wrapper'
            hideControls
            hidePresets
            hideEyeDrop
            hideAdvancedSliders
            hideColorGuide
          />
        </div>
      )}
      <p className='s7 ml-12px mb-0 align-self-center'>{color}</p>
    </div>
  )
}

export default InviteLinkColorPicker
