import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Form, Modal, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import UploadProgressBar from './UploadProgressBar'
import { OrgEcoIdsContext } from '../../utils/contexts'

const uploadingCSVMessage = 'CSV Uploading'
const uploadCSVErrorMessage = 'CSV Upload Error!'
const notInviteOnlyErrorCodesArray = [
  'org_not_invite_only',
  'network_not_invite_only',
]
const initialState = { upload: false, message: '' }

const UploadCSVModal = ({
  orgEcoId,
  buttonClasses = '',
  buttonVariant = 'gray',
  uploadCSVHandler,
  setIsAnyModalEventOccurs = () => {},
}) => {
  const [title, setTitle] = useState(uploadingCSVMessage)
  const [errorRowCol, setErrorRowCol] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [csvSuccessfulMessage, setCSVSuccessfulMessage] = useState(null)
  const Notification = useNotificationService()
  const Error = useErrorService()
  const { companyAndEcoInfo } = useContext(OrgEcoIdsContext)
  const inputRef = useRef()
  const { companyName, sowId } = useParams()
  const [maxLimitCSVUploaded, setMaxLimitCSVUploaded] = useState(initialState)
  const csvFilesArray = ['csv', 'CSV']
  const onHide = () => {
    if (csvSuccessfulMessage) uploadCSVHandler()
    inputRef.current.value = ''
    setShowModal(false)
  }

  useEffect(() => {
    if (showModal) {
      setIsAnyModalEventOccurs(true)
    } else {
      setIsAnyModalEventOccurs(false)
      setCSVSuccessfulMessage(null)
      setTimeout(() => {
        setErrorRowCol(null)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const getRosterCSV = async (e) => {
    const csvFile = e.target.files[0]
    const type = companyName ? 'company' : 'affiliate'
    const isCSVuploaded = csvFilesArray.includes(csvFile.name.split('.').pop())

    setTitle(uploadingCSVMessage)
    setShowModal(true)

    if (!isCSVuploaded) {
      Notification.showNotification('Only CSV file allow!', 'danger')
      onHide()
      return
    }

    const formdata = new FormData()
    formdata.append('bulkUserCsv', csvFile)

    const config = {
      method: 'post',
      url: `${
        process.env.REACT_APP_API_ENDPOINT
      }/${type}/${orgEcoId}/upload-roster-csv${sowId ? `?sowId=${sowId}` : ''}`,
      headers: {
        'Content-Type': 'Multipart/form-data',
      },
      withCredentials: true,
      data: formdata,
    }

    try {
      const response = await axios(config)
      if (response) {
        setCSVSuccessfulMessage(response?.data?.message)
        setTitle(response?.data?.message)
      }
    } catch (error) {
      setCSVSuccessfulMessage(null)
      setTitle(uploadCSVErrorMessage)
      const { response } = error
      const { request, ...errorObject } = response // take everything but 'request'
      if (notInviteOnlyErrorCodesArray.includes(errorObject.data.code)) {
        Error.showError(errorObject.data)
        setErrorRowCol(null)
      } else if (errorObject.data.code === 'csv_max_limit') {
        Error.showError(errorObject.data)
        setMaxLimitCSVUploaded({
          upload: true,
          message: errorObject.data.message,
        })
        setErrorRowCol(null)
      } else {
        const errors = errorObject.data.errors.sort((a, b) => a.row - b.row)
        setErrorRowCol(errors)
      }
    }
  }

  const renderCloseButton = (wrapperClasses = '', btnClasses = '') => {
    return (
      <div className={wrapperClasses}>
        <Button
          variant='none'
          size='lg'
          className={classNames('secondary-popup-button', btnClasses, {
            'w-5/12': !errorRowCol,
            'w-3/12': errorRowCol,
          })}
          onClick={onHide}>
          CLOSE
        </Button>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Button
        variant={buttonVariant}
        size='lg'
        className={classNames('line-height-normal', buttonClasses)}
        onClick={() => {
          if (!companyAndEcoInfo?.planCode) {
            Notification.showNotification(
              'Please set a plan before uploading csv file.',
              'danger',
            )
            return
          }
          inputRef.current.click()
        }}>
        UPLOAD ROSTER CSV
      </Button>
      <Form.Control
        ref={inputRef}
        type='file'
        className='d-none'
        accept='.csv'
        onChange={getRosterCSV}
      />
      <Modal
        show={showModal}
        size='lg'
        onHide={onHide}
        className={classNames('custom-modal', {
          custom__modal: errorRowCol,
        })}
        scrollable={true}
        centered>
        <Modal.Body>
          <h3
            className={classNames('s2 title', {
              'text-center': title !== uploadCSVErrorMessage,
            })}>
            {title}
          </h3>
          {title === uploadingCSVMessage && (
            <div className='mt-2-5'>
              {/* create dummy progress bar */}
              <UploadProgressBar />
            </div>
          )}
          {csvSuccessfulMessage && (
            <div className='upload-csv'>
              {renderCloseButton('text-center', 'line-height-normal')}
            </div>
          )}
          {title === uploadCSVErrorMessage && (
            <div className='mt-point-75'>
              <p className='s6 csv-error'>
                {errorRowCol
                  ? `CSV failed to upload due to errors. Please fix inconsistencies and try again.`
                  : !companyAndEcoInfo?.planCode
                  ? `This ${
                      companyName ? 'organization' : 'network'
                    } is not invite only.`
                  : maxLimitCSVUploaded.upload
                  ? maxLimitCSVUploaded.message
                  : 'Internal server error'}
              </p>
              {errorRowCol && (
                <React.Fragment>
                  <Table hover responsive className='custom-table csv-error'>
                    <thead>
                      <tr className='text-uppercase'>
                        <th width='25%'>Row</th>
                        <th width='25%'>Column</th>
                        <th width='50%'>Error</th>
                      </tr>
                    </thead>
                    <tbody>
                      {errorRowCol.map((error, index) => (
                        <tr key={index}>
                          <td className='word-break' width='25%'>
                            {error?.row}
                          </td>
                          <td className='word-break' width='25%'>
                            {error?.column}
                          </td>
                          <td className='word-break' width='50%'>
                            {error?.error}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
              {renderCloseButton(
                'text-right',
                'line-height-normal csv-close-btn',
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default UploadCSVModal
