import React from 'react'
import { useParams } from 'react-router-dom'
import CONSTANTS from '../../utils/constants'

const NoUsersOrSOWsText = ({ renderOnSOWPage = false }) => {
  const { companyName } = useParams()

  const renderTemplateLink = (linkText) => {
    return (
      <React.Fragment>
        <a
          href={
            renderOnSOWPage
              ? CONSTANTS.csvTemplateLinkForSOWPage
              : CONSTANTS.csvTemplateLinkForUsersPage
          }
          rel='noopener noreferrer'
          target='_blank'
          className='this-template-link'>
          {renderOnSOWPage ? (
            'this template'
          ) : (
            <span>
              this roster
              <br />
              CSV template
            </span>
          )}
        </a>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderOnSOWPage ? (
        <p className='s5 no-users-text'>
          This SOW has no existing users. If you wish to upload an employee
          roster, you may download
          <br />
          {renderTemplateLink()}, then upload it using the button below.
          Alternatively, you may add users already
          <br />
          in this {companyName ? 'organization' : 'network'}, or entirely new
          users with other buttons below.
        </p>
      ) : (
        <p className='s5 no-users-text'>
          This {companyName ? 'organization' : 'network'} currently has no SOWs
          and no users. It is recommended to first create an SOW then upload new
          <br />
          users directly there. However, if you wish to simply add users to this
          organization, you may download {renderTemplateLink()}, then upload it
          using the button below. Alternatively, you may add users individually
          using the
          <br />
          buttons below, or by accessing the actions panel.
        </p>
      )}
    </React.Fragment>
  )
}

export default NoUsersOrSOWsText
