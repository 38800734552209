import React, { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'
import { Container, Row } from 'react-bootstrap'

const Layout = ({ children }) => {
  const location = useLocation()

  // Scroll to top if path changes
  useLayoutEffect(() => {
    document.getElementById('content').scrollTop = 0
  }, [location.pathname])

  return (
    <Container fluid className='custom-container p-0 vh-100 overflow-hidden'>
      <Row className='position-relative m-0 h-100 w-100 overflow-auto no-gutters'>
        <div className='sidebar-content container-col-lg-2 px-0 content-gutter z2'>
          <Sidebar />
        </div>
        <div className='container-col-lg-2 px-0 content-gutter d-none d-lg-block' />
        <div className='container-col-lg-14 container-col-16 px-0'>
          <div id='content' className='overflow-auto pb-5 body-content'>
            <Header />
            <div className='pt-0 pt-sm-sidebar'>{children}</div>
          </div>
        </div>
      </Row>
    </Container>
  )
}

export default Layout
