import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useErrorService, useResolutionService } from '../../services'
import Button from '../Button'
import SubscriptionTableLink from './SubscriptionTableLink'
import CardListing from './CardListing'

const CardChangeOption = ({
  subscriptionId,
  API,
  setIsToggleCardListing,
  isToggleCardListing,
  defaultCardId = '',
  onSuccess = () => {}
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [cardId, setCardId] = useState(defaultCardId)
  const { isLGScreen } = useResolutionService()
  const Error = useErrorService()
  const handleClose = () => {
    if (isLoading) return
    setShowModal(false)
    setCardId(defaultCardId)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const { status } = await API.payment.changeSubscriptionCard({
        subscriptionId,
        paymentMethodId: cardId
      })
      if (status) {
        onSuccess(cardId)
        handleClose()
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className={isLGScreen ? 'custom__modal' : ''}
      >
        <Modal.Header className='px-4'>
          <Modal.Title className='text-uppercase font-montserrat font-16 font-bold leading-5 letter-spacing-02 s7'>
            Option To Change Card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-4 promo-accordion'>
          <CardListing
            cardId={cardId}
            setCardId={setCardId}
            API={API}
            setIsToggleCardListing={setIsToggleCardListing}
            isToggleCardListing={isToggleCardListing}
            defaultCardId={defaultCardId}
          />
          <div className='text-left'>
            <Button
              variant='saffron'
              size='sm'
              disabled={!cardId || isLoading}
              isLoading={isLoading}
              onClick={onSubmit}
              className='text-uppercase border-radius-normal'
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <SubscriptionTableLink
        onClick={() => setShowModal(true)}
        disabled={isLoading}
        linkText='Change card'
      />
    </>
  )
}

export default CardChangeOption
