import React, { useContext, useEffect, useState } from 'react'
import { Button, useErrorService } from '@abroad/components'
import AssignSOWSection from './AssignSOWSection'
import { AssignCoachModal } from '../common'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const AssignCoachSOWButton = ({ ...props }) => {
  const { ids, setIsLoadNewData } = useContext(OrgEcoIdsContext)
  const [showCoachModal, setShowCoachModal] = useState(false)
  const [usersHasNoCoach, setUsersHasNoCoach] = useState(false)
  const [coachId, setCoachId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()

  useEffect(() => {
    if (!showModal) {
      setCoachId(null)
    }
  }, [showModal])

  useEffect(() => {
    const selectedUsersHasNoCoach = props.userCoachMapping.every(
      (user) => user?.coachId === undefined,
    )
    setUsersHasNoCoach(selectedUsersHasNoCoach)
  }, [ids, props.userCoachMapping])

  const handleAssignCoach = async (coach, coaches) => {
    setIsLoading(true)
    try {
      const { data } = await API.AdminAPI.common.bulkAssignCoachToUser({
        userIds: ids,
        coachId: coach,
      })
      if (data) {
        setCoachId(coach)
        setIsLoading(false)
        setShowCoachModal(false)
        if (usersHasNoCoach) {
          setShowModal(true)
        } else {
          setIsLoadNewData(true)
        }
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <>
      <div className='d-flex'>
        <AssignSOWSection
          {...props}
          showModal={showModal}
          setShowModal={setShowModal}
          usersHasNoCoach={usersHasNoCoach}
          coachId={coachId}
        />
        <Button
          variant='saffron'
          size='md'
          className='line-height-normal assign-btn ml-2'
          style={{ height: '2rem' }}
          onClick={() => setShowCoachModal(true)}
          isLoading={isLoading}
          disabled={ids?.length === 0 || isLoading}>
          Assign Coach
        </Button>
      </div>
      <AssignCoachModal
        handleAssignCoach={handleAssignCoach}
        isSubmitting={isLoading}
        showModal={showCoachModal}
        setShowModal={setShowCoachModal}
      />
    </>
  )
}

export default AssignCoachSOWButton
