import React, { useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'
import API from '../../utils/API'
import { useErrorService, useNotificationService } from '@abroad/components'

const animatedComponents = makeAnimated()

const breakpoints = [480, 768, 1024, 1440]

const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`)

const SelectionFieldForAffiliation = ({
  name,
  options,
  placeholder,
  disabled,
  values,
  userEmail,
  userId,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(values)
  const [isLoading, setIsLoading] = useState(false)
  const [fieldValues, setFieldValues] = useState(values)
  const Error = useErrorService()
  const Notification = useNotificationService()

  const styles = {
    container: (provided) => ({
      ...provided,
      [mq[3]]: {
        width: '100%',
      },
    }),
    control: (provided, state) => ({
      border: state.isFocused ? '1px' : '0px',
      borderColor: state.isFocused ? '#80bdff' : '',
      borderRadius: '5px',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '',
      fontSize: '0.75rem',
      height: options.length > 2 ? 'auto' : '2rem',
    }),
    valueContainer: (provided, state) => {
      let borderColor = '#000'
      let backgroundColor = '#fff'
      if (state.selectProps?.isValid) {
        borderColor = '#509f92'
      }
      if (state.selectProps?.isInvalid) {
        borderColor = '#bc4c6a'
      }
      if (disabled) {
        backgroundColor = '#c2c2c2'
      }
      return {
        ...provided,
        width: '100%',
        height: options.length > 2 ? 'auto' : '2rem',
        backgroundColor: backgroundColor,
        backgroundClip: 'padding-box',
        border: '1px solid',
        borderColor: borderColor,
        borderRadius: '5px',
        outline: 0,
      }
    },
    input: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: '0.45rem',
      fontFamily: 'Montserrat',
      color: '#000',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#efc030' : '',
      ':active': {
        backgroundColor: '#efc030',
      },
      zIndex: '200',
      fontSize: '0.75rem',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      border: '1px solid darkgrey',
      borderRadius: '5px',
    }),
    multiValueRemove: (provided, state) => {
      if (state.isDisabled) {
        return {
          ...provided,
          display: 'none',
        }
      }
      return {
        ...provided,
        borderLeft: '1px solid darkgrey',
        ':hover': {
          backgroundColor: 'transparent',
          color: 'darkgrey',
        },
      }
    },
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  }

  const handleChange = async (newSelectedOption) => {
    const result = window.confirm(
      `Do you want to change the Network of ${userEmail}`,
    )
    if (!result) {
      return
    }
    try {
      setIsLoading(true)
      const values = newSelectedOption
        ? newSelectedOption?.map((data) => data.value)
        : []
      if (newSelectedOption) {
        setSelectedOptions(newSelectedOption)
      } else {
        setSelectedOptions([])
      }
      await API.AdminAPI.userList.updateUserAffiliation(
        {
          affiliation: values,
        },
        userId,
      )
      setFieldValues(newSelectedOption)
      setIsLoading(false)
      Notification.showNotification('User network has been updated!', 'success')
    } catch (e) {
      setSelectedOptions(fieldValues)
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <Select
      name={name}
      value={selectedOptions}
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      placeholder={placeholder}
      styles={styles}
      onChange={handleChange}
      options={options}
      isDisabled={disabled || isLoading}
      menuPosition='fixed'
    />
  )
}

SelectionFieldForAffiliation.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

SelectionFieldForAffiliation.defaultProps = {
  options: [],
  name: '',
  label: '',
}

export default SelectionFieldForAffiliation
