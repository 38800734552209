import React from 'react'
import { ABCard } from '@abroad/components'

const AddNewInsight = () => {
  return (
    <ABCard
      shadow={false}
      onHoverShadow={true}
      className='overflow-hidden cursor-pointer text-saffron-300-5 hover:text-saffron-300 pointer-event-none'>
      <ABCard.Body className='p-0'>
        <div className='text-center py-1 font-montserrat text-uppercase tracking-lg-wide font-light text-white bg-gray-301'>
          ADD NEW INSIGHT
        </div>
        <div className='h-35 d-flex justify-content-center align-items-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='52'
            height='52'
            viewBox='0 0 52 52'
            style={{ opacity: '0.3' }}
            fill='none'>
            <g clip-path='url(#clip0_6102_3524)'>
              <path
                d='M26 0C11.664 0 0 11.663 0 26C0 40.337 11.664 52 26 52C40.336 52 52 40.337 52 26C52 11.663 40.336 0 26 0ZM38.5 28H28V39C28 40.104 27.104 41 26 41C24.896 41 24 40.104 24 39V28H13.5C12.396 28 11.5 27.104 11.5 26C11.5 24.896 12.396 24 13.5 24H24V14C24 12.896 24.896 12 26 12C27.104 12 28 12.896 28 14V24H38.5C39.604 24 40.5 24.896 40.5 26C40.5 27.104 39.604 28 38.5 28Z'
                fill='#EFC030'
              />
            </g>
            <defs>
              <clipPath id='clip0_6102_3524'>
                <rect width='52' height='52' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </div>
      </ABCard.Body>
    </ABCard>
  )
}

export default AddNewInsight
