import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import classNames from 'classnames'
import { Button, useErrorService } from '@abroad/components'
import API from '../../utils/API'

const ExportSessionReport = ({ hasSessionData, startDate, endDate }) => {
  const {
    values: { filterByCoach, filterByClient, filterByTeam, filterByCompany },
  } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const [exportData, setExportData] = useState([])
  const Error = useErrorService()

  const headers = [
    { label: 'Coach Name', key: 'coach' },
    { label: 'Client', key: 'client' },
    { label: 'Organization', key: 'company' },
    { label: 'Group', key: 'team' },
    { label: 'Global Session #', key: 'sequence' },
    { label: 'Session # in this cycle', key: 'sessionInCycle' },
    { label: 'Coaching Cycle #', key: 'coachingMonthNumber' },
    { label: 'Coaching Cycle # Range', key: 'coachingMonthRange' },
    { label: 'Session Date', key: 'date' },
    { label: 'Duration (in min.)', key: 'length' },
    {
      label: 'Session Insights (Non-Confidential)',
      key: 'coachNotesNonConfidential',
    },
  ]

  const handleExportReport = async () => {
    setIsLoading(true)

    try {
      const { data: SessionData } =
        await API.AdminAPI.teams.exportSessionInsightsAsCSV({
          ...(filterByCompany && { companyName: filterByCompany }),
          ...(filterByCoach &&
            Array.isArray(filterByCoach) &&
            filterByCoach?.length > 0 && {
              coachIds: filterByCoach.map(({ value }) => value),
            }),
          ...(filterByTeam &&
            Array.isArray(filterByTeam) &&
            filterByTeam?.length > 0 && {
              teamId: filterByTeam[0].value,
            }),
          ...(filterByClient &&
            Array.isArray(filterByClient) &&
            filterByClient?.length > 0 && {
              clientId: filterByClient[0].value,
            }),
          ...(moment(startDate).format('YYYY-MM-DD') !== 'Invalid date' &&
            moment(startDate)?.format('YYYY-MM-DD') !== null && {
              startDate: moment(startDate).format('YYYY-MM-DD'),
              endDate: moment(endDate).format('YYYY-MM-DD'),
            }),
        })
      if (SessionData) {
        setExportData(SessionData)
        document.getElementById('EXPORT_SESSION_REPORT').click()
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const getReportName = () => {
    let filename = ''
    if (
      moment(startDate).format('YYYY-MM-DD') !== 'Invalid date' &&
      moment(startDate)?.format('YYYY-MM-DD') !== null
    ) {
      filename += `_from_${moment(startDate).format('MM-DD-YYYY')}_to_${moment(
        endDate,
      ).format('MM-DD-YYYY')}`
    }
    return `session_insights_report${filename}.csv`
  }

  return (
    <>
      {hasSessionData &&
        (filterByCoach ||
          filterByCompany ||
          (filterByTeam?.length !== 0 && filterByTeam !== '') ||
          (filterByClient?.length !== 0 && filterByClient !== '') ||
          (moment(startDate).format('YYYY-MM-DD') !== 'Invalid date' &&
            moment(startDate)?.format('YYYY-MM-DD') !== null)) && (
          <>
            <Button
              variant='gray'
              className={classNames('line-height-normal border-radius-3', {
                'd-none': !hasSessionData,
              })}
              isLoading={isLoading}
              disabled={isLoading || !hasSessionData}
              onClick={() => handleExportReport()}>
              EXPORT REPORT
            </Button>
            <CSVLink
              data={exportData}
              className='d-none'
              id='EXPORT_SESSION_REPORT'
              filename={getReportName()}
              headers={headers}>
              EXPORT REPORT
            </CSVLink>
          </>
        )}
    </>
  )
}

export default ExportSessionReport
