import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const GetGroupType = ({ render }) => {
  const { values } = useFormikContext()
  useEffect(() => {
    render(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])
  return null
}

export default GetGroupType
