import React, { useCallback, useContext, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Col, Row } from 'react-bootstrap'
import { showJourneyMediaDescription } from '@abroad/components'
import JourneyAudioInformation from './JourneyAudioInformation'
import { JourneyMediasContext } from '../../utils/contexts'

const MediasWithDnd = ({ dndType, id, media, index, render = () => {} }) => {
  const ref = useRef(null)
  const { mediasList, setMediasList } = useContext(JourneyMediasContext)

  const removeMediaHandler = (mediaId) => {
    const filteredMedias = mediasList.filter((media) => media._id !== mediaId)
    setMediasList(filteredMedias)
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = mediasList[dragIndex]

      const cloneList = [...mediasList]

      const prevItem = cloneList.splice(hoverIndex, 1, dragItem)

      cloneList.splice(dragIndex, 1, prevItem[0])

      setMediasList(cloneList)
    },
    [mediasList, setMediasList],
  )

  const [{ handlerId }, drop] = useDrop({
    accept: dndType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
    drop() {
      // media sequence change
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: dndType,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className='jurneys-audio-list'
      data-handler-id={handlerId}>
      <Row className='mb-lg-3 mb-4'>
        <Col className='journeys-col-third align-self-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='14'
            viewBox='0 0 16 14'
            fill='none'
            className='media-list-cursor-drag'>
            <line
              x1='1'
              y1='1'
              x2='15'
              y2='1'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
            />
            <line
              x1='1'
              y1='7'
              x2='15'
              y2='7'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
            />
            <line
              x1='1'
              y1='13'
              x2='15'
              y2='13'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
            />
          </svg>
        </Col>
        <Col
          className='journeys-col-first d-flex align-items-center pl-0'
          draggable={true}
          onDragStart={(event) => event.preventDefault()}>
          <span className='font-open-sans font-24 font-bold leading-9 letter-spacing-minus-04'>{`${
            index + 1
          }.`}</span>
        </Col>
        <Col
          className='journeys-col-second'
          draggable={true}
          onDragStart={(event) => event.preventDefault()}>
          {media && (
            <JourneyAudioInformation
              title={media.title}
              imageURL={media?.imageFilename}
              key={media._id}
              index={index}
              removeMediaHandler={() => removeMediaHandler(id)}
              moduleDetails={showJourneyMediaDescription(media)}
              source={process.env.REACT_APP_IMG_SOURCE}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default MediasWithDnd
