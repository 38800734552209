import React from 'react'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@abroad/components'

const InviteLinkBtn = ({ classes = '', closeClickedHandler }) => {
  const history = useHistory()
  const { companyName, ecoSystemName } = useParams()
  const type = companyName ? 'company' : 'network'
  return (
    <Button
      variant='saffron'
      size='lg'
      onClick={() => {
        const url = `/admin/${
          companyName
            ? `organization/${companyName}`
            : `network/${ecoSystemName}`
        }/invite-link`
        closeClickedHandler()
        history.push(url)
      }}
      className={classNames(
        'w-100 border-radius-3 line-height-normal',
        classes,
      )}>
      {type === 'company' ? 'ORGANIZATION' : 'NETWORK'} INVITE LINK
    </Button>
  )
}

export default InviteLinkBtn
