import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { InputField } from '../form'
import { formatDate } from '../../utils/date'

const disabledOptionValue = ''

const SelectionField = ({
  options,
  isUseFormikSelectionField = true,
  optionplaceholder,
  isKeyValuePair,
  size = 'lg',
  ...rest
}) => {
  return (
    <>
      {isUseFormikSelectionField ? (
        <InputField
          as='select'
          type='text'
          placeholder={optionplaceholder ? optionplaceholder : 'Select One'}
          size={size}
          defaultValue={rest?.defaultValue || disabledOptionValue}
          {...rest}>
          <option disabled value={disabledOptionValue}>
            {optionplaceholder ? optionplaceholder : 'Select One'}
          </option>
          <SelectionOptions
            options={options}
            isKeyValuePair={isKeyValuePair}
            {...rest}
          />
        </InputField>
      ) : (
        <Form.Control
          as='select'
          type='text'
          placeholder={optionplaceholder ? optionplaceholder : 'Select One'}
          size={size}
          {...rest}>
          <option disabled value={disabledOptionValue}>
            {optionplaceholder ? optionplaceholder : 'Select One'}
          </option>
          <SelectionOptions
            options={options}
            isKeyValuePair={isKeyValuePair}
            {...rest}
          />
        </Form.Control>
      )}
    </>
  )
}

const convertTextToCapitalize = (promo) => {
  const promoText = promo ? promo?.toUpperCase() : promo
  return promoText
}

export const SelectionOptions = ({
  options,
  isKeyValuePair = false,
  keyprop = 'key',
  valueprop = 'value',
  labelProp = 'key',
  customKeyprop,
  customValueprop,
  customLabelProp,
  getCustomKeyValuePropsAsOption = false,
  getKeyAsOption = false,
  getValueAsOption = false,
  promoOptions,
}) => {
  if (promoOptions) {
    return (
      <>
        {options.map((option, index) => (
          <option
            key={`${option}-${index}`}
            value={option.code}
            disabled={option?.isExpired}>
            {`${convertTextToCapitalize(option.code)}${
              option?.extraValue ? option?.extraValue : ''
            }${option?.discount ? ` | ${option?.discount} % Off` : ''}${
              option?.maxAmount ? ' | Max $' + option?.maxAmount + ' ' : ''
            }${
              option?.expTime
                ? ` | Expires on ${formatDate(option?.expTime)}`
                : ''
            }`}
          </option>
        ))}
      </>
    )
  }

  return (
    <React.Fragment>
      {options.map((option, index) => (
        <option
          key={
            isKeyValuePair
              ? `${option[keyprop]}`
              : getKeyAsOption
              ? option.key
              : getCustomKeyValuePropsAsOption
              ? option[customKeyprop]
              : getValueAsOption
              ? option.value
              : `${option}-${index}`
          }
          value={
            isKeyValuePair
              ? [
                  JSON.stringify({
                    key: option[keyprop],
                    value: option[valueprop],
                  }),
                ]
              : getKeyAsOption
              ? option.key
              : getCustomKeyValuePropsAsOption
              ? option[customValueprop]
              : getValueAsOption
              ? option.value
              : option
          }>
          {isKeyValuePair
            ? option[labelProp]
            : getKeyAsOption
            ? option.value
            : getCustomKeyValuePropsAsOption
            ? option[customLabelProp]
            : getValueAsOption
            ? option.label
            : option}
        </option>
      ))}
    </React.Fragment>
  )
}

SelectionField.propsTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default SelectionField
