import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useUserService } from '@abroad/components'

const ViewMemberAttendance = ({ groupMembers }) => {
  const maxMembersAfterScrollAppears = 4
  const { user } = useUserService()

  const renderAttendanceMembers = (key, label) => {
    return (
      <Table hover responsive className='custom-table'>
        <thead>
          <tr className='text-uppercase'>
            <th className='text-center'>{label}</th>
          </tr>
        </thead>
        <tbody>
          {groupMembers?.[key]?.length > 0 && (
            <React.Fragment>
              {groupMembers?.[key]?.map((member, index) => (
                <tr key={index}>
                  <td className='text-center'>
                    {user?.isAdmin ? (
                      <Link
                        to={`/admin/users/${member?._id}`}
                        className='text-dark-600'
                        rel='noopener noreferrer'
                        target='_blank'>
                        {member?.fullName}
                      </Link>
                    ) : (
                      <React.Fragment>{member?.fullName}</React.Fragment>
                    )}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          )}
          {groupMembers?.[key]?.length < maxMembersAfterScrollAppears && [
            ...Array.from(
              {
                length:
                  maxMembersAfterScrollAppears - groupMembers?.[key]?.length,
              },
              () => '-',
            ).map((emptyDash, index) => (
              <tr key={index}>
                <td className='text-center'>{emptyDash}</td>
              </tr>
            )),
          ]}
        </tbody>
      </Table>
    )
  }

  if (!groupMembers) return null

  return (
    <div className='d-flex view-member-attendance'>
      <p className='font-open-sans text-uppercase pr-2 mb-0 align-self-center'>
        Attendance:
      </p>
      <div className='remove-coach-popup'>
        <Row>
          <Col>{renderAttendanceMembers('present', 'Present')}</Col>
          <Col>{renderAttendanceMembers('absent', 'Absent')}</Col>
        </Row>
      </div>
    </div>
  )
}

export default ViewMemberAttendance
