import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Button } from '@abroad/components'

const PausingCurrentSOWModal = ({
  showModal,
  setShowModal,
  handleSubmit,
  isLoading,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(true)
      }}
      size='lg'
      scrollable={true}
      className='sow-modal custom__modal'
      centered>
      <Modal.Body className='pt-point-5 pb-point-5'>
        <Row className='save-sow-table'>
          <Col>
            Are you sure? This will send an email to the coach asking them to
            stop coaching the client until you resume SOW again.
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!isLoading && (
          <Button
            variant='none'
            className='text-uppercase line-height-normal secondary-popup-button'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        )}
        <Button
          variant='saffron'
          className='text-uppercase line-height-normal'
          onClick={() => handleSubmit()}
          isLoading={isLoading}
          disabled={isLoading}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PausingCurrentSOWModal
