import React, { useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Modal } from 'react-bootstrap'
import { Button, useErrorService } from '@abroad/components'
import { useInput } from '../common'
import API from '../../utils/API'
import { SingleDatePickerWithoutFormik } from '../form'

const CreateSOWModal = ({
  showModal,
  setShowModal,
  orgEcoId,
  closeClickedHandler = () => {},
}) => {
  const [showCreateSOWModal, setShowCreateSOWModal] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [sowStartDate, setSOWStartDate] = useState(null)
  const [sowStartDateIsValid, setSOWStartDateIsValid] = useState(false)
  const [isStartDateInputTouched, setIsStartDateInputTouched] = useState(false)
  const isValidSOWName = (value) =>
    value.match(/^[a-z\d\-_\s]+$/i) && value !== '' && value.length <= 100 //Regex for allowing alphanumeric,-,_ and space
  const history = useHistory()
  const Error = useErrorService()
  const { companyName, ecoSystemName } = useParams()
  const type = companyName ? 'Organization' : 'Network'

  const {
    value: sowName,
    isValid: sowNameIsValid,
    hasError: sowNameHasError,
    valueChangeHandler: sowNameChangeHandler,
    inputBlurHandler: sowNameBlurHandler,
    reset: resetSOWName,
  } = useInput(isValidSOWName)

  const onHide = () => {
    setShowModal(false)
    setShowCreateSOWModal(true)
    resetSOWName()
    setSOWStartDate(null)
    setIsStartDateInputTouched(false)
  }

  const checkSOWNameExist = async () => {
    setIsLoading(true)
    try {
      const { status } = await API.AdminAPI.companyList.checkDuplicateSOWName({
        planName: sowName,
      })

      if (status === 200) {
        setIsLoading(false)
        return false
      }
      setIsLoading(false)
      return true
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
      return true
    }
  }

  const submitHandler = async (event) => {
    event.preventDefault()

    if (!sowNameIsValid) {
      return
    }
    const isSOWNameExist = await checkSOWNameExist()
    if (isSOWNameExist) return
    setShowCreateSOWModal(false)
    if (!sowStartDateIsValid) {
      return
    }
    if (sowStartDate && sowNameIsValid) {
      setIsLoading(true)

      const orgEcoType = type === 'Organization' ? 'companyId' : 'networkId'

      try {
        const { data } = await API.AdminAPI.companyList.createSOW({
          planName: sowName,
          sowStartDate,
          type: type === 'Organization' ? 'company' : 'network',
          [orgEcoType]: orgEcoId,
        })

        if (data) {
          setIsLoading(false)
          onHide()
          closeClickedHandler()
          history.push(
            type === 'Organization'
              ? `/admin/organization/${companyName}/sow/${data?._id}/details`
              : `/admin/network/${ecoSystemName}/sow/${data?._id}/details`,
          )
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
  }

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      enforceFocus={false}
      className='custom-modal'
      centered>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          {showCreateSOWModal && (
            <React.Fragment>
              <h3 className='s2 mb-4'>Create New SOW</h3>
              <Form.Control
                name='sowName'
                type='text'
                placeholder='SOW Name'
                value={sowName}
                onChange={sowNameChangeHandler}
                onBlur={sowNameBlurHandler}
                className='s6 border-radius-3'
                isInvalid={sowNameHasError}
                size='lg'
                sm='10'
              />
            </React.Fragment>
          )}
          {!showCreateSOWModal && (
            <React.Fragment>
              <h3 className='s2 mb-4'>Select SOW Start Date</h3>
              <SingleDatePickerWithoutFormik
                placeholder='SOW Start Date'
                className={classNames('s6 border-radius-3 form-control-lg', {
                  'is-invalid':
                    !moment(sowStartDate, 'YYYY-MM-DD', true).isValid() &&
                    isStartDateInputTouched,
                })}
                minimumDate={moment()}
                onDateApply={(e) => {
                  // if (
                  //   moment(e).format('YYYY-MM-DD') !==
                  //   moment(companyAndEcoInfo?.cycleStartDate).format(
                  //     'YYYY-MM-DD',
                  //   )
                  // ) {
                  setSOWStartDate(e)
                  setSOWStartDateIsValid(
                    moment(e, 'YYYY-MM-DD', true).isValid(),
                  )
                  //}
                }}
                onHide={() => setIsStartDateInputTouched(true)}
                disabled={isLoading}
              />
            </React.Fragment>
          )}
          <div
            className={classNames('mt-5', {
              'text-right': showCreateSOWModal,
              'd-flex justify-content-between': !showCreateSOWModal,
            })}>
            {!showCreateSOWModal && (
              <Button
                variant='none'
                className='text-uppercase border-radius-3 line-height-normal create-sow-btn secondary-popup-button'
                onClick={(e) => {
                  e.preventDefault()
                  setShowCreateSOWModal(true)
                  setSOWStartDate(null)
                }}
                disabled={isLoading}>
                Return
              </Button>
            )}
            <Button
              variant='saffron'
              className='text-uppercase border-radius-3 line-height-normal create-sow-btn'
              isLoading={isLoading}
              disabled={
                showCreateSOWModal
                  ? sowName === '' || isLoading
                  : !sowStartDateIsValid || isLoading
              }
              type='submit'>
              Next
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateSOWModal
