import React, { useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import Imgix from 'react-imgix'
import { Col, Row } from 'react-bootstrap'
import PlayIcon from '../assetsComponents/media/PlayIcon'
import ABSwiperSlider from '../common/ABSwiperSlider'
import useResolutionService from '../../services/useResolutionService'

const RecommendedAudio = ({
  NavLink,
  recommendedData,
  isRecommendedLoading,
  limit,
  setRecommendPage,
  recommendedSwiperRef,
  playRecommendedAudio = () => {}
}) => {
  const [recentIndex, setRecentIndex] = useState(0)
  const { isMDScreen, isLGScreen } = useResolutionService()

  if (recommendedData.length === 0) {
    return null
  }

  return (
    <Row>
      <Col>
        {!isRecommendedLoading && (
          <ABSwiperSlider
            onSlideNextTransitionEnd={(e) => {
              const index = e.activeIndex
              if (
                index > recentIndex &&
                index > 0 &&
                (index + 3) % limit === 0
              ) {
                setRecentIndex(() => index)
                setRecommendPage((prev) => prev + 1)
              }
            }}
            arrowProps={{
              hide:
                (isLGScreen && recommendedData.length < 3) ||
                (isMDScreen && recommendedData.length < 2),
              bgColor: '#646464',
              bgOpacity: 1
            }}
            onSwiper={(swiper) => (recommendedSwiperRef.current = swiper)}
            containerClassName='card-swiper'
          >
            {recommendedData?.map((media) => (
              <SwiperSlide key={media._id}>
                <NavLink
                  to={`/media/category/${media.subCategoryId._id}/${media._id}`}
                  onClick={() =>
                    playRecommendedAudio(media.subCategoryId._id, media._id)
                  }
                >
                  <div className='position-relative border-radius-10 overflow-hidden'>
                    <Imgix
                      className='border-radius-10 img-cover'
                      imgixParams={{
                        fit: 'crop',
                        crop: 'edges',
                        w: 400,
                        h: 400,
                        ar: '1:1'
                      }}
                      src={`${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}`}
                      width='100%'
                      alt='image'
                    />
                    <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10 hover:bg-black-100 hover:bg-opacity-75 breakthrough-media-card'>
                      <PlayIcon className='play-icon' />
                    </div>
                  </div>
                </NavLink>
                <span className='d-block font-open-sans font-lg-14 font-12 font-semibold media-card-title letter-spacing-minus-02 text-truncate'>
                  {media.title}
                </span>
                <div>
                  <span className='font-open-sans font-semibold font-lg-12 font-10 media-card-minutes'>
                    {Math.round(media.duration / 60)} minutes
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </ABSwiperSlider>
        )}
      </Col>
    </Row>
  )
}

export default RecommendedAudio
