import React from 'react'
import { Link, useParams } from 'react-router-dom'

const SOWGroupList = ({ sowTeams }) => {
  const { companyName, ecoSystemName, sowId } = useParams()
  const url = companyName
    ? `organization/${companyName}`
    : `network/${ecoSystemName}`
  return (
    <div>
      {sowTeams?.map((sowTeam, index) => (
        <Link
          to={`/admin/${url}/sow/${sowId}/sow-group/${sowTeam?._id}`}
          key={index}>
          <span>{sowTeam?.name}</span>
          <br />
        </Link>
      ))}
    </div>
  )
}

export default SOWGroupList
