import React from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import { ABSpinner } from '@abroad/components'
import { PurposeAndVisionForm } from '../companyMetadata'
import CONSTANTS from '../../utils/constants'

export default function PurposeAndVision({
  companyName,
  id,
  isLoading = false,
}) {
  const headerInfo = {
    title: 'What is Purpose & Vision',
    description: `As Simon Sinek once famously said, "people don't buy what you do, they buy why you are doing it." A clear purpose and vision is everything to an organization. It is an organization’s soul and identity that articulates why an organization exists and what problems it is here to solve. According to research by Deloitte, purpose-driven organizations grow 3 times faster, innovate 30% more, and experience 40% higher levels of retention than competitors. For these reasons, your people leaders have taken the time to articulate your organization's purpose, vision, and global impact goal (if completed) to remind you as often as possible why your organization exists.`,
  }

  const dataArr = [
    {
      title: `Abroad’s Purpose`,
      description:
        CONSTANTS.COMPANY_METADATA.purposeAndVision.purposeDescription,
      keyProp: 'purpose',
      inputLabel: 'Purpose',
      placeholder: 'Enter your purpose statement here',
      maxCharactersAllow: '100',
      inputAsTextArea: false,
      rows: 1,
    },
    {
      title: `Abroad’s Vision`,
      description:
        CONSTANTS.COMPANY_METADATA.purposeAndVision.visionDescription,
      keyProp: 'vision',
      inputLabel: 'Vision',
      placeholder: 'Enter vision here',
      maxCharactersAllow: '500',
      inputAsTextArea: true,
      rows: 5,
    },
    {
      title: `Abroad’s Description`,
      description:
        CONSTANTS.COMPANY_METADATA.purposeAndVision.abroadDescription,
      keyProp: 'description',
      inputLabel: 'Description',
      placeholder: 'Enter description here',
      maxCharactersAllow: '1000',
      inputAsTextArea: true,
      rows: 10,
    },
    {
      title: `Abroad’s Global Impact Goal`,
      description:
        CONSTANTS.COMPANY_METADATA.purposeAndVision.globalImpactDescription,
      keyProp: 'goal',
      inputLabel: 'Global Impact Goal',
      placeholder: 'Enter Global Impact Goal Here',
      maxCharactersAllow: '1000',
      inputAsTextArea: true,
      rows: 10,
    },
  ]
  if (isLoading) {
    return <ABSpinner />
  }
  return (
    <>
      <Helmet>
        <title>{`${companyName}'s Purpose & Vision | Abroad`}</title>
        <meta
          name='title'
          content={`${companyName}'s Purpose & Vision | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${companyName}'s Purpose & Vision | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${companyName}'s Purpose & Vision | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/purpose-and-vision`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/purpose-and-vision`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}/purpose-and-vision`}></meta>
      </Helmet>
      <section>
        <Container className='mt-40 px-0' fluid>
          <Row>
            <Col>
              <h3 className='mb-point-75 font-open-sans font-bold font-24 leading-9 letter-spacing-minus-04'>
                {headerInfo.title}
              </h3>
              <p className='font-open-sans font-semibold font-14 leading-6 letter-spacing-minus-02 opacity-point-5 mb-0'>
                {headerInfo.description}
              </p>
            </Col>
          </Row>
          <PurposeAndVisionForm purposeAndVisionData={dataArr} id={id} />
        </Container>
      </section>
    </>
  )
}
