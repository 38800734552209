import React, { useState, useRef, useEffect, useContext } from 'react'
import { Col, Container, Row, Form as RBForm } from 'react-bootstrap'
import RBDateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import { useFormikContext } from 'formik'
import { useErrorService } from '@abroad/components'
import InsightsTeamClientFilter from './InsightsTeamClientFilter'
import { ClearFilters, SelectionField } from '../common'
import { CustomSelectionField } from '../form'
import usePreviousState from '../common/hooks/usePreviousState'
import useInitialState from '../common/hooks/useInitialState'
import API from '../../utils/API'
import { SessionInsightsContext } from '../../utils/contexts'

const FilterForm = React.memo(
  ({ coachList, render, renderHeder, companies }) => {
    const picker = useRef()
    const [isLoading, setIsLoading] = useState(true)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const firstUpdate = useRef(true)
    const observer = useRef(null)
    const loader = useRef(null)
    const Error = useErrorService()
    const [page, setPage] = useState(1)
    const [insightData, setInsightData] = useState({
      isLoadMore: true,
      insightData: [],
    })
    const [isResetForm, setIsResetForm] = useState(false)
    const limit = 25
    const {
      values: { filterByCoach, filterByTeam, filterByClient, filterByCompany },
      resetForm,
    } = useFormikContext()
    const { isSortByAsc } = useContext(SessionInsightsContext)
    const prevPageCount = usePreviousState(page)
    const { isInitiallyLoad, changeIntialStateHandler } = useInitialState()

    /* eslint-disable */
    const getSessionInsightDataList = async (emptyFirst = false) => {
      try {
        setIsLoading(true)
        const { data } = await API.AdminAPI.teams.getSessionInsightDataList({
          page,
          limit,
          ...(filterByCompany && { companyName: filterByCompany }),
          ...(filterByCoach &&
            Array.isArray(filterByCoach) &&
            filterByCoach?.length > 0 && {
              coachIds: filterByCoach.map(({ value }) => value),
            }),
          ...(filterByTeam &&
            Array.isArray(filterByTeam) &&
            filterByTeam?.length > 0 && {
              teamId: filterByTeam[0].value,
            }),
          ...(filterByClient &&
            Array.isArray(filterByClient) &&
            filterByClient?.length > 0 && {
              clientId: filterByClient[0].value,
            }),
          ...(moment(startDate).format('YYYY-MM-DD') !== 'Invalid date' &&
            moment(startDate)?.format('YYYY-MM-DD') !== null && {
              startDate: moment(startDate).format('YYYY-MM-DD'),
              endDate: moment(endDate).format('YYYY-MM-DD'),
            }),
          sort: isSortByAsc ? 'ASC' : 'DESC',
        })
        setIsLoading(false)
        setInsightData((prev) => ({
          isLoadMore:
            data && (data.length === 0 || data.length < 25) ? false : true,
          insightData: emptyFirst ? [...data] : [...prev.insightData, ...data],
        }))
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
      changeIntialStateHandler()
    }

    useEffect(() => {
      getSessionInsightDataList()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false
        return
      }
      setInsightData((prev) => {
        observer.current?.disconnect()
        return {
          isLoading: true,
          isLoadMore: true,
          insightData: [],
        }
      })
      if (page === prevPageCount && page === 1) {
        setPage(null)
        setPage(1)
        changeIntialStateHandler(true)
        getSessionInsightDataList(true)
        return
      }
      changeIntialStateHandler(true)
      setPage(1)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      filterByClient,
      filterByCoach,
      filterByTeam,
      startDate,
      isSortByAsc,
      filterByCompany,
    ])

    const handleObserver = (entities) => {
      const target = entities[0]
      if (target.isIntersecting) {
        setPage((page) => page + 1)
      }
    }

    useEffect(() => {
      let options = {
        root: null,
        rootMargin: '20px',
        threshold: 1.0,
      }
      // initialize IntersectionObserver
      // and attaching to Load More div
      if (insightData.insightData?.length > 0) {
        observer.current = new IntersectionObserver(handleObserver, options)
        if (loader.current && observer.current) {
          observer.current?.observe(loader.current)
        }
      } else {
        observer.current?.disconnect()
      }
      return () => {
        observer.current?.disconnect()
      }
    }, [insightData])

    const handleApply = (event, picker) => {
      picker.element.val(
        picker.startDate.format('MM/DD/YYYY') +
          ' - ' +
          picker.endDate.format('MM/DD/YYYY'),
      )
      setEndDate(picker.endDate)
      setStartDate(picker.startDate)
    }

    const handleDateClear = (event, picker) => {
      datePicker.element.val('')
    }

    return (
      <>
        {renderHeder(insightData.insightData, startDate, endDate)}
        <RBForm
          onSubmit={(e) => {
            e.preventDefault()
          }}
          className='profile h-100 d-flex flex-column justify-content-between space-y-6 space-lg-y-0 mt-5'>
          <Container className='custom-offset-lg-1 custom-col-lg-12 px-0'>
            <Row>
              <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                <CustomSelectionField
                  name='filterByCoach'
                  options={coachList}
                  className='w-100'
                  placeholder='Select Coach'
                  size='lg'
                  colsForLG='12'
                />
              </Col>
              <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                <RBDateRangePicker
                  ref={picker}
                  className='user-input'
                  onApply={handleApply}
                  onHide={handleApply}
                  onCancel={handleDateClear}
                  initialSettings={{
                    autoUpdateInput: false,
                    maxDate: moment(),
                    locale: {
                      format: 'MM/DD/YYYY',
                    },
                    parentEl: '#p1',
                    applyButtonClasses: 'btn-saffron font-montserrat',
                    cancelButtonClasses:
                      'btn-black font-montserrat clear-filter',
                    autoApply: true,
                  }}
                  {...(isResetForm && {
                    key: startDate || endDate,
                  })}>
                  <input
                    autoComplete={'off'}
                    placeholder='MM/DD/YYYY - MM/DD/YYYY'
                    type='text'
                    className='form-control user-input black-color-placeholder black-input-border'
                    {...(isResetForm && {
                      key: startDate || endDate,
                    })}
                  />
                </RBDateRangePicker>
              </Col>
              <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                <InsightsTeamClientFilter
                  name='filterByClient'
                  placeholder='Select Client'
                  disabled={filterByTeam?.length > 0}
                />
              </Col>
              <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                <InsightsTeamClientFilter
                  name='filterByTeam'
                  placeholder='Select Group'
                  filterByTeam={true}
                  disabled={filterByClient?.length > 0}
                />
              </Col>
              <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
                <SelectionField
                  name='filterByCompany'
                  className='pointer-cursor user-input black-color-placeholder black-input-border'
                  options={companies.companies?.filter(
                    (company) => company !== null,
                  )}
                  optionplaceholder={
                    companies.isLoading
                      ? 'Fetching Organization...'
                      : 'Select Organization'
                  }
                  size='md'
                  onBlur={(e) => {
                    // Overrider formik blur handler
                  }}
                />
              </Col>
            </Row>
            {(filterByCoach?.length > 0 ||
              filterByTeam?.length > 0 ||
              filterByClient?.length > 0 ||
              filterByCompany ||
              (moment(startDate).format('YYYY-MM-DD') !== 'Invalid date' &&
                moment(startDate)?.format('YYYY-MM-DD') !== null)) && (
              <ClearFilters
                wrapperClasses='d-flex justify-content-end'
                onClick={() => {
                  if (startDate && endDate) {
                    setStartDate(null)
                    setEndDate(null)
                    picker.current.setStartDate('')
                    picker.current.setEndDate('')
                    setIsResetForm(true)
                    setTimeout(() => {
                      setIsResetForm(false)
                    }, 200)
                  }
                  resetForm()
                }}
                disabled={isLoading}
              />
            )}
          </Container>
        </RBForm>
        {render(insightData, isLoading, loader, isInitiallyLoad)}
      </>
    )
  },
)

export default FilterForm
