import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CountCard = ({ icon, backgroundColor, info, total }) => {
  return (
    <div className='hover:shadow transition card-counter pointer-cursor mb-5 p-4 border-radius-7 d-flex justify-content-between'>
      <FontAwesomeIcon
        size='2x'
        icon={icon}
        color='#fff'
        border
        style={{ padding: '10px', backgroundColor: `${backgroundColor}` }}
        className='f-icon border-radius-5'
      />
      <div className='d-flex flex-column'>
        <span className='font-montserrat text-right'>{info}</span>
        <span className='font-montserrat font-bold text-right'>{total}</span>
      </div>
    </div>
  )
}

export default CountCard
