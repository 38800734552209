import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import {
  ABSpinner,
  Button,
  useErrorService,
  useNotificationService,
  coachRecommends,
  coachRecommendsUrl,
} from '@abroad/components'
import { PageHeader, IntroductoryNotes, LearningJourney } from '../components'
import { SubHeader } from '../components/common'
import CONSTANTS from '../utils/constants'
import { JourneyMediasContext } from '../utils/contexts'
import API from '../utils/API'

const GroupJourney = ({ isShowHeader = true }) => {
  const [mediasList, setMediasList] = useState([])
  const [notes, setNotes] = useState('')
  const [isMediaFetching, setIsMediaFetching] = useState(true)
  const [teamMetadataLoading, setTeamMetadataLoading] = useState(false)
  const [teamName, setTeamName] = useState(null)
  const [isMaxCharLimitExceeded, setIsMaxCharLimitExceeded] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const { teamId } = useParams()
  const Error = useErrorService()
  const Notification = useNotificationService()

  useEffect(() => {
    const getTeamMetaData = async () => {
      try {
        setTeamMetadataLoading(true)
        const { data } = await API.AdminAPI.teams.getTeamMetadata(teamId)
        setTeamMetadataLoading(false)
        setTeamName(data?.name)
      } catch (e) {
        setTeamMetadataLoading(false)
        Error.showError(e)
      }
    }
    getTeamMetaData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getGroupJourneyMedia = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getGroupJourney(teamId)

        const audios = data?.audios?.map((data) => ({
          ...data.mediaOrModuleDetail,
          type: data.type,
          mediaOrModuleId: data.mediaOrModuleId,
        }))

        if (data) {
          setMediasList(Object.keys(data).length === 0 ? [] : audios)
          setNotes(Object.keys(data).length === 0 ? '' : data?.notes)
        }
      } catch (e) {
        Error.showError(e)
      }
      setIsMediaFetching(false)
    }
    getGroupJourneyMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveMediaHandler = async () => {
    if (isMaxCharLimitExceeded) return
    if (notes === '') {
      Notification.showNotification(
        'Introductory Notes is not allowed to be empty',
        'danger',
      )
      return
    }
    setIsUpdating(true)
    try {
      const medias = mediasList.map((media) => ({
        mediaOrModuleId: media.mediaOrModuleId,
        type: media.type,
      }))
      const journeyData = {
        notes,
        audios: medias,
      }
      const { status } = await API.AdminAPI.teams.updateGroupJourney(
        teamId,
        journeyData,
      )
      if (status) {
        setIsUpdating(false)
        Notification.showNotification(
          `Group ${coachRecommends.toLowerCase()} has been updated.`,
          'success',
        )
      }
    } catch (e) {
      setIsUpdating(false)
      Error.showError(e)
    }
  }

  if (isMediaFetching) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${teamName}'s ${coachRecommends} | Abroad`}</title>
        <meta
          name='title'
          content={`${teamName}'s ${coachRecommends} | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${teamName}'s ${coachRecommends} | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${teamName}'s ${coachRecommends} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/groups/${teamId}/${coachRecommendsUrl}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/groups/${teamId}/${coachRecommendsUrl}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/groups/${teamId}/${coachRecommendsUrl}`}></meta>
      </Helmet>
      {isShowHeader && (
        <PageHeader
          titleText={
            teamMetadataLoading ? (
              <span>
                <Spinner animation='border' className='text-saffron-700' />
              </span>
            ) : (
              `${teamName} - ${coachRecommends}`
            )
          }
          titleClasses='mb-0'
          hasBackArrow={true}
          colClasses='px-0'
        />
      )}
      <JourneyMediasContext.Provider
        value={{ mediasList, setMediasList, notes, setNotes }}>
        <section
          className={
            isShowHeader ? 'custom-offset-lg-1 custom-col-lg-12 px-0' : ''
          }>
          <Container fluid className='px-0'>
            <Row>
              <Col>
                <IntroductoryNotes
                  setIsMaxCharLimitExceeded={setIsMaxCharLimitExceeded}
                  notes={notes}
                  wrapperClasses={isShowHeader ? 'mt-5' : ''}
                />
                <SubHeader
                  title={CONSTANTS.JOURNEYS_METADATA.mediaList.title}
                  contentText={
                    CONSTANTS.JOURNEYS_METADATA.mediaList.contentText
                  }
                />
                <div className='mt-4'>
                  <LearningJourney title={teamName} type='GROUP' />
                </div>
                <div className='d-flex justify-content-end mt-4'>
                  <Button
                    variant='gray'
                    className='line-height-normal'
                    size='md'
                    disabled={isUpdating || isMaxCharLimitExceeded}
                    isLoading={isUpdating}
                    onClick={saveMediaHandler}>
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </JourneyMediasContext.Provider>
    </>
  )
}

export default GroupJourney
