import React from 'react'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

const ActionButton = ({
  buttonClickedHandler = () => {},
  buttonText = 'Actions',
  buttonClasses = '',
}) => {
  const renderMenuIcon = () => {
    return (
      <svg
        className='cursor-pointer'
        xmlns='http://www.w3.org/2000/svg'
        width='35.751'
        height='17.992'
        viewBox='0 0 35.751 17.992'>
        <defs>
          <style>
            {`.header-sm-a{fill:none;stroke:#000;stroke-linecap:round;stroke-width:1.5px;}`}
          </style>
        </defs>
        <g transform='translate(-26.494 -22.504)'>
          <line
            className='header-sm-a'
            x2='21.99'
            transform='translate(32.505 23.254)'></line>
          <line
            className='header-sm-a'
            x2='21.99'
            transform='translate(32.505 31.5)'></line>
          <line
            className='header-sm-a'
            x2='21.99'
            transform='translate(32.505 39.746)'></line>
        </g>
      </svg>
    )
  }
  return (
    <Button
      onClick={buttonClickedHandler}
      variant='none'
      size='lg'
      className={classNames(
        'font-12 text-uppercase align-self-center btn-custom action-button pr-1 d-flex',
        buttonClasses,
      )}>
      <div className='align-self-center px-3'>{buttonText}</div>
      {renderMenuIcon()}
    </Button>
  )
}

export default ActionButton
