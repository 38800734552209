import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { plans, useErrorService } from '@abroad/components'
import { SelectionField } from '../common'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const ActivePromo = ({ plan, disabled }) => {
  const [promoData, setPromoData] = useState([])
  const [isPromoDataLoading, setIsPromoDataLoading] = useState(false)
  const { companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)
  const [networkPromo, setNetworkPromo] = useState(
    companyAndEcoInfo?.networkPromo || '',
  )
  const [isPromoChangeLoading, setIsPromoChangeLoading] = useState(false)
  const Error = useErrorService()

  useEffect(() => {
    const getActivePromoCode = async () => {
      try {
        setIsPromoDataLoading(true)
        const { data } = await API.AdminAPI.promo.getActivePromoCodeList()
        if (data) {
          const isActivePromo = data?.find(
            (promo) => promo.code === companyAndEcoInfo?.networkPromo,
          )
          const updatedPromoListing = isActivePromo
            ? data
            : [
                {
                  id: companyAndEcoInfo?.networkPromo,
                  code: companyAndEcoInfo?.networkPromo,
                  isExpired: true,
                  extraValue: ' (Expired)',
                },
                ...data,
              ]
          const sortedPromo = updatedPromoListing?.sort((a, b) =>
            a.code.localeCompare(b.code),
          )
          setPromoData(sortedPromo)
        }
        setIsPromoDataLoading(false)
      } catch (e) {
        setIsPromoDataLoading(false)
        Error.showError(e)
      }
    }
    getActivePromoCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (plan === plans.free.code) {
      setNetworkPromo('')
    }
  }, [plan])

  const updateNetworkPromoHandler = async (newPromo) => {
    setIsPromoChangeLoading(true)
    try {
      const { status } = await API.AdminAPI.ecoSystem.updateProgramData(
        companyAndEcoInfo?._id,
        { networkPromo: newPromo },
      )
      if (status) {
        setCompanyAndEcoInfo((prev) => ({
          ...prev,
          networkPromo: newPromo,
        }))
        setNetworkPromo(newPromo)
        setIsPromoChangeLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsPromoChangeLoading(false)
    }
  }

  return (
    <>
      <Form.Group>
        <Form.Label className='custom-mb-0-625 text-uppercase sidebar-label d-flex align-items-end'>
          Applicable Promo Code
        </Form.Label>
        <SelectionField
          name='promo'
          isUseFormikSelectionField={false}
          value={networkPromo}
          onChange={(e) => {
            updateNetworkPromoHandler(e.target.value)
          }}
          className={!isPromoChangeLoading && !disabled ? 'pointer-cursor' : ''}
          options={promoData}
          optionplaceholder={isPromoDataLoading ? 'Loading...' : 'Select Promo'}
          size='md'
          promoOptions={true}
          disabled={isPromoDataLoading || isPromoChangeLoading || disabled}
        />
        {isPromoChangeLoading && <span className='s7 mt-2'>Updating...</span>}
      </Form.Group>
    </>
  )
}

export default ActivePromo
