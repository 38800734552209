import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Form, Row } from 'react-bootstrap'
import Imgix from 'react-imgix'
import {
  FeatherLoader,
  GoBackIcon,
  showJourneyMediaDescription,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import { useDebounce } from '../common'
import API from '../../utils/API'
import { JourneyMediasContext } from '../../utils/contexts'

const SearchMedias = ({ render = () => {}, journeyType, addMediaHandler }) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 1000)
  const [isLoading, setIsLoading] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [searchData, setSearchData] = useState([])
  const { showError } = useErrorService()
  const { showNotification } = useNotificationService()
  const { mediasList } = useContext(JourneyMediasContext)

  useEffect(() => {
    render(debouncedSearch !== '')
  }, [debouncedSearch, render])

  useEffect(() => {
    if (search) {
      setSearch(debouncedSearch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearching(true)
      try {
        if (search !== '') {
          const { data } = await API.AdminAPI.userList.getJouneySearchMedia(
            search,
            true,
          )
          // TODO: temporary false until BE API done
          const clonedModules = [...data.modules]
          let filteredModules = []
          if (clonedModules.length > 0) {
            filteredModules = clonedModules.map((module) => ({
              ...module,
              type: 'module',
            }))
          }
          const clonedData = {
            medias: [...data.medias],
            modules: [...filteredModules],
          }
          setSearchData(clonedData)
        }
      } catch (e) {
        showError(e)
      }
      setIsSearching(false)
    }
    getSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  const saveMediaHandler = (mediaInfo, type) => {
    setIsLoading(true)

    const sameAudios = mediasList.filter((media) => media._id === mediaInfo._id)

    if (sameAudios.length > 0) {
      setSearch('')
      setSearchData([])
      setIsLoading(false)
      showNotification('Please provide unique audio.', 'danger')
      return
    }

    const selectedMedia = { ...mediaInfo, mediaOrModuleId: mediaInfo._id, type }
    addMediaHandler(selectedMedia)
    setSearch('')
    setSearchData([])
    setIsLoading(false)
  }

  return (
    <div>
      <Form.Control
        type='text'
        placeholder='Enter Audio Title'
        className='font-open-sans font-14 font-semibold leading-6 letter-spacing-minus-02 border-radius-5 search-journey-input'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        disabled={isLoading}
      />
      {isSearching && debouncedSearch !== '' && (
        <div className='d-flex justify-content-center align-items-center my-3'>
          <FeatherLoader />
        </div>
      )}
      {searchData?.medias?.length === 0 &&
        searchData?.modules?.length === 0 &&
        debouncedSearch !== '' &&
        !isSearching && (
          <div className='d-flex justify-content-center align-items-center my-3'>
            <p className='mb-0'>No media/module found.</p>
          </div>
        )}
      {(searchData?.medias?.length > 0 || searchData?.modules?.length > 0) &&
        debouncedSearch !== '' &&
        !isSearching && (
          <div className='mt-3'>
            {searchData?.modules?.map((moduleInfo) => (
              <div key={moduleInfo._id}>
                {renderModuleMediaList(
                  moduleInfo,
                  (e) => {
                    setSearch(moduleInfo.title)
                    saveMediaHandler(moduleInfo, 'module')
                  },
                  isLoading,
                )}
              </div>
            ))}
            {searchData?.medias?.map((mediaInfo) => (
              <div key={mediaInfo._id}>
                {renderModuleMediaList(
                  mediaInfo,
                  (e) => {
                    setSearch(mediaInfo.title)
                    saveMediaHandler(mediaInfo, 'media')
                  },
                  isLoading,
                )}
              </div>
            ))}
          </div>
        )}
    </div>
  )
}

const renderModuleMediaList = (media, addMediaHandler, isLoading) => {
  const source = process.env.REACT_APP_IMG_SOURCE

  return (
    <Row
      className={classNames('mb-lg-3 mb-4', {
        'pointer-event-none': isLoading,
      })}>
      <Col className='search-col-first align-self-center'>
        <GoBackIcon
          className='media-remove-icon add-medias'
          onClick={addMediaHandler}
        />
      </Col>
      <Col className='search-col-second'>
        {media && (
          <div className='d-flex align-items-center'>
            <div className='media-image'>
              <Imgix
                className='border-radius-5 border-black mr-3'
                imgixParams={{ fit: 'scale', w: 48, h: 48 }}
                src={`${source}/${media.imageFilename}`}
                height={48}
                width={48}
                alt='image'
              />
            </div>
            <div className='d-flex flex-column justify-content-center'>
              <span className='font-open-sans font-13 font-semibold leading-4-5 letter-spacing-minus-04'>
                {media.title}
              </span>
              <span className='font-open-sans font-10 font-semibold leading-3-5 tracking-normal'>
                {showJourneyMediaDescription(media)}
              </span>
            </div>
          </div>
        )}
      </Col>
    </Row>
  )
}

export default SearchMedias
