import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Button, useErrorService } from '@abroad/components'
import InviteLinkBtn from './InviteLinkBtn'
import SideBarCloseIcon from './SideBarCloseIcon'
import CustomCoachingRateModal from './CustomCoachingRateModal'
import DeactiveateSOWGroup from '../common/DeactiveateSOWGroup'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const GroupDetailsSection = ({
  closeClickedHandler,
  setIsAnyModalEventOccurs,
}) => {
  const {
    ids,
    setIds,
    setIsLoadNewData,
    selectedGroupisArchived,
    isShowAddBtn,
    setIsShowAddBtn,
  } = useContext(OrgEcoIdsContext)
  const [coachRateData, setCoachRateData] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showCoachingRateModal, setShowCoachingRateModal] = useState(false)
  const { companyName, sowGroupId } = useParams()
  const { pathname } = useLocation()
  const isGroupCoachesTab = pathname.split('/').includes('coaches')
  const Error = useErrorService()
  const type = companyName ? 'company' : 'network'

  const removeUsersFromGroup = async () => {
    if (ids?.length === 0) return
    setIsSubmitting(true)
    try {
      const { status } = await API.AdminAPI.companyList.removeUsersFromGroup(
        sowGroupId,
        {
          ids: ids,
          ...(coachRateData && { coachesPricing: coachRateData }),
        },
      )
      if (status) {
        setIsSubmitting(false)
        setCoachRateData(null)
        setShowCoachingRateModal(false)
        setIds([])
        setIsLoadNewData(true)
        closeClickedHandler()
      }
    } catch (e) {
      if (e.code === 'sow_team_custom_rate_needed') {
        setShowCoachingRateModal(true)
      }
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  useEffect(() => {
    if (coachRateData) {
      removeUsersFromGroup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachRateData])

  useEffect(() => {
    if (showCoachingRateModal) {
      setIsAnyModalEventOccurs(true)
    } else {
      setIsAnyModalEventOccurs(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCoachingRateModal])

  return (
    <section className='m-3 profile'>
      <CustomCoachingRateModal
        showModal={showCoachingRateModal}
        setShowModal={setShowCoachingRateModal}
        setCoachRateData={setCoachRateData}
      />
      <SideBarCloseIcon closeClickedHandler={closeClickedHandler} />
      <InviteLinkBtn
        classes='mb-point-75'
        closeClickedHandler={closeClickedHandler}
        type={type}
      />
      {!isShowAddBtn?.hideBtn && !isGroupCoachesTab && (
        <Button
          variant='gray'
          size='lg'
          className='w-100 mb-point-75 border-radius-3 line-height-normal'
          onClick={() => {
            setIsShowAddBtn({
              hideBtn: true,
              showTable: true,
            })
            closeClickedHandler()
          }}
          disabled={selectedGroupisArchived}>
          ADD USERS TO GROUP
        </Button>
      )}
      {!isShowAddBtn?.hideBtn && isGroupCoachesTab && (
        <Button
          variant='outline-dark'
          className='btn s8 text-uppercase mb-point-75 w-100 team-btn border-radius-3 line-height-normal'
          onClick={() => {
            setIsShowAddBtn({
              hideBtn: true,
              showTable: true,
            })
            closeClickedHandler()
          }}
          disabled={selectedGroupisArchived}>
          Add More Coaches
        </Button>
      )}
      {ids?.length > 0 && !isGroupCoachesTab && !isShowAddBtn?.hideBtn && (
        <Button
          variant='danger'
          size='md'
          className='w-100 mb-point-75 border-radius-3 line-height-normal'
          onClick={removeUsersFromGroup}
          disabled={isSubmitting || selectedGroupisArchived}
          isLoading={isSubmitting}>
          REMOVE USERS FROM GROUP
        </Button>
      )}
      <DeactiveateSOWGroup
        setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
        closeClickedHandler={closeClickedHandler}
        conditionFordisabledBtn={selectedGroupisArchived}
        isRenderAsSOWModal={false}
      />
    </section>
  )
}

export default GroupDetailsSection
