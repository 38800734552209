import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { ABSpinner, useErrorService } from '@abroad/components'
import { CountCard } from '../components/Platformanalytics'
import { PageHeader } from '../components/common'
import API from '../utils/API'

const PlatformAnalytics = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [mostFavorited, setMostFavorited] = useState([])
  const [openPSP, setOpenPSP] = useState([])
  const [listenPSPAudio, setListenPSPAudio] = useState([])
  const [abandonSurvey, setAbandonSurvey] = useState([])
  const [intentionAndInsightLog, setIntentionAndInsightLog] = useState([])
  const [ctrSRQ, setCtrSRQ] = useState([])
  const [listenSRQAudio, setListenSRQAudio] = useState([])
  const [breakthroughJournal, setBreakthroughJournal] = useState(null)
  const [mediaLibraryData, setMediaLibraryData] = useState([])
  const [landingPageData, setLandingPageData] = useState(null)
  const Error = useErrorService()

  useEffect(() => {
    const getMostFavModule = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getMostFavModule()
        if (data) {
          setMostFavorited(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    const getPostSession = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getPostSession()
        if (data) {
          setOpenPSP(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const getListenPSPAudio = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getListenPSPAudio()
        if (data) {
          setListenPSPAudio(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    const getAbandonSurvey = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getAbandonSurvey()
        if (data) {
          setAbandonSurvey(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    const getIntentionAndInsightLog = async () => {
      setIsLoading(true)
      try {
        const { data } =
          await API.AdminAPI.analytics.getIntentionAndInsightLog()
        if (data) {
          setIntentionAndInsightLog(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const getCtrSRQ = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getCtrSRQ()
        if (data) {
          setCtrSRQ(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    const getListenSRQAudio = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getListenSRQAudio()
        if (data) {
          setListenSRQAudio(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    const getBreakthroughJournal = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getBreakthroughJournal()
        if (data) {
          setBreakthroughJournal(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    const getMediaLibraryData = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getMediaLibraryData()
        if (data) {
          setMediaLibraryData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    const getLandingPageData = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.analytics.getLandingPageData()
        if (data) {
          setLandingPageData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    getLandingPageData()
    getMediaLibraryData()
    getBreakthroughJournal()
    getListenSRQAudio()
    getCtrSRQ()
    getIntentionAndInsightLog()
    getAbandonSurvey()
    getListenPSPAudio()
    getPostSession()
    getMostFavModule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{'Statistics | Abroad'}</title>
        <meta name='title' content={'Statistics | Abroad'}></meta>
        <meta property='og:title' content={'Statistics | Abroad'}></meta>
        <meta property='twitter:title' content={'Statistics | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/statistics`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/statistics`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/statistics`}></meta>
      </Helmet>
      <main>
        <PageHeader
          titleText='Statistics'
          colClasses='px-0'
          titleClasses='sentence-case-text'
        />
        <section>
          <Container
            className='pt-5 custom-offset-lg-1 custom-col-lg-12 px-0'
            fluid>
            <h5 className='font-montserrat font-semibold text-saffron-700'>
              Most Favorited Audio & Module
            </h5>
            <br />
            <Row>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#f39734'
                  info={mostFavorited?.favoriteAudio?.audioName}
                  total={mostFavorited?.favoriteAudio?.count}
                />
              </Col>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#5FA35A'
                  info={mostFavorited?.favoriteModule?.moduleName}
                  total={mostFavorited?.favoriteModule?.count}
                />
              </Col>
              <Col lg={4} md={0}></Col>
            </Row>
            <h5 className='font-montserrat font-semibold text-saffron-700'>
              Post-session Practices
            </h5>
            <br />
            <Row>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#42B2C7'
                  info='Open PSP'
                  total={openPSP?.openedCount}
                />
              </Col>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#D14667'
                  info='Listen PSP audio'
                  total={listenPSPAudio?.listenedCount}
                />
              </Col>
              <Col lg={4} md={0}></Col>
            </Row>
            <h5 className='font-montserrat font-semibold text-saffron-700'>
              Survey
            </h5>
            <br />
            <Row>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#8ee4af'
                  info='Abandon Survey'
                  total={abandonSurvey?.completed}
                />
              </Col>
              <Col lg={4} md={0}></Col>
              <Col lg={4} md={0}></Col>
            </Row>
            <h5 className='font-montserrat font-semibold text-saffron-700'>
              Growth-Plan
            </h5>
            <br />
            <Row>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#8ee4af'
                  info='Insight Logged'
                  total={intentionAndInsightLog?.insightCount}
                />
              </Col>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#8ee4af'
                  info='Intention Logged'
                  total={intentionAndInsightLog?.intentionCount}
                />
              </Col>
              <Col lg={4} md={0}></Col>
            </Row>
            <h5 className='font-montserrat font-semibold text-saffron-700'>
              Self-Reflection Questions
            </h5>
            <br />
            <Row>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#8ee4af'
                  info='Email Clicks'
                  total={ctrSRQ}
                />
              </Col>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#8ee4af'
                  info='Listen SRQ Audio'
                  total={listenSRQAudio?.listenedCount}
                />
              </Col>
              <Col lg={4} md={0}></Col>
            </Row>
            <h5 className='font-montserrat font-semibold text-saffron-700'>
              Breakthrough Program
            </h5>
            <br />
            <Row>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#8ee4af'
                  info='Breakthrough Journal'
                  total={breakthroughJournal?.breakthroughJournal[0]?.count}
                />
              </Col>
              <Col lg={4} md={0}></Col>
              <Col lg={4} md={0}></Col>
            </Row>
            <h5 className='font-montserrat font-semibold text-saffron-700'>
              Media Library
            </h5>
            <br />
            <Row>
              {mediaLibraryData?.listenAudio?.map((mediaLibData, index) => (
                <Col lg={4} md={6}>
                  <CountCard
                    key={index}
                    icon={faUsers}
                    backgroundColor='#8ee4af'
                    info={mediaLibData?.moduleName}
                    total={mediaLibData?.count}
                  />
                </Col>
              ))}
            </Row>
            <h5 className='font-montserrat font-semibold text-saffron-700'>
              Landing Page
            </h5>
            <br />
            <Row>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#8ee4af'
                  info='Survey Taken'
                  total={landingPageData?.surveyTaken}
                />
              </Col>
              <Col lg={4} md={6}>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#8ee4af'
                  info='Just SignedUp'
                  total={landingPageData?.justSignedUp}
                />
              </Col>
              <Col lg={4} md={0}></Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default PlatformAnalytics
