import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useUserFirstName } from '../components'
import { AdminProfileForm } from '../components/adminProfile'

const AdminProfile = () => {
  const { adminId } = useParams()
  const userFirstName = useUserFirstName(adminId)
  return (
    <>
      <Helmet>
        <title>{`${userFirstName} Profile | Abroad`}</title>
        <meta name='title' content={`${userFirstName} Profile | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${userFirstName} Profile | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${userFirstName} Profile | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/profile/${adminId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/profile/${adminId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/profile/${adminId}`}></meta>
      </Helmet>
      <main className='pb-5 mt-5'>
        <AdminProfileForm />
      </main>
    </>
  )
}

export default AdminProfile
