import React, { useEffect, useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import {
  ABSpinner,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import PromoCodeTable from './PromoCodeTable'
import { DeleteConfirmationPrompt } from '../common'
import API from '../../utils/API'

export const PromoCodeListing = ({
  removePromoHandler,
  promoCodeData,
  isLoading,
}) => {
  const [isPromoDelete, setIsPromoDelete] = useState(false)
  const [promoId, setPromoId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const [sortedPromo, setSortedPromo] = useState({
    isLoading: true,
    active: [],
    expired: [],
  })

  useEffect(() => {
    setSortedPromo({
      isLoading: true,
      active: [],
      expired: [],
    })
    if (promoCodeData) {
      const sortedActivePromo = promoCodeData
        ?.filter((promo) => promo.status)
        .sort((a, b) => a.code.localeCompare(b.code))

      const sortedExpiredPromo = promoCodeData
        ?.filter((promo) => !promo.status)
        .sort((a, b) => a.code.localeCompare(b.code))
      setSortedPromo({
        isLoading: false,
        active: sortedActivePromo,
        expired: sortedExpiredPromo,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodeData])

  const handleDeletePromo = async () => {
    setIsPromoDelete(true)
    try {
      const { data } = await API.AdminAPI.promo.deletePromoCode(promoId)
      if (data) {
        setIsPromoDelete(false)
        removePromoHandler(promoId)
        Notification.showNotification('Promo Code has been deleted!', 'success')
        setShowModal(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsPromoDelete(false)
      setShowModal(false)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <DeleteConfirmationPrompt
        showModal={showModal}
        setShowModal={setShowModal}
        isDeleteLoading={isPromoDelete}
        handleDelete={handleDeletePromo}
        message='Promo-code can not be reverted. Are you sure you want to delete this
    promo-code?'
      />
      <section className='my-4 custom-offset-lg-1 custom-col-lg-12 px-0 promo-accordion'>
        <Accordion className='space-y-3 mt-3' defaultActiveKey='0'>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey='0'
              className='border-0 cursor-pointer font-12'>
              Active Promo Codes
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body className='p-2'>
                <PromoCodeTable
                  promoCodeData={sortedPromo.active}
                  isLoading={sortedPromo.isLoading}
                  getPromoIdHandler={(promoId) => {
                    setPromoId(promoId)
                    setShowModal(true)
                  }}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey='1'
              className='border-0 cursor-pointer font-12'>
              Expired Promo Codes
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='1'>
              <Card.Body className='p-2'>
                <PromoCodeTable
                  promoCodeData={sortedPromo.expired}
                  isLoading={sortedPromo.isLoading}
                  getPromoIdHandler={(promoId) => {
                    setPromoId(promoId)
                    setShowModal(true)
                  }}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </section>
    </>
  )
}
