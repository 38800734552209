import React, { useContext, useState } from 'react'
import { Table, Modal } from 'react-bootstrap'
import classNames from 'classnames'
import { Button, DropdownCaretUpIcon, FeatherLoader } from '@abroad/components'
import { CKSessionInsight } from './CKSessionInsight'
import { convertSessionDate } from '../../utils/date'
import { SessionInsightsContext } from '../../utils/contexts'
import labels from '../../constants/labels'

const SessionInsightTable = React.memo(
  React.forwardRef(
    ({ insightData, isLoading, loader, isInitiallyLoad }, ref) => {
      const [showModal, setShowModal] = useState(false)
      const [coachNotesData, setCoachNotesData] = useState('')
      const sanitizaAllTags = /(&nbsp;|<([^>]+)>)/gi
      const { isSortByAsc, setIsSortByAsc } = useContext(SessionInsightsContext)

      const getCoachNotesFirst50Chars = (coachNotesNonConfidential) => {
        const getFirst50Chars = coachNotesNonConfidential
          .replace(sanitizaAllTags, '')
          .slice(0, 47)

        // 47 chars + ... is 3 chars = 50 chars

        if (
          coachNotesNonConfidential.replace(sanitizaAllTags, '').length > 50
        ) {
          return `${getFirst50Chars}...`
        }

        return getFirst50Chars
      }

      return (
        <>
          <div className='mt-3'>
            <Table hover responsive className='custom-table h-100'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Coach Name</th>
                  <th>Client / Group</th>
                  <th
                    className='cursor-pointer'
                    onClick={() => setIsSortByAsc(!isSortByAsc)}>
                    <div className='d-flex'>
                      ORGANIZATION&nbsp;&nbsp;
                      <DropdownCaretUpIcon
                        className={classNames(
                          { 'rotate-180': !isSortByAsc },
                          { 'rotate-original-position': isSortByAsc },
                        )}
                      />
                    </div>
                  </th>
                  <th>Global Session #</th>
                  <th>Session # in this cycle</th>
                  <th>Coaching Cycle # and Range </th>
                  <th>Session Date</th>
                  <th>Duration</th>
                  <th width='10%'>Session Insights (Non-Confidential)</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && isInitiallyLoad && (
                  <tr>
                    <td className='text-center border-0' colSpan='10'>
                      <FeatherLoader />
                    </td>
                  </tr>
                )}
                {!isLoading && insightData.insightData?.length === 0 ? (
                  <tr>
                    <td className='text-center' colSpan='10'>
                      No Record Found.
                    </td>
                  </tr>
                ) : (
                  insightData.insightData?.map((insightData, index) => (
                    <tr key={index}>
                      <td width='1%'>{index + 1}</td>
                      <td width='15%'>{insightData?.coach[0]?.fullName}</td>
                      <td width='20%'>
                        {insightData?.team
                          ? `Group Name: ${insightData?.team[0]?.name}`
                          : `Client Name: ${insightData?.client?.fullName}`}
                      </td>
                      <td width='20%'>
                        {!insightData?.team &&
                        insightData?.client?.workStatus ===
                          labels.workStatus.IN_TRANSITION
                          ? 'User is in career transition'
                          : insightData?.company}
                      </td>
                      <td width='5%'>{insightData?.sequence}</td>
                      <td width='5%'>{insightData?.sessionInCycle}</td>
                      <td width='20%'>
                        {insightData?.coachingMonthNumber && (
                          <>
                            {insightData?.coachingMonthNumber} ({' '}
                            {insightData?.coachingMonthRange} )
                          </>
                        )}
                      </td>
                      <td width='5%'>
                        {convertSessionDate(insightData?.date)}
                      </td>
                      <td width='4%'>{insightData?.length} mins</td>
                      <td style={{ maxWidth: '25%' }}>
                        {insightData?.coachNotesNonConfidential.length > 0 && (
                          <>
                            <pre className='text-wrap'>
                              {getCoachNotesFirst50Chars(
                                insightData?.coachNotesNonConfidential,
                              )}
                            </pre>
                            {/* eslint-disable */}
                            {insightData?.coachNotesNonConfidential.replace(
                              sanitizaAllTags,
                              '',
                            ).length > 50 && (
                              <a
                                href='javascript:void(0)'
                                className='text-saffron-700 cursor-pointer'
                                onClick={() => {
                                  setCoachNotesData(
                                    insightData?.coachNotesNonConfidential,
                                  )
                                  setShowModal(true)
                                }}>
                                Read More
                              </a>
                            )}
                            {/* eslint-enable */}
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                )}
                {insightData.isLoadMore && <tr ref={ref}></tr>}
              </tbody>
            </Table>
            <Table borderless>
              <tbody>
                {insightData.isLoadMore && !isInitiallyLoad && (
                  <tr>
                    <td className='text-center border-0' colSpan='10'>
                      <FeatherLoader />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            className='custom__modal'>
            <Modal.Header closeButton>
              <Modal.Title className='text-uppercase font-montserrat text-saffron-700 font-14'>
                SESSION INSIGHTS (NON-CONFIDENTIAL)
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CKSessionInsight data={coachNotesData} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='none'
                onClick={() => setShowModal(false)}
                className='line-height-normal text-uppercase secondary-popup-button'
                size='sm'>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )
    },
  ),
)
export default SessionInsightTable
