import React, { useEffect, useState } from 'react'
import { ABSpinner, useErrorService } from '@abroad/components'
import moment from 'moment'
import { Container, Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import API from '../utils/API'
import { useLocation } from 'react-router-dom'
import { PageHeader } from '../components/common'

export const UserEmailsDetails = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [emailData, setEmailData] = useState([])
  const [clientProfile, setClientProfile] = useState([])
  const Error = useErrorService()
  let { search } = useLocation()
  const query = new URLSearchParams(search)
  const userId = query.get('userId')

  useEffect(() => {
    const fetchClientProfile = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          userId,
          {
            fields: ['email', 'fullName'],
          },
        )
        if (data) {
          setClientProfile(data)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }

    const getUserEmailList = async () => {
      setIsLoading(true)
      try {
        const { data } =
          await API.AdminAPI.emailSequencing.getUserEmailsDetails(userId)
        if (data) {
          setEmailData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserEmailList()
    fetchClientProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  const FindStatus = (status) => {
    if (status === 1) {
      return 'failed'
    } else if (status === 2) {
      return 'completed'
    } else if (status === 3) {
      return 'removed'
    } else if (status === 4) {
      return 'pending'
    } else if (status === 5) {
      return 'paused'
    } else if (status === 6) {
      return 'unsubscribed'
    }
  }

  return (
    <>
      <Helmet>
        <title>{'Email Details | Abroad'}</title>
        <meta name='title' content={'Email Details | Abroad'}></meta>
        <meta property='og:title' content={'Email Details | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Email Details | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/scheduled-emails/user/${userId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/scheduled-emails/user/${userId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/scheduled-emails/user/${userId}`}></meta>
      </Helmet>
      <PageHeader
        titleText='EMAIL DETAILS'
        descriptionFn={() => {
          return (
            <>
              <p className='font-montserrat font-medium mb-0'>
                User : {clientProfile?.fullName}
              </p>
              <p className='font-montserrat font-medium mb-0'>
                Email : {clientProfile?.email}
              </p>
            </>
          )
        }}
        colClasses='px-0'
      />
      <Container
        className='mt-3 custom-offset-lg-1 custom-col-lg-12 px-0'
        fluid>
        <Table hover responsive className='custom-table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Created At</th>
              <th>Email Delivery Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {emailData.length === 0 ? (
              <tr>
                <td className='text-center' colSpan='6'>
                  No email users found.
                </td>
              </tr>
            ) : (
              emailData?.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user?.subCategoryId?.parentId?.title}</td>
                  <td>{user.subCategoryId?.title}</td>
                  <td>
                    {moment(user.createdAt).format(
                      'ddd , MM/DD/YYYY , hh:mm:ss A',
                    )}
                  </td>
                  <td>
                    {moment(user.expectedProcessingTime).format(
                      'ddd , MM/DD/YYYY , hh:mm:ss A',
                    )}
                  </td>
                  <td>{FindStatus(user.status)}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Container>
    </>
  )
}
