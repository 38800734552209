import React, { useState, useEffect } from 'react'
import { string, object } from 'yup'
import { Form } from 'react-bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import { useHistory } from 'react-router-dom'
import { useErrorService, useUserService } from '@abroad/components'
import { SelectionField } from '../common'
import FormikValuesHelper from '../common/FormikValuesHelper'
import API from '../../utils/API'
import { getLoginUserCoachingPlan, isLGScreen } from '../../utils/utility'

export const CoachingPlanDropdown = ({
  setPlanObj = () => {},
  clientId = '',
  include360 = false,
  includeImpact = false,
  isUploadReportLoading = false,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [coachingPlans, setCoachingPlans] = useState([])
  const [initialValues, setInitialValues] = useState({ planId: null })
  const Error = useErrorService()
  const history = useHistory()
  const [options, setOptions] = useState([])
  const { user } = useUserService()

  useEffect(() => {
    const getCoachingPlans = async () => {
      try {
        let key = ''
        if (include360) {
          key = 'report360Included'
        } else if (includeImpact) {
          key = 'impactReportIncluded'
        }
        const { data } = await API.AdminAPI.coachPlan.getClientsCoachingPlans(
          clientId,
          `?${key}=true`,
        )
        if (data) {
          const { labelValuesData, coachingPlanData } =
            getLoginUserCoachingPlan(data, user?._id)

          if (!coachingPlanData || coachingPlanData.length === 0) {
            history.push(`/coach/clients/${clientId}`)
          }

          setOptions(labelValuesData)
          setCoachingPlans(coachingPlanData)
          const defaultPlanId =
            labelValuesData.length > 0 ? labelValuesData[0].value : null
          setInitialValues({
            planId: defaultPlanId,
          })
          setPlanObj(
            data.length > 0
              ? data[data?.find((e) => e.id === defaultPlanId)]
              : null,
          )
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getCoachingPlans()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return null

  return (
    <Formik
      displayName='profile-form'
      validationSchema={object().shape({
        planId: string().required(),
      })}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={(values) => {
        setPlanObj(coachingPlans.find((e) => e.id === values.planId))
      }}>
      {({ handleSubmit }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            as={FormikForm}
            className='profile px-4 px-lg-0 upload-report-form'>
            {/* TODO-MULTIPLE-PLANS-DONE */}
            <SelectionField
              labelProps={{
                column: isLGScreen ? true : false,
                sm: '2',
              }}
              name='planId'
              options={options}
              optionplaceholder='Select one'
              getValueAsOption={true}
              disabled={isUploadReportLoading}
              className='w-12-half'
            />
            <FormikValuesHelper
              render={(values) => {
                setPlanObj(coachingPlans.find((e) => e.id === values.planId))
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
