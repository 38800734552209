import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Form as RBForm } from 'react-bootstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useErrorService } from '@abroad/components'
import CoachList from './CoachList'
import { ClearFilters, SelectionField as RBFormSelectionField } from '../common'
import API from '../../utils/API'
import labels from '../../constants/labels'
import { returnSelectionFieldStyle } from '../../constants/common'

export const CoachFilter = () => {
  const [coaches, setCoaches] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()
  const [filterByGender, setFilterByGender] = useState('')
  const [specialities, setSpecialities] = useState(null)
  const [roleRelateTo, setRoleRelateTo] = useState(null)
  const [filterByTotalClientsServed, setFilterByTotalClientsServed] =
    useState('')
  const [filterByTotalEarnings, setFilterByTotalEarnings] = useState('')

  useEffect(() => {
    const getCoachList = async () => {
      let specialitiesArray = []
      let roleArray = []

      if (specialities !== null) {
        specialitiesArray = specialities.map((data) => data.value)
      }

      if (roleRelateTo !== null) {
        roleArray = roleRelateTo.map((data) => data.value)
      }

      let params = {
        gender: filterByGender,
        totalClientsServed: filterByTotalClientsServed,
        specialities: specialitiesArray.length > 0 ? specialitiesArray : '',
        roleRelateTo: roleArray.length > 0 ? roleArray : '',
        totalEarnings: filterByTotalEarnings,
      }

      Object.keys(params).forEach((key) =>
        params[key] === '' ||
        params[key] === null ||
        params[key] === [] ||
        params[key] === undefined
          ? delete params[key]
          : '',
      )
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.coachingList.coachesList(params)

        if (data) {
          const sortedCoaches = data?.coaches?.sort((a, b) =>
            a.fullName.localeCompare(b.fullName),
          )
          setCoaches({
            occupiedCapacity: data?.occupiedCapacity,
            totalCapacity: data?.totalCapacity,
            coaches: sortedCoaches,
          })
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getCoachList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterByGender,
    specialities,
    roleRelateTo,
    filterByTotalClientsServed,
    filterByTotalEarnings,
  ])

  const animatedComponents = makeAnimated()

  const stylesForCoachSpecialities = returnSelectionFieldStyle(
    false,
    specialities?.length,
  )
  const stylesForTierLevel = returnSelectionFieldStyle(
    false,
    roleRelateTo?.length,
  )

  return (
    <>
      <section className='py-5'>
        <Container
          className='coaches-list custom-offset-lg-1 custom-col-lg-12 px-0'
          fluid>
          <Row>
            <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
              <RBFormSelectionField
                name='filterByGender'
                options={labels.filterByGender}
                className='pointer-cursor user-input'
                optionplaceholder='Select Gender'
                size='md'
                value={filterByGender}
                onChange={(e) => setFilterByGender(e.target.value)}
                isUseFormikSelectionField={false}
                getKeyAsOption={true}
              />
              <RBForm.Text className='text-muted font-8'>
                Select Gender
              </RBForm.Text>
            </Col>
            <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
              <Select
                value={specialities}
                closeMenuOnSelect={false}
                components={animatedComponents}
                classNamePrefix='selection-input'
                isMulti
                placeholder={'Select Coach Specialities'}
                styles={stylesForCoachSpecialities}
                onChange={(selectedOption) => {
                  setSpecialities(selectedOption)
                }}
                options={labels.filterByCoachSpecialities}
                defaultOptions={labels.filterByCoachSpecialities}
                loadOptions={labels.filterByCoachSpecialities}
              />
              <RBForm.Text className='text-muted font-8'>
                Select Coach Specialities
              </RBForm.Text>
            </Col>
            <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
              <Select
                value={roleRelateTo}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                classNamePrefix='selection-input'
                placeholder={'Select Tier level able to coach'}
                styles={stylesForTierLevel}
                onChange={(selectedOption) => setRoleRelateTo(selectedOption)}
                options={[
                  ...labels.roleListForEnterpriceUser,
                  ...labels.noRoleList,
                ]}
                defaultOptions={[
                  ...labels.roleListForEnterpriceUser,
                  ...labels.noRoleList,
                ]}
                loadOptions={[
                  ...labels.roleListForEnterpriceUser,
                  ...labels.noRoleList,
                ]}
              />
              <RBForm.Text className='text-muted font-8'>
                Select Tier level able to coach
              </RBForm.Text>
            </Col>
            <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
              <RBForm.Control
                placeholder='Abroad Clients Served'
                name='filterByTotalClientsServed'
                type='number'
                className='user-input black-color-placeholder'
                value={filterByTotalClientsServed}
                onChange={(e) => setFilterByTotalClientsServed(e.target.value)}
              />
              <RBForm.Text className='text-muted font-8'>
                Abroad Clients Served
              </RBForm.Text>
            </Col>
            <Col sm={12} md={6} xl={4} lg={4} className='pb-3'>
              <RBForm.Control
                placeholder='Total Earning'
                name='filterByTotalEarnings'
                type='number'
                className='user-input black-color-placeholder'
                value={filterByTotalEarnings}
                onChange={(e) => setFilterByTotalEarnings(e.target.value)}
              />
              <RBForm.Text className='text-muted font-8'>
                Total Earning
              </RBForm.Text>
            </Col>
          </Row>
          {(filterByGender !== '' ||
            specialities !== null ||
            roleRelateTo !== null ||
            filterByTotalClientsServed !== '' ||
            filterByTotalEarnings !== '') && (
            <ClearFilters
              wrapperClasses='text-right'
              disabled={isLoading}
              onClick={() => {
                setFilterByGender('')
                setSpecialities(null)
                setRoleRelateTo(null)
                setFilterByTotalClientsServed('')
                setFilterByTotalEarnings('')
              }}
            />
          )}
        </Container>
      </section>
      <section>
        <CoachList isLoading={isLoading} coaches={coaches} />
      </section>
    </>
  )
}
