export const returnSelectionFieldStyle = (
  disabled,
  extraStyle = {},
  noNeedPladding = false,
) => {
  return {
    control: (provided, state) => ({
      borderRadius: '5px',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '',
      fontSize: '0.75rem',
    }),
    valueContainer: (provided, state) => {
      let borderColor = '#000'
      let backgroundColor = '#fff'
      if (state.selectProps?.isValid) {
        borderColor = '#509f92'
      }
      if (state.selectProps?.isInvalid) {
        borderColor = '#bc4c6a'
      }
      if (disabled) {
        backgroundColor = '#c2c2c2'
      }
      return {
        ...provided,
        width: '100%',
        padding: noNeedPladding ? '0px' : '2px 8px',
        backgroundColor: backgroundColor,
        backgroundClip: 'padding-box',
        border: '1px solid #000',
        borderColor: borderColor,
        borderRadius: '5px',
        outline: 0,
      }
    },
    input: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: '0.45rem',
      color: '#000',
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '0.75rem !important',
      //background: '#ffffff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#efc030' : '',
      ':active': {
        backgroundColor: '#efc030',
      },
      zIndex: '200',
      fontSize: '0.75rem',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      border: '1px solid darkgrey',
      borderRadius: '5px',
    }),
    multiValueRemove: (provided, state) => {
      if (state.isDisabled) {
        return {
          ...provided,
          display: 'none',
        }
      }
      return {
        ...provided,
        borderLeft: '1px solid darkgrey',
        ':hover': {
          backgroundColor: 'transparent',
          color: 'darkgrey',
        },
      }
    },
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    extraStyle,
  }
}

export const returnLGSelectionFieldStyle = (disabled, extraStyle = {}) => {
  return {
    control: (provided, state) => ({
      borderRadius: '5px',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '',
      fontSize: '0.75rem',
    }),
    valueContainer: (provided, state) => {
      let borderColor = '#000'
      let backgroundColor = '#fff'
      if (state.selectProps?.isValid) {
        borderColor = '#509f92'
      }
      if (state.selectProps?.isInvalid) {
        borderColor = '#bc4c6a'
      }
      if (disabled) {
        backgroundColor = '#c2c2c2'
      }
      return {
        ...provided,
        width: '100%',
        padding: '0px',
        backgroundColor: backgroundColor,
        backgroundClip: 'padding-box',
        border: '1px solid #000',
        borderColor: borderColor,
        borderRadius: '5px',
        outline: 0,
      }
    },
    input: (provided) => ({
      ...provided,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      fontSize: '0.875rem !important',
      lineHeight: '1.5rem !important',
      letterSpacing: '-0.02em !important',
      color: '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      fontSize: '0.875rem !important',
      lineHeight: '1.5rem !important',
      letterSpacing: '-0.02em !important',
      color: '#000',
      padding: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#efc030' : '',
      ':active': {
        backgroundColor: '#efc030',
      },
      zIndex: '200',
      fontFamily: 'Open Sans',
      fontWeight: 500,
      fontSize: '0.875rem !important',
      lineHeight: '1.5rem !important',
      letterSpacing: '-0.02em !important',
      color: '#000',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      border: '1px solid darkgrey',
      borderRadius: '5px',
    }),
    multiValueRemove: (provided, state) => {
      if (state.isDisabled) {
        return {
          ...provided,
          display: 'none',
        }
      }
      return {
        ...provided,
        borderLeft: '1px solid darkgrey',
        ':hover': {
          backgroundColor: 'transparent',
          color: 'darkgrey',
        },
      }
    },
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    extraStyle,
  }
}

export const coachingPlanWIP = false
