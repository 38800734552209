import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Link, NavLink } from 'react-router-dom'
import { InfoIcon, useErrorService } from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import API from '../utils/API'
import i18n from '../utils/i18n'
import { withTranslation, Trans } from 'react-i18next'
import { Helmet } from 'react-helmet'

const ViewCompanyAssessment = () => {
  const { companyName } = useParams()
  let history = useHistory()
  const Error = useErrorService()
  const [survey, setSurvey] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getCompanyAssessment = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getSurveyByCompanyName(
          companyName,
        )
        setSurvey(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (companyName) {
      getCompanyAssessment()
    } else {
      history.replace(`/admin/organizations`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${companyName}'s Assessment | Abroad`}</title>
        <meta
          name='title'
          content={`${companyName}'s Assessment | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${companyName}'s Assessment | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${companyName}'s Assessment | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${companyName}`}></meta>
      </Helmet>
      <main>
        <Result
          survey={survey}
          isLoading={isLoading}
          i18n={i18n}
          navigateToSurveyCategory={(subcategory) => {
            return (
              <Link to={`/survey/category/${subcategory}`}>
                <InfoIcon />
              </Link>
            )
          }}
          headerText={<Trans>Organizational Awareness Assessment</Trans>}
          subHeaderText={
            <Trans>{`${decodeURIComponent(
              companyName,
            ).toUpperCase()}'S COMBINED SUMMARY RESULTS`}</Trans>
          }
          subHeaderDescription={
            <Trans>
              Below are the aggregate results for how your group rated your
              organization.
            </Trans>
          }
          showBreakthroughProgramInfo={false}
          onGoBack={() => history.goBack()}
          showGoBackButton={false}
          API={API}
          NavLink={NavLink}
        />
      </main>
    </>
  )
}

export default withTranslation('translation')(ViewCompanyAssessment)
