import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useNotificationService, useErrorService } from '@abroad/components'
import { useTeamReducer } from './Reducer'
import API from '../../utils/API'
import DeleteTeam from './DeleteTeam'

const TeamInput = ({ index }) => {
  const [isConfirm, setIsConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    state: {
      teams: { [index]: team },
      orgEcoId,
    },
    dispatch,
  } = useTeamReducer()
  const [enterededValue, setEnteredValue] = useState(team.name || '')
  const { state } = useTeamReducer()
  const Notification = useNotificationService()
  const Error = useErrorService()
  const { companyName } = useParams()

  useEffect(() => {
    setEnteredValue(team?.name || '')
  }, [team])

  const checkRegexAndIsEmpty = () =>
    enterededValue?.trim() === '' || !enterededValue?.match(/^[A-Za-z0-9 &]+$/)

  const checkTeamIsDuplicate = () => {
    return (
      team?.name.trim().toLowerCase() === enterededValue.trim().toLowerCase()
    )
  }

  const inputBlurHandle = async (e) => {
    const isValid = !checkRegexAndIsEmpty()
    const isteamDuplicate = checkTeamIsDuplicate()
    if (!isValid) {
      Notification.showNotification(
        `Department name can't be empty and only allow alphabets, number, space or ampersand as special character`,
        'danger',
      )
      return
    }
    if (isteamDuplicate) {
      Notification.showNotification(
        'This department is allready saved!',
        'danger',
      )
      return
    }
    try {
      setIsLoading(true)
      await API.AdminAPI.teams.addOrgEcoTeam(
        companyName ? 'company' : 'affiliate',
        orgEcoId,
        {
          name: enterededValue.trim(),
        },
      )
      const data = {
        name: enterededValue.trim(),
        isEditable: false,
      }
      dispatch({
        type: 'UPDATE_TEAM',
        payload: data,
        i: index,
      })
      Notification.showNotification('Department has been created!', 'success')
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const checkCharactersLimit = (value = '') => value.trim().length <= 100

  const valueChangeHandle = (e) => {
    const enteredIsValid = checkCharactersLimit(e.target.value)
    if (!enteredIsValid) {
      Notification.showNotification('only allow 100 characters', 'danger')
      return
    }
    setEnteredValue(e.target.value)
  }

  return (
    <div
      className={classNames(
        'd-flex position-relative align-items-center input-wrapper user-input border-radius-5',
        {
          'input-confirm': isConfirm,
        },
        {
          'my-3': index !== 0 && index + 1 !== state?.teams?.length,
        },
        {
          'mt-3': index + 1 === state?.teams?.length && index !== 0,
        },
        {
          'mr-1': state?.teams?.length > 5,
        },
      )}>
      <Form.Control
        type='text'
        value={
          isConfirm
            ? 'If you delete this department, users will become unassigned.'
            : enterededValue
        }
        onChange={valueChangeHandle}
        onBlur={inputBlurHandle}
        onKeyDown={(e) => {
          if (e.key === 'Enter') inputBlurHandle(e)
        }}
        pattern='[A-Za-z0-9 ampersand]'
        title={`department name can't be empty and only allow alphabets, number, space or & as special character`}
        className='user-input team-input'
        disabled={!team?.isEditable || isLoading}
      />
      {/* eslint-disable */}
      {!team?.isEditable && (
        <div className='team-manage-links'>
          {isConfirm && (
            <a
              className='team-cancel-link text-uppercase font-montserrat mr-2'
              href='javascript:void(0)'
              onClick={() => setIsConfirm(false)}>
              cancel
            </a>
          )}
          <DeleteTeam
            isConfirm={isConfirm}
            setIsConfirm={setIsConfirm}
            teamName={team.name}
          />
        </div>
      )}
    </div>
  )
}

export default TeamInput
