import React, { useContext, useEffect } from 'react'
import {
  NavLink,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { Nav, Row, Col } from 'react-bootstrap'
import { matchPath } from 'react-router'
import classnames from 'classnames'
import {
  useUserService,
  FeatherIcon,
  SidebarDropdown,
} from '@abroad/components'
import { LayoutContext } from '../../utils/contexts'
import { isLGScreen } from '../../utils/utility'
import CONSTANTS from '../../utils/constants'

const { ADMIN_ROUTES, COACH_ROUTES } = CONSTANTS

const Sidebar = () => {
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { showSidebar, showPlayBar, setShowSidebar } = useContext(LayoutContext)
  const { user } = useUserService()

  const SIDEBARLINKS_BEFORE_DROPDOWN = [
    ...(user?.isAdmin ? [{ to: '/admin/home', name: 'Home' }] : []),
    ...(user?.isCoach ? [{ to: '/coach/clients', name: 'My Clients' }] : []),
    ...(user?.isAdmin
      ? [
          { to: '/admin/organizations', name: 'Organizations' },
          { to: '/admin/networks', name: 'Networks' },
          { to: '/admin/users', name: 'All Users' },
          { to: '/admin/coaches', name: 'Coaches' },
        ]
      : []),
  ]

  const DROPDOWN_LINKS_FOR_REPORTS = [
    ...(user?.isAdmin
      ? [
          {
            to: '/admin/account',
            name: 'Accounts',
          },
          {
            to: '/admin/payment',
            name: 'Payments',
          },
          {
            to: '/admin/scheduled-emails',
            name: 'Scheduled Emails',
          },
          {
            to: '/admin/session-insights',
            name: 'Session Insights',
          },
        ]
      : []),
  ]

  const DROPDOWN_LINKS_FOR_SETTINGS = [
    ...(user?.isAdmin
      ? [
          {
            to: '/admin/groups',
            name: 'Groups',
            urlWords: ['group', 'groups'],
          },
          { to: '/admin/promo', name: 'Promo Codes', urlWords: ['promo'] },
          {
            to: '/admin/sitecopy/user/sa',
            name: 'Site Copies',
            urlWords: ['sitecopy'],
          },
          {
            to: '/admin/statistics',
            name: 'Statistics',
            urlWords: ['statistics'],
          },
          {
            to: '/admin/media',
            name: 'Medias',
            urlWords: ['upsert', 'program'],
          },
        ]
      : []),
  ]

  useEffect(() => {
    if (!isLGScreen) {
      setShowSidebar(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <aside id='sidebar' className='position-relative'>
      <Nav
        className={classnames(
          'flex-column flex-nowrap vh-100 sidebar-wrapper space-y-2',
          {
            hide: !showSidebar,
            'sidebar-wrapper-with-play-bar': showPlayBar,
          },
        )}>
        <Nav.Item className='pl-3 pr-3 leading-none'>
          <div className='d-none mt-2 pt-1 d-lg-inline-block'>
            <FeatherIcon
              onClick={() => history.push('/admin/home')}
              className='cursor-pointer'
            />
          </div>
        </Nav.Item>
        <div
          className={classnames(
            'bg-dark-800 h-100 pb-2 space-y-4 sidebar-section mt-4',
            {
              'pb-5 mb-3': !isLGScreen,
            },
          )}>
          <div className='space-y-3'>
            <div className='nav-item sidebar-link-separator'>
              <a
                className='px-4 nav-link pt-sm-sidebar'
                href={`${process.env.REACT_APP_ALTERNATE_DOMAIN}/home`}>
                <div className='align-items-center row'>
                  <div className='d-flex align-items-center justify-content-start leading-6 font-14 pl-2 col'>
                    Client Platform
                  </div>
                </div>
              </a>
            </div>
            {SIDEBARLINKS_BEFORE_DROPDOWN.map((link) => (
              <NavItem
                key={link.name}
                link={link}
                hasCoachAccess={user?.isCoach}
              />
            ))}
            {user?.isAdmin && (
              <>
                <SidebarDropdown
                  links={DROPDOWN_LINKS_FOR_REPORTS}
                  name='Reports'
                  path={path}
                  pathname={pathname}
                  NavLink={NavLink}
                  isRenderOnClientSide={false}
                />
                <SidebarDropdown
                  links={DROPDOWN_LINKS_FOR_SETTINGS}
                  name='Settings'
                  path={path}
                  pathname={pathname}
                  NavLink={NavLink}
                  isRenderOnClientSide={false}
                />
              </>
            )}
          </div>
        </div>
      </Nav>
    </aside>
  )
}

const NavItem = ({ link, hasCoachAccess }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className='px-4'
        isActive={(match, location) => {
          const linkTo = link.to // our constant
          const locationPathName = location?.pathname // web URL
          let result
          if (hasCoachAccess) {
            for (let e2 of Object.values(COACH_ROUTES[linkTo])) {
              result = matchPath(locationPathName, {
                path: e2,
                exact: true,
                strict: false,
              })
              if (result !== null) return result
            }
          } else {
            for (let e2 of Object.values(ADMIN_ROUTES[linkTo])) {
              result = matchPath(locationPathName, {
                path: e2,
                exact: true,
                strict: false,
              })
              if (result !== null) return result
            }
          }
          if (result) {
            return true
          } else {
            return false
          }
        }}
        as={NavLink}
        to={link.to}>
        <Row className='align-items-center'>
          {link?.icon && (
            <Col className='d-flex align-items-center nav-link-icon font-14 leading-6 justify-content-center col-1 col-lg-2 pl-1 pr-0'>
              {link.icon}
              {link.hoverIcon}
            </Col>
          )}
          <Col className='d-flex align-items-center justify-content-start font-14 leading-6 pl-2'>
            {link.name}
          </Col>
        </Row>
      </Nav.Link>
    </Nav.Item>
  )
}

export default Sidebar
