import React from 'react'
import { ParentCategoryOfSiteCopy } from '../common'

export const VisionAndImpact = () => {
  return (
    <ParentCategoryOfSiteCopy
      parentKey='5b46b176ce416b175ff98de6'
      activeLink='vi'
    />
  )
}
