import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { useErrorService } from '@abroad/components'
import { SurveyComparisonReport } from '@abroad/components/dist/survey'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import i18n from '../utils/i18n'
import API from '../utils/API'
import { useUserFirstName } from '../components'

export const UserCompareAssessment = () => {
  let { search } = useLocation()
  let history = useHistory()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [comparisonData, setComparisonData] = useState([])
  const query = new URLSearchParams(search)
  const params = query.getAll('survey')
  const { userId } = useParams()
  const userFirstName = useUserFirstName(userId)

  useEffect(() => {
    const getComparion = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getUserComparisonReport(
          userId,
          {
            surveyIds: params,
          },
        )
        setComparisonData(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else if (e?.code === 'upgrade_plan') {
          setIsLoading(false)
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (params.length === 2) {
      getComparion()
    } else {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{`${userFirstName} Compare Results | Abroad`}</title>
        <meta
          name='title'
          content={`${userFirstName} Compare Results | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${userFirstName} Compare Results | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${userFirstName} Compare Results | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/user/${userId}/comparison_report`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/user/${userId}/comparison_report`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/user/${userId}/comparison_report`}></meta>
      </Helmet>
    )
  }

  return (
    <>
      {renderHelmet()}
      <main>
        <SurveyComparisonReport
          preDate={moment(comparisonData[0]?.submittedAt).format(
            'MMM DD, YYYY',
          )}
          postDate={moment(comparisonData[1]?.submittedAt).format(
            'MMM DD, YYYY',
          )}
          isLoading={isLoading}
          comparisonData={comparisonData}
          i18n={i18n}
          header={() => {
            return <Trans>Compare Results</Trans>
          }}
          onGoBack={() => history.goBack()}
          isRequiredToFormatDate={false}
          showGoBackButton={false}
          isRenderFromOP={true}
        />
      </main>
    </>
  )
}
