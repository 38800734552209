import { useContext } from 'react'
import { TeamContext } from '@abroad/components'

const useTeamReducer = () => {
  const { state, dispatch } = useContext(TeamContext)

  return { state, dispatch }
}

export default useTeamReducer
