import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { Button, useErrorService } from '@abroad/components'
import GroupCoachActions from './GroupCoachActions'
import { IndeterminateCheckbox, Table, TableInstance } from '../table'
import { StaffCoachIcon } from '../assetsComponents'
import { ClearFilters } from '../common'
import UserSearch from '../company/UserSearch'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const intialGlobalFilter = {
  search: '',
}

const SOWGroupCoachesTable = ({
  showAddCoachesBtn,
  onBtnClickHandler,
  isSubmitting = false,
  setSelectedCoaches = () => {},
  disabled = false,
  isSamePL = false,
  renderAsAddCoachTable = false,
}) => {
  const hasMore = useRef(false)
  const [isLoading, setIsLoading] = useState(true)
  const { setIds, ids } = useContext(OrgEcoIdsContext)
  const [coachesList, setCoachesList] = useState([])
  const [reqData, setReqData] = useState(null)
  const { sowGroupId } = useParams()
  const Error = useErrorService()

  const getGroupCoachesData = useCallback(async () => {
    setCoachesList([])
    setIsLoading(true)
    const getAPIFunction = () => {
      if (showAddCoachesBtn) {
        return API.AdminAPI.companyList.getExcludedSOWGroupCoaches(
          sowGroupId,
          reqData,
        )
      }
      return API.AdminAPI.companyList.getSOWGroupCoaches(sowGroupId, reqData)
    }
    try {
      const { data } = await getAPIFunction()
      setCoachesList(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  const fetchData = useCallback(({ search, sortBy }) => {
    const req = {
      filter: {
        ...(search && {
          search: search,
        }),
        sort: !sortBy[0].desc ? 1 : -1,
      },
    }
    setReqData(req)
  }, [])

  useEffect(() => {
    if (reqData) {
      getGroupCoachesData(reqData)
    }
  }, [getGroupCoachesData, reqData])

  const updatePricing = (coachId, newPricing) => {
    if (renderAsAddCoachTable) return
    setCoachesList((prev) =>
      prev.map((coach) => ({
        ...coach,
        ...(coach?._id === coachId
          ? { pricing: newPricing }
          : { pricing: coach?.pricing }),
      })),
    )
  }

  const coachColumn = React.useMemo(
    () => [
      {
        id: 'coachname',
        Header: 'COACH NAME',
        accessor: (properties) => ({
          fullName: properties?.fullName,
          _id: properties?._id || properties?.coachId,
          isStaffCoach: properties?.isStaffCoach
            ? properties?.isStaffCoach
            : false,
          pricing: properties?.pricing,
        }),
        width: '18%',
        Cell: ({ cell }) => (
          <span className='word-breakall'>
            <Link
              to={`/admin/coaches/${cell?.value?._id}`}
              target='_blank'
              className='text-dark-900'>
              {cell.value?.fullName}
              {cell.value?.isStaffCoach && (
                <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
              )}
              <br />
            </Link>
          </span>
        ),
      },
    ],
    [],
  )

  const pricingColumn = React.useMemo(
    () => [
      {
        id: 'pricing',
        Header: 'pricing',
        accessor: (properties) => ({
          isStaffCoach: properties?.isStaffCoach
            ? properties?.isStaffCoach
            : false,
          pricing: properties?.pricing,
        }),
        width: '18%',
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.isStaffCoach ? (
              <span className='text-saffron-700'>$0</span>
            ) : !!cell?.value?.pricing || cell?.value?.pricing === 0 ? (
              <span className='text-saffron-700'>${cell?.value?.pricing}</span>
            ) : null}
          </React.Fragment>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  const groupPricingColumns = React.useMemo(
    () => [
      {
        id: 'ic',
        Header: 'IC',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>{renderCoachRated('ic', cell?.value)}</React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },
      {
        id: 'manager',
        Header: 'Manager',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>
            {renderCoachRated('manager', cell?.value)}
          </React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },

      {
        id: 'leader',
        Header: 'Leader',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>
            {renderCoachRated('leader', cell?.value)}
          </React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },

      {
        id: 'executive',
        Header: 'Executive',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>
            {renderCoachRated('executive', cell?.value)}
          </React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },

      {
        id: 'ceo',
        Header: 'Board & CEO',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>
            {renderCoachRated('ceo', cell?.value)}
          </React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },
    ],
    [],
  )

  const checkboxColumn = React.useMemo(
    () => [
      {
        id: 'selection',
        width: '4%',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({
          getToggleAllRowsSelectedProps,
          toggleRowSelected,
          ...rest
        }) => {
          const rowProps = getToggleAllRowsSelectedProps()
          rowProps.onChange = (e) => {
            rest.rows.forEach((row) => {
              if (!rest.isAllPageRowsSelected) {
                toggleRowSelected(row.id, true)
              } else {
                toggleRowSelected(row.id, false)
              }
            })
          }
          return (
            <div>
              <IndeterminateCheckbox
                _id={'header'}
                {...rowProps}
                classes='header-checkbox'
                disabled={disabled}
              />
            </div>
          )
        },
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => {
          return (
            <div>
              <IndeterminateCheckbox
                disabled={disabled}
                _id={row.id}
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          )
        },
      },
    ],
    [disabled],
  )

  const actionsColumn = React.useMemo(
    () => [
      {
        id: 'actions',
        Header: '',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <GroupCoachActions
            isSamePL={isSamePL}
            coachInfo={cell?.value}
            updatePricing={updatePricing}
          />
        ),
        width: '10%',
        disableSortBy: true,
        dataProps: {
          className: 'px-0',
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSamePL],
  )

  const renderCoachRated = (key, coach) => {
    return (
      <span className='coach-rates-text'>
        {coach?.isStaffCoach
          ? '$0'
          : !!coach?.groupPricing?.[key] || coach?.groupPricing?.[key] === 0
          ? `$${coach?.groupPricing?.[key]}`
          : 'Restricted'}
      </span>
    )
  }

  const selectedRow = (selectedRow) => {
    const newCoaches = selectedRow.map((coach) => ({
      coachId: coach?._id,
      coachName: coach?.fullName,
      pricing: '',
      isStaffCoach: coach?.isStaffCoach,
    }))
    setSelectedCoaches(newCoaches)
    const selectedIds = selectedRow.map((row) => row?._id)
    setIds(selectedIds)
  }

  const samePLColumns = React.useMemo(
    () => [...coachColumn, ...groupPricingColumns, ...actionsColumn],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const customPricingColumns = React.useMemo(
    () => [...coachColumn, ...pricingColumn, ...actionsColumn],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const addCoachesColumns = React.useMemo(
    () => [...checkboxColumn, ...coachColumn, ...groupPricingColumns],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <TableInstance
      columns={
        renderAsAddCoachTable
          ? addCoachesColumns
          : isSamePL
          ? samePLColumns
          : customPricingColumns
      }
      data={coachesList}
      initialState={{
        globalFilter: intialGlobalFilter,
        sortBy: [{ id: 'coachname', desc: false }],
      }}>
      {({
        preGlobalFilteredRows,
        state: { globalFilter, ...other },
        setGlobalFilter,
        ...rest
      }) => (
        <Container className='px-0' fluid>
          <div
            className={classNames('d-flex mb-2-5', {
              'justify-content-between': showAddCoachesBtn,
            })}>
            <div className='d-flex'>
              <UserSearch
                {...{
                  globalFilter,
                  setGlobalFilter,
                }}
              />
              {globalFilter?.search !== '' &&
                globalFilter?.search !== undefined && (
                  <ClearFilters
                    wrapperClasses='ml-4 d-flex align-items-center'
                    onClick={() => setGlobalFilter(intialGlobalFilter)}
                    disabled={isLoading}
                  />
                )}
            </div>
            {showAddCoachesBtn && (
              <Button
                variant='saffron'
                size='md'
                className='line-height-normal'
                onClick={onBtnClickHandler}
                isLoading={isSubmitting}
                disabled={isLoading || isSubmitting || ids?.length === 0}>
                ADD COACHES TO GROUP
              </Button>
            )}
          </div>
          <Table
            ref={hasMore}
            emptyTableMessage='No coach found.'
            tableInstance={{
              preGlobalFilteredRows,
              state: { globalFilter, ...other },
              setGlobalFilter,
              ...rest,
            }}
            isLoading={isLoading}
            fetchData={fetchData}
            selectedRow={selectedRow}
            totalNumberOfColumns='7'
          />
        </Container>
      )}
    </TableInstance>
  )
}

export default SOWGroupCoachesTable
