import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { useErrorService } from '@abroad/components'
import { SelectionField } from '../common'
import API from '../../utils/API'

const SOWGroupsDropdown = () => {
  const [groups, setGroups] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {
    values: { sow },
    setFieldValue,
  } = useFormikContext()
  const Error = useErrorService()

  useEffect(() => {
    const getSOWGroups = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.companyList.getSOWGroups(sow)
        if (data) {
          const sortedData = data.sort((a, b) => a.name.localeCompare(b.name))
          setGroups(
            sortedData?.map((group) => ({
              label: group?.name,
              value: group?._id,
            })),
          )
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    setFieldValue('group', '')
    if (sow) getSOWGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sow])

  return (
    <SelectionField
      name='group'
      options={groups}
      className={isLoading ? 'bg-white' : 'pointer-cursor bg-white'}
      optionplaceholder={isLoading ? 'Featching Groups...' : 'Please Select'}
      inputClasses='col-7'
      labelProps={{ className: 's6 mb-0' }}
      formGroupProps={{
        className: 'add-user-input',
      }}
      divClasses='justify-content-between'
      label='SOW Groups'
      size='lg'
      lg='12'
      disabled={isLoading}
      getValueAsOption={true}
    />
  )
}

export default SOWGroupsDropdown
