import React from 'react'
import classNames from 'classnames'

const ShowCharsLimt = ({ limit, enteredChars, wrapperClasses = '' }) => {
  return (
    <p
      className={classNames(
        'text-right font-open-sans mb-3',
        {
          'text-saffron-700': enteredChars <= limit,
        },
        { 'text-error': enteredChars > limit },
        wrapperClasses,
      )}>
      Characters: {enteredChars} / {limit}
    </p>
  )
}

export default ShowCharsLimt
