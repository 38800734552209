import React, { useContext, useState } from 'react'
import SearchMedias from './SearchMedias'
import SelectMediaDropdown from './SelectMediaDropdown'
import { JourneyMediasContext } from '../../utils/contexts'

const JourneysLibrary = ({ title, journeyType }) => {
  const [hasSearchByInput, setHasSearchByInput] = useState(false)
  const { setMediasList } = useContext(JourneyMediasContext)

  const addMediaHandler = (media) => {
    setMediasList((prev) => [...prev, media])
  }

  return (
    <div>
      <h3 className='font-montserrat font-18 font-bold leading-relaxed letter-spacing-minus-04 text-center'>
        {title}
      </h3>
      <SearchMedias
        render={(isSearchByInput) => setHasSearchByInput(isSearchByInput)}
        journeyType={journeyType}
        addMediaHandler={addMediaHandler}
      />
      {!hasSearchByInput && <SelectMediaDropdown journeyType={journeyType} />}
    </div>
  )
}

export default JourneysLibrary
