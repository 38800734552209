import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'
import CKEditor from '../editor'
import ShowCharsLimt from '../common/ShowCharsLimt'
import CONSTANTS from '../../utils/constants'
import useToSetCharsLimit from '../common/hooks/useCountCharactersLimit'

const KeyTakeaways = ({
  qkey,
  saveLogHandler,
  answers,
  handleCountNotesChars,
  disabled = false,
}) => {
  const [description, setDescription] = useState('')
  const [isEditorReady, setIsEditorReady] = useState(false)
  const { clientId, growthSessionId } = useParams()
  const [isInitiallyLoad, setIsInitiallyLoad] = useState(true)
  const logs = localStorage.getItem(`logs-${clientId}`)
  const parseValue = JSON.parse(logs)
  const prevValue =
    parseValue?.clientId === clientId &&
    parseValue?.growthSessionId === growthSessionId
      ? parseValue?.[qkey]
      : ''
  const {
    totalChars: totalCharsInCoachNotes,
    updateCharsLength: updateCharsLengthForCoachNotes,
  } = useToSetCharsLimit()
  let delayAutoSave

  useEffect(() => {
    if (isEditorReady) {
      if (prevValue) {
        setDescription(prevValue || answers || '')
      } else {
        setDescription(answers || '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditorReady])

  useEffect(() => {
    if (!isEditorReady || isInitiallyLoad) return
    // eslint-disable-next-line react-hooks/exhaustive-deps
    delayAutoSave = setTimeout(() => {
      saveLogHandler(description)
    }, 5000)

    return () => clearTimeout(delayAutoSave)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description])

  useEffect(() => {
    handleCountNotesChars(
      totalCharsInCoachNotes > CONSTANTS.charsLimitInSessionConf,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCharsInCoachNotes])

  return (
    <div className='takeaways'>
      <h3 className='mb-point-75 font-open-sans font-22 font-bold review-title'>
        Key Takeaways
      </h3>
      <p className='s6 review-description'>
        While exploring each category with the client, we encourage you to
        identify several key takeaways and patterns that both you and other
        coaches could reference in the future to make sense of these results.
      </p>
      <CKEditor
        data={description}
        onReady={(editor) => {
          setIsEditorReady(true)
        }}
        onFocus={() => {
          setIsInitiallyLoad(false)
        }}
        onChange={(event, editor) => {
          updateCharsLengthForCoachNotes(
            sanitizeHtml(editor.getData(), {
              allowedTags: [],
              allowedAttributes: {},
            })?.length,
          )
          setDescription(editor.getData())
        }}
        onBlur={(event, editor) => {
          clearTimeout(delayAutoSave)
          saveLogHandler(editor.getData())
        }}
        disabled={disabled}
      />
      {isEditorReady && (
        <ShowCharsLimt
          limit={CONSTANTS.charsLimitInSessionConf}
          enteredChars={totalCharsInCoachNotes}
        />
      )}
    </div>
  )
}

export default KeyTakeaways
