import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import { SurveyComparisonReport } from '@abroad/components/dist/survey'
import API from '../utils/API'
import i18n from '../utils/i18n'
import { Helmet } from 'react-helmet'

const CompanyCompareAssessment = (props) => {
  let { search } = useLocation()
  let history = useHistory()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [comparisonData, setComparisonData] = useState([])
  const [instanceCount, setInstanceCount] = useState(0)
  const query = new URLSearchParams(search)
  const preDate = query.getAll('preDate')
  const postDate = query.getAll('postDate')
  const companyName = decodeURIComponent(query.get('organization'))

  useEffect(() => {
    const getComparion = async () => {
      try {
        let data
        if (props?.apiFor === 'common-users') {
          data =
            await API.AdminAPI.common.getCompanyEcoComparisonReportForCommonUsers(
              search,
              'organization', // or network
            )
        } else {
          data = await API.AdminAPI.companyList.getCompanyComparisonReport(
            search,
          )
        }
        data = data.data
        if (props?.apiFor === 'common-users') {
          setInstanceCount(data[0]?.results?.instances)
        }
        setComparisonData(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else if (e?.code === 'upgrade_plan') {
          setIsLoading(false)
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    getComparion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${companyName}'s Compare Assessment | Abroad`}</title>
        <meta
          name='title'
          content={`${companyName}'s Compare Assessment | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${companyName}'s Compare Assessment | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${companyName}'s Compare Assessment | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organizations`}></meta>
      </Helmet>
      <main>
        <SurveyComparisonReport
          preDate={preDate}
          postDate={postDate}
          isLoading={isLoading}
          comparisonData={comparisonData}
          i18n={i18n}
          header={() => {
            return `Compare Results: ${companyName} ${
              instanceCount > 0 ? `(${instanceCount} instances)` : ''
            }`
          }}
          onGoBack={() => history.goBack()}
          isRenderFromOP={true}
        />
      </main>
    </>
  )
}

export default CompanyCompareAssessment
