import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { object, string } from 'yup'
import {
  Button,
  useErrorService,
  useNotificationService,
  nameRegex,
} from '@abroad/components'
import SOWGroupsDropdown from './SOWGroupsDropdown'
import { InputField } from '../form'
import { SelectionField } from '../common'
import labels from '../../constants/labels'
import API from '../../utils/API'
import { OrgEcoIdsContext } from '../../utils/contexts'
import { useTeamReducer } from '../editTeams/Reducer'
import { checkEnteredTeamExist } from '../../utils/utility'

const AddIndividualUserModal = ({
  orgEcoId,
  buttonClasses = '',
  buttonVariant = 'gray',
  uploadCSVHandler,
  setIsAnyModalEventOccurs = () => {},
  isRenderOnUsersTab = true,
}) => {
  const [showModal, setShowModal] = useState(false)
  const { companyName, ecoSystemName, sowId } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    department: '',
    title: '',
    participantLevel: '',
    sow: '',
    group: '',
    isCompanyUser: companyName ? true : false,
  }
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)
  const { companyAndEcoInfo } = useContext(OrgEcoIdsContext)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { state, dispatch } = useTeamReducer()

  const validationSchema = object().shape({
    firstName: string().matches(nameRegex).max(35).required('Required'),
    lastName: string().matches(nameRegex).max(35).required('Required'),
    email: string()
      .email('Please enter a valid Email address')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      .max(255)
      .required('Please enter a valid Email address'),
    department: string().when('isCompanyUser', {
      is: true,
      then: string()
        .matches(/^[A-Za-z0-9 &]+$/)
        .required('Required'),
      otherwise: () => string().notRequired(),
    }),
    title: string().notRequired().max(60),
    participantLevel: string().required('Required'),
    sow: string().notRequired(),
    group: string().notRequired(),
  })

  useEffect(() => {
    if (showModal) {
      setIsAnyModalEventOccurs(true)
    } else {
      setIsAnyModalEventOccurs(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  useEffect(() => {
    if (showModal && orgEcoId && !isRenderOnUsersTab && sowId) {
      setInitialFormikValues((prev) => ({
        ...prev,
        sow: sowId,
      }))
    }
  }, [showModal, orgEcoId, isRenderOnUsersTab, sowId])

  const onHide = () => setShowModal(false)

  const onSubmit = async (values) => {
    setIsSubmitting(true)
    try {
      const { status } = await API.AdminAPI.common.addIndividualUser(
        companyName ? 'company' : 'affiliate',
        orgEcoId,
        {
          firstName: values?.firstName.trim(),
          lastName: values?.lastName.trim(),
          email: values?.email,
          ...(companyName && { companyTeamName: values?.department }),
          ...(values?.title && { title: values?.title }),
          participantLevel: values?.participantLevel,
          ...(values?.sow && { sowId: values?.sow }),
          ...(values?.group && { groupId: values?.group }),
        },
      )
      if (status) {
        const isEnteredTeamExist = checkEnteredTeamExist(
          state?.teams,
          values?.department,
        )
        if (!isEnteredTeamExist && !ecoSystemName) {
          //call add new team
          dispatch({
            type: 'UPDATE_TEAMS',
            payload: { name: values?.department, isEditable: false },
          })
        }
        uploadCSVHandler()
        setIsSubmitting(false)
        setShowModal(false)
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  return (
    <div>
      <Modal
        show={showModal}
        size='lg'
        onHide={onHide}
        className='custom-modal add-individual-user'
        centered>
        <Modal.Body>
          <h3 className='s2 title mb-2'>Add Individual User</h3>
          <Formik
            initialValues={initialFormikValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationSchema}>
            {({ handleSubmit, values }) => (
              <React.Fragment>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(e)
                  }}>
                  <p className='s6 mb-4'>
                    {`Please fill out the criteria below to add a new user to this
                    ${
                      companyName ? 'organization' : 'network'
                    }. Fields with a * are mandatory.`}
                  </p>
                  <InputField
                    name='firstName'
                    type='text'
                    size='lg'
                    lg='12'
                    label='First Name*'
                    inputClasses='col-7'
                    labelProps={{ className: 's6 mb-0' }}
                    formGroupProps={{
                      className: 'add-user-input',
                    }}
                    divClasses='justify-content-between'
                  />
                  <InputField
                    name='lastName'
                    type='text'
                    size='lg'
                    lg='12'
                    label='Last Name*'
                    inputClasses='col-7'
                    labelProps={{ className: 's6 mb-0' }}
                    formGroupProps={{
                      className: 'add-user-input',
                    }}
                    divClasses='justify-content-between'
                  />
                  <InputField
                    name='email'
                    type='email'
                    size='lg'
                    lg='12'
                    label='Email Address*'
                    inputClasses='col-7'
                    labelProps={{ className: 's6 mb-0' }}
                    formGroupProps={{
                      className: 'add-user-input',
                    }}
                    divClasses='justify-content-between'
                  />
                  {companyName && (
                    <InputField
                      name='department'
                      type='text'
                      size='lg'
                      lg='12'
                      label='Department*'
                      inputClasses='col-7'
                      labelProps={{ className: 's6 mb-0' }}
                      formGroupProps={{
                        className: 'add-user-input',
                      }}
                      divClasses='justify-content-between'
                    />
                  )}
                  <InputField
                    name='title'
                    type='text'
                    size='lg'
                    lg='12'
                    label='Title'
                    inputClasses='col-7'
                    labelProps={{ className: 's6 mb-0' }}
                    formGroupProps={{
                      className: 'add-user-input',
                    }}
                    divClasses='justify-content-between'
                  />
                  <SelectionField
                    name='participantLevel'
                    options={labels.roleListForEnterpriceUser}
                    className='pointer-cursor bg-white'
                    optionplaceholder='Please Select'
                    inputClasses='col-7'
                    labelProps={{ className: 's6 mb-0' }}
                    formGroupProps={{
                      className: 'add-user-input',
                    }}
                    divClasses='justify-content-between'
                    label='Participant Level*'
                    size='lg'
                    lg='12'
                    getValueAsOption={true}
                  />
                  {values?.sow && <SOWGroupsDropdown />}
                  <div className='text-right'>
                    <Button
                      variant='black'
                      className='border-radius-3 line-height-normal w-4/12 add-user-btn'
                      size='lg'
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      type='submit'>
                      ADD USER
                    </Button>
                  </div>
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Button
        variant={buttonVariant}
        size='lg'
        className={classNames('line-height-normal', buttonClasses)}
        onClick={() => {
          if (!companyAndEcoInfo?.planCode) {
            Notification.showNotification(
              'Please set a plan before adding user.',
              'danger',
            )
            return
          }
          setShowModal(true)
        }}>
        ADD NEW USER
      </Button>
    </div>
  )
}

export default AddIndividualUserModal
