import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useAsyncDebounce } from 'react-table'

const UserSearch = React.memo(
  ({ globalFilter: { search, ...rest }, setGlobalFilter }) => {
    const [value, setValue] = useState(search)

    useEffect(() => {
      if (search === '') {
        setValue('')
      }
    }, [search])

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter({ ...rest, search: value || undefined })
    }, 1000)

    return (
      <InputGroup className='org-users-filter'>
        <Form.Control
          className='form-control s7 border-radius-5 search-input'
          name='search'
          size='sm'
          type='text'
          placeholder='Search'
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
        <InputGroup.Text>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='13'
            height='13'
            viewBox='0 0 13 13'
            fill='none'>
            <line
              x1='8.06066'
              y1='8'
              x2='12'
              y2='11.9393'
              stroke='black'
              strokeWidth='1.5'
              strokeLinecap='round'
            />
            <circle cx='5' cy='5' r='4.25' stroke='black' strokeWidth='1.5' />
          </svg>
        </InputGroup.Text>
      </InputGroup>
    )
  },
)

export default UserSearch
