import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import makeAnimated from 'react-select/animated'
import { isLGScreen } from '../../utils/utility'
import API from '../../utils/API'
import { useErrorService } from '@abroad/components'
import { returnSelectionFieldStyle } from '../../constants/common'

const animatedComponents = makeAnimated()

const FreeSelectTeamMembers = ({
  label,
  name,
  options,
  placeholder,
  fullInputWidth,
  disabled,
}) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext()

  const Error = useErrorService()

  const styles = returnSelectionFieldStyle(disabled, options?.length)

  const handleChange = (selectedOption) => setFieldValue(name, selectedOption)

  const promiseOptions = async (inputValue) => {
    try {
      const { data } = await API.AdminAPI.teams.getSearchUsersData(inputValue)
      if (data) {
        return data.map((data) => ({
          value: data._id,
          label: `${data.fullName}(${data.email})`,
        }))
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  return (
    <Row noGutters xs={1} sm={1} md={1} lg={2}>
      {label && (
        <Col
          className={classnames('d-flex align-items-center', {
            'col-lg-2': label && fullInputWidth === undefined,
            'col-lg-12': fullInputWidth === 'true',
          })}>
          <Form.Group className={isLGScreen ? '' : 'mb-0'}>
            <Form.Label column={isLGScreen ? true : false}>{label}</Form.Label>
          </Form.Group>
        </Col>
      )}
      <Col
        className={classnames('custom-selection px-lg-0', {
          'col-lg-10': label && fullInputWidth === undefined,
          'col-lg-12': fullInputWidth === 'true',
        })}>
        <Form.Group>
          <AsyncSelect
            value={values[name]}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            isValid={
              touched.hasOwnProperty(name) && !errors.hasOwnProperty(name)
            }
            isInvalid={
              touched.hasOwnProperty(name) && errors.hasOwnProperty(name)
            }
            placeholder={placeholder}
            styles={styles}
            onFocus={() => {
              setFieldTouched(name, true, false)
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultOptions={options}
            loadOptions={promiseOptions}
            isClearable={values[name]}
            isDisabled={disabled}
          />
        </Form.Group>
      </Col>
    </Row>
  )
}

FreeSelectTeamMembers.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

FreeSelectTeamMembers.defaultProps = {
  options: [],
  name: '',
  label: '',
}

export default FreeSelectTeamMembers
