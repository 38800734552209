import React from 'react'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'

const SignupPreviewField = ({ label, name, type, wrapperClasses = '' }) => {
  return (
    <div className={classNames('form-group', wrapperClasses)}>
      <Form.Label className='field-label'>{label}</Form.Label>
      <Form.Control
        name={name}
        type={type}
        readOnly
        className='border-radius-5'
      />
    </div>
  )
}

export default SignupPreviewField
