import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Button, useErrorService } from '@abroad/components'
import SideBarCloseIcon from './SideBarCloseIcon'
import { SelectionField as RBFormSelectionField } from '../common'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'
import UserWithCoachesModal from './UserWithCoachesModal'

const AssignUsersToCoach = ({
  closeClickedHandler,
  setIsAnyModalEventOccurs,
}) => {
  const [selectedCoach, setSelectedCoach] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedUserHasNoCoach, setSelectedUserHasNoCoach] = useState(false)
  const [coaches, setCoaches] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { ids, selectedSOWUsers, setSelectedSOWUsers, setIsLoadNewData } =
    useContext(OrgEcoIdsContext)
  const { sowId } = useParams()
  const Error = useErrorService()

  useEffect(() => {
    if (selectedSOWUsers?.length > 0) {
      const hasCoaches = selectedSOWUsers?.find(
        (user) => user?.sowCoaches?.length > 0,
      )
      setSelectedUserHasNoCoach(!hasCoaches)
    }
  }, [selectedSOWUsers])

  useEffect(() => {
    if (showModal) {
      setIsAnyModalEventOccurs(true)
    } else {
      setIsAnyModalEventOccurs(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  useEffect(() => {
    const getSOWCoaches = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getSpecificDetailsOfSOW(
          {
            fields: ['coaches'],
          },
          sowId,
        )
        if (data) {
          setCoaches(
            data?.coaches?.map((coach) => ({
              key: coach?.coachId?._id,
              value: coach?.coachId?.fullName,
            })),
          )
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getSOWCoaches()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHide = () => setShowModal(false)

  const assignCoachToUsersHandler = async () => {
    setIsSubmitting(true)
    try {
      const { status } = await API.AdminAPI.companyList.assignCoachToSOWUsers(
        sowId,
        selectedCoach,
        { ids },
      )
      if (status) {
        closeClickedHandler()
        setIsLoadNewData(true)
        setSelectedSOWUsers([])
        setIsSubmitting(false)
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
    }
  }

  return (
    <section className='m-3 profile'>
      <SideBarCloseIcon closeClickedHandler={closeClickedHandler} />
      <UserWithCoachesModal
        showModal={showModal}
        onHide={onHide}
        coachName={
          coaches?.find((coach) => coach?.key === selectedCoach)?.value
        }
        isSubmitting={isSubmitting}
        assignCoachToUsersHandler={assignCoachToUsersHandler}
      />
      <div>
        <Form.Label className='custom-mb-0-625 text-uppercase sidebar-label text-left'>
          ASSIGN SELECTED USERS TO A COACH
        </Form.Label>
        <RBFormSelectionField
          name='userCoach'
          options={coaches}
          className='mb-36'
          optionplaceholder='Select Coach'
          size='md'
          value={selectedCoach}
          onChange={(e) => setSelectedCoach(e.target.value)}
          isUseFormikSelectionField={false}
          getKeyAsOption={true}
        />
        <div className='text-right'>
          <Button
            variant='saffron'
            size='md'
            onClick={() =>
              selectedUserHasNoCoach
                ? assignCoachToUsersHandler()
                : setShowModal(true)
            }
            className='w-7/12 border-radius-3 line-height-normal'
            isLoading={isSubmitting}
            disabled={selectedCoach === '' || isSubmitting}>
            Finish
          </Button>
        </div>
      </div>
    </section>
  )
}

export default AssignUsersToCoach
