import React from 'react'
import { convertSessionDate } from '../../utils/date'

const EnterpriseTeams = ({ enterpriseTeams }) => {
  if (enterpriseTeams?.length === 0) {
    return null
  }
  return (
    <>
      {enterpriseTeams?.length && (
        <tr>
          <td className='font-weight-bold text-center' colSpan='13'>
            Enterprise Groups
          </td>
        </tr>
      )}
      {enterpriseTeams?.map((team, index) => (
        <tr key={team.id}>
          <td>{index + 1}</td>
          <td width='20%'>
            <b>Group Name : </b> {team?.name} <br />
            <b>Session Done : </b> {team?.totalSessionTaken}
          </td>
          <td />
          <td>
            {team?.sessions?.map((session, index) => (
              <React.Fragment key={session?.id}>
                {index + 1}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td>
            {team?.sessions?.map((session) => (
              <React.Fragment key={session?.id}>
                {session?.sequence}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td></td>
          <td></td>
          <td>
            {team?.sessions?.map((session, i) => (
              <React.Fragment key={session?.id}>
                {convertSessionDate(session?.date)}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td>
            {team?.sessions?.map((session, i) => (
              <React.Fragment key={i}>
                {session?.length} mins
                <br />
              </React.Fragment>
            ))}
          </td>
          <td width='15%'>
            {team?.sessions?.map((session, i) => (
              <div
                style={{
                  color: session?.paid ? 'green' : 'orangered',
                }}
                className='text-nowrap'
                key={i}>
                $ {session?.amount?.toFixed(2)}
                <br />
              </div>
            ))}
          </td>
          <td />
          <td />
          <td />
          <td className='text-nowrap'>$ {team?.total?.toFixed(2)}</td>
        </tr>
      ))}
    </>
  )
}

export default EnterpriseTeams
