import React, { useRef } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Table, TableInstance } from '../table'
import { StaffCoachIcon } from '../assetsComponents'
import { SOWCoachesActions } from '../OrgEco'

const CoachRatedTable = ({
  assignedCoachInfo,
  removeCoachFromSOW,
  updateCoachRate,
}) => {
  const hasMore = useRef()

  const columns = React.useMemo(
    () => [
      {
        id: 'coachname',
        Header: 'COACH NAME',
        accessor: (properties) => properties?.coachId?.fullName,
        Cell: ({ cell }) => (
          <span className='word-breakall'>
            <Link
              to={`/coach/clients/${cell?.row?.original?.coachId?._id}`}
              className='text-dark-900'>
              {cell.value}
              {cell.row?.original?.coachId?.isStaffCoach && (
                <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
              )}
            </Link>
          </span>
        ),
        width: '18%',
      },
      {
        id: 'type',
        Header: 'Type',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <div className='d-flex flex-column'>
            <span className='mb-2 coach-rates-text'>Individual</span>
            <span className='coach-rates-text'>Group</span>
          </div>
        ),
        disableSortBy: true,
        width: '12%',
      },
      {
        id: 'ic',
        Header: 'IC',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>{renderCoachRated('ic', cell?.value)}</React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },
      {
        id: 'manager',
        Header: 'Manager',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>
            {renderCoachRated('manager', cell?.value)}
          </React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },

      {
        id: 'leader',
        Header: 'Leader',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>
            {renderCoachRated('leader', cell?.value)}
          </React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },

      {
        id: 'executive',
        Header: 'Executive',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>
            {renderCoachRated('executive', cell?.value)}
          </React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },

      {
        id: 'ceo',
        Header: 'Board & CEO',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <React.Fragment>
            {renderCoachRated('ceo', cell?.value)}
          </React.Fragment>
        ),
        disableSortBy: true,
        width: '12%',
      },

      {
        id: 'action',
        Header: '',
        accessor: (properties) => properties,
        Cell: ({ cell }) => (
          <SOWCoachesActions
            coachInfo={cell?.value}
            removeCoachFromSOW={removeCoachFromSOW}
            updateCoachRate={updateCoachRate}
          />
        ),
        disableSortBy: true,
        width: '10%',
        dataProps: {
          className: 'px-0',
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const renderCoachRated = (key, coach) => {
    return (
      <div className='d-flex flex-column'>
        <span className='coach-rates-text mb-2'>
          {coach?.coachId?.isStaffCoach
            ? '$0'
            : !!coach?.individualPricing?.[key] ||
              coach?.individualPricing?.[key] === 0
            ? `$${coach?.individualPricing?.[key]}`
            : 'Restricted'}
        </span>
        <span className='coach-rates-text'>
          {coach?.coachId?.isStaffCoach
            ? '$0'
            : !!coach?.groupPricing?.[key] || coach?.groupPricing?.[key] === 0
            ? `$${coach?.groupPricing?.[key]}`
            : 'Restricted'}
        </span>
      </div>
    )
  }

  if (assignedCoachInfo && assignedCoachInfo?.length === 0) {
    return null
  }

  return (
    <div className='mt-4'>
      <TableInstance
        columns={columns}
        data={assignedCoachInfo}
        initialState={{
          sortBy: [{ id: 'coachname', desc: false }],
        }}
        manualSortBy={false}
        disableMultiRemove={false}>
        {({
          preGlobalFilteredRows,
          state: { globalFilter, ...other },
          setGlobalFilter,
          ...rest
        }) => (
          <Container className='px-0' fluid>
            <Table
              ref={hasMore}
              emptyTableMessage='No coach found.'
              tableInstance={{
                preGlobalFilteredRows,
                state: { globalFilter, ...other },
                setGlobalFilter,
                ...rest,
              }}
              isLoading={false}
              fetchData={() => {}}
              totalNumberOfColumns='8'
            />
          </Container>
        )}
      </TableInstance>
    </div>
  )
}

export default CoachRatedTable
