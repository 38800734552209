import React from 'react'
import { Col, Row } from 'react-bootstrap'
import JourneysSection from './JourneysSection'
import JourneysLibrary from './JourneysLibrary'

const LearningJourney = ({ title = '', type: journeyType }) => {
  const subTitle = 'Library'

  return (
    <section className='learning-journey'>
      <Row className='section-wrapper'>
        <Col className='journeys-custom-col-first journeys-section py-3'>
          <JourneysSection title={title} journeyType={journeyType} />
        </Col>
        <Col className='journeys-custom-col-second journey-divider px-0' />
        <Col className='journeys-custom-col-third library-section py-3 pl-0'>
          <JourneysLibrary title={subTitle} journeyType={journeyType} />
        </Col>
      </Row>
    </section>
  )
}

export default LearningJourney
