import React, { useState, useContext } from 'react'
import {
  useErrorService,
  NotificationContext,
  useUserService,
} from '@abroad/components'
import API from '../../utils/API'
import CheckBox from './CheckBox'

const ToggleAdminPrivileges = ({
  userId,
  isAdmin,
  index,
  changePrivileges = () => {},
}) => {
  const { user, setUser } = useUserService()
  const [checkboxStatus, setCheckboxStatus] = useState({
    disabled: false,
    checked: isAdmin,
  })
  const Error = useErrorService()
  const { showNotification } = useContext(NotificationContext)

  const getUserPriviliges = async () => {
    try {
      const { data } = await API.ClientAPI.user.getUserPriviliges()
      if (data) {
        const profile = data.profile || {}
        delete data.profile
        setUser({
          ...user,
          ...data,
          profile: {
            ...user.profile,
            ...profile,
          },
        })
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const toggleAdminPrivileges = async (e) => {
    setCheckboxStatus({
      disabled: true,
      checked: e.target.checked,
    })
    changePrivileges(e.target.checked)
    try {
      const { data } = await API.AdminAPI.userList.toggleAdminPrivileges(
        {
          isAdmin: e.target.checked,
        },
        userId,
      )
      if (data) {
        if (userId === user?.id) {
          getUserPriviliges()
        }
        setCheckboxStatus((prev) => ({
          ...prev,
          disabled: false,
        }))
        showNotification('User Changes Saved!', 'success')
      }
    } catch (e) {
      changePrivileges(isAdmin)
      setCheckboxStatus({
        disabled: false,
        checked: isAdmin,
      })
      Error.showError(e)
    }
  }

  return (
    <>
      <CheckBox
        onChange={toggleAdminPrivileges}
        checked={checkboxStatus.checked}
        disabled={checkboxStatus.disabled}
        id={`Super-Admin-${index}`}
        className='privileges-checkbox ml-0'
      />
      <label
        className='form-check-label mt-1 ml-1'
        htmlFor={`Super-Admin-${index}`}>
        Super Admin
      </label>
    </>
  )
}

export default ToggleAdminPrivileges
