import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { NavLink, Switch, useLocation, useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import {
  ABSpinner,
  ArrowWrapper,
  SaffronNav,
  useErrorService,
  useUserService,
  simple360,
  simple360Url,
  plans,
  coachRecommends,
  coachRecommendsUrl,
} from '@abroad/components'
import UserJourney from './UserJourney'
import { UpdateEmail } from './UpdateEmail'
import ViewClientProfile from './ViewClientProfile'
import ClientSimple360 from './ClientSimple360'
import GrowthPlan from './GrowthPlanProvider'
import UserPlansAndPayments from './UserPlansAndPayments'
import {
  ProfileHeader,
  ClientAnalytics,
  ClientSessions,
  ClientBasicInformation,
  ClientStatus,
  ClientSOWs,
  UserGrowthPlan,
  ProfileSidebarSection,
  RightSideBar,
  ClientGrowthPlan,
} from '../components'
import API from '../utils/API'
import PrivateRoute from '../routes/PrivateRoute'

const ClientProfile = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [clientProfile, setClientProfile] = useState(null)
  const [isShowActiveSessions, setIsShowActiveSessions] = useState(true)
  const [coachingPlanExist, setCoachingPlanExist] = useState(false)
  const [impactReportIncluded, setImpactReportIncluded] = useState(false)
  const [report360Included, setReport360Included] = useState(false)
  const [showRightSidebar, setShowRightSidebar] = useState(false)
  const [coaches, setCoaches] = useState([])
  const Error = useErrorService()
  const { clientId } = useParams()
  const { user } = useUserService()
  const location = useLocation()
  const isUserComeFromMyClients = location?.pathname
    .split('/')
    .includes('coach')
  const isSimple360page = location?.pathname.split('/').includes(simple360Url)
  const rightSidebarRef = useRef(null)

  const [pages, setPages] = useState([
    {
      path: isUserComeFromMyClients
        ? `/coach/clients/${clientId}/overview`
        : `/admin/users/${clientId}/overview`,
      name: 'Overview',
      defaultUrl: isUserComeFromMyClients
        ? `/coach/clients/${clientId}`
        : `/admin/users/${clientId}`,
    },
    {
      path: isUserComeFromMyClients
        ? `/coach/clients/${clientId}/analytics`
        : `/admin/users/${clientId}/analytics`,
      name: 'Analytics',
    },
    {
      path: isUserComeFromMyClients
        ? `/coach/clients/${clientId}/growth-plan`
        : `/admin/users/${clientId}/growth-plan`,
      name: 'growth plan',
    },
    {
      path: isUserComeFromMyClients
        ? `/coach/clients/${clientId}/sessions`
        : `/admin/users/${clientId}/sessions`,
      name: 'Sessions',
    },
    {
      path: isUserComeFromMyClients
        ? `/coach/clients/${clientId}/${coachRecommendsUrl}`
        : `/admin/users/${clientId}/${coachRecommendsUrl}`,
      name: coachRecommends,
    },
    {
      path: isUserComeFromMyClients
        ? `/coach/clients/${clientId}/intentions`
        : `/admin/users/${clientId}/intentions`,
      name: 'Intentions',
    },
  ])

  useEffect(() => {
    if (user?.isAdmin) {
      if (pages.findIndex((e) => e.name === 'Plans and Payment') === -1) {
        const clonedPages = [...pages]
        clonedPages.push({
          path: isUserComeFromMyClients
            ? `/coach/clients/${clientId}/plans-payments`
            : `/admin/users/${clientId}/plans-payments`,
          name: 'Plans and Payment',
        })
        setPages(clonedPages)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const fetchClientProfile = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.CoachAPI.coachClient.getClientProfile(
          clientId,
        )
        const loginCoachDetails = data?.coachIds?.find(
          (coach) => coach?._id === user?._id,
        )
        if (loginCoachDetails?.report360Included) {
          setReport360Included(true)
        }
        if (loginCoachDetails?.impactReportIncluded) {
          setImpactReportIncluded(true)
        }
        if (loginCoachDetails?.coachingPlans?.length > 0) {
          setCoachingPlanExist(true)
        }
        if (data?.coachIds) {
          setCoaches(
            data?.coachIds?.map((coach) => ({
              _id: coach?._id,
              fullName: coach?.fullName,
              isStaffCoach: coach?.isStaffCoach || false,
            })),
          )
        }
        if (data) {
          setClientProfile(data)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (clientId) fetchClientProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  useEffect(() => {
    setIsShowActiveSessions(true)
  }, [location.pathname])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${clientProfile?.fname}'s Profile | Abroad`}</title>
        <meta
          name='title'
          content={`${clientProfile?.fname}'s Profile | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${clientProfile?.fname}'s Profile | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${clientProfile?.fname}'s Profile | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}`}></meta>
      </Helmet>
      <main>
        <section className='page-header'>
          <ProfileHeader
            name={`${clientProfile?.fullName} ${
              isSimple360page ? ` - ${simple360}` : ''
            }`}
            email={clientProfile?.email}
            buttonClickedHandler={() => setShowRightSidebar(!showRightSidebar)}
            hasBackArrow={true}
          />
          <div className='custom-offset-lg-1 custom-col-lg-12'>
            <RightSideBar show={showRightSidebar} ref={rightSidebarRef}>
              <ProfileSidebarSection
                closeClickedHandler={() => setShowRightSidebar(false)}
                isCoach={clientProfile?.isCoach}
                isAdmin={clientProfile?.isAdmin}
                email={clientProfile?.email}
                clientProfile={clientProfile}
                rightSidebarRef={rightSidebarRef}
                isShowActiveSessions={isShowActiveSessions}
                setIsShowActiveSessions={setIsShowActiveSessions}
                planChangeHandler={(plan) =>
                  setClientProfile((prev) => ({
                    ...prev,
                    planCode: plan,
                  }))
                }
              />
            </RightSideBar>
            <Switch location={location}>
              <PrivateRoute
                exact
                path={[
                  '/coach/clients/:clientId',
                  '/admin/users/:clientId/',
                  '/admin/users/:clientId/overview',
                  '/coach/clients/:clientId/overview',
                ]}>
                <GetTabs pages={pages} location={location} />
                <ClientBasicInformation profileData={clientProfile} />
                {user?.isAdmin && (
                  <ClientStatus
                    userIsAdminOrCoach={
                      clientProfile?.isAdmin || clientProfile?.isCoach
                    }
                  />
                )}
                <ClientGrowthPlan coaches={coaches} />
                <ClientSOWs
                  coachesHasNoSOW={clientProfile?.coachIds?.filter(
                    (coach) =>
                      coach.isIndiCoach && !coach?.coachingPlans?.length,
                  )}
                  userPlan={clientProfile?.planCode}
                  isUserPlanExpired={
                    clientProfile?.expiresOn <
                    moment(new Date()).format('YYYY-MM-DD')
                  }
                  coachesHasSOW={clientProfile?.coachIds?.filter(
                    (coach) =>
                      coach.isIndiCoach && coach?.coachingPlans?.length,
                  )}
                  coachIds={clientProfile?.coachIds.reduce(
                    (coachIds, coach) => {
                      if (
                        coach.isIndiCoach &&
                        (coach?.coachingPlans?.length === 0 ||
                          !coach?.coachingPlans)
                      ) {
                        return [...coachIds, coach?._id]
                      } else {
                        return coachIds
                      }
                    },
                    [],
                  )}
                  userInfo={{
                    fullName: clientProfile?.fullName,
                    _id: clientProfile?._id,
                    userIsAdminOrCoach:
                      clientProfile?.isAdmin || clientProfile?.isCoach,
                  }}
                  addNewCoachHandler={(newCoach) => {
                    if (
                      coaches?.length === 0 &&
                      clientProfile?.planCode === plans.free.code
                    ) {
                      setClientProfile((prev) => ({
                        ...prev,
                        planCode: plans.growth.code,
                      }))
                    }
                    setCoaches([...coaches, newCoach])
                  }}
                  setClientProfile={setClientProfile}
                />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={[
                  '/admin/users/:clientId/analytics',
                  '/coach/clients/:clientId/analytics',
                ]}>
                <GetTabs pages={pages} location={location} />
                <ClientAnalytics
                  clientCoachIds={clientProfile?.coachIds}
                  isCoach={clientProfile?.isCoach}
                  isAdmin={clientProfile?.isAdmin}
                  coachingPlanExist={coachingPlanExist}
                  impactReportIncluded={impactReportIncluded}
                  report360Included={report360Included}
                  simple360Url={`${
                    isUserComeFromMyClients ? '/coach/clients' : '/admin/users'
                  }/${clientId}/${simple360Url}`}
                />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={[
                  '/admin/users/:clientId/sessions',
                  '/coach/clients/:clientId/sessions',
                ]}>
                <GetTabs pages={pages} location={location} />
                <ClientSessions
                  userPlan={clientProfile?.planCode}
                  isUserPlanExpired={
                    clientProfile?.expiresOn <
                    moment(new Date()).format('YYYY-MM-DD')
                  }
                  isShowActiveSessions={isShowActiveSessions}
                />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={[
                  `/admin/users/:clientId/${coachRecommendsUrl}`,
                  `/coach/clients/:clientId/${coachRecommendsUrl}`,
                ]}>
                <GetTabs pages={pages} location={location} />
                <UserJourney
                  userPlan={clientProfile?.planCode}
                  isUserPlanExpired={
                    clientProfile?.expiresOn <
                    moment(new Date()).format('YYYY-MM-DD')
                  }
                />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={[
                  '/admin/users/:clientId/intentions',
                  '/coach/clients/:clientId/intentions',
                ]}>
                <GetTabs pages={pages} location={location} />
                <GrowthPlan />
              </PrivateRoute>
              <PrivateRoute exact path='/admin/users/:clientId/update-email'>
                <UpdateEmail />
              </PrivateRoute>
              <PrivateRoute
                exact
                path='/admin/client/:clientId/view/client-profile'>
                <ViewClientProfile />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={[
                  '/admin/users/:clientId/growth-plan',
                  '/coach/clients/:clientId/growth-plan',
                ]}>
                <GetTabs pages={pages} location={location} />
                <UserGrowthPlan />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={[
                  '/coach/clients/:clientId/plans-payments',
                  '/admin/users/:clientId/plans-payments',
                ]}>
                <GetTabs pages={pages} location={location} />
                <UserPlansAndPayments />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={[
                  `/admin/users/:clientId/${simple360Url}`,
                  `/coach/clients/:clientId/${simple360Url}`,
                ]}
                component={ClientSimple360}
              />
            </Switch>
          </div>
        </section>
      </main>
    </>
  )
}

const GetTabs = ({ pages, location }) => {
  const tabItems = useRef(null)
  const pathname = location?.pathname
  const hash = location?.hash
  return (
    <Container fluid className='px-0'>
      <div id='header-tabs' className='navtab-header profile-tabs'>
        <ArrowWrapper
          parentRef={tabItems}
          parentId='header-tabs'
          childId='header-tab-items'
          classProps={pages.length}
          shiftValueForPrevBtn={-400}
          shiftValueForNextBtn={+400}>
          <SaffronNav
            className='d-flex header-tabs'
            id='header-tab-items'
            ref={tabItems}>
            {pages.map((page, index) => (
              <SaffronNav.Item key={page.path} className='position-relative'>
                <SaffronNav.Link
                  exact
                  as={NavLink}
                  to={page.path}
                  className={classNames('mb-0', {
                    'mr-0': pages?.length === index + 1,
                  })}
                  isActive={(match) => {
                    if (
                      page?.hashURL &&
                      `${page?.path}${page?.hashURL}` === `${pathname}${hash}`
                    ) {
                      return true
                    }
                    if (page?.defaultUrl && page?.defaultUrl === pathname) {
                      return true
                    }
                    if (page?.discardDefaultMatch && pathname !== page?.path) {
                      return false
                    }
                    if (match && !page?.hashURL) {
                      return true
                    }
                    return false
                  }}>
                  {page.name}
                </SaffronNav.Link>
              </SaffronNav.Item>
            ))}
          </SaffronNav>
        </ArrowWrapper>
      </div>
    </Container>
  )
}

export default ClientProfile
