import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import {
  ABSpinner,
  Button,
  plans,
  useErrorService,
  useNotificationService,
  coachRecommends,
  coachRecommendsUrl,
} from '@abroad/components'
import {
  IntroductoryNotes,
  LearningJourney,
  useUserFirstName,
} from '../components'
import API from '../utils/API'
import { SubHeader } from '../components/common'
import CONSTANTS from '../utils/constants'
import { JourneyMediasContext } from '../utils/contexts'
import { messagesConstant } from '../constants/messages'

const UserJourney = ({ userPlan, isUserPlanExpired }) => {
  const [mediasList, setMediasList] = useState([])
  const [notes, setNotes] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [clientProfile, setClientProfile] = useState([])
  const [isMediaFetching, setIsMediaFetching] = useState(true)
  const [showAddJourneyButton, setShowAddJourneyButton] = useState(false)
  const [isMaxCharLimitExceeded, setIsMaxCharLimitExceeded] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const { clientId: userId } = useParams()
  const Error = useErrorService()
  const Notification = useNotificationService()
  const userFirstName = useUserFirstName(userId)

  const renderErrorMessage = (errorMessage) => {
    return (
      <div className='mt-3'>
        <span className='s5'>{errorMessage}</span>
      </div>
    )
  }

  useEffect(() => {
    const getUserJourneyMedia = async () => {
      try {
        const { data } = await API.ClientAPI.user.getUserJourney({
          clientId: userId,
        })

        const audios = data?.audios?.map((data) => ({
          ...data.mediaOrModuleDetail,
          type: data.type,
          mediaOrModuleId: data.mediaOrModuleId,
        }))

        if (data) {
          setShowAddJourneyButton(Object.keys(data).length === 0)
          setMediasList(Object.keys(data).length === 0 ? [] : audios)
          setNotes(Object.keys(data).length === 0 ? '' : data?.notes)
        }
      } catch (e) {
        Error.showError(e)
      }
      setIsMediaFetching(false)
    }
    getUserJourneyMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getUserInformation = async () => {
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          userId,
          {
            fields: ['fullName'],
          },
        )
        if (data) {
          setClientProfile(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveMediaHandler = async () => {
    if (isMaxCharLimitExceeded) return
    if (notes === '') {
      Notification.showNotification(
        'Introductory Notes is not allowed to be empty',
        'danger',
      )
      return
    }
    setIsUpdating(true)
    try {
      const medias = mediasList.map((media) => ({
        mediaOrModuleId: media.mediaOrModuleId,
        type: media.type,
      }))
      const journeyData = {
        notes,
        audios: medias,
      }
      const { status } = await API.AdminAPI.userList.updateUserJourney(
        userId,
        journeyData,
      )
      if (status) {
        setIsUpdating(false)
        Notification.showNotification(
          `User ${coachRecommends.toLowerCase()} has been updated.`,
          'success',
        )
      }
    } catch (e) {
      setIsUpdating(false)
      Error.showError(e)
    }
  }

  if (isLoading || isMediaFetching) {
    return <ABSpinner />
  }

  if ([plans?.free?.code, plans?.growth?.code].includes(userPlan)) {
    return (
      <React.Fragment>
        {renderErrorMessage(messagesConstant?.hasNoJourneysAccess)}
      </React.Fragment>
    )
  } else if (isUserPlanExpired) {
    return (
      <React.Fragment>
        {renderErrorMessage(messagesConstant?.planExpiredErrorMsg)}
      </React.Fragment>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`${userFirstName} ${coachRecommends} | Abroad`}</title>
        <meta
          name='title'
          content={`${userFirstName} ${coachRecommends} | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${userFirstName} ${coachRecommends} | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${userFirstName} ${coachRecommends} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/users/${userId}/${coachRecommendsUrl}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/users/${userId}/${coachRecommendsUrl}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/users/${userId}/${coachRecommendsUrl}`}></meta>
      </Helmet>
      <JourneyMediasContext.Provider
        value={{ mediasList, setMediasList, notes, setNotes }}>
        <Container fluid className='px-0'>
          <Row>
            <Col>
              {showAddJourneyButton ? (
                <div className='d-flex flex-column mt-3'>
                  <span className='s5'>No {coachRecommends} Found.</span>
                  <span>
                    <Button
                      variant='gray'
                      className='mt-3 line-height-normal border-radius-3'
                      size='md'
                      onClick={() => setShowAddJourneyButton(false)}>
                      Add
                    </Button>
                  </span>
                </div>
              ) : (
                <React.Fragment>
                  <IntroductoryNotes
                    notes={notes}
                    wrapperClasses='mt-3'
                    setIsMaxCharLimitExceeded={setIsMaxCharLimitExceeded}
                  />
                  <SubHeader
                    title={CONSTANTS.JOURNEYS_METADATA.mediaList.title}
                    contentText={
                      CONSTANTS.JOURNEYS_METADATA.mediaList.contentText
                    }
                  />
                  <div className='mt-4'>
                    <LearningJourney
                      title={clientProfile.fullName}
                      type='INDIVIDUAL'
                    />
                  </div>
                  <div className='d-flex justify-content-end mt-4'>
                    <Button
                      variant='gray'
                      className='line-height-normal'
                      size='md'
                      disabled={isUpdating || isMaxCharLimitExceeded}
                      isLoading={isUpdating}
                      onClick={saveMediaHandler}>
                      Update
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </JourneyMediasContext.Provider>
    </>
  )
}

export default UserJourney
