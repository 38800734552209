import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNotificationService, useErrorService } from '@abroad/components'
import { useTeamReducer } from './Reducer'
import API from '../../utils/API'

const DeleteTeam = ({ isConfirm, setIsConfirm, teamName }) => {
  const {
    state: { orgEcoId },
    dispatch,
  } = useTeamReducer()
  const Error = useErrorService()
  const Notification = useNotificationService()
  const [isLoading, setIsLoading] = useState()
  const { companyName } = useParams()

  const teamDeletehandler = async () => {
    try {
      setIsLoading(true)
      await API.AdminAPI.teams.deleteOrgEcoTeam(
        companyName ? 'company' : 'affiliate',
        orgEcoId,
        teamName,
      )
      dispatch({
        type: 'DELETE_TEAM',
        payload: teamName,
      })
      Notification.showNotification('Department has been deleted!', 'success')
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className='team-delete-link text-uppercase font-montserrat'
      href=''
      onClick={(e) => {
        e.preventDefault()
        if (!isConfirm) {
          setIsConfirm(true)
          return
        }
        if (!isLoading) {
          teamDeletehandler()
        }
      }}>
      {isLoading ? 'Deleting...' : 'Delete'}
    </a>
  )
}

export default DeleteTeam
