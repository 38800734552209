import React from 'react'
import { convertSessionDate } from '../../utils/date'

const EnterpriseClients = ({ enterpriseClients }) => {
  const participantLevelObj = {
    leader: 'leader',
    executive: 'executive',
    ceo: 'CEO or Board Member',
    ic: 'Individual Contributor',
    manager: 'manager',
  }
  if (enterpriseClients?.length === 0) {
    return null
  }
  return (
    <>
      {enterpriseClients?.length > 0 && (
        <tr>
          <td className='font-weight-bold text-center' colSpan='13'>
            Enterprise Clients
          </td>
        </tr>
      )}
      {enterpriseClients?.map((client, index) => (
        <tr key={client.id}>
          {/* que - show star? */}
          <td>{index + 1}</td>
          <td>
            {client?.fullName} <br />
            <span>{client?.email}</span> <br />
            {client?.company} <br />
            {/* que position */}
            {client?.roleInCompany && (
              <>
                <b>Participant Level : </b>{' '}
                {participantLevelObj[client?.roleInCompany]?.toUpperCase()}
              </>
            )}
            <br />
            <b>Session Done : </b> {client?.totalSessionTaken}
          </td>
          <td />
          <td>
            {client?.sessions?.map((session, index) => (
              <React.Fragment key={session?.id}>
                {index + 1}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td>
            {client?.sessions?.map((session) => (
              <React.Fragment key={session?.id}>
                {session?.sequence}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td />
          <td />
          <td>
            {client?.sessions?.map((session) => (
              <React.Fragment key={session?.id}>
                {`${convertSessionDate(session?.date)} ${
                  session?.isExtra ? '*' : ''
                }`}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td>
            {client?.sessions?.map((session, i) => (
              <React.Fragment key={session?.id}>
                {session?.length} mins
                <br />
              </React.Fragment>
            ))}
          </td>
          <td className='text-nowrap'>
            {client?.sessions?.map((session) => (
              <div
                style={{
                  color: session?.paid ? 'green' : 'orangered',
                }}
                key={session?.id}>
                $ {session?.amount?.toFixed(2)}
                <br />
              </div>
            ))}
          </td>
          {/* que reports */}
          <td />
          <td />
          <td />
          <td className='text-nowrap'>$ {client?.total?.toFixed(2)}</td>
        </tr>
      ))}
    </>
  )
}

export default EnterpriseClients
