import React, { useState } from 'react'
import { Form as RBForm, Row, Col, Container } from 'react-bootstrap'
import sanitizeHtml from 'sanitize-html'
import { Button, useUserService } from '@abroad/components'
import PageHeader from './PageHeader'
import ShowCharsLimt from './ShowCharsLimt'
import UpsertAttachAudio from './UpsertAttachAudio'
import UpsertAttachKeyResources from './UpsertAttachKeyResources'
import useToSetCharsLimit from './hooks/useCountCharactersLimit'
import { ViewMemberAttendance } from '../team'
import CKEditor from '../editor'
import { convertSessionDate } from '../../utils/date'
import CONSTANTS from '../../utils/constants'
import { getUniqueAudioAndSkillIds } from '../../utils/utility'

export const UpdateClientTeamSession = ({
  isTeamSession = false,
  showCKCondition,
  clientFullName,
  sessionData,
  sessionInsights,
  setSessionInsights,
  nonConfidentialsessionInsights,
  setNonConfidentialSessionInsights,
  postSession,
  setPostSession,
  search,
  setSearch,
  inputFocus,
  setInputFocus,
  searchData,
  isSearch,
  isSessionUpdateLoading,
  onSubmit,
  medias,
  isDiffTeamCoach = false,
  searchKeySkill,
  setSearchKeySkill,
  searchKeySkillData,
  isSearchKeySkill,
  inputFocusOfKeySkill,
  setInputFocusOfKeySkill,
  keySkillPdfsData,
  isRenderOnGroupSession = false,
  groupMembers = {},
}) => {
  const { user } = useUserService()
  let values
  if (medias.length < CONSTANTS.maxSessionAudioLimit) {
    values = [...medias, {}]
  } else {
    values = [...medias]
  }

  const [audios, setAudios] = useState({ values })

  if (keySkillPdfsData.length < CONSTANTS.maxLimitForKeySkill) {
    values = [...keySkillPdfsData, {}]
  } else {
    values = [...keySkillPdfsData]
  }

  const [keySkills, setKeySkills] = useState({ values })
  const {
    totalChars: totalCharsInSessionNonConf,
    updateCharsLength: updateCharsLengthForSessionNonConf,
  } = useToSetCharsLimit()

  const {
    totalChars: totalCharsInSessionConf,
    updateCharsLength: updateCharsLengthForSessionConf,
  } = useToSetCharsLimit()

  const {
    totalChars: totalCharsInPSP,
    updateCharsLength: updateCharsLengthForPSP,
  } = useToSetCharsLimit()

  const onFormSubmit = () => {
    const wrapper = getUniqueAudioAndSkillIds(audios, keySkills)
    onSubmit(wrapper)
  }

  const countLimitOfSessionConf = (chars) => {
    updateCharsLengthForSessionConf(
      sanitizeHtml(chars, {
        allowedTags: [],
        allowedAttributes: {},
      })?.length,
    )
    return
  }

  const countLimitOfSessionNonConf = (chars) => {
    updateCharsLengthForSessionNonConf(
      sanitizeHtml(chars, {
        allowedTags: [],
        allowedAttributes: {},
      })?.length,
    )
    return
  }

  const countLimitOfPSP = (chars) => {
    updateCharsLengthForPSP(
      sanitizeHtml(chars, {
        allowedTags: [],
        allowedAttributes: {},
      })?.length,
    )
    return
  }

  return (
    <>
      <PageHeader
        titleFn={() => {
          return (
            <h3 className='text-dark-600'>
              {`${sessionData?.length} Minute session on ${convertSessionDate(
                sessionData?.date,
              )}`}
            </h3>
          )
        }}
        colClasses='px-lg-0'
      />
      <Container
        className='py-3 custom-offset-lg-1 custom-col-lg-12 px-lg-0'
        fluid>
        <section>
          {isTeamSession ? (
            <p className='font-open-sans'>
              GROUP NAME: {sessionData?.teamId?.name}
            </p>
          ) : (
            <p className='font-open-sans'>CLIENT NAME: {clientFullName}</p>
          )}
          {/* TODO-MULTIPLE-PLANS-DONE */}
          {!isTeamSession && (
            <p className='font-open-sans'>
              PLAN NAME: {sessionData?.planId?.planName}
            </p>
          )}
          <p className='font-open-sans'>
            COACH NAME: {sessionData?.coach?.fullName}
          </p>
          <p className='font-open-sans'>
            SESSION DATE: {convertSessionDate(sessionData?.date)}
          </p>
          <p className='font-open-sans'>
            DURATION: {`${sessionData?.length} minutes`}
          </p>
          {isRenderOnGroupSession && (
            <ViewMemberAttendance groupMembers={groupMembers} />
          )}
        </section>
      </Container>
      <Container
        className='py-5 custom-offset-lg-1 custom-col-lg-12 px-lg-0'
        fluid>
        <RBForm className='profile'>
          {user?.id === showCKCondition && (
            <>
              <Row>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  className='d-lg-flex align-items-lg-center justify-content-lg-end pr-lg-1'>
                  <RBForm.Label>SESSION INSIGHTS (Confidential)</RBForm.Label>
                </Col>
                <Col lg={10} md={12} sm={12}>
                  <CKEditor
                    data={sessionInsights}
                    placeholder='Session Insights are required. They are confidential to you as the coach and will not be seen by Abroad or the client.'
                    onReady={(editor) => {
                      countLimitOfSessionConf(editor.getData())
                    }}
                    onChange={(event, editor) => {
                      countLimitOfSessionConf(editor.getData())
                      setSessionInsights(editor.getData())
                    }}
                  />
                </Col>
              </Row>
              <ShowCharsLimt
                limit={CONSTANTS.charsLimitInSessionConf}
                enteredChars={totalCharsInSessionConf}
              />
            </>
          )}
          {!isDiffTeamCoach && (
            <>
              <Row>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  className='d-lg-flex align-items-lg-center justify-content-lg-end pr-lg-1'>
                  <RBForm.Label>
                    SESSION INSIGHTS (Non-Confidential)
                  </RBForm.Label>
                </Col>
                <Col lg={10} md={12} sm={12}>
                  <CKEditor
                    data={nonConfidentialsessionInsights}
                    disabled={user?.id !== showCKCondition ? true : false}
                    placeholder='Share Session Insights with the Abroad Coaching Group. Non-Confidential Insights may be specific to this session or the overall engagement.'
                    onReady={(editor) => {
                      countLimitOfSessionNonConf(editor.getData())
                    }}
                    onChange={(event, editor) => {
                      countLimitOfSessionNonConf(editor.getData())
                      setNonConfidentialSessionInsights(editor.getData())
                    }}
                  />
                </Col>
              </Row>
              <ShowCharsLimt
                limit={CONSTANTS.charsLimitInSessionNonConf}
                enteredChars={totalCharsInSessionNonConf}
              />
            </>
          )}
          <Row>
            <Col
              lg={2}
              md={12}
              sm={12}
              className='d-lg-flex align-items-lg-center justify-content-lg-end pr-lg-1'>
              <RBForm.Label>POST-SESSION PRACTICES</RBForm.Label>
            </Col>
            <Col lg={10} md={12} sm={12}>
              <CKEditor
                data={postSession}
                disabled={user?.id !== showCKCondition || isDiffTeamCoach}
                placeholder='Post-Session Practices are recommended. Once you save the session, the client(s) will be notified via email to log into the Abroad Platform to view Post-Session Practices.'
                onReady={(editor) => {
                  countLimitOfPSP(editor.getData())
                }}
                onChange={(event, editor) => {
                  countLimitOfPSP(editor.getData())
                  setPostSession(editor.getData())
                }}
              />
            </Col>
          </Row>
          <ShowCharsLimt
            limit={CONSTANTS.charsLimitInPSP}
            enteredChars={totalCharsInPSP}
          />
          {user?.id === showCKCondition && (
            <>
              <Row>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  className='d-lg-flex justify-content-lg-end align-items-lg-center p-lg-0'>
                  <RBForm.Label className='form-label'>
                    ATTACH AUDIO
                  </RBForm.Label>
                </Col>
                <Col>
                  <UpsertAttachAudio
                    search={search}
                    setSearch={setSearch}
                    searchData={searchData}
                    isSearch={isSearch}
                    inputFocus={inputFocus}
                    setInputFocus={setInputFocus}
                    audios={audios}
                    setAudios={setAudios}
                    maxLimit={CONSTANTS.maxSessionAudioLimit}
                  />
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  className='d-lg-flex justify-content-lg-end align-items-lg-center p-lg-0'>
                  <RBForm.Label className='form-label text-uppercase'>
                    Attach Key Resources
                  </RBForm.Label>
                </Col>
                <Col>
                  <UpsertAttachKeyResources
                    searchKeySkill={searchKeySkill}
                    setSearchKeySkill={setSearchKeySkill}
                    searchKeySkillData={searchKeySkillData}
                    isSearchKeySkill={isSearchKeySkill}
                    inputFocusOfKeySkill={inputFocusOfKeySkill}
                    setInputFocusOfKeySkill={setInputFocusOfKeySkill}
                    keySkills={keySkills}
                    setKeySkills={setKeySkills}
                    maxLimit={CONSTANTS.maxLimitForKeySkill}
                  />
                </Col>
              </Row>
              <div className='text-right mt-3'>
                <Button
                  isLoading={isSessionUpdateLoading}
                  disabled={
                    isSessionUpdateLoading ||
                    totalCharsInSessionNonConf >
                      CONSTANTS.charsLimitInSessionNonConf ||
                    totalCharsInSessionConf >
                      CONSTANTS.charsLimitInSessionConf ||
                    totalCharsInPSP > CONSTANTS.charsLimitInPSP
                  }
                  onClick={(e) => onFormSubmit(e)}
                  type='button'
                  size='md'
                  className='line-height-normal border-radius-3 text-uppercase'
                  variant='gray'>
                  UPDATE
                </Button>
              </div>
            </>
          )}
        </RBForm>
      </Container>
    </>
  )
}
