import React from 'react'
import classNames from 'classnames'
import { Button } from '@abroad/components'

const GoToNextStepBtn = ({
  nextStepHandler,
  isLoading = false,
  isDisabled = false,
  btnClasses = '',
  btnText = 'Next',
}) => {
  return (
    <Button
      isLoading={isLoading}
      disabled={isDisabled}
      onClick={nextStepHandler}
      className={classNames('line-height-normal text-uppercase', btnClasses)}
      size='md'
      variant='saffron'>
      {btnText}
    </Button>
  )
}

export default GoToNextStepBtn
