import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { Button, FeatherLoader, useErrorService } from '@abroad/components'
import { StaffCoachIcon } from '../assetsComponents'
import API from '../../utils/API'

const RemoveCoachesModal = ({
  ids = [],
  removeCoachesFromSOW,
  showModal,
  onHide,
  isSubmitting = false,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [coachesInfo, setCoachesInfo] = useState([])
  const { sowId } = useParams()
  const Error = useErrorService()

  useEffect(() => {
    const getRemoveCoachesMapping = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.companyList.getRemoveCoachesMapping(
          sowId,
          { coachIds: ids },
        )
        if (data) {
          setCoachesInfo(data)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (showModal) getRemoveCoachesMapping()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, ids, sowId])

  const renderSOWClientsTable = () => {
    return (
      <React.Fragment>
        <Table hover responsive className='custom-table'>
          <thead>
            <tr className='text-uppercase'>
              <th>Coach Name</th>
              <th>Clients</th>
            </tr>
          </thead>
          <tbody>
            {coachesInfo?.map((coach, index) => (
              <tr key={index}>
                <td className='d-flex'>
                  <Link to={`/admin/coaches/${coach?._id}`} target='_blank'>
                    {coach?.fullName}
                    {coach?.isStaffCoach ? (
                      <StaffCoachIcon classes='staff-coach-table-indicator ml-1' />
                    ) : null}
                  </Link>
                </td>
                <td>
                  <React.Fragment>
                    {coach?.clients?.map((client, index) => (
                      <React.Fragment key={index}>
                        <Link
                          to={`/admin/users/${client?.clientId?._id}`}
                          target='_blank'>
                          {client?.clientId?.fullName}
                        </Link>
                        <br />
                      </React.Fragment>
                    ))}
                    {coach?.clients?.length === 0 && <span>no clients.</span>}
                  </React.Fragment>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    )
  }

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      className='custom-modal remove-coaches'
      centered>
      <Modal.Body>
        <h3 className='s2 title'>Remove coaches from SOW</h3>
        {isLoading ? (
          <div className='text-center'>
            <FeatherLoader />
          </div>
        ) : (
          <React.Fragment>{renderSOWClientsTable()}</React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='w-100 d-flex'>
          <Button
            variant='none'
            size='md'
            className='w-100 border-radius-3 line-height-normal mr-3 secondary-popup-button'
            onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant='danger'
            size='md'
            className='w-100 border-radius-3 confirm-btn line-height-normal ml-3'
            isLoading={isSubmitting}
            disabled={isLoading || isSubmitting}
            onClick={() => removeCoachesFromSOW()}
            type='submit'>
            Remove
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default RemoveCoachesModal
