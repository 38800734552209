import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import CONSTANTS from '../../utils/constants'
import UpsertAttachAudio from '../common/UpsertAttachAudio'
import API from '../../utils/API'

const AttachSessionAudios = ({ audios, setAudios, disabled = false }) => {
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const { clientId } = useParams()
  const Error = useErrorService()

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        if (search !== '') {
          const { data } = await API.AdminAPI.common.getSearchMedia(
            search,
            'user',
            clientId,
          )
          if (data) {
            setSearchData(data)
            setIsSearch(false)
            // data.length > 0 && setMediaInfo(data)
          }
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (search === '' || search === undefined) {
      return
    } else {
      getSearchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <UpsertAttachAudio
      search={search}
      setSearch={setSearch}
      searchData={searchData}
      isSearch={isSearch}
      inputFocus={inputFocus}
      setInputFocus={setInputFocus}
      audios={audios}
      setAudios={setAudios}
      maxLimit={CONSTANTS.maxSessionAudioLimit}
      disabled={disabled}
    />
  )
}

export default AttachSessionAudios
