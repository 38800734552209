import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { sows } from '@abroad/components'

const SOWPlansList = ({ b2cPlans, b2bPlans }) => {
  const { companyName, ecoSystemName } = useParams()
  const url = companyName
    ? `organization/${companyName}`
    : `network/${ecoSystemName}`
  const sortedB2CPlans = b2cPlans.sort((a, b) =>
    a.planName.localeCompare(b.planName),
  )
  const sortedB2BPlans = b2bPlans.sort((a, b) =>
    a.planName.localeCompare(b.planName),
  )
  return (
    <div>
      {sortedB2CPlans?.map((plan, index) => (
        <Link
          to={`/coach/clients/${plan?.clientId}/plans/${plan?._id}`}
          key={index}
          target='_blank'>
          <span>{`${sows.prefix.individual}${plan?.planName}`}</span>
          <br />
        </Link>
      ))}
      {sortedB2BPlans?.map((sowPlan, index) => (
        <Link
          to={`/admin/${url}/sow/${sowPlan?._id}/users`}
          key={index}
          target='_blank'>
          <span>{`${sows.prefix.b2b}${sowPlan?.planName}`}</span>
          <br />
        </Link>
      ))}
    </div>
  )
}

export default SOWPlansList
