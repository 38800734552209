import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import {
  FeatherLoader,
  useErrorService,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { DeleteConfirmationPrompt } from './DeleteConfirmationPrompt'
import { convertSessionDate } from '../../utils/date'
import API from '../../utils/API'

const GroupSessionsTable = ({ isArchived }) => {
  const [showModal, setShowModal] = useState(false)
  const [isSessionDelete, setIsSessionDelete] = useState(false)
  const [sessionId, setSessionId] = useState(null)
  const [sessionList, setSessionList] = useState([])
  const [fetchSessionList, setFetchSessionList] = useState(true)
  const { sowGroupId, teamId } = useParams()
  const { user } = useUserService()
  const Notification = useNotificationService()
  const Error = useErrorService()

  useEffect(() => {
    const getSessionListData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getSessionListData(
          sowGroupId ? sowGroupId : teamId,
        )
        if (data) {
          setSessionList(data)
          setFetchSessionList(false)
        }
      } catch (e) {
        Error.showError(e)
        setFetchSessionList(false)
      }
    }
    getSessionListData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSessionDelete = async () => {
    setIsSessionDelete(true)
    try {
      const { data } = await API.AdminAPI.teams.sessionDelete(sessionId)
      if (data) {
        Notification.showNotification('session has been deleted.', 'success')
        setSessionList((prev) =>
          prev?.filter((team) => team?._id !== sessionId),
        )
        setIsSessionDelete(false)
        setShowModal(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsSessionDelete(false)
      setShowModal(false)
    }
  }

  return (
    <React.Fragment>
      <DeleteConfirmationPrompt
        showModal={showModal}
        setShowModal={setShowModal}
        isDeleteLoading={isSessionDelete}
        handleDelete={handleSessionDelete}
        message='This can not be reverted. Are you sure you want to delete this
    group session?'
      />
      <Table hover responsive className='custom-table mb-0'>
        <thead>
          <tr>
            <th className={isArchived ? 'w-4/12' : ''}>Type</th>
            <th className={isArchived ? 'w-4/12' : ''}>Date</th>
            <th className={isArchived ? 'w-4/12' : ''}>Taken by</th>
            {!isArchived && <th className='text-center'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {fetchSessionList && (
            <tr>
              <td colSpan='4' className='text-center border-0'>
                <FeatherLoader />
              </td>
            </tr>
          )}
          {sessionList.length > 0 &&
            !fetchSessionList &&
            sessionList.map((data) => (
              <tr key={data._id}>
                <td width='30%'>Group</td>
                <td width='30%'>
                  {isArchived ? (
                    convertSessionDate(data.date)
                  ) : (
                    <Link
                      to={`/admin/groups/${
                        sowGroupId ? sowGroupId : teamId
                      }/session/${data._id}`}
                      className='text-dark-600 under-line'>
                      {convertSessionDate(data.date)}
                    </Link>
                  )}
                </td>
                <td width='30%'>
                  {user?.isAdmin && !user?.isCoach
                    ? data?.coach?.fullName
                    : data?.coach?._id === user?._id
                    ? 'Taken by you'
                    : data?.coach?.fullName}
                </td>
                {!isArchived && (
                  <td className='text-center' width='10%'>
                    {data?.coach?._id === user?._id || user?.isAdmin ? (
                      <>
                        {/* eslint-disable */}
                        <a
                          href='javascript:void(0)'
                          onClick={() => {
                            setShowModal(true)
                            setSessionId(data._id)
                          }}
                          className='text-red-600 p-0'
                          variant='none'>
                          Delete
                        </a>
                      </>
                    ) : (
                      <p className='mb-0'>-</p>
                    )}
                    {/* eslint-enable */}
                  </td>
                )}
              </tr>
            ))}
          {sessionList.length === 0 && !fetchSessionList && (
            <tr>
              <td colSpan='5' className='text-center'>
                No Session.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default GroupSessionsTable
