import React, { useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  useErrorService,
  useNotificationService,
  useUserService,
  sows,
} from '@abroad/components'
import ShowTakenByCoachName from './ShowTakenByCoachName'
import { DeleteConfirmationPrompt } from '../common'
import { Table, TableInstance } from '../table'
import { convertSessionDate } from '../../utils/date'
import API from '../../utils/API'

const SessionsTable = ({
  data,
  sessionId,
  setSessionId,
  setFilterSessions,
  type,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [isSessionDelete, setIsSessionDelete] = useState(false)
  const { user } = useUserService()
  const { clientId } = useParams()
  const hasMore = useRef(null)
  const Error = useErrorService()
  const Notification = useNotificationService()

  const deleteSession = async () => {
    setIsSessionDelete(true)
    try {
      const { data } = await API.CoachAPI.coachingSession.deleteSession(
        sessionId,
      )
      if (data?.status) {
        setFilterSessions(true)
        Notification.showNotification(data?.message, 'success')
        setShowModal(false)
        setIsSessionDelete(false)
      }
    } catch (e) {
      setIsSessionDelete(false)
      Error.showError(e)
    }
  }

  const getUpdateSessionLink = (session) => {
    if (user?.isAdmin || session?.coach?._id === user?._id) {
      return session?.clientId ? (
        <Link
          className='text-dark-900 date-link font-bold'
          to={`/coach/clients/${clientId}/session/${session?._id}`}>
          {convertSessionDate(session?.date, 'MM/DD/YY')}
        </Link>
      ) : (
        <Link
          className='text-dark-900 date-link font-bold'
          to={`/admin/groups/${session?.teamId?._id}/session/${session?._id}`}>
          {convertSessionDate(session?.date, 'MM/DD/YY')}
        </Link>
      )
    }
    return (
      <span className='text-dark-900 font-bold'>
        {convertSessionDate(session?.date, 'MM/DD/YY')}
      </span>
    )
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'date',
        Header: 'Date',
        width: '24%',
        accessor: (properties) => properties,
        Cell: ({ cell }) => <span>{getUpdateSessionLink(cell?.value)}</span>,
      },
      {
        id: 'Type',
        Header: 'Type',
        width: '19%',
        accessor: (properties) => ({
          teamId: properties?.teamId,
          type: properties?.type,
        }),
        Cell: ({ cell }) => (
          <span>
            {cell?.value?.teamId
              ? 'Group'
              : type === sows.planType.b2b
              ? sows.label.b2b
              : sows.label.individual}
          </span>
        ),
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        disableSortBy: true,
      },
      {
        id: 'Coach',
        Header: 'Coach',
        width: '19%',
        accessor: (properties) => properties?.coach,
        Cell: ({ cell }) => (
          <ShowTakenByCoachName
            coach={cell?.value}
            userId={user?._id}
            isAdmin={user?.isAdmin}
          />
        ),
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        disableSortBy: true,
      },
      {
        id: 'Duration',
        Header: 'Duration',
        width: '19%',
        accessor: (properties) => properties?.length,
        Cell: ({ cell }) => <span>{`${cell?.value}m`}</span>,
        dataProps: {
          className: 'text-center',
        },
        headerProps: {
          className: 'text-center',
          wrapperClassName: 'd-flex-unset',
        },
        disableSortBy: true,
      },
      {
        id: 'Actions',
        Header: '',
        width: '19%',
        accessor: (properties) => ({
          coachId: properties?.coach?._id,
          sessionId: properties?._id,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {user?.isAdmin || cell?.value?.coachId === user?._id ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={() => {
                  setSessionId(cell?.value?.sessionId)
                  setShowModal(true)
                }}
                className='delete-link pointer-cursor'>
                Delete
              </a>
            ) : (
              '-'
            )}
          </React.Fragment>
        ),
        dataProps: {
          className: 'text-right delete-session',
        },
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className='client-sessions'>
      <DeleteConfirmationPrompt
        showModal={showModal}
        setShowModal={setShowModal}
        isDeleteLoading={isSessionDelete}
        handleDelete={deleteSession}
        message='This can not be reverted. Are you sure you want to delete this
    client session?'
      />
      <TableInstance
        columns={columns}
        data={data}
        initialState={{
          sortBy: [{ id: 'date', desc: false }],
        }}
        manualSortBy={false}>
        {({ ...rest }) => (
          <Table
            ref={hasMore}
            emptyTableMessage='No Sessions.'
            tableInstance={{
              ...rest,
            }}
            isLoading={false}
            fetchData={() => {}}
          />
        )}
      </TableInstance>
    </div>
  )
}

export default SessionsTable
