import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  useErrorService,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { UpdateClientTeamSession } from '../common'
import API from '../../utils/API'

export const UpdateSessionForm = ({
  sessionData,
  isDiffTeamCoach,
  url = null,
}) => {
  const [isSessionUpdateLoading, setIsSessionUpdateLoading] = useState(false)
  const [sessionInsights, setSessionInsights] = useState([])
  const [nonConfidentialsessionInsights, setNonConfidentialSessionInsights] =
    useState([])
  const [postSession, setPostSession] = useState([])
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [mediaInfo, setMediaInfo] = useState([])
  const [keySkillSearchData, setKeySkillSearchData] = useState([])
  const [searchKeySkillData, setSearchKeySkillData] = useState([])
  const [searchKeySkill, setSearchKeySkill] = useState('')
  const [inputFocusOfKeySkill, setInputFocusOfKeySkill] = useState(false)
  const [isSearchKeySkill, setIsSearchKeySkill] = useState(false)
  const [groupMembers, setGroupMembers] = useState(null)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const history = useHistory()
  const { user } = useUserService()
  const { teamId, sessionId } = useParams()

  useEffect(() => {
    setSessionInsights(sessionData?.coachNotes)
    setNonConfidentialSessionInsights(
      sessionData?.coachNotesNonConfidential !== undefined
        ? sessionData?.coachNotesNonConfidential
        : '',
    )
    setPostSession(
      sessionData?.postSession !== undefined ? sessionData?.postSession : '',
    )
    setSearch(sessionData?.media?.title)
    if (
      sessionData?.absentees?.length === 0 &&
      sessionData?.attendees?.length === 0
    ) {
      setGroupMembers(null)
    } else {
      setGroupMembers({
        present: sessionData?.attendees,
        absent: sessionData?.absentees,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        if (search !== '') {
          const { data } = await API.AdminAPI.common.getSearchMedia(
            search,
            'team',
          )
          if (data) {
            setSearchData(data)
            setIsSearch(false)
            data.length > 0 && setMediaInfo(data)
          }
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (search === '' || search === undefined) {
      return
    } else {
      getSearchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const getSearchData = () => {
      setIsSearchKeySkill(true)
      const data = keySkillSearchData.filter((data) =>
        data?.title?.toLowerCase()?.includes(searchKeySkill?.toLowerCase()),
      )

      setSearchKeySkillData(data)
      setIsSearchKeySkill(false)
    }
    getSearchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeySkill, keySkillSearchData])

  useEffect(() => {
    const getKeySkillData = async () => {
      try {
        const { data } = await API.AdminAPI.common.getKeySkillSearchPdf()
        if (data) {
          setKeySkillSearchData(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getKeySkillData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async ({ mediaIds, moduleIds, keySkillsIds }) => {
    if (sessionInsights.length === 0) {
      Notification.showNotification('Please Enter Session Insights!', 'danger')
    } else {
      let params = {
        coachNotes: sessionInsights,
        coachNotesNonConfidential:
          nonConfidentialsessionInsights.length === 0
            ? ''
            : nonConfidentialsessionInsights,
        postSession: postSession.length === 0 ? '' : postSession,
        mediaIds: mediaIds,
        moduleIds: moduleIds,
        keySkillIds: keySkillsIds,
      }

      search === mediaInfo[0]?.title
        ? setSearch(mediaInfo[0]?.title)
        : setSearch('')

      try {
        setIsSessionUpdateLoading(true)
        const { data } = await API.AdminAPI.teams.updateSession(
          sessionId,
          params,
        )
        if (data) {
          setIsSessionUpdateLoading(false)
          Notification.showNotification(
            'Group session has been updated!',
            'success',
          )
          if (user?.isAdmin && url) {
            history.push(url)
          } else {
            history.push(`/admin/groups/${teamId}/session-report`)
          }
        }
      } catch (e) {
        setIsSessionUpdateLoading(false)
        Error.showError(e)
      }
    }
  }

  return (
    <main className='py-3'>
      <UpdateClientTeamSession
        sessionData={sessionData}
        sessionInsights={sessionInsights}
        setSessionInsights={setSessionInsights}
        showCKCondition={sessionData?.coachId}
        isTeamSession={true}
        nonConfidentialsessionInsights={nonConfidentialsessionInsights}
        setNonConfidentialSessionInsights={setNonConfidentialSessionInsights}
        postSession={postSession}
        setPostSession={setPostSession}
        search={search}
        setSearch={setSearch}
        inputFocus={inputFocus}
        setInputFocus={setInputFocus}
        searchData={searchData}
        isSearch={isSearch}
        isSessionUpdateLoading={isSessionUpdateLoading}
        onSubmit={onSubmit}
        medias={[...sessionData?.medias, ...sessionData?.mediaModules]}
        isDiffTeamCoach={isDiffTeamCoach}
        searchKeySkill={searchKeySkill}
        setSearchKeySkill={setSearchKeySkill}
        searchKeySkillData={searchKeySkillData}
        isSearchKeySkill={isSearchKeySkill}
        inputFocusOfKeySkill={inputFocusOfKeySkill}
        setInputFocusOfKeySkill={setInputFocusOfKeySkill}
        keySkillPdfsData={sessionData?.keySkills}
        isRenderOnGroupSession={true}
        groupMembers={groupMembers}
      />
    </main>
  )
}
