import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Modal } from 'react-bootstrap'
import { Button } from '@abroad/components'
import SelectionField from './SelectionField'
import API from '../../utils/API'

const AssignCoachModal = ({
  fullName = null,
  handleAssignCoach,
  indiSOWCoachIds = [],
  isSubmitting,
  showModal,
  setShowModal,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [coach, setCoach] = useState('')
  const [coaches, setCoaches] = useState([])

  const onHide = () => {
    setShowModal(false)
    setCoaches([])
    setCoach('')
  }

  useEffect(() => {
    if (!showModal) {
      setCoaches([])
      setCoach('')
    }
  }, [showModal])

  useEffect(() => {
    setIsLoading(true)
    const getCoachData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getCoachData()
        if (data) {
          const filteredCoaches = data?.filter(
            (coach) => !indiSOWCoachIds.includes(coach?._id),
          )
          setCoaches(
            filteredCoaches?.map((coach) => ({
              label: `${coach?.fullName} ${
                coach?.isStaffCoach ? '(Staff Coach)' : ''
              }`,
              value: coach?._id,
              isStaffCoach: coach?.isStaffCoach || false,
              fullName: coach?.fullName,
              _id: coach?._id,
            })),
          )
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (showModal) getCoachData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  if (!showModal) return null

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      className='custom-modal normal-padding-modal'
      centered>
      <Modal.Body>
        <h3 className='s2 mb-4 text-center'>
          {`Assign a coach ${fullName ? `to ${fullName}` : ''}`}
        </h3>
        <SelectionField
          name='coachId'
          size='lg'
          options={coaches}
          getValueAsOption={true}
          optionplaceholder={isLoading ? 'Fetching Coaches...' : 'Select Coach'}
          sm='10'
          className={classNames('mb-3', {
            'bg-white cursor-pointer': !(isLoading || isSubmitting),
            'is-disabled': isLoading || isSubmitting,
          })}
          isUseFormikSelectionField={false}
          value={coach}
          onChange={(e) => setCoach(e.target.value)}
          disabled={isLoading || isSubmitting}
        />
        <Button
          variant='gray'
          className='line-height-normal'
          size='md'
          disabled={isLoading || !coach || isSubmitting}
          isLoading={isSubmitting}
          onClick={() => handleAssignCoach(coach, coaches)}>
          Assign
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default AssignCoachModal
