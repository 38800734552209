import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import classnames from 'classnames'

const SingleInputLabel = React.forwardRef(
  (
    {
      formGroupProps: {
        className: formGroupClassName,
        ...otherFormGroupProps
      } = {},
      id,
      children,
      label,
      labelProps,
      displayFlex = true,
      showTooltip = false,
      showTooltipMessage = false,
      dataTooltip,
      extraIcon,
      isDiscardWidth = false,
      divClasses = '',
      labelClasses = '',
      ...rest
    },
    ref,
  ) => {
    const renderLabel = () => {
      if (label && showTooltipMessage) {
        return (
          <OverlayTrigger
            key='top'
            placement='top'
            overlay={
              <Tooltip id={`tooltip-${rest.name}`} className='font-open-sans'>
                <div dangerouslySetInnerHTML={{ __html: dataTooltip }}></div>
              </Tooltip>
            }>
            <span {...labelProps} className='cursor-pointer'>
              {label}
              {extraIcon}
            </span>
          </OverlayTrigger>
        )
      } else if (label && !showTooltipMessage) {
        return (
          <span {...labelProps}>
            {label}
            {extraIcon}
          </span>
        )
      }
      return null
    }

    return (
      <label
        className={classnames(
          'd-flex position-relative align-self-center',
          labelClasses,
        )}
        style={{ height: '39px' }}>
        <div
          className={classnames(
            'align-items-center',
            {
              'w-100': !isDiscardWidth,
            },
            {
              'd-lg-flex': displayFlex,
            },
            divClasses,
          )}>
          {renderLabel()}
        </div>
      </label>
    )
  },
)

export default SingleInputLabel
