import moment from 'moment'
import { sows, plans as usersPlan } from '@abroad/components'
import labels from '../constants/labels'

export const isLGScreen = window.innerWidth > 1080 ? true : false
export const isSMScreen =
  window.innerWidth > 576 && window.innerWidth < 768 ? true : false
export const isMDScreen =
  window.innerWidth > 768 && window.innerWidth < 1080 ? true : false

export const getQuarter = (quater) => {
  if (quater === 1) {
    return 'JAN - MARCH'
  } else if (quater === 2) {
    return 'APRIL - JUNE'
  } else if (quater === 3) {
    return 'JULY - SEPTEMBER'
  } else {
    return 'OCTOBER - DECEMBER'
  }
}

export const isDatesOverlap = (rangeOne, rangeTwo) => {
  if (
    moment(rangeTwo.startDate).isBetween(
      rangeOne.startDate,
      rangeOne.endDate,
    ) ||
    moment(rangeTwo.endDate).isBetween(rangeOne.startDate, rangeOne.endDate) ||
    moment(rangeOne.startDate).isBetween(
      rangeTwo.startDate,
      rangeTwo.endDate,
    ) ||
    moment(rangeOne.endDate).isBetween(rangeTwo.startDate, rangeTwo.endDate) ||
    moment(rangeTwo.startDate).isSame(rangeOne.startDate) ||
    moment(rangeTwo.endDate).isSame(rangeOne.endDate) ||
    moment(rangeTwo.startDate).isSame(rangeOne.endDate) ||
    moment(rangeTwo.endDate).isSame(rangeOne.startDate)
  ) {
    return true
  }
  return false
}

export const prevMonthFirstDay = moment().subtract(1, 'months').startOf('month')

export const prevMonthLastDay = moment().subtract(1, 'months').endOf('month')

export const getDateRangeString = (startDate, endDate) => {
  return `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate)
    .subtract(1, 'days')
    .format('MM/DD/YYYY')}`
}

export const getFileName = (organizationCode, startDate, endDate) => {
  return `${organizationCode}_cyclestart_from_${moment(startDate).format(
    'MM-DD-YYYY',
  )}_to_${moment(endDate).subtract(1, 'days').format('MM-DD-YYYY')}.xlsx`
}

export const getNameArrayFromCompanies = (data) => {
  return data.map((e) => e.name)
}

export const roleList = {
  ic: 'Employee (Individual Contributor/Team Member, Student)',
  manager: 'First-Line Manager (Direct Manager of Individual Contributors)',
  leader: 'Middle Manager (Department Heads, Manager of First-Line Managers)',
  executive:
    'Top-Level Manager (Business Unit Leads, Executives/C-Suite, Partners)',
  ceo: 'CEO / FOUNDER / Board of Directors',
  none: 'No Titles or Hierarchy',
}

export const participantLevelsForGroup = {
  leader: 'Leader',
  executive: 'Executive',
  ceo: 'CEO or Board Member',
  ic: 'Individual Contributor',
  manager: 'Manager',
}

export const getGroupLabel = (value) => {
  let label
  switch (value) {
    case 1:
      label = 'Organization'
      break
    case 2:
      label = 'Network'
      break
    case 3:
      label = 'Promo Code'
      break
    case 4:
      label = 'Free Select'
      break
    case 5:
      label = 'Organization With SOW'
      break
    case 6:
      label = 'Network With SOW'
      break
    default:
      break
  }
  return label
}

export const plans = {
  [usersPlan.free.code]: usersPlan.free.name,
  [usersPlan.growth.code]: usersPlan.growth.name,
  [usersPlan.coaching.code]: usersPlan.coaching.name,
}

export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const columnNameForPL = [
  'IC',
  'Manager',
  'Leader',
  'Executive',
  'Board & CEO',
]

export const getMomentConvertedDateForSOW = (date) =>
  moment(date).format('MM/DD/YY')

export const removeEmpty = (values) => {
  return Object.fromEntries(
    Object.entries(values).filter(([_, v]) => v != null && v !== ''),
  )
}

export const getUnmaskedObject = (obj, unmaskFunction) =>
  Object.entries(obj).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]:
        typeof value === 'string' && value !== ''
          ? unmaskFunction(value)
          : value,
    }),
    {},
  )
export const isSOWGroups = (groupType) => {
  return (
    groupType === labels.allGroupType.COMPANY_WITH_SOW ||
    groupType === labels.allGroupType.NETWORK_WITH_SOW
  )
}

export const getManageGroupURL = (teamData, isadmin = false) => {
  const sowGroups = isSOWGroups(teamData?.groupType)
  const groupId = teamData?.groupId
  if (sowGroups && isadmin) {
    return `/admin/${
      teamData?.groupType === labels.allGroupType.COMPANY_WITH_SOW
        ? 'organization'
        : 'network'
    }/${groupId?.name}/sow/${teamData?.b2bSOWId}/sow-group/${teamData?._id}`
  }
  return `/admin/groups/${teamData?._id}/information`
}

export const getLoginUserCoachingPlan = (data, userId) => {
  const planType = sows.planType

  // individual plan
  const individualPlanData = data?.filter(
    (plan) => plan?.type === planType.individual && plan?.isCoachOfCurrentPlan, // type && isCoachOfCurrentPlan key
  )

  const updateIndividualPlans = individualPlanData.map((e) => ({
    label: `${sows.prefix.individual}${e.planName}`,
    value: e._id,
    type: sows.planType.individual,
    sessionDuration: e.sessionDuration,
  }))

  // B2B plan
  const b2bPlanData = data?.filter((plan) => plan?.type === planType.b2b) // type key

  const filterB2BPlans = b2bPlanData?.filter((plan) =>
    plan?.coaches?.map((coach) => coach?.coachId?._id).includes(userId),
  )

  const updateB2BPlans = filterB2BPlans.map((e) => ({
    label: `${sows.prefix.b2b}${e.planName}`,
    value: e._id,
    type: sows.planType.b2b,
    sessionDuration: e.sessionDuration,
  }))
  const labelValuesData = [...updateIndividualPlans, ...updateB2BPlans]
  const coachingPlanData = [...individualPlanData, ...b2bPlanData]
  return { labelValuesData, coachingPlanData }
}

export const sortByGivenKey = (array, key) => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) return -1
    if (a[key] > b[key]) return 1
    return 0
  })
}

export const checkEnteredTeamExist = (teams, enteredTeam) => {
  const isEnteredTeamExist = teams?.find(
    (team) => team?.name?.toLowerCase() === enteredTeam?.toLowerCase(),
  )
  return isEnteredTeamExist
}

export const getCoachingPlanObject = (data, status = null) => {
  let timeZoneKey
  timeZoneKey = labels.timeZoneList.filter(
    (timeZone) => timeZone.value === data?.plan?.timeZone,
  )
  return {
    status: status || data?.plan?.status,
    paymentStatus: data?.plan?.paymentStatus,
    planName: data?.plan?.planName || '',
    clientTimeZone: [
      {
        value: data?.plan?.timeZone,
        label: `${timeZoneKey[0]?.label} - ${data?.plan?.timeZone}`,
      },
    ],
    paymentCycle: data?.plan?.paymentCycle,
    initialFee: data?.plan?.initialFee,
    invoiceEvery: data?.plan?.invoiceEvery,
    numberOfCoachingCycle: data?.plan?.numberOfCoachingCycle || '',
    amount: data?.plan?.amount,

    sessionPerCoachingCycle: data?.plan?.sessionPerCoachingCycle,
    numberOfMonths: data?.plan?.numberOfMonths,
    sessionDuration: data?.plan?.sessionDuration,
    hourlyRate: data?.plan?.hourlyRate,
    firstSessionDate: moment(data?.plan?.firstSessionDate).format('MM/DD/YYYY'),

    report360:
      data?.plan?.report360 === null || data?.plan?.report360 === undefined
        ? false
        : true,
    reportPlan360:
      data?.plan?.report360 === null || data?.plan?.report360 === undefined
        ? ''
        : data?.plan?.report360,
    impactReport:
      data?.plan?.impactReport === null ||
      data?.plan?.impactReport === undefined
        ? false
        : true,
    impactReportPlan:
      data?.plan?.impactReport === null ||
      data?.plan?.impactReport === undefined
        ? ''
        : data?.plan?.impactReport,
    report360hourlyRate: data?.plan?.report360hourlyRate
      ? data?.plan?.report360hourlyRate
      : '',
    impactReporthourlyRate: data?.plan?.impactReporthourlyRate
      ? data?.plan?.impactReporthourlyRate
      : '',
  }
}

const filterUniqueIds = (array) => {
  const result = array.reduce((prevIds, id) => {
    if (id) {
      return prevIds.includes(id) ? prevIds : [...prevIds, id]
    } else {
      return prevIds
    }
  }, [])
  return result
}

export const getUniqueAudioAndSkillIds = (audios, keySkills) => {
  if (!audios || !keySkills) {
    return {
      moduleIds: [],
      mediaIds: [],
      keySkillsIds: [],
    }
  }
  let wrapper = {}
  let modulesArr = audios?.values.filter((e) => 'parentId' in e)
  let mediasArr = audios?.values.filter((e) => !('parentId' in e))
  //module
  let moduleIds = modulesArr?.map((e) => e._id)
  const uniqueModuleIds = filterUniqueIds(moduleIds)
  wrapper.moduleIds = uniqueModuleIds
  //media
  let mediaIds = mediasArr?.map((e) => e._id)
  const uniqueMediaIds = filterUniqueIds(mediaIds)
  wrapper.mediaIds = uniqueMediaIds
  //key skills
  let keySkillsIds = keySkills?.values?.map((e) => e._id)
  const uniqueSkillsIds = filterUniqueIds(keySkillsIds)
  wrapper.keySkillsIds = uniqueSkillsIds

  return wrapper
}

export const getStatusDefaultFilterValue = () => {
  const anyOptionObj = labels.statusFilterOptions.find(
    (value) => value.key === labels.statusFilterOptionsType.ANY,
  )
  return anyOptionObj?.key
}
