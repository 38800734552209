import React from 'react'
import { Container } from 'react-bootstrap'
import SwiperCore, { Controller, History } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { useResolutionService } from '@abroad/components'
import { ABSwiperSlider } from '@abroad/components/dist/slider'
import Intentions from './Intentions'

SwiperCore.use([Controller, History])
const swiperRef = React.createRef()

const GrowthPlan = () => {
  const { isSMScreen } = useResolutionService()
  return (
    <main>
      <section>
        <Container
          className='mt-0 mt-lg-4 d-lg-flex d-none justify-content-center align-items-center px-lg-0'
          fluid>
          <h3 className='font-montserrat'>INTENTIONS & INSIGHTS</h3>
        </Container>
      </section>
      <section className='d-none d-md-block'>
        <Intentions />
      </section>
      <section className='d-block position-relative d-md-none'>
        <ABSwiperSlider
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            576: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper
          }}
          arrowProps={{
            bgColor: '#646464',
            bgOpacity: 1,
            className: 'position-absolute',
            style: {
              top: '2rem',
            },
          }}>
          <SwiperSlide>
            <div>
              <div className='px-5 pt-1 font-medium text-saffron-700 h-auto gp-label text-center font-montserrat'>
                INTENTIONS & INSIGHTS
                <hr className='w-25' />
              </div>
              {isSMScreen && <Intentions />}
            </div>
          </SwiperSlide>
        </ABSwiperSlider>
      </section>
    </main>
  )
}

export default GrowthPlan
