import React, { useState } from 'react'
import { Form as RBFrom, Row, Col, Container } from 'react-bootstrap'
import {
  Button,
  useNotificationService,
  useErrorService,
} from '@abroad/components'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { string, object } from 'yup'
import { InputField } from '../form'
import { isLGScreen } from '../../utils/utility'
import API from '../../utils/API'

const UpdatePasswordForm = ({ coachId }) => {
  let history = useHistory()
  const Notification = useNotificationService()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = object().shape({
    currentPassword: string().required('\0'),
    password: string()
      .matches(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()-=_+\\[\\]{};\':",./<>?\\\\|]).{8,}$',
        '',
      )
      .required('Required'),
    confirmPassword: string()
      .test(
        'password-match',
        'Please Make Sure The Passwords Match',
        function (value) {
          const { password } = this.parent
          return password === value
        },
      )
      .required('\0'),
  })

  const onSubmit = async (values, action) => {
    try {
      setIsLoading(true)
      await API.AdminAPI.userList.updatePassword(
        {
          currentPassword: values.currentPassword,
          password: values.password,
        },
        coachId,
      )
      setIsLoading(false)
      Notification.showNotification('Password has been updated!', 'success')
      history.goBack()
    } catch (e) {
      if (e.code === 'invalid_password') {
        Notification.showNotification(e.message)
      } else {
        Error.showError(e)
      }
      setIsLoading(false)
    }
  }

  return (
    <Container fluid className='custom-offset-lg-1 custom-col-lg-12 px-0'>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          password: '',
          confirmPassword: '',
          currentPassword: '',
        }}
        onSubmit={onSubmit}>
        {({ handleSubmit }) => {
          return (
            <RBFrom as={Form} className='profile pt-5'>
              <Row noGutters xs={1} sm={1} md={1} lg={2}>
                <Col className='col-lg-2 d-flex justify-content-start justify-content-lg-end align-items-center'>
                  <RBFrom.Group className={isLGScreen ? '' : 'mb-0'}>
                    <RBFrom.Label column={isLGScreen ? true : false}>
                      Current Password
                    </RBFrom.Label>
                  </RBFrom.Group>
                </Col>
                <Col className='col-lg-10 pr-lg-0'>
                  <InputField
                    name='currentPassword'
                    type='password'
                    placeholder='Current Password'
                    size='lg'
                    sm='10'
                  />
                </Col>
              </Row>
              <Row noGutters xs={1} sm={1} md={1} lg={2}>
                <Col className='col-lg-2 d-flex justify-content-start justify-content-lg-end align-items-center'>
                  <RBFrom.Group className={isLGScreen ? '' : 'mb-0'}>
                    <RBFrom.Label column={isLGScreen ? true : false}>
                      Password
                    </RBFrom.Label>
                  </RBFrom.Group>
                </Col>
                <Col className='col-lg-10 pr-lg-0'>
                  <InputField
                    name='password'
                    type='password'
                    placeholder='Password'
                    size='lg'
                    sm='10'
                  />
                </Col>
              </Row>
              <Row noGutters xs={1} sm={1} md={1} lg={2}>
                <Col className='col-lg-2 d-flex justify-content-start justify-content-lg-end align-items-center'>
                  <RBFrom.Group className={isLGScreen ? '' : 'mb-0'}>
                    <RBFrom.Label column={isLGScreen ? true : false}>
                      Confirm password
                    </RBFrom.Label>
                  </RBFrom.Group>
                </Col>
                <Col className='col-lg-10 pr-lg-0'>
                  <InputField
                    name='confirmPassword'
                    type='password'
                    placeholder='Confirm password'
                    size='lg'
                    sm='10'
                  />
                </Col>
              </Row>
              <div className='text-right'>
                <Button
                  onClick={handleSubmit}
                  isLoading={isLoading}
                  disabled={isLoading}
                  type='submit'
                  size='md'
                  className='line-height-normal text-uppercase border-radius-3'
                  variant='gray'>
                  Update
                </Button>
              </div>
            </RBFrom>
          )
        }}
      </Formik>
    </Container>
  )
}

export default UpdatePasswordForm
