import React, { useCallback, useEffect, useState } from 'react'
import { FeatherLoader, useErrorService } from '@abroad/components'
import { ABSelect } from '../common'
import CONSTANTS from '../../utils/constants'
import API from '../../utils/API'

const ModuleMediaAccordion = React.lazy(() => import('./ModuleMediaAccordion'))

const SelectMediaDropdown = ({ journeyType }) => {
  const defaultSelectedCategory = 'breakthrough'
  const [currentCategorySelected, setCurrentCategorySelected] = useState(
    defaultSelectedCategory,
  )
  const [moduleMediaDetails, setModuleMediaDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { showError } = useErrorService()

  const updateCategory = useCallback((e) => {
    setCurrentCategorySelected(e)
  }, [])

  const getApiURL = useCallback(() => {
    let path
    switch (currentCategorySelected) {
      case 'breakthrough':
        path = API.ClientAPI.programs.getPrograms('5fddc237a483be3860dea6f2')
        break
      case 'evolution':
        path = API.ClientAPI.programs.getPrograms('60e885f48ea7c31048bc553d')
        break
      case 'resilience':
        path = API.ClientAPI.programs.getPrograms('639970530a71c612b0026943')
        break
      // case 'essentials':
      //   path = API.ClientAPI.programs.getPrograms('639c067da4d9940fd87f216d')
      //   break
      case 'self awareness':
        path = API.ClientAPI.mediaLibrary.getMediaByCategory(
          '5b46b176ce416b175ff98e17',
        )
        break
      case 'team cohesion':
        path = API.ClientAPI.mediaLibrary.getMediaByCategory(
          '5b46b176ce416b175ff98e2f',
        )
        break
      case 'vision & impact':
        path = API.ClientAPI.mediaLibrary.getMediaByCategory(
          '5b46b176ce416b175ff98de6',
        )
        break
      // case 'mindfulness & somatics':
      //   path = API.ClientAPI.mediaLibrary.getMediaByCategory(
      //     '60eef62e0c2ca67244cbdb9a',
      //   )
      //   break
      default:
        break
    }
    return path
  }, [currentCategorySelected])

  useEffect(() => {
    const updateDropdownOption = async () => {
      setIsLoading(true)
      try {
        const { data } = await getApiURL()
        setModuleMediaDetails(data)
      } catch (e) {
        showError(e)
      }
      setIsLoading(false)
    }

    updateDropdownOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategorySelected])

  return (
    <div className='media-dropdown my-3'>
      <ABSelect
        name='media-search'
        options={CONSTANTS.JOURNEYS_CATEGORY}
        setFirstDefault={true}
        onChange={(e) => updateCategory(e.value)}
        portal={null}
      />
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center my-3'>
          <FeatherLoader />
        </div>
      )}
      {!isLoading && (
        <div className='my-3'>
          <ModuleMediaAccordion
            moduleMediaDetails={moduleMediaDetails}
            journeyType={journeyType}
            dropDownSelected={
              [
                'breakthrough',
                'evolution',
                'resilience',
                'essentials',
              ].includes(currentCategorySelected)
                ? 'module'
                : 'media'
            }
            isBreakthroughOrEvoModule={[
              'breakthrough',
              'evolution',
              'resilience',
              'essentials',
            ].includes(currentCategorySelected)}
            currentCategorySelected={currentCategorySelected}
          />
        </div>
      )}
    </div>
  )
}

export default SelectMediaDropdown
