import React from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'
import classnames from 'classnames'

const SingleInputField = React.forwardRef(
  (
    {
      formGroupProps: {
        className: formGroupClassName,
        ...otherFormGroupProps
      } = {},
      children,
      id,
      label,
      labelProps,
      renderChildren,
      className,
      displayFlex = true,
      showTooltip,
      customErrorMessage,
      showErrorMessage,
      type,
      placeholder,
      removeFormikField,
      dataTooltip,
      showTooltipMessage = false,
      extraIcon,
      onFocus,
      hasErrorMessagePadding = true,
      renderLink = false,
      linkWrapperClasses = '',
      linkLabel = 'Delete',
      linkClickHandler = () => {},
      inputClasses = '',
      divClasses = '',
      ...rest
    },
    ref,
  ) => {
    const [field, meta] = useField({ ...rest, ...{ type: type } })

    const renderErrorMessages = (textColorClass = 'text-error') => {
      if (!showErrorMessage) {
        return null
      }
      if (customErrorMessage) {
        return customErrorMessage
      }
      return (
        <Form.Text
          className={classnames(
            'text-muted font-montserrat font-semibold font-12',
            { 'px-1': hasErrorMessagePadding },
          )}>
          {customErrorMessage ? (
            customErrorMessage
          ) : (
            <ErrorMessage
              component='span'
              name={rest?.name}
              className={classnames(
                'font-montserrat font-semibold transition',
                { 'px-1': hasErrorMessagePadding },
                textColorClass,
                className,
              )}
            />
          )}
        </Form.Text>
      )
    }

    return (
      <Form.Group
        controlId={id || `control-${rest?.name}-${Math.random()}`}
        className={classnames(formGroupClassName)}
        {...otherFormGroupProps}>
        {renderErrorMessages()}
        <span className='d-flex position-relative align-items-center'>
          <div
            className={classnames(
              'align-items-center w-100',
              {
                'd-lg-flex': displayFlex,
              },
              divClasses,
            )}>
            <Form.Control
              ref={ref}
              className={classnames(
                'border-radius-5 transition user-input abroad-fs-exclude',
                className,
                inputClasses,
              )}
              isValid={meta.touched && !meta.error}
              isInvalid={meta.touched && meta.error}
              onFocus={onFocus}
              type={type}
              placeholder={placeholder}
              {...(!removeFormikField ? field : {})}
              {...rest}>
              {children || renderChildren}
            </Form.Control>
          </div>
        </span>
      </Form.Group>
    )
  },
)

SingleInputField.defaultProps = {
  showTooltip: false,
  removeFormikField: false,
  type: 'text',
  showErrorMessage: false,
}

SingleInputField.propTypes = {
  removeFormikField: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
}

export default SingleInputField
