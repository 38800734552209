import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTeamReducer, initialTeamState } from './Reducer'

const AddNewTeamIcon = ({ disabled }) => {
  const maximumTeamCreated = 50
  const { state, dispatch } = useTeamReducer()
  // eslint-disable-next-line no-unused-vars
  const [isDisabled, setIsDisabled] = useState(disabled)

  const addNewTeamInput = () => {
    if (!isDisabled) {
      //call add new team
      dispatch({
        type: 'UPDATE_TEAMS',
        payload: initialTeamState,
      })
      const inputId = document.getElementById('edit-team-inputs')
      setTimeout(
        () =>
          inputId.scrollTo({
            top: inputId.scrollHeight,
            behavior: 'smooth',
          }),
        250,
      )
    }
  }

  useEffect(() => {
    if (maximumTeamCreated <= state?.teams?.length) {
      setIsDisabled(true)
    }
    if (state?.teams?.length === 0) {
      addNewTeamInput()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.teams?.length])

  return (
    <div
      className={classNames('m-0', {
        'add-team': !isDisabled,
      })}>
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classNames(
          'm-0',
          {
            'cursor-disabled disable': isDisabled,
          },
          {
            'pointer-cursor': !isDisabled,
          },
        )}
        onClick={addNewTeamInput}>
        <rect width='32' height='32' rx='3' fill='black' fillOpacity='0.7' />
        <line
          x1='16'
          y1='9'
          x2='16'
          y2='23'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <line
          x1='9'
          y1='16'
          x2='23'
          y2='16'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </svg>
    </div>
  )
}

export default AddNewTeamIcon
