import React, { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useErrorService, NotificationContext } from '@abroad/components'
import { SelectionOptions } from '../common/SelectionField'
import { UserListDataContext } from './Users'
import API from '../../utils/API'

const UserCompany = ({ workStatus, userComapany, userId, email, ...rest }) => {
  const { companies } = useContext(UserListDataContext)
  const [selectedCompany, setSelectedCompany] = useState({
    disabled: true,
    company: userComapany,
  })

  const Error = useErrorService()
  const { showNotification } = useContext(NotificationContext)

  const handleCompanyUpdate = async (e) => {
    setSelectedCompany({
      disabled: true,
      company: e.target.value,
    })
    if (
      window.confirm(
        `Do you want to change the company of ${email} to ${e.target.value}`,
      )
    ) {
      try {
        const { data } = await API.AdminAPI.userList.updateUserCompany(
          {
            company: e.target.value,
          },
          userId,
        )
        if (data) {
          setSelectedCompany((prev) => ({
            ...prev,
            disabled: false,
          }))
          showNotification('User company has been updated!', 'success')
        }
      } catch (e) {
        setSelectedCompany({
          disabled: false,
          company: userComapany,
        })
        Error.showError(e)
      }
    } else {
      setSelectedCompany({
        disabled: false,
        company: userComapany,
      })
    }
  }

  return (
    <Form.Control
      as='select'
      type='text'
      placeholder='Select One'
      size='md'
      className='pointer-cursor'
      onChange={handleCompanyUpdate}
      value={selectedCompany.company ? selectedCompany.company : ''}
      {...rest}>
      <option disabled value=''>
        {companies.isLoading
          ? 'Fetching Organization...'
          : 'Select Organization'}
      </option>
      <SelectionOptions options={companies.companies} />
    </Form.Control>
  )
}

export default UserCompany
