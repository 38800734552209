import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { FeatherLoader } from '@abroad/components'
import AttachSessionAudios from './AttachSessionAudios'
import AttachKeyResources from './AttachKeyResources'
import CONSTANTS from '../../utils/constants'
import { getUniqueAudioAndSkillIds } from '../../utils/utility'

const AttachAudioResource = ({
  growthPlanData,
  setAudioResource,
  setSaveResourcePdf,
  disabled = false,
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const [audios, setAudios] = useState({ values: [{}] })

  const [keySkills, setKeySkills] = useState({ values: [{}] })

  useState(() => {
    if (isLoading) {
      const medias = []
      if (growthPlanData?.mediaModules) {
        medias.push(...growthPlanData?.mediaModules)
      }
      if (growthPlanData?.medias) {
        medias.push(...growthPlanData?.medias)
      }

      if (medias.length < CONSTANTS.maxSessionAudioLimit && !disabled) {
        setAudios({ values: [...medias, {}] })
      } else {
        setAudios({ values: [...medias] })
      }

      const keyResource = []
      if (growthPlanData?.keySkills) {
        keyResource.push(...growthPlanData?.keySkills)
      }

      if (keyResource?.length < CONSTANTS.maxLimitForKeySkill && !disabled) {
        setKeySkills({ values: [...keyResource, {}] })
      } else {
        setKeySkills({ values: [...keyResource] })
      }
      setIsLoading(false)
    }
  }, [isLoading, growthPlanData])

  const getSaveResourcePdf = () => {
    let modulesArr = audios.values.filter((e) => 'parentId' in e)
    let mediasArr = audios.values.filter((e) => !('parentId' in e))

    const selectedMediaModulesArr = modulesArr
      ? modulesArr.filter((value) => Object.keys(value).length !== 0)
      : []
    const selectMediasArr = mediasArr
      ? mediasArr.filter((value) => Object.keys(value).length !== 0)
      : []
    const selectKeySkillsArr = keySkills?.values
      ? keySkills?.values.filter((value) => Object.keys(value).length !== 0)
      : []

    const mediaModules = [
      ...new Map(
        selectedMediaModulesArr.map((item) => [item['_id'], item]),
      ).values(),
    ]

    const medias = [
      ...new Map(selectMediasArr.map((item) => [item['_id'], item])).values(),
    ]

    const keySkillsArr = [
      ...new Map(
        selectKeySkillsArr.map((item) => [item['_id'], item]),
      ).values(),
    ]

    setSaveResourcePdf({
      mediaModules,
      medias,
      keySkills: keySkillsArr,
    })
  }

  useEffect(() => {
    getSaveResourcePdf()
    const wrapper = getUniqueAudioAndSkillIds(audios, keySkills)
    setAudioResource(wrapper)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audios, keySkills])

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <FeatherLoader />
      </div>
    )
  }

  return (
    <Container fluid className='profile'>
      {audios?.values?.length > 0 && (
        <Row>
          <Col
            lg={2}
            md={12}
            sm={12}
            className='d-lg-flex justify-content-lg-end align-items-lg-center p-lg-0'>
            <Form.Label className='form-label'>ATTACH AUDIO</Form.Label>
          </Col>
          <Col>
            <AttachSessionAudios
              audios={audios}
              setAudios={setAudios}
              disabled={disabled}
            />
          </Col>
        </Row>
      )}
      {keySkills?.values?.length > 0 && (
        <Row className='mt-3'>
          <Col
            lg={2}
            md={12}
            sm={12}
            className='d-lg-flex justify-content-lg-end align-items-lg-center p-lg-0'>
            <Form.Label className='form-label text-uppercase'>
              Attach Key Resource
            </Form.Label>
          </Col>
          <Col>
            <AttachKeyResources
              keySkills={keySkills}
              setKeySkills={setKeySkills}
              disabled={disabled}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default AttachAudioResource
