import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const LeftArrowIcon = React.forwardRef(
  (
    { className, style, bgColor, bgOpacity, removeSwiperClass, ...rest },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        className={classnames(
          {
            'swiper-button-prev': !removeSwiperClass,
          },
          className,
        )}
        xmlns='http://www.w3.org/2000/svg'
        width='42'
        height='42'
        viewBox='0 0 42 42'
        style={style}>
        <g
          id='Group_340'
          data-name='Group 340'
          transform='translate(-403 -1161)'>
          <circle
            id='Ellipse_18'
            data-name='Ellipse 18'
            cx='21'
            cy='21'
            r='21'
            transform='translate(445 1203) rotate(180)'
            fill={bgColor || '#fff'}
            opacity={bgOpacity || '0.45'}
          />
          <line
            id='Left_Panel_Close_2'
            data-name='Left Panel Close 2'
            x1='12.764'
            y1='8.711'
            transform='translate(428.441 1191.217) rotate(180)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeWidth='1.5'
          />
          <line
            id='Left_Panel_Close_2-2'
            data-name='Left Panel Close 2'
            x1='12.764'
            y2='8.711'
            transform='translate(428.441 1182.506) rotate(180)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeWidth='1.5'
          />
        </g>
      </svg>
    )
  },
)

LeftArrowIcon.propTypes = {
  removeSwiperClass: PropTypes.bool,
}

LeftArrowIcon.defaultProps = {
  removeSwiperClass: false,
}

export default LeftArrowIcon
