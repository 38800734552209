import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { CSVLink } from 'react-csv'
import classNames from 'classnames'
import API from '../../utils/API'
import { Button, useErrorService } from '@abroad/components'

const headers = [
  { label: 'First Name', key: 'fname' },
  { label: 'Last Name', key: 'lname' },
  { label: 'Email', key: 'email' },
  { label: 'Plan Code', key: 'planCode' },
  { label: 'Organization', key: 'company' },
  { label: 'Network', key: 'affiliation' },
  { label: 'Promo Code', key: 'promoCode' },
  { label: 'Created At', key: 'createdAt' },
  { label: 'Survey Taken', key: 'surveyTaken' },
  { label: 'Work Status', key: 'workStatus' },
]

const ExportUser = ({ hasUsersData, showAddUserBtn = false }) => {
  const {
    values: {
      filterBySearch,
      filterByAffiliation,
      filterByCompany,
      filterByPromoCode,
      filterByPlan,
      //filterByCoachId,
    },
  } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const [exportData, setExportData] = useState([])
  const Error = useErrorService()

  const handleExportUser = async () => {
    setIsLoading(true)

    const params = {
      company: filterByCompany,
      affiliation: filterByAffiliation,
      promoCode: filterByPromoCode,
      planCode: filterByPlan ? JSON.parse(filterByPlan).value : '',
      search: filterBySearch,
      //coachId: filterByCoachId,
    }

    Object.keys(params).forEach(
      (key) => params[key] === '' && delete params[key],
    )

    try {
      const { data: userData } = await API.AdminAPI.userList.exportUserCSV(
        params,
      )
      if (userData) {
        setExportData(userData)
        document.getElementById('EXPORT_USER').click()
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <div className='d-flex'>
      {(filterByAffiliation !== '' ||
        filterByCompany !== '' ||
        filterByPlan !== '' ||
        filterBySearch !== '' ||
        filterByPromoCode !== '') && (
        //|| filterByCoachId !== ''
        <Button
          variant='gray'
          className={classNames('line-height-normal border-radius-3', {
            'd-none': !hasUsersData,
            'ml-2': hasUsersData,
          })}
          isLoading={isLoading}
          disabled={isLoading || !hasUsersData}
          onClick={() => handleExportUser({ filename: 'users.csv' })}>
          EXPORT USER
        </Button>
      )}
      <CSVLink
        data={exportData}
        className='d-none'
        id='EXPORT_USER'
        filename='users.csv'
        headers={headers}>
        EXPORT USER
      </CSVLink>
    </div>
  )
}

export default ExportUser
