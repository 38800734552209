import React, { useEffect } from 'react'
import { UpdatePasswordForm } from '../components/coachProfile'
import { useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../components/common'

const UpdatePassword = () => {
  let { state } = useLocation()
  let history = useHistory()

  useEffect(() => {
    if (!state?.fullName || !state?.email) {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, history])

  return (
    <>
      <Helmet>
        <title>{'Update Password | Abroad'}</title>
        <meta name='title' content={'Update Password | Abroad'}></meta>
        <meta property='og:title' content={'Update Password | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Update Password | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/profile/update-password`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/profile/update-password`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/profile/update-password`}></meta>
      </Helmet>
      <main className='pb-5 mt-5'>
        <PageHeader
          titleText={`${state?.fullName}`}
          descriptionFn={() => {
            return <p className='font-montserrat font-medium'>{state?.email}</p>
          }}
          colClasses='px-0'
        />
        <section className='pt-5'>
          <UpdatePasswordForm coachId={state?.coachId} />
        </section>
      </main>
    </>
  )
}

export default UpdatePassword
