import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import {
  faUser,
  faBuilding,
  faSitemap,
  faChalkboardTeacher,
  faUsers,
  faFileAlt,
  faPercent,
  faEnvelopeSquare,
  faCalculator,
  faMoneyCheckAlt,
} from '@fortawesome/free-solid-svg-icons'
import { ABSpinner, useErrorService } from '@abroad/components'
import { CountCard, PageHeader } from '../components'
import API from '../utils/API'

const Home = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const Error = useErrorService()

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data } = await API.AdminAPI.home.getUserInfo()
        if (data) {
          setData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }
  return (
    <>
      <Helmet>
        <title>{'Home | Abroad'}</title>
        <meta name='title' content={'Home | Abroad'}></meta>
        <meta property='og:title' content={'Home | Abroad'}></meta>
        <meta property='twitter:title' content={'Home | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/home`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/home`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/home`}></meta>
      </Helmet>
      <main>
        <PageHeader
          titleText='home'
          colClasses='px-0'
          titleClasses='sentence-case-text'
        />
        <section className='custom-offset-lg-1 custom-col-lg-12 px-0'>
          <Container className='pt-5 px-0' fluid>
            <Row>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faUser}
                  backgroundColor='#f39734'
                  url='/admin/users'
                  info='Users'
                  total={data?.usersCount}
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faBuilding}
                  backgroundColor='#5FA35A'
                  url='/admin/organizations'
                  info='Organizations'
                  total={data?.companiesCount}
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faSitemap}
                  backgroundColor='#DC5041'
                  url='/admin/networks'
                  info='Network'
                  total={data?.ecosystemCount}
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faChalkboardTeacher}
                  backgroundColor='#42B2C7'
                  url='/admin/coaches'
                  info='Coaches'
                  total={data?.coachesCount}
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faUsers}
                  backgroundColor='#D14667'
                  url='/admin/groups'
                  info='Groups'
                  total={data?.teamsCount}
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faFileAlt}
                  backgroundColor='#9B5DB0'
                  url='/admin/promo'
                  info='Active Promo codes'
                  total={data?.promoCount}
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faPercent}
                  backgroundColor='#8ee4af'
                  url=''
                  info='Assessment'
                  total={data?.assessmentCount}
                  linkDisabled={true}
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faEnvelopeSquare}
                  backgroundColor='#e7717d'
                  url='/admin/scheduled-emails'
                  info='Email Sequencing'
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faCalculator}
                  backgroundColor='#116466'
                  url='/admin/account'
                  info='Account'
                />
              </Col>
              <Col lg={4} md={6} className='px-lg-1'>
                <CountCard
                  icon={faMoneyCheckAlt}
                  backgroundColor='#5ab9ea'
                  url='/admin/payment'
                  info='Payment'
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default Home
