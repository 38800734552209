import React, { useContext, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { Button, useErrorService } from '@abroad/components'
import { useInput } from '../common'
import API from '../../utils/API'
import { OrgEcoIdsContext } from '../../utils/contexts'

const CreateSOWGroupModal = ({
  showModal,
  setShowModal,
  orgEcoId,
  orgEcoType,
  showCreateGroupSOWModal,
  setShowCreateGroupSOWModal,
  closeClickedHandler = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [coaches, setCoaches] = useState([])
  const isValidGroupName = (value) =>
    value.match(/^[a-z\d\-_\s]+$/i) && value !== '' && value.length <= 100 //Regex for allowing alphanumeric,-,_ and space, same as create SOW
  const history = useHistory()
  const Error = useErrorService()
  const { sowId, companyName, ecoSystemName } = useParams()
  const {
    value: groupName,
    isValid: groupNameIsValid,
    hasError: groupNameHasError,
    valueChangeHandler: groupNameChangeHandler,
    inputBlurHandler: groupNameBlurHandler,
    reset: resetGroupName,
  } = useInput(isValidGroupName)
  const { companyAndEcoInfo } = useContext(OrgEcoIdsContext)

  const {
    value: groupCoach,
    isValid: groupCoachIsValid,
    hasError: groupCoachHasError,
    valueChangeHandler: groupCoachChangeHandler,
    inputBlurHandler: groupCoachBlurHandler,
    reset: resetGroupCoach,
  } = useInput((value) => value !== '')

  useEffect(() => {
    const getCoachData = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getSOWCoaches(sowId)
        if (data) {
          setCoaches(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (showModal) getCoachData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const checkGroupNameExist = async () => {
    setIsSubmitting(true)
    try {
      const { status } = await API.AdminAPI.common.checkDuplicateGroupName({
        name: groupName,
        groupId: companyAndEcoInfo?._id,
      })

      if (status === 200) {
        setIsSubmitting(false)
        return false
      }
      setIsSubmitting(false)
      return true
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
      return true
    }
  }

  const submitHandler = async (event) => {
    event.preventDefault()

    if (!groupNameIsValid) {
      return
    }
    const isGroupNameExist = await checkGroupNameExist()
    if (isGroupNameExist) return
    setShowCreateGroupSOWModal(false)
    if (!groupCoachIsValid) {
      return
    }

    try {
      setIsSubmitting(true)
      const { data } = await API.AdminAPI.companyList.createSOWGroup(sowId, {
        name: groupName,
        coachIds: [groupCoach],
        groupId: orgEcoId,
        groupType: orgEcoType,
      })
      if (data) {
        setIsSubmitting(false)
        onHide()
        closeClickedHandler()
        history.push(
          `/admin/${
            companyName
              ? `organization/${companyName}`
              : `network/${ecoSystemName}`
          }/sow/${sowId}/sow-group/${data?.sowGroupId}`,
        )
      }
    } catch (e) {
      Error.showError(e)
      setIsSubmitting(false)
    }
  }

  const onHide = () => {
    setShowModal(false)
    setShowCreateGroupSOWModal(true)
    resetGroupName()
    resetGroupCoach()
  }

  return (
    <Modal
      show={showModal}
      size='lg'
      onHide={onHide}
      className='custom-modal'
      centered>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          {showCreateGroupSOWModal && (
            <React.Fragment>
              <h3 className='s2 mb-4'>Create Group Name</h3>
              <Form.Control
                name='groupName'
                type='text'
                placeholder='Group name'
                value={groupName}
                onChange={groupNameChangeHandler}
                onBlur={groupNameBlurHandler}
                className='s6 border-radius-3'
                isInvalid={groupNameHasError}
                size='lg'
                sm='10'
              />
            </React.Fragment>
          )}
          {!showCreateGroupSOWModal && (
            <React.Fragment>
              <h3 className='s2 mb-4'>Assign Group Coach</h3>
              <Form.Control
                name='groupCoach'
                as='select'
                type='text'
                value={groupCoach}
                onChange={groupCoachChangeHandler}
                onBlur={groupCoachBlurHandler}
                className='s6 border-radius-3 bg-white'
                isInvalid={groupCoachHasError}
                size='lg'
                sm='10'>
                <option disabled value=''>
                  Select group coach
                </option>
                {coaches?.map((coach) => (
                  <option key={coach?._id} value={coach?._id}>
                    {coach?.fullName}
                    {coach?.isStaffCoach ? ' (Staff Coach)' : ''}
                  </option>
                ))}
              </Form.Control>
            </React.Fragment>
          )}
          <div className='text-right'>
            <Button
              variant='saffron'
              className='text-uppercase border-radius-3 mt-5 line-height-normal w-6/12'
              isLoading={isSubmitting}
              disabled={
                showCreateGroupSOWModal
                  ? groupName === '' || isSubmitting
                  : groupCoach === '' || isSubmitting
              }
              type='submit'>
              {showCreateGroupSOWModal ? 'Next' : 'Finish'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateSOWGroupModal
