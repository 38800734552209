import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { plans } from '@abroad/components'

const CopyInviteLink = ({ companyAndEcoInfo }) => {
  const [show, setShow] = useState(false)
  const { companyName } = useParams()
  const {
    values: { plan, promo },
  } = useFormikContext()

  const renderCopyTextTooltip = (props) => (
    <Tooltip id='copy-text' {...props}>
      Link Copied
    </Tooltip>
  )

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false)
      }, [400])
    }
  }, [show])

  const getInviteLink = () => {
    return `${process.env.REACT_APP_ALTERNATE_DOMAIN}/account?${
      companyName ? 'c' : 'e'
    }=${companyAndEcoInfo?.code}${
      plan === plans.free.code ? '' : `&plan=${plan}`
    }${promo ? `&promo=${promo}` : ''}`
  }

  const copyText = (str) => {
    const el = document.createElement('textarea')
    el.value = getInviteLink()
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  return (
    <span className='d-flex'>
      <OverlayTrigger
        placement='right'
        show={show}
        overlay={renderCopyTextTooltip}>
        <Button
          variant='saffron'
          size='md'
          onClick={() => {
            setShow(true)
            copyText()
          }}
          className='mb-4 border-radius-3 w-25'>
          Copy Link
        </Button>
      </OverlayTrigger>
    </span>
  )
}

export default CopyInviteLink
