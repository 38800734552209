import React from 'react'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { ModuleList, CategoryList } from '../components'

const MediaUploader = () => {
  let { path } = useRouteMatch()
  return (
    <>
      <Helmet>
        <title>{'Medias | Abroad'}</title>
        <meta name='title' content={'Medias | Abroad'}></meta>
        <meta property='og:title' content={'Medias | Abroad'}></meta>
        <meta property='twitter:title' content={'Medias | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/media}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/media}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/media}`}></meta>
      </Helmet>
      <Switch>
        <Route exact path={path}>
          <CategoryList />
        </Route>
        <Route exact path={`${path}/:mediaCategory/:catId/:subCatId`}>
          <ModuleList />
        </Route>
        <Redirect to={path} />
      </Switch>
    </>
  )
}

export default withTranslation('translation')(MediaUploader)
