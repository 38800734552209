import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  useErrorService,
  useNotificationService,
  Button,
  ABSpinner,
  useUserService,
  FeatherLoader,
  sows,
} from '@abroad/components'

import { formatDate } from '../utils/date'
import API from '../utils/API'
import { DeleteConfirmationPrompt, PageHeader } from '../components/common'
import labels from '../constants/labels'
import { CoachingPlanDropdown } from '../components/reports'

export const Upload360Report = () => {
  const [planObj, handlePlanObj] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [reportData, setReportData] = useState([])
  const [isUploadReportLoading, setIsUploadReportLoading] = useState(false)
  const [fetchDocumentList, setFetchDocumentList] = useState(true)
  const [clientProfile, setClientProfile] = useState(null)
  const [isReportDelete, setIsReportDelete] = useState(false)
  const [reportId, setReportId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [allCoachIds, setAllCoachIds] = useState([])
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { clientId } = useParams()
  const { user } = useUserService()
  const inputRef = useRef(null)

  useEffect(() => {
    const fetchClientProfile = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['email', 'coachIds', 'fname'],
          },
        )
        setClientProfile(data)
        if (data?.coachIds) {
          setAllCoachIds(data?.coachIds?.map((coach) => coach?._id))
        }

        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    fetchClientProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const { data } =
          await API.CoachAPI.document.getClientDocumentListByPlanId(
            clientId,
            planObj?.id,
            2,
          )
        if (data) {
          setReportData(data)
          setFetchDocumentList(false)
        }
      } catch (e) {
        setFetchDocumentList(false)
        Error.showError(e)
      }
    }
    if (fetchDocumentList && planObj?.id) {
      if (planObj?.type === sows?.planType.b2b) {
        setFetchDocumentList(false)
        setReportData([])
      } else {
        fetchReports()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDocumentList, planObj])

  useEffect(() => {
    if (planObj?.id) {
      setFetchDocumentList(true)
    }
  }, [planObj])

  const handleDocDelete = async () => {
    setIsReportDelete(true)
    try {
      const { data } = await API.CoachAPI.document.deleteClientDocument(
        clientId,
        reportId,
      )
      if (data?.status) {
        setIsReportDelete(false)
        setShowModal(false)
        setReportData((prev) =>
          prev.filter((report) => report?._id !== reportId),
        )
        Notification.showNotification('Document has been deleted.', 'success')
      }
    } catch (e) {
      setIsReportDelete(false)
      setShowModal(false)
      setFetchDocumentList(false)
      Error.showError(e)
    }
  }

  const handleDocDownload = async (docId, fileName, docType) => {
    let contentType = fileName.substr(fileName.length - 4).toUpperCase()
    if (contentType === '.PDF') {
      contentType = 'PDF'
    } else {
      contentType = 'DOCX'
    }
    try {
      const { data } = await API.CoachAPI.document.getDownloadDocumentUrl(
        clientId,
        docId,
        docType,
        fileName,
        contentType,
      )
      if (data) {
        window.open(data.url)
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const get360Report = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const Report360File = files[0]
      add360Report(Report360File)
    }
  }

  const fetch360Hour = (hour) => {
    if (!planObj) return
    const Find360ReportKey = labels?.ReportPlanOf360?.filter(
      (data) => data.key === parseInt(hour),
    )
    return Find360ReportKey[0]?.value
  }

  const add360Report = async (reportFileName) => {
    if (planObj?.type === sows.planType.b2b) return
    let contentType = null

    if (
      reportFileName.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      contentType = 'DOCX'
    } else if (reportFileName.type === 'application/pdf') {
      contentType = 'PDF'
    } else {
      Notification.showNotification(
        'Only pdf or docx file format is allowed',
        'danger',
      )
      return
    }

    setIsUploadReportLoading(true)
    try {
      const { data } =
        await API.CoachAPI.document.getSignUrlForUploadReportOfUser(
          contentType,
          clientId,
          2,
        )
      if (data) {
        let uploadFile = await axios.put(data.url.url, reportFileName)

        if (uploadFile) {
          const { data: reportData } =
            await API.CoachAPI.document.addReportForUser(clientId, 2, {
              planId: planObj?.id,
              fileName: data.fileName,
            })
          if (reportData) {
            setIsUploadReportLoading(false)
            Notification.showNotification(
              '360 Report has been uploaded.',
              'success',
            )
            inputRef.current.value = ''
            setFetchDocumentList(true)
          }
        }
      }
    } catch (e) {
      setIsUploadReportLoading(false)
      inputRef.current.value = ''
      Error.showError(e)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${clientProfile?.fname}'s 360 Report | Abroad`}</title>
        <meta
          name='title'
          content={`${clientProfile?.fname}'s 360 Report | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${clientProfile?.fname}'s 360 Report | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${clientProfile?.fname}'s 360 Report | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/reports/360`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/reports/360`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/coach/clients/${clientId}/reports/360`}></meta>
      </Helmet>
      <DeleteConfirmationPrompt
        showModal={showModal}
        setShowModal={setShowModal}
        isDeleteLoading={isReportDelete}
        handleDelete={handleDocDelete}
        message='This can not be reverted. Are you sure you want to delete this
    Report?'
      />
      <main className='py-3'>
        <PageHeader
          titleText='360 Reports'
          wrapperClassName='d-flex justify-content-between'
          titleClasses='mb-0'
          descriptionFn={() => {
            return (
              <>
                <p className='font-montserrat font-medium mb-0'>
                  {clientProfile?.email}
                </p>
                {planObj?.report360 && (
                  <p className='font-montserrat font-medium'>
                    Plan : {fetch360Hour(planObj?.report360)}
                  </p>
                )}
              </>
            )
          }}
          extraFn={() => {
            return (
              <div className='d-flex justify-content-end'>
                <CoachingPlanDropdown
                  clientId={clientId}
                  setPlanObj={(planObj) => {
                    handlePlanObj(planObj)
                  }}
                  include360={true}
                  isUploadReportLoading={isUploadReportLoading}
                />
                <Button
                  variant='gray'
                  onClick={() =>
                    allCoachIds.includes(user?._id)
                      ? inputRef.current.click()
                      : Notification.showNotification(
                          'You are not coach for this user',
                          'danger',
                        )
                  }
                  className='line-height-normal text-uppercase border-radius-3'
                  isLoading={isUploadReportLoading}
                  disabled={
                    planObj === null ||
                    fetchDocumentList ||
                    isUploadReportLoading ||
                    planObj?.id === null ||
                    planObj?.type === sows.planType.b2b
                  }>
                  Upload 360 Report
                </Button>
              </div>
            )
          }}
          colClasses='px-lg-0'
        />
        <Form.Control
          type='file'
          className='d-none'
          accept='.pdf,.docx'
          ref={inputRef}
          onChange={get360Report}
        />
        <section className='custom-offset-lg-1 custom-col-lg-12 pt-3 px-lg-0'>
          <Container fluid>
            <h3 className='profile-subheader font-montserrat font-18 font-bold leading-relaxed my-4'>
              Reports
            </h3>
            <Row>
              <Col>
                <Table responsive hover className='custom-table user-table'>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchDocumentList && (
                      <tr>
                        <td className='text-center border-0' colSpan='4'>
                          <FeatherLoader />
                        </td>
                      </tr>
                    )}
                    {/* eslint-disable */}
                    {reportData.length > 0 &&
                      !fetchDocumentList &&
                      reportData.map((doc) => (
                        <tr key={doc._id}>
                          <td>
                            <a
                              onClick={() =>
                                handleDocDownload(
                                  doc._id,
                                  doc.fileName,
                                  doc.type,
                                )
                              }
                              className='text-dark-600 under-line my-2 cursor-pointer'>
                              360 Report
                            </a>
                          </td>
                          <td>{formatDate(doc.uploadedAt)}</td>
                          <td>
                            <a
                              onClick={() => {
                                setReportId(doc._id)
                                setShowModal(true)
                              }}
                              className='text-red-600 under-line my-2 ml-2 cursor-pointer'>
                              Delete
                            </a>
                          </td>
                        </tr>
                      ))}
                    {reportData?.length === 0 && !fetchDocumentList && (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No Reports.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}
