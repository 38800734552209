import { simple360Url, coachRecommendsUrl } from '@abroad/components'
import AuthService from './AuthService'

const checkUserIsCoach = () => {
  if (
    AuthService?.getUserData()?.isCoach &&
    !AuthService?.getUserData()?.isAdmin
  ) {
    return true
  }
  return false
}

const isOnlyCoach = checkUserIsCoach()

const CONSTANTS = {
  ADMIN_ROUTES: {
    '/coach/clients': [
      '/coach/clients',
      '/coach/groups',
      '/coach/clients/:clientId/session/:sessionId',
      '/coach/clients/:clientId/reports/360',
      '/coach/clients/:clientId/reports/custom',
      '/coach/clients/:clientId/plans/:planId',
      '/coach/clients/:clientId/reports/Impact',
      '/survey/:surveyId/clients/:clientId',
      '/coach/clients/:clientId/session',
      '/coach/clients/:clientId',
      '/coach/clients/:clientId/overview',
      '/coach/clients/:clientId/analytics',
      '/coach/clients/:clientId/sessions',
      '/coach/clients/:clientId/intentions',
      '/coach/clients/:clientId/plans-payments',
      `/coach/clients/:clientId/${coachRecommendsUrl}`,
      `/coach/clients/:clientId/${simple360Url}`,
      '/coach/clients/:clientId/growth-plan',
      '/coach/clients/:clientId/growth-plan/:growthSessionId',
    ],
    '/admin/home': ['/admin/home'],
    '/admin/users': [
      '/admin/users',
      '/admin/users/:clientId/findCoaches',
      '/admin/users/:clientId',
      '/admin/users/:clientId/overview',
      '/admin/users/:clientId/analytics',
      '/admin/users/:clientId/sessions',
      '/admin/users/:clientId/intentions',
      '/admin/users/:clientId/plans-payments',
      `/admin/users/:clientId/${coachRecommendsUrl}`,
      '/admin/users/:clientId/update-email',
      '/user/:userId/comparison_report',
      '/survey/:surveyId/clients/:clientId',
      '/admin/survey/users_combined_report',
      '/survey/category/:category',
      `/admin/users/:clientId/${simple360Url}`,
      '/admin/users/:clientId/growth-plan',
      '/admin/users/:clientId/growth-plan/:growthSessionId',
      '/admin/client/:clientId/view/client-profile',
      '/survey/leader/:id/clients/:clientId',
    ],
    '/admin/groups': [
      '/admin/group',
      '/survey/group/:teamId/group_combined_report',
      '/survey/group/:teamId',
      '/admin/groups',
      '/admin/groups/active',
      '/admin/groups/archive',
      '/admin/groups/:teamId/information',
      '/admin/groups/:teamId/growth-plan',
      '/admin/groups/:teamId/assessments',
      '/admin/groups/:teamId/session-report',
      '/admin/groups/:teamId/session',
      '/admin/groups/:teamId/session/:sessionId',
      `/admin/groups/:teamId/${coachRecommendsUrl}`,
      '/admin/survey/group/:teamId',
      '/survey/compare/group/:teamId',
      '/survey/group-comparison-report-common/:teamId',
    ],
    '/admin/organizations': [
      '/admin/organizations',
      '/admin/organization/:companyName/users',
      '/admin/organization/:companyName/survey',
      '/admin/survey/organization/:companyName',
      '/admin/survey/organization_comparison_report',
      '/survey/organization/:companyName',
      '/survey/organization/view/:companyName',
      '/admin/organization/:companyName/sows',
      '/admin/organization/:companyName/invite-link',
      '/admin/organization/:companyName/objectives',
      '/admin/survey/user_comparison_report_common',
      '/admin/organization/:companyName/purpose-and-vision',
      '/admin/organization/:companyName/values-and-principles',
      '/admin/organization/:companyName/sow/:sowId/details',
      '/admin/organization/:companyName/sow/:sowId/users',
      '/admin/organization/:companyName/sow/:sowId/sow-groups',
      '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId',
      '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/users',
      '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/sessions',
      '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/coaches',
      `/admin/organization/:companyName/sow/:sowId/sow-group/:teamId/${coachRecommendsUrl}`,
    ],
    '/admin/networks': [
      '/admin/networks',
      '/admin/network/:ecoSystemName/users',
      '/admin/network/:ecoSystemName/survey',
      '/admin/network/:ecoSystemName/invite-link',
      '/admin/network/:ecoSystemName/welcome-email',
      '/admin/survey/network_comparison_report',
      '/admin/survey/network_user_comparison_report_common',
      '/admin/survey/network/:ecoSystemName',
      '/survey/network/:ecoSystemName',
      '/survey/network/view/:ecoSystemName',
    ],
    '/admin/coaches': [
      '/admin/coaches',
      '/admin/coach/:coachId/clients/:clientId/plans/:planId',
      '/admin/coaches/:coachId',
      '/admin/coach/:coachId/profile',
    ],
    '/admin/promo': ['/admin/promo', '/admin/promo/:promoId'],
    '/admin/account': ['/admin/account'],
    '/admin/payment': ['/admin/payment'],
    '/admin/sitecopy/user/sa': [
      '/admin/sitecopy/user',
      '/admin/sitecopy/user/sa',
      '/admin/sitecopy/user/oe',
      '/admin/sitecopy/user/vi',
    ],
    '/admin/scheduled-emails': [
      '/admin/scheduled-emails',
      '/admin/scheduled-emails/user/:userId',
    ],
    '/admin/statistics': ['/admin/statistics'],
    '/admin/media': [
      '/admin/media',
      '/admin/media/program/:catId/:subCatId',
      '/admin/media/category/:catId/:subCatId',
      '/admin/upsert/program/:catId/:subCatId/:mediaId',
      '/admin/upsert/category/:catId/:subCatId/:mediaId',
    ],
  },
  COACH_ROUTES: {
    '/coach/clients': [
      '/coach/clients',
      '/coach/groups',
      ...(isOnlyCoach
        ? [
            '/admin/groups/:teamId/information',
            '/admin/groups/:teamId/assessments',
            '/admin/groups/:teamId/session-report',
            '/survey/group/:teamId',
            '/survey/group/:teamId/group_combined_report',
            '/admin/groups/:teamId/session',
            '/admin/groups/:teamId/session/:sessionId',
            '/user/:userId/comparison_report',
            '/coach/clients/:clientId/intentions',
            '/coach/clients/:clientId/plans-payments',
            `/coach/clients/:clientId/${coachRecommendsUrl}`,
            '/admin/client/:clientId/view/client-profile',
            `/admin/groups/:teamId/${coachRecommendsUrl}`,
            '/survey/leader/:id/clients/:clientId',
          ]
        : []),
      '/coach/clients/:clientId/session/:sessionId',
      '/coach/clients/:clientId/reports/360',
      '/coach/clients/:clientId/reports/custom',
      '/coach/clients/:clientId/plans/:planId',
      '/coach/clients/:clientId/reports/Impact',
      '/survey/:surveyId/clients/:clientId',
      '/coach/clients/:clientId/session',
      '/coach/clients/:clientId',
      '/coach/clients/:clientId/overview',
      '/coach/clients/:clientId/analytics',
      '/coach/clients/:clientId/sessions',
      '/coach/clients/:clientId/intentions',
      '/coach/clients/:clientId/plans-payments',
      `/coach/clients/:clientId/${coachRecommendsUrl}`,
      '/survey/:surveyId/clients/:clientId',
      '/admin/survey/users_combined_report',
      '/admin/survey/group/:teamId',
      '/survey/compare/group/:teamId',
      '/survey/category/:category',
      '/coach/:coachId/profile',
      `/coach/clients/:clientId/${simple360Url}`,
      '/coach/clients/:clientId/growth-plan',
      '/coach/clients/:clientId/growth-plan/:growthSessionId',
    ],
    '/admin/home': ['/admin/home'],
    '/admin/users': [
      '/admin/users',
      '/admin/users/:clientId/findCoaches',
      '/admin/users/:clientId',
      '/admin/users/:clientId/overview',
      '/admin/users/:clientId/analytics',
      '/admin/users/:clientId/sessions',
      '/admin/users/:clientId/intentions',
      '/admin/users/:clientId/plans-payments',
      `/admin/users/:clientId/${coachRecommendsUrl}`,
      '/admin/users/:clientId/update-email',
      '/user/:userId/comparison_report',
      `/admin/users/:clientId/${simple360Url}`,
      '/admin/users/:clientId/growth-plan',
      '/admin/users/:clientId/growth-plan/:growthSessionId',
      '/admin/client/:clientId/view/client-profile',
      '/survey/leader/:id/clients/:clientId',
    ],
    '/admin/groups': [
      '/admin/group',
      '/survey/group/:teamId/group_combined_report',
      '/survey/group/:teamId',
      '/admin/groups',
      '/admin/groups/active',
      '/admin/groups/archive',
      '/admin/groups/:teamId/information',
      '/admin/groups/:teamId/growth-plan',
      '/admin/groups/:teamId/assessments',
      '/admin/groups/:teamId/session-report',
      '/admin/groups/:teamId/session',
      '/admin/groups/:teamId/session/:sessionId',
      `/admin/groups/:teamId/${coachRecommendsUrl}`,
      '/survey/group-comparison-report-common/:teamId',
    ],
    '/admin/organizations': [
      '/admin/organizations',
      '/admin/organization/:companyName/users',
      '/admin/organization/:companyName/survey',
      '/admin/survey/organization/:companyName',
      '/admin/survey/organization_comparison_report',
      '/survey/organization/:companyName',
      '/survey/organization/view/:companyName',
      '/admin/organization/:companyName/sows',
      '/admin/organization/:companyName/invite-link',
      '/admin/survey/user_comparison_report_common',
      '/admin/organization/:companyName/objectives',
      '/admin/organization/:companyName/purpose-and-vision',
      '/admin/organization/:companyName/values-and-principles',
      '/admin/organization/:companyName/sow/:sowId/details',
      '/admin/organization/:companyName/sow/:sowId/users',
      '/admin/organization/:companyName/sow/:sowId/sow-groups',
      '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId',
      '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/users',
      '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/sessions',
      '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/coaches',
      `/admin/organization/:companyName/sow/:sowId/sow-group/:teamId/${coachRecommendsUrl}`,
    ],
    '/admin/networks': [
      '/admin/networks',
      '/admin/network/:ecoSystemName/users',
      '/admin/network/:ecoSystemName/survey',
      '/admin/network/:ecoSystemName/invite-link',
      '/admin/network/:ecoSystemName/welcome-email',
      '/admin/survey/network_comparison_report',
      '/admin/survey/network_user_comparison_report_common',
      '/admin/survey/network/:ecoSystemName',
      '/survey/network/:ecoSystemName',
      '/survey/network/view/:ecoSystemName',
    ],
    '/admin/coaches': [
      '/admin/coaches',
      '/admin/coach/:coachId/clients/:clientId/plans/:planId',
      '/admin/coaches/:coachId',
      '/admin/coach/:coachId/profile',
    ],
    '/admin/promo': ['/admin/promo', '/admin/promo/:promoId'],
    '/admin/account': ['/admin/account'],
    '/admin/payment': ['/admin/payment'],
    '/admin/sitecopy/user/sa': [
      '/admin/sitecopy/user',
      '/admin/sitecopy/user/sa',
      '/admin/sitecopy/user/oe',
      '/admin/sitecopy/user/vi',
    ],
    '/admin/scheduled-emails': [
      '/admin/scheduled-emails',
      '/admin/scheduled-emails/user/:userId',
    ],
    '/admin/statistics': ['/admin/statistics'],
    '/admin/media': [
      '/admin/media',
      '/admin/media/program/:catId/:subCatId',
      '/admin/media/category/:catId/:subCatId',
      '/admin/upsert/program/:catId/:subCatId/:mediaId',
      '/admin/upsert/category/:catId/:subCatId/:mediaId',
    ],
  },
  PARTICIPANT_LEVELS: [
    {
      label: 'Leader',
      value: 'leader',
    },
    {
      label: 'Executive',
      value: 'executive',
    },
    {
      label: 'CEO or Board Member',
      value: 'ceo',
    },
    {
      label: 'Individual Contributor',
      value: 'ic',
    },
    {
      label: 'Manager',
      value: 'manager',
    },
  ],

  PART_OF_SOW_STATUS: [
    {
      label: 'Active',
      value: true,
    },
    {
      label: 'Deactive',
      value: false,
    },
  ],
  COMPANY_METADATA: {
    purposeAndVision: {
      purposeDescription: `To actualize human potential and co-create the wellbeing economy`,
      visionDescription: `Self Actualization for All`,
      abroadDescription: `Abroad was founded with the recognition that in order for our species to survive and thrive, we must change the dream of the modern world. Five years of Abroad research revealed that the depth of what we create in the world is mirrored by the depth of who we are. For these reasons, our purpose is to provide human beings the psychological, emotional, leadership, and spiritual tools to self-actualize and stretch the edges of their potential for the benefit of everyone. The transformation of individuals serves a larger purpose to help birth the wellbeing economy, which is an economy that prioritizes the wellbeing of people and our natural world along with our economic prosperity.`,
      globalImpactDescription: `By 2030, 10,000 leaders and 500 organizations have improved their Self Awareness by at least 5%.<br/><br/>A Global Impact Goal lets us know if we have achieved part of our purpose. The goal needs to be SMART: -Specific -Measurable -Achievable -Relevant -Time-Bound`,
    },
    valuesAndPrinciples: {
      culturalValueDescription: 'Actualize Our Potential',
      leadershipPrincplesDescription:
        'Corageous Transparency <br/> Cultivate Self Awareness',
      abroadDescription: `We challenge our deepest assumptions and operate with a <br/> beginner’s mindset to see beyond the horizon of what we know.`,
      behaviorsDescription: `It's ok to say no <br/> Prioritize the priorities <br/> Change agreements mindfully`,
    },
    objective: {
      description: `Please input the Key Performance Indicators for Abroad's collaboration with this organization. Of note, the KPIs you list below will be listed in the organization section of each user's growth plan section, so please make sure to write with proper grammar. Also, if these KPIs map to the cultural values and leadership principles of the organization, please make sure to link them.`,
    },
  },
  JOURNEYS_METADATA: {
    notes: {
      title: 'Introductory Notes',
      contentText:
        'Here is where you provide an overview to the client as to why you have constructed their journey this way.',
    },
    mediaList: {
      title: 'Learning Journey',
      contentText:
        'Learning journey is the list of audio reocmmendations. This functionality will work identical to how adding audios to PSP currently works. AS the Coach begins to type, the available audios are shown. To help the coaches, they will be given a Google sheets document with all the audios and when/why they should be recomended.',
    },
  },
  JOURNEYS_CATEGORY: [
    {
      label: 'Breakthrough',
      value: 'breakthrough',
    },
    {
      label: 'Evolution',
      value: 'evolution',
    },
    {
      label: 'Trauma Informed Leadership',
      value: 'resilience',
    },
    // {
    //   label: 'Essentials',
    //   value: 'essentials',
    // },
    {
      label: 'Self Awareness',
      value: 'self awareness',
    },
    {
      label: 'Team Cohesion',
      value: 'team cohesion',
    },
    {
      label: 'Vision & Impact',
      value: 'vision & impact',
    },
    // {
    //   label: 'Mindfulness & Somatics',
    //   value: 'mindfulness & somatics',
    // },
  ],
  externalPaymentModeType: {
    ADMIN_UPGRADE: 1,
    ADD_STATIC_USER_UPGRADE: 2,
    REMOVE_PAYWALL_UPGRADE: 3,
  },

  swiperSliderBreakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    576: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  csvTemplateLinkForSOWPage:
    'https://drive.google.com/file/d/1Sq5UVN39QPcZldldGXYg59-M_PhWqh-R/view?usp=share_link',
  csvTemplateLinkForUsersPage:
    'https://drive.google.com/file/d/1QYAV-vxETe6asK97gpgcqPqZ0P9WF_bx/view',
  defaultMinAmountOfPaymentRange: 1,
  minAmountOfPaymentRange: 0,
  maxAmonuntOfPaymentRange: 7000,
  paymentStatus: {
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    ALL: 'all',
  },
  charsLimitInSessionNonConf: 5000,
  maxSessionAudioLimit: 10,
  maxLimitForKeySkill: 10,
  charsLimitInSessionConf: 5000,
  charsLimitInPSP: 5000,
  callOutcomeObj: {
    CLIENT_READY:
      'Client is ready to move forward and I am a good coach for them',
    NOT_GOOD_FIT:
      'Client is ready to move forward but we need to schedule a fit call with another coach',
    CLIENT_OPEN: 'Client is open to coaching, but needs more time to think',
    CLIENT_TEAM_ORG_PROGRAM:
      'Client is interested in a team or organizational program',
    CLIENT_INTERESTED_QUEST: 'Client is interested in a leadership quest',
    CLIENT_NEED_THERAPY:
      'Client could benefit from therapy rather than coaching',
    CLIENT_NO_COACHING: 'Client is a NO for coaching right now',
  },
}

export default CONSTANTS
