import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import classnames from 'classnames'
import { Formik, Form } from 'formik'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import {
  Form as RBForm,
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import { string, number, object, date } from 'yup'
import {
  useErrorService,
  ABSpinner,
  Button as CustomButton,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { GetCurrentClientsCapacity } from './GetCurrentClientsCapacity'
import { GetDateOfBirthValues } from './GetDateOfBirthValues'
import CKEditor from '../editor'
import {
  InputField,
  Checkbox,
  CustomSelectionField,
  SingleDatePicker,
} from '../form'
import { SelectionField } from '../common'
import { formatDate } from '../../utils/date'
import API from '../../utils/API'
import { isLGScreen, roleList } from '../../utils/utility'
import Labels from '../../constants/labels'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const formInitialValues = {
  dateOfBirth: '',
  age: '',
  gender: '',
  country: '',
  state: '',
  city: '',
  ethnicity: [],
  languages: [],
  bio: '',
  experience: '',
  licensedTherapist: false,
  referForTherapy: false,
  specialities: [],
  maximumClients: '',
  currentClients: '',
  currentCapacity: '',
  psychologyScore: '',
  businessPracticalityScore: '',
  executivePresenceScore: '',
  ancientWisdomScore: '',
  roleRelateTo: [],
  companyTypeRelateTo: [],
  companySizeRelateTo: [],
  investmentStageRelateTo: [],
  shareCompanyTypeSizeFunding: false,
  superSkills: [],
  expertSkills: [],
  masterSkills: [],
  notGoodAtSkills: [],
  shareSkillDivisions: false,
}

const CoachProfileForm = () => {
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [isLoading, setIsLoading] = useState(true)
  const [updateProfileLoading, setIsUpdateProfileLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [lastUpdated, setLastUpdated] = useState('')
  const [coachName, setCoachName] = useState('')
  const [coachEmail, setCoachEmail] = useState('')
  const [changeCoachProfile, setChangeCoachProfile] = useState(false)
  const [image, setImage] = useState(false)
  const [imageFile, setImageFile] = useState('')
  const Error = useErrorService()
  const Notification = useNotificationService()
  let { coachId } = useParams()
  let { user } = useUserService()
  const profileRef = useRef(null)

  const isNotProduction = process.env.REACT_APP_ENV !== 'prod'

  let validationSchema

  if (user.isAdmin) {
    validationSchema = object().shape({
      dateOfBirth: date().notRequired(),
      age: number().notRequired(),
      gender: string().notRequired(),
      country: string().notRequired(),
      state: string().notRequired(),
      city: string().notRequired(),
      ethnicity: string().notRequired(),
      languages: string().notRequired(),
      experience: number().notRequired().min(1).max(100),
      specialities: string().notRequired(),
      maximumClients: number().notRequired().integer().min(1).max(1000),
      currentClients: number().notRequired().integer().min(0).max(1000),
      psychologyScore: number().notRequired().min(1).max(5),
      businessPracticalityScore: number().notRequired().min(1).max(5),
      executivePresenceScore: number().notRequired().min(1).max(5),
      ancientWisdomScore: number().notRequired().min(1).max(5),
      roleRelateTo: string().notRequired(),
      companyTypeRelateTo: string().notRequired(),
      companySizeRelateTo: string().notRequired(),
      investmentStageRelateTo: string().notRequired(),
      superSkills: string().notRequired(),
      expertSkills: string().notRequired(),
      masterSkills: string().notRequired(),
      notGoodAtSkills: string().notRequired(),
    })
  } else {
    validationSchema = object().shape({
      gender: string().notRequired(),
      country: string().notRequired(),
      state: string().notRequired(),
      city: string().notRequired(),
      ethnicity: string().notRequired(),
      languages: string().notRequired(),
      experience: number().notRequired().min(1).max(100),
      maximumClients: number().notRequired().integer().min(1).max(1000),
    })
  }

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await API.AdminAPI.coachingList.getCoachProfile(
          coachId,
        )
        setLastUpdated(data?.updatedAt)
        setDescription(data?.bio)
        setImageFile(data?.profileImage ? data?.profileImage : '')
        setCoachName(data?.fullName)
        setCoachEmail(data?.email)
        setImage(data?.profileImage ? true : false)
        setInitialValues((prev) => ({
          ...prev,
          dateOfBirth: data?.dateOfBirth ? formatDate(data?.dateOfBirth) : '',
          age: data?.age ? data?.age : '',
          gender: data?.gender ? data?.gender : '',
          country: data?.country ? data?.country : '',
          state: data?.state ? data?.state : '',
          city: data?.city ? data?.city : '',
          shareCompanyTypeSizeFunding: data?.showRelateTo
            ? data?.showRelateTo
            : false,
          shareSkillDivisions: data?.showSkills ? data?.showSkills : false,
          languages:
            data?.languages?.length > 0
              ? data?.languages?.map((language) => ({
                  value: language,
                  label: language,
                }))
              : [],
          ethnicity:
            data?.ethnicity?.length > 0
              ? data?.ethnicity?.map((ethnicity) => ({
                  value: ethnicity,
                  label: ethnicity,
                }))
              : [],
          experience: data?.experience ? data?.experience : '',
          licensedTherapist: data?.licensedTherapist ? true : false,
          referForTherapy: data?.onlyTherapyCoach ? true : false,
          specialities:
            data?.specialities?.length > 0
              ? data?.specialities?.map((specialitie) => ({
                  value: specialitie,
                  label: specialitie,
                }))
              : [],

          maximumClients: data?.maximumClients ? data?.maximumClients : '',
          currentClients: data?.currentClientsCount
            ? data?.currentClientsCount
            : 0,
          currentCapacity: data?.currentCapacity ? data?.currentCapacity : '',
          psychologyScore: data?.psychologyScore ? data?.psychologyScore : '',
          businessPracticalityScore: data?.businessPracticalityScore
            ? data?.businessPracticalityScore
            : '',
          executivePresenceScore: data?.executivePresenceScore
            ? data?.executivePresenceScore
            : '',
          ancientWisdomScore: data?.ancientWisdomScore
            ? data?.ancientWisdomScore
            : '',
          roleRelateTo:
            data?.roleRelateTo?.length > 0
              ? data?.roleRelateTo?.map((role) => ({
                  value: role,
                  label: roleList[role],
                }))
              : [],
          companyTypeRelateTo:
            data?.companyTypeRelateTo?.length > 0
              ? data?.companyTypeRelateTo?.map((companyType) => ({
                  value: companyType,
                  label: companyType,
                }))
              : [],
          companySizeRelateTo:
            data?.companySizeRelateTo?.length > 0
              ? data?.companySizeRelateTo?.map((companySize) => ({
                  value: companySize,
                  label: companySize,
                }))
              : [],
          investmentStageRelateTo:
            data?.investmentStageRelateTo?.length > 0
              ? data?.investmentStageRelateTo?.map((investmentStage) => ({
                  value: investmentStage,
                  label: investmentStage,
                }))
              : [],
          superSkills:
            data?.superSkills?.length > 0
              ? data?.superSkills?.map((superSkill) => ({
                  value: superSkill,
                  label: superSkill,
                }))
              : [],
          expertSkills:
            data?.expertSkills?.length > 0
              ? data?.expertSkills?.map((expertSkill) => ({
                  value: expertSkill,
                  label: expertSkill,
                }))
              : [],
          masterSkills:
            data?.masterSkills?.length > 0
              ? data?.masterSkills?.map((masterSkill) => ({
                  value: masterSkill,
                  label: masterSkill,
                }))
              : [],
          notGoodAtSkills:
            data?.notGoodAtSkills?.length > 0
              ? data?.notGoodAtSkills?.map((notGoodAtSkill) => ({
                  value: notGoodAtSkill,
                  label: notGoodAtSkill,
                }))
              : [],
        }))
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getImage = (e) => {
    setChangeCoachProfile(true)
    const files = e.target.files
    if (files && files.length > 0) {
      const imageFile = files[0]
      uploadCoachImage(imageFile)
    }
  }

  const uploadCoachImage = async (imageFile) => {
    try {
      const contentType = imageFile.name
        .substr(imageFile.name.lastIndexOf('.') + 1)
        .toUpperCase()

      const { data } = await API.AdminAPI.coachPlan.getFileURL(
        coachId,
        contentType,
      )
      if (data) {
        let uploadImage = await axios.put(data.url.url, imageFile, {
          headers: {
            'Content-Type': data.url.contentType,
          },
        })
        if (uploadImage) {
          setImageFile(data.fileName)
          setChangeCoachProfile(false)
        }
      }
    } catch (e) {
      setChangeCoachProfile(false)
      profileRef.current.value = ''
      Error.showError(e)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  const onSubmit = async (values) => {
    let params
    if ((user?.isAdmin && user.isCoach) || (user?.isAdmin && !user.isCoach)) {
      params = {
        profile: {
          dateOfBirth: values?.dateOfBirth
            ? moment(values?.dateOfBirth).format('YYYY-MM-DD')
            : '',
          gender: values?.gender,
          country: values?.country,
          state: values?.state,
          city: values?.city,
          ethnicity:
            values?.ethnicity?.length > 0
              ? values?.ethnicity.map((ethnicity) => ethnicity.value)
              : [],
          languages:
            values?.languages?.length > 0
              ? values?.languages.map((language) => language.value)
              : [],
        },
        coachProfile: {
          profileImage: imageFile,
          bio: description,
          experienceYear: values?.experience
            ? parseInt(values?.experience)
            : '',
          licensedTherapist: values?.licensedTherapist,
          specialities:
            values?.specialities?.length > 0
              ? values?.specialities.map((specialitie) => specialitie.value)
              : [],
          maximumClients: values?.maximumClients,
          psychologyScore: values?.psychologyScore,
          businessPracticalityScore: values?.businessPracticalityScore,
          executivePresenceScore: values?.executivePresenceScore,
          ancientWisdomScore: values?.ancientWisdomScore,
          roleRelateTo:
            values?.roleRelateTo?.length > 0
              ? values?.roleRelateTo.map((roleRelateTo) => roleRelateTo.value)
              : [],
          companyTypeRelateTo:
            values?.companyTypeRelateTo?.length > 0
              ? values?.companyTypeRelateTo.map(
                  (companyTypeRelateTo) => companyTypeRelateTo.value,
                )
              : [],
          companySizeRelateTo:
            values?.companySizeRelateTo?.length > 0
              ? values?.companySizeRelateTo.map(
                  (companySizeRelateTo) => companySizeRelateTo.value,
                )
              : [],
          investmentStageRelateTo:
            values?.investmentStageRelateTo?.length > 0
              ? values?.investmentStageRelateTo.map(
                  (investmentStageRelateTo) => investmentStageRelateTo.value,
                )
              : [],
          superSkills:
            values?.superSkills?.length > 0
              ? values?.superSkills.map((superSkills) => superSkills.value)
              : [],
          expertSkills:
            values?.expertSkills?.length > 0
              ? values?.expertSkills.map((expertSkills) => expertSkills.value)
              : [],
          masterSkills:
            values?.masterSkills?.length > 0
              ? values?.masterSkills.map((masterSkills) => masterSkills.value)
              : [],
          notGoodAtSkills:
            values?.notGoodAtSkills?.length > 0
              ? values?.notGoodAtSkills.map(
                  (notGoodAtSkills) => notGoodAtSkills.value,
                )
              : [],
          onlyTherapyCoach: values?.referForTherapy,
          showSkills: values?.shareSkillDivisions,
          showRelateTo: values?.shareCompanyTypeSizeFunding,
        },
      }
    } else if (!user?.isAdmin && user?.isCoach) {
      params = {
        profile: {
          dateOfBirth: values?.dateOfBirth
            ? moment(values?.dateOfBirth).format('YYYY-MM-DD')
            : '',
          gender: values?.gender,
          country: values?.country,
          state: values?.state,
          city: values?.city,
          ethnicity:
            values?.ethnicity?.length > 0
              ? values?.ethnicity.map((ethnicity) => ethnicity.value)
              : [],
          languages:
            values?.languages?.length > 0
              ? values?.languages.map((language) => language.value)
              : [],
        },
        coachProfile: {
          profileImage: imageFile,
          bio: description,
          experienceYear: values?.experience
            ? parseInt(values?.experience)
            : '',
          maximumClients: values?.maximumClients,
        },
      }
    }

    Object.keys(params.profile).forEach(
      (key) => params.profile[key] === '' && delete params.profile[key],
    )
    Object.keys(params.coachProfile).forEach((key) => {
      if (params.coachProfile[key] === '') {
        delete params.coachProfile[key]
      }
      if (params.coachProfile[key] === null) {
        delete params.coachProfile[key]
      }
    })

    try {
      setIsUpdateProfileLoading(true)
      const { data } = await API.AdminAPI.coachingList.updateCoachProfile(
        params,
        coachId,
      )
      if (data) {
        setIsUpdateProfileLoading(false)
        Notification.showNotification('Profile has been updated!', 'success')
        if (imageFile !== '') setImage(true)
        profileRef.current.value = ''
      }
    } catch (e) {
      setIsUpdateProfileLoading(false)
      setImage(true)
      Error.showError(e)
    }
  }

  return (
    <>
      <section className='py-5 bg-gray-100'>
        <Container className='custom-offset-lg-1 custom-col-lg-12 px-0' fluid>
          <Row>
            <Col>
              <div className='d-flex justify-content-between mb-0'>
                <div className='d-flex flex-column'>
                  <h3 className='page-label text-uppercase'>{coachName}</h3>
                  <p className='font-montserrat font-medium mb-2'>
                    {coachEmail}
                  </p>
                  <Link
                    to={{
                      pathname: '/profile/update-password',
                      state: {
                        fullName: coachName,
                        email: coachEmail,
                        coachId,
                      },
                    }}
                    className='text-dark-600 under-line font-montserrat font-medium'>
                    Update Password
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {lastUpdated !== undefined && lastUpdated !== '' && (
        <p className='text-center font-montserrat font-medium mt-5 mb-0'>
          Profile Last Updated : {formatDate(lastUpdated)}
        </p>
      )}
      <section>
        <Container fluid className='custom-offset-lg-1 custom-col-lg-12 px-0'>
          <Formik
            displayName='profile-form'
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnMount
            onSubmit={onSubmit}>
            {({ values, handleSubmit }) => {
              return (
                <RBForm
                  onSubmit={handleSubmit}
                  as={Form}
                  className='profile px-4 px-lg-0'>
                  {/* Personal Information Sectiion start */}
                  <Row noGutters className='mb-3 mt-5'>
                    <Col className='pl-0'>
                      <h5
                        className={classnames(
                          'font-montserrat font-semibold text-saffron-700',
                        )}>
                        Personal Information
                      </h5>
                    </Col>
                  </Row>
                  <div className='form-group'>
                    <SingleDatePicker
                      label='DATE OF BIRTH'
                      labelProps={labelProps}
                      name='dateOfBirth'
                      className='pointer-cursor user-input black-color-placeholder bg-white form-control-lg'
                      sm='10'
                      minimumDate={moment().subtract(100, 'years')}
                      maximumDate={moment()}
                    />
                  </div>
                  <GetDateOfBirthValues />
                  {user.isAdmin && user.isCoach && (
                    <InputField
                      label='age'
                      labelProps={labelProps}
                      name='age'
                      type='number'
                      size='lg'
                      placeholder='Age calculate from Date Of Birth.'
                      sm='10'
                      className='cursor-disabled'
                      disabled
                    />
                  )}
                  <SelectionField
                    label='GENDER'
                    labelProps={labelProps}
                    name='gender'
                    options={Labels.gender}
                    size='lg'
                    getKeyAsOption={true}
                  />
                  <SelectionField
                    label='RESIDENT COUNTRY'
                    labelProps={labelProps}
                    name='country'
                    options={Labels.countryData}
                    size='lg'
                    getKeyAsOption={true}
                  />
                  {values.country === 'United States' ? (
                    <SelectionField
                      label='RESIDENT STATE'
                      labelProps={labelProps}
                      name='state'
                      options={Labels.stateData}
                      size='lg'
                      getKeyAsOption={true}
                    />
                  ) : null}

                  <InputField
                    label='RESIDENT CITY'
                    labelProps={labelProps}
                    name='city'
                    type='text'
                    size='lg'
                    placeholder='Enter City.'
                    sm='10'
                  />
                  <CustomSelectionField
                    classNamePrefix='coach-profile-input'
                    name='ethnicity'
                    placeholder='Select'
                    label='CULTURAL ETHNICITY'
                    options={Labels.ethnicities.map((ethnicity) => ({
                      value: ethnicity,
                      label: ethnicity,
                    }))}
                  />
                  <CustomSelectionField
                    classNamePrefix='coach-profile-input'
                    name='languages'
                    placeholder='Select'
                    label='LANGUAGES SPOKEN'
                    options={Labels.languages.map((language) => ({
                      value: language,
                      label: language,
                    }))}
                  />
                  {/* Personal Information Sectiion end */}
                  {/* Coaching Profile Section start*/}
                  <Row noGutters className='mb-3'>
                    <Col className='pl-0'>
                      <h5 className='font-montserrat font-semibold text-saffron-700'>
                        Coaching Profile
                      </h5>
                    </Col>
                  </Row>
                  <Row
                    className={classnames({
                      'd-none m-0': image,
                    })}>
                    <Col lg={2} md={12} sm={12} className='pr-0'>
                      <RBForm.Label>COACH PROFILE PICTURE </RBForm.Label>
                    </Col>
                    <Col lg={7} md={12} sm={12} className='d-flex'>
                      <RBForm.Control
                        type='file'
                        size='lg'
                        placeholder='Select File'
                        accept='image/jpg, image/jpeg, image/png'
                        onChange={getImage}
                        name='profileImage'
                        sm='10'
                        ref={profileRef}
                      />
                    </Col>
                  </Row>
                  <Row
                    className={classnames({
                      'd-none m-0': !image,
                    })}>
                    <Col lg={2} md={12} sm={12} className='pr-0'>
                      <RBForm.Label>COACH PROFILE PICTURE</RBForm.Label>
                    </Col>
                    <Col lg={7} md={12} sm={12} className='d-flex'>
                      <img
                        id='profileImage'
                        src={`https://${
                          isNotProduction
                            ? 'abroad-public-dev'
                            : 'abroad-public'
                        }.s3.us-east-2.amazonaws.com/profile/${imageFile}`}
                        alt='coach profile'
                        height='150'
                        width='150'
                      />
                      <Button
                        onClick={() => {
                          setImage(false)
                        }}
                        size='md'
                        className='d-flex justify-content-start align-items-center align-self-center line-height-normal border-radius-3 text-uppercase'
                        style={{ marginLeft: '10px' }}
                        variant='gray'>
                        Change Profile
                      </Button>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col
                      lg={2}
                      md={12}
                      sm={12}
                      className='d-lg-flex align-items-lg-center justify-content-lg-end p-lg-0'>
                      <OverlayTrigger
                        key='top'
                        placement='top'
                        overlay={
                          <Tooltip
                            id={`tooltip-bio`}
                            className='font-open-sans'>
                            This blurb should reference&#013; your coaching
                            experience in&#013; terms of years and
                            training&#013; the typical clients and issues&#013;
                            you work with, what clients&#013; say is unique
                            about you, top&#013; adjectives that describe
                            your&#013; coaching style or experience&#013; and
                            impact, and lastly&#013; something personal
                            that&#013; people would not know by&#013; looking at
                            you &#013; (family,hobbies, etc)
                          </Tooltip>
                        }>
                        <RBForm.Label className='cursor-pointer'>
                          BIO BLURB{' '}
                          <svg
                            aria-hidden='true'
                            focusable='false'
                            data-prefix='fas'
                            data-icon='question-circle'
                            className='svg-inline--fa fa-question-circle fa-w-16'
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                            width='20'
                            height='20'>
                            <path
                              fill='currentColor'
                              d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z'></path>
                          </svg>
                        </RBForm.Label>
                      </OverlayTrigger>{' '}
                    </Col>
                    <Col lg={10} md={12} sm={12} className='mb-3'>
                      <CKEditor
                        data={description}
                        onBlur={(event, editor) => {
                          setDescription(editor.getData())
                        }}
                      />
                    </Col>
                  </Row>

                  <InputField
                    label='NUMBER OF YEARS COACHING'
                    labelProps={labelProps}
                    name='experience'
                    type='number'
                    size='lg'
                    placeholder='Number of years coaching.'
                    sm='10'
                  />
                  <Row>
                    <Col lg={2} md={12} sm={12} className='pr-0'>
                      <RBForm.Label>LICENSED THERAPIST </RBForm.Label>
                    </Col>
                    <Col lg={7} md={12} sm={12}>
                      <Checkbox
                        name='licensedTherapist'
                        type='checkbox'
                        label='Licensed therapist?'
                        disabled={!user.isAdmin}
                      />
                    </Col>
                  </Row>
                  {values.licensedTherapist && (
                    <Row>
                      <Col lg={2} md={0} sm={0}></Col>
                      <Col lg={7} md={12} sm={12}>
                        <Checkbox
                          name='referForTherapy'
                          type='checkbox'
                          label='Refer for therapy only?'
                          className={classnames({
                            'd-none m-0': !values.licensedTherapist,
                          })}
                          disabled={!user.isAdmin}
                        />
                      </Col>
                    </Row>
                  )}
                  <CustomSelectionField
                    classNamePrefix='coach-profile-input'
                    name='specialities'
                    placeholder='Select'
                    label='COACH SPECIALITIES'
                    options={Labels.coachSpecialityList.map(
                      (coachSpeciality) => ({
                        value: coachSpeciality,
                        label: coachSpeciality,
                      }),
                    )}
                    disabled={!user.isAdmin}
                  />

                  <InputField
                    label='MAXIMUM CLIENT CAPACITY'
                    labelProps={labelProps}
                    name='maximumClients'
                    type='number'
                    size='lg'
                    placeholder='Max Capacity'
                    sm='10'
                  />
                  {user.isAdmin && (
                    <>
                      <InputField
                        label='CURRENT CLIENTS'
                        labelProps={labelProps}
                        name='currentClients'
                        type='number'
                        size='lg'
                        placeholder='Current Clients.'
                        sm='10'
                        disabled
                        className='cursor-disabled'
                      />

                      <GetCurrentClientsCapacity />

                      <InputField
                        label='CURRENT CAPACITY'
                        labelProps={labelProps}
                        name='currentCapacity'
                        type='number'
                        placeholder='Current Capacity'
                        sm='10'
                        size='lg'
                        className='cursor-disabled'
                        disabled
                      />
                    </>
                  )}
                  {/* Coaching Profile Section end*/}

                  {/* RATE COACH SKILL start */}

                  {user.isAdmin && (
                    <>
                      <Row noGutters className='mb-3'>
                        <Col className='pl-0'>
                          <h5 className='font-montserrat font-semibold text-saffron-700'>
                            Rate Coach Skill On The Scale Of 1 To 5:
                          </h5>
                        </Col>
                      </Row>

                      <InputField
                        label='PSYCHOLOGY SCORE'
                        labelProps={labelProps}
                        name='psychologyScore'
                        type='number'
                        size='lg'
                        placeholder='2.5'
                        sm='10'
                        step='0.5'
                      />

                      <InputField
                        label='BUSINESS PRACTICALITY'
                        labelProps={labelProps}
                        name='businessPracticalityScore'
                        type='number'
                        size='lg'
                        placeholder='2.5'
                        sm='10'
                        step='0.5'
                      />

                      <InputField
                        label='EXECUTIVE PRESENCE'
                        labelProps={labelProps}
                        name='executivePresenceScore'
                        type='number'
                        size='lg'
                        placeholder='2.5'
                        sm='10'
                        step='0.5'
                      />

                      <InputField
                        label='ANCIENT WISDOM'
                        labelProps={labelProps}
                        name='ancientWisdomScore'
                        type='number'
                        size='lg'
                        placeholder='2.5'
                        sm='10'
                        step='0.5'
                      />
                    </>
                  )}

                  {/* RATE COACH SKILL end */}

                  {/* ROLES AND COMPANY TYPE, SIZE, AND FUNDING STAGE start */}

                  {(user.isAdmin || values.shareCompanyTypeSizeFunding) && (
                    <>
                      {(user.isAdmin ||
                        values.roleRelateTo.length > 0 ||
                        values.companyTypeRelateTo.length > 0 ||
                        values.companySizeRelateTo.length > 0 ||
                        values.investmentStageRelateTo.length > 0) && (
                        <Row noGutters className='mb-3'>
                          <Col className='pl-0'>
                            <h5 className='font-montserrat font-semibold text-saffron-700'>
                              Roles And Organization Type, Size, And Funding
                              Stage Coach Can Relate To:
                            </h5>
                          </Col>
                        </Row>
                      )}
                      {(user.isAdmin || values.roleRelateTo.length > 0) && (
                        <CustomSelectionField
                          classNamePrefix='coach-profile-input'
                          name='roleRelateTo'
                          placeholder='Select'
                          label='THE ROLES OF CLIENT'
                          options={[
                            ...Labels.roleListForEnterpriceUser,
                            ...Labels.noRoleList,
                          ]}
                          disabled={!user.isAdmin}
                        />
                      )}
                      {(user.isAdmin ||
                        values.companyTypeRelateTo.length > 0) && (
                        <CustomSelectionField
                          classNamePrefix='coach-profile-input'
                          name='companyTypeRelateTo'
                          placeholder='Select'
                          label='TYPES OF ORGANIZATION'
                          options={Labels.companyType.map((organization) => ({
                            value: organization,
                            label: organization,
                          }))}
                          disabled={!user.isAdmin}
                        />
                      )}
                      {(user.isAdmin ||
                        values.companySizeRelateTo.length > 0) && (
                        <CustomSelectionField
                          classNamePrefix='coach-profile-input'
                          name='companySizeRelateTo'
                          placeholder='Select'
                          label='THE SIZE OF ORGANIZATION'
                          options={Labels.organizationPeople.map(
                            (organizationPeople) => ({
                              value: organizationPeople,
                              label: organizationPeople,
                            }),
                          )}
                          disabled={!user.isAdmin}
                        />
                      )}
                      {(user.isAdmin ||
                        values.investmentStageRelateTo.length > 0) && (
                        <CustomSelectionField
                          classNamePrefix='coach-profile-input'
                          name='investmentStageRelateTo'
                          placeholder='Select'
                          label='THE FUNDING STAGE'
                          options={Labels.investmentStage.map(
                            (investmentStage) => ({
                              value: investmentStage,
                              label: investmentStage,
                            }),
                          )}
                          disabled={!user.isAdmin}
                        />
                      )}
                    </>
                  )}

                  {user.isAdmin && (
                    <Row>
                      <Col>
                        <Checkbox
                          name='shareCompanyTypeSizeFunding'
                          type='checkbox'
                          label='Share roles and organization type, organization size, and
                          organization funding stage coach can relate with the coach? Coach can not edit it.'
                        />
                      </Col>
                    </Row>
                  )}

                  {(user.isAdmin || values.shareSkillDivisions) && (
                    <>
                      {(user.isAdmin || values.superSkills.length > 0) && (
                        <CustomSelectionField
                          classNamePrefix='coach-profile-input'
                          name='superSkills'
                          placeholder='Select'
                          fullInputWidth='true'
                          label='CLIENT GROWTH AREAS COACH HAS A SUPERSKILL FOR:'
                          options={Labels.growthList.map((growth) => ({
                            value: growth,
                            label: growth,
                          }))}
                          disabledOptions={[
                            values?.expertSkills?.map((data) => data.value),
                            values?.notGoodAtSkills?.map((data) => data.value),
                            values?.masterSkills?.map((data) => data.value),
                          ]}
                          disabled={!user.isAdmin}
                        />
                      )}
                      {(user.isAdmin || values.expertSkills.length > 0) && (
                        <CustomSelectionField
                          classNamePrefix='coach-profile-input'
                          fullInputWidth='true'
                          name='expertSkills'
                          placeholder='Select'
                          label='CLIENT GROWTH AREAS COACH IS GROWING INTO:'
                          options={Labels.growthList.map((growth) => ({
                            value: growth,
                            label: growth,
                          }))}
                          disabledOptions={[
                            values?.superSkills?.map((data) => data.value),
                            values?.notGoodAtSkills?.map((data) => data.value),
                            values?.masterSkills?.map((data) => data.value),
                          ]}
                          disabled={!user.isAdmin}
                        />
                      )}
                      {(user.isAdmin || values.masterSkills.length > 0) && (
                        <CustomSelectionField
                          classNamePrefix='coach-profile-input'
                          fullInputWidth='true'
                          name='masterSkills'
                          placeholder='Select'
                          label='CLIENT GROWTH AREAS COACH IS A EXPERT IN:'
                          options={Labels.growthList.map((growth) => ({
                            value: growth,
                            label: growth,
                          }))}
                          disabledOptions={[
                            values?.expertSkills?.map((data) => data.value),
                            values?.notGoodAtSkills?.map((data) => data.value),
                            values?.superSkills?.map((data) => data.value),
                          ]}
                          disabled={!user.isAdmin}
                        />
                      )}

                      {(user.isAdmin || values.notGoodAtSkills.length > 0) && (
                        <CustomSelectionField
                          classNamePrefix='coach-profile-input'
                          fullInputWidth='true'
                          name='notGoodAtSkills'
                          placeholder='Select'
                          label='CLIENT GROWTH AREAS COACH IS NOT THE BEST FIT FOR:'
                          options={Labels.growthList.map((growth) => ({
                            value: growth,
                            label: growth,
                          }))}
                          disabledOptions={[
                            values?.expertSkills?.map((data) => data.value),
                            values?.superSkills?.map((data) => data.value),
                            values?.masterSkills?.map((data) => data.value),
                          ]}
                          disabled={!user.isAdmin}
                        />
                      )}
                    </>
                  )}

                  {user.isAdmin && (
                    <Row>
                      <Col>
                        <Checkbox
                          name='shareSkillDivisions'
                          type='checkbox'
                          label='Share skill divisions with the coach? Coach can not edit it.'
                        />
                      </Col>
                    </Row>
                  )}

                  {/* ROLES AND COMPANY TYPE, SIZE, AND FUNDING STAGE end */}
                  <div className='text-right'>
                    <CustomButton
                      isLoading={updateProfileLoading}
                      disabled={updateProfileLoading || changeCoachProfile}
                      type='submit'
                      size='md'
                      className='line-height-normal border-radius-3 text-uppercase'
                      variant='gray'>
                      {changeCoachProfile ? 'Uploading Coach Profile' : 'SAVE'}
                    </CustomButton>
                  </div>
                </RBForm>
              )
            }}
          </Formik>
        </Container>
      </section>
    </>
  )
}

export default CoachProfileForm
