import React from 'react'
import { Button } from '@abroad/components'
import classnames from 'classnames'

const AssessmentActionBottons = ({
  compareBtnProps,
  className = '',
  text = 'Compare Assessments',
}) => {
  return (
    <div className={classnames('mt-4 float-left', className)}>
      <div>
        <Button
          onClick={compareBtnProps?.onClick}
          variant='gray'
          size='lg'
          className='text-uppercase line-height-normal'
          {...compareBtnProps}>
          {text}
        </Button>
      </div>
    </div>
  )
}

export default AssessmentActionBottons
