import React, { useEffect, useState } from 'react'
import { Button } from '@abroad/components'
import CoachRatedTable from './CoachRatedTable'
import CoachPaymentRatesModal from './CoachPaymentRatesModal'

const CoachesAssignToSOW = ({ sowDetails, setIsCoachesEmpty }) => {
  const [showModal, setShowModal] = useState(false)
  const [assignedCoachInfo, setAssignedCoachInfo] = useState([])

  useEffect(() => {
    if (sowDetails?.coaches?.length > 0) {
      const sortedData = sowDetails?.coaches.sort((a, b) =>
        a?.coachId?.fullName.localeCompare(b?.coachId?.fullName),
      )
      setAssignedCoachInfo(sortedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowDetails])

  useEffect(() => {
    if (assignedCoachInfo?.length > 0) {
      setIsCoachesEmpty(false)
    } else {
      setIsCoachesEmpty(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedCoachInfo])

  return (
    <React.Fragment>
      <CoachPaymentRatesModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        addNewCoachPaymentRate={(newValue) =>
          setAssignedCoachInfo((prev) => [...prev, newValue])
        }
        existingCoachIds={assignedCoachInfo?.map(
          (coach) => coach?.coachId?._id,
        )}
      />
      <div className='mt-2-5'>
        <h3 className='s3'>Coaches Assigned to SOW</h3>
        <CoachRatedTable
          assignedCoachInfo={assignedCoachInfo}
          removeCoachFromSOW={(id) =>
            setAssignedCoachInfo((prev) =>
              prev?.filter((coach) => coach?.coachId?._id !== id),
            )
          }
          updateCoachRate={(newValue) =>
            setAssignedCoachInfo((prev) =>
              prev.map((coachPayment) =>
                coachPayment?.coachId?._id === newValue?.coachId?._id
                  ? newValue
                  : coachPayment,
              ),
            )
          }
        />
        <Button
          variant='black'
          className='text-uppercase border-radius-3 mt-2-5 line-height-normal sow-details-btn'
          onClick={(e) => {
            e.preventDefault()
            setShowModal(true)
          }}>
          Add Coach
        </Button>
      </div>
    </React.Fragment>
  )
}

export default CoachesAssignToSOW
