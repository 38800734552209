import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FieldArray } from 'formik'
import ObjectivesWithDnd from './ObjectivesWithDnd'

const ObjectivesField = ({ objectives }) => {
  let boundArrayHelpers
  const maxInputAllow = 7
  const inputsMandatory = 3

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers
  }

  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    setIsDisabled(objectives.length === maxInputAllow)
  }, [objectives, maxInputAllow])

  const addNewInputs = () => {
    if (isDisabled) return
    boundArrayHelpers.push('')
  }

  const inputDeletehandler = (index) => {
    if (objectives.length === inputsMandatory) return
    boundArrayHelpers.remove(index)
  }

  const moveInput = React.useCallback(
    (dragIndex, hoverIndex) => {
      boundArrayHelpers.swap(dragIndex, hoverIndex)
    },
    [boundArrayHelpers],
  )

  return (
    <React.Fragment>
      <FieldArray
        name='objectivesList'
        render={(arrayHelpers) => {
          bindArrayHelpers(arrayHelpers)
          return (
            <>
              {objectives.map((_, index) => (
                <ObjectivesWithDnd
                  index={index}
                  id={index}
                  objectives={objectives}
                  inputsMandatory={inputsMandatory}
                  inputDeletehandler={inputDeletehandler}
                  moveInput={moveInput}
                />
              ))}
            </>
          )
        }}
      />
      <Button
        variant='gray'
        size='md'
        className='line-height-normal text-uppercase mt-2 border-radius-3'
        disabled={isDisabled}
        onClick={addNewInputs}>
        Add
      </Button>
    </React.Fragment>
  )
}

export default ObjectivesField
