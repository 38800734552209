import { Button } from '@abroad/components'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import CreateSOWGroupModal from './CreateSOWGroupModal'
import InviteLinkBtn from './InviteLinkBtn'
import SideBarCloseIcon from './SideBarCloseIcon'
import DeactiveateSOWGroup from '../common/DeactiveateSOWGroup'
import { OrgEcoIdsContext } from '../../utils/contexts'
import labels from '../../constants/labels'

const GroupsTab = ({
  isShowAddSOWGroup,
  orgEcoId,
  closeClickedHandler,
  showCreateGroupModal,
  setShowCreateGroupModal,
  setIsAnyModalEventOccurs,
}) => {
  const { companyName } = useParams()
  const [showCreateGroupSOWModal, setShowCreateGroupSOWModal] = useState(false)
  const { ids } = useContext(OrgEcoIdsContext)
  const type = companyName ? 'company' : 'network'
  return (
    <section className='m-3 profile'>
      <CreateSOWGroupModal
        showModal={showCreateGroupModal}
        setShowModal={setShowCreateGroupModal}
        orgEcoId={orgEcoId}
        orgEcoType={
          companyName
            ? labels?.allGroupType?.COMPANY_WITH_SOW
            : labels?.allGroupType?.NETWORK_WITH_SOW
        }
        showCreateGroupSOWModal={showCreateGroupSOWModal}
        setShowCreateGroupSOWModal={setShowCreateGroupSOWModal}
        closeClickedHandler={closeClickedHandler}
      />
      <SideBarCloseIcon closeClickedHandler={closeClickedHandler} />
      <InviteLinkBtn
        classes='mb-point-75'
        closeClickedHandler={closeClickedHandler}
        type={type}
      />
      {isShowAddSOWGroup && (
        <Button
          variant='black'
          size='lg'
          className='w-100 mb-point-75 border-radius-3 line-height-normal'
          onClick={() => {
            setShowCreateGroupModal(true)
            setShowCreateGroupSOWModal(true)
          }}>
          CREATE NEW GROUP
        </Button>
      )}
      <DeactiveateSOWGroup
        setIsAnyModalEventOccurs={setIsAnyModalEventOccurs}
        closeClickedHandler={closeClickedHandler}
        isRenderAsSOWModal={ids?.length > 0 ? false : true}
      />
    </section>
  )
}

export default GroupsTab
