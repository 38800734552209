import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'
import {
  Switch,
  NavLink,
  useRouteMatch,
  useParams,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom'
import { SaffronNav, useErrorService } from '@abroad/components'
import Objectives from './Objectives'
import { PurposeAndVision, ValuesAndPrinciples } from '../components'
import { CompanyMetadataContext } from '../utils/contexts'
import API from '../utils/API'

const CompanyMetadata = ({ companyAndEcoInfo }) => {
  const Error = useErrorService()

  let { companyName } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [companyRawMetadata, setCompanyRawMetaData] = useState(null)
  let { path } = useRouteMatch()
  const { location } = useHistory()

  useEffect(() => {
    const getMetadata = async () => {
      if (location.pathname === `/admin/organization/${companyName}/objectives`)
        return
      try {
        setIsFetching(true)
        const { data } = await API.AdminAPI.companyList.getCompanyMetadata(
          companyAndEcoInfo?._id,
        )
        if (data) {
          if (
            location.pathname ===
            `/admin/organization/${companyName}/purpose-and-vision`
          ) {
            setCompanyRawMetaData(data.purposeAndVision || {})
          } else {
            const mataData = data?.valuesAndPrinciples?.map((data) => ({
              behaviors: data.behaviors,
              description: data.description,
              principles: data.principles,
              value: data.value,
            }))
            const emptyData = Array.from(
              { length: 7 - data?.valuesAndPrinciples?.length },
              (x, i) => ({
                value: '',
                description: '',
                behaviors: [''],
                principles: [''],
              }),
            )

            setCompanyRawMetaData([...mataData, ...emptyData])
          }
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
      setIsFetching(false)
    }
    getMetadata()
    // eslint-disable-next-line
  }, [location.pathname])

  const pages = [
    {
      path: `/admin/organization/${companyName}/purpose-and-vision`,
      name: 'PURPOSE & VISION',
    },
    {
      path: `/admin/organization/${companyName}/values-and-principles`,
      name: 'VALUES & PRINCIPLES',
    },
    {
      path: `/admin/organization/${companyName}/objectives`,
      name: 'OBJECTIVES',
    },
  ]

  return (
    <>
      <section>
        <Container className='mt-40 px-0' fluid>
          <SaffronNav className='d-flex header-tabs'>
            {pages.map((page, index) => {
              return (
                <SaffronNav.Item key={page.path} className='position-relative'>
                  <SaffronNav.Link
                    className={classNames('mb-0', {
                      'mr-0': pages.length === index + 1,
                    })}
                    as={NavLink}
                    to={page.path}
                    isActive={(match) => {
                      if (!match) {
                        return false
                      }
                      return true
                    }}>
                    {page.name}
                  </SaffronNav.Link>
                </SaffronNav.Item>
              )
            })}
          </SaffronNav>
        </Container>
      </section>
      <CompanyMetadataContext.Provider
        value={{
          setData: (obj) => setCompanyRawMetaData(obj),
          data: companyRawMetadata,
        }}>
        <Switch>
          <Route exact strict path={`${path}/purpose-and-vision`}>
            <PurposeAndVision
              companyName={companyName}
              id={companyAndEcoInfo?._id}
              isLoading={isLoading || isFetching}
            />
          </Route>
          <Route path={`${path}/values-and-principles`}>
            <ValuesAndPrinciples
              companyName={companyName}
              tabsLength={7}
              id={companyAndEcoInfo?._id}
              isLoading={isLoading || isFetching}
            />
          </Route>
          <Route path={`${path}/objectives`}>
            <Objectives companyAndEcoInfo={companyAndEcoInfo} />
          </Route>
          <Redirect to={`/admin/organization/${companyName}/users`} />
        </Switch>
      </CompanyMetadataContext.Provider>
    </>
  )
}

export default CompanyMetadata
