import React, { useState } from 'react'
import { Accordion, Row, Col } from 'react-bootstrap'
import classnames from 'classnames'
import moment from 'moment'
import IntentionAccordianContext from './IntentionAccordianContext'
import InsightCard from './InsightCard'
import AddNewInsight from './AddNewInsight'
import AddIntentionField from './AddIntentionField'
import { isLGScreen } from '../../utils/utility'

const Intention = ({ intention, index, inputKeyUp, isActive }) => {
  const [hasIntention, setHasIntention] = useState(true)
  return (
    <Row className='mt-4'>
      <Col className='p-hover-0'>
        <div
          className={classnames(
            'py-3 pt-lg-2 pb-lg-0 px-3 px-md-5 px-lg-5 bg-gray-100 overflow-hidden',
            {
              'border-radius-10': isLGScreen,
            },
          )}>
          <div className='px-4'>
            <div className='d-flex justify-content-between align-items-center'>
              <span className='font-montserrat tracking-xl-wide text-uppercase text-saffron-700'>
                Intention &nbsp;{index}
              </span>
              <span className='font-montserrat font-semibold font-12 d-none d-lg-inline-block'>
                {intention?.createdAt
                  ? moment(intention.createdAt).format('Do MMMM, YYYY')
                  : moment().format('Do MMMM, YYYY')}
              </span>
            </div>
            <div>
              <AddIntentionField
                inputKeyUp={inputKeyUp}
                setHasIntention={setHasIntention}
                title={intention?.title}
                index={index}
                hasIntention={hasIntention}
                intentionId={intention?._id}
                isActive={isActive}
              />
            </div>
            <div className='text-center'>
              <span className='font-montserrat font-semibold font-12 d-block d-lg-none '>
                {intention?.createdAt
                  ? moment(intention.createdAt).format('Do MMMM, YYYY')
                  : moment().format('Do MMMM, YYYY')}
              </span>
            </div>
            <Row xs={1} sm={1} md={1} lg={3} xl={3}>
              <Col className='py-3'>
                <AddNewInsight
                  setHasIntention={setHasIntention}
                  intentionId={intention?._id}
                />
              </Col>
              {[
                ...Array.from(
                  { length: 2 - intention?.insights?.slice(0, 2).length },
                  (x, i) => ({}),
                ),
                ...intention?.insights?.slice(0, 2),
              ].map((insight, index) => (
                <Col key={insight?._id ? insight?._id : index} className='py-3'>
                  {insight?._id ? (
                    <InsightCard
                      insight={insight}
                      intentionId={intention?._id}
                    />
                  ) : (
                    <AddNewInsight
                      setHasIntention={setHasIntention}
                      intentionId={intention?._id}
                    />
                  )}
                </Col>
              ))}
            </Row>
            {intention?.insights?.slice(2, intention?.insights.length).length >
            0 ? (
              <Accordion defaultActiveKey={isLGScreen ? '' : '0'}>
                <Accordion.Collapse eventKey='0'>
                  <Row xs={1} sm={1} md={1} lg={3} xl={3} className='flex-wrap'>
                    {intention?.insights
                      ?.slice(2, intention?.insights.length)
                      .map((insight) => (
                        <Col key={insight?._id} className='py-3'>
                          <InsightCard
                            insight={insight}
                            intentionId={intention?._id}
                          />
                        </Col>
                      ))}
                  </Row>
                </Accordion.Collapse>
                <div className='position-relative'>
                  <div className='text-center pb-3 d-none d-lg-block'>
                    <IntentionAccordianContext eventKey='0' />
                  </div>
                </div>
              </Accordion>
            ) : (
              <div
                className={classnames('position-relative', {
                  'mt-2': !isLGScreen,
                })}
                style={{ height: isLGScreen ? '3rem' : '0.8rem' }}></div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}

Intention.defaultProps = {
  inputKeyUp: () => {},
}

export default Intention
