import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useErrorService } from '../../services'
import Button from '../Button'
import SubscriptionTableLink from './SubscriptionTableLink'

const CancelSubscription = ({ sowId, onSuccess, API }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const handleClose = () => {
    if (isLoading) return
    setShowModal(false)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const { status } = await API.payment.cancelSubscription({
        coachingPlanId: sowId
      })
      if (status) {
        handleClose()
        setIsLoading(false)
        onSuccess()
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }
  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header className='border-0 pb-0 px-4'>
          <Modal.Title className='text-uppercase s7 font-montserrat font-16 font-bold leading-5 letter-spacing-02'>
            Are you sure you want to cancel this subscription?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-3 pt-2 px-4 border-0 s10 font-open-sans font-semibold font-14 leading-6 letter-spacing-minus-02'>
          You can't undo this action.
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end px-4 space-x-3'>
          <Button
            variant='secondary-light'
            onClick={handleClose}
            disabled={isLoading}
            className='px-3 secondary-popup-button'
            size='sm'
          >
            Cancel
          </Button>
          <Button
            variant='saffron'
            size='sm'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onSubmit}
            className='text-uppercase border-radius-normal'
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <SubscriptionTableLink
        onClick={() => setShowModal(true)}
        disabled={isLoading}
        linkText='Cancel Subscription'
      />
    </>
  )
}

export default CancelSubscription
