import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Form } from 'react-bootstrap'
import { plans, useErrorService } from '@abroad/components'
import { SelectionField } from '../common'
import { Checkbox } from '../form'
import API from '../../utils/API'
import labels from '../../constants/labels'

const PromoSelectionField = ({ hasInvitePlan, isLoading, targetPlan }) => {
  const [promoActive, setPromoActive] = useState(false)
  const [promoData, setPromoData] = useState([])
  const [isPromoDataLoading, setIsPromoDataLoading] = useState(false)
  const {
    values: { includePromocode, plan },
    setFieldValue,
  } = useFormikContext()
  const Error = useErrorService()

  useEffect(() => {
    const getActivePromoCode = async () => {
      try {
        setIsPromoDataLoading(true)
        const { data } = await API.AdminAPI.promo.getActivePromoCodeList()
        if (data) {
          const sortedPromo = data?.sort((a, b) => a.code.localeCompare(b.code))
          setPromoData(sortedPromo)
        }
        setIsPromoDataLoading(false)
      } catch (e) {
        setIsPromoDataLoading(false)
        Error.showError(e)
      }
    }
    if (promoActive && promoData.length === 0) {
      getActivePromoCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoActive])

  useEffect(() => {
    if (!includePromocode || plan === plans.free.code) {
      setFieldValue('promo', '')
      setFieldValue('includePromocode', false)
    }
    if (includePromocode) {
      setPromoActive(includePromocode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includePromocode, plan])

  return (
    <div className='d-flex'>
      <div className='d-flex flex-column'>
        <Form.Group className='mb-0'>
          {targetPlan && (
            <Form.Label className='custom-mb-0-625 text-uppercase sidebar-label d-flex align-items-end'>
              Invite link plan
            </Form.Label>
          )}
          <SelectionField
            name='plan'
            options={labels.plans}
            optionplaceholder='Select One'
            getValueAsOption={true}
            size='md'
            className='plan-dropdown text-uppercase'
            disabled={isLoading || hasInvitePlan || targetPlan}
            key={plan}
            formGroupProps={{ className: 'mb-0' }}
          />
        </Form.Group>
        {!targetPlan && (
          <Checkbox
            formGroupProps={{
              className: 'mt-n2 mb-2',
            }}
            labelProps={{
              className: 'include-promocode',
            }}
            id='includePromocode'
            name='includePromocode'
            type='checkbox'
            label='Include Promocode?'
            disabled={plan === plans.free.code || hasInvitePlan}
          />
        )}
      </div>
      {includePromocode && (
        <div className={targetPlan ? '' : 'ml-3'}>
          <SelectionField
            name='promo'
            options={promoData}
            optionplaceholder={
              isPromoDataLoading ? 'Loading...' : 'Select Promo'
            }
            // className='plan-dropdown'
            size='md'
            promoOptions={true}
            disabled={isPromoDataLoading}
          />
        </div>
      )}
    </div>
  )
}

export default PromoSelectionField
