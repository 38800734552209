import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  ABSpinner,
  Button,
  useErrorService,
  useUserService,
} from '@abroad/components'
import { GroupSessionsTable } from '../common'
import API from '../../utils/API'

const SOWGroupSessions = ({ groupName }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useUserService()
  const { companyName, ecoSystemName, sowId, sowGroupId } = useParams()
  const history = useHistory()
  const Error = useErrorService()
  const [isTeamCoach, setIsTeamCoach] = useState(false)
  const [isArchived, setIsArchived] = useState(false)
  const url = `/admin/${
    companyName ? `organization/${companyName}` : `network/${ecoSystemName}`
  }/sow/${sowId}/sow-group/${sowGroupId}/sessions`
  const title = groupName
    ? `${groupName}'s Sessions | Abroad`
    : 'Group Sessions | Abroad'

  useEffect(() => {
    const getTeamData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(
          sowGroupId,
          {
            fields: ['name', 'isArchived', 'coaches'],
            populateArray: [
              {
                path: 'coaches',
                select: 'fullName',
              },
            ],
          },
        )
        if (data?.coaches?.length > 0) {
          setIsTeamCoach(
            data.coaches.map((data) => data._id).includes(user._id),
          )
          setIsArchived(data?.isArchived)
          setIsLoading(false)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getTeamData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <main>
      <Helmet>
        <title>{`${title}`}</title>
        <meta name='title' content={`${title}`}></meta>
        <meta property='og:title' content={`${title}`}></meta>
        <meta property='twitter:title' content={`${title}`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}${url}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
      </Helmet>
      <section>
        <div className='text-right'>
          {user.isCoach && isTeamCoach && !isArchived && (
            <Button
              variant='black'
              size='md'
              onClick={() =>
                history.push(`/admin/groups/${sowGroupId}/session`)
              }
              className='line-height-normal border-radius-3 mb-3'>
              Add Session
            </Button>
          )}
        </div>
        <GroupSessionsTable isArchived={isArchived} />
      </section>
    </main>
  )
}

export default SOWGroupSessions
