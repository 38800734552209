import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FilterSelection } from '../common'
import { OrganizationFilterForm } from '../company'
import labels from '../../constants/labels'
import API from '../../utils/API'

const CoachesSowFilters = ({
  isShowCoachesAndSOWFilter,
  globalFilter,
  setGlobalFilter,
}) => {
  const [coaches, setCoaches] = useState({ isLoading: true, data: [] })

  useEffect(() => {
    const getCoaches = async () => {
      setCoaches({ isLoading: true, data: [] })
      try {
        const { data } = await API.AdminAPI.userList.getCoaches()
        if (data) {
          const coachesList = data.map((coach) => ({
            key: coach?._id,
            value: coach?.fullName,
          }))
          setCoaches({ isLoading: false, data: coachesList })
        } else {
          setCoaches({ isLoading: false, data: [] })
        }
      } catch (e) {
        setCoaches({ isLoading: false, data: [] })
        Error.showError(e)
      }
    }
    if (isShowCoachesAndSOWFilter) getCoaches()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowCoachesAndSOWFilter])

  if (!isShowCoachesAndSOWFilter) return null

  return (
    <>
      <FilterSelection
        {...{
          globalFilter,
          setGlobalFilter,
        }}
        name='coachId'
        inputClasses='org-users-filter border-radius-5'
        placeholder='Select Coach'
        options={coaches?.data}
        disabled={coaches?.isLoading}
        size='sm'
        key={globalFilter?.coachId}
      />
      <div className='d-flex flex-column mx-4'>
        <OrganizationFilterForm
          {...{
            globalFilter,
            setGlobalFilter,
          }}
          name='isSOWAssigned'
          className='org-users-filter border-radius-5'
          optionplaceholder='Is Individual SOW Assigned'
          options={labels.statusFilterOptions}
          size='sm'
          key={globalFilter?.isSOWAssigned}
        />
        <Form.Text className='text-muted font-10'>
          Is Individual SOW Assigned?
        </Form.Text>
      </div>
    </>
  )
}

export default CoachesSowFilters
