import React, { useState, useContext, useEffect } from 'react'
import classNames from 'classnames'
import { useErrorService, NotificationContext } from '@abroad/components'
import API from '../../utils/API'
import { SelectionField } from '../common'
import Labels from '../../constants/labels'

const UserPlanUpdate = ({
  userId,
  planCode,
  userEmail,
  selectionClasses = '',
  inputClasses = '',
  onPlanChangeHandler = () => {},
  isAdminOrCoach = false,
}) => {
  const [selectionStatus, setSelectionStatus] = useState({
    disabled: false,
    planCode: planCode,
  })
  const Error = useErrorService()
  const { showNotification } = useContext(NotificationContext)

  useEffect(() => {
    if (planCode) {
      setSelectionStatus({
        disabled: false,
        planCode: planCode,
      })
    }
  }, [planCode])

  const handlePlanUpdate = async (e) => {
    if (isAdminOrCoach) return
    const localPlanCode = e.target.value
    setSelectionStatus({
      disabled: true,
      planCode: localPlanCode,
    })
    if (
      window.confirm(
        `Do you want to change the plan of ${userEmail} to ${localPlanCode}`,
      )
    ) {
      try {
        const { data } = await API.AdminAPI.userList.updateUserPlan(
          {
            planCode: localPlanCode,
          },
          userId,
        )
        if (data) {
          setSelectionStatus((prev) => ({
            ...prev,
            disabled: false,
          }))
          onPlanChangeHandler(localPlanCode, data?.expiresOn)
          showNotification('User plan has been updated!', 'success')
        }
      } catch (e) {
        setSelectionStatus({
          disabled: false,
          planCode: planCode,
        })
        onPlanChangeHandler(planCode)
        Error.showError(e)
      }
    } else {
      setSelectionStatus({
        disabled: false,
        planCode: planCode,
      })
    }
  }

  return (
    <SelectionField
      options={Labels.plans}
      name={`userPlan-${userEmail}`}
      className={classNames(
        'userinfo-select-plan text-capitalize',
        {
          'pointer-cursor': !(selectionStatus.disabled || isAdminOrCoach),
          'field-disabled': selectionStatus.disabled || isAdminOrCoach,
        },
        selectionClasses,
      )}
      inputClasses={inputClasses}
      userSelected={selectionStatus.planCode}
      value={selectionStatus.planCode}
      id={`select-plan-${userEmail}`}
      optionplaceholder='Select Plan'
      onChange={(e) => handlePlanUpdate(e)}
      disabled={selectionStatus.disabled || isAdminOrCoach}
      getValueAsOption={true}
      size='md'
      key={planCode}
    />
  )
}

export default UserPlanUpdate
