import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { FeatherLoader, useErrorService } from '@abroad/components'
import ClientStatusBtn from './ClientStatusBtn'
import API from '../../utils/API'

const ClientStatus = ({ userIsAdminOrCoach }) => {
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [timeLine, setTimeLine] = useState([])
  const { clientId } = useParams()
  const clientStatus = {
    isRegistered: 'REGISTERED',
    assessmentTaken: 'ASSESSMENT',
    isInSOW: 'SOW',
    isCoachAssigned: 'COACH',
    isInGroup: 'GROUP',
  }
  const status = []

  useEffect(() => {
    const getUserTimeLine = async (value) => {
      try {
        const { data } = await API.CoachAPI.coachUser.getUserTimeline(clientId)
        if (data) {
          setTimeLine(data)
        }
      } catch (e) {
        Error.showError(e)
      }
      setIsLoading(false)
    }
    getUserTimeLine()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div
      className={classNames({
        'mt-4': userIsAdminOrCoach,
        'mt-2-5': !userIsAdminOrCoach,
      })}>
      <h3 className='s3 mb-3'>Client Status</h3>
      {isLoading ? (
        <div className='text-center border-0'>
          <FeatherLoader />
        </div>
      ) : (
        <React.Fragment>
          {Object.entries(timeLine).forEach(([key, value]) => {
            status.push(
              <ClientStatusBtn
                key={key}
                value={value}
                label={clientStatus[key]}
                className={
                  clientStatus[key] === 'hasGroup' ? 'mb-3' : 'mr-4 mb-3'
                }
              />,
            )
          })}
          {status}
        </React.Fragment>
      )}
    </div>
  )
}

export default ClientStatus
