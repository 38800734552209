import React, { useEffect, useRef, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { Switch, NavLink, useLocation, useParams } from 'react-router-dom'
import { NavTabs } from '@abroad/components'
import { EcoSystemAssessments } from './EcoSystemAssessments'
import InviteOrgEcoLink from './InviteOrgEcoLink'
import { EcoSystemListing } from './EcoSystemListing'
import WelcomeEmail from './WelcomeEmail'
import {
  OrgEcoPageHeader,
  TeamProvider,
  OrgEcoUsers,
  ListingFiltersProvider,
} from '../components'
import PrivateRoute from '../routes/PrivateRoute'
import { OrgEcoRightSection, RightSideBar } from '../components/common'
import API from '../utils/API'
import { OrgEcoIdsContext } from '../utils/contexts'

export const EcoSystem = () => {
  const location = useLocation()
  const { ecoSystemName } = useParams()
  const rightSidebarRef = useRef(null)
  const [showRightSidebar, setShowRightSidebar] = useState(false)
  const [companyAndEcoInfo, setCompanyAndEcoInfo] = useState()
  const [ids, setIds] = useState([])
  const [isLoadNewData, setIsLoadNewData] = useState(false)

  const networkPages = [
    {
      path: `/admin/network/${ecoSystemName}/users`,
      name: 'USERS',
    },
    {
      path: `/admin/network/${ecoSystemName}/survey`,
      name: 'ASSESSMENTS',
    },
  ]

  useEffect(() => {
    setIds([])
  }, [location.pathname])

  useEffect(() => {
    const getNetworkCode = async () => {
      setCompanyAndEcoInfo(null)
      try {
        const { data } = await API.AdminAPI.ecoSystem.getEcoSystemByName(
          ecoSystemName,
        )
        setCompanyAndEcoInfo(data)
      } catch (e) {
        Error.showError(e)
      }
    }
    if (ecoSystemName) getNetworkCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecoSystemName])

  return (
    <main>
      <ListingFiltersProvider type='network'>
        <TeamProvider>
          <OrgEcoIdsContext.Provider
            value={{
              ids,
              setIds,
              isLoadNewData,
              setIsLoadNewData,
              setCompanyAndEcoInfo,
              companyAndEcoInfo,
            }}>
            {ecoSystemName && (
              <React.Fragment>
                <OrgEcoPageHeader
                  setShowRightSidebar={setShowRightSidebar}
                  sowName={null}
                  groupName={null}
                />
                <RightSideBar show={showRightSidebar} ref={rightSidebarRef}>
                  <OrgEcoRightSection
                    closeClickedHandler={() => setShowRightSidebar(false)}
                    rightSidebarRef={rightSidebarRef}
                  />
                </RightSideBar>
              </React.Fragment>
            )}
            {ecoSystemName ? (
              <React.Fragment>
                {companyAndEcoInfo ? (
                  <section>
                    <Switch location={location}>
                      <PrivateRoute
                        exact
                        path={`/admin/network/:ecoSystemName/users`}>
                        <GetTabs pages={networkPages} location={location} />
                        <div className='custom-offset-lg-1 custom-col-lg-12 px-0'>
                          <OrgEcoUsers
                            orgEcoId={companyAndEcoInfo?._id}
                            type='affiliate'
                          />
                        </div>
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={`/admin/network/:ecoSystemName/survey`}>
                        <GetTabs pages={networkPages} location={location} />
                        <EcoSystemAssessments />
                      </PrivateRoute>
                      <div className='custom-offset-lg-1 custom-col-lg-12 px-0'>
                        <PrivateRoute
                          exact
                          path='/admin/network/:ecoSystemName/invite-link'>
                          <InviteOrgEcoLink
                            companyAndEcoInfo={companyAndEcoInfo}
                          />
                        </PrivateRoute>

                        <PrivateRoute
                          exact
                          path='/admin/network/:ecoSystemName/welcome-email'>
                          <WelcomeEmail type='Network' />
                        </PrivateRoute>
                      </div>
                    </Switch>
                  </section>
                ) : (
                  <div className='text-center mt-4'>
                    <Spinner animation='border' className='text-saffron-700' />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <PrivateRoute exact path='/admin/networks'>
                <EcoSystemListing />
              </PrivateRoute>
            )}
          </OrgEcoIdsContext.Provider>
        </TeamProvider>
      </ListingFiltersProvider>
    </main>
  )
}

const GetTabs = ({ pages, location, noNeedWrapperClasses = false }) => {
  return (
    <section
      className={
        noNeedWrapperClasses ? '' : 'custom-offset-lg-1 custom-col-lg-12 px-0'
      }>
      <Container className='org-tabs-wrapper px-0' fluid>
        <NavTabs
          pages={pages}
          pathname={location?.pathname}
          hash={location?.hash}
          NavLink={NavLink}
        />
      </Container>
    </section>
  )
}
