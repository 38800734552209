import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useErrorService } from '@abroad/components'
import API from '../../utils/API'
import { getGroupLabel } from '../../utils/utility'
import { Table, TableInstance } from '../table'

export const TeamsOfCoach = () => {
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()
  const [teams, setTeams] = useState([])
  const hasMore = useRef(false)
  const [reqData, setReqData] = useState(null)

  const fetchTeams = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await API.CoachAPI.teams.getTeamList(
        `&page=${reqData.page}&limit=${reqData.limit}`,
      )
      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setTeams((prev) => [...prev, ...data])
      } else {
        setTeams(data)
      }
      setIsLoading(false)
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      fetchTeams(reqData)
    }
  }, [fetchTeams, reqData])

  const columns = React.useMemo(
    () => [
      {
        id: 'number',
        Header: '#',
        width: '2%',
        accessor: (properties) => properties,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
        disableSortBy: true,
      },
      {
        id: 'group-name',
        Header: 'Group Name',
        width: '48%',
        accessor: (properties) => properties?.name,
        Cell: ({ cell }) => <span>{cell?.value}</span>,
        disableSortBy: true,
      },
      {
        id: 'group',
        Header: 'Group',
        width: '48%',
        accessor: (properties) => ({
          groupName: properties?.groupName,
          groupId: properties?.groupId,
          groupType: properties?.groupType,
        }),
        Cell: ({ cell }) => (
          <React.Fragment>
            {cell?.value?.groupName || cell?.value?.groupId?.name ? (
              <>
                {cell?.value.groupName || cell?.value?.groupId?.name}
                <br />
              </>
            ) : null}
            <small className='text-saffron-700'>
              ({getGroupLabel(cell?.value?.groupType)})
            </small>
          </React.Fragment>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  const fetchData = useCallback(({ page, limit }) => {
    if (page === 1) {
      setTeams([])
    }
    const req = {
      limit,
      page: page,
    }
    setReqData(req)
  }, [])

  return (
    <Container fluid className='px-lg-0 mt-3'>
      <TableInstance columns={columns} data={teams}>
        {({ ...rest }) => (
          <Table
            ref={hasMore}
            emptyTableMessage={'No Groups Found.'}
            tableInstance={{
              ...rest,
            }}
            isLoading={isLoading}
            fetchData={fetchData}
            isRenderOnCoachGroupTable={true}
            trClasses='cursor-pointer'
          />
        )}
      </TableInstance>
    </Container>
  )
}
