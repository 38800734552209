import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { ShimmerButton, ShimmerTitle } from 'react-shimmer-effects'
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useErrorService, useUserService } from '../../services'
import Button from '../Button'
import { ReturnLeftIcon } from '../assetsComponents'
import AddCreditCard from './AddCreditCard'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const AddCardForm = ({ onSuccess, onGoBack, API }) => {
  const stripe = useStripe()
  const elements = useElements()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(false)
  const [stripeLoading, setStripeLoading] = useState(true)
  const { user } = useUserService()
  useEffect(() => {
    if (stripe) {
      setStripeLoading(false)
    }
  }, [stripe])

  const addCard = async () => {
    try {
      setIsLoading(true)
      const data = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement('cardNumber'),
        billing_details: {
          name: user?.fullName,
          email: user?.email,
          address: {}
        }
      })
      if (data && !data?.error) {
        const { data: cardData } = await API.payment.attachPayMethod({
          paymentMethodId: data?.paymentMethod?.id
        })
        if (cardData) {
          onSuccess(data?.paymentMethod?.id)
        }
      } else {
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  if (stripeLoading) {
    return (
      <div className='text-center border-0'>
        <ShimmerTitle
          line={1}
          variant='primary'
          className='input-shimmer mb-2-rem'
        />
        <ShimmerTitle
          line={1}
          variant='primary'
          className='input-shimmer mb-2-rem'
        />
        <div className='d-flex justify-content-between'>
          <ShimmerButton side='lg' />
          <ShimmerButton side='lg' />
        </div>
      </div>
    )
  }

  return (
    <div className='payment-details'>
      <AddCreditCard />
      <div className='d-flex justify-content-between mb-3'>
        <div
          className={classNames(
            's5 align-items-center space-x-3 text-dark-900',
            {
              'cursor-pointer': !isLoading,
              'pointer-event-none pw-none': isLoading
            }
          )}
          onClick={onGoBack}
        >
          <ReturnLeftIcon />
          <span className='text-dark-900'>Return</span>
        </div>
        <Button
          onClick={() => addCard()}
          variant='saffron'
          className='text-uppercase line-height-normal s9 border-radius-normal'
          disabled={isLoading}
          isLoading={isLoading}
          size='md'
        >
          Add Card
        </Button>
      </div>
    </div>
  )
}

const UserCardWrapperWithStripe = ({ onSuccess, onGoBack, API }) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans'
          }
        ]
      }}
    >
      <AddCardForm onSuccess={onSuccess} onGoBack={onGoBack} API={API} />
    </Elements>
  )
}

export default UserCardWrapperWithStripe
