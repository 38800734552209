import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { useParams } from 'react-router'
import { Form } from 'react-bootstrap'
import CKEditor from '../components/editor'
import {
  Button,
  FeatherLoader,
  ABSpinner,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import API from '../utils/API'
import { SelectionField } from '../components/common'

const welcomeEmailType = [
  { value: 'SEND_TO_ALL_USER', label: 'Send this email to all users' },
  { value: 'SEND_TO_PAID_USER', label: 'Send this email to only paid users' },
]

const errorObject = {
  SELECT_EMAIL_TYPE: 'Please select email type.',
  CK_EDITOR_EMPTY: 'Please enter custom email body.',
  TITLE_EMPTY: 'Please enter custom email title.',
  TITLE_CHARACTER_LIMIT_EXCEEDED:
    'Character limit exceeded. Max allowed characters for Title is 100.',
}

const initialState = {
  onEmailType: false,
  onCkEditor: false,
  onEmailTitle: false,
}

const WelcomeEmail = () => {
  const { ecoSystemName } = useParams()
  const [editorData, setEditorData] = useState('')
  const [emailType, setEmailType] = useState('')
  const [hasError, setHasError] = useState(initialState)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const [emailTitle, setEmailTitle] = useState('')

  useEffect(() => {
    const getWelcomeEmailBody = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.ecoSystem.getWelcomeEmailBody(
          ecoSystemName,
        )
        if (data?.welcomeEmail) {
          setEditorData(data?.welcomeEmail)
        }
        if (data?.welcomeEmailType) {
          setEmailType(data?.welcomeEmailType)
        }
        if (data?.welcomeEmailTitle) {
          setEmailTitle(data?.welcomeEmailTitle)
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getWelcomeEmailBody()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (emailType === welcomeEmailType[0].value) {
      setEmailTitle('')
    }
  }, [emailType])

  const saveEmailHandler = async () => {
    setHasError({
      onEmailType: emailType === '',
      onCkEditor: editorData === '',
      onEmailTitle:
        emailTitle === '' || !emailTitle || emailTitle?.length > 100,
    })
    if (emailType === '') {
      Notification.showNotification(errorObject.SELECT_EMAIL_TYPE, 'danger')
      return
    } else if (editorData === '' || !editorData) {
      Notification.showNotification(errorObject.CK_EDITOR_EMPTY, 'danger')
      return
    } else if (
      (emailTitle === '' || !emailTitle || emailTitle?.length > 100) &&
      emailType === welcomeEmailType[1].value
    ) {
      const errorMessage =
        emailTitle?.length > 100
          ? errorObject.TITLE_CHARACTER_LIMIT_EXCEEDED
          : errorObject.TITLE_EMPTY
      Notification.showNotification(errorMessage, 'danger')
      return
    } else {
      setIsSubmitting(true)
      try {
        const { data } = await API.AdminAPI.ecoSystem.updateWelcomeEmailBody({
          data: editorData,
          welcomeEmailType: emailType,
          ...(emailType === welcomeEmailType[1].value && {
            welcomeEmailTitle: emailTitle,
          }),
          name: ecoSystemName,
        })
        if (data) {
          setIsSubmitting(false)
          setHasError(initialState)
          Notification.showNotification(
            'Welcome email has been updated!',
            'success',
          )
        }
      } catch (e) {
        Error.showError(e)
        setIsSubmitting(false)
      }
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{`${ecoSystemName}'s Welcome Email | Abroad`}</title>
        <meta
          name='title'
          content={`${ecoSystemName}'s Welcome Email | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${ecoSystemName}'s Welcome Email | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${ecoSystemName}'s Welcome Email | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/network/${ecoSystemName}/welcome-email`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/network/${ecoSystemName}/welcome-email`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/network/${ecoSystemName}/welcome-email`}></meta>
      </Helmet>
      <main>
        <section className='mt-40'>
          <Form.Group className='profile'>
            <Form.Label>Email Type</Form.Label>
            <SelectionField
              name='emailType'
              size='lg'
              options={welcomeEmailType}
              getValueAsOption={true}
              optionplaceholder='Select email type'
              sm='10'
              className={classNames({
                'bg-white cursor-pointer': !(isLoading || isSubmitting),
                'is-disabled': isLoading || isSubmitting,
                'is-invalid': hasError.onEmailType,
              })}
              isUseFormikSelectionField={false}
              value={emailType}
              onChange={(e) => {
                setHasError((prev) => ({ ...prev, onEmailType: false }))
                setEmailType(e.target.value)
              }}
              getKey
              disabled={isLoading || isSubmitting}
            />
          </Form.Group>
          {emailType === welcomeEmailType[1].value && (
            <Form.Group className='profile'>
              <Form.Label>Email Title</Form.Label>
              <Form.Control
                type='text'
                value={emailTitle}
                className={classNames({
                  'is-invalid': hasError.onEmailTitle,
                })}
                placeholder='Enter Email Title'
                onChange={(e) => {
                  const value = e.target.value
                  const hasError = value === '' || !value || value?.length > 100
                  setHasError((prev) => ({ ...prev, onEmailTitle: hasError }))
                  setEmailTitle(value)
                }}
                disabled={isLoading || isSubmitting}
                size='lg'
                sm='10'
              />
            </Form.Group>
          )}
          <div className='s5 mb-3'>
            <b>
              {emailType === welcomeEmailType[1].value
                ? 'Instruction'
                : 'Welcome'}{' '}
              Email Copy
            </b>{' '}
            (This will be common for all people who registers - It does not
            support dynamic variable for now so address it in a common address
            fashion even though it will be sent seperately to each user on
            registration)
          </div>
          {isLoading ? (
            <FeatherLoader width={60} />
          ) : (
            <CKEditor
              data={editorData}
              onReady={(editor) => {
                if (editor) {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      'height',
                      '70vh',
                      editor.editing.view.document.getRoot(),
                    )
                  })
                }
              }}
              onChange={(event, editor) => {
                setHasError((prev) => ({ ...prev, onCkEditor: false }))
                setEditorData(editor.getData())
              }}
              disabled={isSubmitting}
            />
          )}
          <div className='mt-3 text-right'>
            <Button
              variant='saffron'
              size='lg'
              onClick={saveEmailHandler}
              isLoading={isSubmitting}
              disabled={isSubmitting || isLoading}
              className='border-radius-3 line-height-normal'>
              Save
            </Button>
          </div>
        </section>
      </main>
    </>
  )
}

export default WelcomeEmail
