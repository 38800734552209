import React, { useEffect, useState } from 'react'
import { Form as RBForm, Row, Col, Container } from 'react-bootstrap'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { Formik, Form, Field } from 'formik'
import { string, number, object, date } from 'yup'
import { useParams } from 'react-router-dom'
import { InputField, SingleDatePicker } from '../form'
import { isLGScreen } from '../../utils/utility'
import API from '../../utils/API'
import { formatDate } from '../../utils/date'
import moment from 'moment'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const formInitialValues = {
  agreementSignedAt: null,
  sessionsPerMonth: '',
  paymentToCoachPerHour: '',
  contractDuration: '',
  totalPlan: '',
  paymentUpToDate: 'no',
}

const validationSchema = object().shape({
  agreementSignedAt: date()
    .required()
    .test('valid-date', 'Please enter valid date!', function (value) {
      const checkDate = moment(value, 'MM/DD/YYYY')
      return checkDate.isValid()
    }),
  sessionsPerMonth: number().min(1).max(1000).required(),
  paymentToCoachPerHour: number().min(0).max(1000).required(),
  contractDuration: number().min(1).max(36).required(),
  totalPlan: number().min(0).max(100000).required(),
  paymentUpToDate: string().required(),
})

export const TeamGrowthPlan = ({ teamMetadata = { isArchived: false } }) => {
  const { user } = useUserService()
  const [isLoading, setIsLoading] = useState(true)
  const [isTeamCreateLoading, setIsTeamCreateLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const { teamId } = useParams()

  const fieldDisabled = !user?.isAdmin || teamMetadata?.isArchived

  useEffect(() => {
    const getTeamData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(teamId, {
          fields: [
            'sessionsPerMonth',
            'hourlyPayToCoach',
            'contractDuration',
            'planTotal',
            'hasPaid',
            'coaches',
            'agreementSignedAt',
          ],
        })
        if (data) {
          setInitialValues((prev) => ({
            ...prev,
            agreementSignedAt: formatDate(data?.agreementSignedAt),
            sessionsPerMonth: data?.sessionsPerMonth,
            paymentToCoachPerHour: data?.hourlyPayToCoach,
            contractDuration: data?.contractDuration,
            totalPlan: data?.planTotal,
            paymentUpToDate: data?.hasPaid ? 'yes' : 'no',
          }))
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getTeamData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values) => {
    let params = {
      agreementSignedAt: moment(values?.agreementSignedAt, 'MM/DD/YYYY').format(
        'YYYY-MM-DD',
      ),
      sessionsPerMonth: values.sessionsPerMonth,
      hourlyPayToCoach: values.paymentToCoachPerHour,
      contractDuration: values.contractDuration,
      planTotal: values.totalPlan,
      hasPaid: values.paymentUpToDate === 'yes' ? true : false,
    }

    Object.keys(params).forEach(
      (key) => params[key] === '' && delete params[key],
    )

    try {
      setIsTeamCreateLoading(true)
      const { data } = await API.AdminAPI.teams.updateTeam(teamId, params)
      if (data) {
        setIsTeamCreateLoading(false)
        Notification.showNotification('Group has been updated!', 'success')
      }
    } catch (e) {
      setIsTeamCreateLoading(false)
      Error.showError(e)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <Container className='mt-5 px-lg-0' fluid>
      <Formik
        displayName='profile-form'
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnMount
        onSubmit={onSubmit}>
        {({ values, handleSubmit }) => {
          return (
            <RBForm
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit(e)
              }}
              as={Form}
              className='profile px-4 px-lg-0'>
              {user?.isAdmin && (
                <>
                  <SingleDatePicker
                    label='Group agreement Sign'
                    labelProps={labelProps}
                    name='agreementSignedAt'
                    className='pointer-cursor user-input bg-white'
                    spanClassName='mb-3'
                    disabled={fieldDisabled}
                    minimumDate={moment().subtract(1, 'years')}
                    maximumDate={moment().add(1, 'years')}
                  />
                  <InputField
                    label='SESSIONS PER MONTH'
                    labelProps={labelProps}
                    name='sessionsPerMonth'
                    type='number'
                    size='md'
                    sm='10'
                    disabled={fieldDisabled}
                  />
                  <InputField
                    label='PAYMENT TO COACH PER HOUR (USD)'
                    labelProps={labelProps}
                    name='paymentToCoachPerHour'
                    type='number'
                    size='md'
                    sm='10'
                    disabled={fieldDisabled}
                  />
                  <InputField
                    label='CONTRACT DURATION (MONTHS)'
                    labelProps={labelProps}
                    name='contractDuration'
                    type='number'
                    size='md'
                    sm='10'
                    disabled={fieldDisabled}
                  />
                  <InputField
                    label='PLAN TOTAL (USD)'
                    labelProps={labelProps}
                    name='totalPlan'
                    type='number'
                    size='md'
                    sm='10'
                    disabled={fieldDisabled}
                  />
                  <Row className='d-flex'>
                    <Col lg={2} md={2} sm={2} className='p-0 pl-lg-1'>
                      <RBForm.Label className='text-right pl-lg-4'>
                        PAYMENT UP TO DATE?
                      </RBForm.Label>
                    </Col>
                    <Col className='pl-lg-0'>
                      <label>
                        <Field
                          type='radio'
                          name='paymentUpToDate'
                          value='yes'
                          className='ml-2'
                          disabled={fieldDisabled}
                        />
                        YES
                      </label>
                      <label>
                        <Field
                          type='radio'
                          name='paymentUpToDate'
                          value='no'
                          className='ml-2'
                          disabled={fieldDisabled}
                        />
                        NO
                      </label>
                    </Col>
                  </Row>
                  {!fieldDisabled && (
                    <div className='text-right'>
                      <Button
                        isLoading={isTeamCreateLoading}
                        disabled={isTeamCreateLoading}
                        type='submit'
                        variant='gray'
                        size='md'
                        className='line-height-normal border-radius-3 text-uppercase'>
                        UPDATE
                      </Button>
                    </div>
                  )}
                </>
              )}
            </RBForm>
          )
        }}
      </Formik>
    </Container>
  )
}
