import React, { useEffect, useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import { Form as RBForm, Row, Col } from 'react-bootstrap'
import { string, object, array } from 'yup'
import {
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'

import { InputField } from '../form'
import { ObjectivesContext } from '../../utils/contexts'
import API from '../../utils/API'
import ObjectivesField from './ObjectivesField'

const ObjectivesForm = ({ id }) => {
  const { data, setData } = useContext(ObjectivesContext)
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const maxCharsAllowInDescription = 1000
  const maxCharsAllowInObjectives = 100
  const emptyArray = ['', '', '', '', '']

  const formInitialValues = {
    description: '',
    objectivesList: emptyArray,
  }
  const validationSchema = object().shape({
    description: string()
      .required('This is required field, please add description.')
      .min(2, 'Must be at least 2 characters')
      .max(
        maxCharsAllowInDescription,
        `Must be at most ${maxCharsAllowInDescription} characters`,
      ),
    objectivesList: array()
      .of(
        string()
          .required('This is required field, please add objectives.')
          .min(2, 'Must be at least 2 characters')
          .max(
            maxCharsAllowInObjectives,
            `Must be at most ${maxCharsAllowInObjectives} characters`,
          ),
      )
      .test(
        'unique',
        (ctx) => {
          let arr = ctx.value
          let length = arr.filter((a) => a !== undefined).length
          let obj = {}
          if (length > 1)
            obj[0] =
              'This principles has already been added. Please add another objectives.'
          if (Object.keys(obj).length === 0) return null
          else return obj
        },
        (values) => {
          return new Set(values).size === values.length
        },
      ),
  })
  const [initialValues, setInitialValues] = useState(formInitialValues)
  useEffect(() => {
    if (data) {
      setInitialValues({
        description: data.description,
        objectivesList: data.objectivesList,
      })
    }
  }, [data])

  const updateMetadata = async (obj) => {
    try {
      setIsLoading(true)
      const { data } = await API.AdminAPI.companyList.updateCompanyMetdata(
        id,
        'objectives',
        obj,
      )
      if (data) {
        Notification.showNotification(data?.message, 'success')
      }
    } catch (e) {
      Error.showError(e)
    }
    setIsLoading(false)
  }

  const onSubmit = async (values) => {
    try {
      await updateMetadata({
        objectives: values,
      })
      setData(values)
    } catch (e) {
      Error.showError(e)
    }
  }

  return (
    <Formik
      displayName='objectives'
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={onSubmit}>
      {({ values, handleSubmit }) => {
        return (
          <RBForm
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(e)
            }}
            as={Form}
            className='purpose-vision-form objectives'>
            <Row>
              <Col className='col-12 objectives-form'>
                <h3 className='font-open-sans font-bold font-18 leading-relaxed align-items-center letter-spacing-minus-04 mb-3'>
                  Objectives Introduction
                </h3>
                <InputField
                  name='description'
                  as='textarea'
                  size='lg'
                  rows='10'
                  placeholder='e.g, 5% increase in team member self awareness'
                  sm='12'
                  inputClasses='into-area font-open-sans font-14 font-semibold leading-6 letter-spacing-minus-02'
                  showErrorMessage={true}
                  hasErrorMessagePadding={false}
                />
              </Col>
              <Col className='col-12 col-lg-10 mt-40'>
                <h3 className='font-open-sans font-bold font-18 leading-relaxed align-items-center letter-spacing-minus-04 mb-3'>
                  Objectives
                </h3>
                <ObjectivesField objectives={values.objectivesList} />
              </Col>
            </Row>
            <Button
              variant='gray'
              size='md'
              className='line-height-normal text-uppercase mt-4 border-radius-3'
              isLoading={isLoading}
              disabled={isLoading}
              type='submit'>
              Submit
            </Button>
          </RBForm>
        )
      }}
    </Formik>
  )
}

export default ObjectivesForm
