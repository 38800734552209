import React from 'react'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory, Link, useParams } from 'react-router-dom'
import { ReturnPageIcon } from '../assetsComponents'
import { formatDate } from '../../utils/date'

const GrowthPlanHeader = ({ fullName, date }) => {
  const history = useHistory()
  const { clientId } = useParams()

  const renderGoBackIcon = () => {
    return (
      <div className='align-self-center'>
        <ReturnPageIcon
          onClick={() => history.goBack()}
          height='12px'
          width='6.23px'
        />
      </div>
    )
  }

  return (
    <section className='py-5 bg-gray-100 header-shadow platform-header d-flex align-items-center'>
      <Container fluid>
        <Row>
          <Col className='custom-offset-lg-1 custom-col-lg-12 d-flex'>
            <div
              className={classNames('d-flex', {
                'flex-column': fullName,
              })}>
              {!fullName && renderGoBackIcon()}
              {fullName && (
                <div className='s6 d-flex'>
                  {renderGoBackIcon()}
                  <Link
                    className='text-dark-900'
                    to={`/coach/clients/${clientId}/growth-plan`}>
                    {fullName}
                  </Link>
                </div>
              )}
              <h3
                className={classNames('text-dark-600 page-label mb-0', {
                  'ml-4': fullName,
                })}>{`Growth Plan 
                  ${date ? ` - ${formatDate(date)}` : ''}
                  `}</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default GrowthPlanHeader
