const accountConstants = {
  ACCOUNTING_TYPE_FILTER: {
    All: '1',
    Group: '2',
    Personal: '3',
    // Enterprise: '4', // temporary commented
  },
}

export default accountConstants
