import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '@abroad/components'

export const DeleteConfirmationPrompt = ({
  showModal,
  setShowModal,
  isDeleteLoading,
  handleDelete,
  message,
  showCancelButton = true,
  onHide = () => {},
  buttonConfirmText = 'OK',
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false)
        onHide()
      }}
      centered>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {showCancelButton && (
          <Button
            variant='none'
            className='confirmation-btn text-uppercase secondary-popup-button'
            size='sm'
            onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        )}
        <Button
          variant='saffron'
          className='confirmation-btn text-uppercase'
          size='sm'
          onClick={() => handleDelete()}
          isLoading={isDeleteLoading}
          disabled={isDeleteLoading}>
          {buttonConfirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
