import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import {
  FeatherLoader,
  useErrorService,
  useUserService,
} from '@abroad/components'
import API from '../../utils/API'
import { formatDate } from '../../utils/date'

const GrowthPlanSOWTable = ({ growthPlanDetails }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [tableData, setTableData] = useState(null)
  const { clientId } = useParams()
  const { user } = useUserService()
  const Error = useErrorService()

  useEffect(() => {
    const getGrowthSOW = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.coachPlan.getGrowthSOW(clientId)
        if (data) {
          setTableData(data)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (growthPlanDetails.isGrowthPlanExists) getGrowthSOW()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [growthPlanDetails.isGrowthPlanExists, clientId])

  useEffect(() => {
    const getGrowthSessionList = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.session.getGrowthSessionList(
          clientId,
        )
        if (data) {
          setTableData(data)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (growthPlanDetails.isGrowthSessionDone) getGrowthSessionList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [growthPlanDetails.isGrowthSessionDone, clientId])

  return (
    <Table hover responsive className='custom-table mb-0'>
      <thead>
        <tr>
          <th width='34%'>Coach</th>
          {growthPlanDetails.isGrowthPlanExists &&
            !growthPlanDetails.isGrowthSessionDone && (
              <th width='33%'>SOW Name</th>
            )}
          {growthPlanDetails.isGrowthSessionDone && <th width='33%'>Date</th>}
          <th width='33%'>Action</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && (
          <tr>
            <td colSpan='3' className='text-center border-0'>
              <FeatherLoader />
            </td>
          </tr>
        )}
        {!isLoading && (
          <>
            {!user?.isAdmin &&
            tableData?.coachId?._id !== user?._id &&
            !growthPlanDetails?.isGrowthSessionDone ? (
              <tr>
                <td colSpan='3' className='text-center border-0'>
                  There is no growth plan taken yet.
                </td>
              </tr>
            ) : (
              <tr>
                <td width='34%'>
                  {user?.isAdmin ? (
                    <Link
                      to={`/admin/coaches/${tableData?.coachId?._id}`}
                      className='text-dark-900'>
                      {tableData?.coachId?.fullName}
                    </Link>
                  ) : (
                    <>{tableData?.coachId?.fullName}</>
                  )}
                </td>
                {growthPlanDetails.isGrowthPlanExists &&
                  !growthPlanDetails.isGrowthSessionDone && (
                    <td width='33%'>{tableData?.planName}</td>
                  )}
                {growthPlanDetails.isGrowthSessionDone && (
                  <td width='33%'>{formatDate(tableData?.date)}</td>
                )}
                <td width='33%'>
                  {tableData?.coachId?._id !== user?._id &&
                  !growthPlanDetails?.isGrowthSessionDone ? (
                    <span>No Session Taken</span>
                  ) : (
                    <Link
                      className='text-saffron-700'
                      to={`/coach/clients/${clientId}/growth-plan`}>
                      {tableData?.coachId?._id === user?._id &&
                      !growthPlanDetails?.isGrowthSessionDone
                        ? 'Add Growth Plan'
                        : 'View Session'}
                    </Link>
                  )}
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </Table>
  )
}

export default GrowthPlanSOWTable
