import React, { useContext } from 'react'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { Container, Navbar, Row } from 'react-bootstrap'
import {
  useUserService,
  ABTooltip,
  ProfileDropdown,
  useErrorService,
  AbroadIconForSM,
} from '@abroad/components'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import GlobalSearchInput from './GlobalSearchInput'
import { LayoutContext } from '../../utils/contexts'
import API from '../../utils/API'
import AuthService from '../../utils/AuthService'
import i18n from '../../utils/i18n'

const Header = () => {
  const { showSidebar, setShowSidebar } = useContext(LayoutContext)
  const { setUser } = useUserService()
  let history = useHistory()
  const Error = useErrorService()
  let { user } = useUserService()
  const profileURL = [
    `/admin/${user?._id}/profile`,
    `/coach/${user?._id}/profile`,
    `/admin/profile/${user?._id}`,
  ]
  const matchedURL = profileURL.includes(history.location.pathname)

  const onLogout = async () => {
    try {
      await API.ClientAPI.onboarding.logout()
      setUser(null)
      AuthService.removeData()
      history.push('/client-redirect')
    } catch (e) {
      Error.showError(e)
    }
  }
  return (
    <Container fluid>
      <Row>
        <div className='custom-col-12 custom-offset-lg-1 px-0'>
          <Navbar className='d-none d-lg-flex nav-header px-0'>
            <Navbar.Brand
              className={classnames('position-fixed pl-0', {
                'd-none': showSidebar,
                'd-lg-flex': !showSidebar,
              })}>
              <svg
                onClick={() => setShowSidebar(true)}
                className='cursor-pointer'
                xmlns='http://www.w3.org/2000/svg'
                width='35.751'
                height='17.992'
                viewBox='0 0 35.751 17.992'>
                <defs>
                  <style>
                    {`.header-a{fill:none;stroke:#959595;stroke-linecap:round;stroke-width:1.5px;}`}
                  </style>
                </defs>
                <g transform='translate(-26.494 -22.504)'>
                  <line
                    className='header-a'
                    x2='21.99'
                    transform='translate(32.505 23.254)'
                  />
                  <line
                    className='header-a'
                    x2='21.99'
                    transform='translate(32.505 31.5)'
                  />
                  <line
                    className='header-a'
                    x2='21.99'
                    transform='translate(32.505 39.746)'
                  />
                </g>
              </svg>
            </Navbar.Brand>
            <Navbar.Collapse className='justify-content-end space-x-3 py-2'>
              <div className='searchbox-wrapper'>
                <GlobalSearchInput />
              </div>
              <ProfileDropdown
                render={() => {}}
                onLogout={onLogout}
                i18n={i18n}
                profile={() => {
                  return (
                    <Dropdown.Item
                      as={Link}
                      to={
                        user.isAdmin && user.isCoach
                          ? `/admin/${user?._id}/profile`
                          : !user.isAdmin && user.isCoach
                          ? `/coach/${user?._id}/profile`
                          : user.isAdmin && !user.isCoach
                          ? `/admin/profile/${user?._id}`
                          : `/`
                      }
                      eventKey='2'
                      active={matchedURL}
                      className='text-left'>
                      <Trans i18n={i18n}>Profile</Trans>
                    </Dropdown.Item>
                  )
                }}
                url1={process.env.REACT_APP_ALTERNATE_DOMAIN}
                url2={process.env.REACT_APP_DOMAIN}
              />
            </Navbar.Collapse>
          </Navbar>
          {/* Mobile header */}
          <Navbar
            fixed='top'
            className='d-flex d-lg-none bg-gray-104 align-items-center'>
            <Navbar.Brand className='mr-0 leading-none'>
              <AbroadIconForSM
                onClick={() => history.push('/admin/home')}
                className='pointer-cursor abroad-sm-icon'
              />
            </Navbar.Brand>
            <Navbar.Collapse className='position-relative justify-content-end'>
              <Navbar.Text className='d-flex'>
                <ABTooltip
                  customPlacement
                  placement='bottom-end'
                  controllId='header-tooltip'
                  contentText='Using the top section of our Menu, you can access all of the features across our entire platform'
                  className='xs-header-tooltip-icon'
                />
                {showSidebar && (
                  <>
                    <ProfileDropdown
                      render={() => {}}
                      onLogout={onLogout}
                      i18n={i18n}
                      profile={() => {
                        return (
                          <Dropdown.Item
                            as={Link}
                            to={
                              user.isAdmin && user.isCoach
                                ? `/admin/${user?._id}/profile`
                                : !user.isAdmin && user.isCoach
                                ? `/coach/${user?._id}/profile`
                                : user.isAdmin && !user.isCoach
                                ? `/admin/profile/${user?._id}`
                                : `/`
                            }
                            eventKey='2'
                            active={matchedURL}
                            className='text-left'>
                            <Trans i18n={i18n}>Profile</Trans>
                          </Dropdown.Item>
                        )
                      }}
                    />
                  </>
                )}
                <svg
                  onClick={() => setShowSidebar(true)}
                  className={classnames('cursor-pointer', {
                    'd-none': showSidebar,
                  })}
                  xmlns='http://www.w3.org/2000/svg'
                  width='35.751'
                  height='17.992'
                  viewBox='0 0 35.751 17.992'>
                  <defs>
                    <style>
                      {`.header-sm-a{fill:none;stroke:#000;stroke-linecap:round;stroke-width:1.5px;}`}
                    </style>
                  </defs>
                  <g transform='translate(-26.494 -22.504)'>
                    <line
                      className='header-sm-a'
                      x2='21.99'
                      transform='translate(32.505 23.254)'
                    />
                    <line
                      className='header-sm-a'
                      x2='21.99'
                      transform='translate(32.505 31.5)'
                    />
                    <line
                      className='header-sm-a'
                      x2='21.99'
                      transform='translate(32.505 39.746)'
                    />
                  </g>
                </svg>

                <svg
                  onClick={() => setShowSidebar(false)}
                  className={classnames('cursor-pointer ml-4', {
                    'd-none': !showSidebar,
                  })}
                  xmlns='http://www.w3.org/2000/svg'
                  width='26.615'
                  height='22.615'
                  viewBox='0 0 26.615 22.615'>
                  <defs>
                    <style>
                      {`.arrow-a{fill:none;stroke:#000;stroke-linecap:round;stroke-width:2px;}`}
                    </style>
                  </defs>
                  <g transform='translate(-330.192 -30.192)'>
                    <line
                      className='arrow-a'
                      x1='24'
                      y1='10'
                      transform='translate(331.5 41.5)'
                    />
                    <line
                      className='arrow-a'
                      x1='24'
                      y2='10'
                      transform='translate(331.5 31.5)'
                    />
                  </g>
                </svg>
              </Navbar.Text>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Row>
    </Container>
  )
}

export default Header
