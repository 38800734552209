import React from 'react'
import labels from '../constants/labels'

export const LayoutContext = React.createContext({
  showSidebar: window.innerWidth > 992 ? true : false,
  setShowSidebar: () => {},
  showPlayBar: false,
  setShowPlayBar: () => {},
  minimizePlayBar: false,
  setMinimizePlayBar: () => {},
  mediaObject: null,
  setMediaObject: () => {},
})

export const CompanyMetadataContext = React.createContext({
  setData: () => {},
  data: null,
})

export const ObjectivesContext = React.createContext({
  setData: () => {},
  data: null,
})

export const SessionInsightsContext = React.createContext({
  setIsSortByAsc: () => {},
  isSortByAsc: true,
})

export const JourneyMediasContext = React.createContext({
  setMediasList: () => {},
  mediasList: [],
})

export const OrgEcoIdsContext = React.createContext({
  ids: [],
  setIds: () => {},
  isLoadNewData: false,
  setIsLoadNewData: () => {},
  selectedSOWUsers: [],
  setSelectedSOWUsers: () => {},
  selectedGroupisArchived: () => {},
  companyAndEcoInfo: null,
  setCompanyAndEcoInfo: () => {},
  isCsvUploaded: {
    showRosterUI: true,
    showBtn: false,
  },
  setIsCsvUploaded: () => {},
  isShowAddBtn: {
    hideBtn: true,
    showTable: true,
  },
  setIsShowAddBtn: () => {},
})

export const ListingFiltersContext = React.createContext({
  filters: {
    search: '',
    type: labels.orgFilterDefaultType.SOW_OR_INVITE_ONLY,
    status: labels.orgFilterStatusDefaultType.ORG_ACTIVE,
    sort: 'ASC',
  },
  setFilters: () => {},
})
