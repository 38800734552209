import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import moment from 'moment'
import { Formik } from 'formik'
import {
  useNotificationService,
  useErrorService,
  Button,
  plans,
} from '@abroad/components'
import { SingleDatePickerWithoutFormik } from '../form'
import { formatDate } from '../../utils/date'
import API from '../../utils/API'
import UserPlanUpdate from '../userList/UserPlanUpdate'
import CONSTANTS from '../../utils/constants'

const ClientPlans = ({ clientData, setIsShowCalendar, planChangeHandler }) => {
  const Notification = useNotificationService()
  const [planExpiryLoader, setPlanExpiryLoader] = useState(false)
  const [planEndExpiresOn, setPlanEndExpiresOn] = useState(
    clientData?.expiresOn,
  )
  const [paywallStatus, setPaywallStatus] = useState({
    isLoading: false,
    isPaywallRemoved: false,
  })
  const removePaywallArray = Object.values(CONSTANTS.externalPaymentModeType)
  const [clientPlanCode, setClientPlanCode] = useState(clientData?.planCode)
  const isAdminOrCoach = clientData?.isAdmin || clientData?.isCoach
  const lifeTimePlanLabel = 'Lifetime plan'
  const lifeTimePlanEndDate = '2070'
  const planExpiryYear = moment(planEndExpiresOn).format('YYYY')
  const Error = useErrorService()

  const UpdatePlanEndDateHandler = async (value) => {
    if (isAdminOrCoach || clientPlanCode === plans.free.code) return
    try {
      setPlanExpiryLoader(true)
      const { data } = await API.CoachAPI.coachUser.updatePlanExpiry(
        clientData?._id,
        {
          expiresOn: `${value}T00:00:00.000Z`,
        },
      )
      if (data) {
        setPlanEndExpiresOn(data?.expiresOn)
        setPlanExpiryLoader(false)
        Notification.showNotification(
          'Plan End Date has beet updated!',
          'success',
        )
      }
    } catch (e) {
      setPlanExpiryLoader(false)
      Error.showError(e)
    }
  }

  const removePayWallHandler = async () => {
    try {
      setPaywallStatus({
        isLoading: true,
        isPaywallRemoved: false,
      })
      const { data } = await API.CoachAPI.coachUser.removePaywall({
        userId: clientData?._id,
      })
      if (data) {
        setPaywallStatus({
          isLoading: false,
          isPaywallRemoved: true,
        })
        setPlanEndExpiresOn(data?.expiresOn)
        Notification.showNotification('Paywall removed by admin.', 'success')
      }
    } catch (e) {
      setPaywallStatus({
        isLoading: false,
        isPaywallRemoved: false,
      })
      Error.showError(e)
    }
  }

  const renderPaymentDetails = () => {
    const promoCode = clientData?.payments?.promoCode
    const cardHolderDetailsExist =
      clientData?.payments?.stripeCharges?.paymentMethodDetails?.card?.last4
    const totalPayment = clientData?.payments?.totalPaid
    const stripeChargesId = clientData?.payments?.stripeCharges?.id
    const payPaymentDate = clientData?.payments?.createAt
    const promo = promoCode ? promoCode?.toString()?.toUpperCase() : promoCode
    const isPaidViaOrgNetworkWidePlan =
      clientData?.payments?.isPaidViaOrgWidePlan ||
      clientData?.payments?.isPaidViaNetworkWidePlan

    if (
      (!planEndExpiresOn && clientPlanCode !== plans.free.code) ||
      (planEndExpiresOn &&
        planEndExpiresOn < moment(new Date()).format('YYYY-MM-DD'))
    ) {
      return (
        <div className='d-flex flex-column'>
          <span className='font-open-sans font-10 font-normal leading-3 mb-2'>
            Not any payment yet.
          </span>
          <Button
            variant='gray'
            className='text-uppercase remove-paywall-btn'
            size='sm'
            disabled={paywallStatus?.isLoading}
            isLoading={paywallStatus?.isLoading}
            onClick={removePayWallHandler}>
            Remove Paywall
          </Button>
        </div>
      )
    } else if (
      removePaywallArray.includes(clientData?.externalPaymentMode) ||
      paywallStatus?.isPaywallRemoved
    ) {
      if (
        clientData?.externalPaymentMode ===
        CONSTANTS.externalPaymentModeType.ADMIN_UPGRADE
      ) {
        return (
          <span className='font-open-sans font-10 font-normal leading-3'>
            Admin changed the plan.
          </span>
        )
      } else {
        return (
          <span className='font-open-sans font-10 font-normal leading-3'>
            Paywall removed by admin.
          </span>
        )
      }
    } else if (!promoCode && cardHolderDetailsExist) {
      return (
        <span className='font-open-sans font-10 font-normal leading-3'>
          {/* eslint-disable */}
          <a
            href={
              totalPayment
                ? `https://dashboard.stripe.com/payments/${stripeChargesId}`
                : null
            }
            target='_blank'
            className='cursor-pointer text-saffron-700 payment-link'>
            {`$ ${totalPayment || 'N/A'}`}
          </a>{' '}
          {`on ${formatDate(payPaymentDate)}`}
          {/* eslint-enable */}
        </span>
      )
    } else if (promoCode && cardHolderDetailsExist) {
      return (
        <span className='font-open-sans font-10 font-normal leading-3'>
          {/* eslint-disable */}
          <a
            href={`https://dashboard.stripe.com/payments/${stripeChargesId}`}
            target='_blank'
            className='cursor-pointer text-saffron-700 payment-link'>
            {`$ ${totalPayment}`}
          </a>{' '}
          {`($ ${
            clientData?.payments?.discountedAmount
          } off with Promo code ${promo}) on ${formatDate(payPaymentDate)}`}
          {/* eslint-enable */}
        </span>
      )
    } else if (planEndExpiresOn && clientPlanCode === plans.free.code) {
      return (
        <span className='font-open-sans font-10 font-normal leading-3'>
          User has free plan.
        </span>
      )
    } else if (!clientData?.payments) {
      return (
        <span className='font-open-sans font-10 font-normal leading-3'>-</span>
      )
    } else if (isPaidViaOrgNetworkWidePlan) {
      return (
        <span className='font-open-sans font-10 font-normal leading-3'>
          Paid with B2B plan
        </span>
      )
    } else {
      let promoText = '100% Off with promocode '
      if (promoCode) {
        promoText += `- ${promo || ''}`
      }
      return (
        <span className='font-open-sans font-10 font-normal leading-3'>
          {promoText}
        </span>
      )
    }
  }

  const userPlanChange = (plan, expiresOn) => {
    if (expiresOn) setPlanEndExpiresOn(expiresOn)
    setClientPlanCode(plan)
    planChangeHandler(plan)
    setPaywallStatus({
      isPaywallRemoved: plan !== plans.free.code,
      isLoading: false,
    })
  }

  return (
    <section className='client-plans'>
      <div className='d-flex flex-column'>
        <div className='d-flex mb-3'>
          <Col className='font-montserrat font-10 font-bold leading-3 tracking-lg-wide align-self-center col-5 pl-0'>
            PLAN:
          </Col>
          <span className='font-open-sans font-10 font-normal leading-3 tracking-normal user-plan-dropdown col-7'>
            <Formik
              validateOnBlur={false}
              initialValues={{}}
              enableReinitialize={false}>
              <UserPlanUpdate
                userId={clientData?.id}
                planCode={clientData?.planCode}
                userEmail={clientData?.email}
                selectionClasses='w-100'
                inputClasses='mb-0'
                onPlanChangeHandler={userPlanChange}
                isAdminOrCoach={isAdminOrCoach}
                key={clientData?.planCode}
              />
            </Formik>
          </span>
        </div>

        <div className='d-flex mb-3'>
          <Col className='font-montserrat font-10 font-bold leading-3 tracking-lg-wide d-flex align-self-center col-5 pl-0'>
            START:
          </Col>
          <Col className='font-open-sans font-10 font-normal leading-3 tracking-normal col-7'>
            {formatDate(clientData?.createdAt)}
          </Col>
        </div>

        <div className='d-flex mb-3'>
          <Col className='font-montserrat font-10 font-bold leading-3 tracking-lg-wide d-flex align-self-center col-5 pl-0'>
            END:
          </Col>
          <Col className='col-7'>
            {planEndExpiresOn ? (
              <>
                {planExpiryYear === lifeTimePlanEndDate ? (
                  <span className='font-open-sans font-10 font-normal leading-3 tracking-normal'>
                    {lifeTimePlanLabel}
                  </span>
                ) : (
                  <SingleDatePickerWithoutFormik
                    className='planend-datepicker user-input font-open-sans font-10 font-normal leading-3'
                    labelClasses='font-montserrat font-10 font-bold leading-3 tracking-lg-wide'
                    minimumDate={moment(clientData?.createdAt)}
                    onDateApply={UpdatePlanEndDateHandler}
                    value={planEndExpiresOn}
                    key={planEndExpiresOn}
                    disabled={
                      isAdminOrCoach ||
                      planExpiryLoader ||
                      clientPlanCode === plans.free.code
                    }
                    onShow={() => setIsShowCalendar(true)}
                    onHide={() => setIsShowCalendar(false)}
                  />
                )}
              </>
            ) : (
              <span className='font-open-sans font-10 font-normal leading-3 tracking-normal'>
                Not paid
              </span>
            )}
          </Col>
        </div>

        <div className='d-flex mb-3'>
          <Col className='font-montserrat font-10 font-bold leading-3 tracking-lg-wide d-flex align-self-center col-5 pl-0'>
            PAYMENT:
          </Col>
          <Col className='font-open-sans font-10 font-normal leading-3 tracking-normal col-7'>
            {renderPaymentDetails()}
          </Col>
        </div>

        {clientData?.promoCode && (
          <div className='d-flex mb-3'>
            <Col className='font-montserrat font-10 font-bold leading-3 tracking-lg-wide d-flex align-self-center col-5 pl-0'>
              PROMO CODE:
            </Col>
            <Col className='font-open-sans font-10 font-normal leading-3 tracking-normal col-7'>
              <a
                href={`/admin/promo?code=${clientData?.promoCode}`}
                className='text-saffron-700 payment-link text-uppercase'>
                {clientData.promoCode}
              </a>
            </Col>
          </div>
        )}
      </div>
    </section>
  )
}

export default ClientPlans
