import React from 'react'
import { Link } from 'react-router-dom'
import { Button, useUserService, sows } from '@abroad/components'
import labels from '../../constants/labels'

const CoachingPlansList = ({
  coachingPlans = [],
  b2bPlans = [],
  isListRenderOnModal = false,
  handleRemoveClient = () => {},
  buttonDisabledProps = false,
}) => {
  const { user } = useUserService()

  const renderB2BPlans = () => {
    return (
      <React.Fragment>
        {b2bPlans?.map((b2bPlan, index) => (
          <React.Fragment key={index}>
            {user?.isAdmin && !b2bPlan?.isDeleted ? (
              <Link
                to={`/admin/${
                  b2bPlan?.companyId ? 'organization' : 'network'
                }/${b2bPlan?.companyId?.name || b2bPlan?.networkId?.name}/sow/${
                  b2bPlan?._id
                }/users`}
                className='text-saffron-700 cursor-pointer'
                target='_blank'>
                {sows.prefix.b2b}
                {b2bPlan?.planName}
              </Link>
            ) : (
              <span>
                {sows.prefix.b2b}
                {b2bPlan?.planName}
              </span>
            )}
            <br />
          </React.Fragment>
        ))}
        {b2bPlans?.length === 0 && isListRenderOnModal && 'no plans.'}
      </React.Fragment>
    )
  }

  const renderIndividualPlans = () => {
    return (
      <React.Fragment>
        {coachingPlans?.map((plan, planIndex) => (
          <Link
            className='text-saffron-700 cursor-pointer'
            key={planIndex}
            to={`/coach/clients/${plan?.clientId}/plans/${plan?._id}`}
            target='_blank'>
            {sows.prefix.individual}
            {plan.planName}
            <br />
          </Link>
        ))}
        {coachingPlans?.length === 0 && isListRenderOnModal && 'no plans.'}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {isListRenderOnModal ? (
        <tr>
          <td width='50%'>
            {renderIndividualPlans()}
            {coachingPlans?.length > 0 && (
              <Button
                variant='danger'
                size='md'
                className='border-radius-3 confirm-btn line-height-normal mt-3'
                disabled={buttonDisabledProps}
                onClick={() =>
                  handleRemoveClient(
                    labels.removeCoachFromUsersTypes.ALL_INDIVIDUAL_SOWS,
                  )
                }>
                Remove From {sows.label.individual} SOW
              </Button>
            )}
          </td>
          <td width='50%'>
            {renderB2BPlans()}
            {b2bPlans?.length > 0 && (
              <Button
                variant='danger'
                size='md'
                className='border-radius-3 confirm-btn line-height-normal mt-3'
                disabled={buttonDisabledProps}
                onClick={() =>
                  handleRemoveClient(
                    labels.removeCoachFromUsersTypes.ALL_B2B_SOWS,
                  )
                }>
                Remove From {sows.label.b2b} SOW
              </Button>
            )}
          </td>
        </tr>
      ) : (
        <React.Fragment>
          {renderIndividualPlans()}
          {renderB2BPlans()}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default CoachingPlansList
