import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import { useIntentionReducer } from './Reducer'
import API from '../../utils/API'
import { getArchivedIntentionDate } from '../../utils/date'

const ArchivedIntentions = () => {
  const { state, dispatch } = useIntentionReducer()
  const Error = useErrorService()
  const { clientId } = useParams()
  useEffect(() => {
    const getArchivedIntentions = async () => {
      try {
        let { data } = await API.CoachAPI.coachClient.getIntentions(clientId, {
          archived: true,
        })
        dispatch({ type: 'UPDATE_ARCHIVED_INTENTION', payload: data })
      } catch (e) {
        Error.showError(e)
      }
    }
    getArchivedIntentions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (state.archivedIntentions.length === 0) {
    return null
  }

  return (
    <div className='mt-3 pt-5 px-0 custom-offset-lg-1 custom-col-lg-12'>
      <h2 className='font-30 font-lg-44 font-montserrat font-semibold'>
        Archived Intentions
      </h2>
      <div className='mt-3'>
        {state.archivedIntentions.map((intention, index) => (
          <div key={intention._id} className='pt-3 pb-2 archived-intention'>
            <span className='font-montserrat font-semibold mb-1 font-14 d-block d-lg-none'>
              Logged: &nbsp;
              {getArchivedIntentionDate(intention.createdAt)}
            </span>
            <h4 className='font-montserrat font-medium'>{intention?.title}</h4>
            <div className='d-flex justify-content-between text-right align-items-center'>
              <div>
                <span className='font-montserrat font-semibold font-12 d-none d-lg-inline-block'>
                  Logged: &nbsp;
                  {getArchivedIntentionDate(intention.createdAt)}
                </span>
              </div>
              <p className='font-montserrat cursor-pointer text-saffron-700 h5 mb-0 tracking-lg-wide text-uppercase cursor-disabled underline'>
                Restore Intention
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ArchivedIntentions
