import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Container } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import { IndeterminateCheckbox, Table, TableInstance } from '../table'
import CONSTANTS from '../../utils/constants'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'
import { ClearFilters, FilterSelection } from '../common'
import UserSearch from '../company/UserSearch'

const intialGlobalFilter = {
  search: '',
  filterByPL: null,
}

const GroupUsersTable = ({ setIsMembersEmpty, disabled }) => {
  const hasMore = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const { sowGroupId, ecoSystemName } = useParams()
  const { setIds, selectedGroupisArchived } = useContext(OrgEcoIdsContext)
  const [usersList, setUsersList] = useState([])
  const [reqData, setReqData] = useState(null)
  const Error = useErrorService()

  const columns = React.useMemo(
    () => [
      {
        id: 'userName',
        Header: 'Name',
        width: '32%',
        accessor: (properties) => ({
          fullName: properties.fullName,
          _id: properties._id,
        }),
        Cell: ({ cell }) => (
          <span className='word-breakall'>
            <Link
              to={`/admin/users/${cell?.value?._id}`}
              rel='noopener noreferrer'
              className='text-dark-900'
              target='_blank'>
              {cell?.value?.fullName}
            </Link>
          </span>
        ),
      },
      {
        id: 'Department',
        Header: 'Department',
        width: '32%',
        disableSortBy: true,
        accessor: (properties) => ({
          companyTeamName: properties?.companyTeamName,
          _id: properties._id,
        }),
        Cell: ({ cell }) => <span>{cell?.value?.companyTeamName}</span>,
        dataProps: {
          className: ecoSystemName ? 'd-none p-0' : '',
        },
        headerProps: {
          className: ecoSystemName ? 'd-none p-0' : '',
        },
      },
      {
        id: 'Participant Level',
        Header: 'Participant Level',
        width: '32%',
        disableSortBy: true,
        accessor: (properties) => ({
          profile: properties?.profile,
          _id: properties._id,
        }),
        Cell: ({ cell }) => (
          <span>
            {cell?.value?.profile?.roleInCompany && (
              <React.Fragment>
                {
                  CONSTANTS.PARTICIPANT_LEVELS.find(
                    (e) => e.value === cell?.value?.profile?.roleInCompany,
                  )?.label
                }
              </React.Fragment>
            )}
          </span>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const selectionHook = useCallback(
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          width: '4%',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({
            getToggleAllRowsSelectedProps,
            toggleRowSelected,
            ...rest
          }) => {
            const rowProps = getToggleAllRowsSelectedProps()
            rowProps.onChange = (e) => {
              rest.rows.forEach((row) => {
                if (!rest.isAllPageRowsSelected) {
                  toggleRowSelected(row.id, true)
                } else {
                  toggleRowSelected(row.id, false)
                }
              })
            }
            return (
              <div>
                {!selectedGroupisArchived && !disabled && (
                  <IndeterminateCheckbox
                    _id={'header'}
                    {...rowProps}
                    classes='header-checkbox'
                    disabled={selectedGroupisArchived || disabled}
                  />
                )}
              </div>
            )
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            return (
              <div>
                {!selectedGroupisArchived && !disabled && (
                  <IndeterminateCheckbox
                    disabled={selectedGroupisArchived || disabled}
                    _id={row.id}
                    {...row.getToggleRowSelectedProps()}
                  />
                )}
              </div>
            )
          },
          dataProps: {
            className: selectedGroupisArchived || disabled ? 'd-none p-0' : '',
          },
          headerProps: {
            className: selectedGroupisArchived || disabled ? 'd-none p-0' : '',
          },
        },
        ...columns,
      ])
    },
    [selectedGroupisArchived, disabled],
  )

  const selectedRow = (selectedRow) => {
    const selectedIds = selectedRow.map((row) => row?._id)
    setIds(selectedIds)
  }

  const getUsersData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await API.AdminAPI.common.getSOWGroupUsers(
        sowGroupId,
        reqData,
      )

      if (data.length === 0 || data.length < 25) {
        hasMore.current = false
      } else {
        hasMore.current = true
      }
      if (reqData.page > 1) {
        setUsersList((prev) => [...prev, ...data])
      } else {
        setUsersList(data)
      }
      setIsMembersEmpty(data?.length === 0 && usersList?.length === 0)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData])

  useEffect(() => {
    if (reqData) {
      getUsersData(reqData)
    }
  }, [getUsersData, reqData])

  const fetchData = useCallback(
    ({ search, filterByPL, sortBy, page, limit }) => {
      if (page === 1) {
        setUsersList([])
      }
      const req = {
        limit,
        page: page,
        ...(search && {
          search: search,
        }),
        ...(filterByPL && {
          participantLevel: filterByPL,
        }),
        sort: !sortBy[0].desc ? 1 : -1,
      }
      setReqData(req)
    },
    [],
  )

  return (
    <TableInstance
      columns={columns}
      data={usersList}
      initialState={{
        globalFilter: intialGlobalFilter,
        sortBy: [{ id: 'userName', desc: false }],
      }}
      autoResetSelectedRows={false}
      tableHooks={[selectionHook]}>
      {({
        preGlobalFilteredRows,
        state: { globalFilter, ...other },
        setGlobalFilter,
        ...rest
      }) => (
        <Container className='px-0 mt-3' fluid>
          <div className='d-flex mb-2-5 sow-user-filters'>
            <UserSearch
              {...{
                globalFilter,
                setGlobalFilter,
              }}
            />
            <FilterSelection
              {...{
                globalFilter,
                setGlobalFilter,
              }}
              name='filterByPL'
              inputClasses='org-users-filter mx-4 border-radius-5'
              placeholder='Level Filter'
              options={CONSTANTS.PARTICIPANT_LEVELS.map((pl) => ({
                key: pl.value,
                value: pl.label,
              }))}
              size='sm'
            />
            {((globalFilter?.search !== '' &&
              globalFilter?.search !== undefined) ||
              globalFilter?.filterByPL !== null) && (
              <ClearFilters
                wrapperClasses='d-flex align-items-center'
                onClick={() => setGlobalFilter(intialGlobalFilter)}
                disabled={isLoading}
              />
            )}
          </div>
          <Table
            ref={hasMore}
            emptyTableMessage='No user found.'
            tableInstance={{
              preGlobalFilteredRows,
              state: { globalFilter, ...other },
              setGlobalFilter,
              ...rest,
            }}
            isLoading={isLoading}
            fetchData={fetchData}
            selectedRow={selectedRow}
            totalNumberOfColumns='4'
          />
        </Container>
      )}
    </TableInstance>
  )
}

export default GroupUsersTable
