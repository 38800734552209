import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ABSpinner, useErrorService } from '@abroad/components'
import {
  CustomCoachingRateModal,
  EditableUsersList,
  GroupUsersTable,
} from '../components'
import { OrgEcoIdsContext } from '../utils/contexts'
import API from '../utils/API'

const SOWGroupDetails = ({ orgEcoId, groupName }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isMembersEmpty, setIsMembersEmpty] = useState(true)
  const [coachRateData, setCoachRateData] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [groupisArchived, setGroupisArchived] = useState(false)
  const Error = useErrorService()
  const {
    ids,
    isLoadNewData,
    setIsLoadNewData,
    isShowAddBtn,
    setIsShowAddBtn,
    selectedGroupisArchived,
  } = useContext(OrgEcoIdsContext)
  const { companyName, ecoSystemName, sowId, sowGroupId } = useParams()
  const url = `/admin/${
    companyName ? `organization/${companyName}` : `network/${ecoSystemName}`
  }/sow/${sowId}/sow-group/${sowGroupId}`

  useEffect(() => {
    const getTeamSpecificData = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(
          sowGroupId,
          {
            fields: ['name', 'isArchived'],
          },
        )
        if (data) {
          setIsShowAddBtn({
            hideBtn: data?.isMembersEmpty,
            showTable: data?.isMembersEmpty,
          })
          setGroupisArchived(data?.isArchived)
          setIsMembersEmpty(data?.isMembersEmpty)
          setIsLoading(false)
          setIsLoadNewData(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (sowGroupId && isLoading) getTeamSpecificData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowGroupId, isLoading])

  useEffect(() => {
    if (isLoadNewData) {
      setIsLoading(true)
    }
  }, [isLoadNewData])

  const addMembersOnGroup = async () => {
    if (ids?.length === 0) return
    setIsSubmitting(true)
    try {
      const { data } = await API.AdminAPI.companyList.addUsersInGroup(
        sowGroupId,
        {
          ids: ids,
          ...(coachRateData && { coachesPricing: coachRateData }),
        },
      )
      if (data) {
        setIsShowAddBtn({
          hideBtn: false,
          showTable: false,
        })
        setIsLoadNewData(true)
        setIsSubmitting(false)
        setCoachRateData(null)
      }
    } catch (e) {
      setIsSubmitting(false)
      Error.showError(e)
      if (e.code === 'sow_team_custom_rate_needed') {
        setShowModal(true)
      }
    }
  }

  useEffect(() => {
    if (coachRateData) {
      addMembersOnGroup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachRateData])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${groupName}'s Users | Abroad`}</title>
        <meta name='title' content={`${groupName}'s Users | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${groupName}'s Users | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${groupName}'s Users | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}${url}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}${url}`}></meta>
      </Helmet>
      <React.Fragment>
        <CustomCoachingRateModal
          showModal={showModal}
          setShowModal={setShowModal}
          setCoachRateData={setCoachRateData}
        />
        {!isShowAddBtn?.showTable && (
          <GroupUsersTable
            setIsMembersEmpty={setIsMembersEmpty}
            disabled={groupisArchived}
          />
        )}
      </React.Fragment>
      {isShowAddBtn?.showTable && (
        <React.Fragment>
          {isMembersEmpty && !groupisArchived && (
            <p className='s3 font-semibold no-users mt-3'>
              There are currently no users in this Group. Please select the
              users you would like
              <br />
              to add from the SOW, then add them to this group.
            </p>
          )}
          <div
            className={classNames({
              'mt-3': !isMembersEmpty,
            })}>
            {!groupisArchived ? (
              <EditableUsersList
                showAddUserToBtn={!selectedGroupisArchived}
                orgEcoId={orgEcoId}
                type={companyName ? 'company' : 'ecosystem'}
                buttonText='ADD USERS TO GROUP'
                isShowCoachAndGroupCell={true}
                onBtnClickHandler={addMembersOnGroup}
                isRenderOnGroupUsers={true}
                isSubmitting={isSubmitting}
                hideCheckbox={groupisArchived}
              />
            ) : (
              <div className='text-center'>
                <span className='s5'>No users.</span>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default SOWGroupDetails
