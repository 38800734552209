import React, { useState, useContext } from 'react'
import { useErrorService, NotificationContext } from '@abroad/components'
import classnames from 'classnames'
import API from '../../utils/API'
import CheckBox from './CheckBox'

const ToggleTeamPrivileges = ({
  userId,
  isCompanyAdmin,
  workStatus,
  index,
}) => {
  const [checkboxStatus, setCheckboxStatus] = useState({
    disabled: workStatus === 'transition',
    checked: isCompanyAdmin,
  })
  const Error = useErrorService()
  const { showNotification } = useContext(NotificationContext)

  const toggleTeamPrivileges = async (e) => {
    setCheckboxStatus({
      disabled: true,
      checked: e.target.checked,
    })
    try {
      const { data } = await API.AdminAPI.userList.toggleTeamPrivileges(
        {
          isCompanyAdmin: e.target.checked,
        },
        userId,
      )
      if (data) {
        setCheckboxStatus((prev) => ({
          ...prev,
          disabled: workStatus === 'transition',
        }))
        showNotification('User Changes Saved!', 'success')
      }
    } catch (e) {
      setCheckboxStatus({
        disabled: workStatus === 'transition',
        checked: isCompanyAdmin,
      })
      Error.showError(e)
    }
  }

  return (
    <>
      <CheckBox
        onChange={toggleTeamPrivileges}
        checked={checkboxStatus.checked}
        disabled={checkboxStatus.disabled}
        id={`team-assessment-${index}`}
        className={classnames(
          'cursor-pointer form-check-input privileges-checkbox ml-0',
          {
            'cursor-disabled': workStatus === 'transition',
          },
        )}
      />
      <label
        className='form-check-label ml-1'
        htmlFor={`team-assessment-${index}`}>
        Organizational
        <br />
        Assessment
      </label>
    </>
  )
}

export default ToggleTeamPrivileges
