import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useErrorService } from '../../services'
import Button from '../Button'
import SubscriptionTableLink from './SubscriptionTableLink'

const PauseOrResumeSubscription = ({
  sowId,
  modalText,
  linkText,
  newValue,
  onSuccess,
  API,
  userId
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const handleClose = () => {
    if (isLoading) return
    setShowModal(false)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const { data } = await API.user.updatePlanStatus(userId, sowId, {
        status: newValue
      })
      if (data) {
        setIsLoading(false)
        onSuccess()
        handleClose()
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }
  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header className='border-0 pb-0 px-4'>
          <Modal.Title className='text-uppercase font-montserrat font-16 font-bold leading-5 letter-spacing-02'>
            {modalText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end px-4 space-x-3'>
          <Button
            variant='none'
            onClick={handleClose}
            disabled={isLoading}
            className='px-3 secondary-popup-button'
            size='sm'
          >
            Cancel
          </Button>
          <Button
            variant='saffron'
            size='sm'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onSubmit}
            className='text-uppercase'
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <SubscriptionTableLink
        onClick={() => setShowModal(true)}
        disabled={isLoading}
        linkText={linkText}
      />
    </>
  )
}

export default PauseOrResumeSubscription
