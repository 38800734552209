import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { ShowCharsLimt } from '../common'
import { InputField } from '../form'
import { useToSetCharsLimit } from '../common/hooks'

const labelProps = { column: false, sm: '2' }

const CustomHeader = ({
  maxCharsInHeader,
  minCharsInHeader,
  disabled,
  placeholder,
}) => {
  const [error, setError] = useState(null)
  const {
    totalChars: totalCharsInHeaderText,
    updateCharsLength: updateCharsInHeaderText,
  } = useToSetCharsLimit()
  const {
    values: { header },
    handleBlur,
    handleChange,
  } = useFormikContext()

  useEffect(() => {
    updateCharsInHeaderText(header)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header])

  return (
    <>
      <InputField
        name='header'
        type='text'
        labelProps={labelProps}
        placeholder={placeholder}
        size='lg'
        lg='12'
        label='Custom Header'
        displayFlex={false}
        formGroupProps={{ className: 'mb-0' }}
        onChange={(e) => {
          handleChange(e)
          setError(null)
        }}
        onBlur={(e) => {
          handleBlur(e)
          const headerError =
            header?.length > maxCharsInHeader
              ? `Header must be less than ${maxCharsInHeader} characters.`
              : header?.length < minCharsInHeader && header?.length !== 0
              ? `Header must be at least ${minCharsInHeader} characters long.`
              : null
          setError(headerError)
        }}
        disabled={disabled}
        className='border-radius-5'
      />
      <div
        className={classNames({
          'd-flex justify-content-between': error,
          'd-flex justify-content-end': !error,
        })}>
        {error && <span className='font-open-sans text-error'>{error}</span>}
        <ShowCharsLimt
          limit={maxCharsInHeader}
          enteredChars={
            totalCharsInHeaderText ? totalCharsInHeaderText?.length : 0
          }
          wrapperClasses='mb-4'
        />
      </div>
    </>
  )
}

export default CustomHeader
