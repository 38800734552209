import React, { useState } from 'react'
import { SubCategory } from './SubCategory'
import { Button } from '@abroad/components'

export const SubCategoryOfSiteCopy = ({ data }) => {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)

  const handelSiteCopyUpdate = () => {
    setIsUpdateLoading(true)
    setTimeout(() => {
      setIsUpdateLoading(false)
    }, 1000)
  }

  return (
    <section className='sitecopy d-flex p-3 mx-3 bg-gray-100 d-flex flex-column mt-3'>
      <h4 className='font-20 font-montserrat font-medium font-lg-24 text-dark-600 mb-4'>
        SUBCATEGORIES
        {data?.map((data) => (
          <SubCategory
            id={data?._id}
            title={data?.title}
            isUpdateLoading={isUpdateLoading}
            key={data?._id}
          />
        ))}
        <div className='text-center'>
          <Button
            isLoading={isUpdateLoading}
            disabled={isUpdateLoading}
            onClick={handelSiteCopyUpdate}
            size='md'
            className='line-height-normal border-radius-3 text-uppercase'
            variant='dark'>
            Update
          </Button>
        </div>
      </h4>
    </section>
  )
}
