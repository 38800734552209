import * as CryptoJS from 'crypto-js'
/**
 * Store and get user data
 *
 * @class Auth
 */
class AuthService {
  getRememberCookie() {
    try {
      if (document.cookie) {
        const rememberMe = document.cookie
          ?.split('; ')
          ?.find((row) =>
            row.startsWith(`${process.env.REACT_APP_COOKIE_PREFIX}_remember`),
          )
          ?.split('=')[1]
        return rememberMe === 'true'
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  getCookiesPolicy() {
    const date = new Date()
    const rememberMe = this.getRememberCookie()
    date.setTime(date.getTime() + (rememberMe ? 30 : 3) * 24 * 60 * 60 * 1000)
    return {
      expires: date,
      domain: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
    }
  }

  getStorage() {
    return localStorage
  }

  getLoginState() {
    const user = this.getUserData()
    return user ? true : false
  }

  setUserData(data) {
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_CIPHER,
      { mode: CryptoJS.mode.CBC },
    ).toString()
    const revisited = CryptoJS.AES.encrypt(
      JSON.stringify({ visited: true }),
      process.env.REACT_APP_CIPHER,
      { mode: CryptoJS.mode.CBC },
    ).toString()
    const cookiePolicy = this.getCookiesPolicy()
    document.cookie = `${
      process.env.REACT_APP_COOKIE_PREFIX
    }_ab_u=${cipherText};expires=${cookiePolicy.expires.toUTCString()};domain=${
      cookiePolicy.domain
    };path=/`
    document.cookie = `${
      process.env.REACT_APP_COOKIE_PREFIX
    }_ab_rs=${revisited};expires=${cookiePolicy.expires.toUTCString()};domain=${
      cookiePolicy.domain
    };path=/`
    document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_userId=${
      data.id
    };expires=${cookiePolicy.expires.toUTCString()};domain=${
      cookiePolicy.domain
    };path=/`
  }

  getUserData() {
    try {
      // const user = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`)
      if (document.cookie) {
        const user = document.cookie
          ?.split('; ')
          ?.find((row) =>
            row.startsWith(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`),
          )
          ?.split('=')[1]
        const bytes = CryptoJS.AES.decrypt(user, process.env.REACT_APP_CIPHER, {
          mode: CryptoJS.mode.CBC,
        })
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  getUserId() {
    const user = this.getUserData()
    return user._id
  }

  removeData() {
    const cookiePolicy = this.getCookiesPolicy()
    const expires = new Date(null).toUTCString()
    document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_ab_u=;expires=${expires};domain=${cookiePolicy.domain};path=/`
    document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_userId=;expires=${expires};domain=${cookiePolicy.domain};path=/`
    document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_remember=;expires=${expires};domain=${cookiePolicy.domain};path=/`
    document.cookie = `ab_auth=;expires=${expires};path=/`
    document.cookie = `ab_u=;expires=${expires};path=/`
    localStorage.removeItem('surveyRequestId')
  }

  isLogedIn() {
    const auth = this.getLoginState()
    if (auth) {
      return true
    } else {
      return false
    }
  }

  isUserRevisited() {
    if (document.cookie) {
      const revisited = document.cookie
        ?.split('; ')
        ?.find((row) =>
          row.startsWith(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_rs`),
        )
        ?.split('=')[1]
      if (revisited) {
        return true
      }
      return false
    } else {
      return false
    }
  }
}

export default new AuthService()
