import React, { useRef, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import DateRangePicker from './DateRangePicker'
import API from '../../utils/API'

const CustomDateRange = ({
  affiliation,
  affiliationId,
  onSelectSurvey,
  id,
  selectedSurveys,
  updateSelectedSurvey,
}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [numberOfSurveys, setNumberOfSurveys] = useState(null)
  let { ecoSystemName, companyName } = useParams()
  const picker = useRef()

  const getCustomDateRange = async (startDate, endDate) => {
    setIsLoading(true)
    if (affiliation) {
      try {
        const { data } = await API.AdminAPI.companyList.getCountByAffiliation(
          `?ecosystem=${ecoSystemName}&startDate=${moment(startDate).format(
            'YYYY-MM-DD',
          )}&endDate=${moment(endDate).format('YYYY-MM-DD')}`,
        )
        setNumberOfSurveys(data)
        setIsLoading(false)
        if (
          selectedSurveys?.filter((survey) => survey?.customId === id).length >
          0
        ) {
          updateSelectedSurvey(
            { customId: id, startDate, endDate },
            data?.count <= 2,
          )
        }
      } catch (e) {
        setIsLoading(false)
      }
    } else {
      try {
        const { data } = await API.ClientAPI.survey.getCountByCompany(
          null,
          encodeURIComponent(companyName),
          null,
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
        )
        setNumberOfSurveys(data)
        setIsLoading(false)
        if (
          selectedSurveys?.filter((survey) => survey?.customId === id).length >
          0
        ) {
          updateSelectedSurvey(
            { customId: id, startDate, endDate },
            data?.count <= 2,
          )
        }
      } catch (e) {
        setIsLoading(false)
      }
    }
  }

  return (
    <tr>
      <td>
        <Form.Group className='mb-0'>
          <Form.Check
            id={id}
            custom={true}
            onChange={() => {
              if (
                selectedSurveys?.filter((survey) => survey?.customId === id)
                  .length > 0
              ) {
                updateSelectedSurvey({ customId: id }, true)
              } else {
                onSelectSurvey({ customId: id, startDate, endDate })
              }
            }}
            checked={
              numberOfSurveys?.count > 0 &&
              selectedSurveys?.filter((survey) => survey?.customId === id)
                .length > 0
                ? true
                : false
            }
            disabled={
              isLoading ||
              !numberOfSurveys ||
              (numberOfSurveys && numberOfSurveys.count < 1)
            }
            type='checkbox'
            className='cursor-pointer'
          />
        </Form.Group>
      </td>
      <td>
        <DateRangePicker
          parentEl={affiliationId}
          onApply={(start, end) => {
            getCustomDateRange(start, end)
            setStartDate(start)
            setEndDate(end)
          }}
          ref={picker}
        />
      </td>
      <td>
        {isLoading && (
          <Spinner
            animation='border'
            className='text-saffron-700'
            role='status'
            size='sm'
          />
        )}
        {numberOfSurveys && !isLoading ? numberOfSurveys.count : ''}
      </td>
      <td>
        {numberOfSurveys && numberOfSurveys?.count > 0 && !isLoading ? (
          affiliation ? (
            <Link
              className='text-saffron-700'
              to={`/admin/survey/network/${ecoSystemName}?startDate=${moment(
                startDate,
              ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
                'YYYY-MM-DD',
              )}`}
              rel='noopener noreferrer'
              target='_blank'>
              Results
            </Link>
          ) : (
            <Link
              className='text-saffron-700'
              to={`/admin/survey/organization/${companyName}?startDate=${moment(
                startDate,
              ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
                'YYYY-MM-DD',
              )}`}
              rel='noopener noreferrer'
              target='_blank'>
              Results
            </Link>
          )
        ) : (
          ''
        )}
      </td>
    </tr>
  )
}

export default CustomDateRange
