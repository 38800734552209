import React from 'react'
import classNames from 'classnames'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Col, Form, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { useErrorService } from '@abroad/components'
import { returnLGSelectionFieldStyle } from '../../constants/common'
import API from '../../utils/API'
import { getCoachingPlanObject, isLGScreen } from '../../utils/utility'
import Labels from '../../constants/labels'

const getPlanLabel = (plan) => {
  // SOW Name(For Client name) - Number of Session/X Month
  return `${plan?.planName} ${
    plan?.clientId?.fullName ? `(${plan?.clientId?.fullName})` : ''
  } - ${plan?.sessionPerCoachingCycle} session(s)/${
    plan?.numberOfMonths
  } month(s)`
}

const SOWCopySelection = ({
  name,
  placeholder,
  disabled,
  label,
  // updateFormValuesHandle,
  isDiscardLabelPadding,
  isInsidePopup = false,
  popupClassName = 'sow-modal',
}) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    setValues,
  } = useFormikContext()
  const Error = useErrorService()
  // const [isLoadOptionAgain, setIsLoadOptionAgain] = useState(false)
  const extraStyle = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  }
  const styles = returnLGSelectionFieldStyle(disabled, extraStyle)

  const getOptionsList = async (search = '', page = 1) => {
    try {
      const { data } = await API.AdminAPI.coachPlan.getCoachingPlanList(search)

      const options = data.map((data) => ({
        value: data._id,
        label: getPlanLabel(data),
        extra: data,
      }))
      return {
        options,
        hasMore: false,
      }
    } catch (e) {
      Error.showError(e)
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      const selectedPlan = getCoachingPlanObject(
        {
          plan: selectedOption[selectedOption?.length - 1].extra,
        },
        Labels?.coachingPlanStatus[1].value,
      )
      delete selectedPlan.paymentStatus
      delete selectedPlan.status
      // updateFormValuesHandle(selectedPlan)
      setValues(
        {
          ...values,
          ...selectedPlan,
          sowCopy: [selectedOption[selectedOption?.length - 1]],
        },
        true,
      )
    } else {
      setFieldValue(name, [], true)
    }
    // todo - quick fixed for now, need to optimize
    // setTimeout(() => {
    //   setFieldValue(
    //     name,
    //     selectedOption === null
    //       ? []
    //       : [selectedOption[selectedOption?.length - 1]],
    //   )
    // }, 500)
    // setIsLoadOptionAgain(!isLoadOptionAgain)
  }

  const loadPageOptions = async (inputValue, prevOptions, { page }) => {
    const { options, hasMore } = await getOptionsList(
      inputValue,
      page,
      prevOptions,
    )

    return {
      options,
      hasMore,
      additional: {
        page: 1, //static
      },
    }
  }

  let portalProps = {
    menuPortalTarget: document.body,
  }

  if (isInsidePopup) {
    portalProps = {
      menuPortalTarget: document.getElementsByClassName(popupClassName)[0],
      menuPosition: 'fixed',
    }
  }

  return (
    <Row noGutters xs={1} sm={1} md={1} lg={2}>
      {label && (
        <Col
          className={classNames(
            'col-lg-2 d-flex align-items-center justify-content-lg-end',
            {
              'px-0': !isLGScreen,
            },
          )}>
          <Form.Group className={isLGScreen ? '' : 'mb-0'}>
            <Form.Label
              column={isLGScreen ? true : false}
              className={classNames({ 'px-0': isDiscardLabelPadding })}>
              {label}
            </Form.Label>
          </Form.Group>
        </Col>
      )}
      <Col className={classNames({ 'col-lg-10 px-0': label })}>
        <Form.Group>
          <AsyncPaginate
            // to set initial options options={}
            // key={isLoadOptionAgain}
            value={values[name]}
            closeMenuOnSelect={true}
            additional={{ page: 1 }}
            isMulti
            loadOptions={loadPageOptions}
            isValid={
              touched.hasOwnProperty(name) && !errors.hasOwnProperty(name)
            }
            isInvalid={
              touched.hasOwnProperty(name) && errors.hasOwnProperty(name)
            }
            placeholder={placeholder}
            styles={styles}
            onFocus={() => {
              setFieldTouched(name, true, false)
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            isClearable={values[name]}
            isDisabled={disabled}
            debounceTimeout={1000}
            classNamePrefix='coach-profile-input'
            {...portalProps}
          />
        </Form.Group>
      </Col>
    </Row>
  )
}

SOWCopySelection.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

SOWCopySelection.defaultProps = {
  options: [],
  name: '',
  label: '',
}

export default SOWCopySelection
