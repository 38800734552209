import React, { useEffect, useState } from 'react'

const UploadProgressBar = () => {
  const [completed, setCompleted] = useState(18)

  useEffect(() => {
    setInterval(
      () =>
        setCompleted((prev) => {
          if (prev !== 99) {
            return prev + 9
          } else {
            return 99
          }
        }),
      200,
    )
  }, [])

  const fillerStyles = {
    width: `${completed}%`,
    transition: 'width 1s ease-in-out',
  }

  return (
    <div className='custom-progressbar w-100 bg-white border-radius-3'>
      <div
        style={fillerStyles}
        className='d-flex align-items-center filled-progressbar bg-dark-900 text-right border-radius-0'>
        <span className='s8 pl-4 text-white'>{`${completed}%`}</span>
      </div>
    </div>
  )
}

export default UploadProgressBar
