import React from 'react'
import { IntentionProvider } from '../components'
import GrowthPlan from './GrowthPlan'

const GrowthPlanProvider = () => {
  return (
    <IntentionProvider>
      <GrowthPlan />
    </IntentionProvider>
  )
}

export default GrowthPlanProvider
