import React from 'react'
import classnames from 'classnames'

const GoBackIcon = ({ className = '', ...rest }) => {
  return (
    <svg
      className={classnames('cursor-pointer', className)}
      width='17.15'
      height='33.29'
      viewBox='0 0 21 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M19.15 2L2 18.6462'
        stroke='black'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M19.15 35.2927L2 18.6465'
        stroke='black'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default GoBackIcon
