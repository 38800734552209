import React, { useEffect, useState } from 'react'
import { Form as RBForm } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
} from '@abroad/components'
import { Formik, Form } from 'formik'
import { string, number, object, date } from 'yup'
import { InputField, SingleDatePicker } from '../form'
import { SelectionField } from '../common'
import Labels from '../../constants/labels'
import { isLGScreen } from '../../utils/utility'
import API from '../../utils/API'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const formInitialValues = {
  code: '',
  noOfUse: '',
  expiryDate: moment().format('MM/DD/YYYY'),
  maxAmount: '',
  status: 'Enabled',
}

const validationSchema = object().shape({
  code: string().required().matches('^[a-zA-Z0-9]+$'),
  noOfUse: number().required().positive().integer().max(100000),
  maxAmount: number().positive().integer().max(100000),
  expiryDate: date()
    .required()
    .test('valid-date', 'Please enter valid date!', function (value) {
      const checkDate = moment(value, 'MM/DD/YYYY')
      return checkDate.isValid()
    }),
  status: string().required(),
})

export const UpdatePromoForm = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [prevPromo, setPrevPromo] = useState('')
  const [isPromoUpdateLoading, setIsPromoUpdateLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const { promoId } = useParams()
  const Notification = useNotificationService()
  const Error = useErrorService()

  useEffect(() => {
    const getPromData = async () => {
      try {
        const { data } = await API.AdminAPI.promo.getPromData(promoId)
        if (data) {
          setPrevPromo(data.code.toUpperCase())
          setInitialValues((prev) => ({
            ...prev,
            code: data.code,
            noOfUse: data.noOfUse,
            expiryDate: moment(data.expTime).format('MM/DD/YYYY'),
            maxAmount: data.maxAmount ? data.maxAmount : '',
            status: data.status ? 'Enabled' : 'Not Enabled',
          }))
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getPromData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values) => {
    setIsPromoUpdateLoading(true)

    let params = {
      ...(prevPromo !== values.code.toUpperCase() && {
        code: values.code.toUpperCase(),
      }),
      noOfUse: values.noOfUse,
      expTime: moment(values.expiryDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      maxAmount: values.maxAmount,
      status: values.status === 'Enabled' ? true : false,
    }

    Object.keys(params).forEach(
      (key) => params[key] === '' && delete params[key],
    )

    try {
      const { data } = await API.AdminAPI.promo.updatePromoCode(promoId, params)
      if (data) {
        setIsPromoUpdateLoading(false)
        if (prevPromo !== values.code.toUpperCase()) {
          setPrevPromo(values.code.toUpperCase())
        }
        Notification.showNotification('Promo Code Updated!', 'success')
      }
    } catch (e) {
      if (e.code === 'promo_code_exists') {
        Notification.showNotification(e.message, 'danger')
      } else {
        Error.showError(e)
      }
      setIsPromoUpdateLoading(false)
    }
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <Formik
      displayName='profile-form'
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={onSubmit}>
      {({ values, handleSubmit }) => {
        return (
          <RBForm
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(e)
            }}
            as={Form}
            className='profile'>
            <InputField
              label='CODE'
              labelProps={labelProps}
              name='code'
              type='string'
              size='lg'
              sm='10'
              className='text-uppercase'
            />
            <SelectionField
              label='STATUS'
              labelProps={labelProps}
              name='status'
              size='lg'
              options={Labels?.promoStatus}
            />
            <InputField
              label='MAX OFF'
              placeholder='Keep it empty for no maximum limit'
              labelProps={labelProps}
              name='maxAmount'
              type='number'
              size='lg'
              sm='10'
            />
            <InputField
              label='REMAINING'
              labelProps={labelProps}
              name='noOfUse'
              type='number'
              size='lg'
              sm='10'
            />
            <SingleDatePicker
              name='expiryDate'
              label='Expiry Date'
              labelProps={labelProps}
              sm='10'
              className='pointer-cursor user-input form-control-lg bg-white'
              position='up'
              minimumDate={moment()}
              maximumDate={moment().add(1, 'years')}
            />
            <div className='text-right mt-3'>
              <Button
                isLoading={isPromoUpdateLoading}
                disabled={isPromoUpdateLoading}
                type='submit'
                size='md'
                className='line-height-normal border-radius-3 text-uppercase'
                variant='gray'>
                UPDATE
              </Button>
            </div>
          </RBForm>
        )
      }}
    </Formik>
  )
}
