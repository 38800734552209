import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { SelectionField as RBFormSelectionField } from '../common'
import labels from '../../constants/labels'

const coachTypeAllCoaches = labels.filterBycoachTypeDefault.ALL_COACHES

const FilterSelection = React.memo(
  ({
    globalFilter,
    setGlobalFilter,
    options = [],
    name,
    placeholder = 'Select One',
    size = 'md',
    inputClasses,
    defaultValue = '',
    disabled = false,
  }) => {
    const [value, setValue] = useState(globalFilter[name])

    useEffect(() => {
      if (globalFilter[name] === null) {
        setValue('')
      }
      // condition for SOW group coach type filter
      if (globalFilter[name] === coachTypeAllCoaches) {
        setValue(coachTypeAllCoaches)
      }
    }, [globalFilter, name])

    useEffect(() => {
      if (defaultValue) {
        setValue(defaultValue)
      }
    }, [defaultValue])

    return (
      <RBFormSelectionField
        name={name}
        options={options}
        className={classNames(
          { 'pointer-cursor': !disabled },
          {
            'is-disabled': disabled,
          },
          inputClasses,
        )}
        optionplaceholder={placeholder}
        size={size}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          setGlobalFilter({
            ...globalFilter,
            [name]: e.target.value,
          })
        }}
        isUseFormikSelectionField={false}
        getKeyAsOption={true}
        disabled={disabled}
      />
    )
  },
)

export default FilterSelection
