import React, { useCallback, useContext, useState } from 'react'
import { JourneyMediasContext } from '../../utils/contexts'
import MediasWithDnd from './MediasWithDnd'

const JourneysSection = ({ title, journeyType }) => {
  const { mediasList: data } = useContext(JourneyMediasContext)
  const [isDeleting, setIsDeleting] = useState(false)
  const TYPE = 'USER_JOURNEY_MEDIA_LIST'

  const renderMediaList = useCallback(
    (media, index) => {
      return (
        <MediasWithDnd
          dndType={TYPE}
          key={media._id}
          index={index}
          id={media._id}
          media={media}
          type={journeyType}
          render={(value) => setIsDeleting(value)}
        />
      )
    },
    [journeyType],
  )

  return (
    <div>
      <h3 className='section-title font-montserrat font-18 font-bold leading-relaxed letter-spacing-minus-04 text-center'>
        {title}
      </h3>
      {data?.length === 0 && (
        <p className='font-open-sans font-14 font-semibold leading-6 letter-spacing-minus-02 text-center mb-0'>
          Add audio from other side.
        </p>
      )}
      {data?.length > 0 && (
        <>
          {data?.map((media, index) => (
            <div
              key={media._id}
              className={isDeleting ? 'pointer-event-none' : ''}>
              {renderMediaList(media, index)}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default JourneysSection
