import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ABSpinner, useErrorService } from '@abroad/components'
import { EnterpriseSOW } from './EnterpriseSOW'
import { CoachesAssignToSOW } from '../components'
import API from '../utils/API'
import { OrgEcoIdsContext } from '../utils/contexts'

const SOWDetails = ({ type, setIsCoachesEmpty }) => {
  const { companyName, ecoSystemName, sowId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [sowDetails, setSOWDetails] = useState([])
  const { isLoadNewData, setIsLoadNewData } = useContext(OrgEcoIdsContext)
  const Error = useErrorService()
  const title = sowDetails?.planName
    ? `${sowDetails?.planName}'s Details | Abroad`
    : 'SOW Details | Abroad'
  const url = `${process.env.REACT_APP_DOMAIN}/admin/${
    type === 'Organization'
      ? `organization/${companyName}`
      : `network/${ecoSystemName}`
  }/sow/${sowId}/details`

  useEffect(() => {
    const getSOWDetails = async () => {
      try {
        const { data } = await API.AdminAPI.companyList.getSpecificDetailsOfSOW(
          {
            fields: [
              'coaches',
              'monthlyFee',
              'monthlyIndividualSessions',
              'individualSessionDuration',
              'monthlyGroupSessions',
              'groupSessionDuration',
              'planName',
            ],
          },
          sowId,
        )
        if (data) {
          setIsLoadNewData(false)
          setIsLoading(false)
          setSOWDetails(data)
          setIsCoachesEmpty(data?.coaches?.length > 0 ? false : true)
        }
      } catch (e) {
        setIsLoadNewData(false)
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (sowId && isLoading) getSOWDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowId, isLoading])

  useEffect(() => {
    if (isLoadNewData) {
      setIsLoading(true)
    }
  }, [isLoadNewData])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name='title' content={title}></meta>
        <meta property='og:title' content={title}></meta>
        <meta property='twitter:title' content={title}></meta>
        <link rel='canonical' href={url}></link>
        <meta property='og:url' content={url}></meta>
        <meta property='twitter:url' content={url}></meta>
      </Helmet>
      <main>
        <EnterpriseSOW sowDetails={sowDetails} />
        <CoachesAssignToSOW
          sowDetails={sowDetails}
          setIsCoachesEmpty={setIsCoachesEmpty}
        />
      </main>
    </React.Fragment>
  )
}

export default SOWDetails
