import React from 'react'
import { Table } from 'react-bootstrap'
import CONSTANTS from '../../utils/constants'

const ClientBasicInformation = ({ profileData }) => {
  const renderParticipantLevel = (userPL) => {
    if (userPL) {
      const findUserPL = CONSTANTS.PARTICIPANT_LEVELS.find(
        (pl) => pl.value === userPL,
      )

      return findUserPL?.label
    }
    return '-'
  }

  return (
    <React.Fragment>
      <h3 className='s3 mt-4 mb-0'>Client Basic Information</h3>
      <Table hover responsive className='custom-table client-basic-info'>
        <thead>
          <tr className='text-uppercase'>
            <th width='20%'>Name</th>
            <th width='15%'>Organization</th>
            <th width='15%'>Title</th>
            <th width='15%'>Department</th>
            <th width='15%'>Country</th>
            <th width='20%'>Participant Level</th>
          </tr>
        </thead>
        <tbody>
          <tr key={profileData?._id}>
            <td>
              <b>{profileData?.fullName}</b>
            </td>
            <td>{profileData?.company ? profileData?.company : '-'}</td>
            <td>{profileData?.profile?.title || '-'}</td>
            <td>{profileData?.companyTeamName || '-'}</td>
            <td>{profileData?.profile?.country || '-'}</td>
            <td>
              {renderParticipantLevel(profileData?.profile?.roleInCompany)}
            </td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default ClientBasicInformation
