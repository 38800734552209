import React from 'react'
import classnames from 'classnames'

const CheckBox = ({ isChecked, onChangeHandler, className, ...rest }) => {
  return (
    <input
      type='checkbox'
      className={classnames('form-check-input cursor-pointer', className)}
      onBlur={() => {}}
      {...rest}
    />
  )
}

export default CheckBox
