import React, { useEffect, useRef, useState } from 'react'
import Axios from 'axios'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import {
  Col,
  Container,
  Row,
  Table,
  Spinner as RBSpinner,
} from 'react-bootstrap'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import {
  ABSpinner,
  useErrorService,
  useNotificationService,
  Button,
} from '@abroad/components'
import { DateRangePicker } from '../components'
import accountConstants from '../constants/account'
import { ClearFilters, PageHeader } from '../components/common'
import {
  EnterpriseClients,
  EnterpriseOneTimePayments,
  EnterpriseTeams,
} from '../components/accounting'
import { StaffCoachIcon } from '../components/assetsComponents'
import API from '../utils/API'
import { convertSessionDate, formatDate } from '../utils/date'
import {
  getNameArrayFromCompanies,
  prevMonthFirstDay,
  prevMonthLastDay,
} from '../utils/utility'

const Account = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const Error = useErrorService()
  const [startDate, setStartDate] = useState(prevMonthFirstDay)
  const [endDate, setEndDate] = useState(prevMonthLastDay)
  const [organization, setOrganization] = useState('')
  const [network, setNetwork] = useState('')
  const [companies, setCompanies] = useState({
    isLoading: true,
    companies: [],
  })
  const [ecoSystems, setEcoSystems] = useState({
    isLoading: true,
    ecoSystems: [],
  })
  const [type, setType] = useState('1')
  const [downloadReportLoading, setDownloadReportLoading] = useState(false)
  const [coachList, setCoachList] = useState([])
  const [selectedCoach, setSelectedCoach] = useState('')
  const [markAsPaidLoaderIndex, setMarkAsPaidLoaderIndex] = useState(null)
  const Notification = useNotificationService()
  const picker = useRef()

  const getAccountReport = async (sd, ed) => {
    try {
      setIsLoading(true)
      const { data } = await API.AdminAPI.account.getAccountReport(
        moment(sd).format('YYYY-MM-DD'),
        moment(ed).format('YYYY-MM-DD'),
        type,
        selectedCoach,
        organization,
        network,
      )
      if (data) {
        setData(data)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  useEffect(() => {
    const getCoachList = async () => {
      try {
        setIsLoading(true)
        let { data } = await API.AdminAPI.userList.getCoaches()
        setCoachList((prev) => [...prev, ...data])
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getCoachList()
    getAccountReport(prevMonthFirstDay, prevMonthLastDay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        let { data } = await API.AdminAPI.companyList.getCompanyData(
          '?excludeEnterprise=true',
        )
        data = getNameArrayFromCompanies(data)
        if (data) {
          setCompanies({
            isLoading: false,
            companies: data,
          })
        }
      } catch (e) {
        setCompanies({
          isLoading: false,
          companies: [],
        })
        Error.showError(e)
      }
    }
    getCompanyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getEcoSystemsData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getEcoSystemsData(
          `?excludeEnterprise=true`,
        )
        if (data) {
          setEcoSystems({
            isLoading: false,
            ecoSystems: data,
          })
        }
      } catch (e) {
        setEcoSystems({
          isLoading: false,
          ecoSystems: [],
        })
        Error.showError(e)
      }
    }
    getEcoSystemsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const markAsPaid = async (coachId, recordIndex) => {
    try {
      setMarkAsPaidLoaderIndex(recordIndex)
      const markAsPaidData = await API.AdminAPI.account.markAsPaid(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        type,
        coachId,
        organization,
        network,
      )
      setMarkAsPaidLoaderIndex(null)
      Notification.showNotification(markAsPaidData?.data, 'success')
      getAccountReport(startDate, endDate)
    } catch (e) {
      setMarkAsPaidLoaderIndex(null)
      Error.showError(e)
    }
  }

  const downloadXLSX = async () => {
    let xlsxFile, downloadLink, filename
    setDownloadReportLoading(true)
    try {
      //API call
      const coachId = selectedCoach !== '' ? `&coachId=${selectedCoach}` : ''
      const coachName = coachList.find((e) => e._id === selectedCoach)

      if (selectedCoach !== '') {
        filename = `account_report_from_${moment(startDate).format(
          'YYYY-MM-DD',
        )}_to_${moment(endDate).format(
          'YYYY-MM-DD',
        )}_for_${coachName?.fullName.replace(/ /g, '_')}.xlsx`
      } else {
        filename = `account_report_from_${moment(startDate).format(
          'YYYY-MM-DD',
        )}_to_${moment(endDate).format('YYYY-MM-DD')}.xlsx`
      }

      const { data } = await Axios.get(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/accounts/export-account-excel?startDate=${moment(startDate).format(
          'YYYY-MM-DD',
        )}&endDate=${moment(endDate).format(
          'YYYY-MM-DD',
        )}&reportFor=${type}${coachId}${
          organization ? `&company=${organization}` : ''
        }${network ? `&network=${network}` : ''}`,
        { responseType: 'blob', withCredentials: true },
      )
      if (data) {
        // XLSX file
        xlsxFile = new Blob([data])

        // Download link
        downloadLink = document.createElement('a')

        // File name
        downloadLink.download = filename

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(xlsxFile)

        // Hide download link
        downloadLink.style.display = 'none'

        // Add the link to DOM
        document.body.appendChild(downloadLink)

        // Click download link
        downloadLink.click()

        Notification.showNotification(
          'Report download successfully.',
          'success',
        )
        setDownloadReportLoading(false)
      }
    } catch (e) {
      setDownloadReportLoading(false)
      Error.showError(e)
    }
  }

  const renderStaffCoachIcon = (isStaffCoach = false) => {
    if (!isStaffCoach) return null
    return <StaffCoachIcon classes='staff-coach-indicator mb-2' />
  }

  let coachingMonthRangeIndex = 0

  const handleClearFilter = () => {
    setSelectedCoach('')
    setStartDate(prevMonthFirstDay)
    setEndDate(prevMonthLastDay)
    setType('1')
    setOrganization('')
    setNetwork('')
    picker.current.setStartDate(formatDate(prevMonthFirstDay))
    picker.current.setEndDate(formatDate(prevMonthLastDay))
  }

  return (
    <>
      <Helmet>
        <title>{'Accounts | Abroad'}</title>
        <meta name='title' content={'Accounts | Abroad'}></meta>
        <meta property='og:title' content={'Accounts | Abroad'}></meta>
        <meta property='twitter:title' content={'Accounts | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/account`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/account`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/account`}></meta>
      </Helmet>
      <main>
        <PageHeader
          titleText='accounts'
          colClasses='px-0'
          titleClasses='sentence-case-text'
        />
        <section className='account pt-5'>
          <Container fluid className='custom-offset-lg-1 custom-col-lg-12 px-0'>
            <Row className='custom-report-container'>
              <Col xs={4} className='mb-2'>
                <select
                  name='type'
                  className='account-daterange report-coach profile-select bg-white-shadow user-input form-control'
                  id='type'
                  value={selectedCoach}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setSelectedCoach('')
                    } else {
                      setSelectedCoach(e.target.value)
                    }
                  }}>
                  <option value=''>All Coaches</option>
                  {coachList.map((coach) => (
                    <option value={coach.id} key={coach.id}>
                      {coach.fullName}
                    </option>
                  ))}
                </select>
              </Col>
              <Col xs={4} className='mb-2'>
                <select
                  name='type'
                  className='account-daterange report-coach profile-select bg-white-shadow user-input form-control'
                  id='type'
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value)
                  }}>
                  {Object.keys(accountConstants.ACCOUNTING_TYPE_FILTER).map(
                    (e) => (
                      <option
                        value={accountConstants.ACCOUNTING_TYPE_FILTER[e]}
                        key={accountConstants.ACCOUNTING_TYPE_FILTER[e]}>
                        {e}
                      </option>
                    ),
                  )}
                </select>
              </Col>
              <Col xs={4} className='mb-2'>
                <DateRangePicker
                  initialSettings={{
                    maxSpan: { months: 6 },
                    autoUpdateInput: true,
                    opens: 'center',
                    startDate: prevMonthFirstDay,
                    endDate: prevMonthLastDay,
                  }}
                  onApply={(start, end) => {
                    setStartDate(start)
                    setEndDate(end)
                  }}
                  setOnHide={true}
                  className='account-daterange w-100'
                  ref={picker}
                />
              </Col>
              <Col xs={4}>
                <select
                  name='organization'
                  className={classNames(
                    'account-daterange report-coach profile-select bg-white-shadow user-input form-control',
                    {
                      'disabled-field': companies.isLoading || network,
                    },
                  )}
                  id='organization'
                  value={organization}
                  onChange={(e) => {
                    setOrganization(e.target.value)
                  }}
                  disabled={companies.isLoading || network}>
                  <option value='' disabled selected>
                    {companies.isLoading
                      ? 'Fetching Organization...'
                      : 'Select Organization'}
                  </option>
                  {companies?.companies?.map((company) => (
                    <option value={company} key={company}>
                      {company}
                    </option>
                  ))}
                </select>
              </Col>
              <Col xs={4}>
                <select
                  name='network'
                  className={classNames(
                    'account-daterange report-coach profile-select bg-white-shadow user-input form-control',
                    {
                      'disabled-field': ecoSystems.isLoading || organization,
                    },
                  )}
                  id='network'
                  value={network}
                  onChange={(e) => {
                    setNetwork(e.target.value)
                  }}
                  disabled={ecoSystems.isLoading || organization}>
                  <option value={''} disabled selected>
                    {ecoSystems.isLoading
                      ? 'Fetching Network...'
                      : 'Select Network'}
                  </option>
                  {ecoSystems?.ecoSystems?.map((ecoSystem) => (
                    <option value={ecoSystem} key={ecoSystem}>
                      {ecoSystem}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            {(selectedCoach !== '' ||
              prevMonthFirstDay.format('MM/DD/YYYY') !==
                startDate.format('MM/DD/YYYY') ||
              prevMonthLastDay.format('MM/DD/YYYY') !==
                endDate.format('MM/DD/YYYY') ||
              type !== '1' ||
              organization !== '' ||
              network !== '') && (
              <ClearFilters
                wrapperClasses='text-right'
                onClick={handleClearFilter}
                disabled={isLoading}
              />
            )}
            <div className='margin-auto text-center'>
              <Button
                variant='gray'
                size='md'
                className='line-height-normal text-uppercase border-radius-3'
                onClick={() => {
                  if (!startDate || !endDate) {
                    Notification.showNotification('Please select dates!')
                  } else {
                    getAccountReport(startDate, endDate)
                  }
                }}>
                View Report
              </Button>
              {data?.length > 0 && (
                <Button
                  variant='gray'
                  disabled={downloadReportLoading}
                  isLoading={downloadReportLoading}
                  id='generate-report'
                  size='md'
                  className='line-height-normal text-uppercase border-radius-3'
                  style={{ marginLeft: '10px' }}
                  onClick={() => downloadXLSX()}>
                  Export Report
                </Button>
              )}
            </div>
          </Container>
          <hr />
          {isLoading && <ABSpinner />}
          {!isLoading && (
            <Container
              className='account-report custom-offset-lg-1 custom-col-lg-12 px-0'
              fluid>
              {data?.length > 0 &&
                data?.map((record, recordIndex) => (
                  <React.Fragment key={record?._id}>
                    <Row className='m-0 d-lg-block'>
                      <div className='col-xs-12'>
                        <div>
                          <Table
                            hover
                            responsive
                            className='custom-table'
                            id={record?._id}>
                            <caption className='caption-top'>
                              <h2>
                                {record?.fullName?.toUpperCase()}
                                {renderStaffCoachIcon(record?.isStaffCoach)}
                              </h2>
                            </caption>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th width='6%'>Client / Group</th>
                                <th width='12%'>Plan</th>
                                <th>Session #</th>
                                <th>Global Session #</th>
                                <th>Session # in this cycle</th>
                                <th className='coaching-cycle'>
                                  Coaching Cycle #
                                </th>
                                <th>Sessions</th>
                                <th>Session Duration</th>
                                <th>Session Pay</th>
                                <th>Reports</th>
                                <th>Report Duration</th>
                                <th>Report Pay</th>
                                <th
                                  id='total'
                                  className='sort_th_class clickable'>
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {record?.clients?.map((client, index) => (
                                <React.Fragment key={client.id}>
                                  <tr key={client.id}>
                                    <td rowSpan={client?.plans.length}>
                                      {index + 1}
                                    </td>
                                    <td rowSpan={client?.plans.length}>
                                      <Link
                                        to={`/admin/users/${client?.id}`}
                                        target='_blank'>
                                        {client?.fullName}
                                      </Link>{' '}
                                      <br />
                                      <Link
                                        to={`/admin/users/${client?.id}`}
                                        target='_blank'>
                                        {client?.email}
                                      </Link>{' '}
                                      <br />
                                      {client?.roleInCompany} <br />
                                      {client?.company} <br />
                                    </td>
                                    <React.Fragment key={client?.id}>
                                      {[
                                        client?.plans.length > 0 &&
                                          client?.plans[0],
                                      ]?.map((clientPlan, index) => (
                                        <React.Fragment key={index}>
                                          <td>
                                            <b>Plan Name : </b>
                                            {clientPlan?.planName}
                                            <br />
                                            <b>Plan Cycle : </b>
                                            {clientPlan?.coachingCycle}
                                            <br />
                                            <b>First Session Date : </b>
                                            {formatDate(
                                              clientPlan?.firstSessionDate,
                                            )}
                                            <br />
                                            <b>Sessions Done : </b>
                                            {clientPlan &&
                                            'totalSessionTaken' in clientPlan
                                              ? clientPlan?.totalSessionTaken
                                              : 0}{' '}
                                            {((clientPlan?.numberOfCoachingCycle &&
                                              clientPlan?.sessionPerCoachingCycle) ||
                                              clientPlan?.planIndefinite) && (
                                              <>
                                                (
                                                {clientPlan?.planIndefinite
                                                  ? 'Indefinite'
                                                  : clientPlan?.numberOfCoachingCycle *
                                                    clientPlan?.sessionPerCoachingCycle}
                                                )
                                              </>
                                            )}
                                            <br />
                                            <b>Hourly Rate : </b>${' '}
                                            {clientPlan?.hourlyRate || 0}
                                            <br />
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (session, index) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {index + 1}
                                                  <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (session) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {session?.sequence}
                                                  <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (currentValue, index, array) => {
                                                if (
                                                  index > 0 &&
                                                  array[index - 1]
                                                    ?.coachingMonthNumber ===
                                                    array[index]
                                                      ?.coachingMonthNumber
                                                ) {
                                                  coachingMonthRangeIndex += 1
                                                } else {
                                                  coachingMonthRangeIndex = 0
                                                }
                                                return (
                                                  <React.Fragment key={index}>
                                                    {coachingMonthRangeIndex +
                                                      1}
                                                    <br />
                                                  </React.Fragment>
                                                )
                                              },
                                            )}
                                          </td>
                                          <td className='coaching-cycle'>
                                            {clientPlan?.sessions?.map(
                                              (session) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {session?.coachingMonthNumber}{' '}
                                                  ({' '}
                                                  {session?.coachingMonthRange}{' '}
                                                  ) <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (session) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {convertSessionDate(
                                                    session?.date,
                                                  )}
                                                  <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (session, i) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {session?.length} mins
                                                  <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td className='text-nowrap'>
                                            {clientPlan?.sessions?.map(
                                              (session) => (
                                                <div
                                                  style={{
                                                    color: session?.paid
                                                      ? 'green'
                                                      : 'orangered',
                                                  }}
                                                  key={session?.id}>
                                                  ${' '}
                                                  {session?.amount?.toFixed(2)}
                                                  <br />
                                                </div>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.documents?.map(
                                              (doc, i) => {
                                                if (doc?.type === 1) {
                                                  return null
                                                } else {
                                                  return (
                                                    <React.Fragment
                                                      key={doc.id}>
                                                      {doc?.type === 2
                                                        ? '360 | '
                                                        : 'Impact | '}
                                                      {formatDate(
                                                        doc?.uploadedAt,
                                                      )}
                                                      <br />
                                                    </React.Fragment>
                                                  )
                                                }
                                              },
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.documents?.map(
                                              (doc, i) => {
                                                if (doc?.type === 1) {
                                                  return null
                                                } else {
                                                  return (
                                                    <React.Fragment
                                                      key={doc.id}>
                                                      {doc && 'duration' in doc
                                                        ? `${doc.duration} hours`
                                                        : '-'}
                                                      <br />
                                                    </React.Fragment>
                                                  )
                                                }
                                              },
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.documents?.map(
                                              (doc) => {
                                                if (
                                                  doc?.type === 1 ||
                                                  !(doc && 'duration' in doc)
                                                ) {
                                                  return null
                                                } else {
                                                  return (
                                                    <span
                                                      key={doc.id}
                                                      style={{
                                                        color: doc?.paid
                                                          ? 'green'
                                                          : 'orangered',
                                                      }}>
                                                      $ {doc?.amount}
                                                      <br />
                                                    </span>
                                                  )
                                                }
                                              },
                                            )}
                                          </td>
                                          <td className='text-nowrap'>
                                            $ {clientPlan?.total?.toFixed(2)}
                                          </td>
                                        </React.Fragment>
                                      ))}
                                    </React.Fragment>
                                  </tr>
                                  {client?.plans
                                    .slice(1)
                                    .map((clientPlan, index) => (
                                      <tr key={index}>
                                        <>
                                          <td>
                                            <b>Plan Name : </b>
                                            {clientPlan?.planName}
                                            <br />
                                            <b>Plan Cycle : </b>
                                            {clientPlan?.coachingCycle}
                                            <br />
                                            <b>First Session Date : </b>
                                            {formatDate(
                                              clientPlan?.firstSessionDate,
                                            )}
                                            <br />
                                            <b>Sessions Done : </b>
                                            {clientPlan &&
                                            'totalSessionTaken' in clientPlan
                                              ? clientPlan?.totalSessionTaken
                                              : 0}{' '}
                                            {((clientPlan?.numberOfCoachingCycle &&
                                              clientPlan?.sessionPerCoachingCycle) ||
                                              clientPlan?.planIndefinite) && (
                                              <>
                                                (
                                                {clientPlan?.planIndefinite
                                                  ? 'Indefinite'
                                                  : clientPlan?.numberOfCoachingCycle *
                                                    clientPlan?.sessionPerCoachingCycle}
                                                )
                                              </>
                                            )}
                                            <br />
                                            <b>Hourly Rate : </b>${' '}
                                            {clientPlan?.hourlyRate || 0}
                                            <br />
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (session, index) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {index + 1}
                                                  <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (session) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {session?.sequence}
                                                  <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (currentValue, index, array) => {
                                                if (
                                                  index > 0 &&
                                                  array[index - 1]
                                                    ?.coachingMonthNumber ===
                                                    array[index]
                                                      ?.coachingMonthNumber
                                                ) {
                                                  coachingMonthRangeIndex += 1
                                                } else {
                                                  coachingMonthRangeIndex = 0
                                                }
                                                return (
                                                  <React.Fragment key={index}>
                                                    {coachingMonthRangeIndex +
                                                      1}
                                                    <br />
                                                  </React.Fragment>
                                                )
                                              },
                                            )}
                                          </td>
                                          <td className='coaching-cycle'>
                                            {clientPlan?.sessions?.map(
                                              (session) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {session?.coachingMonthNumber}{' '}
                                                  ({' '}
                                                  {session?.coachingMonthRange}{' '}
                                                  ) <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (session) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {convertSessionDate(
                                                    session?.date,
                                                  )}
                                                  <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.sessions?.map(
                                              (session, i) => (
                                                <React.Fragment
                                                  key={session?.id}>
                                                  {session?.length} mins
                                                  <br />
                                                </React.Fragment>
                                              ),
                                            )}
                                          </td>
                                          <td className='text-nowrap'>
                                            {clientPlan?.sessions?.map(
                                              (session) => (
                                                <div
                                                  style={{
                                                    color: session?.paid
                                                      ? 'green'
                                                      : 'orangered',
                                                  }}
                                                  key={session?.id}>
                                                  ${' '}
                                                  {session?.amount?.toFixed(2)}
                                                  <br />
                                                </div>
                                              ),
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.documents?.map(
                                              (doc, i) => {
                                                if (doc?.type === 1) {
                                                  return null
                                                } else {
                                                  return (
                                                    <React.Fragment
                                                      key={doc.id}>
                                                      {doc?.type === 2
                                                        ? '360 | '
                                                        : 'Impact | '}
                                                      {formatDate(
                                                        doc?.uploadedAt,
                                                      )}
                                                      <br />
                                                    </React.Fragment>
                                                  )
                                                }
                                              },
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.documents?.map(
                                              (doc, i) => {
                                                if (doc?.type === 1) {
                                                  return null
                                                } else {
                                                  return (
                                                    <React.Fragment
                                                      key={doc.id}>
                                                      {doc && 'duration' in doc
                                                        ? `${doc.duration} hours`
                                                        : '-'}
                                                      <br />
                                                    </React.Fragment>
                                                  )
                                                }
                                              },
                                            )}
                                          </td>
                                          <td>
                                            {clientPlan?.documents?.map(
                                              (doc) => {
                                                if (
                                                  doc?.type === 1 ||
                                                  !(doc && 'duration' in doc)
                                                ) {
                                                  return null
                                                } else {
                                                  return (
                                                    <span
                                                      key={doc.id}
                                                      style={{
                                                        color: doc?.paid
                                                          ? 'green'
                                                          : 'orangered',
                                                      }}>
                                                      $ {doc?.amount}
                                                      <br />
                                                    </span>
                                                  )
                                                }
                                              },
                                            )}
                                          </td>
                                          <td className='text-nowrap'>
                                            $ {clientPlan?.total?.toFixed(2)}
                                          </td>
                                        </>
                                      </tr>
                                    ))}
                                </React.Fragment>
                              ))}
                              {record?.teams?.map((team, index) => (
                                <tr key={team.id}>
                                  <td>{index + 1}</td>
                                  <td width='20%'>
                                    <b>Group Name : </b> {team?.name} <br />
                                    <b>Hourly Pay to Coach : </b>&nbsp; ${' '}
                                    {team?.hourlyPayToCoach} <br />
                                    <b>Session Done : </b>{' '}
                                    {team?.totalSessionTaken} (
                                    {!team?.sessionsPerMonth
                                      ? 'Indefinite'
                                      : team?.sessionsPerMonth}
                                    )
                                  </td>
                                  <td />
                                  <td>
                                    {team?.sessions?.map((session, index) => (
                                      <React.Fragment key={session?.id}>
                                        {index + 1}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </td>
                                  <td>
                                    {team?.sessions?.map((session) => (
                                      <React.Fragment key={session?.id}>
                                        {session?.sequence}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {team?.sessions?.map((session, i) => (
                                      <React.Fragment key={session?.id}>
                                        {convertSessionDate(session?.date)}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </td>
                                  <td>
                                    {team?.sessions?.map((session, i) => (
                                      <React.Fragment key={i}>
                                        {session?.length} mins
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </td>
                                  <td width='15%'>
                                    {team?.sessions?.map((session, i) => (
                                      <div
                                        style={{
                                          color: session?.paid
                                            ? 'green'
                                            : 'orangered',
                                        }}
                                        className='text-nowrap'
                                        key={i}>
                                        $ {session?.amount?.toFixed(2)}
                                        <br />
                                      </div>
                                    ))}
                                  </td>
                                  <td />
                                  <td />
                                  <td />
                                  <td className='text-nowrap'>
                                    $ {team?.total?.toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                              <EnterpriseClients
                                enterpriseClients={record?.enterpriseClients}
                              />
                              <EnterpriseTeams
                                enterpriseTeams={record?.enterpriseTeams}
                              />
                              <EnterpriseOneTimePayments
                                oneTimePayments={record?.oneTimePayments}
                              />
                            </tbody>
                            <tfoot>
                              <tr>
                                <th className='text-right' colSpan='13'>
                                  Total Unpaid :
                                </th>
                                <td>
                                  <label
                                    className='form-label text-right text-nowrap'
                                    style={{
                                      color: 'orangered',
                                    }}>
                                    $ {record?.totalUnpaid?.toFixed(2)}
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <th className='text-right' colSpan='13'>
                                  Total Paid :
                                </th>
                                <td>
                                  <label
                                    className='form-label text-right text-nowrap'
                                    style={{ color: 'green' }}>
                                    $ {record?.totalPaid?.toFixed(2)}
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td className='text-right' colSpan='12'>
                                  <Button
                                    variant='gray'
                                    className='btn-gray-sm text-uppercase'
                                    size='sm'
                                    id='mark-as-paid'
                                    disabled={
                                      markAsPaidLoaderIndex !== null ||
                                      record?.totalUnpaid === 0
                                    }
                                    onClick={() =>
                                      markAsPaid(record?.id, recordIndex)
                                    }>
                                    Mark as paid
                                  </Button>
                                  {markAsPaidLoaderIndex === recordIndex && (
                                    <RBSpinner
                                      animation='border'
                                      className='text-saffron-700'
                                      role='status'
                                      size='sm'
                                    />
                                  )}
                                </td>
                                <th className='text-right'>Total :</th>
                                <td colSpan='3' className='text-nowrap'>
                                  $ {record?.total?.toFixed(2)}
                                </td>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </div>
                    </Row>
                    <hr />
                  </React.Fragment>
                ))}
              {data.length === 0 && (
                <h3 className='font-montserrat text-center'>
                  No Record Found.
                </h3>
              )}
            </Container>
          )}
        </section>
      </main>
    </>
  )
}

export default Account
