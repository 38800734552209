import { plans } from '@abroad/components'

const countriesData = {
  Afghanistan: { ct: 'Asia' },
  'Åland Islands': { ct: 'Europe' },
  Albania: { ct: 'Europe' },
  Algeria: { ct: 'Africa' },
  'American Samoa': { ct: 'Oceania' },
  Andorra: { ct: 'Europe' },
  Angola: { ct: 'Africa' },
  Anguilla: { ct: 'North America' },
  Antarctica: { ct: 'Antarctica' },
  'Antigua & Barbuda': { ct: 'North America' },
  Argentina: { ct: 'South America' },
  Armenia: { ct: 'Asia' },
  Aruba: { ct: 'North America' },
  Australia: { ct: 'Oceania' },
  Austria: { ct: 'Europe' },
  Azerbaijan: { ct: 'Asia' },
  Bahamas: { ct: 'North America' },
  Bahrain: { ct: 'Asia' },
  Bangladesh: { ct: 'Asia' },
  Barbados: { ct: 'North America' },
  Belarus: { ct: 'Europe' },
  Belgium: { ct: 'Europe' },
  Belize: { ct: 'North America' },
  Benin: { ct: 'Africa' },
  Bermuda: { ct: 'North America' },
  Bhutan: { ct: 'Asia' },
  Bolivia: { ct: 'South America' },
  'Bosnia & Herzegovina': { ct: 'Europe' },
  Botswana: { ct: 'Africa' },
  Brazil: { ct: 'South America' },
  'British Indian Ocean Territory': { ct: 'Africa' },
  'British Virgin Islands': { ct: 'North America' },
  Brunei: { ct: 'Asia' },
  Bulgaria: { ct: 'Europe' },
  'Burkina Faso': { ct: 'Africa' },
  Burundi: { ct: 'Africa' },
  Cambodia: { ct: 'Asia' },
  Cameroon: { ct: 'Africa' },
  Canada: { ct: 'North America' },
  'Cape Verde': { ct: 'Africa' },
  'Cayman Islands': { ct: 'North America' },
  'Central African Republic': { ct: 'Africa' },
  Chad: { ct: 'Africa' },
  Chile: { ct: 'South America' },
  China: { ct: 'Asia' },
  'Christmas Island': { ct: 'Oceania' },
  'Cocos (Keeling) Islands': { ct: 'Oceania' },
  Colombia: { ct: 'South America' },
  Comoros: { ct: 'Africa' },
  'Congo - Brazzaville': { ct: 'Africa' },
  'Congo - Kinshasa': { ct: 'Africa' },
  'Cook Islands': { ct: 'Oceania' },
  'Costa Rica': { ct: 'North America' },
  'Côte d’Ivoire': { ct: 'Africa' },
  Croatia: { ct: 'Europe' },
  Cuba: { ct: 'North America' },
  Curaçao: { ct: 'North America' },
  Cyprus: { ct: 'Asia' },
  Czechia: { ct: 'Europe' },
  Denmark: { ct: 'Europe' },
  Djibouti: { ct: 'Africa' },
  Dominica: { ct: 'North America' },
  'Dominican Republic': { ct: 'North America' },
  Ecuador: { ct: 'South America' },
  Egypt: { ct: 'Africa' },
  'El Salvador': { ct: 'North America' },
  'Equatorial Guinea': { ct: 'Africa' },
  Eritrea: { ct: 'Africa' },
  Estonia: { ct: 'Europe' },
  Ethiopia: { ct: 'Africa' },
  'Falkland Islands': { ct: 'South America' },
  'Faroe Islands': { ct: 'Europe' },
  Fiji: { ct: 'Oceania' },
  Finland: { ct: 'Europe' },
  France: { ct: 'Europe' },
  'French Guiana': { ct: 'South America' },
  'French Polynesia': { ct: 'Oceania' },
  'French Southern Territories': { ct: 'Africa' },
  Gabon: { ct: 'Africa' },
  Gambia: { ct: 'Africa' },
  Georgia: { ct: 'Asia' },
  Germany: { ct: 'Europe' },
  Ghana: { ct: 'Africa' },
  Gibraltar: { ct: 'Europe' },
  Greece: { ct: 'Europe' },
  Greenland: { ct: 'North America' },
  Grenada: { ct: 'North America' },
  Guadeloupe: { ct: 'North America' },
  Guam: { ct: 'Oceania' },
  Guatemala: { ct: 'North America' },
  Guernsey: { ct: 'Europe' },
  Guinea: { ct: 'Africa' },
  'Guinea-Bissau': { ct: 'Africa' },
  Guyana: { ct: 'South America' },
  Haiti: { ct: 'North America' },
  Honduras: { ct: 'North America' },
  'Hong Kong SAR China': { ct: 'Asia' },
  Hungary: { ct: 'Europe' },
  Iceland: { ct: 'Europe' },
  India: { ct: 'Asia' },
  Indonesia: { ct: 'Asia' },
  Iran: { ct: 'Asia' },
  Iraq: { ct: 'Asia' },
  Ireland: { ct: 'Europe' },
  'Isle of Man': { ct: 'Europe' },
  Israel: { ct: 'Asia' },
  Italy: { ct: 'Europe' },
  Jamaica: { ct: 'North America' },
  Japan: { ct: 'Asia' },
  Jersey: { ct: 'Europe' },
  Jordan: { ct: 'Asia' },
  Kazakhstan: { ct: 'Asia' },
  Kenya: { ct: 'Africa' },
  Kiribati: { ct: 'Oceania' },
  Kosovo: { ct: 'Europe' },
  Kuwait: { ct: 'Asia' },
  Kyrgyzstan: { ct: 'Asia' },
  Laos: { ct: 'Asia' },
  Latvia: { ct: 'Europe' },
  Lebanon: { ct: 'Asia' },
  Lesotho: { ct: 'Africa' },
  Liberia: { ct: 'Africa' },
  Libya: { ct: 'Africa' },
  Liechtenstein: { ct: 'Europe' },
  Lithuania: { ct: 'Europe' },
  Luxembourg: { ct: 'Europe' },
  'Macau SAR China': { ct: 'Asia' },
  Macedonia: { ct: 'Europe' },
  Madagascar: { ct: 'Africa' },
  Malawi: { ct: 'Africa' },
  Malaysia: { ct: 'Asia' },
  Maldives: { ct: 'Asia' },
  Mali: { ct: 'Africa' },
  Malta: { ct: 'Europe' },
  'Marshall Islands': { ct: 'Oceania' },
  Martinique: { ct: 'North America' },
  Mauritania: { ct: 'Africa' },
  Mauritius: { ct: 'Africa' },
  Mayotte: { ct: 'Africa' },
  Mexico: { ct: 'North America' },
  Micronesia: { ct: 'Oceania' },
  Moldova: { ct: 'Europe' },
  Monaco: { ct: 'Europe' },
  Mongolia: { ct: 'Asia' },
  Montenegro: { ct: 'Europe' },
  Montserrat: { ct: 'North America' },
  Morocco: { ct: 'Africa' },
  Mozambique: { ct: 'Africa' },
  'Myanmar (Burma)': { ct: 'Asia' },
  Namibia: { ct: 'Africa' },
  Nauru: { ct: 'Oceania' },
  Nepal: { ct: 'Asia' },
  Netherlands: { ct: 'Europe' },
  'New Caledonia': { ct: 'Oceania' },
  'New Zealand': { ct: 'Oceania' },
  Nicaragua: { ct: 'North America' },
  Niger: { ct: 'Africa' },
  Nigeria: { ct: 'Africa' },
  Niue: { ct: 'Oceania' },
  'Norfolk Island': { ct: 'Oceania' },
  'North Korea': { ct: 'Asia' },
  'Northern Mariana Islands': { ct: 'Oceania' },
  Norway: { ct: 'Europe' },
  Oman: { ct: 'Asia' },
  Pakistan: { ct: 'Asia' },
  Palau: { ct: 'Oceania' },
  'Palestinian Territories': { ct: 'Asia' },
  Panama: { ct: 'North America' },
  'Papua New Guinea': { ct: 'Oceania' },
  Paraguay: { ct: 'South America' },
  Peru: { ct: 'South America' },
  Philippines: { ct: 'Asia' },
  'Pitcairn Islands': { ct: 'Oceania' },
  Poland: { ct: 'Europe' },
  Portugal: { ct: 'Europe' },
  'Puerto Rico': { ct: 'North America' },
  Qatar: { ct: 'Asia' },
  Réunion: { ct: 'Africa' },
  Romania: { ct: 'Europe' },
  Russia: { ct: 'Europe' },
  Rwanda: { ct: 'Africa' },
  Samoa: { ct: 'Oceania' },
  'San Marino': { ct: 'Europe' },
  'São Tomé & Príncipe': { ct: 'Africa' },
  'Saudi Arabia': { ct: 'Asia' },
  Senegal: { ct: 'Africa' },
  Serbia: { ct: 'Europe' },
  Seychelles: { ct: 'Africa' },
  'Sierra Leone': { ct: 'Africa' },
  Singapore: { ct: 'Asia' },
  'Sint Maarten': { ct: 'North America' },
  Slovakia: { ct: 'Europe' },
  Slovenia: { ct: 'Europe' },
  'Solomon Islands': { ct: 'Oceania' },
  Somalia: { ct: 'Africa' },
  'South Africa': { ct: 'Africa' },
  'South Georgia & South Sandwich Islands': { ct: 'South America' },
  'South Korea': { ct: 'Asia' },
  'South Sudan': { ct: 'Africa' },
  Spain: { ct: 'Europe' },
  'Sri Lanka': { ct: 'Asia' },
  'St. Barthélemy': { ct: 'North America' },
  'St. Helena': { ct: 'Africa' },
  'St. Kitts & Nevis': { ct: 'North America' },
  'St. Lucia': { ct: 'North America' },
  'St. Martin': { ct: 'North America' },
  'St. Pierre & Miquelon': { ct: 'North America' },
  'St. Vincent & Grenadines': { ct: 'North America' },
  Sudan: { ct: 'Africa' },
  Suriname: { ct: 'South America' },
  'Svalbard & Jan Mayen': { ct: 'Europe' },
  Swaziland: { ct: 'Africa' },
  Sweden: { ct: 'Europe' },
  Switzerland: { ct: 'Europe' },
  Syria: { ct: 'Asia' },
  Taiwan: { ct: 'Asia' },
  Tajikistan: { ct: 'Asia' },
  Tanzania: { ct: 'Africa' },
  Thailand: { ct: 'Asia' },
  'Timor-Leste': { ct: 'Asia' },
  Togo: { ct: 'Africa' },
  Tokelau: { ct: 'Oceania' },
  Tonga: { ct: 'Oceania' },
  'Trinidad & Tobago': { ct: 'North America' },
  'Tristan da Cunha': { ct: 'Africa' },
  Tunisia: { ct: 'Africa' },
  Turkey: { ct: 'Asia' },
  Turkmenistan: { ct: 'Asia' },
  'Turks & Caicos Islands': { ct: 'North America' },
  Tuvalu: { ct: 'Oceania' },
  'U.S. Outlying Islands': { ct: 'Oceania' },
  'U.S. Virgin Islands': { ct: 'North America' },
  Uganda: { ct: 'Africa' },
  Ukraine: { ct: 'Europe' },
  'United Arab Emirates': { ct: 'Asia' },
  'United Kingdom': { ct: 'Europe' },
  'United States': { ct: 'North America' },
  Uruguay: { ct: 'South America' },
  Uzbekistan: { ct: 'Asia' },
  Vanuatu: { ct: 'Oceania' },
  'Vatican City': { ct: 'Europe' },
  Venezuela: { ct: 'South America' },
  Vietnam: { ct: 'Asia' },
  'Wallis & Futuna': { ct: 'Oceania' },
  'Western Sahara': { ct: 'Africa' },
  Yemen: { ct: 'Asia' },
  Zambia: { ct: 'Africa' },
  Zimbabwe: { ct: 'Africa' },
}

const labels = {
  affiliation: [
    '500 Startups',
    '500 Global Flagship Fund',
    'CO.STARTERS',
    'Callaghan Innovation',
    'Global Accelerator Network',
    'Icehouse',
    'Insight Ignite',
    'London Stock Exchange ELITE',
    'New Zealand Trade & Enterprise',
    'TechStars',
    'The World Innovation Network (TWIN)',
    'Angel Association of New Zealand',
    '500 Durians (Southeast Asia)',
    'YCombinator',
  ],
  companyName: ['Venture techlabs', 'Abroad', 'Tech Shere software'],
  plans: [
    { label: plans.free.name, value: plans.free.code },
    { label: plans.growth.name, value: plans.growth.code },
    { label: plans.coaching.name, value: plans.coaching.code },
  ],

  groupType: [
    'Organization',
    'Network',
    'Promo Code',
    'Free Select',
    'Organization With SOW',
    'Network With SOW',
    //'Enterprise',
  ],

  participantLevel: [
    'leader',
    'executive',
    'CEO or Board Member',
    'Individual Contributor',
    'manager',
  ],

  promoCode: ['Test100', 'Abroad100'],
  paymentCycle: [
    { key: 'onetime', value: 'One Time Payment' },
    { key: 'recurring', value: 'Recurring Payment' },
  ],
  SOWBillEvery: [1, 2, 3, 6, 12],
  coachingPlanStatusConst: {
    Draft: 'DRAFT',
    // Pending: 'PENDING',
    Resumed: 'RESUMED',
    InProgress: 'IN_PROGRESS',
    Terminate: 'STOPPED',
    Paused: 'PAUSED',
  },
  // do not change order of coachingPlanStatus it's affect coaching-plan code
  coachingPlanStatus: [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Terminate', value: 'STOPPED' },
    // { label: 'Pending', value: 'PENDING' },
    { label: 'Resumed', value: 'RESUMED' },
    { label: 'Paused', value: 'PAUSED' },
  ],
  paymentStatus: {
    PAID: 'PAID',
    FAILED: 'FAILED',
    PAUSED: 'PAUSED',
    CANCELLED: 'CANCELLED',
    PENDING: 'PENDING',
    NOT_INITIATED: 'NOT_INITIATED',
    NO_BILLING_REQUIRED: 'NO_BILLING_REQUIRED',
    RESUMED: 'RESUMED',
    // SUCCEEDED: 'succeeded',
    // FAILED: 'failed',
    // ALL: 'all',
    // PAUSED: 'paused',
    // STOPPED: 'stopped',
    // PENDING: 'pending',
    // 'NOT-INITIATED': 'not_initiated',
  },
  planCycle: [
    { key: 'Monthly', value: 'Monthly' },
    { key: 'Annually', value: 'Annually' },
  ],
  ReportPlanOf360: [
    {
      key: 1,
      value:
        '1 Hour: Two 30 minute interviews + No report (log this as a usual session)',
    },
    {
      key: 2,
      value:
        '2 Hours: Three 30 minute interviews + 1 brief 30 minute summary write up',
    },
    { key: 4, value: '4 Hours: (4 People x 30 minutes) + Report Write- up' },
    { key: 5, value: '5 Hours: (6 People x 30 minutes) + Report Write - up' },
    { key: 7, value: '7 Hours: (8 People x 30 minutes) + Report Write - up' },
  ],

  impactReportPlan: [
    {
      key: 0,
      value:
        '0 Hours: Quantitative Only(Assessment Pre & Post Results Comparison) & No Report Write- up',
    },
    {
      key: 2,
      value:
        '2 Hours: Quantitative + Qualitative Impact Report (Review of quant comparison, write up of qual reflections)',
    },
  ],

  timeZoneList: [
    {
      value: 'America/Adak',
      label: 'Hawaii-Aleutian Standard Time (GMT-10:00)',
    },
    { value: 'America/Yakutat', label: 'Alaska Standard Time (GMT-9:00)' },
    { value: 'America/Dawson', label: 'Pacific Standard Time(PST) (GMT-8:00)' },
    {
      value: 'America/Chihuahua',
      label: 'Pacific Daylight Saving Time(PDT) (GMT-7:00)',
    },
    { value: 'America/Boise', label: 'Mountain Standard Time(MST) (GMT-7:00)' },
    { value: 'Canada/Central', label: 'Central Standard Time(CST) (GMT-6:00)' },
    { value: 'Chile/EasterIsland', label: 'Easter Island Time (GMT-6:00)' },
    {
      value: 'America/Atikokan',
      label: 'Eastern Standard Time(EST) (GMT-5:00)',
    },
    { value: 'America/Bogota', label: 'Colombia Time (GMT-5:00)' },
    { value: 'America/Guayaquil', label: 'Ecuador Time (GMT-5:00)' },
    { value: 'America/Havana', label: 'Cuba Standard Time (GMT-5:00)' },
    { value: 'America/Lima', label: 'Peru Time (GMT-5:00)' },
    { value: 'America/Caracas', label: 'Venezuela Time (GMT-4:-30)' },
    { value: 'America/Aruba', label: 'Eastern Daylight Time(EDT) (GMT-4:00)' },
    { value: 'America/Asuncion', label: 'Paraguay Time (GMT-4:00)' },
    { value: 'America/Guyana', label: 'Guyana Time (GMT-4:00)' },
    { value: 'America/La_Paz', label: 'Bolivia Time (GMT-4:00)' },
    { value: 'Atlantic/Stanley', label: 'Falkland Island Time (GMT-4:00)' },
    { value: 'Brazil/Acre', label: 'Amazon Time (GMT-4:00)' },
    {
      value: 'Canada/Atlantic',
      label: 'Atlantic Standard Time(AST) (GMT-4:00)',
    },
    { value: 'Chile/Continental', label: 'Chile Time (GMT-4:00)' },
    {
      value: 'America/St_Johns',
      label: 'Newfoundland Standard Time (GMT-3:-30)',
    },
    { value: 'America/Buenos_Aires', label: 'Argentine Time (GMT-3:00)' },
    { value: 'America/Cayenne', label: 'French Guiana Time (GMT-3:00)' },
    { value: 'America/Godthab', label: 'Western Greenland Time (GMT-3:00)' },
    {
      value: 'America/Miquelon',
      label: 'Pierre & Miquelon Standard Time (GMT-3:00)',
    },
    { value: 'America/Montevideo', label: 'Uruguay Time (GMT-3:00)' },
    { value: 'America/Paramaribo', label: 'Suritz Time (GMT-3:00)' },
    { value: 'Antarctica/Rothera', label: 'Rothera Time (GMT-3:00)' },
    { value: 'Brazil/East', label: 'Brasilia Time (GMT-3:00)' },
    {
      value: 'Atlantic/South_Georgia',
      label: 'South Georgia Standard Time (GMT-2:00)',
    },
    { value: 'Brazil/DeNoronha', label: 'Fernando de Noronha Time (GMT-2:00)' },
    {
      value: 'America/Scoresbysund',
      label: 'Eastern Greenland Time (GMT-1:00)',
    },
    { value: 'Atlantic/Azores', label: 'Azores Time (GMT-1:00)' },
    { value: 'Atlantic/Cape_Verde', label: 'Cape Verde Time (GMT-1:00)' },
    { value: 'Africa/Abidjan', label: 'Greenwich Mean Time(GMT) (GMT+0:00)' },
    { value: 'Africa/Accra', label: 'Ghana Mean Time (GMT+0:00)' },
    {
      value: 'Africa/Casablanca',
      label: 'Western European Time(WET) (GMT+0:00)',
    },
    { value: 'Europe/Stockholm', label: 'British Summer Time(BST) (GMT+1:00)' },
    { value: 'Africa/Algiers', label: 'Central European Time(CET) (GMT+1:00)' },
    { value: 'Africa/Bangui', label: 'Western African Time (GMT+1:00)' },
    { value: 'Africa/Blantyre', label: 'Central African Time (GMT+2:00)' },
    {
      value: 'Africa/Johannesburg',
      label: 'South Africa Standard Time (GMT+2:00)',
    },
    { value: 'Asia/Jerusalem', label: 'Israel Standard Time (GMT+2:00)' },
    {
      value: 'Europe/Zaporozhye',
      label: 'Eastern European Time(EET) (GMT+2:00)',
    },
    { value: 'Antarctica/Syowa', label: 'Syowa Time (GMT+3:00)' },
    { value: 'Asia/Qatar', label: 'Arabia Standard Time (GMT+3:00)' },
    { value: 'Europe/Moscow', label: 'Moscow Standard Time(MSK) (GMT+3:00)' },
    { value: 'Europe/Volgograd', label: 'Volgograd Time (GMT+3:00)' },
    { value: 'Indian/Mayotte', label: 'Eastern African Time (GMT+3:00)' },
    { value: 'Asia/Tehran', label: 'Iran Standard Time (GMT+3:30)' },
    { value: 'Asia/Baku', label: 'Azerbaijan Time (GMT+4:00)' },
    { value: 'Asia/Dubai', label: 'Gulf Standard Time (GMT+4:00)' },
    { value: 'Asia/Tbilisi', label: 'Georgia Time (GMT+4:00)' },
    { value: 'Asia/Yerevan', label: 'Armenia Time (GMT+4:00)' },
    { value: 'Europe/Samara', label: 'Samara Time (GMT+4:00)' },
    { value: 'Indian/Mahe', label: 'Seychelles Time (GMT+4:00)' },
    { value: 'Indian/Mauritius', label: 'Mauritius Time (GMT+4:00)' },
    { value: 'Indian/Reunion', label: 'Reunion Time (GMT+4:00)' },
    { value: 'Asia/Kabul', label: 'Afghanistan Time (GMT+4:30)' },
    { value: 'Asia/Aqtau', label: 'Aqtau Time (GMT+5:00)' },
    { value: 'Asia/Aqtobe', label: 'Aqtobe Time (GMT+5:00)' },
    { value: 'Asia/Ashkhabad', label: 'Turkmenistan Time (GMT+5:00)' },
    { value: 'Asia/Dushanbe', label: 'Tajikistan Time (GMT+5:00)' },
    { value: 'Asia/Karachi', label: 'Pakistan Time (GMT+5:00)' },
    { value: 'Asia/Oral', label: 'Oral Time (GMT+5:00)' },
    { value: 'Asia/Samarkand', label: 'Uzbekistan Time (GMT+5:00)' },
    { value: 'Asia/Yekaterinburg', label: 'Yekaterinburg Time (GMT+5:00)' },
    {
      value: 'Indian/Kerguelen',
      label: 'French Southern & Antarctic Lands Time (GMT+5:00)',
    },
    { value: 'Indian/Maldives', label: 'Maldives Time (GMT+5:00)' },
    { value: 'Asia/Kolkata', label: 'India Standard Time(IST) (GMT+5:30)' },
    { value: 'Asia/Katmandu', label: 'Nepal Time (GMT+5:45)' },
    { value: 'Antarctica/Mawson', label: 'Mawson Time (GMT+6:00)' },
    { value: 'Antarctica/Vostok', label: 'Vostok Time (GMT+6:00)' },
    { value: 'Asia/Almaty', label: 'Alma-Ata Time (GMT+6:00)' },
    { value: 'Asia/Bishkek', label: 'Kirgizstan Time (GMT+6:00)' },
    { value: 'Asia/Dhaka', label: 'Bangladesh Time (GMT+6:00)' },
    { value: 'Asia/Novosibirsk', label: 'Novosibirsk Time (GMT+6:00)' },
    { value: 'Asia/Omsk', label: 'Omsk Time (GMT+6:00)' },
    { value: 'Asia/Qyzylorda', label: 'Qyzylorda Time (GMT+6:00)' },
    { value: 'Asia/Thimphu', label: 'Bhutan Time (GMT+6:00)' },
    { value: 'Indian/Chagos', label: 'Indian Ocean Territory Time (GMT+6:00)' },
    { value: 'Asia/Rangoon', label: 'Myanmar Time (GMT+6:30)' },
    { value: 'Indian/Cocos', label: 'Cocos Islands Time (GMT+6:30)' },
    { value: 'Antarctica/Davis', label: 'Davis Time (GMT+7:00)' },
    { value: 'Asia/Bangkok', label: 'Indochina Time (GMT+7:00)' },
    { value: 'Asia/Hovd', label: 'Hovd Time (GMT+7:00)' },
    { value: 'Asia/Jakarta', label: 'West Indonesia Time (GMT+7:00)' },
    { value: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk Time (GMT+7:00)' },
    { value: 'Indian/Christmas', label: 'Christmas Island Time (GMT+7:00)' },
    { value: 'Asia/Brunei', label: 'Brunei Time (GMT+8:00)' },
    { value: 'Asia/Choibalsan', label: 'Choibalsan Time (GMT+8:00)' },
    { value: 'Asia/Hong_Kong', label: 'Hong Kong Time (GMT+8:00)' },
    { value: 'Asia/Irkutsk', label: 'Irkutsk Time (GMT+8:00)' },
    { value: 'Asia/Kuala_Lumpur', label: 'Malaysia Time (GMT+8:00)' },
    { value: 'Asia/Manila', label: 'Philippines Time (GMT+8:00)' },
    { value: 'Asia/Shanghai', label: 'China Standard Time(CST) (GMT+8:00)' },
    { value: 'Asia/Singapore', label: 'Singapore Time (GMT+8:00)' },
    { value: 'Asia/Ujung_Pandang', label: 'Central Indonesia Time (GMT+8:00)' },
    { value: 'Asia/Ulan_Bator', label: 'Ulaanbaatar Time (GMT+8:00)' },
    {
      value: 'Australia/Perth',
      label: 'Australia Western Standard Time (AWST) (GMT+8:00)',
    },
    {
      value: 'Australia/Eucla',
      label: 'Central Western Standard Time (Australia) (GMT+8:45)',
    },
    { value: 'Asia/Dili', label: 'Timor-Leste Time (GMT+9:00)' },
    { value: 'Asia/Jayapura', label: 'East Indonesia Time (GMT+9:00)' },
    { value: 'Asia/Seoul', label: 'Korea Standard Time (GMT+9:00)' },
    { value: 'Asia/Tokyo', label: 'Japan Standard Time(JST) (GMT+9:00)' },
    { value: 'Asia/Yakutsk', label: 'Yakutsk Time (GMT+9:00)' },
    {
      value: 'Australia/North',
      label: 'Australian Central Standard Time(CST) (GMT+9:30)',
    },
    {
      value: 'Antarctica/DumontDUrville',
      label: "Dumont-d'Urville Time (GMT+10:00)",
    },
    { value: 'Asia/Sakhalin', label: 'Sakhalin Time (GMT+10:00)' },
    { value: 'Asia/Vladivostok', label: 'Vladivostok Time (GMT+10:00)' },
    {
      value: 'Australia/ACT',
      label: 'Australian Eastern Standard Time(AEST) (GMT+10:00)',
    },
    {
      value: 'Australia/Queensland',
      label: 'Australian Eastern Standard Time(AEST) (GMT+10:00)',
    },
    {
      value: 'Australia/Tasmania',
      label: 'Australian Eastern Standard Time(AEST) (GMT+10:00)',
    },
    {
      value: 'Australia/Victoria',
      label: 'Australian Eastern Standard Time(AEST) (GMT+10:00)',
    },
    { value: 'Australia/LHI', label: 'Lord Howe Standard Time (GMT+10:30)' },
    { value: 'Asia/Magadan', label: 'Magadan Time (GMT+11:00)' },
    {
      value: 'Antarctica/South_Pole',
      label: 'New Zealand Standard Time(NZST) (GMT+12:00)',
    },
    { value: 'Asia/Anadyr', label: 'Anadyr Time (GMT+12:00)' },
    {
      value: 'Asia/Kamchatka',
      label: 'Petropavlovsk-Kamchatski Time (GMT+12:00)',
    },
  ],

  gender: [
    { key: 'Male', value: 'Male' },
    { key: 'Female', value: 'Female' },
    { key: 'Non-Binary', value: 'Non-Binary' },
    { key: 'Other', value: 'Other' },
  ],

  countryData: [
    { key: 'Afghanistan', value: 'Afghanistan' },
    { key: 'Åland Islands', value: 'Åland Islands' },
    { key: 'Albania', value: 'Albania' },
    { key: 'Algeria', value: 'Algeria' },
    { key: 'American Samoa', value: 'American Samoa' },
    { key: 'Andorra', value: 'Andorra' },
    { key: 'Angola', value: 'Angola' },
    { key: 'Anguilla', value: 'Anguilla' },
    { key: 'Antarctica', value: 'Antarctica' },
    { key: 'Antigua & Barbuda', value: 'Antigua & Barbuda' },
    { key: 'Argentina', value: 'Argentina' },
    { key: 'Armenia', value: 'Armenia' },
    { key: 'Aruba', value: 'Aruba' },
    { key: 'Australia', value: 'Australia' },
    { key: 'Austria', value: 'Austria' },
    { key: 'Azerbaijan', value: 'Azerbaijan' },
    { key: 'Bahamas', value: 'Bahamas' },
    { key: 'Bahrain', value: 'Bahrain' },
    { key: 'Bangladesh', value: 'Bangladesh' },
    { key: 'Barbados', value: 'Barbados' },
    { key: 'Belarus', value: 'Belarus' },
    { key: 'Belgium', value: 'Belgium' },
    { key: 'Belize', value: 'Belize' },
    { key: 'Benin', value: 'Benin' },
    { key: 'Bermuda', value: 'Bermuda' },
    { key: 'Bhutan', value: 'Bhutan' },
    { key: 'Bolivia', value: 'Bolivia' },
    { key: 'Bosnia & Herzegovina', value: 'Bosnia & Herzegovina' },
    { key: 'Botswana', value: 'Botswana' },
    { key: 'Brazil', value: 'Brazil' },
    { key: 'British Indian Ocean Territory', value: 'British Indian Ocean' },
    { key: 'British Virgin Islands', value: 'British Virgin Islands' },
    { key: 'Brunei', value: 'Brunei' },
    { key: 'Bulgaria', value: 'Bulgaria' },
    { key: 'Burkina Faso', value: 'Burkina Faso' },
    { key: 'Burundi', value: 'Burundi' },
    { key: 'Cambodia', value: 'Cambodia' },
    { key: 'Cameroon', value: 'Cameroon' },
    { key: 'Canada', value: 'Canada' },
    { key: 'Cape Verde', value: 'Cape Verde' },
    { key: 'Cayman Islands', value: 'Cayman Islands' },
    { key: 'Central African Republic', value: 'Central African Republic' },
    { key: 'Chad', value: 'Chad' },
    { key: 'Chile', value: 'Chile' },
    { key: 'China', value: 'China' },
    { key: 'Christmas Island', value: 'Christmas Island' },
    { key: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
    { key: 'Colombia', value: 'Colombia' },
    { key: 'Comoros', value: 'Comoros' },
    { key: 'Congo - Brazzaville', value: 'Congo - Brazzaville' },
    { key: 'Congo - Kinshasa', value: 'Congo - Kinshasa' },
    { key: 'Cook Islands', value: 'Cook Islands' },
    { key: 'Costa Rica', value: 'Costa Rica' },
    { key: 'Côte d’Ivoire', value: 'Côte d’Ivoire' },
    { key: 'Croatia', value: 'Croatia' },
    { key: 'Cuba', value: 'Cuba' },
    { key: 'Curaçao', value: 'Curaçao' },
    { key: 'Cyprus', value: 'Cyprus' },
    { key: 'Czechia', value: 'Czechia' },
    { key: 'Denmark', value: 'Denmark' },
    { key: 'Djibouti', value: 'Djibouti' },
    { key: 'Dominica', value: 'Dominica' },
    { key: 'Dominican Republic', value: 'Dominican Republic' },
    { key: 'Ecuador', value: 'Ecuador' },
    { key: 'Egypt', value: 'Egypt' },
    { key: 'El Salvador', value: 'El Salvador' },
    { key: 'Equatorial Guinea', value: 'Equatorial Guinea' },
    { key: 'Eritrea', value: 'Eritrea' },
    { key: 'Estonia', value: 'Estonia' },
    { key: 'Ethiopia', value: 'Ethiopia' },
    { key: 'Falkland Islands', value: 'Falkland Islands' },
    { key: 'Faroe Islands', value: 'Faroe Islands' },
    { key: 'Fiji', value: 'Fiji' },
    { key: 'Finland', value: 'Finland' },
    { key: 'France', value: 'France' },
    { key: 'French Guiana', value: 'French Guiana' },
    { key: 'French Polynesia', value: 'French Polynesia' },
    { key: 'French Southern Territories', value: 'French Southern' },
    { key: 'Gabon', value: 'Gabon' },
    { key: 'Gambia', value: 'Gambia' },
    { key: 'Georgia', value: 'Georgia' },
    { key: 'Germany', value: 'Germany' },
    { key: 'Ghana', value: 'Ghana' },
    { key: 'Gibraltar', value: 'Gibraltar' },
    { key: 'Greece', value: 'Greece' },
    { key: 'Greenland', value: 'Greenland' },
    { key: 'Grenada', value: 'Grenada' },
    { key: 'Guadeloupe', value: 'Guadeloupe' },
    { key: 'Guam', value: 'Guam' },
    { key: 'Guatemala', value: 'Guatemala' },
    { key: 'Guernsey', value: 'Guernsey' },
    { key: 'Guinea', value: 'Guinea' },
    { key: 'Guinea-Bissau', value: 'Guinea-Bissau' },
    { key: 'Guyana', value: 'Guyana' },
    { key: 'Haiti', value: 'Haiti' },
    { key: 'Honduras', value: 'Honduras' },
    { key: 'Hong Kong SAR China', value: 'Hong Kong SAR China' },
    { key: 'Hungary', value: 'Hungary' },
    { key: 'Iceland', value: 'Iceland' },
    { key: 'India', value: 'India' },
    { key: 'Indonesia', value: 'Indonesia' },
    { key: 'Iran', value: 'Iran' },
    { key: 'Iraq', value: 'Iraq' },
    { key: 'Ireland', value: 'Ireland' },
    { key: 'Isle of Man', value: 'Isle of Man' },
    { key: 'Israel', value: 'Israel' },
    { key: 'Italy', value: 'Italy' },
    { key: 'Jamaica', value: 'Jamaica' },
    { key: 'Japan', value: 'Japan' },
    { key: 'Jersey', value: 'Jersey' },
    { key: 'Jordan', value: 'Jordan' },
    { key: 'Kazakhstan', value: 'Kazakhstan' },
    { key: 'Kenya', value: 'Kenya' },
    { key: 'Kiribati', value: 'Kiribati' },
    { key: 'Kosovo', value: 'Kosovo' },
    { key: 'Kuwait', value: 'Kuwait' },
    { key: 'Kyrgyzstan', value: 'Kyrgyzstan' },
    { key: 'Laos', value: 'Laos' },
    { key: 'Latvia', value: 'Latvia' },
    { key: 'Lebanon', value: 'Lebanon' },
    { key: 'Lesotho', value: 'Lesotho' },
    { key: 'Liberia', value: 'Liberia' },
    { key: 'Libya', value: 'Libya' },
    { key: 'Liechtenstein', value: 'Liechtenstein' },
    { key: 'Lithuania', value: 'Lithuania' },
    { key: 'Luxembourg', value: 'Luxembourg' },
    { key: 'Macau SAR China', value: 'Macau SAR China' },
    { key: 'Macedonia', value: 'Macedonia' },
    { key: 'Madagascar', value: 'Madagascar' },
    { key: 'Malawi', value: 'Malawi' },
    { key: 'Malaysia', value: 'Malaysia' },
    { key: 'Maldives', value: 'Maldives' },
    { key: 'Mali', value: 'Mali' },
    { key: 'Malta', value: 'Malta' },
    { key: 'Marshall Islands', value: 'Marshall Islands' },
    { key: 'Martinique', value: 'Martinique' },
    { key: 'Mauritania', value: 'Mauritania' },
    { key: 'Mauritius', value: 'Mauritius' },
    { key: 'Mayotte', value: 'Mayotte' },
    { key: 'Mexico', value: 'Mexico' },
    { key: 'Micronesia', value: 'Micronesia' },
    { key: 'Moldova', value: 'Moldova' },
    { key: 'Monaco', value: 'Monaco' },
    { key: 'Mongolia', value: 'Mongolia' },
    { key: 'Montenegro', value: 'Montenegro' },
    { key: 'Montserrat', value: 'Montserrat' },
    { key: 'Morocco', value: 'Morocco' },
    { key: 'Mozambique', value: 'Mozambique' },
    { key: 'Myanmar (Burma)', value: 'Myanmar (Burma)' },
    { key: 'Namibia', value: 'Namibia' },
    { key: 'Nauru', value: 'Nauru' },
    { key: 'Nepal', value: 'Nepal' },
    { key: 'Netherlands', value: 'Netherlands' },
    { key: 'New Caledonia', value: 'New Caledonia' },
    { key: 'New Zealand', value: 'New Zealand' },
    { key: 'Nicaragua', value: 'Nicaragua' },
    { key: 'Niger', value: 'Niger' },
    { key: 'Nigeria', value: 'Nigeria' },
    { key: 'Niue', value: 'Niue' },
    { key: 'Norfolk Island', value: 'Norfolk Island' },
    { key: 'North Korea', value: 'North Korea' },
    { key: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
    { key: 'Norway', value: 'Norway' },
    { key: 'Oman', value: 'Oman' },
    { key: 'Pakistan', value: 'Pakistan' },
    { key: 'Palau', value: 'Palau' },
    { key: 'Palestinian Territories', value: 'Palestinian Territories' },
    { key: 'Panama', value: 'Panama' },
    { key: 'Papua New Guinea', value: 'Papua New Guinea' },
    { key: 'Paraguay', value: 'Paraguay' },
    { key: 'Peru', value: 'Peru' },
    { key: 'Philippines', value: 'Philippines' },
    { key: 'Pitcairn Islands', value: 'Pitcairn Islands' },
    { key: 'Poland', value: 'Poland' },
    { key: 'Portugal', value: 'Portugal' },
    { key: 'Puerto Rico', value: 'Puerto Rico' },
    { key: 'Qatar', value: 'Qatar' },
    { key: 'Réunion', value: 'Réunion' },
    { key: 'Romania', value: 'Romania' },
    { key: 'Russia', value: 'Russia' },
    { key: 'Rwanda', value: 'Rwanda' },
    { key: 'Samoa', value: 'Samoa' },
    { key: 'San Marino', value: 'San Marino' },
    { key: 'São Tomé & Príncipe', value: 'São Tomé & Príncipe' },
    { key: 'Saudi Arabia', value: 'Saudi Arabia' },
    { key: 'Senegal', value: 'Senegal' },
    { key: 'Serbia', value: 'Serbia' },
    { key: 'Seychelles', value: 'Seychelles' },
    { key: 'Sierra Leone', value: 'Sierra Leone' },
    { key: 'Singapore', value: 'Singapore' },
    { key: 'Sint Maarten', value: 'Sint Maarten' },
    { key: 'Slovakia', value: 'Slovakia' },
    { key: 'Slovenia', value: 'Slovenia' },
    { key: 'Solomon Islands', value: 'Solomon Islands' },
    { key: 'Somalia', value: 'Somalia' },
    { key: 'South Africa', value: 'South Africa' },
    {
      key: 'South Georgia & South Sandwich Islands',
      value: 'South Georgia & South Sandwich Islands',
    },
    { key: 'South Korea', value: 'South Korea' },
    { key: 'South Sudan', value: 'South Sudan' },
    { key: 'Spain', value: 'Spain' },
    { key: 'Sri Lanka', value: 'Sri Lanka' },
    { key: 'St. Barthélemy', value: 'St. Barthélemy' },
    { key: 'St. Helena', value: 'St. Helena' },
    { key: 'St. Kitts & Nevis', value: 'St. Kitts & Nevis' },
    { key: 'St. Lucia', value: 'St. Lucia' },
    { key: 'St. Martin', value: 'St. Martin' },
    { key: 'St. Pierre & Miquelon', value: 'St. Pierre & Miquelon' },
    { key: 'St. Vincent & Grenadines', value: 'St. Vincent & Grenadines' },
    { key: 'Sudan', value: 'Sudan' },
    { key: 'Suriname', value: 'Suriname' },
    { key: 'Svalbard & Jan Mayen', value: 'Svalbard & Jan Mayen' },
    { key: 'Swaziland', value: 'Swaziland' },
    { key: 'Sweden', value: 'Sweden' },
    { key: 'Switzerland', value: 'Switzerland' },
    { key: 'Syria', value: 'Syria' },
    { key: 'Taiwan', value: 'Taiwan' },
    { key: 'Tajikistan', value: 'Tajikistan' },
    { key: 'Tanzania', value: 'Tanzania' },
    { key: 'Thailand', value: 'Thailand' },
    { key: 'Timor-Leste', value: 'Timor-Leste' },
    { key: 'Togo', value: 'Togo' },
    { key: 'Tokelau', value: 'Tokelau' },
    { key: 'Tonga', value: 'Tonga' },
    { key: 'Trinidad & Tobago', value: 'Trinidad & Tobago' },
    { key: 'Tristan da Cunha', value: 'Tristan da Cunha' },
    { key: 'Tunisia', value: 'Tunisia' },
    { key: 'Turkey', value: 'Turkey' },
    { key: 'Turkmenistan', value: 'Turkmenistan' },
    { key: 'Turks & Caicos Islands', value: 'Turks & Caicos Islands' },
    { key: 'Tuvalu', value: 'Tuvalu' },
    { key: 'U.S. Outlying Islands', value: 'U.S. Outlying Islands' },
    { key: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands' },
    { key: 'Uganda', value: 'Uganda' },
    { key: 'Ukraine', value: 'Ukraine' },
    { key: 'United Arab Emirates', value: 'United Arab Emirates' },
    { key: 'United Kingdom', value: 'United Kingdom' },
    { key: 'United States', value: 'United States' },
    { key: 'Uruguay', value: 'Uruguay' },
    { key: 'Uzbekistan', value: 'Uzbekistan' },
    { key: 'Vanuatu', value: 'Vanuatu' },
    { key: 'Vatican City', value: 'Vatican City' },
    { key: 'Venezuela', value: 'Venezuela' },
    { key: 'Vietnam', value: 'Vietnam' },
    { key: 'Wallis & Futuna', value: 'Wallis & Futuna' },
    { key: 'Western Sahara', value: 'Western Sahara' },
    { key: 'Yemen', value: 'Yemen' },
    { key: 'Zambia', value: 'Zambia' },
    { key: 'Zimbabwe', value: 'Zimbabwe' },
  ],

  stateData: [
    { key: 'Alabama', value: 'Alabama' },
    { key: 'Alaska', value: 'Alaska' },
    { key: 'Arizona', value: 'Arizona' },
    { key: 'Arkansas', value: 'Arkansas' },
    { key: 'California', value: 'California' },
    { key: 'Colorado', value: 'Colorado' },
    { key: 'Connecticut', value: 'Connecticut' },
    { key: 'Delaware', value: 'Delaware' },
    { key: 'Florida', value: 'Florida' },
    { key: 'Georgia', value: 'Georgia' },
    { key: 'Hawaii', value: 'Hawaii' },
    { key: 'Idaho', value: 'Idaho' },
    { key: 'Illinois', value: 'Illinois' },
    { key: 'Indiana', value: 'Indiana' },
    { key: 'Iowa', value: 'Iowa' },
    { key: 'Kansas', value: 'Kansas' },
    { key: 'Kentucky', value: 'Kentucky' },
    { key: 'Louisiana', value: 'Louisiana' },
    { key: 'Maine', value: 'Maine' },
    { key: 'Maryland', value: 'Maryland' },
    { key: 'Massachusetts', value: 'Massachusetts' },
    { key: 'Michigan', value: 'Michigan' },
    { key: 'Minnesota', value: 'Minnesota' },
    { key: 'Mississippi', value: 'Mississippi' },
    { key: 'Missouri', value: 'Missouri' },
    { key: 'Montana', value: 'Montana' },
    { key: 'Nebraska', value: 'Nebraska' },
    { key: 'Nevada', value: 'Nevada' },
    { key: 'Hampshire', value: 'Hampshire' },
    { key: 'Jersey', value: 'Jersey' },
    { key: 'Mexico', value: 'Mexico' },
    { key: 'York', value: 'York' },
    { key: 'Ohio', value: 'Ohio' },
    { key: 'Oklahoma', value: 'Oklahoma' },
    { key: 'Oregon', value: 'Oregon' },
    { key: 'Pennsylvania', value: 'Pennsylvania' },
    { key: 'Island', value: 'Island' },
    { key: 'Carolina', value: 'Carolina' },
    { key: 'Dakota', value: 'Dakota' },
    { key: 'Tennessee', value: 'Tennessee' },
    { key: 'Texas', value: 'Texas' },
    { key: 'Utah', value: 'Utah' },
    { key: 'Vermont', value: 'Vermont' },
    { key: 'Virginia', value: 'Virginia' },
    { key: 'Washington', value: 'Washington' },
    { key: 'Wisconsin', value: 'Wisconsin' },
  ],
  coachSpecialityList: [
    'Nonprofits',
    'Students',
    'Millennial founders',
    'Female early stage founders',
    'Female high growth founders',
    'Elite client',
    'Power founder',
  ],

  ethnicities: [
    'African, African-American',
    'Chinese, Chinese-American',
    'Caucasian, European, European-American',
    'Filipino, Filipino-American',
    'Japanese, Japanese-American',
    'Korean, Korean-American',
    'Southeast Asian (e.g., Vietnam, Cambodia, etc.)',
    'Other Asian',
    'Mexican, Mexican-American, Chicano',
    'Other Latino',
    'Middle Eastern (e.g., Arab, Persian)',
    'Native American, First Nation',
  ],
  languages: [
    'Afrikaans',
    'Albanian',
    'Arabic',
    'Armenian',
    'Basque',
    'Bengali',
    'Bulgarian',
    'Catalan',
    'Cambodian',
    'Chinese (Mandarin)',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Fiji',
    'Finnish',
    'French',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Korean',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Romanian',
    'Russian',
    'Samoan',
    'Serbian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swahili',
    'Swedish ',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Tonga',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhos',
  ],
  genders: ['Male', 'Female', 'Non-Binary', 'Other'],
  ethnicity: {
    'African, African-American': 'African',
    'Chinese, Chinese-American': 'Asian',
    'Caucasian, European, European-American': 'Caucasian',
    'Filipino, Filipino-American': 'Asian',
    'Japanese, Japanese-American': 'Asian',
    'Korean, Korean-American': 'Asian',
    'Southeast Asian (e.g., Vietnam, Cambodia, etc.)': 'Asian',
    'Other Asian': 'Asian',
    'Mexican, Mexican-American, Chicano': 'Latino',
    'Other Latino': 'Latino',
    'Middle Eastern (e.g., Arab, Persian)': 'Middle Eastern',
    'Native American, First Nation': 'Native American',
  },
  education: [
    'No schooling completed',
    'Nursery school to 8th grade',
    'Some high school',
    'High school graduate or equivalent',
    'Some college',
    'Trade, technical or vocational',
    "Associate's degree",
    "Bachelor's degree",
    "Master's degree",
    'Professional degree',
    'Doctorate degree',
  ],
  growthList: [
    'Leadership Development (e.g. Strategy, Executive Presence)',
    'Well-Being (e.g. Stress, Burnout, Mental Health)',
    'Team Culture (e.g. Cohesion, Trust, Employee Experience)',
    'Purpose & Life Alignment (e.g. Career Change, Fulfillment)',
    'Emotional Intelligence & Communication (e.g. Soft Skills)',
    'Practical Manager Skills (Prioritization, Focus, Running 1:1s)',
    'Developing Team Members (e.g. Coaching, Career Paths)',
    'Interpersonal Relationships (e.g. Colleagues, Co-Founders)',
    'Succession Planning (e.g. Role Transition, Leadership Competencies)',
    'Organizational Development (e.g. OKRs, Operational Mapping)',
  ],
  userStatus: ['I am in career transition', 'I am currenly in operating role'],
  roleListForEnterpriceUser: [
    {
      label: 'Employee (Individual Contributor/Team Member, Student)',
      value: 'ic',
    },
    {
      label: 'First-Line Manager (Direct Manager of Individual Contributors)',
      value: 'manager',
    },
    {
      label:
        'Middle Manager (Department Heads, Manager of First-Line Managers)',
      value: 'leader',
    },
    {
      label:
        'Top-Level Manager (Business Unit Leads, Executives/C-Suite, Partners)',
      value: 'executive',
    },
    { label: 'CEO / FOUNDER / Board of Directors', value: 'ceo' },
  ],
  noRoleList: [{ label: 'No Titles or Hierarchy', value: 'none' }],
  companyType: [
    'Large Enterprise/Corporation',
    'Small-to-Medium Sized Enterprise (SME)',
    'Small Business',
    'Non-Profit/NGO',
    'High Growth Startup',
    'Family Office',
    'Venture Capital/PE Fund',
    'Hedge Fund',
  ],
  companyPeople: [
    'Self-employed',
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-1,000 employees',
    '1,001-5,000 employees',
    '5,001-10,000 employees',
    '10,001+ employees',
  ],
  organizationPeople: [
    'Self-employed',
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-1000 employees',
    '1001-5000 employees',
    '5001-10,000 employees',
    '10,001+ employees',
  ],
  investmentStage: [
    'Not Applicable',
    "Ideation Stage - we're getting our vision in order.",
    'Self Funded - our team has financed most of it.',
    'Friends & Family - most of our funding has come from personal contacts.',
    'Crowdfunding - the masses have spoken.',
    'Angel Investment - an individual or individuals have kickstarted our company.',
    "Accelerator - we're part of an organization providing a little capital, a lot of mentoring, and some office space.",
    "Seed Investment - we've secured between $500k -$1 million from investors.",
    "Series A - we've secured between $1-6 million from investors.",
    "Series B - we've secured between $6.1-11 million from investors.",
    "Series C - we've secured between $11.1-100 million from investors.",
    'Series D & Beyond - we want to see how far the rabbit hole goes.',
    'Publicly traded company',
  ],
  sessionDuration: [
    { key: '30', value: '30 minutes' },
    { key: '45', value: '45 minutes' },
    { key: '60', value: '60 minutes' },
    { key: '75', value: '75 minutes' },
    { key: '90', value: '90 minutes' },
    { key: '120', value: '120 minutes' },
    { key: '150', value: '150 minutes' },
    { key: '180', value: '180 minutes' },
  ],
  countries: countriesData,
  promoStatus: ['Enabled', 'Not Enabled'],
  platform: ['Abroad', 'Good Startups', 'Good Culture'],

  filterByGender: [
    { key: 'M', value: 'Male' },
    { key: 'F', value: 'Female' },
    { key: 'NB', value: 'Non-Binary' },
    { key: 'O', value: 'Other' },
  ],
  filterByCoachSpecialities: [
    { value: 'NONPROFIT', label: 'Nonprofits' },
    { value: 'STUDENTS', label: 'Students' },
    { value: 'MILLENNIAL', label: 'Millennial founders' },
    { value: 'FEMALE_EARLY_STAGE', label: 'Female early stage founders' },
    { value: 'FEMALE_HIGH_GROWTH', label: 'Female high growth founders' },
    { value: 'ELITE', label: 'Elite client' },
    { value: 'POWER_FOUNDER', label: 'Power founder' },
  ],
  // filterByCoachRoleRelateTo: [
  //   { value: 'C_SUITE_FOUNDER', label: 'C-Suite/Founder/Managing Partner' },
  //   { value: 'SENIOR_VICE_PRESIDENT', label: 'Senior Vice President/Partner' },
  //   { value: 'VICE_PRESIDENT', label: 'Vice President/Principal' },
  //   { value: 'MANAGING_PARTNER_DIRECTOR', label: 'Managing Partner/Director' },
  //   { value: 'PARTNER', label: 'Partner' },
  //   { value: 'SENIOR_DIRECTOR', label: 'Senior Director' },
  //   { value: 'DIRECTOR', label: 'Director' },
  //   { value: 'DEPARTMENT_HEAD_MANAGER', label: 'Department Head/Manager' },
  //   { value: 'TEAM_MEMBER_ASSOCIATE', label: 'Team Member/Associate' },
  //   { value: 'PRINCIPAL', label: 'Principal' },
  //   { value: 'STUDENT', label: 'Student' },
  //   {
  //     value: 'NO_TITLES_OR_HIERARCHY',
  //     label: 'Our organization has No Titles or Hierarchy',
  //   },
  // ],
  orgFilterType: [
    { key: 1, value: 'All' },
    { key: 2, value: 'With SOW' },
    { key: 3, value: 'Invite Only' },
    { key: 4, value: 'SOW or Invite Only' },
  ],
  orgFilterDefaultType: {
    ALL: 1,
    ORG_WITH_SOW: 2,
    ORG_WITH_INVITE_ONLY: 3,
    SOW_OR_INVITE_ONLY: 4,
  },
  noplanSetOption: { label: 'No Plan Set', value: 'null' },
  orgFilterstatus: [
    { key: 1, value: 'Any' },
    { key: 2, value: 'Active' },
    { key: 3, value: 'Inactive' },
  ],
  statusFilterOptions: [
    { key: 1, value: 'Any' },
    { key: 2, value: 'Yes' },
    { key: 3, value: 'No' },
  ],
  statusFilterOptionsType: {
    ANY: 1,
    YES: 2,
    NO: 3,
  },
  orgFilterStatusDefaultType: {
    ALL: 1,
    ORG_ACTIVE: 2,
    ORG_INACTIVE: 3,
  },
  filterBycoachType: [
    { key: 1, value: 'All Coaches' },
    { key: 2, value: 'Staff Coaches' },
  ],
  filterBycoachTypeDefault: { ALL_COACHES: 1, STAFF_COACHES: 2 },
  coachType: [
    { key: 1, value: 'Contract Based Coach' },
    { key: 2, value: 'Staff Coach' },
  ],
  defaultValueForCoachType: {
    CONTRACT_BASED_COACH: 1,
    STAFF_COACH: 2,
  },
  allGroupType: {
    COMPANY: 1,
    NETWORK: 2,
    PROMO_CODE: 3,
    FREE_SELECT: 4,
    COMPANY_WITH_SOW: 5,
    NETWORK_WITH_SOW: 6,
  },
  removeCoachFromUsersTypes: {
    SINGLE_B2B_SOW: 'SINGLE_B2B_SOW',
    ALL_B2B_SOWS: 'ALL_B2B_SOWS',
    ALL_TYPES_OF_SOWS: 'ALL_TYPES_OF_SOWS',
    ALL_INDIVIDUAL_SOWS: 'ALL_INDIVIDUAL_SOWS',
  },
  workStatus: {
    IN_OPERATING: 'operating',
    IN_TRANSITION: 'transition',
  },
}

export default labels
