import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
// import { Col, Nav, Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import { showInUrls } from '../../helper'
import {
  AngleRightIconForClient,
  AngleRightIconForAdmin
} from '../assetsComponents'

const SidebarDropdown = ({
  links = [],
  name,
  path,
  pathname,
  NavLink,
  matchPath = () => {},
  isRenderOnClientSide = true,
  removeDefaultActive = false,
  linkClasses = '',
  listWrapperClasses = '',
  icon = null
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [isDropDownItemActive, setIsDropDownItemActive] = useState(false)
  const [matchWord, setMatchWord] = useState(null)
  const discardDefaultMatchPath = ['/coach/groups']
  const discardDefaultMatchWord = ['sow-groups', ':sowGroupId']

  const hasMatchAnyDropdownItem = useCallback(() => {
    if (
      discardDefaultMatchPath.includes(path) ||
      discardDefaultMatchWord.includes(path.split('/').pop())
    ) {
      return false
    }
    const hasMatchPath = links?.map((link) => link.to === path).includes(true)
    const hasMatchWord = links
      ?.map((link) => link?.urlWords)
      .flat(2)
      .some((word) => pathname.includes(word))

    const matchWordStr = links
      .map((link) => link?.urlWords)
      .flat()
      .filter((word) => pathname?.includes(word))
      .toString()
    setMatchWord(matchWordStr)
    return hasMatchPath || hasMatchWord
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links, path, name, pathname])

  useEffect(() => {
    const isMatch = hasMatchAnyDropdownItem()
    if (isMatch) {
      setIsDropDownItemActive(true)
      setIsDropDownOpen(true)
    } else {
      setIsDropDownItemActive(false)
      setIsDropDownOpen(false)
    }
  }, [hasMatchAnyDropdownItem, pathname])

  return (
    <li className='nav-item'>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        // eslint-disable-next-line no-script-url
        href='javascript:void(0)'
        className={classnames('nav-link sidebar-link', {
          active: isDropDownItemActive,
          'text-decoration-none s6 line-height-normal': isRenderOnClientSide,
          'pb-2': isDropDownOpen && isRenderOnClientSide
        })}
        id='sidebar-navlink'
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
      >
        <Row
          className={classnames({
            'align-items-center': !isRenderOnClientSide
          })}
        >
          <Col
            className={classnames('d-flex align-items-center', {
              'font-14 leading-6': !isRenderOnClientSide,
              'text-white text-capitalize s6':
                isRenderOnClientSide && !isDropDownItemActive,
              'text-saffron-300 text-capitalize s6':
                isRenderOnClientSide && isDropDownItemActive
            })}
          >
            {icon}
            {name}
            {isRenderOnClientSide ? (
              <AngleRightIconForClient
                className={classnames('ml-12px', {
                  active: isDropDownItemActive
                })}
                isDropDownOpen={isDropDownOpen}
              />
            ) : (
              <AngleRightIconForAdmin
                className='ml-3'
                isDropDownOpen={isDropDownOpen}
              />
            )}
          </Col>
        </Row>
      </a>
      <ul
        className={classnames(
          'nav',
          {
            'd-none': !isDropDownOpen
          },
          {
            'd-flex flex-column show-dropdown-items':
              isDropDownOpen || (isDropDownItemActive && isDropDownOpen)
          },
          listWrapperClasses
        )}
      >
        {links?.map((link, index) => {
          if (link?.onlyRC && !showInUrls.includes(window.location.origin)) {
            return null
          }
          return (
            <li className={classnames('nav-item')} key={link.name}>
              <Nav.Link
                to={link.to}
                className={classnames('nav-link', {
                  active: removeDefaultActive
                    ? matchPath(pathname, {
                        path: link?.pageUrls,
                        exact: true,
                        strict: false
                      })
                    : pathname === link.to ||
                      link?.urlWords?.includes(matchWord),
                  'clickable-nav-link mb-2 py-0': isRenderOnClientSide,
                  'mb-3': isRenderOnClientSide && index + 1 === links?.length
                })}
                as={NavLink}
                {...(removeDefaultActive && { isActive: () => false })}
              >
                <p className={classnames('mb-0', linkClasses)}>{link.name}</p>
              </Nav.Link>
            </li>
          )
        })}
      </ul>
    </li>
  )
}

export default SidebarDropdown
