import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import { Container } from 'react-bootstrap'
import { ABSpinner, useErrorService } from '@abroad/components'
import {
  PageHeader,
  SessionInsightTable,
  ExportSessionReport,
  FilterForm,
} from '../components'
import API from '../utils/API'
import { SessionInsightsContext } from '../utils/contexts'
import { getNameArrayFromCompanies } from '../utils/utility'

const formInitialValues = {
  filterByCoach: '',
  filterByDate: null,
  filterByClient: '',
  filterByTeam: '',
  filterByCompany: '',
}

const SessionInsights = React.memo(() => {
  const [isLoading, setIsLoading] = useState(true)
  const [coachList, setCoachList] = useState([])
  const [isSortByAsc, setIsSortByAsc] = useState(true)
  const [companies, setCompanies] = useState({
    isLoading: true,
    companies: [],
  })
  const formRef = useRef()
  const Error = useErrorService()

  useEffect(() => {
    const getCoachList = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.AdminAPI.userList.getCoaches()
        setCoachList(
          data.map((coach) => ({ value: coach.id, label: coach.fullName })),
        )
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getCoachList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        let { data } = await API.AdminAPI.companyList.getCompanyData()
        data = getNameArrayFromCompanies(data)
        if (data) {
          setCompanies({
            isLoading: false,
            companies: data,
          })
        }
      } catch (e) {
        setCompanies({
          isLoading: false,
          companies: [],
        })
        Error.showError(e)
      }
    }
    getCompanyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values, { resetForm }) => {
    resetForm()
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{'Session Insights | Abroad'}</title>
        <meta name='title' content={'Session Insights | Abroad'}></meta>
        <meta property='og:title' content={'Session Insights | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Session Insights | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/session-insights`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/session-insights`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/session-insights`}></meta>
      </Helmet>
      <main>
        <SessionInsightsContext.Provider
          value={{ isSortByAsc, setIsSortByAsc }}>
          <section>
            <Formik
              innerRef={formRef}
              displayName='profile-form'
              validateOnBlur={false}
              initialValues={formInitialValues}
              onSubmit={onSubmit}
              enableReinitialize={false}>
              <>
                <FilterForm
                  coachList={coachList}
                  companies={companies}
                  renderHeder={(insightData, startDate, endDate) => (
                    <PageHeader
                      titleText='Session Insights'
                      wrapperClassName='d-flex justify-content-between'
                      titleClasses='mb-0 sentence-case-text'
                      extraFn={() => {
                        return (
                          <>
                            <ExportSessionReport
                              hasSessionData={
                                insightData.length > 0 ? true : false
                              }
                              startDate={startDate}
                              endDate={endDate}
                            />
                          </>
                        )
                      }}
                      colClasses='px-0'
                    />
                  )}
                  render={(insightData, isLoading, loader, isInitiallyLoad) => (
                    <Container
                      fluid
                      className='custom-offset-lg-1 custom-col-lg-12 px-0'>
                      <SessionInsightTable
                        insightData={insightData}
                        isLoading={isLoading}
                        ref={loader}
                        isInitiallyLoad={isInitiallyLoad}
                      />
                    </Container>
                  )}
                />
              </>
            </Formik>
          </section>
        </SessionInsightsContext.Provider>
      </main>
    </>
  )
})

export default SessionInsights
