import React, { useContext } from 'react'
import UploadCSVModal from './UploadCSVModal'
import AddIndividualUserModal from './AddIndividualUserModal'
import NoUsersOrSOWsText from './NoUsersOrSOWsText'
import { OrgEcoIdsContext } from '../../utils/contexts'

const UploadCSV = ({ orgEcoId }) => {
  const { setIsCsvUploaded, companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)

  return (
    <div>
      <NoUsersOrSOWsText />
      <div className='d-flex'>
        <UploadCSVModal
          orgEcoId={orgEcoId}
          buttonClasses='mr-4'
          uploadCSVHandler={() => {
            setIsCsvUploaded({ showRosterUI: false, showBtn: true })
            if (!companyAndEcoInfo?.usersCount) {
              setCompanyAndEcoInfo((prev) => ({
                ...prev,
                isCsvUploaded: true,
                usersCount: 1, // add static user count for UI
              }))
            }
          }}
        />
        <AddIndividualUserModal
          orgEcoId={orgEcoId}
          uploadCSVHandler={() => {
            setIsCsvUploaded({ showRosterUI: false, showBtn: true })
            if (!companyAndEcoInfo?.usersCount) {
              setCompanyAndEcoInfo((prev) => ({
                ...prev,
                usersCount: 1, // add static user count for UI
              }))
            }
          }}
        />
      </div>
    </div>
  )
}

export default UploadCSV
