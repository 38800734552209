import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { InputField } from '../form'
import Labels from '../../constants/labels'
import labels from '../../constants/labels'

const PriorityField = ({ disabledOptions, ...rest }) => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    const optionValue = values[rest?.name]
    if (!labels.growthList.includes(optionValue)) {
      setFieldValue(rest?.name, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InputField
      as='select'
      type='text'
      size='lg'
      sm='10'
      placeholder='Select One'
      defaultValue=''
      {...rest}>
      <option disabled value=''>
        Select One
      </option>
      {Labels.growthList.map((growthType) => (
        <option
          disabled={disabledOptions.includes(growthType)}
          key={growthType}
          value={growthType}>
          {growthType}
        </option>
      ))}
    </InputField>
  )
}

export default PriorityField
