import React from 'react'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

const ClientStatusBtn = ({ value, label, className = '' }) => {
  return (
    <Button
      className={classNames(
        'btn-inactive',
        {
          'green-btn': value,
          'normal-btn': !value,
        },
        className,
      )}
      variant='none'>
      {label}
    </Button>
  )
}

export default ClientStatusBtn
