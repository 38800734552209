import React, { useState, useEffect } from 'react'
// import { MediaControlBarRoot, MediaControlBar } from '../media'
import { LayoutContext } from '../../utils/contexts'

const MediaControlWrapper = ({ children }) => {
  const isLGScreen = window.innerWidth > 992 ? true : false
  const [showSidebar, setShowSidebar] = useState(isLGScreen)
  const [showPlayBar, setShowPlayBar] = useState(false)
  const [minimizePlayBar, setMinimizePlayBar] = useState(false)
  const [mediaObject, setMediaObject] = useState(null)

  useEffect(() => {
    if (!isLGScreen) {
      setShowSidebar(false)
    }
  }, [isLGScreen])

  return (
    <LayoutContext.Provider
      value={{
        setShowSidebar: (isSidebarVisible) => setShowSidebar(isSidebarVisible),
        showSidebar: showSidebar,
        showPlayBar: showPlayBar,
        setShowPlayBar: (isPlayBarVisible) => setShowPlayBar(isPlayBarVisible),
        minimizePlayBar: minimizePlayBar,
        setMinimizePlayBar: (isMinimize) => setMinimizePlayBar(isMinimize),
        mediaObject: mediaObject,
        setMediaObject: (obj) => setMediaObject(obj),
      }}>
      <div>
        {children}
        {/* <MediaControlBarRoot>
          <MediaControlBar />
        </MediaControlBarRoot> */}
      </div>
    </LayoutContext.Provider>
  )
}

export default MediaControlWrapper
