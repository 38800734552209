import moment from 'moment'

export const formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY')
}

export const formatDateInPlan = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const convertSessionDate = (date, format = 'MM/DD/YYYY') => {
  return date ? moment(date).utcOffset('+0000').format(format) : null
}

export const getCurrentDate = () => moment()

export const getIntentionDate = (date) => moment(date).format('MMMM Do YYYY')

export const getArchivedIntentionDate = (date) =>
  moment(date).format('Do MMMM YYYY')
