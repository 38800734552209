export const initialState = {
  intentions: [],
  archivedIntentions: [],
  isLoading: true,
  isUpgradeRequired: false,
}

const intentionReducer = (state, action) => {
  switch (action.type) {
    case 'INTENTION_FETCH':
      return { ...state, intentions: [], isLoading: true, error: null }
    case 'INTENTION_SUCCESS':
      return {
        ...state,
        intentions: action.payload,
        isLoading: false,
        error: null,
      }
    case 'INTENTION_FAIL':
      return {
        ...state,
        intentions: [],
        isLoading: false,
        error: action.payload,
      }
    case 'UPDATE_INTENTIONS':
      return {
        ...state,
        intentions: action.payload,
        isLoading: false,
        error: null,
      }
    case 'UPDATE_ARCHIVED_INTENTION':
      return { ...state, archivedIntentions: action.payload, error: null }
    case 'POST_SESSION_SUCCESS':
      return { ...state, error: null }
    case 'POST_SESSION_FAIL':
      return { ...state, error: action.payload }
    case 'INTENTION_UPGRADE':
      return {
        ...state,
        isUpgradeRequired: action.payload,
      }
    default:
      throw new Error()
  }
}

export default intentionReducer
