import React from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ABSpinner } from '@abroad/components'
import { StaffCoachIcon } from '../assetsComponents'
import { formatDate } from '../../utils/date'

const CoachList = ({ isLoading, coaches }) => {
  if (isLoading) {
    return <ABSpinner />
  }

  const renderStaffCoachIcon = (isStaffCoach = false) => {
    if (!isStaffCoach) return null
    return <StaffCoachIcon classes='staff-coach-table-indicator mb-1' />
  }

  return (
    <Container fluid className='custom-offset-lg-1 custom-col-lg-12 px-0'>
      <Row>
        <Col className='coaches-list'>
          <>
            <p className='text-center'>
              Total Remaining Capacity for All coaches:{' '}
              {coaches.totalCapacity - coaches.occupiedCapacity}(
              {coaches.totalCapacity})
            </p>
            <Table hover responsive className='custom-table'>
              <thead>
                <tr>
                  <th width='2%'>#</th>
                  <th width='20%'>Full Name</th>
                  <th width='20%'>Sign Up Date</th>
                  <th width='18%'>Assigned Client</th>
                  <th width='20%'>Remaining Capacity</th>
                  <th width='20%'>Profile Status</th>
                </tr>
              </thead>
              <tbody className='font-16'>
                {coaches?.coaches?.length === 0 ? (
                  <tr>
                    <td className='text-center' colSpan='6'>
                      No coaches found for given criteria
                    </td>
                  </tr>
                ) : (
                  coaches?.coaches?.map((coach, index) => (
                    <tr key={index}>
                      <td width='2%'>{index + 1}</td>
                      <td width='20%'>
                        <div className='d-flex flex-column'>
                          <Link
                            to={`/admin/coaches/${coach?._id}`}
                            className='text-dark-600'>
                            <Row className='d-flex'>
                              <Col>
                                <span className='word-breakall'>
                                  {coach?.fullName}
                                </span>
                                {renderStaffCoachIcon(coach?.isStaffCoach)}
                              </Col>
                            </Row>
                          </Link>
                          <Link
                            to={`/admin/coaches/${coach?._id}`}
                            className='text-dark-600'>
                            {coach?.email}
                          </Link>
                        </div>
                      </td>
                      <td width='20%'>{formatDate(coach?.createdAt)}</td>
                      <td width='18%'>{coach?.occupiedCapacity}</td>
                      {Math.abs(coach?.capacity - coach?.totalClients) ? (
                        <td width='20%'>
                          {coach?.occupiedCapacity}({coach?.capacity})
                        </td>
                      ) : (
                        <td width='20%'>Not Specified</td>
                      )}
                      <td width='20%'>
                        {coach?.profileCompleted ? (
                          <svg
                            aria-hidden='true'
                            focusable='false'
                            data-prefix='fas'
                            data-icon='check'
                            className='svg-inline--fa fa-check fa-w-16 pointer-cursor'
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                            width='20'
                            height='20'>
                            <path
                              fill='#008000'
                              d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path>
                          </svg>
                        ) : (
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={`tooltip-coach-incompletedFields`}
                                className='font-open-sans'>
                                {coach?.incompletedFields.join(' , ')}
                              </Tooltip>
                            }>
                            <svg
                              aria-hidden='true'
                              focusable='false'
                              data-prefix='fas'
                              data-icon='exclamation'
                              className='svg-inline--fa fa-exclamation fa-w-6 pointer-cursor uncompleted-icon'
                              role='img'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 192 512'
                              width='20'
                              height='20'>
                              <path
                                fill='#FFA500'
                                d='M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z'></path>
                            </svg>
                          </OverlayTrigger>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {/* <p className='pt-4 mb-1'>
              * Only <b>EVOLUTION</b> clients are considered in calculating
              capacity.
            </p> */}
            <p className='pt-4 mb-1'>
              * Not Specified means coach has not filled their profile yet.
            </p>
          </>
        </Col>
      </Row>
    </Container>
  )
}

export default CoachList
