import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import CustomCoachingRateModal from './CustomCoachingRateModal'
import { DeleteConfirmationPrompt } from '../common'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const initialStateOfModal = {
  show: false,
  id: null,
}

const GroupCoachActions = ({ isSamePL, coachInfo, updatePricing }) => {
  const [showModal, setShowModal] = useState(initialStateOfModal)
  const [showCoachingRateModal, setShowCoachingRateModal] = useState(false)
  const [coachId, setCoachId] = useState(null)
  const { setIsLoadNewData, selectedGroupisArchived } =
    useContext(OrgEcoIdsContext)
  const [coachRateData, setCoachRateData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const { sowGroupId } = useParams()
  const Error = useErrorService()

  useEffect(() => {
    if (coachRateData) {
      updateCoachCustomPricing()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachRateData])

  const updateCoachCustomPricing = async () => {
    setIsLoading(true)
    try {
      const { status } =
        await API.AdminAPI.companyList.updateCoachCustomPricing(
          sowGroupId,
          coachId,
          {
            pricing: coachRateData[0]?.pricing,
          },
        )
      if (status) {
        updatePricing(coachId, coachRateData[0]?.pricing)
        setIsLoading(false)
        setShowModal(initialStateOfModal)
        setCoachId(null)
        setCoachRateData(null)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const removeCoachesFromGroup = async () => {
    if (showModal.id === null) return
    setIsDeleting(true)
    try {
      const { status } = await API.AdminAPI.companyList.removeSOWGroupCoaches(
        sowGroupId,
        {
          coachIds: showModal.id,
        },
      )
      if (status) {
        setIsDeleting(false)
        setShowModal(initialStateOfModal)
        setIsLoadNewData(true)
      }
    } catch (e) {
      setIsDeleting(false)
      Error.showError(e)
    }
  }

  return (
    <React.Fragment>
      <DeleteConfirmationPrompt
        showModal={showModal?.show}
        setShowModal={setShowModal}
        isDeleteLoading={isDeleting}
        handleDelete={removeCoachesFromGroup}
        message='Remove coach from this group?'
      />
      <CustomCoachingRateModal
        showModal={showCoachingRateModal}
        setShowModal={setShowCoachingRateModal}
        setCoachRateData={setCoachRateData}
        coachId={coachId}
      />
      {!isSamePL && (
        <span
          className={classNames('s6 text-saffron-700 edit-coach font-bold', {
            'cursor-disabled opacity-point-5':
              coachInfo?.isStaffCoach || isLoading || selectedGroupisArchived,
            'cursor-pointer':
              !coachInfo?.isStaffCoach &&
              !isLoading &&
              !selectedGroupisArchived,
          })}
          onClick={() => {
            if (coachInfo?.isStaffCoach || selectedGroupisArchived) return
            setCoachId(coachInfo?._id)
            setShowCoachingRateModal(true)
          }}>
          {isLoading ? 'Editing...' : 'Edit'}
        </span>
      )}
      <span
        className={classNames('s6 text-red-800 font-bold', {
          'cursor-disabled opacity-point-5': selectedGroupisArchived,
          'cursor-pointer': !selectedGroupisArchived,
        })}
        onClick={() => {
          if (selectedGroupisArchived) return
          setShowModal({
            show: true,
            id: [coachInfo?._id],
          })
        }}>
        {isDeleting ? 'Deleting...' : 'Delete'}
      </span>
    </React.Fragment>
  )
}

export default GroupCoachActions
