import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ABSpinner, useErrorService, useUserService } from '@abroad/components'
import BeginGrowthPlan from './BeginGrowthPlan'
import ViewGrowthPlan from './ViewGrowthPlan'
import API from '../../utils/API'

const initialState = {
  isLoading: true,
  data: null,
}

const UserGrowthPlan = () => {
  const [growthSessionData, setGrowthSessionData] = useState(initialState)
  const [growthSOWData, setGrowthSOWData] = useState(initialState)
  const [isLoading, setIsLoading] = useState(true)
  const [isSurveyTaken, setIsSurveyTaken] = useState(false)
  const { clientId } = useParams()
  const { user } = useUserService()
  const Error = useErrorService()

  const handleVariableStateChange = () => {
    setGrowthSOWData({
      isLoading: false,
      data: null,
    })
    setGrowthSessionData({
      isLoading: false,
      data: null,
    })
  }

  useEffect(() => {
    const getGrowthPlanDetails = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          clientId,
          {
            fields: ['surveyTaken'],
          },
        )
        if (data) {
          setIsSurveyTaken(data?.surveyTaken)
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getGrowthPlanDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // get growth session data
    const getGrowthSessionList = async () => {
      setGrowthSessionData(initialState)
      try {
        const { data } = await API.AdminAPI.session.getGrowthSessionList(
          clientId,
        )
        if (data) {
          setGrowthSessionData({
            isLoading: false,
            data,
          })
        } else {
          setGrowthSessionData({
            isLoading: false,
            data: null,
          })
        }
      } catch (e) {
        setGrowthSessionData({
          isLoading: false,
          data: null,
        })
        Error.showError(e)
      }
    }
    // get growth sow data
    const getGrowthSOW = async () => {
      setGrowthSOWData(initialState)
      try {
        const { data } = await API.AdminAPI.coachPlan.getGrowthSOW(clientId)
        if (data) {
          setGrowthSOWData({
            isLoading: false,
            data,
          })
          getGrowthSessionList()
        } else {
          handleVariableStateChange()
        }
      } catch (e) {
        setGrowthSOWData({
          isLoading: false,
          data: null,
        })
        Error.showError(e)
      }
    }
    if (isSurveyTaken) {
      getGrowthSOW()
    } else {
      handleVariableStateChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyTaken])

  if (growthSOWData?.isLoading || growthSessionData?.isLoading || isLoading) {
    return <ABSpinner />
  }

  if (!isSurveyTaken) {
    return (
      <p className='s5 text-center mt-4'>
        Looks like user has not yet completed any assessments.
      </p>
    )
  }

  if (
    !growthSOWData?.data ||
    (!user?.isAdmin &&
      user?._id !== growthSOWData?.data?.coachId?._id &&
      !growthSessionData?.data) ||
    (user?.isAdmin &&
      !growthSessionData?.data &&
      user?._id !== growthSOWData?.data?.coachId?._id)
  ) {
    return (
      <p className='s5 text-center mt-4'>There is no growth plan taken yet.</p>
    )
  }

  return (
    <>
      {growthSessionData?.data && (
        <ViewGrowthPlan
          growthSessionId={growthSessionData?.data?._id}
          isEditable={user?._id === growthSessionData?.data?.coachId?._id}
        />
      )}
      {!growthSessionData?.data &&
        user?._id === growthSOWData?.data?.coachId?._id && <BeginGrowthPlan />}
    </>
  )
}

export default UserGrowthPlan
