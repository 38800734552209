import { useEffect, useState } from 'react'
import { useErrorService } from '@abroad/components'
import API from '../../../utils/API'

const useUserFirstName = (id) => {
  const [userFirstName, setUserFirstName] = useState('')
  const Error = useErrorService()

  useEffect(() => {
    const getUserFirstName = async () => {
      try {
        const { data } = await API.AdminAPI.coachClient.getClientSpecificData(
          id,
          {
            fields: ['fname'],
          },
        )
        if (data) {
          setUserFirstName(data?.fname)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (id) getUserFirstName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!id) return ''

  return userFirstName ? `${userFirstName}'s` : ''
}

export default useUserFirstName
