import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { CoachProfileForm } from '../components/coachProfile'

const CoachProfile = () => {
  const { coachId } = useParams()
  return (
    <>
      <Helmet>
        <title>{`Profile | Abroad`}</title>
        <meta name='title' content={`Profile | Abroad`}></meta>
        <meta property='og:title' content={`Profile | Abroad`}></meta>
        <meta property='twitter:title' content={`Profile | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/coach/${coachId}/profile`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/coach/${coachId}/profile`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/coach/${coachId}/profile`}></meta>
      </Helmet>
      <main className='pb-5 mt-5'>
        <CoachProfileForm />
      </main>
    </>
  )
}

export default CoachProfile
