import React from 'react'
import { Helmet } from 'react-helmet'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { SelfAwareness, TeamCohesion, VisionAndImpact } from '../components'

const SiteCopy = () => {
  let { path } = useRouteMatch()
  return (
    <>
      <Helmet>
        <title>{'Site Copies | Abroad'}</title>
        <meta name='title' content={'Site Copies | Abroad'}></meta>
        <meta property='og:title' content={'Site Copies | Abroad'}></meta>
        <meta property='twitter:title' content={'Site Copies | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/sitecopy/user/sa`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/sitecopy/user/sa`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/sitecopy/user/sa`}></meta>
      </Helmet>
      <Switch>
        <Route exact path={`${path}/sa`}>
          <SelfAwareness />
        </Route>
        <Route exact path={`${path}/oe`}>
          <TeamCohesion />
        </Route>
        <Route exact path={`${path}/vi`}>
          <VisionAndImpact />
        </Route>
        <Redirect to={`${path}/sa`} />
      </Switch>
    </>
  )
}

export default SiteCopy
