import React, { useEffect, useState, useRef } from 'react'
import { useErrorService, FeatherLoader } from '@abroad/components'
import moment from 'moment'
import { Container, Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import API from '../utils/API'
import { PageHeader } from '../components/common'
import useInitialState from '../components/common/hooks/useInitialState'

export const EmailSequencing = () => {
  const observer = useRef(null)
  const loader = useRef(null)
  const [isLoadMore, setIsLoadMore] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [emailData, setEmailData] = useState([])
  const [page, setPage] = useState(1)
  const Error = useErrorService()
  const history = useHistory()
  const { isInitiallyLoad, changeIntialStateHandler } = useInitialState()

  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    }
    // initialize IntersectionObserver
    // and attaching to Load More div
    if (emailData?.length > 0) {
      observer.current = new IntersectionObserver(handleObserver, options)
      if (loader.current && observer.current) {
        // eslint-disable-next-line no-unused-expressions
        observer.current?.observe(loader.current)
      }
    } else {
      // eslint-disable-next-line no-unused-expressions
      observer.current?.disconnect()
    }
    return () => {
      // eslint-disable-next-line no-unused-expressions
      observer.current?.disconnect()
    }
  }, [emailData])

  const getUserEmailList = async () => {
    if (isLoadMore) {
      try {
        const { data } = await API.AdminAPI.emailSequencing.getEmailUserData(
          page,
          25,
        )
        if (data.length === 0 || data.length < 25) {
          setIsLoadMore(false)
        } else {
          setIsLoadMore(true)
        }
        if (data.length === 0) {
          setIsLoading(false)
        } else if (page > 1) {
          setEmailData((prev) => [...prev, ...data])
        } else {
          setEmailData(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoadMore(false)
        Error.showError(e)
      }
    }
    changeIntialStateHandler()
  }

  useEffect(() => {
    if (isLoadMore) {
      getUserEmailList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <>
      <Helmet>
        <title>{'Scheduled Emails | Abroad'}</title>
        <meta name='title' content={'Scheduled Emails | Abroad'}></meta>
        <meta property='og:title' content={'Scheduled Emails | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Scheduled Emails | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/scheduled-emails`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/scheduled-emails`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/scheduled-emails`}></meta>
      </Helmet>
      <PageHeader
        titleText='REGISTERED USERS'
        colClasses='px-0'
        titleClasses='sentence-case-text'
      />
      <Container
        className='mt-3 custom-offset-lg-1 custom-col-lg-12 px-0'
        fluid>
        <Table hover responsive className='custom-table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Full Name</th>
              <th>Organization</th>
              <th>Creation Date</th>
            </tr>
          </thead>
          <tbody>
            {isInitiallyLoad && isLoading && (
              <tr>
                <td colSpan='5' className='text-center'>
                  <FeatherLoader />
                </td>
              </tr>
            )}
            {emailData.length === 0 && !isLoading && (
              <tr>
                <td className='text-center' colSpan='5'>
                  No email users found.
                </td>
              </tr>
            )}
            {emailData.length > 0 &&
              !isLoading &&
              emailData.map((user, index) => (
                <tr
                  key={index}
                  onClick={() =>
                    history.push(
                      `/admin/scheduled-emails/user/email-details?userId=${user._id}`,
                    )
                  }
                  className='pointer-cursor'>
                  <td>{index + 1}</td>
                  <td>
                    <div className='d-flex flex-column'>
                      {user.fullName} <br />
                      {user.email}
                    </div>
                  </td>
                  <td>
                    {user.company ? user.company : 'User in career transition'}
                  </td>
                  <td>{moment(user.createdAt).format('MM/DD/YYYY')}</td>
                </tr>
              ))}
            {isLoadMore && <tr ref={loader}></tr>}
          </tbody>
        </Table>
        {!isInitiallyLoad && isLoadMore && (
          <div className='d-flex justify-content-center align-items-center p-075'>
            <FeatherLoader />
          </div>
        )}
      </Container>
    </>
  )
}
