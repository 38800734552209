import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'
import { useErrorService, plans } from '@abroad/components'
import { OrgEcoIdsContext } from '../../utils/contexts'
import API from '../../utils/API'

const ProgramCheckbox = () => {
  const { companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)
  const [isProgramNetwork, setIsProgramNetwork] = useState(
    companyAndEcoInfo?.isProgramNetwork || false,
  )
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()

  useEffect(() => {
    if (companyAndEcoInfo?.isProgramNetwork) {
      setIsProgramNetwork(true)
    } else {
      setIsProgramNetwork(false)
    }
  }, [companyAndEcoInfo])

  const updateIsProgramNetworkFlag = async () => {
    setIsLoading(true)
    try {
      const { status } = await API.AdminAPI.ecoSystem.updateProgramData(
        companyAndEcoInfo?._id,
        {
          isProgramNetwork: !isProgramNetwork,
          ...(!isProgramNetwork && {
            targetPlan: plans.free.code,
          }),
        },
      )
      if (status) {
        setCompanyAndEcoInfo((prev) => ({
          ...prev,
          isProgramNetwork: !isProgramNetwork,
          ...(!isProgramNetwork && {
            planCode: null,
            targetPlan: plans.free.code,
          }),
          ...(isProgramNetwork && { targetPlan: null, networkPromo: null }),
        }))
        setIsProgramNetwork(!isProgramNetwork)
        setIsLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  return (
    <div className='calender-setup-checkbox mb-3'>
      <Form.Check
        id='isProgramNetwork'
        custom={true}
        onChange={updateIsProgramNetworkFlag}
        checked={isProgramNetwork ? true : false}
        type='checkbox'
        label='Is it a program?'
        className={classNames('s6', {
          'cursor-pointer': !isLoading,
          'pointer-events-none': isLoading,
        })}
        disabled={isLoading}
      />
      {isLoading && <span className='s7'>Updating...</span>}
    </div>
  )
}

export default ProgramCheckbox
