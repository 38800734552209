import React, { useEffect, useState } from 'react'
import {
  useErrorService,
  Button,
  useUserService,
  ABSpinner,
} from '@abroad/components'
import { useHistory, useParams } from 'react-router-dom'
import API from '../../utils/API'
import { Col, Container, Row } from 'react-bootstrap'
import { TeamReports } from './TeamReports'
import { GroupSessionsTable } from '../common'
import labels from '../../constants/labels'

export const TeamSessionReport = ({ teamMetadata = { isArchived: false } }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useUserService()
  const { teamId } = useParams()
  const history = useHistory()
  const Error = useErrorService()
  const [isTeamCoach, setIsTeamCoach] = useState(false)

  useEffect(() => {
    const getTeamData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getTeamSpecificData(teamId, {
          fields: ['name', 'coaches'],
          populateArray: [
            {
              path: 'coaches',
              select: 'fullName',
            },
          ],
        })
        if (data?.coaches?.length > 0) {
          setIsTeamCoach(
            data.coaches.map((data) => data._id).includes(user._id),
          )
          setIsLoading(false)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getTeamData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Container className='mt-5' fluid>
        <Row className='d-flex justify-content-between'>
          <Col className='pl-lg-0'>
            <h3 className='font-montserrat font-semibold text-saffron-700 font-18'>
              SESSIONS
            </h3>
          </Col>
          <Col className='d-flex justify-content-end pr-lg-1'>
            {user.isCoach && isTeamCoach && !teamMetadata?.isArchived && (
              <Button
                variant='dark'
                size='sm'
                onClick={() => history.push(`/admin/groups/${teamId}/session`)}
                className='btn-report'>
                Add Session
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col className='px-0'>
            <GroupSessionsTable isArchived={teamMetadata?.isArchived} />
          </Col>
        </Row>
        <br />
        <hr className='m-0' />
        <br />
        <TeamReports
          user={user}
          isTeamCoach={isTeamCoach}
          isArchived={teamMetadata?.isArchived}
          isSOWGroup={[
            labels.allGroupType.COMPANY_WITH_SOW,
            labels.allGroupType.NETWORK_WITH_SOW,
          ]?.includes(teamMetadata?.groupType)}
        />
      </Container>
    </>
  )
}
