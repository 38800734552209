import React, { useEffect, useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import { Col, Form as RBForm, Row } from 'react-bootstrap'
import { string, object } from 'yup'
import classNames from 'classnames'
import {
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'

import { InputField } from '../form'
import CompanyMetadataOverlay from './CompanyMetadataOverlay'
import { CompanyMetadataContext } from '../../utils/contexts'
import API from '../../utils/API'

const PurposeAndVisionForm = ({ purposeAndVisionData, id }) => {
  const { data, setData } = useContext(CompanyMetadataContext)
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const keyWrapper = 'purposeAndVision'

  const formInitialValues = {
    purpose: '',
    vision: '',
    description: '',
    goal: '',
  }
  const validationSchema = object().shape({
    purpose: string()
      .required(`This is required field, please add purpose.`)
      .min(2, 'Must be at least 2 characters')
      .max(100, `Must be at most 100 characters`),
    vision: string()
      .required(`This is required field, please add vision.`)
      .min(2, 'Must be at least 2 characters')
      .max(500, `Must be at most 500 characters`),
    description: string()
      .required(`This is required field, please add description.`)
      .min(2, 'Must be at least 2 characters')
      .max(1000, `Must be at most 1000 characters`),
    goal: string()
      .required(`This is required field, please add goal.`)
      .min(2, 'Must be at least 2 characters')
      .max(1000, `Must be at most 1000 characters`),
  })
  const [initialValues, setInitialValues] = useState(formInitialValues)

  useEffect(() => {
    if (data) {
      setInitialValues({
        purpose: data?.purpose || '',
        vision: data?.vision || '',
        description: data?.description || '',
        goal: data?.goal || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const updateMetadata = async (obj) => {
    try {
      const { data } = await API.AdminAPI.companyList.updateCompanyMetdata(
        id,
        keyWrapper,
        obj,
      )
      if (data) {
        Notification.showNotification(data?.message, 'success')
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const onSubmit = async (values) => {
    try {
      setIsLoading(true)
      const { purpose, vision, description, goal } = values
      await updateMetadata({
        [keyWrapper]: { purpose, vision, description, goal },
      })
      setData({ purpose, vision, description, goal })
    } catch (e) {
      Error.showError(e)
    }
    setIsLoading(false)
  }

  return (
    <Formik
      displayName={`purpose-vision`}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={onSubmit}>
      {({ handleSubmit, isValid, dirty }) => {
        return (
          <RBForm
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(e)
            }}
            as={Form}
            className='purpose-vision-form'>
            {purposeAndVisionData.map((e, index) => (
              <Row className='custom-mt-2-6' key={index}>
                <Col className='col-12 col-lg-6'>
                  <Row>
                    <Col className='d-flex align-items-center mb-3'>
                      <h3 className='mb-0 mr-3 font-open-sans font-semibold font-20 leading-7-half letter-spacing-minus-02'>
                        {e.inputLabel}
                      </h3>
                      <CompanyMetadataOverlay toolTipText={e.tooltipText} />
                    </Col>
                  </Row>
                  <InputField
                    className={classNames('mb-pv-form', {
                      'hide-scrollbar resize-none': e.inputAsTextArea,
                    })}
                    name={e.keyProp}
                    {...(e.inputAsTextArea
                      ? { as: 'textarea' }
                      : { type: 'text' })}
                    {...(e.inputAsTextArea && { rows: e.rows })}
                    size='lg'
                    placeholder={e.placeholder}
                    sm='12'
                    showErrorMessage={true}
                    hasErrorMessagePadding={false}
                  />
                </Col>
                <Col className='col-12 col-lg-6'>
                  <div className='font-open-sans font-semibold font-20 leading-7-half letter-spacing-minus-02 mb-2'>
                    {e.title}
                  </div>
                  <div
                    className='font-open-sans font-semibold font-14 leading-6 letter-spacing-minus-02 opacity-point-5'
                    dangerouslySetInnerHTML={{ __html: e.description }}
                  />
                </Col>
              </Row>
            ))}
            <Button
              variant='gray'
              isLoading={isLoading}
              disabled={isLoading || !(isValid && dirty)}
              size='md'
              className='line-height-normal text-uppercase border-radius-3'
              type='submit'>
              Submit
            </Button>
          </RBForm>
        )
      }}
    </Formik>
  )
}

export default PurposeAndVisionForm
