import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { useRouteMatch } from 'react-router-dom'
import { ShimmerTable } from 'react-shimmer-effects'
import { InfoIcon } from '../assetsComponents'
import { useErrorService } from '../../services'
import { formatDate } from '../../utils'

const placement = 'top'

const PaymentHistoryTable = ({
  setShowHighlightedPart,
  API,
  userId,
  isTogglePayment = false
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [userPayments, setUserPayments] = useState([])
  const Error = useErrorService()
  const { url } = useRouteMatch()
  let timeInterval = null

  useEffect(() => {
    return () => {
      clearInterval(timeInterval)
    }
  }, [timeInterval])

  useEffect(() => {
    const getUserPayments = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.payment.getUserPayments(userId)
        if (data) {
          setUserPayments(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserPayments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTogglePayment])

  const renderPageTitle = () => {
    return <h3 className='s3 payment-title'>Payment History</h3>
  }

  const renderInfoIcon = (reason) => {
    return (
      <div>
        <OverlayTrigger
          key={placement}
          placement={placement}
          overlay={
            <Tooltip
              id={`tooltip-${placement}`}
              className='font-open-sans font-16 font-semibold leading-7 text-center header-tooltip'
            >
              {reason}
            </Tooltip>
          }
        >
          <span className='d-inline-block'>
            <InfoIcon className='align-self-center cursor-pointer ml-2 ms-2' />
          </span>
        </OverlayTrigger>
      </div>
    )
  }

  if (isLoading) {
    return (
      <>
        {renderPageTitle()}
        <ShimmerTable row={2} col={4} />
      </>
    )
  }

  return (
    <section>
      {userPayments?.length > 0 && (
        <>
          {renderPageTitle()}
          <Table hover responsive className='custom-table'>
            <thead>
              <tr>
                <th width='15%'>Type</th>
                <th width='25%'>Plan Name or SOW Name</th>
                <th width='12%'>Amount</th>
                <th width='14%'>Status</th>
                <th width='16%'>Date</th>
                <th width='18%'>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {userPayments?.map((payment, index) => (
                <tr key={index}>
                  <td width='15%'>{payment?.plan ? 'Platform' : 'Coaching'}</td>
                  <td width='25%'>
                    {payment?.plan ? (
                      <span>{payment?.plan}</span>
                    ) : (
                      <a
                        rel='noopener'
                        href={`${url}#${payment?.sowId?._id}`}
                        onClick={() => {
                          setShowHighlightedPart(true)
                          timeInterval = setInterval(() => {
                            setShowHighlightedPart(false)
                            clearInterval(timeInterval)
                          }, 3000)
                        }}
                      >
                        {payment?.sowId?.planName} With{' '}
                        {payment?.sowId?.coachId?.fullName}
                      </a>
                    )}
                  </td>
                  <td width='12%'>
                    {payment?.amount || payment?.amount === 0
                      ? `$${payment?.amount}`
                      : ''}
                  </td>
                  <td width='14%'>
                    <div className='d-flex'>
                      {payment?.paid ? (
                        'Paid'
                      ) : payment?.stripeCharges?.message ? (
                        <>
                          Failed{' '}
                          {renderInfoIcon(payment?.stripeCharges?.message)}
                        </> // send reason text
                      ) : (
                        'Failed'
                      )}
                    </div>
                  </td>
                  <td width='16%'>{formatDate(payment?.createAt)}</td>
                  <td width='18%'>
                    {payment?.stripeCharges?.invoiceUrl ? (
                      <a
                        target='_blank'
                        href={payment?.stripeCharges?.invoiceUrl}
                        rel='noreferrer'
                      >
                        Download
                      </a>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </section>
  )
}

export default PaymentHistoryTable
