import React, { useEffect, useState } from 'react'
import { Form as RBForm, Row, Col, Container } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { Trans, withTranslation } from 'react-i18next'
import { string, number, object } from 'yup'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  useErrorService,
  ABSpinner,
  Button,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { InputField, CustomSelectionField } from '../form'
import { PageHeader, SelectionField } from '../common'
import { CurrentStatusField, PriorityField } from '../clientProfile'
import { isLGScreen } from '../../utils/utility'
import Labels from '../../constants/labels'
import API from '../../utils/API'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const formInitialValues = {
  gender: '',
  languages: [],
  ethnicity: [],
  workStatus: '',
  company: '',
  age: '',
  virtualWorkers: '',
  title: '',
}

const validationSchema = object().shape({
  country: string().notRequired(),
  languages: string().notRequired(),
  ethnicity: string().notRequired(),
  gender: string().notRequired(),
  age: number().min(10).max(99).integer().notRequired(),
  education: string().notRequired(),
  coachingPriority1: string().notRequired(),
  coachingPriority2: string().notRequired(),
  coachingPriority3: string().notRequired(),
  company: string().notRequired().max(100),
  roleInCompany: string().notRequired(),
  companyType: string().notRequired(),
  numberOfPeopleInCompany: string().notRequired(),
  investmentStage: string().notRequired(),
  virtualWorkers: number().notRequired(),
  title: string().notRequired().nullable().max(60),
})

const AdminProfileForm = () => {
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateProfileLoading, setIsUpdateProfileLoading] = useState(false)
  const { user } = useUserService()
  const { adminId } = useParams()
  const Error = useErrorService()
  const Notification = useNotificationService()

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await API.ClientAPI.user.getProfile(adminId)
        setInitialValues((prev) => ({
          ...prev,
          gender: data.profile?.gender ? data.profile?.gender : '',
          age: data.profile?.age ? data.profile?.age : '',
          country: data.profile?.country ? data.profile?.country : '',
          languages:
            data.profile?.languages?.length > 0
              ? data.profile?.languages?.map((language) => ({
                  value: language,
                  label: language,
                }))
              : [],
          ethnicity:
            data.profile?.ethnicity?.length > 0
              ? data.profile?.ethnicity?.map((ethnicity) => ({
                  value: ethnicity,
                  label: ethnicity,
                }))
              : [],
          education: data.profile?.education ? data.profile?.education : '',
          coachingPriority1: data.profile?.coachingPriority1
            ? data.profile?.coachingPriority1
            : '',
          coachingPriority2: data.profile?.coachingPriority2
            ? data.profile?.coachingPriority2
            : '',
          coachingPriority3: data.profile?.coachingPriority3
            ? data.profile?.coachingPriority3
            : '',
          roleInCompany: data.profile?.roleInCompany
            ? data.profile?.roleInCompany
            : '',
          companyType: data.profile?.companyType
            ? data.profile?.companyType
            : '',
          numberOfPeopleInCompany: data.profile?.numberOfPeopleInCompany
            ? data.profile?.numberOfPeopleInCompany
            : '',
          investmentStage: data.profile?.investmentStage
            ? data.profile?.investmentStage
            : '',
          virtualWorkers: data.profile?.virtualWorkers
            ? data.profile?.virtualWorkers
            : '',
          workStatus:
            data?.workStatus && data.workStatus === 'operating'
              ? Labels.userStatus[1]
              : Labels.userStatus[0],
          company: data?.staticCompany ? data?.staticCompany : '',
          title: data?.profile?.title,
        }))
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  const onSubmit = async (values) => {
    try {
      setIsUpdateProfileLoading(true)
      const params = {
        ...(values.workStatus !== Labels.userStatus[0] && {
          company: values.company,
        }),
        workStatus:
          values.workStatus !== Labels.userStatus[0]
            ? 'operating'
            : 'transition',
        profile: {
          gender: values?.gender,
          age: values?.age,
          country: values?.country,
          languages:
            values?.languages?.length > 0
              ? values?.languages.map((language) => language.value)
              : [],
          ethnicity:
            values?.ethnicity?.length > 0
              ? values?.ethnicity.map((ethnicity) => ethnicity.value)
              : [],
          education: values?.education,
          coachingPriority1: values?.coachingPriority1,
          coachingPriority2: values?.coachingPriority2,
          coachingPriority3: values?.coachingPriority3,
          ...(values.workStatus !== Labels.userStatus[0] && {
            roleInCompany: values?.roleInCompany,
            companyType: values?.companyType,
            numberOfPeopleInCompany: values?.numberOfPeopleInCompany,
            investmentStage: values?.investmentStage,
            virtualWorkers: values?.virtualWorkers,
            title: values?.title ? values?.title : '',
          }),
        },
      }
      Object.keys(params.profile).forEach(
        (key) =>
          params.profile[key] === '' &&
          key !== 'title' &&
          delete params.profile[key],
      )
      Object.keys(params).forEach(
        (key) => params[key] === '' && delete params[key],
      )
      const { data: profileData } = await API.ClientAPI.user.updateProfile(
        params,
        adminId,
      )
      if (profileData) {
        setIsUpdateProfileLoading(false)
        Notification.showNotification('Profile has been updated!', 'success')
      }
    } catch (e) {
      setIsUpdateProfileLoading(false)
      Error.showError(e)
    }
  }

  return (
    <Formik
      displayName='profile-form'
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={onSubmit}>
      {({ values, handleSubmit }) => {
        return (
          <>
            <PageHeader
              titleText={`${user?.fullName}`}
              descriptionFn={() => {
                return (
                  <>
                    <p className='font-montserrat font-medium'>{user?.email}</p>
                    <Link
                      to={{
                        pathname: '/profile/update-password',
                        state: {
                          fullName: user?.fullName,
                          email: user?.email,
                          coachId: adminId,
                        },
                      }}
                      className='text-dark-600 under-line font-montserrat font-medium'>
                      Update Password
                    </Link>
                  </>
                )
              }}
            />
            <Container fluid>
              <RBForm
                onSubmit={handleSubmit}
                as={Form}
                className='profile py-3 custom-offset-lg-1 custom-col-lg-12 px-0'>
                <Row noGutters className='mb-3'>
                  <Col className='col-sm-2 d-none d-lg-block' />
                  <Col className='col-lg-offset-2'>
                    <h5 className='font-montserrat font-semibold text-saffron-700 mt-3'>
                      <Trans>Personal Information</Trans>
                    </h5>
                  </Col>
                </Row>
                <SelectionField
                  label='Gender'
                  labelProps={labelProps}
                  name='gender'
                  options={Labels.genders}
                />
                <InputField
                  label='Age'
                  labelProps={labelProps}
                  name='age'
                  type='number'
                  size='lg'
                  placeholder='Age'
                  sm='10'
                />
                <SelectionField
                  label='Resident Country'
                  labelProps={labelProps}
                  name='country'
                  options={Object.keys(Labels.countries)}
                />
                <CustomSelectionField
                  name='languages'
                  placeholder='Select'
                  label='PREFERRED LANGUAGE'
                  options={Labels.languages.map((language) => ({
                    value: language,
                    label: language,
                  }))}
                  classNamePrefix='bg-white-container ethnicity'
                />
                <CustomSelectionField
                  name='ethnicity'
                  placeholder='Select'
                  label='ETHNICITY / RACE'
                  options={Object.keys(Labels.ethnicity).map((key) => ({
                    value: key,
                    label: key,
                  }))}
                  classNamePrefix='bg-white-container ethnicity'
                />
                <SelectionField
                  label='EDUCATION'
                  labelProps={labelProps}
                  name='education'
                  options={Labels.education}
                />
                <Row noGutters className='py-2'>
                  <Col className='col-sm-2 d-none d-lg-block' />
                  <Col className=''>
                    <label className='text-center text-lg-left font-light font-16'>
                      <Trans i18nKey='PriorityInfo'>
                        WHAT ARE THE TOP 3 AREAS YOU BELIEVE HAVE THE GREATEST
                        GROWTH POTENTIAL IF YOU HAD THE SUPPORT OF A COACH?
                      </Trans>
                    </label>
                  </Col>
                </Row>
                <PriorityField
                  label='FIRST PRIORITY'
                  labelProps={labelProps}
                  name='coachingPriority1'
                  disabledOptions={[
                    values?.coachingPriority2,
                    values?.coachingPriority3,
                  ]}
                />
                <PriorityField
                  label='SECOND PRIORITY'
                  labelProps={labelProps}
                  name='coachingPriority2'
                  disabledOptions={[
                    values?.coachingPriority1,
                    values?.coachingPriority3,
                  ]}
                />
                <PriorityField
                  label='THIRD PRIORITY'
                  labelProps={labelProps}
                  name='coachingPriority3'
                  disabledOptions={[
                    values?.coachingPriority1,
                    values?.coachingPriority2,
                  ]}
                />
                <Row noGutters className='py-3'>
                  <Col className='col-sm-2 d-none d-lg-block' />
                  <Col className=''>
                    <h5 className='font-montserrat font-semibold text-saffron-700 mt-3'>
                      <Trans>Organization Information</Trans>
                    </h5>
                  </Col>
                </Row>
                <CurrentStatusField labelProps={labelProps} />
                <InputField
                  label='ORGANIZATION'
                  labelProps={labelProps}
                  name='company'
                  type='text'
                  placeholder='Organization'
                  size='lg'
                  sm='10'
                  disabled={values?.workStatus === Labels.userStatus[0]}
                />
                <SelectionField
                  label='ROLE AT ORGANIZATION'
                  labelProps={labelProps}
                  name='roleInCompany'
                  getValueAsOption={true}
                  options={Labels.roleListForEnterpriceUser}
                  disabled={values?.workStatus === Labels.userStatus[0]}
                />
                <InputField
                  label='Title'
                  labelProps={labelProps}
                  placeholder='Title'
                  name='title'
                  type='text'
                  size='lg'
                  sm='10'
                  disabled={values?.workStatus === Labels.userStatus[0]}
                />
                <SelectionField
                  label='ORGANIZATION TYPE'
                  labelProps={labelProps}
                  name='companyType'
                  options={Labels.companyType}
                  disabled={values?.workStatus === Labels.userStatus[0]}
                />
                <SelectionField
                  label='NUMBER OF PEOPLE'
                  labelProps={labelProps}
                  name='numberOfPeopleInCompany'
                  options={Labels.companyPeople}
                  disabled={values?.workStatus === Labels.userStatus[0]}
                />
                <SelectionField
                  label='INVESTMENT STAGE'
                  labelProps={labelProps}
                  name='investmentStage'
                  options={Labels.investmentStage}
                  disabled={values?.workStatus === Labels.userStatus[0]}
                />
                <InputField
                  label='VIRTUAL WORKERS'
                  labelProps={labelProps}
                  name='virtualWorkers'
                  type='number'
                  min='0'
                  max='100'
                  placeholder='Virtual Workers'
                  size='lg'
                  sm='10'
                  disabled={values?.workStatus === Labels.userStatus[0]}
                />
                <div className='text-right'>
                  <Button
                    isLoading={isUpdateProfileLoading}
                    disabled={isUpdateProfileLoading}
                    type='submit'
                    className='line-height-normal text-uppercase'
                    size='md'
                    variant='gray'>
                    <Trans>Update</Trans>
                  </Button>
                </div>
              </RBForm>
            </Container>
          </>
        )
      }}
    </Formik>
  )
}

export default withTranslation('translation')(AdminProfileForm)
