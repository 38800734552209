export const getCurrencyMaskOption = () => {
  return {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 6, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
    inputMode: 'numeric',
  }
}
export const getMinutesMaskOption = () => {
  return {
    prefix: '',
    suffix: ' min',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false,
    integerLimit: 5, // limit length of integer numbers
    inputMode: 'numeric',
  }
}
