import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import classNames from 'classnames'
import { Button, useErrorService } from '@abroad/components'
import API from '../../utils/API'
import useDebounce from './useDebounce'

const SearchInput = ({
  search,
  render,
  setClientInfo,
  handleSubmit,
  buttonText,
  placeholder,
  btnColumnClasses = '',
  inputColumnClasses = '',
  wrapperClasses = '',
  listClasses = '',
  inputClasses = '',
  parentComponentLoading = false,
  useUserListAPI = false,
  isShowLoader = false,
}) => {
  const [searchData, setSearchData] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const Error = useErrorService()
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    if (searchData.length > 0) {
      setIsValid(searchData[0].email === search)
    } else {
      setIsValid(false)
    }
  }, [searchData, search])

  useEffect(() => {
    const getSearchData = async () => {
      setIsSearch(true)
      try {
        const { data } = await API.AdminAPI.coachClient.getSearchUser(
          debouncedSearch,
        )
        if (data) {
          setSearchData(data)
          setIsSearch(false)
          data.length > 0 && setClientInfo(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    const getUserList = async () => {
      setIsSearch(true)
      try {
        const { data } = await API.AdminAPI.userList.getUserList({
          limit: 25,
          page: 1,
          search: debouncedSearch,
          filter: {},
        })
        if (data) {
          setSearchData(data)
          setIsSearch(false)
          data.length > 0 && setClientInfo(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (debouncedSearch !== '' && !useUserListAPI) getSearchData()
    if (debouncedSearch !== '' && useUserListAPI) getUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  return (
    <Row className={wrapperClasses}>
      <Col className={inputColumnClasses}>
        <Form.Control
          type='text'
          placeholder={placeholder}
          onChange={(e) => render(e.target.value)}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setTimeout(() => setInputFocus(false), 200)}
          value={search}
          id='search-user'
          size='md'
          className={classNames('user-input', inputClasses)}
        />
        {searchData.length > 0 && inputFocus && search !== '' ? (
          isSearch ? (
            <div className='d-flex justify-content-center align-items-center search-user-list py-5'>
              <Spinner animation='border' />
            </div>
          ) : (
            <ul className={classNames('search-user-list p-0', listClasses)}>
              {searchData?.map((userInfo) => (
                <React.Fragment key={userInfo?._id}>
                  <li
                    onClick={() => {
                      render(userInfo?.email)
                      setSearchData([userInfo])
                    }}
                    className='user-input'>
                    <strong>{userInfo?.fullName}</strong> / {userInfo?.email}/
                    Organization:
                    {userInfo?.company === null || userInfo?.company === ''
                      ? 'Not Specified'
                      : userInfo?.company}{' '}
                    / Role :
                    {userInfo?.profile?.roleInCompany === undefined ||
                    userInfo?.profile?.roleInCompany === null
                      ? 'Not Specified'
                      : userInfo?.profile?.roleInCompany}{' '}
                    / Gender :{' '}
                    {userInfo?.profile?.gender === undefined
                      ? 'Not Specified'
                      : userInfo?.profile?.gender}{' '}
                    / Country :{' '}
                    {userInfo?.profile?.country === undefined
                      ? 'Not Specified'
                      : userInfo?.profile?.country}
                  </li>
                  <hr className='m-0' />
                </React.Fragment>
              ))}
            </ul>
          )
        ) : null}
      </Col>
      <Col className={btnColumnClasses}>
        <Button
          variant='gray'
          size='md'
          className='black-color-placeholder text-uppercase my-3 border-radius-3'
          isLoading={isShowLoader ? parentComponentLoading : false}
          onClick={handleSubmit}
          disabled={
            search === '' ||
            searchData?.length === 0 ||
            !isValid ||
            isSearch ||
            parentComponentLoading
          }>
          {isSearch ? 'Loading...' : buttonText}
        </Button>
      </Col>
    </Row>
  )
}

export default SearchInput
