import React from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import moment from 'moment'
import classNames from 'classnames'
import PolygonChart from './PolygonChart'
import SurveyComparisonTabelRow from './SurveyComparisonTabelRow'
import ABSpinner from '../Spinner'
import Feedback from '../common/Feedback'

const SurveyComparisonReport = ({
  isLoading,
  preDate,
  postDate,
  comparisonData,
  i18n,
  header,
  onGoBack = () => {},
  isRequiredToFormatDate = true,
  showGoBackButton = true,
  isRenderFromOP = false
}) => {
  const spitedPreDate = preDate.toString().split(' - ')
  const spitedPostDate = postDate.toString().split(' - ')
  const formatedPreDate = isRequiredToFormatDate
    ? `${moment(spitedPreDate[0]).format('MM-DD-YYYY')} - ${moment(
        spitedPreDate[1]
      ).format('MM-DD-YYYY')}`
    : `${moment(preDate).format('MM-DD-YYYY')}`
  const formatedPostDate = isRequiredToFormatDate
    ? `${moment(spitedPostDate[0]).format('MM-DD-YYYY')} - ${moment(
        spitedPostDate[1]
      ).format('MM-DD-YYYY')}`
    : `${moment(postDate).format('MM-DD-YYYY')}`

  if (isLoading) {
    return <ABSpinner />
  }
  return (
    <div className='abroad-fs-exclude'>
      <section
        className={classNames({
          'px-0 custom-offset-lg-1 custom-col-lg-12': isRenderFromOP
        })}
      >
        <Container
          className={classNames('pt-4', {
            'px-0': isRenderFromOP
          })}
          fluid
        >
          <Row>
            <Col className='text-center'>
              <span className='icon icon-assessments font-40' />
              <div className='d-flex'>
                {showGoBackButton && (
                  <div className='back-btn'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='36'
                      viewBox='0 0 20 36'
                      fill='none'
                    >
                      <path
                        d='M18.5748 1.35352L1.4248 17.9997'
                        stroke='black'
                        strokeWidth='2.5'
                        strokeLinecap='round'
                      />
                      <path
                        d='M18.5748 34.6462L1.4248 18'
                        stroke='black'
                        strokeWidth='2.5'
                        strokeLinecap='round'
                      />
                    </svg>

                    <svg
                      onClick={onGoBack}
                      className='cursor-pointer'
                      width='20'
                      height='36'
                      viewBox='0 0 22 38'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                )}
                <div className='d-flex w-100 justify-content-center'>
                  <h2 className='font-montserrat font-semibold text-dark-600'>
                    {header()}
                  </h2>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className='pb-4' fluid>
          <Row className='mb-3'>
            <Col className='text-center'>
              <div className='d-flex flex-column text-gray-800 align-items-center font-open-sans'>
                <p
                  className={classNames('w-100 font-normal', {
                    'px-0 custom-col-lg-12': isRenderFromOP,
                    'w-lg-9/12': !isRenderFromOP
                  })}
                >
                  The following charts and quantitative data help you see how
                  your Self Awareness, Team Cohesion, and Vision & Impact scores
                  have changed between the two assessment periods you selected.
                </p>
                <p
                  className={classNames('w-100 font-normal', {
                    'px-0 custom-col-lg-12': isRenderFromOP,
                    'w-lg-9/12': !isRenderFromOP
                  })}
                >
                  We invite you not to be overly concerned with whether your
                  scores have increased or decreased. Sometimes life and
                  business present challenges that require us to change our
                  working habits for a period of time. If an area decreases,
                  that is good awareness around opportunities to course correct.
                  Similarly, if an area increases, we invite you to celebrate,
                  but also not attach to it always being that way.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Self Awareness */}
      <section className={isRenderFromOP && 'offset-lg-1 col-lg-10 px-lg-0'}>
        <div className='result-category-compare-panel py-2'>
          <Container fluid>
            <span className='result-category-compare-label text-blue-100'>
              Self Awareness
            </span>
          </Container>
        </div>
        <Row noGutters>
          <Col className='col-12 bg-gray-101 col-lg-3'>
            {/* survey length < 3 */}
            <Container
              fluid='lg'
              className=' d-flex flex-row flex-lg-column h-100 justify-content-center align-items-center space-x-2 space-lg-y-3 py-2'
            >
              <div className='assessment-date space-y-2'>
                <div className='bg-blue-100 result-compare-circle' />
                <div className='text-center'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPreDate}
                  <br />
                </div>
              </div>
              <div className='assessment-date space-y-2'>
                <div className='bg-blue-200 result-compare-circle' />
                <div className='text-center'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPostDate}
                  <br />
                </div>
              </div>
            </Container>
          </Col>
          <Col className='col-12 col-lg-9 py-4'>
            <div className='d-flex flex-column align-items-center'>
              <PolygonChart
                categories={[
                  'Burnout Buffer',
                  'Fear & Anxiety',
                  'Healthy Responsibility',
                  'Psychological Adaptability',
                  'Self Compassion',
                  'Time Management & Mindset'
                ]}
                colors={['#69B1d8', '#0076BD']}
                surveyOne={[
                  parseInt(
                    comparisonData[0]?.results.self_awareness.subcategories
                      .burnout_buffer?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_awareness.subcategories
                      .fear__decision_making?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_awareness.subcategories
                      .healthy_responsibility?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_awareness.subcategories
                      .psychological_adaptability?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_awareness.subcategories
                      .self_compassion?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_awareness.subcategories
                      .time_management__mindset?.percent
                  )
                ]}
                surveyTwo={[
                  parseInt(
                    comparisonData[1]?.results.self_awareness.subcategories
                      .burnout_buffer?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_awareness.subcategories
                      .fear__decision_making?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_awareness.subcategories
                      .healthy_responsibility?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_awareness.subcategories
                      .psychological_adaptability?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_awareness.subcategories
                      .self_compassion?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_awareness.subcategories
                      .time_management__mindset?.percent
                  )
                ]}
              />
              <Container fluid>
                <Table
                  responsive
                  hover
                  size='sm'
                  className='comparison-table m-auto'
                >
                  <thead>
                    <tr>
                      <th>Subcategory</th>
                      <th className='text-center text-blue-100'>
                        Score #1
                        <br />
                        {formatedPreDate}
                      </th>
                      <th className='text-center text-blue-200'>
                        Score #2
                        <br />
                        {formatedPostDate}
                      </th>
                      <th className='text-center'>
                        Change <br />
                        (+/-)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <SurveyComparisonTabelRow
                      title='Burnout Buffer'
                      survey0={
                        comparisonData[0]?.results.self_awareness.subcategories
                          .burnout_buffer?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_awareness.subcategories
                          .burnout_buffer?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Fear & Anxiety'
                      survey0={
                        comparisonData[0]?.results.self_awareness.subcategories
                          .fear__decision_making?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_awareness.subcategories
                          .fear__decision_making?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Healthy Responsibility'
                      survey0={
                        comparisonData[0]?.results.self_awareness.subcategories
                          .healthy_responsibility?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_awareness.subcategories
                          .healthy_responsibility?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Psychological Adaptability'
                      survey0={
                        comparisonData[0]?.results.self_awareness.subcategories
                          .psychological_adaptability?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_awareness.subcategories
                          .psychological_adaptability?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Self Compassion'
                      survey0={
                        comparisonData[0]?.results.self_awareness.subcategories
                          .self_compassion?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_awareness.subcategories
                          .self_compassion?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Time Management & Mindset'
                      survey0={
                        comparisonData[0]?.results.self_awareness.subcategories
                          .time_management__mindset?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_awareness.subcategories
                          .time_management__mindset?.percent
                      }
                    />
                  </tbody>
                </Table>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
      {/* Team Cohesion */}
      <section className={isRenderFromOP && 'offset-lg-1 col-lg-10 px-lg-0'}>
        <div className='result-category-compare-panel py-2'>
          <Container fluid>
            <span className='result-category-compare-label text-green-100'>
              Team Cohesion
            </span>
          </Container>
        </div>
        <Row noGutters>
          <Col className='col-12 bg-gray-101 col-lg-3'>
            {/* survey length < 3 */}
            <Container
              fluid='lg'
              className=' d-flex flex-row flex-lg-column h-100 justify-content-center align-items-center space-x-2 space-lg-y-3 py-2'
            >
              <div className='assessment-date space-y-2'>
                <div className='bg-green-100 result-compare-circle' />
                <div className='text-center'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPreDate}
                  <br />
                </div>
              </div>
              <div className='assessment-date space-y-2'>
                <div className='bg-green-200 result-compare-circle' />
                <div className='text-center'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPostDate}
                  <br />
                </div>
              </div>
            </Container>
          </Col>
          <Col className='col-12 col-lg-9 py-4'>
            <div className='d-flex flex-column align-items-center'>
              <PolygonChart
                categories={[
                  'Agreements & Accountability',
                  'Collaboration',
                  'Communication',
                  'Culture',
                  'Decision Making',
                  'Emotional Intelligence',
                  'Team Quality',
                  'Trust'
                ]}
                colors={['#509f92', '#37763B']}
                surveyOne={[
                  parseInt(
                    comparisonData[0]?.results.team_cohesion.subcategories
                      .agreements?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.team_cohesion.subcategories
                      .collaboration?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.team_cohesion.subcategories
                      .communication?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.team_cohesion.subcategories
                      .culture?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.team_cohesion.subcategories
                      .decision_making?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.team_cohesion.subcategories
                      .emotional_intelligence?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.team_cohesion.subcategories
                      .team_quality?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.team_cohesion.subcategories.trust
                      ?.percent
                  )
                ]}
                surveyTwo={[
                  parseInt(
                    comparisonData[1]?.results.team_cohesion.subcategories
                      .agreements?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.team_cohesion.subcategories
                      .collaboration?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.team_cohesion.subcategories
                      .communication?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.team_cohesion.subcategories
                      .culture?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.team_cohesion.subcategories
                      .decision_making?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.team_cohesion.subcategories
                      .emotional_intelligence?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.team_cohesion.subcategories
                      .team_quality?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.team_cohesion.subcategories.trust
                      ?.percent
                  )
                ]}
              />
              <Container fluid>
                <Table
                  responsive
                  hover
                  size='sm'
                  className='comparison-table m-auto'
                >
                  <thead>
                    <tr>
                      <th>Subcategory</th>
                      <th className='text-center text-green-100'>
                        Score #1
                        <br />
                        {formatedPreDate}
                      </th>
                      <th className='text-center text-green-200'>
                        Score #2
                        <br />
                        {formatedPostDate}
                      </th>
                      <th className='text-center'>
                        Change <br />
                        (+/-)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <SurveyComparisonTabelRow
                      title='Agreements & Accountability'
                      survey0={
                        comparisonData[0]?.results.team_cohesion.subcategories
                          .agreements?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.team_cohesion.subcategories
                          .agreements?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Collaboration'
                      survey0={
                        comparisonData[0]?.results.team_cohesion.subcategories
                          .collaboration?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.team_cohesion.subcategories
                          .collaboration?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Communication'
                      survey0={
                        comparisonData[0]?.results.team_cohesion.subcategories
                          .communication?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.team_cohesion.subcategories
                          .communication?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Culture'
                      survey0={
                        comparisonData[0]?.results.team_cohesion.subcategories
                          .culture?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.team_cohesion.subcategories
                          .culture?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Decision Making'
                      survey0={
                        comparisonData[0]?.results.team_cohesion.subcategories
                          .decision_making?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.team_cohesion.subcategories
                          .decision_making?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Emotional Intelligence'
                      survey0={
                        comparisonData[0]?.results.team_cohesion.subcategories
                          .emotional_intelligence?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.team_cohesion.subcategories
                          .emotional_intelligence?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Team Quality'
                      survey0={
                        comparisonData[0]?.results.team_cohesion.subcategories
                          .team_quality?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.team_cohesion.subcategories
                          .team_quality?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Trust'
                      survey0={
                        comparisonData[0]?.results.team_cohesion.subcategories
                          .trust?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.team_cohesion.subcategories
                          .trust?.percent
                      }
                    />
                  </tbody>
                </Table>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
      {/* Vision & Impact */}
      <section className={isRenderFromOP && 'offset-lg-1 col-lg-10 px-lg-0'}>
        <div className='result-category-compare-panel py-2'>
          <Container fluid>
            <span className='result-category-compare-label text-red-500'>
              Vision & Impact
            </span>
          </Container>
        </div>
        <Row noGutters>
          <Col className='col-12 bg-gray-101 col-lg-3'>
            {/* survey length < 3 */}
            <Container
              fluid='lg'
              className=' d-flex flex-row flex-lg-column h-100 justify-content-center align-items-center space-x-2 space-lg-y-3 py-2'
            >
              <div className='assessment-date space-y-2'>
                <div className='bg-red-500 result-compare-circle' />
                <div className='text-center'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPreDate}
                  <br />
                </div>
              </div>
              <div className='assessment-date space-y-2'>
                <div className='bg-red-700 result-compare-circle' />
                <div className='text-center'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPostDate}
                  <br />
                </div>
              </div>
            </Container>
          </Col>
          <Col className='col-12 col-lg-9 py-4'>
            <div className='d-flex flex-column align-items-center'>
              <PolygonChart
                categories={[
                  'Compassion for Others',
                  'Intrinsic Motivation',
                  'Leadership Strength',
                  'Superordinate Goals'
                ]}
                colors={['#BC4C6A', '#7E2D55']}
                surveyOne={[
                  parseInt(
                    comparisonData[0]?.results.vision__impact.subcategories
                      .compassion_for_others?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.vision__impact.subcategories
                      .intrinsic_motivation?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.vision__impact.subcategories
                      .leadership_strength?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.vision__impact.subcategories
                      .global_impact_goals?.percent
                  )
                ]}
                surveyTwo={[
                  parseInt(
                    comparisonData[1]?.results.vision__impact.subcategories
                      .compassion_for_others?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.vision__impact.subcategories
                      .intrinsic_motivation?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.vision__impact.subcategories
                      .leadership_strength?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.vision__impact.subcategories
                      .global_impact_goals?.percent
                  )
                ]}
              />
              <Container fluid>
                <Table
                  responsive
                  hover
                  size='sm'
                  className='comparison-table m-auto'
                >
                  <thead>
                    <tr>
                      <th>Subcategory</th>
                      <th className='text-center text-red-500'>
                        Score #1
                        <br />
                        {formatedPreDate}
                      </th>
                      <th className='text-center text-red-700'>
                        Score #2
                        <br />
                        {formatedPostDate}
                      </th>
                      <th className='text-center'>
                        Change <br />
                        (+/-)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <SurveyComparisonTabelRow
                      title='Compassion for Others'
                      survey0={
                        comparisonData[0]?.results.vision__impact.subcategories
                          .compassion_for_others?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.vision__impact.subcategories
                          .compassion_for_others?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Intrinsic Motivation'
                      survey0={
                        comparisonData[0]?.results.vision__impact.subcategories
                          .intrinsic_motivation?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.vision__impact.subcategories
                          .intrinsic_motivation?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Leadership Strength'
                      survey0={
                        comparisonData[0]?.results.vision__impact.subcategories
                          .leadership_strength?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.vision__impact.subcategories
                          .leadership_strength?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Superordinate Goals'
                      survey0={
                        comparisonData[0]?.results.vision__impact.subcategories
                          .global_impact_goals?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.vision__impact.subcategories
                          .global_impact_goals?.percent
                      }
                    />
                  </tbody>
                </Table>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
      <Feedback i18n={i18n} />
    </div>
  )
}

export default SurveyComparisonReport
