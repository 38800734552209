import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CountCard = ({
  icon,
  backgroundColor,
  url,
  info,
  total,
  linkDisabled,
}) => {
  return (
    <Link
      to={url}
      className={classNames('text-dark-600 survey-subcat-link', {
        'pointer-events-none': linkDisabled,
      })}>
      <div className='hover:shadow transition card-counter pointer-cursor m-2 mb-5 p-4 border-radius-7 d-flex justify-content-between'>
        <FontAwesomeIcon
          size='2x'
          icon={icon}
          color='#fff'
          border
          style={{ padding: '10px', backgroundColor: `${backgroundColor}` }}
          className='f-icon border-radius-5'
        />
        <div className='d-flex flex-column'>
          <span className='font-montserrat'>{info}</span>
          <span className='font-montserrat font-bold text-right'>{total}</span>
        </div>
      </div>
    </Link>
  )
}

export default CountCard
