import React from 'react'
import classNames from 'classnames'

const RightSideBar = React.forwardRef(({ children, show }, ref) => {
  return (
    <React.Fragment>
      <div
        className={classNames('right-sidebar-overlay', {
          'show-overlay': show,
          'hide-overlay': !show,
        })}></div>
      <div className='right-sidebar-wrapper'>
        <div
          ref={ref}
          className={classNames(
            'bg-gray-100 header-shadow right-sidebar-content h-100',
            {
              'hide-sidebar': !show,
            },
          )}>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
})

export default RightSideBar
