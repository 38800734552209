import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import RBDateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { formatDate } from '../../utils/date'
import classNames from 'classnames'
import moment from 'moment'

const SingleDatePickerWithoutFormik = ({
  formGroupProps: {
    className: formGroupClassName,
    ...otherFormGroupProps
  } = {},
  children,
  id,
  label,
  labelProps,
  renderChildren,
  size,
  className,
  showTooltip,
  customErrorMessage,
  showErrorMessage,
  type,
  placeholder,
  removeFormikField,
  onFocus,
  minDate,
  disabled,
  name,
  position,
  opens,
  minimumDate = null,
  maximumDate = null,
  value,
  onDateApply,
  labelClasses,
  ...rest
}) => {
  const picker = useRef(null)
  const [dateValue, setDateValue] = useState(value)

  useEffect(() => {
    if (dateValue) {
      picker.current.setStartDate(formatDate(dateValue))
      picker.current.setEndDate(formatDate(dateValue))
      picker.current.$picker.val(formatDate(dateValue))
    } else {
      picker.current.$picker.val('')
      picker.current.setStartDate(formatDate(moment()))
      picker.current.setEndDate(formatDate(moment()))
    }
  }, [dateValue, picker])

  const renderLabel = () => {
    if (label) {
      return (
        <Form.Label {...labelProps} className={classNames(labelClasses)}>
          {label}
        </Form.Label>
      )
    }
    return null
  }

  const onApply = (e, datePicker) => {
    if (moment(datePicker.startDate).isValid()) {
      setDateValue(datePicker.startDate.format('MM/DD/YYYY'))
      datePicker.element.val(datePicker.startDate.format('MM/DD/YYYY'))
      onDateApply(moment(datePicker.startDate).format('YYYY-MM-DD'))
    } else {
      datePicker.element.val('')
      setDateValue(null)
    }
  }

  const handleChange = (e) => {
    if (moment(e.target.value).isValid()) {
      setDateValue(e.target.value)
    }
  }

  let initialDatePickerSettings = {
    singleDatePicker: true,
    showDropdowns: true,
    autoUpdateInput: false,
    locale: {
      format: 'MM/DD/YYYY',
    },
    applyButtonClasses: 'btn-saffron font-montserrat',
    cancelButtonClasses: 'btn-black font-montserrat',
    drops: position,
    opens: opens,
    autoApply: true,
  }
  if (minimumDate) {
    initialDatePickerSettings.minDate = minimumDate
  }
  if (maximumDate) {
    initialDatePickerSettings.maxDate = maximumDate
  }

  return (
    <span className='d-flex position-relative align-items-center'>
      <div className='d-lg-flex align-items-center w-100'>
        {renderLabel()}
        <RBDateRangePicker
          ref={picker}
          onApply={onApply}
          {...(rest?.onHide && { onHide: rest?.onHide })}
          {...(rest?.onShow && { onShow: rest?.onShow })}
          initialSettings={initialDatePickerSettings}>
          <input
            autoComplete={'off'}
            placeholder={placeholder ? placeholder : 'MM/DD/YYYY'}
            disabled={disabled}
            type='text'
            value={dateValue ? moment(dateValue).format('MM/DD/YYYY') : null}
            className={classNames('form-control', className)}
            onChange={handleChange}
            name={name}
          />
        </RBDateRangePicker>
      </div>
    </span>
  )
}

SingleDatePickerWithoutFormik.defaultProps = {
  showTooltip: false,
  removeFormikField: false,
  type: 'text',
  showErrorMessage: false,
  disabled: false,
  opens: 'right',
  position: 'down',
}

SingleDatePickerWithoutFormik.propTypes = {
  removeFormikField: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  position: PropTypes.oneOf(['down', 'up', 'auto']),
  opens: PropTypes.oneOf(['left', 'right', 'center']),
}

export default SingleDatePickerWithoutFormik
