import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useErrorService, useNotificationService } from '@abroad/components'
import { DeleteConfirmationPrompt } from '../DeleteConfirmationPrompt'
import { InformationIcon } from '../../assetsComponents'
import labels from '../../../constants/labels'
import API from '../../../utils/API'
import { OrgEcoIdsContext } from '../../../utils/contexts'

const placement = 'bottom'
const PlanDropdown = ({ setIsAnyModalEventOccurs }) => {
  const [orgEcoPlan, setOrgEcoPlan] = useState(labels.noplanSetOption)
  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [planBeforeConfirm, setPlanBeforeConfirm] = useState('')
  const [isPlanChangeLoading, setIsPlanChangeLoading] = useState(false)
  const Notification = useNotificationService()
  const Error = useErrorService()
  const { companyName } = useParams()
  const { setIsCsvUploaded, companyAndEcoInfo, setCompanyAndEcoInfo } =
    useContext(OrgEcoIdsContext)

  const popupTextForSetOrgPlan = `Once plan is set all the current users within this organization will be updated to selected plan. All the new user will be signed up on this plan only.`
  const popupTextForUnsetOrgPlan = `If this plan is not set, user will be asked to pay for the plan and anyone can register with this organization from signup menu.`

  const contentText = `Only participant with invite link can signup for this organization. If organization wide plan is set for this ${
    companyName ? 'company' : 'network'
  } then user will be on that plan without any payment.`

  useEffect(() => {
    if (companyName && companyAndEcoInfo) {
      getCompanyEcoCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, companyAndEcoInfo])

  useEffect(() => {
    if (showModal) {
      setIsAnyModalEventOccurs(true)
    } else {
      setIsAnyModalEventOccurs(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const getCompanyEcoCode = async () => {
    try {
      setIsLoading(true)
      setIsCsvUploaded({
        showRosterUI:
          !companyAndEcoInfo?.isCsvUploaded &&
          companyAndEcoInfo?.usersCount === 0,
        showBtn: companyAndEcoInfo?.usersCount > 0, // companyAndEcoInfo?.isCsvUploaded && companyAndEcoInfo?.usersCount > 0
      })
      setOrgEcoPlan(
        companyAndEcoInfo?.planCode ? companyAndEcoInfo?.planCode : 'null',
      )
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const renderInfoIcon = () => {
    return (
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={
          <Tooltip
            id={`tooltip-${placement}`}
            className='font-open-sans font-16 font-semibold leading-7 text-center header-tooltip inviteonly-content-text'>
            {contentText}
          </Tooltip>
        }>
        <InformationIcon iconClasses='align-self-center ml-2' />
      </OverlayTrigger>
    )
  }

  const orgEcoPlanChangeHandler = async () => {
    setIsPlanChangeLoading(true)

    try {
      const { status } = await API.AdminAPI.companyList.updateOrganizationPlan(
        companyAndEcoInfo?._id,
        {
          plan: planBeforeConfirm === 'null' ? null : planBeforeConfirm,
        },
      )
      if (status) {
        setOrgEcoPlan(planBeforeConfirm)
        setCompanyAndEcoInfo((prev) => ({
          ...prev,
          planCode: planBeforeConfirm === 'null' ? null : planBeforeConfirm,
        }))
        Notification.showNotification(
          'Organization plan has been updated!',
          'success',
        )
      }
    } catch (e) {
      Error.showError(e)
    }
    setIsPlanChangeLoading(false)
    setShowModal(false)
  }

  return (
    <Form.Group className='align-self-start custom-mb-1-25'>
      <DeleteConfirmationPrompt
        showModal={showModal}
        setShowModal={setShowModal}
        isDeleteLoading={isPlanChangeLoading}
        handleDelete={orgEcoPlanChangeHandler}
        message={
          planBeforeConfirm === 'null'
            ? popupTextForUnsetOrgPlan
            : popupTextForSetOrgPlan
        }
      />
      <Form.Label className='custom-mb-0-625 text-uppercase sidebar-label d-flex align-items-end'>
        Organization Plan
        {renderInfoIcon()}
      </Form.Label>
      <Form.Control
        as='select'
        name='orgPlan'
        className={classNames('user-input mb-1', {
          'pointer-cursor': !isPlanChangeLoading,
        })}
        value={orgEcoPlan}
        onChange={(e) => {
          setPlanBeforeConfirm(e.target.value)
          setShowModal(true)
        }}
        disabled={isPlanChangeLoading || isLoading}>
        {[labels.noplanSetOption, ...labels.plans].map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default PlanDropdown
