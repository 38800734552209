import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import { useErrorService } from '@abroad/components'
import { ClearFilters, FilterSelection, MultiRangeSlider } from '../common'
import API from '../../utils/API'
import { getNameArrayFromCompanies } from '../../utils/utility'
import { DateRangePicker } from '../assessments'
import { getCurrentDate } from '../../utils/date'
import CONSTANTS from '../../utils/constants'

const PaymentFilters = ({
  globalFilter,
  setGlobalFilter,
  handleClearFilter,
  isLoading,
}) => {
  const [companies, setCompanies] = useState({
    isLoading: true,
    companies: [],
  })
  const [ecoSystems, setEcoSystems] = useState({
    isLoading: true,
    ecoSystems: [],
  })
  const [promoCodes, setPromoCodes] = useState({
    isLoading: true,
    promoCodes: [],
  })
  const [isResetForm, setIsResetForm] = useState(false)
  const picker = useRef()
  const Error = useErrorService()

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        let { data } = await API.AdminAPI.companyList.getCompanyData(
          '?excludeEnterprise=true',
        )
        data = getNameArrayFromCompanies(data)
        if (data) {
          setCompanies({
            isLoading: false,
            companies: data?.map((company) => ({
              key: company,
              value: company,
            })),
          })
        }
      } catch (e) {
        setCompanies({
          isLoading: false,
          companies: [],
        })
        Error.showError(e)
      }
    }
    getCompanyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getEcoSystemsData = async () => {
      try {
        const { data } = await API.AdminAPI.teams.getEcoSystemsData(
          `?excludeEnterprise=true`,
        )
        if (data) {
          setEcoSystems({
            isLoading: false,
            ecoSystems: data?.map((ecosystem) => ({
              key: ecosystem,
              value: ecosystem,
            })),
          })
        }
      } catch (e) {
        setEcoSystems({
          isLoading: false,
          ecoSystems: [],
        })
        Error.showError(e)
      }
    }
    getEcoSystemsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getPromocodeData = async () => {
      try {
        const { data } = await API.AdminAPI.userList.getPromocodeData()
        if (data) {
          const sortedPromo = data?.sort((a, b) =>
            a?.code?.localeCompare(b?.code),
          )
          setPromoCodes({
            isLoading: false,
            promoCodes: sortedPromo?.map((promoCode) => ({
              key: promoCode?.code,
              value: promoCode.code?.toUpperCase(),
            })),
          })
        }
      } catch (e) {
        setPromoCodes({
          isLoading: false,
          promoCodes: [],
        })
        Error.showError(e)
      }
    }
    getPromocodeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='mb-2-5'>
      <Row className='user-payment-filters'>
        <Col className='col-4 mb-3'>
          <FilterSelection
            {...{
              globalFilter,
              setGlobalFilter,
            }}
            name='promoCode'
            placeholder='Select Promo'
            inputClasses={classNames('user-input', {
              'pointer-cursor': !promoCodes?.isLoading || !isLoading,
              'cursor-disabled': promoCodes?.isLoading || isLoading,
            })}
            options={promoCodes?.promoCodes}
            size='md'
            disabled={promoCodes?.isLoading || isLoading}
            key={globalFilter.promoCode}
          />
        </Col>
        <Col className='col-4 mb-3'>
          {/* Date range of payment */}
          <DateRangePicker
            initialSettings={{
              maxSpan: { months: 6 },
              autoUpdateInput: false,
              opens: 'center',
              startDate: getCurrentDate(),
              endDate: getCurrentDate(),
            }}
            onApply={(start, end) => {
              setGlobalFilter({
                ...globalFilter,
                preDate: start,
                postDate: end,
              })
            }}
            //setOnHide={true}
            className='w-100'
            ref={picker}
            isUseKeyProp={isResetForm}
            {...(isResetForm && {
              key: globalFilter?.preDate || globalFilter?.postDate,
            })}
            disabled={isLoading}
          />
        </Col>
        <Col className='col-4 mb-3'>
          <FilterSelection
            {...{
              globalFilter,
              setGlobalFilter,
            }}
            name='organization'
            placeholder='Select Organization'
            inputClasses={classNames('user-input', {
              'pointer-cursor':
                !companies?.isLoading || !globalFilter.network || !isLoading,
              'cursor-disabled':
                companies?.isLoading || globalFilter.network || isLoading,
            })}
            options={companies?.companies}
            size='md'
            disabled={companies?.isLoading || globalFilter.network || isLoading}
            key={globalFilter.organization}
          />
        </Col>
        <Col className='col-4 mb-3'>
          <FilterSelection
            {...{
              globalFilter,
              setGlobalFilter,
            }}
            name='network'
            placeholder='Select Network'
            inputClasses={classNames('user-input', {
              'pointer-cursor':
                !ecoSystems?.isLoading ||
                !globalFilter.organization ||
                !isLoading,
              'cursor-disabled':
                ecoSystems?.isLoading || globalFilter.organization || isLoading,
            })}
            options={ecoSystems?.ecoSystems}
            size='md'
            disabled={
              ecoSystems?.isLoading || globalFilter.organization || isLoading
            }
            key={globalFilter.network}
          />
        </Col>
        <Col className='col-4 mb-3 mt-2'>
          {/* Payment range - slider */}
          <MultiRangeSlider
            min={CONSTANTS.minAmountOfPaymentRange}
            max={CONSTANTS.maxAmonuntOfPaymentRange}
            defaultValue={CONSTANTS.defaultMinAmountOfPaymentRange}
            onChange={(amount) => {
              setGlobalFilter({
                ...globalFilter,
                preAmount: amount.min,
                postAmount: amount.max,
              })
            }}
            {...(isResetForm && {
              key: globalFilter?.preAmount || globalFilter?.postAmount,
            })}
            disabled={isLoading}
          />
        </Col>
        <Col className='col-4 mb-3'>
          <FilterSelection
            {...{
              globalFilter,
              setGlobalFilter,
            }}
            name='status'
            placeholder='Select Payment Status'
            inputClasses={classNames('user-input', {
              'pointer-cursor': !isLoading,
              'cursor-disabled': isLoading,
            })}
            options={Object.values(CONSTANTS.paymentStatus).map((status) => ({
              key: status,
              value: status.toUpperCase(),
            }))}
            size='md'
            disabled={isLoading}
            key={globalFilter.status}
          />
        </Col>
      </Row>
      {(globalFilter.preDate ||
        globalFilter.postDate ||
        globalFilter.organization !== '' ||
        globalFilter.network !== '' ||
        globalFilter.promoCode !== '' ||
        globalFilter.preAmount !== CONSTANTS.defaultMinAmountOfPaymentRange ||
        globalFilter.postAmount !== CONSTANTS.maxAmonuntOfPaymentRange ||
        globalFilter.status !== CONSTANTS.paymentStatus.SUCCEEDED) && (
        <ClearFilters
          wrapperClasses='payment-clear-filter text-right'
          onClick={() => {
            handleClearFilter()
            // for reset payment date range
            picker.current.setStartDate('')
            picker.current.setEndDate('')
            setIsResetForm(true)
            setTimeout(() => {
              setIsResetForm(false)
            }, 200)
          }}
          disabled={isLoading}
        />
      )}
    </section>
  )
}

export default PaymentFilters
