import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../Button'
import { ModelContext } from '../../contexts'

const CategoryModal = ({ i18n }) => {
  const { show, onHide, data } = useContext(ModelContext)

  return (
    <Modal
      show={show}
      size='lg'
      onHide={onHide}
      aria-labelledby='Insight'
      centered
    >
      <Modal.Header className='border-0 pb-0 px-4'>
        <span className='text-uppercase tracking-md-wide font-montserrat text-saffron-700 font-16'>
          {data?.title}
        </span>
      </Modal.Header>
      <Modal.Body className='pb-3 pt-2 px-4'>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description
          }}
        />
        <div className='text-right mt-3 space-x-3'>
          <Button
            variant='none'
            size='sm'
            onClick={onHide}
            className='px-3 secondary-popup-button'
          >
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CategoryModal
