import React, { useState, useEffect } from 'react'
import { Container, Table, Form as RBForm } from 'react-bootstrap'
import { Formik } from 'formik'
import { addMethod, number, object, string } from 'yup'
import { useParams } from 'react-router-dom'
import { ABSpinner, Button, useNotificationService } from '@abroad/components'
import { MonthlyPaymentTable } from '../components'
import {
  currencyUnmask,
  minuteUnmask,
} from '../components/enterpriseSOW/MonthlyPaymentTable'
import { getUnmaskedObject, removeEmpty } from '../utils/utility'
import API from '../utils/API'

const monthlyFeeColumn = {
  ic: null,
  manager: null,
  leader: null,
  executive: null,
  ceo: null,
}

export const EnterpriseSOW = ({ sowDetails }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isEditable, setIsEditable] = useState(false)
  const [initialValue, setInitialValue] = useState({
    monthlyFee: monthlyFeeColumn,
    monthlyIndividualSessions: monthlyFeeColumn,
    individualSessionDuration: monthlyFeeColumn,
    monthlyGroupSessions: monthlyFeeColumn,
    groupSessionDuration: monthlyFeeColumn,
  })
  const { sowId } = useParams()
  const Notification = useNotificationService()
  const [isSOWUpdateLoading, setIsSOWUpdateLoading] = useState(false)

  const isValidSessionDuration = () => {
    return string().test(
      'isValidSessionDuration',
      'Please enter valid session duration!',
      function (value) {
        if (value === null || value === '' || value === undefined) return true
        const unmaskDuration = minuteUnmask(value)
        return unmaskDuration >= 30 && unmaskDuration <= 10080
      },
    )
  }
  addMethod(string, 'isValidSessionDuration', isValidSessionDuration)

  const isValidMonthlyFee = () => {
    return string().test(
      'isValidMonthlyFee',
      'Please enter valid monthly fee!',
      function (value) {
        if (value === null || value === '' || value === undefined) return true
        const unmaskMonthlyFee = currencyUnmask(value)
        return unmaskMonthlyFee >= 0 && unmaskMonthlyFee <= 100000
      },
    )
  }
  addMethod(string, 'isValidMonthlyFee', isValidMonthlyFee)

  const validationSchema = object().shape({
    monthlyFee: object().shape({
      ic: string().isValidMonthlyFee().nullable(),
      manager: string().isValidMonthlyFee().nullable(),
      leader: string().isValidMonthlyFee().nullable(),
      executive: string().isValidMonthlyFee().nullable(),
      ceo: string().isValidMonthlyFee().nullable(),
    }),
    monthlyIndividualSessions: object().shape({
      ic: number().min(1).max(100).nullable(),
      manager: number().min(1).max(100).nullable(),
      leader: number().min(1).max(100).nullable(),
      executive: number().min(1).max(100).nullable(),
      ceo: number().min(1).max(100).nullable(),
    }),
    individualSessionDuration: object().shape({
      ic: string().isValidSessionDuration().nullable(),
      manager: string().isValidSessionDuration().nullable(),
      leader: string().isValidSessionDuration().nullable(),
      executive: string().isValidSessionDuration().nullable(),
      ceo: string().isValidSessionDuration().nullable(),
    }),
    monthlyGroupSessions: object().shape({
      ic: number().min(1).max(100).nullable(),
      manager: number().min(1).max(100).nullable(),
      leader: number().min(1).max(100).nullable(),
      executive: number().min(1).max(100).nullable(),
      ceo: number().min(1).max(100).nullable(),
    }),
    groupSessionDuration: object().shape({
      ic: string().isValidSessionDuration().nullable(),
      manager: string().isValidSessionDuration().nullable(),
      leader: string().isValidSessionDuration().nullable(),
      executive: string().isValidSessionDuration().nullable(),
      ceo: string().isValidSessionDuration().nullable(),
    }),
  })

  useEffect(() => {
    if (sowDetails) {
      const pickedSOWObject = (({
        monthlyFee,
        monthlyIndividualSessions,
        individualSessionDuration,
        monthlyGroupSessions,
        groupSessionDuration,
      }) => ({
        monthlyFee,
        monthlyIndividualSessions,
        individualSessionDuration,
        monthlyGroupSessions,
        groupSessionDuration,
      }))(sowDetails)
      setInitialValue(pickedSOWObject)
      setIsLoading(false)
    }
  }, [sowDetails])

  if (isLoading) {
    return <ABSpinner />
  }

  const onSubmit = async (values) => {
    setIsSOWUpdateLoading(true)
    const newMonthlyFeeObject = getUnmaskedObject(values.monthlyFee, (value) =>
      currencyUnmask(value),
    )

    const newIndividualSessionDurationObject = getUnmaskedObject(
      values.individualSessionDuration,
      (value) => minuteUnmask(value),
    )

    const newGroupSessionDurationObject = getUnmaskedObject(
      values.groupSessionDuration,
      (value) => minuteUnmask(value),
    )

    const monthlyIndividualSessions = removeEmpty(
      values.monthlyIndividualSessions,
    )
    const monthlyGroupSessions = removeEmpty(values.monthlyGroupSessions)
    const monthlyFee = removeEmpty(newMonthlyFeeObject)
    const individualSessionDuration = removeEmpty(
      newIndividualSessionDurationObject,
    )
    const groupSessionDuration = removeEmpty(newGroupSessionDurationObject)

    const requestedData = {
      ...(monthlyFee && { monthlyFee: monthlyFee }),
      ...(monthlyIndividualSessions && {
        monthlyIndividualSessions,
      }),
      ...(individualSessionDuration && {
        individualSessionDuration,
      }),
      ...(monthlyGroupSessions && { monthlyGroupSessions }),
      ...(groupSessionDuration && { groupSessionDuration }),
    }
    try {
      const { status } = await API.AdminAPI.companyList.updateSOW(
        requestedData,
        sowId,
      )
      if (status) {
        setIsEditable(false)
        Notification.showNotification(
          `${sowDetails?.planName} has been updated!`,
          'success',
        )
        setIsSOWUpdateLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsSOWUpdateLoading(false)
    }
  }

  return (
    <section>
      <Container className='py-2 px-0' fluid>
        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}>
          {({ handleSubmit }) => {
            return (
              <RBForm
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit(e)
                }}>
                <Table hover className='custom-table enterprise-sow-table m-0'>
                  <MonthlyPaymentTable disabled={!isEditable} />
                </Table>
                {isEditable ? (
                  <Button
                    variant='black'
                    className='mt-2-5 line-height-normal border-radius-3'
                    disabled={isSOWUpdateLoading}
                    isLoading={isSOWUpdateLoading}
                    type='submit'>
                    Save And Finish
                  </Button>
                ) : (
                  <Button
                    variant='black'
                    className='mt-2-5 line-height-normal sow-details-btn border-radius-3'
                    onClick={(e) => {
                      e.preventDefault()
                      setIsEditable(true)
                    }}>
                    Edit
                  </Button>
                )}
              </RBForm>
            )
          }}
        </Formik>
      </Container>
    </section>
  )
}
