import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useUserFirstName } from '../components'
import { CoachingPlanDetails } from '../components/coachingPlan'

const CoachingPlan = () => {
  const { coachId, clientId } = useParams()
  const userFirstName = useUserFirstName(clientId)
  return (
    <>
      <Helmet>
        <title>{`${userFirstName} SOW | Abroad`}</title>
        <meta name='title' content={`${userFirstName} SOW | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${userFirstName} SOW | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${userFirstName} SOW | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/coach/${coachId}/clients/${clientId}/plans/:planId`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/coach/${coachId}/clients/${clientId}/plans/:planId`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/coach/${coachId}/clients/${clientId}/plans/:planId`}></meta>
      </Helmet>
      <main className='pb-5'>
        <section className='pt-5'>
          <CoachingPlanDetails />
        </section>
      </main>
    </>
  )
}

export default CoachingPlan
