import React from 'react'
import GoToNextStepBtn from './GoToNextStepBtn'
import ReturnLink from './ReturnLink'

const CreateGPAndOffering = ({ nextStepHandler, prevStepHandler }) => {
  return (
    <section className='vh-65 explore-offerings d-flex flex-column'>
      <h3 className='mb-3 font-open-sans font-22 font-bold review-title'>
        Help client create a growth plan and explore offerings (15 minutes)
      </h3>
      <p className='s6 review-description'>
        For the final 15 minutes of this call with your client, please help them
        generate their own growth plan from their account. Once that is done, we
        encourage you explore what Abroad offerings might fit best for this
        client. Once you have finished the call with your client, please
        continue with the ‘next’ button to finish logging this session.
      </p>
      <div className='d-flex justify-content-between position-absolute growth-step-btn w-100'>
        <ReturnLink prevStepHandler={prevStepHandler} />
        <div className='text-right'>
          <GoToNextStepBtn
            nextStepHandler={nextStepHandler}
            btnClasses='mr-5'
          />
        </div>
      </div>
    </section>
  )
}

export default CreateGPAndOffering
