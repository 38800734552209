import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useErrorService, useUserService } from '@abroad/components'
import API from '../../utils/API'

const GetTeamMemberData = ({ render }) => {
  const { values } = useFormikContext()
  const Error = useErrorService()
  const { user } = useUserService()
  useEffect(() => {
    const getTeamMembers = async (getTeamMembersByType) => {
      try {
        const { data } = await getTeamMembersByType.call(
          API.AdminAPI.teams,
          values.group,
        )
        if (data) {
          render(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (user.isAdmin && values?.group) {
      if (values?.groupType === 'Organization') {
        getTeamMembers(API.AdminAPI.teams.getCompanyTeamMembers)
      } else if (values?.groupType === 'Network') {
        getTeamMembers(API.AdminAPI.teams.getEcoSystemTeamMembers)
      } else if (values?.groupType === 'Promo Code') {
        getTeamMembers(API.AdminAPI.teams.getPromocodeTeamMembers)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.group])
  return null
}

export default GetTeamMemberData
