import React, { useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import RBDateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { formatDate } from '../../utils/date'
import classNames from 'classnames'
import moment from 'moment'

const SingleDatePicker = ({
  formGroupProps: {
    className: formGroupClassName,
    ...otherFormGroupProps
  } = {},
  children,
  id,
  label,
  labelProps,
  renderChildren,
  size,
  className,
  spanClassName = '',
  showTooltip,
  customErrorMessage,
  showErrorMessage,
  type,
  placeholder,
  removeFormikField,
  onFocus,
  minDate,
  disabled,
  name,
  position,
  opens,
  inputStyle,
  minimumDate = null,
  maximumDate = null,
  pickerClass = '',
  ...rest
}) => {
  const picker = useRef(null)
  const { setFieldValue, values } = useFormikContext()
  const [field, meta] = useField({
    ...rest,
    ...{ type: 'text' },
    name,
  })

  useEffect(() => {
    if (values[name]) {
      picker.current.setStartDate(formatDate(values[name]))
      picker.current.setEndDate(formatDate(values[name]))
      picker.current.$picker.val(formatDate(values[name]))
    } else {
      picker.current.$picker.val('')
      picker.current.setStartDate(formatDate(moment()))
      picker.current.setEndDate(formatDate(moment()))
    }
  }, [values, name, picker])

  const renderLabel = () => {
    if (label) {
      return (
        <Form.Label {...labelProps} className='text-uppercase'>
          {label}
        </Form.Label>
      )
    }
    return null
  }

  const onApply = (e, datePicker) => {
    if (moment(datePicker.startDate).isValid()) {
      setFieldValue(name, datePicker.startDate.format('MM/DD/YYYY'))
      datePicker.element.val(datePicker.startDate.format('MM/DD/YYYY'))
    } else {
      datePicker.element.val('')
      setFieldValue(name, null)
    }
  }

  const handleChange = (e) => {
    if (moment(e.target.value).isValid()) {
      setFieldValue(name, e.target.value)
    }
  }

  let initialDatePickerSettings = {
    singleDatePicker: true,
    showDropdowns: true,
    autoUpdateInput: false,
    locale: {
      format: 'MM/DD/YYYY',
    },
    applyButtonClasses: 'btn-saffron font-montserrat',
    cancelButtonClasses: 'btn-black font-montserrat',
    drops: position,
    opens: opens,
    autoApply: true,
  }
  if (minimumDate) {
    initialDatePickerSettings.minDate = minimumDate
  }
  if (maximumDate) {
    initialDatePickerSettings.maxDate = maximumDate
  }
  if (pickerClass) {
    initialDatePickerSettings.parentEl = pickerClass
  }

  return (
    <span
      className={classNames(
        'd-flex position-relative align-items-center',
        spanClassName,
      )}>
      <div className='d-lg-flex align-items-center w-100'>
        {renderLabel()}
        <RBDateRangePicker
          ref={picker}
          onApply={onApply}
          initialSettings={initialDatePickerSettings}>
          <input
            {...field}
            autoComplete={'off'}
            placeholder='MM/DD/YYYY'
            disabled={disabled}
            type='text'
            value={values[name]}
            className={classNames(
              'form-control',
              { 'is-valid': meta.touched && !meta.error },
              { 'is-invalid': meta.touched && meta.error },
              className,
            )}
            onChange={handleChange}
            name={name}
            isValid={meta.touched && !meta.error}
            isInvalid={meta.touched && meta.error}
            style={inputStyle}
          />
        </RBDateRangePicker>
      </div>
    </span>
  )
}

SingleDatePicker.defaultProps = {
  showTooltip: false,
  removeFormikField: false,
  type: 'text',
  showErrorMessage: false,
  disabled: false,
  opens: 'right',
  position: 'down',
  inputStyle: {
    border: '1px solid #000',
    background: '#fff',
    //height: '42px',
  },
}

SingleDatePicker.propTypes = {
  removeFormikField: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  position: PropTypes.oneOf(['down', 'up', 'auto']),
  opens: PropTypes.oneOf(['left', 'right', 'center']),
}

export default SingleDatePicker
