import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { ABSpinner, Button, useErrorService } from '@abroad/components'
import { SOWDetailsTable, CreateSOWModal } from '../components'
import API from '../utils/API'
import { OrgEcoIdsContext } from '../utils/contexts'
import { sortByGivenKey } from '../utils/utility'

const OrgNetworkSOW = ({ type, name, orgEcoId, setIsShowAddSOWBtn }) => {
  const [sowsData, setSOWsData] = useState({ isLoading: true, data: [] })
  const [showModal, setShowModal] = useState(false)
  const { isLoadNewData, setIsLoadNewData } = useContext(OrgEcoIdsContext)
  const Error = useErrorService()

  useEffect(() => {
    const getSOWData = async () => {
      try {
        const { data } = await API.AdminAPI.common.getSOWData(
          type === 'Organization' ? 'company' : 'network',
          orgEcoId,
        )
        if (data) {
          const activeSOWs = data.filter((sow) => !sow?.isDeleted)
          const sortActiveGroup = sortByGivenKey(activeSOWs, 'planName')
          const archivedSOWs = data.filter((sow) => sow?.isDeleted)
          const sortArchivedSOWs = sortByGivenKey(archivedSOWs, 'planName')
          setIsLoadNewData(false)
          setSOWsData({
            isLoading: false,
            data: [...sortActiveGroup, ...sortArchivedSOWs],
          })
          if (data?.length > 0) {
            setIsShowAddSOWBtn(true)
          } else {
            setIsShowAddSOWBtn(false)
          }
        }
      } catch (e) {
        setSOWsData({
          isLoading: false,
          data: [],
        })
        Error.showError(e)
      }
    }
    if (sowsData?.isLoading && orgEcoId) getSOWData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgEcoId, sowsData.isLoading])

  useEffect(() => {
    if (isLoadNewData) {
      setSOWsData({ isLoading: true, data: [] })
    }
  }, [isLoadNewData])

  if (sowsData?.isLoading) {
    return <ABSpinner />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${name}'s SOWs | Abroad`}</title>
        <meta name='title' content={`${name}'s SOWs | Abroad`}></meta>
        <meta property='og:title' content={`${name}'s SOWs | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${name}'s SOWs | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/organization/${name}/sows`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${name}/sows`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/organization/${name}/sows`}></meta>
      </Helmet>
      <main>
        <section>
          <CreateSOWModal
            showModal={showModal}
            setShowModal={setShowModal}
            type={type}
            orgEcoId={orgEcoId}
          />
          <Container className='px-0' fluid>
            <div className='sows'>
              {sowsData?.data?.length > 0 ? (
                <SOWDetailsTable data={sowsData?.data} type={type} />
              ) : (
                <div>
                  <p className='s3 font-semibold mb-0'>
                    This {type} has no SOW. You may create one pressing the
                    <br />
                    button below, or in the actions panel.
                  </p>
                  <Button
                    variant='black'
                    className='create-new-btn line-height-normal border-radius-3'
                    onClick={() => setShowModal(true)}>
                    Create New SOW
                  </Button>
                </div>
              )}
            </div>
          </Container>
        </section>
      </main>
    </React.Fragment>
  )
}

OrgNetworkSOW.propsTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hasAnySOW: PropTypes.bool,
}

export default OrgNetworkSOW
